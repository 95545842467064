import { Type } from '@angular/core';
import { Report, ReportContextEnum } from '@cohesity/api/reporting';
import {
  flagEnabled,
  IrisContext,
  isAllClustersScope,
  isDmsScope,
  isGlobalScope,
  isTenantUser,
} from '@cohesity/iris-core';

import { ActivityTypeFilterComponent } from './activity-type-filter/activity-type-filter.component';
import {
  BackupStatusFilterComponent,
  ObjectStatusFilterComponent,
} from './backup-status-filter/backup-status-filter.component';
import { ConnectionStatusFilterComponent } from './connection-status-filter/connection-status-filter.component';
import { ReportFilterComponent } from './base-report-filter.component';
import { DateRangeFilterComponent } from './date-range-filter/date-range-filter.component';
import {
  EnvGroupFilterComponent,
  GroupEnvGroupFilterComponent,
  SourceEnvGroupFilterComponent,
} from './env-group-filter/env-group-filter.component';
import {
  EnvironmentFilterComponent,
  GroupEnvironmentFilterComponent
} from './environment-filter/environment-filter.component';
import { ReportFilterType } from './filters-types';
import { MessageCodeFilterComponent } from './message-code-filter/message-code-filter.component';
import { ObjectSearchFilterComponent } from './object-search-filter/object-search-filter.component';
import { ObjectSnapshotStatusFilterComponent } from './object-snapshot-status-filter/object-snapshot-status-filter.component';
import {
  M365AppFilterComponent,
  M365FilterComponent,
  ObjectTypeFilterComponent,
} from './object-type-filter/object-type-filter.component';
import { OwnershipFilterComponent } from './ownership-filter/ownership-filter.component';
import { PolicyFilterComponent } from './policy-filter/policy-filter.component';
import { ProtectionGroupFilterComponent } from './protection-group-filter/protection-group-filter.component';
import { ProtectionStatusFilterComponent } from './protection-status-filter/protection-status-filter.component';
import {
  BackupStatusLabelFilterComponent,
  LastRunStatusFilterComponent,
  LastRunStatusLabelFilterComponent,
  RunStatusFilterComponent,
  TaskStatusFilterComponent
} from './run-status-filter/run-status-filter.component';
import { SlaStatusFilterComponent } from './sla-status-filter/sla-status-filter.component';
import { SnapshotStatusFilterComponent } from './snapshot-status-filter/snapshot-status-filter.component';
import { SnapshotTypeFilterComponent } from './snapshot-type-filter/snapshot-type-filter.component';
import {
  M365SourceFilterComponent,
  SourceFilterComponent,
  SourceListFilterComponent,
} from './source-filter/source-filter.component';
import { SystemFilterComponent } from './system-filter/system-filter.component';
import { TargetNameFilterComponent } from './target-name-filter/target-name-filter.component';
import { TargetTypeFilterComponent } from './target-type-filter/target-type-filter.component';
import {
  TenantsFilterComponent,
  TenantsFilterSingleSelectionComponent
} from './tenants-filter/tenants-filter.component';

/**
 * Supported contexts by report.
 */
export type ReportContextsType = Report['supportedUserContexts'];

/**
 * A list of all supported report contexts.
 */
const ReportContexts: ReportContextEnum[] = ['Hybrid', 'MCM', 'DMaaS', 'MCMTenant', 'FortKnox', 'SmartFiles'];

/**
 * This maps the filter types to components that we will use to render each filter.
 */
export const filterComponentMap: Record<ReportFilterType, Type<ReportFilterComponent>> = {
  activityType: ActivityTypeFilterComponent,
  backupStatus: BackupStatusFilterComponent,
  backupStatusLabel: BackupStatusLabelFilterComponent,
  connectionStatus:  ConnectionStatusFilterComponent,
  date: DateRangeFilterComponent,
  envGroup: EnvGroupFilterComponent,
  environment: EnvironmentFilterComponent,
  groupEnvGroup: GroupEnvGroupFilterComponent,
  groupEnvironment: GroupEnvironmentFilterComponent,
  lastRunStatus: LastRunStatusFilterComponent,
  lastRunStatusLabel: LastRunStatusLabelFilterComponent,
  m365: M365FilterComponent,
  m365App: M365AppFilterComponent,
  managedBy: OwnershipFilterComponent,
  messageCode: MessageCodeFilterComponent,
  objectSnapshotStatus: ObjectSnapshotStatusFilterComponent,
  objectStatus: ObjectStatusFilterComponent,
  objectUuid: ObjectSearchFilterComponent,
  objectType: ObjectTypeFilterComponent,
  policyId: PolicyFilterComponent,
  groupId: ProtectionGroupFilterComponent,
  protectionStatus: ProtectionStatusFilterComponent,
  runStatus: RunStatusFilterComponent,
  slaStatus: SlaStatusFilterComponent,
  snapshotStatus: SnapshotStatusFilterComponent,
  snapshotType: SnapshotTypeFilterComponent,
  sourceEnvGroup: SourceEnvGroupFilterComponent,
  sourceM365: M365SourceFilterComponent,
  sourceUuid: SourceFilterComponent,
  sourceUuids: SourceListFilterComponent,
  systemId: SystemFilterComponent,
  targetId: TargetNameFilterComponent,
  targetType: TargetTypeFilterComponent,
  taskStatus: TaskStatusFilterComponent,
  tenantsId: TenantsFilterComponent,
  tenantId: TenantsFilterSingleSelectionComponent
};

/**
 * Map of filter types. This is better as a const than a switch statement since calls to getFiltersByReportId
 * will always return the same object reference.
 */
const reportFilterMap = {
  'cluster-connections': ['systemId', 'connectionStatus', 'date'],
  'data-transferred-to-external-targets': ['systemId', 'targetType', 'targetId', 'managedBy', 'date'],
  failures: ['systemId', 'sourceUuid', 'tenantsId', 'groupId', 'envGroup', 'policyId', 'messageCode', 'date', 'objectUuid'],
  'm365-backup-storage-usage': ['systemId', 'sourceM365', 'm365', 'date', 'objectUuid'],
  'm365-compliance-report': ['systemId', 'sourceM365', 'policyId', 'm365App', 'backupStatusLabel', 'date', 'objectUuid'],
  'object-growth': ['systemId', 'sourceUuid', 'tenantsId', 'envGroup', 'date', 'objectUuid'],
  'protected-unprotected': ['systemId', 'sourceUuid', 'tenantsId', 'envGroup', 'protectionStatus', 'objectUuid'],
  protected: ['systemId', 'sourceUuid', 'tenantsId', 'groupId', 'envGroup', 'policyId', 'protectionStatus', 'objectStatus', 'objectSnapshotStatus', 'lastRunStatusLabel', 'date', 'objectUuid'],
  'protection-activity': ['systemId', 'tenantsId', 'groupId', 'groupEnvGroup', 'policyId', 'runStatus', 'slaStatus', 'activityType', 'date'],
  'protection-group-summary':
    ['systemId', 'sourceUuids', 'tenantsId', 'groupId', 'groupEnvGroup', 'policyId', 'backupStatus', 'lastRunStatus', 'slaStatus', 'date'],
  'protection-runs':
  ['systemId', 'sourceUuid', 'tenantsId', 'groupId', 'envGroup', 'policyId', 'runStatus', 'snapshotStatus',
    'slaStatus', 'messageCode', 'date', 'objectUuid'],
  'system-protection': ['systemId', 'activityType', 'date'],
  'recovery': ['systemId', 'sourceUuid', 'tenantsId', 'envGroup', 'taskStatus', 'snapshotType', 'date', 'objectUuid'],
  'service-consumption': ['systemId', 'sourceUuid', 'tenantsId', 'envGroup', 'date'],
  'source-growth': ['systemId', 'sourceUuid', 'tenantsId', 'sourceEnvGroup', 'date'],
  'storage-consumption-cluster': ['systemId', 'date'],
  'storage-consumption-group': ['systemId', 'sourceUuids', 'tenantsId', 'groupId', 'groupEnvGroup', 'policyId', 'date'],
  'storage-consumption-object': ['systemId', 'sourceUuid', 'tenantsId', 'envGroup', 'date', 'objectUuid'],
  'storage-consumption-organization': ['systemId', 'date'],
  'storage-consumption-storage-domain': ['systemId', 'tenantId', 'date'],
  'storage-consumption-view': ['systemId', 'tenantsId', 'date'],
};

/**
 * Filters to exclude in specific context
 */
export const excludeFilterMap = {
  'DMaaS': [ 'groupId', 'tenantsId', 'tenantId' ],
  'MCM': [ 'messageCode' ],
  'MCMTenant': [ 'tenantsId', 'tenantId' ],
};

/**
 * Columns to exclude in specific context
 */
export const excludeColumnMap = {
  'DMaaS': [ 'tenantName', 'tenantNames' ],
  'MCMTenant': [ 'tenantName', 'tenantNames' ],
};

/**
 * Map of supported contexts from report ID.
 */
export const reportContextMap: { [key: string]: ReportContextsType } = {
  'cluster-connections': ['Hybrid', 'MCM', 'MCMTenant'],
  'data-transferred-to-external-targets': ['Hybrid', 'MCM', 'MCMTenant', 'FortKnox'],
  'failures': ['Hybrid', 'MCM', 'DMaaS', 'MCMTenant'],
  'm365-backup-storage-usage': ['Hybrid', 'DMaaS'],
  'm365-compliance-report': ['DMaaS'],
  'object-growth': ['Hybrid', 'MCM', 'MCMTenant'],
  'protected': ['Hybrid', 'MCM', 'DMaaS', 'MCMTenant'],
  'protected-unprotected': ['Hybrid', 'MCM', 'DMaaS', 'MCMTenant'],
  'protection-activity': ['Hybrid', 'MCM', 'MCMTenant', 'FortKnox'],
  'protection-group-summary': ['Hybrid', 'MCM', 'MCMTenant'],
  'protection-runs': ['Hybrid', 'MCM', 'DMaaS', 'MCMTenant'],
  'service-consumption': ['Hybrid', 'DMaaS', 'MCMTenant'],
  'source-growth': ['Hybrid', 'MCM', 'MCMTenant'],
  'system-protection': ['Hybrid', 'MCM', 'MCMTenant', 'FortKnox'],
  'recovery': ['Hybrid', 'MCM', 'DMaaS', 'MCMTenant', 'FortKnox'],
  'storage-consumption-cluster': ['Hybrid', 'MCM', 'MCMTenant'],
  'storage-consumption-group': ['Hybrid', 'MCM', 'MCMTenant'],
  'storage-consumption-object': ['Hybrid', 'MCM', 'MCMTenant'],
  'storage-consumption-organization': ['Hybrid', 'MCM', 'MCMTenant'],
  'storage-consumption-storage-domain': ['Hybrid', 'MCM', 'MCMTenant'],
  'storage-consumption-view': ['Hybrid', 'MCM', 'MCMTenant', 'SmartFiles'],
};

/**
 * Holds a generated map of report IDs by context.
 */
export const reportIdsByContext = {};

/**
 * Compiles a list of report IDs supported by the specified context.
 */
ReportContexts.forEach(context => {
  reportIdsByContext[context] = [];
  for (const reportId of Object.keys(reportContextMap)) {
    if (getContextsByReportId(reportId).includes(context)) {
      reportIdsByContext[context].push(reportId);
    }
  }
});

/**
 * The supported contexts are determined by the report id.
 *
 * @param reportId The report's id
 * @returns A list of supported contexts.
 */
export function getContextsByReportId(reportId: string): ReportContextsType {
  return reportContextMap[reportId] || [ ...ReportContexts ];
}

/**
 * The available filters are determined by the report type, so we can statically determinee the list
 * based on a given report. For now, we are basing the on the report id until the API is more complete.
 *
 * @param reportId The report's id
 * @returns A list of available filters.
 */
export function getFiltersByReportId(reportId: string): ReportFilterType[] {
  return reportFilterMap[reportId] || undefined;
}

/**
 * Returns false if not showing filter based on feature flag and filter key.
 *
 * @param filterKey reporting filter key
 * @returns false if not showing messageCode filter
 */
export function checkAttribute(filterKey: string, irisContext: IrisContext): boolean {
  if (filterKey === 'messageCode') {
    if (flagEnabled(irisContext, 'messageCode')) {
      return isDmsScope(irisContext) || isGlobalScope(irisContext);
    }
    if (flagEnabled(irisContext, 'messageCodeAllClustersAndGlobalScopes')) {
      return isAllClustersScope(irisContext) || isGlobalScope(irisContext);
    }
    return false;
  }
  if (['tenantId', 'tenantsId', 'tenantName'].includes(filterKey)) {
    return flagEnabled(irisContext, 'ngReportSPView');
  }
  return true;
}

/**
 * The available filters are determined by the report type, so we can statically determine the list
 * based on a given report. For now, we are basing the on the report id until the API is more complete.
 *
 * @param reportId The report's id
 * @returns A list of available filters.
 */
export function getAvailableFilters(reportId: string, irisContext: IrisContext): ReportFilterType[] {
  const filterList = getFiltersByReportId(reportId) || [];
  if (isTenantUser(irisContext)) {
    return filterList.filter(f => !excludeFilterMap['MCMTenant'].includes(f) && checkAttribute(f, irisContext));
  } else if (isDmsScope(irisContext)) {
    return filterList.filter(f => !excludeFilterMap['DMaaS'].includes(f) && checkAttribute(f, irisContext));
  }
  return filterList.filter(f => checkAttribute(f, irisContext));
}

/**
 * for url params for filter on reporting, i.e., property value of all filters.
 */
export const filterReportingUrlParams: string[]  = [
  'activityType',
  'backupStatus',
  'connectionStatus',
  'date',
  'endTime',
  'envGroup',
  'environment',
  'groupId',
  'lastRunStatus',
  'managedBy',
  'messageCode',
  'objectStatus',
  'objectUuid',
  'objectType',
  'policyId',
  'protectionStatus',
  'runStatus',
  'slaStatus',
  'snapshotStatus',
  'snapshotType',
  'sourceUuid',
  'sourceUuids',
  'startTime',
  'status',
  'systemId',
  'targetId',
  'targetType',
  'tenantsId',
  'tenantId',
  'timeRange',
  'protectionEnvName'
];

/**
 * Parameter exclude list by report ID as the corresponding filter does not exist.
 */
export const filterReportingExcludeParams = {
  all: ['timestampUsecs'],
  failures: ['strikeCount', 'objectStatus'],
  'protection-runs': ['maxPolicyName', 'runStartTimeUsecs'],
  'system-protection': ['status'],
};

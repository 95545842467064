import { Directive, forwardRef, Host, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LegacyDateAdapter as DateAdapter } from '@angular/material/legacy-core';
import { MatCalendar } from '@angular/material/datepicker';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CalendarConfig } from './config/calendar.config';

/**
 * The calendar directive extends the MatCalendar capabilities by adding ControlValueAccessor & timezone support.
 *
 * @example
 *  <mat-calendar
 *    cogCalendar
 *    [utcOffset]="utcOffset"
 *    [formControl]="startDate"
 *  ></mat-calendar>
 */
@Directive({
  selector: 'mat-calendar[cogCalendar]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CalendarDirective),
      multi: true,
    }
  ],
  standalone: true
})
export class CalendarDirective<D> implements ControlValueAccessor, OnChanges, OnInit, OnDestroy {
  /**
   * Optionally provide the UTC offset value which is used to calendar date at a specified timezone.
   */
  @Input() utcOffset = 0;

  /**
   * Skip updating material selected date and used when date selection is controlled externally by custom dateClass.
   */
  @Input() skipUpdatingMatSelected = false;

  /**
   * The difference in UTC values b/w input utcOffset and current user's UTC offset value.
   */
  private get utcDelta(): number {
    if (!this.utcOffset) {
      return 0;
    }
    return this.utcOffset - this.calendarConfig.getCurrentUtcOffset();
  }

  /**
   * Use to clean up subscriptions on destroy.
   */
  private destroy = new Subject<void>();

  /**
   * The provided external date value to cog-calendar using formControl.
   */
  private externalValue: D;

  /**
   * Holds the cached internal value.
   */
  private _internalValue: D;

  /**
   * Return the selected internal date w/o any utcOffset adjustments.
   */
  private get internalValue(): D {
    return this._internalValue;
  }

  /**
   * Set the selected internal date.
   */
  private set internalValue(date: D) {
    this._internalValue = date;

    if (!this.skipUpdatingMatSelected) {
      this.calendar.selected = date;
    }
  }

  constructor(
    @Host() public calendar: MatCalendar<D>,
    @Inject(CalendarConfig) private calendarConfig: CalendarConfig<D>,
    private dateAdapter: DateAdapter<D>,
  ) {}

  /**
   * Initialize directive.
   */
  ngOnInit() {
    this.calendar.selectedChange.pipe(takeUntil(this.destroy)).subscribe(date => this.selectDay(date));
  }

  /**
   * Cleanup the change detection listener on destroy.
   */
  ngOnDestroy() {
    this.destroy.next();
  }

  /**
   * The component binding changes life cycle method.
   *
   * @param  changes  The bindings changes object.
   */
  ngOnChanges(changes: SimpleChanges) {
    // On UTC offset value changes adjust the internal date.
    if (changes && changes.utcOffset && this.externalValue) {
      this.internalValue = this.addMinutes(this.externalValue, this.utcDelta);
    }
  }

  /**
   * Refresh the active calendar view.
   */
  refresh() {
    const activeView = this.calendar.monthView || this.calendar.yearView || this.calendar.multiYearView;
    activeView._init();
  }

  /**
   * Handles day selection in the month view.
   *
   * @param   date   The selected date.
   */
  selectDay(date: D) {
    this.internalValue = date;

    // adjusting the external date by subtracting the UTC delta from the selected date because
    // MatCalendar will always emit the date in current UTC date.
    this.externalValue = this.addMinutes(this.internalValue, this.utcDelta * -1);

    // Notifying value changes to FormControl.
    this.onChange(this.externalValue);
  }

  /**
   * Add the number of minutes to the provided date.
   *
   * @param   date      The date.
   * @param   nMinutes  The number of minutes to add.
   * @return  A new date by adding provided minutes.
   */
  addMinutes(date: D, nMinutes: number): D {
    const outDate = this.dateAdapter.clone(date);

    if (!nMinutes) {
      return outDate;
    }

    return this.calendarConfig.addMinutes(outDate, nMinutes);
  }

  /**
   * Update the view on model changes is request programmatic via forms API.
   *
   * This method is called by the forms API to write to the view when programmatic changes from model to view are
   * requested.
   *
   * @param   date   The new date object.
   */
  writeValue(date: D) {
    // Keep external date safe for later used maybe when UTC offset changes to adjust.
    this.externalValue = date;

    const isValid = this.dateAdapter.isDateInstance(this.externalValue) &&
      this.dateAdapter.isValid(this.externalValue);

    // Use only valid input date.
    const newDate = isValid ? this.externalValue : null;

    // Adjust utcOffset values for only valid input dates.
    this.internalValue = isValid ? this.addMinutes(newDate, this.utcDelta) : null;

    // Updating active date used to select current month & year in the calendar.
    if (this.calendar && this.internalValue) {
      const activeDate = this.dateAdapter.createDate(
        this.dateAdapter.getYear(this.internalValue),
        this.dateAdapter.getMonth(this.internalValue),
        this.dateAdapter.getDate(this.internalValue)
      );

      // Initialize the calendar with month or year equal to active date's month or year.
      if (!this.calendar.activeDate && !this.calendar.startAt) {
        this.calendar.startAt = activeDate;
      }

      this.calendar.activeDate = activeDate;
    }
  }

  /**
   * This method is called by the forms API on initialization to update the form
   * model when values propagate from the view to the model.
   */
  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  /**
   * Registers a callback function is called by the forms API on initialization
   * to update the form model on blur.
   */
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  /**
   * The placeholder method populated by forms API registerOnChange method which
   * is used to update changes from view to modal.
   */
  onChange = (_date: D) => {};

  /**
   * The placeholder method populated by forms API registerOnTouched method which
   * is used to mark a form field should be considered blurred or "touched".
   */
  onTouched = () => {};

  /**
   * Function that is called by the forms API when the control status changes to
   * or from 'DISABLED'. Depending on the status, it enables or disables the
   * appropriate DOM element.
   */
  setDisabledState(_isDisabled: boolean) {}
}

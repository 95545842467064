import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ByteSizeModule } from '../byte-size/public_api';
import { DatePipesModule } from '../date-pipe/index';
import { IconModule } from '../icon/icon.module';
import { dataRendererProvider } from './data-renderer.model';
import { DataRendererComponent } from './data-renderer/data-renderer.component';
import {
  BytesDataRendererComponent,
  DateDataRendererComponent,
  DurationDataRendererComponent,
  IconDataRendererComponent,
  NumberDataRendererComponent,
  renderers,
  TextDataRendererComponent,
} from './renderers/index';
import { percentNumberRenderer } from './renderers/percent-data-renderer';

@NgModule({
  imports: [
    CommonModule,
    DatePipesModule,
    PortalModule,
    IconModule,
    ByteSizeModule,
    DataRendererComponent,
    ...renderers,
  ],
  exports: [DataRendererComponent],
  providers: [
    dataRendererProvider({
      bytes: BytesDataRendererComponent,
      date: DateDataRendererComponent,
      duration: DurationDataRendererComponent,
      icon: IconDataRendererComponent,
      number: NumberDataRendererComponent,
      percent: percentNumberRenderer,
      text: TextDataRendererComponent,
    }),
  ],
})
export class DataRenderersModule {}

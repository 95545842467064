import { Component } from '@angular/core';
import {
  AgentInformation,
  ClusterNetworkingResourceInformation,
  PhysicalProtectionSource,
  PhysicalVolume,
  ProtectionSourceNode,
} from '@cohesity/api/v1';
import { PopoverRef } from '@cohesity/helix';
import { IrisContextService, isDmsScope } from '@cohesity/iris-core';
import { orderBy } from 'lodash-es';

import {
  ClusterNetworkingResourceType,
  ENUM_HOST_TYPE,
  ENV_GROUPS,
  Environment,
  HostType,
  PhysicalEntityType,
} from '../../constants';
import { filterVolumes } from '../../helper-utils';

/**
 * Specifies Agents information data.
 */
export interface NodeAgentInformation extends AgentInformation {
  /**
   * Determines the status of the Agent.
   */
  statusKey: string;

  /**
   * Determines if the Agent has any error based on the error messages.
   */
  isAnyError: boolean;

  /**
   * Determines if the Agent is upgradable or not.
   */
  isUpgradable: boolean;
}

@Component({
  selector: 'coh-physical-popover',
  templateUrl: './physical-popover.component.html',
  styleUrls: ['./physical-popover.component.scss'],
})
export class PhysicalPopoverComponent {
  /**
   * Access to the current node property.
   */
  get node(): ProtectionSourceNode {
    return this.popoverRef && this.popoverRef.data;
  }

  /**
   * Access to the Physical Protection Source object.
   */
  get physicalSource(): PhysicalProtectionSource {
    return this.node?.protectionSource?.physicalProtectionSource;
  }

  /**
   * Determines if the node is a physical host.
   */
  get isHost(): boolean {
    return this.physicalSource.type === PhysicalEntityType.kHost;
  }

  /**
   * Determines if the node is a SQL cluster.
   */
  get isSqlCluster(): boolean {
    return this.physicalSource.type === PhysicalEntityType.kWindowsCluster;
  }

  /**
   * Determines if the node is a Unix cluster.
   */
  get isUnixCluster(): boolean {
    return this.physicalSource.type === PhysicalEntityType.kUnixCluster;
  }

  /**
   * Determines if the node is a Oracle RAC cluster or not.
   */
  get isOracleRacCluster(): boolean {
    return ENV_GROUPS.oracleClusterTypes.includes(this.physicalSource.type);
  }

  /**
   * Determines if the node is protected as SQL.
   */
  get isSqlProtected(): boolean {
    return this.node.protectedSourcesSummary.some(
      envSummary => envSummary.environment === Environment.kSQL && envSummary.leavesCount > 0
    );
  }

  /**
   * Determines if the host is a SQL host
   */
  get isSqlHost(): boolean {
    return (this.node.registrationInfo.environments || []).includes(Environment.kSQL);
  }

  /**
   * Determines if the host is a Oracle host.
   */
  get isOracleHost(): boolean {
    return (this.node.registrationInfo.environments || []).includes(Environment.kOracle);
  }

  /**
   * Determines if the node is protected as Oracle.
   */
  get isOracleProtected(): boolean {
    return this.node.protectedSourcesSummary.some(
      envSummary => envSummary.environment === Environment.kOracle && envSummary.leavesCount > 0
    );
  }

  /**
   * Determines if the host is an Exchange host.
   */
  get isExchangeHost(): boolean {
    return (this.node.registrationInfo.environments || []).includes(Environment.kExchange);
  }

  /**
   * Determines if the node is VCS cluster.
   */
  get isVcsCluster(): boolean {
    return this.physicalSource.type === PhysicalEntityType.kOracleAPCluster && !!this.physicalSource.vcsVersion;
  }

  /**
   * Determines if the node has Windows os.
   */
  get isWindows(): boolean {
    return this.physicalSource.hostType === HostType.kWindows;
  }

  /**
   * Access to the lvm volumes of the node.
   */
  get lvmVolumes(): PhysicalVolume[] {
    return orderBy(filterVolumes(this.physicalSource.volumes, 'lvm'), 'mountPoints');
  }

  /**
   * Access to the non lvm volumes of the node.
   */
  get nonLvmVolumes(): PhysicalVolume[] {
    return orderBy(filterVolumes(this.physicalSource.volumes, 'nonlvm'), 'mountPoints');
  }

  /**
   * Access to the shared volumes of the node.
   */
  get sharedVolumes(): PhysicalVolume[] {
    return orderBy(filterVolumes(this.physicalSource.volumes, 'shared'), 'mountPoints');
  }

  /**
   * Determines if there is any connection error while connecting to the node.
   */
  get isConnectionError(): boolean {
    return !!this.node.registrationInfo.refreshErrorMessage;
  }

  /**
   * Access to the decorated agents of the node.
   */
  get agents(): NodeAgentInformation[] {
    const agents: any = this.physicalSource.agents || [];

    agents.forEach(agent => {
      agent.isAnyError = false;
      agent.statusKey = 'healthy';
      if (agent.refreshErrorMessage) {
        agent.statusKey = 'refreshError';
        agent.isAnyError = true;
      } else if (!!agent.verificationError || !!agent?.registrationInfo?.authenticationErrorMessage) {
        agent.statusKey = 'registrationError';
        agent.isAnyError = true;
      }

      agent.isUpgradable =
        this.node?.protectionSource?.environment !== Environment.kVMware && agent.upgradability === 'kUpgradable';
    });

    return agents;
  }

  /**
   * Indicates if the user is operating in DMaaS scope or not.
   */
  get isDmsScope(): boolean {
    return isDmsScope(this.irisContextService.irisContext);
  }

  /**
   * Check to display popover info based on scope and environment.
   */
  get isDmsPhysicalFile(): boolean {
    return this.isDmsScope &&
      this.node.protectedSourcesSummary.some(protection => protection.environment === Environment.kPhysicalFiles);
  }

  /**
   * Determines if the node has windows file server role enabled.
   */
  get isWindowsFileServerRole(): boolean {
    return this.physicalSource.type === PhysicalEntityType.kWindowsCluster &&
      this.physicalSource.clusterSourceType === 'kRole';
  }

  /**
   * Returns the server type of the node.
   */
  get serverType(): string {
    let type = 'enum.sourceType.kPhysical.' + this.physicalSource.type;
    if ( this.isWindowsFileServerRole ) {
      type += '.kRole';
    }
    return type;
  }

  /**
   * Specifies cluster networking resource information.
   */
  clusterNetworkingResourceInfo: ClusterNetworkingResourceInformation[] = [];

  /**
   * Constant enum of operating system type
   */
  ENUM_HOST_TYPE = ENUM_HOST_TYPE;

  /**
   * Whether node is protected or not
   */
  isNodeProtected: boolean;

  constructor(private popoverRef: PopoverRef<ProtectionSourceNode>, private irisContextService: IrisContextService) {
    this.node.protectedSourcesSummary = this.node.protectedSourcesSummary.filter(
      protection =>
        !!(
          [Environment.kPhysical, Environment.kPhysicalFiles].includes(protection.environment as Environment) &&
          protection.leavesCount
        )
    );
    const protectionSummary = this.node.protectedSourcesSummary;

    this.isNodeProtected =
      !!(protectionSummary[0]?.leavesCount > 0) || this.isOracleProtected;

    this.clusterNetworkingResourceInfo = (this.node?.protectionSource?.physicalProtectionSource?.networkingInfo?.
      resourceVec || []).filter(val => val.type === ClusterNetworkingResourceType.kServer);
  }

  /**
   * Returns whether physical node has popover or not based on node specific logic
   *
   * @returns  true/false whether node has popover or not
   */
  static hasPopover(physicalNode: ProtectionSourceNode) {
    return !physicalNode.nodes;
  }

  /**
   * Returns application type based on current node info.
   *
   * @returns Application environment
   */
  getApplicationType(): string {
    switch (true) {
      case this.isSqlHost || this.isSqlCluster:
        return Environment.kSQL;

      case this.isOracleHost || this.isOracleRacCluster:
        return Environment.kOracle;
    }
  }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ViewProtocol } from '@cohesity/api/v2';
import { flagEnabled, IrisContextService, isMcm } from '@cohesity/iris-core';
import { CreateForm, createFormProviders } from '@cohesity/shared-forms';

import { DefaultProtocolListByCategory, DefaultProtocolListByCategoryWithNFS4, ReadOnlyProtocols, ReadWriteProtocols, ViewCategory } from '../../../models';

/**
 * Internal form interface.
 */
export interface ViewProtocolForm {
  readWriteProtocol: ViewProtocol[] | ViewProtocol;
  readOnlyProtocol?: ViewProtocol[];
}

@Component({
  selector: 'coh-create-view-protocol',
  templateUrl: './view-protocol.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: createFormProviders(ViewProtocolComponent),
})
export class ViewProtocolComponent {

  /**
   * The category for the view.
   */
  _category: ViewCategory;

  /**
   * Category setter, need to update the optons and default values on category
   * change.
   */
  @Input() set category(category: ViewCategory) {
    this._category = category;

    this.readWriteProtocolList = ReadWriteProtocols
      .filter(protocol => protocol.applicableCategories.includes(category))
      .map(({ mode, type }) => ({ mode, type }));
    this.readOnlyProtocolList = ReadOnlyProtocols
      .filter(protocol => protocol.applicableCategories.includes(category))
      .map(({ mode, type }) => ({ mode, type }));

    // Need to udpate the view because the formgroup gets updated before the
    // template is updated causing one console error.
    this.cdr.detectChanges();
    this.form.formGroup.setValue(this.getDefaultValues());
  }

  /**
   * The view category.
   */
  get category(): ViewCategory {
    return this._category || 'FileServices';
  }

  /**
   * Read/Write protocol can be single/multi select depending on the category.
   *
   * Multi-select for 'FileServices'.
   */
  get isSingleSelectReadWriteProtocol() {
    return this.category !== 'FileServices';
  }

  /**
   * Show the Read Only protocol only for 'FileServices' and 'BackupTarget' view categories.
   */
  get showReadOnlyProtocolSelect() {
    return ['FileServices', 'BackupTarget'].includes(this.category) && !this.isHelios;
  }

  /**
   * If opened in a Helios session.
   */
  isHelios = isMcm(this.irisCtx.irisContext);

  /**
   * The list of options for Read/Write protocol.
   */
  readWriteProtocolList: ViewProtocol[] = [];

  /**
   * The list of options for Read Only protocol.
   */
  readOnlyProtocolList: ViewProtocol[] = [];

  /**
   * Initialize the form.
   */
  form = new CreateForm<ViewProtocol[], ViewProtocolForm>(this, {
    formControls: {
      readWriteProtocol: new UntypedFormControl(null, Validators.required),
      readOnlyProtocol: new UntypedFormControl(null),
    },
    transformFromFormGroup: this.transformFromFormGroup.bind(this),
    transformToFormGroup: this.transformToFormGroup.bind(this),
  });

  constructor(
    private cdr: ChangeDetectorRef,
    private irisCtx: IrisContextService,
  ) { }

  /**
   * Get the default values for the form.
   */
  getDefaultValues(): ViewProtocolForm {
    const defaultProtocolList =
      flagEnabled(this.irisCtx.irisContext, 'viewSettingsSecureByDefault') ?
        DefaultProtocolListByCategoryWithNFS4[this.category] :
        DefaultProtocolListByCategory[this.category];
    const { readWriteProtocol, readOnlyProtocol } = this.getProtocolByMode(defaultProtocolList);

    // If the category is 'FileServices' then the default protocol is an array
    // as the mat-select is multi-select.
    return {
      readWriteProtocol: this.isSingleSelectReadWriteProtocol ? readWriteProtocol[0] : readWriteProtocol,
      readOnlyProtocol,
    };
  }

  /**
   * Transform the form group value to be used as the input form value.
   */
  transformToFormGroup(value: ViewProtocol[]): ViewProtocolForm {
    if (!value) {
      return this.getDefaultValues();
    }

    const { readWriteProtocol, readOnlyProtocol } = this.getProtocolByMode(value);

    // If the category is 'FileServices' then the default protocol is an array
    // as the mat-select is multi-select.
    return {
      readWriteProtocol: this.isSingleSelectReadWriteProtocol ? readWriteProtocol[0] : readWriteProtocol,
      readOnlyProtocol,
    };
  }

  /**
   * Transform the form value to be used as the output form value.
   */
  transformFromFormGroup(value: ViewProtocolForm): ViewProtocol[] {
    // Concatenate all the Read/Write & ReadOnly protocols.
    return [
      ...(Array.isArray(value.readWriteProtocol) ? value.readWriteProtocol : [value.readWriteProtocol]),
      ...(value.readOnlyProtocol || []),
    ];
  }

  /**
   * Splits the protocol into read-write and read-only.
   *
   * @param protocolList The protocolList to split.
   * @returns Protocol list split by mode.
   */
  private getProtocolByMode(protocolList: ViewProtocol[]): { [mode: string]: ViewProtocol[] } {
    const readWriteProtocol: ViewProtocol[] = [];
    const readOnlyProtocol: ViewProtocol[] = [];

    protocolList.forEach(protocol => {
      // Remove S3 if this is Helios.
      if (this.isHelios && protocol.type === 'S3') {
        return;
      }

      if (protocol.mode === 'ReadWrite') {
        readWriteProtocol.push(protocol);
      } else if (protocol.mode === 'ReadOnly') {
        readOnlyProtocol.push(protocol);
      }
    });

    return {
      readOnlyProtocol,
      readWriteProtocol,
    };
  }
  /**
   * This is used when value for mat-select is an object
   * Compares view protocols for selection
   *
   * @param p1    view protocol.
   * @param p2    view protocol.
   */
  compareProtocols(p1: ViewProtocol, p2: ViewProtocol): boolean {
    if (p1 && p2) {
      return p1.type === p2.type && p1.mode === p2.mode;
    }
    return false;
  }
}

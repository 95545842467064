import { NgClass, NgIf } from '@angular/common';
import { Component, ElementRef, HostBinding, HostListener, Input, ViewEncapsulation } from '@angular/core';
import { IconSize } from '../../icon';
import { IconComponent } from '../../icon/icon.component';

/**
 * Display a single item for inclusion inside a cogStatList. The element should wrap a value to be displayed and
 * can/should have a label and an optional labelLegend which will provide a colored dot that typically should related to
 * a nearby chart.
 *
 * @example
 *   Simple Usage:
 *   <cog-statlist-item [label]="'boringThings' | translate">
 *     48
 *   </cog-statlist-item>
 *
 *   <a cogStatlistItem uiSref="Alerts" [label]="'linkedBoringThings' | translate">
 *     48
 *   </a>
 *
 *   Full Usage:
 *   <cog-statlist-item [label]="'excitingThings' | translate" labelLegend="critical">
 *     24
 *   </cog-statlist-item>
 *
 *  With iconClass:
 *   <cog-statlist-item [label]="'activeDirectory' | translate" iconClass="icn-type-active-directory">
 *     24
 *   </cog-statlist-item>
 *
 *  With material icons:
 *   <cog-statlist-item [label]="'error' | translate" iconName="error_outline" iconClass="critical">
 *     24
 *   </cog-statlist-item>
 *
 */
@Component({
  selector: 'cog-statlist-item, a[cogStatlistItem]',
  templateUrl: './statlist-item.component.html',
  styleUrls: ['./statlist-item.component.scss'],
  // Encapsulation turned off so StatlistComponent can style StatlistItemComponent ancestors for flex layout.
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgIf, IconComponent, NgClass],
})
export class StatlistItemComponent {
  /**
   * Add a class to the component for style targeting. This allows shared styles beween standard and attrib selectors.
   */
  @HostBinding('class.cog-statlist-item') cogClass = true;

  /**
   * Can include regular icons as well as stylings for material icons.
   * If no `iconName` provided then `iconClass` will be applied to simple <span>
   */
  @Input() iconClass: string;

  /**
   * Icon name which can be material icon name or helix icon name (prefix with 'helix:')
   */
  @Input() iconName: string;

  /**
   * Icon size
   */
  @Input() iconSize: IconSize;

  /**
   * Statlist label : displayed underneath value
   */
  @Input() label: string;

  /**
   * Draws circle as per specified class
   */
  @Input() labelLegend: string;

  /**
   * Statlist label in HTML: displayed underneath value
   */
  @Input() htmlLabel: string;

  /**
   * Listen to enter keydown on the statlist item.
   *
   * @param target The target element.
   */
  @HostListener('document:keydown.enter', ['$event.target']) onKeydownEnter(target: HTMLElement) {
    if (!target || !this.elementRef?.nativeElement?.contains(target) || target?.getAttribute('href')) {
      // If the target has href (uiSref), then enter keydown will work automatically.
      return;
    }

    target.click();
  }

  constructor(private elementRef: ElementRef) {}
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { MatToolbarModule } from '@angular/material/toolbar';
import { HelixIntlService } from '../../../helix-intl.service';
import { DataIdDirective } from '../../data-id/data-id.directive';
import { HelixAssetUrlPipe } from '../../helix-assets/helix-asset-url/helix-asset-url.pipe';
import { IconComponent } from '../../icon/icon.component';

/**
 * A specialized mat-toolbar for showing a large header for onboarding
 * workflows.
 *
 * @example
 *  <cog-onboarding-toolbar
 *    [productName]="'Cohesity'">
 *    <cog-stepper-header stepper [matStepperRef]="stepper">
 *    </cog-stepper-header>
 *  </cog-onboarding-toolbar>
 */
@Component({
  selector: 'cog-onboarding-toolbar',
  templateUrl: './onboarding-toolbar.component.html',
  styleUrls: ['./onboarding-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    MatToolbarModule,
    MatLegacyButtonModule,
    DataIdDirective,
    MatLegacyTooltipModule,
    IconComponent,
    HelixAssetUrlPipe,
  ],
})
export class OnboardingToolbarComponent {
  /**
   * The visualization to show as the background of the toolbar.
   */
  @Input() backgroundImage: string;

  /**
   * The name of the product. This appears below the Cohesity logo.
   */
  @Input() productName: string;

  /**
   * Emits event on clicking the close button
   */
  @Output() exit = new EventEmitter<void>();

  constructor(public readonly intl: HelixIntlService) {}
}

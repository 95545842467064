import { ChangeDetectorRef, Component, Inject, Input, LOCALE_ID } from '@angular/core';
import { ObjectServiceApi } from '@cohesity/api/v2';
import { CreateVulScanTaskParams, RestoreTasksApiService } from '@cohesity/api/private';
import { SnackBarService } from '@cohesity/helix';
import { TranslateService } from '@ngx-translate/core';
import { get } from 'lodash-es';
import { switchMap } from 'rxjs/operators';
import { AutoDestroyable } from '@cohesity/utils';
import { ComponentExtensionImpl, SnapshotSelectionDetailModel } from 'src/app/plugin-shared';
import { DatePipeWrapper } from 'src/app/shared/pipes';
import { SnapshotParams } from '../model/snapshot-params';
import { SnapshotInfoService } from '../services/snapshot-info.service';

/**
 * This is the plugged-in component for Cyberscan for snapshot.
 */
@Component({
  selector: 'coh-snapshot-info',
  templateUrl: './snapshot-info.component.html',
  styleUrls: ['./snapshot-info.component.scss'],
})
export class SnapshotInfoComponent extends AutoDestroyable
  implements ComponentExtensionImpl<SnapshotSelectionDetailModel> {
  /**
   * Extension data.
   */
  _extensionData: SnapshotSelectionDetailModel;

  /**
   * Sets extension data.
   */
  @Input() set extensionData(extensionData: SnapshotSelectionDetailModel) {
    if (extensionData !== this._extensionData) {
      this._extensionData = extensionData;
      this.getScanInfo();
    }
  }

  /**
   * Gets extension data.
   */
  get extensionData(): SnapshotSelectionDetailModel {
    return this._extensionData;
  }

  /**
   * Parameters for start scan API.
   */
  scanParams: CreateVulScanTaskParams;

  /**
   * Gets scan result data from extentionData.
   *
   * @returns  Scan result data.
   */
  scanInfo: any;

  /**
   * Gets scan result data from extentionData.
   *
   * @returns  Scan result data.
   */
  get name(): string {
    return get(this.scanParams, 'objectId.entity.displayName');
  }

  /**
   * Date pipe used for time display.
   */
  private datePipe: DatePipeWrapper;

  /**
   * Constructor.
   */
  constructor(
    private cdr: ChangeDetectorRef,
    private objectsService: ObjectServiceApi,
    private restoreTasksApi: RestoreTasksApiService,
    private snackBarService: SnackBarService,
    private translate: TranslateService,
    private snapshotInfoService: SnapshotInfoService,
    @Inject(LOCALE_ID) locale: string
  ) {
    super();
    this.datePipe = new DatePipeWrapper(locale);
  }

  /**
   * Gets scan information based on extension data.
   */
  getScanInfo() {
    if (!this.extensionData) {
      return;
    }

    if (this.extensionData.snapshot) {
      const params = SnapshotParams.mapSnapshot(this.extensionData.snapshot);

      this.scanParams = params.scan;
      this.cdr.detectChanges();

      this.snapshotInfoService.bulkGetVulScanResult(params.getResult).pipe(
        this.untilDestroy()
      ).subscribe(scanInfo => {
        this.scanInfo = scanInfo;
        this.cdr.detectChanges();
      });
    } else if (this.extensionData.snapshotId) {
      this.objectsService.GetObjectSnapshotInfo({snapshotId: this.extensionData.snapshotId}).pipe(
        switchMap(snapshot => {
          const params = SnapshotParams.mapSnapshot(snapshot);

          this.scanParams = params.scan;
          this.cdr.detectChanges();
          return this.restoreTasksApi.getVulScanResult(params.getResult);
        })
      ).subscribe(response => {
        this.scanInfo = response[0];
        this.cdr.detectChanges();
      });
    } else if (this.extensionData.object) {
      const params = SnapshotParams.mapProtectedObject(this.extensionData.object);

      this.scanParams = params.scan;
      this.cdr.detectChanges();
      this.restoreTasksApi.getVulScanResult(params.getResult).subscribe(response => {
        this.scanInfo = response[0];
        this.cdr.detectChanges();
      });
    }
  }

  /**
   * Perform scan action.
   */
  startScan() {
    this.restoreTasksApi.createVulScanTask(this.scanParams).subscribe(
      () => {
        const timestamp = get(this.scanParams, 'snapshot.instanceId.jobStartTimeUsecs');
        const time = [
          this.datePipe.transform(timestamp, 'mediumDate'),
          this.datePipe.transform(timestamp, 'shortTime')
        ].join(' ');

        this.snackBarService.open(this.translate.instant('tenableScan.descriptionNg', { name: this.name, time }));
      },
      (error) => this.snackBarService.open(
        error.error && error.error.message ? error.error.message : this.translate.instant('tenableScan.error'),
        'error'
      )
    );
  }

  /**
   * Perform download action.
   */
  download() {
    this.restoreTasksApi.downloadVulScanReport(this.scanInfo.result.reportUrl, this.extensionData.clusterId);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Api } from '@cohesity/api/private';
import { Poller, PollingConfig } from '@cohesity/utils';
import { every } from 'lodash-es';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PassthroughOptionsService } from './passthrough-options.service';

/**
 * The Polling Service.
 */
@Injectable({ providedIn: 'root' })
export class PollingService {
  /**
   * Creates an instance of PollingService.
   *
   * @param   http         The HttpClient service.
   */
  constructor(
    private http: HttpClient,
    private passthroughOptionsService: PassthroughOptionsService,
  ) {}

  /**
   * Generic poller function. Developer must provide the Observable returning
   * iterator function, and the boolean returning termination function.
   *
   * @example
   *   this.poller = PollingService.createPoll(pollerConfig);
   *   ...
   *   this.poller.poller.subscribe(data => console.log(data));
   *   ...
   *   this.poller.finish();
   *
   * @param     config   The poller config.
   * @returns   The Observable with every iteration of the poller.
   */
  createPoll<T>(config: PollingConfig<T>): Poller<T> {
    return new Poller<T>(config);
  }

  /**
   * Helper function to poll Pulse for the given task path. Wraps up Pulse Task
   * polling boilerplate.
   *
   * @example
   *   this.poller = PollingService.pollTask('restore_sql_75989');
   *   ...
   *   this.poller.subscribe(taskVec => this.pulseData = taskVec);
   *
   * @param     taskPaths                     Single or array of taskPaths to
   *                                          fetch.
   * @param     [interval=30]                 The polling interval in seconds.
   * @param     [includeSubTasks=true]        Include subTasks or not.
   * @param     [includeFinishedTasks=true]   Include Finished tasks or not.
   * @param     [includeEventLogs=true]       Specifies whether to include event logs.
   * @param     [fetchLogsMaxLevel=0]         Number of levels till which we need to fetch the event logs for a pulse
   *                                          tree. Note that it is applicable only when includeEventLogs is true.
   * @param     [regionId]                    Region where to poll task from.
   * @param     [clusterId]                   Access cluster ID.
   * @returns   The observable Poller.
   */
  pollTask(
    taskPaths: string|string[],
    interval = 30,
    includeSubTasks = true,
    includeFinishedTasks = true,
    includeEventLogs = true,
    fetchLogsMaxLevel =  0,
    regionId?,
    clusterId?: number
  ): Poller<any[]> {
    taskPaths = [].concat(taskPaths).join('&taskPathVec=');
    const taskUrl = Api.private(
      `progressMonitors?taskPathVec=${taskPaths}` +
      `&excludeSubTasks=${!includeSubTasks}` +
      `&includeFinishedTasks=${includeFinishedTasks}` +
      `&includeEventLogs=${includeEventLogs}` +
      `&fetchLogsMaxLevel=${fetchLogsMaxLevel}`
    );

    let options;

    const requestHeaders = this.passthroughOptionsService.requestHeaders;
    options = requestHeaders ? {headers: requestHeaders} : undefined;
    if (!options && regionId) {
      options = {
        params: {regionId},
      };
    }

    if (clusterId) {
      if (!options) {
        options = {};
      }

      if (!options.headers) {
        options.headers = {};
      }

      options.headers.clusterId = String(clusterId);
    }

    const taskPollConfig: PollingConfig<any[]> = {
      interval: interval,
      iterator: (): Observable<any[]> =>
        this.http.get<any>(taskUrl, options).pipe(map(data => (data as any).resultGroupVec)),
      shouldContinue: (groupVec): boolean =>
        // False when all requested tasks are completed.
         !every(groupVec, 'taskVec[0].progress.endTimeSecs')
      ,
    };

    return this.createPoll(taskPollConfig);
  }
}

import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  Optional,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { LegacyDateAdapter as DateAdapter } from '@angular/material/legacy-core';
import {
  MAT_LEGACY_FORM_FIELD as MAT_FORM_FIELD,
  MatLegacyFormField as MatFormField,
  MatLegacyFormFieldControl as MatFormFieldControl,
} from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { Moment } from 'moment';
import { Subject } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { OnChange, OnTouched } from '../../util';
import { DataIdDirective } from '../data-id/data-id.directive';
import { DateAdapterUtils } from '../date-utils/public_api';

/** The MatFormFieldControl controlType name which should be same as component selector */
const controlType = 'cog-time-picker';

@Component({
  selector: 'cog-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: TimePickerComponent }],
  standalone: true,
  imports: [MatLegacyInputModule, DataIdDirective],
})
export class TimePickerComponent<D> implements ControlValueAccessor, MatFormFieldControl<D>, OnDestroy {
  /**
   * Whether to include seconds in the time input.
   */
  @Input() showSeconds = false;

  /**
   * The time format to use.
   */
  get timeFormat(): string {
    return this.showSeconds ? 'HH:mm:ss' : 'HH:mm';
  }

  /**
   * The selected time for HTML time input element.
   */
  get selectedTime(): string {
    return this.selected?.format(this.timeFormat) || '';
  }

  /**
   * The selected time moment date.
   * NOTE: keeping it as moment date instead of D because MatNativeDateModule is lacking
   *       formatter support which is required to get the selected time string for HTML time input element.
   */
  selected: Moment;

  /**
   * Event emitted with new time on time selection.
   */
  @Output() changes = new EventEmitter<D>();

  // ----------- MatFormFieldControl properties implementation --------------

  controlType = controlType;

  @HostBinding('id') id = `${controlType}-${uuid()}`;

  stateChanges = new Subject<void>();

  focused = false;

  touched = false;

  get empty() {
    return !this.value;
  }

  @HostBinding(`class.${controlType}-floating`)
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input()
  get placeholder(): string {
    return this._placeholder || this.timeFormat;
  }

  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  private _placeholder: string;

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): D | null {
    return this._value;
  }

  set value(selected: D | null) {
    this._value = this.dateAdapter.getValidDateOrNull(selected);
    this.selected = this._value ? this.dateAdapterUtils.dateToMoment(this._value) : null;
    this.stateChanges.next();
  }

  private _value: D;

  get errorState(): boolean {
    return !this.value && this.touched;
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-describedby') userAriaDescribedBy: string;

  /** The input time element ref */
  @ViewChild('timeInput') timeInput: ElementRef<HTMLInputElement>;

  constructor(
    @Optional() @Inject(MAT_FORM_FIELD) public formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl,
    private dateAdapter: DateAdapter<D>,
    private dateAdapterUtils: DateAdapterUtils<D>,
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
  }

  /**
   * Handles time selection.
   *
   * @param   selectedTime   The selected time from HTML input element in HH:mm:ss or HH:mm format.
   */
  selectTime(selectedTime: string) {
    const [hours, minutes, seconds] = selectedTime.split(':').map(Number);
    const selected = this.selected || this.dateAdapterUtils.dateToMoment(this.dateAdapter.today());
    this.selected = selected.set({ hours, minutes, seconds });

    // output selected time of D type.
    const output = this.dateAdapterUtils.momentToDate(this.selected);
    this.onChange(output);
  }

  /**
   * Set focus-in status on inner element focus.
   */
  onFocusIn() {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  /**
   * Set focus-out status on inner element blur.
   */
  onFocusOut() {
    this.touched = true;
    this.focused = false;
    this.onTouched();
    this.stateChanges.next();
  }

  // ----------- MatFormFieldControl properties implementation --------------

  setDescribedByIds(ids: string[]) {
    this.timeInput?.nativeElement?.setAttribute('aria-describedby', ids.join(' '));
  }

  /**
   * Implemented as part of MatFormFieldControl.
   */
  onContainerClick() {
    // Do not re-focus the input element if the element is already focused. Otherwise it can happen
    // that someone clicks on a time input and the cursor resets to the "hours" field while the
    // "minutes" field was actually clicked. See: https://github.com/angular/components/issues/12849
    if (!this.focused) {
      this.timeInput?.nativeElement?.focus();
    }
  }

  /**
   * Update the view on model changes is request programmatic via forms API.
   *
   * This method is called by the forms API to write to the view when programmatic changes from model to view are
   * requested.
   *
   * @param   time   The new date time value..
   */
  writeValue(time: D | null) {
    this.value = time;
  }

  /**
   * This method is called by the forms API on initialization to update the form
   * model when values propagate from the view to the model.
   */
  registerOnChange(fn: OnChange<D>) {
    const orgOnChange = this.onChange;

    this.onChange = (...args) => {
      // calling the original on changes callback defined below which will emit the changes output event.
      orgOnChange.call(this, ...args);

      // calling to on changes fn provided by FormControl.
      fn.call(this, ...args);
    };
  }

  /**
   * Registers a callback function is called by the forms API on initialization
   * to update the form model on blur.
   */
  registerOnTouched(fn: OnTouched) {
    this.onTouched = fn;
  }

  /**
   * The placeholder method populated by forms API registerOnChange method which
   * is used to update changes from view to modal.
   */
  onChange: OnChange<D> = time => {
    this.changes.emit(time);
  };

  /**
   * The placeholder method populated by forms API registerOnTouched method which
   * is used to mark a form field should be considered blurred or "touched".
   */
  onTouched: OnTouched = () => {};

  /**
   * Function that is called by the forms API when the control status changes to
   * or from 'DISABLED'. Depending on the status, it enables or disables the
   * appropriate DOM element.
   */
  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}

import { UntypedFormControl, ValidatorFn } from '@angular/forms';
import { REGEX_FORMATS } from '@cohesity/utils';

/**
 * A custom validator function for to validate cluster name.
 *
 * @param control - The UntypedFormControl instance to be validated.
 *
 * @returns An object with the validation error key 'invalidClusterName'
 * if the control value does not match the cluster name regex pattern.
 */
export const ClusterNameValidator: ValidatorFn = (control: UntypedFormControl) => {
  if (!control.value) {
    return { invalidClusterName: true };
  }

  const isClusterName = REGEX_FORMATS.clusterName.test(control.value || '');
  return isClusterName ? null : { invalidClusterName: true };
};

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyNativeDateModule as MatNativeDateModule } from '@angular/material/legacy-core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';

import { DataIdModule } from '../data-id';
import { DatePipesModule } from '../date-pipe';
import { IconModule } from '../icon';
import { CalendarHeaderComponent } from './calendar-header/calendar-header.component';
import { CalendarComponent } from './calendar.component';
import { CalendarDirective } from './calendar.directive';
import { CalendarConfig } from './config/calendar.config';
import { NativeDateCalendarConfig } from './config/native-date-calendar.config';

const components = [
  CalendarComponent,
  CalendarDirective,
  CalendarHeaderComponent,
];

@NgModule({
  imports: [
    CommonModule,
    DataIdModule,
    DatePipesModule,
    IconModule,
    MatButtonModule,
    MatDatepickerModule,
    MatIconModule,
    MatNativeDateModule,
    ...components,
  ],
  providers: [
    {
      provide: CalendarConfig,
      useClass: NativeDateCalendarConfig,
    },
  ],
  exports: [...components],
})
export class CalendarModule {}

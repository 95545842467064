import { Injectable } from '@angular/core';
import {
  flagEnabled,
  hasClusters,
  IrisContextService,
  isDGaaSUser,
  isDmsUser,
  isDraasUser,
  isGaiaUser,
  isHeliosTenantUser,
  isIbmBaaSEnabled,
  isMcm,
  isMcmOnPrem,
  isMcmSaaS,
  isRestrictedUser,
  isSizerUser,
  isTenantUser,
} from '@cohesity/iris-core';
import {
  ApplicationToSourceEnvironment,
  CanAccessEnvBase,
  EnvGroup,
  envGroups,
  Environment,
  EnvItems,
  JobEnvironments,
  JobToSourceEnvironment,
} from '@cohesity/iris-shared-constants';
import {
  CanAccessEnv,
  EnvAccessContext,
  EnvAccessResult,
  Workflow,
  WorkflowAccessContext,
} from 'src/app/app-module.config';
import { AppServiceManagerService } from 'src/app/app-services';
import { HostType } from 'src/app/shared/constants/host-type-groups.constants';

import { CustomizationService } from './customization.service';
import { UserService } from './user.service';
import { WorkflowAccessContextService } from './workflow-access-context.service';

// Different types of users, each type can have its own access conditions
export type UserType = 'cluster' | 'tenant' | 'dms' | 'heliosTenant' | 'bifrostTenant';

/**
 * Adaptor access service holds the conditions and utility methods to determine whether an environment is accessible by
 * logged-in user or not for the provided workflow like backupAndRecovery, clone etc.
 */
@Injectable({
  providedIn: 'root'
})
export class AdaptorAccessService {
  /**
   * Holds the conditions used to determine whether a logged-in user can access the environment or not.
   */
  readonly envCanAccessFn: CanAccessEnvBase<CanAccessEnv>;

  constructor(
    private ctxService: WorkflowAccessContextService,
    private customizationService: CustomizationService,
    private irisCtx: IrisContextService,
    private userService: UserService,
    private appServiceManager: AppServiceManagerService,
  ) {
    this.envCanAccessFn = {
      kAcropolis: this.canAccessAcropolis,
      kAD: {
        host: {
          kPhysical: this.canAccessADHostPhysical,
        },
      },
      kAgent: this.canAccessAgent,
      kAuroraSnapshotManager: this.canAccessAuroraSnapshotManager,
      kAWS: this.canAccessAWS,
      kAWSNative: this.canAccessAWSNative,
      kAWSSnapshotManager: this.canAccessAWSSnapshotManager,
      kAwsRDSPostgresBackup: this.canAccessAwsRdsPostgres,
      kAwsRDSPostgres: this.canAccessAwsRdsPostgres,
      kAwsAuroraPostgres: this.canAccessAwsRdsPostgres,
      kAwsDynamoDB: this.canAccessAwsDynamoDb,
      kAwsS3: this.canAccessAwsS3,
      kAzure: this.canAccessAzure,
      kAzureEntraID: this.canAccessAzureEntraId,
      kEntraID: this.canAccessAzureEntraId,
      kAzureSQL: this.canAccessAzureSQL,
      kAzureNative: this.canAccessAzureNative,
      kAzureSnapshotManager: this.canAccessAzureSnapshotManager,
      kCassandra: this.canAccessCassandra,
      kCouchbase: this.canAccessCouchbase,
      kSfdc: this.canAccessSfdc,
      kMongoDB: this.canAccessMongoDB,
      kElastifile: this.canAccessElastifile,
      kExchange: {
        host: {
          kVMware: this.canAccessExchangeHostVMware,
          kPhysical: this.canAccessExchangeHostPhysical,
        },
      },
      kFlashBlade: this.canAccessFlashBlade,
      kGCP: this.canAccessGCP,
      kGCPNative: this.canAccessGCPNative,
      kGenericNas: this.canAccessGenericNas,
      kUDA: this.canAccessUda,
      kGPFS: this.canAccessGPFS,
      kHBase: this.canAccessHBase,
      kHdfs: this.canAccessHdfs,
      kHive: this.canAccessHive,
      kHyperFlex: this.canAccessHyperFlex,
      kHyperV: {
        entity: {
          kSCVMMServer: this.canAccessHyperVEntitySCVMMServer,
          kStandaloneHost: this.canAccessHyperVEntityStandaloneHost,
          kStandaloneCluster: this.canAccessHyperVEntityStandaloneCluster,
        },
      },
      kHyperVVSS: this.canAccessHyperVVSS,
      kIbmFlashSystem: this.canAccessIbmFlashSystem,
      kIsilon: this.canAccessIsilon,
      kKubernetes: this.canAccessKubernetes,
      kKVM: this.canAccessKVM,
      kNetapp: {
        entity: {
          kCluster: this.canAccessNetappEntityCluster,
          kVserver: this.canAccessNetappEntityVserver,
        },
      },
      kNimble: this.canAccessNimble,
      kO365: {
        entity: {
          kApplication: this.defaultCanAccessFn,
          kDomain: this.defaultCanAccessFn,
          kGraphUser: this.defaultCanAccessFn,
          kGroup: this.defaultCanAccessFn,
          kGroups: this.canAccessO365EntityGroups,
          kMailbox: this.canAccessO365EntityMailbox,
          kMailboxCSM: this.canAccessO365EntityMailbox,
          kO365Exchange: this.defaultCanAccessFn,
          kO365OneDrive: this.defaultCanAccessFn,
          kO365Sharepoint: this.defaultCanAccessFn,
          kOneDrive: this.canAccessO365EntityOneDrive,
          kOneDriveCSM: this.canAccessO365EntityOneDrive,
          kOutlook: this.defaultCanAccessFn,
          kPublicFolder: this.defaultCanAccessFn,
          kPublicFolders: this.canAccessO365EntityPublicFolders,
          kRootPublicFolder: this.defaultCanAccessFn,
          kSharePoint: this.canAccessO365EntitySharePoint,
          kSharePointCSM: this.canAccessO365EntitySharePoint,
          kSite: this.defaultCanAccessFn,
          kSites: this.defaultCanAccessFn,
          kTeam: this.defaultCanAccessFn,
          kTeams: this.canAccessO365EntityTeams,
          kUser: this.defaultCanAccessFn,
          kUsers: this.defaultCanAccessFn,
        },
      },
      kO365Exchange: this.canAccessO365Exchange,
      kO365ExchangeCSM: this.canAccessO365Exchange,
      kO365Group: this.canAccessO365Group,
      kO365OneDrive: this.canAccessO365OneDrive,
      kO365OneDriveCSM: this.canAccessO365OneDrive,
      kO365Outlook: this.canAccessO365Outlook,
      kO365PublicFolders: this.canAccessO365PublicFolders,
      kO365Sharepoint: this.canAccessO365Sharepoint,
      kO365SharepointCSM: this.canAccessO365Sharepoint,
      kO365Teams: this.canAccessO365Teams,
      kOracle: {
        host: {
          kPhysical: this.canAccessOracleHostPhysical,
        },
      },
      kPhysical: this.canAccessPhysical,
      kPhysicalFiles: this.canAccessPhysicalFiles,
      kPure: this.canAccessPure,
      kRDSSnapshotManager: this.canAccessRDSSnapshotManager,
      kRemoteAdapter: this.canAccessRemoteAdapter,
      kSAPHANA: this.canAccessSapHana,
      kSQL: {
        host: {
          kVMware: this.canAccessSQLHostVMware,
          kPhysical: this.canAccessSQLHostPhysical,
          kSQL: this.canAccessSQLHostSQL,
        },
      },
      kStorageSnapshotProvider: this.canAccessStorageSnapshotProvider,
      kVCD: this.canAccessVCD,
      kView: this.canAccessView,
      kVMware: {
        entity: {
          kVCenter: this.canAccessVMwareEntityVCenter,
          kStandaloneHost: this.canAccessVMwareEntityStandaloneHost,
          kvCloudDirector: this.canAccessVMwareEntityvCloudDirector,
        },
      },
      kPuppeteer: this.canAccessPuppeteer,
      kMongoDBPhysical: this.canAccessMongoDBPhysical
    };
  }

  /**
   * Return the environment access context.
   *
   * @return  The environment access context object.
   */
  getEnvAccessContext(): EnvAccessContext {
    const ctx = this.irisCtx.irisContext;
    return {
      // For backward compatibility b/w angular & AJS keeping the all caps key for FEATURE_FLAGS.
      FEATURE_FLAGS: this.irisCtx.irisContext.featureFlags as { [key: string]: boolean },
      BIFROST_CAPABILITIES: this.userService.bifrostCapabilities,
      CLUSTER_CAPABILITIES: this.userService.clusterCapabilities,
      isClusterUser: !isMcm(ctx) || hasClusters(ctx),
      isTenantUser: isTenantUser(ctx),
      isHeliosTenantUser: this.userService.isHeliosTenantUser(),
      isBifrostTenantUser: this.userService.isBifrostTenantUser(),
      isDGaaSUser: isDGaaSUser(ctx),
      isGaiaUser: isGaiaUser(ctx),
      isDmsUser: isDmsUser(ctx),
      isDraasUser: isDraasUser(ctx),
      isRestrictedUser: isRestrictedUser(ctx),
      isHelios: isMcm(ctx),
      isHeliosOnPrem: isMcmOnPrem(ctx),
      isHeliosSaaS: isMcmSaaS(ctx),
      isSizerUser: isSizerUser(ctx),
      isIbmBaas: isIbmBaaSEnabled(ctx),
      irisContext: ctx,
      activeScope: this.appServiceManager.getScopeName()
    };
  }

  /**
   * Return the workflow context used to determine whether a logged-in user can access the provided environment's
   * workflow or not.
   *
   * @param     envAccessCtx  custom env access context
   * @returns  The workflow context object.
   */
  getWorkflowAccessContext(envAccessCtx?: EnvAccessContext): WorkflowAccessContext {
    // use cached workflow access context.
    if (!envAccessCtx && this.ctxService.workflowAccessContext) {
      return this.ctxService.workflowAccessContext;
    }

    const ctx = envAccessCtx || this.getEnvAccessContext();
    let result: Partial<WorkflowAccessContext> = {};
    const workflows = Object.values(Workflow);

    // loop over all the workflows and determine whether environment's access.
    workflows.forEach(workflow => {
      const envAccessResult: Partial<EnvAccessResult> = {};

      this.getSortedEnvList().forEach(environment => {
        const envCanAccessEnvMap = this.envCanAccessFn[environment];

        if (typeof envCanAccessEnvMap === 'object') {
          const key = envCanAccessEnvMap.entity ? 'entity' : 'host';

          envAccessResult[<string>environment] = { [key]: {} };
          Object.keys(envCanAccessEnvMap[key]).forEach(innerEnv => {
            envAccessResult[environment][key][innerEnv] = !!(
              // checking whether the application host env is also accessible eg. for kSQL checking whether its host
              // like kPhysical or kVMware is accessible or not.
              (key === 'host' ?
                this.canAccessEnv(<CanAccessEnvBase<boolean>>envAccessResult, <Environment>innerEnv) : true) &&
              envCanAccessEnvMap[key][innerEnv](ctx, workflow)
            );
          });

        } else if (typeof envCanAccessEnvMap === 'function') {
          const canAccessJobSource: boolean = this.isJobEnv(environment) ?
            JobToSourceEnvironment[environment].some(env =>
              this.canAccessEnv(<EnvAccessResult>envAccessResult, env)
            ) : true;
          const canAccessApplicationHost: boolean = this.isApplicationEnv(environment) ?
            ApplicationToSourceEnvironment[environment].some(env =>
              this.canAccessEnv(<EnvAccessResult>envAccessResult, env)
            ) : true;

          envAccessResult[<string>environment] = (
            canAccessJobSource &&
            canAccessApplicationHost &&
            envCanAccessEnvMap(ctx, workflow)
          );
        }
      });

      result = { ...result, [workflow]: <EnvAccessResult>envAccessResult };
    });

    if (!envAccessCtx) {
      this.ctxService.workflowAccessContext = <WorkflowAccessContext>result;
    }

    return <WorkflowAccessContext>result;
  }

  /**
   * Return's the sorted list of environments such that root sources followed by application sources followed
   * by job types.
   *
   * @returns  The sorted list of environments.
   */
  getSortedEnvList(): Environment[] {
    return Object.keys(this.envCanAccessFn)
      // bucketizeing the environment such that 1st group contains backup source env, 2nd group application env and
      // 3rd group contains job env.
      .reduce((acc: Environment[][], env: Environment) => {
        const index = this.isJobEnv(env) ? 2 : this.isApplicationEnv(env) ? 1 : 0;

        if (!acc[index]) {
          acc[index] = [];
        }

        acc[index].push(env);
        return acc;
      }, [])

      // flattening the nested array.
      .reduce((acc, envGroup: Environment[]) => acc.concat(envGroup), []);
  }

  /**
   * Determines whether the provided environment is accessible or not with special handling for application, job type
   * and environment having entities are source eg. kVMware's kvCenter, kvCloudDirector etc.
   *
   * @param   EnvAccessResult  The partially computed environment's access result.
   * @param   environment   The environment to check.
   * @return  Return true if environment is accessible else false.
   */
  canAccessEnv(envAccessResult: EnvAccessResult, environment: Environment): boolean {
    if (flagEnabled(this.irisCtx.irisContext, 'uiCustomization') &&
      this.customizationService.isHiddenEnvironment(environment)) {
      return false;
    }

    if (this.isJobEnv(environment)) {
      return envAccessResult[environment] &&
        // ensure the job type's backup source type is accessible.
        JobToSourceEnvironment[environment].some(env => envAccessResult[env]);
    } else if (typeof envAccessResult[environment] === 'object') {
      const key = Object.prototype.hasOwnProperty.call(envAccessResult[environment], 'entity') ? 'entity' : 'host';

      return Object.keys(envAccessResult[environment][key]).some(innerEnv =>
        envAccessResult[environment][key][innerEnv] &&
          // ensure the host type backup source environment is accessible.
          (key === 'host' ? this.canAccessEnv(envAccessResult, <Environment>innerEnv) : true));
    }

    return <boolean>envAccessResult[environment];
  }

  /**
   * Determines whether the provided environment is of application type.
   *
   * @param   environment   The environment to check.
   * @return  Return true if environment is of application type.
   */
  isApplicationEnv(environment: Environment): boolean {
    return Object.prototype.hasOwnProperty.call(ApplicationToSourceEnvironment, environment);
  }

  /**
   * Determines whether the provided environment is of job type.
   *
   * @param   environment   The environment to check.
   * @return  Return true if environment is of job type.
   */
  isJobEnv(environment: Environment): boolean {
    return !!JobEnvironments.find(jobEnv => jobEnv === environment);
  }

  /**
   * Filter out non accessible environments by teanant for the provided workflow.
   *
   * @param   environment   The environments.
   * @param   workflow      The workflow.
   * @return  Return the accessible environments.
   */
  filterByTenantAccessibleEnv(
    environments: Environment[],
    workflow?: Workflow,
    inputCtx: Partial<Pick<EnvAccessContext, 'isBifrostTenantUser' | 'BIFROST_CAPABILITIES'>> = {}
  ): Environment[] {
    const ctx = { ...this.getEnvAccessContext(), ...inputCtx, isTenantUser: true };
    const workflowAccessContext = this.getWorkflowAccessContext(ctx);
    return this.filterByAccessibleEnv(environments, workflow, workflowAccessContext);
  }

  /**
   * Filter out non accessible environments for the provided workflow.
   *
   * @param   environment   The environments.
   * @param   workflow      The workflow.
   * @param   workflowCtx   Custom workflow context
   * @return  Return the accessible environments.
   */
  filterByAccessibleEnv(
    environments: Environment[],
    workflow?: Workflow,
    workflowCtx?: WorkflowAccessContext
  ): Environment[] {
    const envItems = (environments || []).map(env => <EnvItems>{ environment: env });

    return this.filterByAccessibleEnvItems(
      envItems, workflow, workflowCtx).map(envItem => envItem.environment);
  }

  /**
   * Filter out non accessible environment items for the provided workflow.
   *
   * @param   environment   The environments.
   * @param   workflow      The workflow.
   * @param   workflowCtx   Custom workflow context
   * @return  Return the accessible environments items.
   */
  filterByAccessibleEnvItems(
    envItems: EnvItems[],
    workflow?: Workflow,
    workflowCtx?: WorkflowAccessContext
  ): EnvItems[] {
    workflowCtx = workflowCtx || this.getWorkflowAccessContext();
    return (envItems || []).filter(({ environment, entity, hostEnvironment }) => {

      const envAccessCtx = workflowCtx[workflow || Workflow.backupAndRecovery];
      let isEnabledByUser = true;
      let isEnvAccessible = true;

      if (typeof envAccessCtx[environment] === 'object' && (entity || hostEnvironment)) {
        const key = Object.prototype.hasOwnProperty.call(envAccessCtx[environment], 'entity') ? 'entity' : 'host';

        isEnabledByUser = flagEnabled(this.irisCtx.irisContext, 'uiCustomization') ?
          !this.customizationService.isHiddenEnvironment(entity || hostEnvironment) : true;

        isEnvAccessible = envAccessCtx[environment][key][entity || hostEnvironment] &&
          (key === 'host' ? this.canAccessEnv(envAccessCtx, hostEnvironment) : true);

      } else {

        isEnabledByUser = flagEnabled(this.irisCtx.irisContext, 'uiCustomization') ?
          !this.customizationService.isHiddenEnvironment(environment) : true;

        isEnvAccessible = this.canAccessEnv(envAccessCtx, environment);

      }

      return isEnabledByUser && isEnvAccessible;
    });
  }

  /**
   * Determines whether any one of the environments is accessible by the user for the provided workflow.
   *
   * @example
   * import { recoveryGroup } from 'src/app/shared/constants';
   *
   * const canAccessNasRecovery = (ctx) => {
   *   return ctx.RESTORE_MODIFY && ctx.canAccessSomeEnv(recoveryGroup.nas);
   * };
   *
   * const canAccessVmRecovery = (ctx) => {
   *   return ctx.RESTORE_MODIFY && ctx.canAccessSomeEnv(recoveryGroup.vm);
   * };
   *
   * const canAccessVmClone = (ctx) => {
   *   return ctx.CLONE_MODIFY && ctx.canAccessSomeEnv(recoveryGroup.vm);
   * };
   *
   * @param   environment   The environments.
   * @param   workflow      The workflow.
   * @return  Return true if any one of the environments is accessible else false.
   */
  canAccessSomeEnv(environments: Environment[], workflow?: Workflow): boolean {
    const accessibleEnv = this.filterByAccessibleEnv(environments, workflow);
    return !!accessibleEnv.length;
  }

  /**
   * Determines whether any one of the environment items is accessible by the user for the provided workflow.
   *
   * @example
   * import { hypervisorSources, nasSources } from 'src/app/shared/constants';
   *
   * const canAccessHypervisor = (ctx) => {
   *   return ctx.PROTECTION_SOURCE_MODIFY && ctx.canAccessSomeEnvItems(hypervisorSources);
   * };
   *
   * const canAccessNasSources = (ctx) => {
   *   return ctx.PROTECTION_SOURCE_MODIFY && ctx.canAccessSomeEnvItems(nasSources);
   * };
   *
   * @param   environment   The environments.
   * @param   workflow      The workflow.
   * @return  Return true if any one of the environment items is accessible else false.
   */
  canAccessSomeEnvItems(envItems: EnvItems[], workflow?: Workflow): boolean {
    const accessibleEnvItems = this.filterByAccessibleEnvItems(envItems, workflow);
    return !!accessibleEnvItems.length;
  }

  /**
   * Get the list of environment group values available for the current user.
   *
   * @param initialEnvGroup (optional) a GroupEnum with the keys being the environments we want to use.
   * @returns Environment group values.
   */
  getEnvironmentGroups(initialEnvGroups = envGroups): EnvGroup[] {
    if (isHeliosTenantUser(this.irisCtx.irisContext)) {
      return ['vms'];
    }

    const result = Object.keys(initialEnvGroups) as EnvGroup[];
    return result.filter(groupName => this.canAccessSomeEnv(initialEnvGroups[groupName]));
  }

  /**
   * Provides a list of accessible environments for the provided env group.
   *
   * @param group the envgroup to return environments for.
   * @param initialEnvGroups (optional) a GroupEnum from which the environments would be fetched.
   * @returns  list of (acessible) environments belonging to the env group
   */
  getEnvironmentGroupEnvironments(group: EnvGroup, initialEnvGroups = envGroups): Environment[] {
    // NOTE: using canAccessSomeEnv because I don't understand the first parameter for canAccessEnv.
    return initialEnvGroups[group].filter(env => this.canAccessSomeEnv([env]));
  }

  /**
   * canAccessWorkflow method is used to write complex conditions for an adaptors different workflows.
   *
   * NOTE: browsing, clone, kupier and data migration will be supported when backup and recovery is supported this is
   *       how magneto works hence bringing that construct here for example Isilon adaptor having one flag to
   *       enable/disable backup and recovery(isilonSourceEnabled) and another for browsing(nasBrowse) so even though
   *       browsing is supported but backup and recovery is not supported which means browsing is not supported.
   *
   * @example
   *   const canAccessOracle = (ctx, workflow) = {
   *     this.canAccessWorkflow(ctx, workflow, {
   *       // allow workflows supported by the cluster users.
   *       cluster: true,
   *
   *       // assuming by default non of the workflows are supported for not specified users.
   *       // tenant: false,
   *       // heliosTenant: false,
   *     })
   *
   * @param    ctx        The ctx.
   * @param    workflow   The workflow.
   * @param    options    The options.
   * @returns  Return true if a workflow is supported else false.
   */
  private canAccessWorkflow(ctx: EnvAccessContext, workflow: Workflow, options: {
      [K in UserType]?: Partial<Record<Workflow, boolean>> | boolean;
  } | boolean): boolean {
    // allow access to all users & workflow.
    if (typeof options === 'boolean') {
      return options;
    }

    const guardrailEnabled = ctx.FEATURE_FLAGS.irisExecMultiTenancyGuardrailEnabled;
    const bifrostEnabled = ctx.FEATURE_FLAGS.irisExecBifrostCapabilityFrameworkEnabled;
    const userTypes: [UserType, boolean][] = [
      ['cluster', true],
      ['tenant', guardrailEnabled && ctx.isTenantUser],
      ['heliosTenant', guardrailEnabled && ctx.isHeliosTenantUser],
      ['bifrostTenant', guardrailEnabled && bifrostEnabled && ctx.isBifrostTenantUser],
    ];

    return userTypes.reduce((canAccess, [userType, condition]) => {
      // Helios Tenants should see the same options as a regular tenant when dropped down
      // to a cluster
      if (userType === 'heliosTenant') {
        userType = 'tenant';
      }
      return canAccess && (!condition || this.calculateWorkflowAccess(options[userType], workflow));
    }, true);
  }

  /**
   * calculateWorkflowAccess method is used to calculate workflow access for a specific adaptor
   *  for a specific workflow, based on adaptor access options
   *
   * @param    options    The Options.
   * @param    workflow   The workflow.
   * @returns  Return true if a workflow is supported else false.
   */
  private calculateWorkflowAccess(
    options: Partial<Record<Workflow, boolean>> | boolean,
    workflow: Workflow
  ) {
    const option = options || false;

    if (typeof option === 'boolean') {
      return option;
    }

    switch (workflow) {
      case Workflow.kuiper:
        // you can't use kuiper workflows if you can't take the backup.
        return !!(option.backupAndRecovery && option.kuiper);
      case Workflow.dataMigration:
        // you can't migrate data from a NAS source to a view if you can't take the backup because internally magneto
        // keeps the snapshot in the views.
        return !!(option.backupAndRecovery && option.dataMigration);
      case Workflow.dbMigration:
        // you can't migrate database if you can't take the backup because internally magneto keeps the snapshot in the
        // views.
        return !!(option.backupAndRecovery && option.dbMigration);
      case Workflow.clone:
        // you can't clone a snapshot if you can't take the backup i.e. taking a snapshot.
        return !!(option.backupAndRecovery && option.clone);
      case Workflow.browsing:
        // you can't browse anything if you can't take the backup.
        return !!(option.backupAndRecovery && option.browsing);
      case Workflow.backupAndRecovery:
      default:
        return !!option.backupAndRecovery;
    }
  }

  /**
   * CanAccessEnv callback for Acropolis adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private defaultCanAccessFn: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: false,
        browsing: false,
        clone: false,
        kuiper: false,
      },
    });

  /**
   * CanAccessEnv callback for Acropolis adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessAcropolis: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: true,
        browsing: true,
        clone: ctx.FEATURE_FLAGS.acropolisClone,
        kuiper: ctx.FEATURE_FLAGS.testDevEnabled,
      },
      tenant: {
        backupAndRecovery: ctx.FEATURE_FLAGS.tenantProtectionSourcekAcropolisEnabled,
      },
    });

  /**
   * CanAccessEnv callback for AD Host Physical adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessADHostPhysical: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: ctx.FEATURE_FLAGS.activeDirectorySourceEnabled,
        browsing: true,
      },
      tenant: {
        backupAndRecovery: ctx.FEATURE_FLAGS.tenantProtectionSourcekADhostkPhysicalEnabled,
        browsing: true,
      },
    });

  /**
   * CanAccessEnv callback for Agent adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessAgent: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: true,
      tenant: {
        backupAndRecovery: ctx.FEATURE_FLAGS.tenantProtectionSourcekAgentEnabled,
      },
    });

  /**
   * CanAccessEnv callback for AWS Aurora adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessAuroraSnapshotManager: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: ctx.FEATURE_FLAGS.ngProtectionGroupAuroraModify,
      },
      tenant: {
        backupAndRecovery: ctx.FEATURE_FLAGS.ngProtectionGroupAuroraModify &&
          ctx.FEATURE_FLAGS.tenantProtectionSourcekAWSEnabled,
      },
    });

  /**
   * CanAccessEnv callback for AWS adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessAWS: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: ctx.FEATURE_FLAGS.awsSourceRegistration,
        browsing: true,
        kuiper: ctx.FEATURE_FLAGS.testDevEnabled,
      },
      tenant: {
        backupAndRecovery: ctx.FEATURE_FLAGS.tenantProtectionSourcekAWSEnabled,
      },
    });


  /**
   * CanAccessEnv callback for AWSNative adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessAWSNative: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: true,
        browsing: true,
      },
      tenant: {
        backupAndRecovery: ctx.FEATURE_FLAGS.tenantProtectionSourcekAWSEnabled,
      },
    });


  /**
   * CanAccessEnv callback for AWSSnapshotManager adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessAWSSnapshotManager: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: ctx.FEATURE_FLAGS.awsSnapshotManager,
      },
      tenant: {
        backupAndRecovery: ctx.FEATURE_FLAGS.tenantProtectionSourcekAWSEnabled,
      },
    });

  /**
   * CanAccessEnv callback for Aws RDS/Aurora adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessAwsRdsPostgres: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: false,
      },
      tenant: {
        backupAndRecovery: ctx.FEATURE_FLAGS.awsPostgresDbAuthorization,
      },
    });

  /**
   * CanAccessEnv callback for AwsDynamoDb adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessAwsDynamoDb: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: ctx.FEATURE_FLAGS.awsDynamoDbEnabled,
      },
      tenant: {
        backupAndRecovery: ctx.FEATURE_FLAGS.awsDynamoDbEnabled,
      },
    });

  /**
   * CanAccessEnv callback for AwsS3 adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
   private canAccessAwsS3: CanAccessEnv = (ctx, workflow) =>
   this.canAccessWorkflow(ctx, workflow, {
     cluster: {
       backupAndRecovery: (ctx.FEATURE_FLAGS.dmsAwsS3Protection || ctx.FEATURE_FLAGS.s3OnPremProtection),
     },
     tenant: {
       backupAndRecovery: ctx.FEATURE_FLAGS.tenantProtectionSourcekAWSEnabled,
     },
   });

  /**
   * CanAccessEnv callback for Azure adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessAzure: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: ctx.activeScope === 'dms' ? ctx.FEATURE_FLAGS.dmsAzureRegistration
          : ctx.FEATURE_FLAGS.azureSourceRegistration,
        browsing: true,
        kuiper: ctx.FEATURE_FLAGS.testDevEnabled,
      },
      tenant: {
        backupAndRecovery: ctx.FEATURE_FLAGS.tenantProtectionSourcekAzureEnabled,
      },
    });


  /**
   * CanAccessEnv callback for AzureNative adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessAzureNative: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: true,
        browsing: true,
      },
    });

  /**
   * CanAccessEnv callback for AzureNative adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessAzureSQL: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: ctx.activeScope === 'dms' ? ctx.FEATURE_FLAGS.dmsAzureRegistration
          : ctx.FEATURE_FLAGS.azureSourceRegistration,
        browsing: true,
        kuiper: ctx.FEATURE_FLAGS.testDevEnabled,
      },
      tenant: {
        backupAndRecovery: ctx.FEATURE_FLAGS.dmsAzureSqlWorkload
      },
    });

  /**
   * CanAccessEnv callback for Azure EntraId adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessAzureEntraId: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: ctx.activeScope === 'dms' ? ctx.FEATURE_FLAGS.dmsAzureRegistration
          : ctx.FEATURE_FLAGS.azureSourceRegistration,
        browsing: true,
        kuiper: ctx.FEATURE_FLAGS.testDevEnabled,
      },
      tenant: {
        backupAndRecovery: ctx.FEATURE_FLAGS.dmsAzureEntraIdWorkload
      },
    });

  /**
   * CanAccessEnv callback for AzureSnapshotManager adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessAzureSnapshotManager: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: ctx.FEATURE_FLAGS.azureSnapshotManager,
      },
    });


  /**
   * CanAccessEnv callback for Cassandra adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessCassandra: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: ctx.FEATURE_FLAGS.cassandraSourceEnabled,
      },
    });

  /**
   * CanAccessEnv callback for Couchbase adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessCouchbase: CanAccessEnv = (ctx, workflow) =>
  this.canAccessWorkflow(ctx, workflow, {
    cluster: {
      backupAndRecovery: ctx.FEATURE_FLAGS.couchbaseSourceEnabled,
    },
  });

  /**
   * CanAccessEnv callback for Salesforce adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessSfdc: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: ctx.FEATURE_FLAGS.dmsSfdcRegistration,
      },
    });

  /**
   * CanAccessEnv callback for Elastifile adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessElastifile: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: ctx.FEATURE_FLAGS.elastifileSourceEnabled,
        browsing: ctx.FEATURE_FLAGS.nasBrowse,
      },
      tenant: {
        backupAndRecovery: ctx.FEATURE_FLAGS.tenantProtectionSourcekElastifileEnabled,
      },
    });


  /**
   * CanAccessEnv callback for Exchange Host VMware adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessExchangeHostVMware: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: true,
      },
      tenant: {
        backupAndRecovery: ctx.FEATURE_FLAGS.tenantProtectionSourcekExchangehostkVMwareEnabled,
      },
    });


  /**
   * CanAccessEnv callback for Exchange Host Physical aka ExchangeOnPrem adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessExchangeHostPhysical: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: ctx.FEATURE_FLAGS.exchangeOnPrem,
      },
      tenant: {
        backupAndRecovery: ctx.FEATURE_FLAGS.tenantProtectionSourcekExchangehostkPhysicalEnabled,
      },
    });


  /**
   * CanAccessEnv callback for FlashBlade adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessFlashBlade: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: ctx.FEATURE_FLAGS.flashBladeSourceEnabled,
        browsing: ctx.FEATURE_FLAGS.nasBrowse,
      },
      tenant: {
        backupAndRecovery: ctx.FEATURE_FLAGS.tenantProtectionSourcekFlashBladeEnabled,
      },
    });


  /**
   * CanAccessEnv callback for GCP adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessGCP: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: true,
        browsing: true,
        kuiper: ctx.FEATURE_FLAGS.testDevEnabled,
      },
      tenant: {
        backupAndRecovery: ctx.FEATURE_FLAGS.tenantProtectionSourcekGCPEnabled,
      },
    });


  /**
   * CanAccessEnv callback for GCPNative adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessGCPNative: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: true,
        browsing: true,
      },
    });


  /**
   * CanAccessEnv callback for GenericNas adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessGenericNas: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: true,
        browsing: ctx.FEATURE_FLAGS.nasBrowse,
        dataMigration: ctx.FEATURE_FLAGS.fileStubbing,
      },
      tenant: {
        backupAndRecovery: ctx.FEATURE_FLAGS.tenantProtectionSourcekGenericNasEnabled,
        browsing: true,
        dataMigration: true,
      },
      bifrostTenant: {
        backupAndRecovery: ctx.BIFROST_CAPABILITIES.BR_kGenericNas,
        browsing: true,
      },
    });

  /**
   * CanAccessEnv callback for Universal Data Adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessUda: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: ctx.FEATURE_FLAGS.udaSourceEnabled,
      },
      tenant: {
        backupAndRecovery: ctx.FEATURE_FLAGS.tenantProtectionSourcekUdaEnabled,
      },
      bifrostTenant: {
        backupAndRecovery: ctx.BIFROST_CAPABILITIES.BR_kUda,
      },
    });

  /**
   * CanAccessEnv callback for GPFS adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessGPFS: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: ctx.FEATURE_FLAGS.gpfsSourceEnabled,
        browsing: ctx.FEATURE_FLAGS.nasBrowse,
      },
      tenant: {
        backupAndRecovery: ctx.FEATURE_FLAGS.tenantProtectionSourcekGPFSEnabled,
      },
    });

  /**
   * CanAccessEnv callback for HBase adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessHBase: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: ctx.FEATURE_FLAGS.hBaseSourceEnabled,
      },
    });

  /**
   * CanAccessEnv callback for HDFS adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessHdfs: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: ctx.FEATURE_FLAGS.hdfsSourceEnabled,
      },
    });

  /**
   * CanAccessEnv callback for Hive adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessHive: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: ctx.FEATURE_FLAGS.hiveSourceEnabled,
      },
    });

  /**
   * CanAccessEnv callback for HyperFlex adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessHyperFlex: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: ctx.FEATURE_FLAGS.storageSnapshotProviderSourceEnabled,
      },
      tenant: {
        backupAndRecovery: ctx.FEATURE_FLAGS.tenantProtectionSourcekHyperFlexEnabled,
      },
    });

  /**
   * CanAccessEnv callback for HyperV Entity SCVMMServer adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessHyperVEntitySCVMMServer: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: true,
        browsing: true,
        clone: true,
        kuiper: ctx.FEATURE_FLAGS.testDevEnabled,
      },
      tenant: {
        backupAndRecovery: ctx.FEATURE_FLAGS.tenantProtectionSourcekHyperVkSCVMMServerEnabled,
      },
      bifrostTenant: {
        backupAndRecovery: ctx.BIFROST_CAPABILITIES.BR_kHyperV_kSCVMMServer,
        browsing: true,
      },
    });

  /**
   * CanAccessEnv callback for HyperV Entity StandaloneHost adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessHyperVEntityStandaloneHost: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: true,
        browsing: true,
        clone: true,
        kuiper: ctx.FEATURE_FLAGS.testDevEnabled,
      },
      tenant: {
        backupAndRecovery: ctx.FEATURE_FLAGS.tenantProtectionSourcekHyperVkStandaloneHostEnabled,
      },
      bifrostTenant: {
        backupAndRecovery: ctx.BIFROST_CAPABILITIES.BR_kHyperV_kStandaloneHost,
        browsing: true,
      },
    });

  /**
   * CanAccessEnv callback for HyperV Entity StandaloneCluster adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessHyperVEntityStandaloneCluster: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: ctx.FEATURE_FLAGS.hyperVStandAloneEnabled,
        browsing: true,
        clone: true,
        kuiper: ctx.FEATURE_FLAGS.testDevEnabled,
      },
      tenant: {
        backupAndRecovery: ctx.FEATURE_FLAGS.tenantProtectionSourcekHyperVkStandaloneClusterEnabled,
      },
      bifrostTenant: {
        backupAndRecovery: ctx.BIFROST_CAPABILITIES.BR_kHyperV_kStandaloneCluster,
        browsing: true,
      },
    });

  /**
   * CanAccessEnv callback for HyperVVSS adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessHyperVVSS: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: true,
        browsing: ctx.FEATURE_FLAGS.hyperVVSSBrowse,
        clone: true,
      },
    });


  /**
   * CanAccessEnv callback for Isilon adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessIsilon: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: ctx.FEATURE_FLAGS.isilonSourceEnabled,
        browsing: ctx.FEATURE_FLAGS.nasBrowse,
        dataMigration: ctx.FEATURE_FLAGS.vendorSpecificPlannerEnabled,
      },
      tenant: {
        backupAndRecovery: ctx.FEATURE_FLAGS.tenantProtectionSourcekIsilonEnabled,
        browsing: true,
      },
      bifrostTenant: {
        backupAndRecovery: ctx.BIFROST_CAPABILITIES.BR_kIsilon,
        browsing: true,
      },
    });


  /**
   * CanAccessEnv callback for Kubernetes adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessKubernetes: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: ctx.FEATURE_FLAGS.kubernetesEnabled,
        browsing: true,
      },
      tenant: {
        backupAndRecovery: ctx.FEATURE_FLAGS.tenantProtectionSourcekKubernetesEnabled,
      },
      bifrostTenant: {
        backupAndRecovery: ctx.FEATURE_FLAGS.enableKubernetesBiForst && ctx.BIFROST_CAPABILITIES.BR_kKubernetes,
        browsing: true,
      },
    });


  /**
   * CanAccessEnv callback for KVM adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessKVM: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: ctx.FEATURE_FLAGS.rhvSupport,
        browsing: true,
        kuiper: ctx.FEATURE_FLAGS.testDevEnabled,
      },
      tenant: {
        backupAndRecovery: ctx.FEATURE_FLAGS.tenantProtectionSourcekKVMEnabled,
      },
    });


  /**
   * CanAccessEnv callback for MongoDB adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessMongoDB: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: ctx.FEATURE_FLAGS.mongoDBSourceEnabled,
      }
    });


    /**
     * CanAccessEnv callback for MongoDB Protection via Ops manager.
     *
     * @param    ctx        The state access context.
     * @param    workflow   The workflow.
     * @returns  Return true if allowed else false.
     */
    private canAccessMongoDBPhysical: CanAccessEnv = (ctx, workflow) =>
      this.canAccessWorkflow(ctx, workflow, {
        cluster: {
          backupAndRecovery: ctx.FEATURE_FLAGS.mongodbPhysicalProtection,
        }
      });


  /**
   * CanAccessEnv callback for Netapp Entity Cluster adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessNetappEntityCluster: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: true,
        browsing: ctx.FEATURE_FLAGS.nasBrowse,
      },
      tenant: {
        backupAndRecovery: ctx.FEATURE_FLAGS.tenantProtectionSourcekNetappkClusterEnabled,
      },
      bifrostTenant: {
        backupAndRecovery: ctx.BIFROST_CAPABILITIES.BR_kNetapp_kCluster,
        browsing: true,
      },
    });


  /**
   * CanAccessEnv callback for Netapp Entity Vserver adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessNetappEntityVserver: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: true,
        browsing: ctx.FEATURE_FLAGS.nasBrowse,
      },
      tenant: {
        backupAndRecovery: ctx.FEATURE_FLAGS.tenantProtectionSourcekNetappkVserverEnabled,
      },
      bifrostTenant: {
        backupAndRecovery: ctx.BIFROST_CAPABILITIES.BR_kNetapp_kVserver,
        browsing: true,
      },
    });

  /**
   * CanAccessEnv callback for Nimble adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessNimble: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: ctx.FEATURE_FLAGS.nimbleEnabled,
      },
      tenant: {
        backupAndRecovery: ctx.FEATURE_FLAGS.tenantProtectionSourcekNimbleEnabled,
      },
    });

  /**
   * CanAccessEnv callback for Ibm Flash System adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessIbmFlashSystem: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: ctx.FEATURE_FLAGS.ibmFlashSystemEnabled,
      },
      tenant: {
        backupAndRecovery: ctx.FEATURE_FLAGS.ibmFlashSystemEnabled,
      },
    });

  /**
   * CanAccessEnv callback for O365 Entity Mailbox adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessO365EntityMailbox: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: ctx.FEATURE_FLAGS.office365SourcesEnabled &&
          ctx.FEATURE_FLAGS.office365MailboxSupportEnabled,
      },
      tenant: {
        backupAndRecovery: ctx.FEATURE_FLAGS.tenantProtectionSourcekO365Enabled &&
          ctx.FEATURE_FLAGS.tenantProtectionSourcekO365kMailboxEnabled,
      },
    });

  /**
   * CanAccessEnv callback for O365 Entity Group adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessO365EntityGroups: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: ctx.FEATURE_FLAGS.office365SourcesEnabled &&
          ctx.FEATURE_FLAGS.office365GroupSupportEnabled,
      },
      tenant: {
        backupAndRecovery: ctx.FEATURE_FLAGS.tenantProtectionSourcekO365Enabled &&
          ctx.FEATURE_FLAGS.tenantProtectionSourcekO365kGroupsEnabled,
      },
    });

  /**
   * CanAccessEnv callback for O365 Entity OneDrive adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessO365EntityOneDrive: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: ctx.FEATURE_FLAGS.office365SourcesEnabled &&
          ctx.FEATURE_FLAGS.office365OneDriveSupportEnabled,
      },
      tenant: {
        backupAndRecovery: ctx.FEATURE_FLAGS.tenantProtectionSourcekO365Enabled &&
          ctx.FEATURE_FLAGS.tenantProtectionSourcekO365kOneDriveEnabled,
      },
    });

  /**
   * CanAccessEnv callback for O365 Entity PublicFolders adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessO365EntityPublicFolders: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: ctx.FEATURE_FLAGS.office365SourcesEnabled &&
          ctx.FEATURE_FLAGS.office365PublicFolderSupportEnabled,
      },
      tenant: {
        backupAndRecovery: ctx.FEATURE_FLAGS.tenantProtectionSourcekO365Enabled &&
          ctx.FEATURE_FLAGS.tenantProtectionSourcekO365kPublicFolderEnabled,
      },
    });

  /**
   * CanAccessEnv callback for O365 Entity SharePoint adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessO365EntitySharePoint: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: ctx.FEATURE_FLAGS.office365SourcesEnabled &&
          ctx.FEATURE_FLAGS.office365SharePointSupportEnabled,
      },
      tenant: {
        backupAndRecovery: ctx.FEATURE_FLAGS.tenantProtectionSourcekO365Enabled &&
          ctx.FEATURE_FLAGS.tenantProtectionSourcekO365kSharePointEnabled,
      },
    });

  /**
   * CanAccessEnv callback for O365 Entity Teams adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessO365EntityTeams: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: ctx.FEATURE_FLAGS.office365SourcesEnabled &&
          ctx.FEATURE_FLAGS.office365TeamsSupportEnabled,
      },
      tenant: {
        backupAndRecovery: ctx.FEATURE_FLAGS.tenantProtectionSourcekO365Enabled &&
          ctx.FEATURE_FLAGS.tenantProtectionSourcekO365kTeamsEnabled,
      },
    });

  /**
   * CanAccessEnv callback for O365Outlook adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  // TODO(Tauseef): Remove and refactor this.
  private canAccessO365Outlook: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: ctx.FEATURE_FLAGS.office365OutlookSupportEnabled,
      },
    });

  /**
   * CanAccessEnv callback for kO365Exchange adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessO365Exchange: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: ctx.FEATURE_FLAGS.office365SourcesEnabled &&
          ctx.FEATURE_FLAGS.office365MailboxSupportEnabled,
      },
      tenant: {
        backupAndRecovery: ctx.FEATURE_FLAGS.tenantProtectionSourcekO365Enabled &&
          ctx.FEATURE_FLAGS.tenantProtectionSourcekO365kMailboxEnabled,
      },
    });

  /**
   * CanAccessEnv callback for kO365Group adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessO365Group: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: ctx.FEATURE_FLAGS.office365SourcesEnabled &&
          ctx.FEATURE_FLAGS.office365GroupSupportEnabled,
      },
      tenant: {
        backupAndRecovery: ctx.FEATURE_FLAGS.tenantProtectionSourcekO365Enabled &&
          ctx.FEATURE_FLAGS.tenantProtectionSourcekO365kGroupsEnabled,
      },
    });

  /**
   * CanAccessEnv callback for kO365OneDrive adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessO365OneDrive: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: ctx.FEATURE_FLAGS.office365SourcesEnabled &&
          ctx.FEATURE_FLAGS.office365OneDriveSupportEnabled,
      },
      tenant: {
        backupAndRecovery: ctx.FEATURE_FLAGS.tenantProtectionSourcekO365Enabled &&
          ctx.FEATURE_FLAGS.tenantProtectionSourcekO365kOneDriveEnabled,
      },
    });

  /**
   * CanAccessEnv callback for kO365PublicFolders adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessO365PublicFolders: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: ctx.FEATURE_FLAGS.office365SourcesEnabled &&
          ctx.FEATURE_FLAGS.office365PublicFolderSupportEnabled,
      },
      tenant: {
        backupAndRecovery: ctx.FEATURE_FLAGS.tenantProtectionSourcekO365Enabled &&
          ctx.FEATURE_FLAGS.tenantProtectionSourcekO365kPublicFolderEnabled,
      },
    });

  /**
   * CanAccessEnv callback for kO365Sharepoint adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessO365Sharepoint: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: ctx.FEATURE_FLAGS.office365SourcesEnabled &&
          ctx.FEATURE_FLAGS.office365SharePointSupportEnabled,
      },
      tenant: {
        backupAndRecovery: ctx.FEATURE_FLAGS.tenantProtectionSourcekO365Enabled &&
          ctx.FEATURE_FLAGS.tenantProtectionSourcekO365kSharePointEnabled,
      },
    });

  /**
   * CanAccessEnv callback for kO365Teams adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessO365Teams: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: ctx.FEATURE_FLAGS.office365SourcesEnabled &&
          ctx.FEATURE_FLAGS.office365TeamsSupportEnabled,
      },
      tenant: {
        backupAndRecovery: ctx.FEATURE_FLAGS.tenantProtectionSourcekO365Enabled &&
          ctx.FEATURE_FLAGS.tenantProtectionSourcekO365kTeamsEnabled,
      },
    });

  /**
   * CanAccessEnv callback for Oracle Host Physical adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessOracleHostPhysical: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: ctx.FEATURE_FLAGS.oracleSourcesEnabled,
        clone: ctx.FEATURE_FLAGS.oracleClone,
        kuiper: ctx.FEATURE_FLAGS.testDevEnabled,
      },
      tenant: {
        backupAndRecovery: ctx.FEATURE_FLAGS.tenantProtectionSourcekOraclehostkPhysicalEnabled,
        clone: true,
      },
      bifrostTenant: {
        backupAndRecovery: ctx.BIFROST_CAPABILITIES.BR_kPhysical_kOracle,
        clone: true,
      },
    });

  /**
   * CanAccessEnv callback for Physical adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessPhysical: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: true,
        browsing: true,
        clone: true,
      },
      tenant: {
        backupAndRecovery: ctx.FEATURE_FLAGS.tenantProtectionSourcekPhysicalEnabled,
        browsing: true,
        clone: true,
      },
      bifrostTenant: {
        backupAndRecovery: ctx.BIFROST_CAPABILITIES.BR_kPhysical,
        browsing: true,
      },
    });

  /**
   * CanAccessEnv callback for PhysicalFiles adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessPhysicalFiles: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: true,
        browsing: true,
      },
      tenant: {
        backupAndRecovery: true,
        browsing: true,
      },
      bifrostTenant: {
        backupAndRecovery: ctx.BIFROST_CAPABILITIES.BR_kPhysicalFiles,
        browsing: true,
      },
    });


  /**
   * CanAccessEnv callback for Pure adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessPure: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: true,
      },
      tenant: {
        backupAndRecovery: ctx.FEATURE_FLAGS.tenantProtectionSourcekPureEnabled,
      },
    });


  /**
   * CanAccessEnv callback for RDSSnapshotManager adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessRDSSnapshotManager: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: ctx.FEATURE_FLAGS.awsRDS,
      },
      tenant: {
        backupAndRecovery: ctx.FEATURE_FLAGS.tenantProtectionSourcekAWSEnabled,
      },
    });


  /**
   * CanAccessEnv callback for RemoteAdapter adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessRemoteAdapter: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: true,
      },
      tenant: {
        backupAndRecovery: ctx.FEATURE_FLAGS.tenantProtectionSourcekRemoteAdapterEnabled &&

          // TODO: this became a duplcate flag now, deprecate this flag by 6.6
          ctx.FEATURE_FLAGS.enableRemoteAdaptorJobsForTenants,
      },
    });

  /**
   * CanAccessEnv callback for Pure adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessSapHana: CanAccessEnv = (ctx, workflow) =>
   this.canAccessWorkflow(ctx, workflow, {
    cluster: {
      backupAndRecovery: ctx.FEATURE_FLAGS.sapHanaEnabledDms,
    },
    tenant: {
      backupAndRecovery: ctx.FEATURE_FLAGS.sapHanaEnabledDms,
    },
  });

  /**
   * CanAccessEnv callback for SQL Host VMware adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessSQLHostVMware: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: true,
        clone: true,
        dbMigration: ctx.FEATURE_FLAGS.databaseMigration,
        browsing: true,
      },
      tenant: {
        backupAndRecovery: ctx.FEATURE_FLAGS.tenantProtectionSourcekSQLhostkVMwareEnabled,
        clone: true,
        browsing: true,
      },
      bifrostTenant: {
        backupAndRecovery: ctx.BIFROST_CAPABILITIES.BR_kSQL_kVMWare,
        clone: true,
        browsing: true,
      },
    });

  /**
   * CanAccessEnv callback for SQL Host Physical adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessSQLHostPhysical: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: true,
        clone: true,
        dbMigration: ctx.FEATURE_FLAGS.databaseMigration,
        browsing: true,
      },
      tenant: {
        backupAndRecovery: ctx.FEATURE_FLAGS.tenantProtectionSourcekSQLhostkPhysicalEnabled,
        clone: true,
        browsing: true,
      },
      bifrostTenant: {
        backupAndRecovery: ctx.BIFROST_CAPABILITIES.BR_kSQL_kPhysical,
        clone: true,
        browsing: true,
      },
    });

  /**
   * CanAccessEnv callback for SQL Host SQL adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessSQLHostSQL: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: true,
        clone: true,
        dbMigration: ctx.FEATURE_FLAGS.databaseMigration,
      },
      tenant: {
        backupAndRecovery: ctx.FEATURE_FLAGS.tenantProtectionSourcekSQLhostkSQLEnabled,
        clone: true,
      },
    });

  /**
   * CanAccessEnv callback for StorageSnapshotProvider.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessStorageSnapshotProvider: CanAccessEnv = (ctx, workflow) =>
    this.canAccessHyperFlex(ctx, workflow);

  /**
   * CanAccessEnv callback for VCD adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessVCD: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: true,
    });

  /**
   * CanAccessEnv callback for View adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessView: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: true,
        browsing: true,
        clone: true,
      },
      tenant: {
        backupAndRecovery: ctx.FEATURE_FLAGS.tenantProtectionSourcekViewEnabled,
        browsing: true,
        clone: true,
      },
      bifrostTenant: {
        backupAndRecovery: ctx.BIFROST_CAPABILITIES.BR_kView,
        clone: true,
        browsing: true,
      },
    });

  /**
   * CanAccessEnv callback for VMware Entity VCenter adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessVMwareEntityVCenter: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: true,
        browsing: true,
        clone: true,
        kuiper: ctx.FEATURE_FLAGS.testDevEnabled,
      },
      tenant: {
        backupAndRecovery: ctx.FEATURE_FLAGS.tenantProtectionSourcekVMwarekVCenterEnabled,
        browsing: true,
        clone: true,
      },
      bifrostTenant: {
        backupAndRecovery: ctx.BIFROST_CAPABILITIES.BR_kVMWare_kVCenter,
        browsing: true,
        clone: true,
      },
    });

  /**
   * CanAccessEnv callback for VMware Entity StandaloneHost adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessVMwareEntityStandaloneHost: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: true,
        browsing: true,
        clone: true,
        kuiper: ctx.FEATURE_FLAGS.testDevEnabled,
      },
      tenant: {
        backupAndRecovery: ctx.FEATURE_FLAGS.tenantProtectionSourcekVMwarekStandaloneHostEnabled,
        browsing: true,
        clone: true,
      },
      bifrostTenant: {
        backupAndRecovery: ctx.BIFROST_CAPABILITIES.BR_kVMWare_kStandaloneHost,
        browsing: true,
        clone: true,
      },
    });

  /**
   * CanAccessEnv callback for VMware Entity vCloudDirector adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessVMwareEntityvCloudDirector: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: ctx.FEATURE_FLAGS.vCloudSourceRegistration,
        browsing: true,
        clone: ctx.FEATURE_FLAGS.enableVcdClone,
        kuiper: ctx.FEATURE_FLAGS.testDevEnabled,
      },
      tenant: {
        backupAndRecovery: ctx.FEATURE_FLAGS.tenantProtectionSourcekVMwarekvCloudDirectorEnabled,
      },
    });

  /**
   * CanAccessEnv callback for Puppeteer adaptor.
   *
   * @param    ctx        The state access context.
   * @param    workflow   The workflow.
   * @returns  Return true if allowed else false.
   */
  private canAccessPuppeteer: CanAccessEnv = (ctx, workflow) =>
    this.canAccessWorkflow(ctx, workflow, {
      cluster: {
        backupAndRecovery: true,
      },
    });

  /**
   * Return boolean to specify whether the clone action is allowed for selected environment.
   *
   * @param   environment  object run environment
   * @param   osType       object os type
   * @returns boolean to allow or block clone operation based on selected environment.
   */
  canAccessCloneWorkflow(environment: Environment, osType?: HostType): boolean {
    const canAccess = this.canAccessSomeEnvItems([{environment}] as EnvItems[], Workflow.clone);

    switch (environment) {
      case Environment.kOracle:

        // Remove Clone action for Oracle Windows & Solaris databases.
        if (osType === HostType.kWindows) {
          return canAccess && flagEnabled(this.irisCtx.irisContext, 'enableOracleWindowsClone');
        } else if (osType === HostType.kSolaris) {
          return canAccess && flagEnabled(this.irisCtx.irisContext, 'enableOracleSolarisClone');
        }
        return canAccess;

      // Remove Clone action for SQL Linux databases & hide clone option for IBM BAAS Cluster.
      case Environment.kSQL:
        return osType !== HostType.kLinux && canAccess && !isIbmBaaSEnabled(this.irisCtx.irisContext);

      default:
        return canAccess;
    }
  }
}

import { Injectable } from '@angular/core';
import { Api } from '@cohesity/api/private';
import {
  ArchivalExternalTarget,
  DeployTaskRequest,
  ProtectionRunsServiceApi,
  RestoreTasksServiceApi,
} from '@cohesity/api/v1';
import {
  FailedRunDetails,
  ObjectServiceApi,
  ObjectSnapshot,
  PerformRunActionResponse,
  ProtectionGroupRun,
  ProtectionGroupRuns,
  ProtectionGroupServiceApi,
  UpdateExistingArchivalSnapshotConfig,
  UpdateProtectionGroupRunParams,
  UpdateProtectionGroupRunResponseBody,
} from '@cohesity/api/v2';
import { NavItem, SnackBarService, WindowRef } from '@cohesity/helix';
import { flagEnabled, IrisContextService, isClusterScope, isEntityOwner, isMcm } from '@cohesity/iris-core';
import { ConfirmationDialogComponent } from '@cohesity/shared-dialogs';
import { ExportToCsvService, sanitizeParameters } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { RawParams } from '@uirouter/core';
import { uniq } from 'lodash-es';
import { forkJoin, from, Observable, Subject } from 'rxjs';
import { filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import {
  AdaptorAccessService,
  AjsUpgradeService,
  ClusterService,
  DialogService,
  PassthroughOptionsService,
  RemoteClusterService,
  UserService,
  V1V2ApiUtilsService,
} from 'src/app/core/services';
import {
  ENV_GROUPS,
  envGroups,
  Environment,
  HostType,
  JobRunFinishedSuccessfullyStates,
  JobRunType,
  TaskStatus,
} from 'src/app/shared';
import { IbmProtectionTypes } from 'src/app/shared/source-tree/protection-source/ibmflashsystem/ibmflashsystem.constants';
import { PureProtectionTypes } from 'src/app/shared/source-tree/protection-source/pure/pure.constants';
import { isRelationalDatabase } from 'src/app/util';

import {
  ProtectionRunDeleteDialogComponent,
  SnapshotDeletion,
} from '../components/protection-run-delete-dialog/protection-run-delete-dialog.component';
import { ArchivalTarget } from '../models/archival-target.models';
import { CancelTask, CancelTaskType, IProtectionRun } from '../models/common.models';
import { ProtectedObjectRun } from '../models/protected-object-run.models';
import { ProtectionGroup } from '../models/protection-group.models';
import { ProtectionRunObject } from '../models/protection-run-object.models';
import { ProtectionRun } from '../models/protection-run.models';
import {
  PauseRunsDialogResponse,
  ProtectionGroupPauseRunsDialogComponent
} from '../components/protection-group-pause-runs-dialog/protection-group-pause-runs-dialog.component';

declare const window: any;

/**
 * Parameters for GetRunErrorsReport
 */
export interface GetRunErrorsReportParams {
  /**
   * Specifies a unique run id of the Protection Group run.
   */
  runId: string;

  /**
   * Specifies the id of the object for which errors/warnings are to be returned.
   */
  objectId: string;

  /**
   * Specifies a unique id of the Protection Group.
   */
  id: string;

  /**
   * Specified the cluster id of the protection run.
   */
  clusterId: string;

  /**
   * Specifies the protection type 'kFile' or 'kVolume' for Physical
   */
  physicalProtectionType?: string;
}

/**
 * Alias for `ProtectionGroupServiceApi.GetProtectionGroupRunsParams`.
 */
export type GetProtectionGroupRunsParams = ProtectionGroupServiceApi.GetProtectionGroupRunsParams;

/**
 * Alias for `ProtectionGroupServiceApi.GetProtectionGroupRunParams`.
 */
export type GetProtectionGroupRunParams = ProtectionGroupServiceApi.GetProtectionGroupRunParams;

/**
 * Alias for `ProtectionGroupServiceApi.PerformActionOnProtectionGroupRunParams`.
 */
export type PerformActionOnGroupRunParams = ProtectionGroupServiceApi.PerformActionOnProtectionGroupRunParams;

/**
 * Alias for `ProtectionGroupServiceApi.GetProtectionRunProgressParams`.
 */
export type GetProtectionRunProgressParams = ProtectionGroupServiceApi.GetProtectionRunProgressParams;

/**
 * Alias for `ObjectServiceApi.GetObjectRunsParams`.
 */
export type GetObjectRunsParams =  ObjectServiceApi.GetObjectRunsParams;

/**
 * Delete Snapshots Parameters.
 */
export interface DeleteSnapshotsParams {
  /**
   * Cluster ID.
   */
  clusterId: number;

  /**
   * Cluster Incarnation ID.
   */
  clusterIncarnationId: number;

  /**
   * Protection Job ID.
   */
  jobId: number;

  /**
   * Protection run start time in microseconds.
   */
  runStartTimeUsecs: number;

  /**
   * Protection source ID list.
   */
  sourceIds: number[];

  /**
   * Specifies snapshot type.
   */
  type?: 'kLocal' | 'kRemote' | 'kArchival' | 'kCloudDeploy';

  /**
   * Specifies the Archival External Target for storing a copied Snapshot.
   * If the type is not 'kLocal', either a replicationTarget,
   * archivalExternalTarget or cloudReplicationTarget must be specified.
   */
  archivalTarget?: ArchivalExternalTarget;

  /**
   * Specifies the protection run type.
   */
  runType?: string;
}

/**
 * Data required to populate Protection Run Object actions.
 */
export interface RunObjectActionsData {
  group?: ProtectionGroup;
  run: ProtectionRun;
  object: ProtectionRunObject;
}

/**
 * Applicable menu items for `RunObjectMenuComponent` component.
 */
export enum RunObjectAction {
  CloneDB,
  CloneView,
  CloneVM,
  DownloadDebugLog,
  RecoverAurora,
  RecoverDB,
  RecoverFiles,
  RecoverNas,
  RecoverPhysical,
  RecoverSanVolume,
  RecoverSanGroup,
  RecoverRDS,
  RecoverVM,
  RecoverS3,
  CancelVM
}

/**
 * Map menu item names to translate values
 */
export const runObjectActionName: { [key in RunObjectAction]?: string } = {
  [RunObjectAction.CloneDB]: 'clone',
  [RunObjectAction.CloneView]: 'cloneView',
  [RunObjectAction.CloneVM]: 'cloneVm',
  [RunObjectAction.DownloadDebugLog]: 'downloadDebugLogs',
  [RunObjectAction.RecoverAurora]: 'recoverAurora',
  [RunObjectAction.RecoverDB]: 'recover',
  [RunObjectAction.RecoverNas]: 'recoverNasVolume',
  [RunObjectAction.RecoverPhysical]: 'instantVolumeMount',
  [RunObjectAction.RecoverSanVolume]: 'recoverSanVolume',
  [RunObjectAction.RecoverSanGroup]: 'recoverSanGroup',
  [RunObjectAction.RecoverFiles]: 'recoverFiles',
  [RunObjectAction.RecoverRDS]: 'recoverRDS',
  [RunObjectAction.RecoverVM]: 'recoverVm',
  [RunObjectAction.RecoverS3]: 'recoverS3',
  [RunObjectAction.CancelVM]: 'cancelVm'
};

/**
 * Map menu item to ui router state.
 */
const runObjectActionStates: { [key in RunObjectAction]?: string } = {
  [RunObjectAction.CloneDB]: 'clone-db.options',
  [RunObjectAction.CloneView]: 'clone-view.options',
  [RunObjectAction.CloneVM]: 'clone-vms.clone-options',
  [RunObjectAction.RecoverAurora]: 'recover-rds.options',
  [RunObjectAction.RecoverDB]: 'recover-db.options',
  [RunObjectAction.RecoverFiles]: 'recover-files.search',
  [RunObjectAction.RecoverNas]: 'recover-storage-volume.nas-options',
  [RunObjectAction.RecoverPhysical]: 'recover-mount-point.options',
  [RunObjectAction.RecoverRDS]: 'recover-rds.options',
  [RunObjectAction.RecoverVM]: 'recover-vm.recover-options',
  [RunObjectAction.RecoverS3]: 'recover-s3-ng',
};

/**
 * Protection run backup action.
 */
export enum SnapshotAction {
  Cancel = 'cancelSnapshot',
  Delete = 'deleteSnapshot',
}

/**
 * Maps SnapshotAction type to string value.
 */
export type SnapshotActionValue = { [key in SnapshotAction]: string };

/**
 * Translate values for SnapshotAction.
 */
export const backupActionName: SnapshotActionValue = {
  [SnapshotAction.Cancel]: 'cancelBackup',
  [SnapshotAction.Delete]: 'deleteSnapshots',
};

/**
 * Icons for SnapshotAction.
 */
export const backupActionIcon: SnapshotActionValue = {
  [SnapshotAction.Cancel]: 'block',
  [SnapshotAction.Delete]: 'delete',
};

/**
 * Menu item for Run menu.
 */
export enum MenuItem {
  DownloadDebugLogs = 'downloadDebugLogs',
  EditRun = 'editRun',
  ExportToCsv = 'exportToCsv',
  PauseRun = 'pauseRun',
  ResumeRun = 'resumeRun',
  CancelRun = 'cancelRun',
}

/**
 * Protection Run service for getting Protection Run Info and delete snapshots.
 */
@Injectable({
  providedIn: 'root',
})
export class ProtectionRunService {
  /**
   * AJS JobRunsService service.
   */
  private ajsJobRunsService: any;

  /**
   * AJS DateTimeService service.
   */
  private dateTimeService: any;

  /**
   * Array of initial menu items for a Run item.
   */
  InitialRunMenuItems: NavItem[] = [
    {
      displayName: MenuItem.EditRun,
    },
  ];

  /**
   * Constructor.
   */
  constructor(
    private adaptorAccessService: AdaptorAccessService,
    private clusterService: ClusterService,
    private dialogService: DialogService,
    private exportToCsvService: ExportToCsvService,
    private groupsServiceV2: ProtectionGroupServiceApi,
    private irisContextService: IrisContextService,
    private objectService: ObjectServiceApi,
    private runsService: ProtectionRunsServiceApi,
    private restoreService: RestoreTasksServiceApi,
    private upgradeService: AjsUpgradeService,
    private userService: UserService,
    private windowRef: WindowRef,
    private passthroughOptionsService: PassthroughOptionsService,
    private translate: TranslateService,
    private snackbarService: SnackBarService,
    private v1v2util: V1V2ApiUtilsService,
    private remoteClusterService: RemoteClusterService,
  ) {
    this.ajsJobRunsService = this.upgradeService.get('JobRunsService');
    this.dateTimeService = this.upgradeService.get('DateTimeService');
  }

  /**
   * Converts old recovery flow params to the new recovery params model.
   *
   * @param   params   The ajs flow state params object
   */
  private getRecoveryStateParams(params: RawParams): RawParams{
    return {
      ...params,
      protectionGroupId: params.jobId && String(params.jobId) !== '-1' ?
        this.v1v2util.generateV2protectionGroupId(params.jobUid) : null,
      runId: [params.jobId, params.jobRunStartTime].join(':'),
      objectId: params.entityId,
      sourceId: params.sourceId || (params.sourceEntity && params.sourceEntity.id)
    };
  }

  /**
   *
   * @param param0 the name of the action to handle.
   * @param run the run on which we will make the actions.
   * @returns Observable of when modal window is closed.
   */
  actionHandler({ displayName }: NavItem, run: ProtectionRun) {
    const subject = new Subject();

    switch (displayName) {
      case MenuItem.EditRun:
        return this.editRun( run.jobId, run.startTimeUsecs, run.endTimeUsecs);

      case MenuItem.PauseRun:
        return this.pauseRun(run.groupId, run.runId);

      case MenuItem.ResumeRun:
        return this.resumeRun(run.groupId, run.runId);

      case MenuItem.CancelRun: {
        let taskType = CancelTaskType.Backup;
        let taskId = run.localTaskId;

        if (run?.archivalTargets?.length) {
          taskId = run.archivalTargets[0]?.archivalTaskId;
          if (this.clusterService.isClusterNGCE) {
            taskType = CancelTaskType.Archival;
          } else if (run.isDirectArchive) {
            taskType = CancelTaskType.Cad;
          }
        }
        return this.cancelRun(run.groupId, run.runId, taskId, taskType);
      }

      case MenuItem.DownloadDebugLogs:
        this.v2DebugLogDownload(run.groupId, run.runId);
        subject.next();
        subject.complete();
        return subject;

      case MenuItem.ExportToCsv:
        this.getRun(run.groupId, run.runId, {
          includeObjectDetails: true,
        }).subscribe(
          value => {
            subject.next();
            subject.complete();
            this.exportToCsvService.downloadCsv(value?.objects || [], 'protection_run_objects');
          }
        );
        return subject;
    }
  }

  /**
   * Opens AJS modal dialog for managing job run.
   *
   * @param  jobId           Job ID.
   * @param  startTimeUsecs  Run start time in usec.
   * @param  endTimeUsecs    Run end time in usec.
   * @returns Observable of when modal window is closed.
   */
  editRun(jobId: number, startTimeUsecs: number, endTimeUsecs: number): Observable<unknown> {
    return this.getV1Run(jobId, startTimeUsecs, endTimeUsecs).pipe(
      switchMap((res: any[]) => {
        const response = res.find(data => {
          const {
            backupJobRuns: { protectionRuns: runs },
          } = data;

          return runs.find(run => run.backupRun.base.startTimeUsecs === startTimeUsecs);
        });

        const {
          backupJobRuns: { jobDescription, protectionRuns },
        } = response;

        const { backupRun } = protectionRuns.find(run => run.backupRun.base.startTimeUsecs === startTimeUsecs);

        return from(
          this.ajsJobRunsService.getJobRunAction('edit', jobDescription, backupRun).action()
        );
      })
    );
  }

  /**
   * Returns observable of job run from v1 API.
   *
   * @param   jobId           Job ID.
   * @param   startTimeUsecs  Run start time in usec.
   * @param   endTimeUsecs    Run end time in usec.
   * @returns Observable of job run from V1 API.
   */
  getV1Run(jobId: number, startTimeUsecs: number, endTimeUsecs: number): Observable<unknown> {
    return from(
      this.ajsJobRunsService.getJobRuns({
        id: jobId,
        numRuns: 1000,
        runTypes: 'kAll',
        excludeTasks: true,
        onlyReturnDataMigrationJobs: false,
        startTimeUsecs,
        endTimeUsecs,
        allUnderHierarchy: true,
      })
    );
  }

  /**
   * Returns true if run can be modified.
   */
  canEditRun(run: ProtectionRun): boolean {
    const {
      archivalStats = [],
      cloudSpinStats = [],
      isLocalSnapshotsDeleted,
      objects = [],
      replicationStats = [],
      successfulAppObjectsCount = 0,
      successfulObjectsCount = 0,
      hasLegalHoldTargets,
      onLegalHold,
    } = run;
    const isEnabled = flagEnabled(this.irisContextService.irisContext, 'editRunEnabled');
    const hasAccess = this.userService.privs.PROTECTION_MODIFY;
    const hasRemoteTasks = replicationStats.length > 0 || archivalStats.length > 0 || cloudSpinStats.length > 0;
    const hasNormalStatusRemoteTasks = [...replicationStats, ...archivalStats, ...cloudSpinStats].some(stat =>
      ['Canceled', 'Missed', 'Succeeded', 'SucceededWithWarning'].includes(stat.status)
    );
    const expired = !!objects.find(obj => obj.hasExpired(this.dateTimeService.getCurrentUsecs()));
    const isLegalHold = onLegalHold || hasLegalHoldTargets;

    // Allow edit if edit run is enabled and user has access
    return (
      isEnabled &&
      hasAccess &&
      this.isRunOwner(run) &&
      // Allow edit if run has remote tasks and some of them are not erroneous
      ((hasRemoteTasks && hasNormalStatusRemoteTasks) ||
        // Allow edit run for partially successful backups
        (!!(successfulAppObjectsCount || successfulObjectsCount) &&
          // Allow edit if snapshot is not deleted and snapshot objects have not expired
          !isLocalSnapshotsDeleted &&
          !expired) ||
        // Allow edit if run is onLegalHold regardless of status
        !!isLegalHold)
    );
  }

  /**
   * Returns true if run can be paused.
   *
   * @param   run  Protection run that belongs to the user.
   * @return  True if run can be paused.
   */
  canPauseRun(run: ProtectionRun): boolean {
    const isEnabled = flagEnabled(this.irisContextService.irisContext, 'pauseRunsEnabled');
    return isEnabled && run.status === TaskStatus.Running && envGroups.nas.includes(run.environment);
  }

  /**
   * Returns true if run can be cancelled.
   *
   * @param   run  Protection run that belongs to the user.
   * @return  True if run can be cancelled.
   */
  canCancelRun(run: ProtectionRun): boolean {
    return run.status === TaskStatus.Paused || run.status === TaskStatus.Running;
  }

  /**
   * Returns true if run can be resumed.
   *
   * @param   run  Protection run that belongs to the user.
   * @return  True if run can be resumed.
   */
  canResumeRun(run: ProtectionRun): boolean {
    return run.status === TaskStatus.Paused && envGroups.nas.includes(run.environment);
  }

  /**
   * Returns true if debuglog can be download.
   *
   * @param   run  Protection run that belongs to the user.
   * @return  True if debuglog can be download.
   */
  canDownloadDebugLog(run: ProtectionRun): boolean {
    const {
      objects = []
    } = run;
    const expired = !!objects.find(obj => obj.hasExpired(this.dateTimeService.getCurrentUsecs()));
    const isOracle = run.environment === Environment.kOracle;
    const isEnabled = flagEnabled(this.irisContextService.irisContext, 'downloadDebugLogsEnabled');
    const isFinished = !run.status ||
      [TaskStatus.Succeeded, TaskStatus.SucceededWithWarning, TaskStatus.Failed, TaskStatus.Canceled]
      .includes(run.status as TaskStatus);
    return (isEnabled && isOracle && !expired && isFinished && !run.isLocalSnapshotsDeleted);
  }

  /**
   * Indicates whether the run is owned by logged-in user or not.
   *
   * @param   run  Protection run that belongs to the user.
   * @return  True if specified run is owned by the user.
   */
  isRunOwner(run: IProtectionRun): boolean {
    if (!run) {
      return false;
    }

    const { permissions = [] } = run;

    if (permissions && permissions.length) {
      return isEntityOwner(this.irisContextService.irisContext, permissions.map(({ id }) => id));
    }

    // if run permissions are empty, any user can modify run
    return true;
  }

  /**
   *
   * @param run the run from the slected row.
   * @returns a list of actions that can be done on the run.
   */
  getMenu(run: ProtectionRun) {
    const menuItems = this.InitialRunMenuItems.map(item => ({...item}));
    if (run) {
      if (this.canPauseRun(run)) {
        menuItems.push({
          displayName: MenuItem.PauseRun
        });
      }

      if (this.canCancelRun(run)) {
        menuItems.push({
          displayName: MenuItem.CancelRun
        });
      }

      if (this.canResumeRun(run)) {
        menuItems.push({
          displayName: MenuItem.ResumeRun
        });
      }

      if (this.canDownloadDebugLog(run)) {
        menuItems.push({
          displayName: MenuItem.DownloadDebugLogs
        });
      }

      if (this.irisContextService.irisContext.featureFlags.enableUiExportToCsv) {
        menuItems.push({
          displayName: MenuItem.ExportToCsv
        });
      }
    }

    menuItems.forEach(
      item => (item.disabled = !run || (item.displayName === MenuItem.EditRun && !this.canEditRun(run)))
    );
    return menuItems;
  }

  /**
   * Get protection run info, including a list of protection objects.
   *
   * @param    groupId  Protection group/job ID.
   * @param    runId    Protection run ID.
   * @param    options  Additional API request params.
   * @returns  Observable of Protection Run.
   */
  getRun(
    groupId: string,
    runId: string,
    options: Partial<GetProtectionGroupRunParams> = {}
  ): Observable<ProtectionGroupRun> {
    return this.groupsServiceV2.GetProtectionGroupRun({
      id: groupId,
      runId: runId,
      ...options,
      ...this.passthroughOptionsService.requestParams,
    });
  }

  /**
   * Returns observable of `ProtectionGroupRuns` based on Group ID provided.
   *
   * @param    params  Parameters for making the API call to get protection runs.
   * @returns  Observable of `ProtectionGroupRuns`.
   */
  getRuns(params: GetProtectionGroupRunsParams): Observable<ProtectionGroupRuns> {
    return this.groupsServiceV2.GetProtectionGroupRuns({
      ...params,
      includeTenants: true,
      ...this.passthroughOptionsService.requestParams,
    });
  }

  /**
   * Delete protection runs.
   *
   * @param    runs  List of protection runs with local snapshots to be deleted.
   * @returns  Observable of `UpdateProtectionGroupRunResponseBody`.
   */
  deleteLocalSnapshots(runs: IProtectionRun[]): Observable<UpdateProtectionGroupRunResponseBody> {
    const apiParams = {
      id: runs[0].groupId,
      body: {
        updateProtectionGroupRunParams: runs.map((run: IProtectionRun) => {
          const { runId, backupStatus } = run;
          const params: UpdateProtectionGroupRunParams = { runId };

          if (backupStatus && backupStatus.status) {
            params.localSnapshotConfig = { deleteSnapshot: true };
          }
          return params;
        }),
      },
      ...this.passthroughOptionsService.requestParams,
    };

    return this.dialogService
      .showDialog(ConfirmationDialogComponent, {
        title: 'deleteLocalSnapshots.title',
        message: 'deleteLocalSnapshots.body',
        confirmLabel: 'delete',
      })
      .pipe(
        filter(Boolean),
        switchMap(() => this.groupsServiceV2.UpdateProtectionGroupRun(apiParams))
      );
  }

  /**
   * Deletes all snapshots for all targets.
   *
   * @param    runs  List of protection runs with local snapshots to be deleted.
   * @returns  Observable of `UpdateProtectionGroupRunResponseBody`.
   */
  deleteAllSnapshots(runs: IProtectionRun[] = []): Observable<UpdateProtectionGroupRunResponseBody> {
    const groupAction$: Observable<SnapshotDeletion> =
      this.dialogService.showDialog(ProtectionRunDeleteDialogComponent, {});

    return groupAction$.pipe(
      filter(Boolean),
      mergeMap(snapshotDeletion => {
        const apiParams = {
          id: runs[0]?.groupId,
          body: {
            updateProtectionGroupRunParams: runs.map(run => {
              const { runId, backupStatus, archivalTargets, replicationTargets } = run;
              const params: UpdateProtectionGroupRunParams = { runId };

              if (backupStatus?.status) {
                params.localSnapshotConfig = { deleteSnapshot: true };
              }

              // If snapshots are already deleted on the target, do not send
              // archival targets for deletion again.
              if (archivalTargets?.length && snapshotDeletion === SnapshotDeletion.All &&
                  !archivalTargets?.some((target) => target?.isDeleted)) {
                params.archivalSnapshotConfig = {
                  updateExistingSnapshotConfig: archivalTargets.map(target => ({
                    archivalTargetType: target.type,
                    deleteSnapshot: true,
                    id: target.id,
                    name: target.name,
                  }))
                };
              }

              if (replicationTargets?.filter(target => target.id && !target.isInBound)?.length &&
                snapshotDeletion === SnapshotDeletion.All) {
                params.replicationSnapshotConfig = {
                  updateExistingSnapshotConfig: replicationTargets.map(target => ({
                    deleteSnapshot: true,
                    id: target.id,
                    name: target.targetName,
                  }))
                };
              }

              return params;
            }),
          },
          ...this.passthroughOptionsService.requestParams,
        };

        return this.groupsServiceV2.UpdateProtectionGroupRun(apiParams);
      }),
    );
  }

  /**
   * Opens dialog to delete specified archival snapshot IDs.
   *
   * @param   groupId           Protection Group ID Archival Snapshot belong to.
   * @param   runId             Protection Run ID Archival Snapshot belong to.
   * @param   archiveSnapshots  List of Archival Snapshot update request params.
   * @return  Observable for API request to delete specified archival snapshots.
   */
  deleteArchiveSnapshots(
    groupId: string,
    runId: string,
    archiveSnapshots: UpdateExistingArchivalSnapshotConfig[]
  ): Observable<UpdateProtectionGroupRunResponseBody> {
    const updateExistingSnapshotConfig: UpdateExistingArchivalSnapshotConfig[] = archiveSnapshots.map(snapshot => ({
      ...snapshot,
      deleteSnapshot: true,
      type: 'kArchival',
    }));

    const params = {
      id: groupId,
      body: {
        updateProtectionGroupRunParams: [
          {
            runId,
            archivalSnapshotConfig: {
              updateExistingSnapshotConfig,
            },
          },
        ],
      },
      ...this.passthroughOptionsService.requestParams,
    };

    return this.dialogService
      .showDialog(ConfirmationDialogComponent, {
        title: 'deleteArchiveSnapshots.dialog.title',
        message: 'deleteArchiveSnapshots.dialog.body',
        confirmLabel: 'delete',
      })
      .pipe(
        filter(Boolean),
        switchMap(() => this.groupsServiceV2.UpdateProtectionGroupRun(params))
      );
  }

  /**
   * Delete Snapshots Action.
   *
   * @param    params  Parameters for making the API call to delete snapshots.
   * @returns  Observable for calling component to handle success/fail cases.
   */
  deleteSnapshots(params: DeleteSnapshotsParams): Observable<null> {
    const {
      archivalTarget,
      clusterId,
      clusterIncarnationId,
      jobId,
      runStartTimeUsecs,
      runType,
      sourceIds,
      type = 'kLocal',
    } = params;

    const apiParams: ProtectionRunsServiceApi.UpdateProtectionRunsParams = {
      body: {
        jobRuns: [
          {
            copyRunTargets: [
              {
                archivalTarget,
                // value 0 means delete
                daysToKeep: 0,
                type,
              },
            ],
            jobUid: {
              clusterId,
              clusterIncarnationId,
              id: jobId,
            },
            runStartTimeUsecs,
            runType,
            sourceIds,
          },
        ],
      },
      ...this.passthroughOptionsService.requestParams,
    };

    return this.dialogService
      .showDialog(ConfirmationDialogComponent, {
        title: 'deleteSnapshots',
        message: 'deleteSnapshots.body',
        confirmLabel: 'delete',
      })
      .pipe(
        filter(Boolean),
        switchMap(() => this.runsService.UpdateProtectionRuns(apiParams))
      );
  }

  /**
   * Downloads error logs for a task
   *
   * @param   apiParams   Download error message parameters
   */
  downloadErrorLogs(apiParams: GetRunErrorsReportParams) {
    const { id, runId, objectId, clusterId } = apiParams;
    const url = Api.publicV2(
      `data-protect/protection-groups/${id}/runs/${runId}/objects/${objectId}/downloadMessages?clusterId=${clusterId}`
    );
    window.open(url);
  }

  /**
   * Return url to download job success report.
   *
   * @param   id unique id of the Protection Group
   * @param   runId unique run id of the Protection Group run
   * @param   objectId id of the object
   * @param   name of backup source
   * @returns Download link to fetch job success report.
   */
  downloadJobSuccessReportUrl(id: string, runId: string, objectId: number, name: string): string {
    let clusterPath = '';

    if (isMcm(this.irisContextService.irisContext) &&
      isClusterScope(this.irisContextService.irisContext)) {
      // Add required cluster passthrough information if this call is being
      // made from Helios
      clusterPath = `&clusterId=${this.remoteClusterService.selectedScope.clusterId}`;
    }

    return Api.publicV2(`data-protect/protection-groups/${id}/runs/${runId}/objects/${objectId}/downloadFiles`) +
      `?${sanitizeParameters({ fileType: 'success_files_list' })}&name=${name}${clusterPath}`;
  }

  /**
   * Return url to download job inclusion-exclusion report.
   *
   * @param   id unique id of the Protection Group
   * @param   runId unique run id of the Protection Group run
   * @param   objectId id of the object
   * @param   name of backup source
   * @returns Download link to fetch job success report.
   */
  downloadJobInclusionExclusionReportUrl(id: string, runId: string, objectId: number, name: string): string {
    return Api.publicV2(`data-protect/protection-groups/${id}/runs/${runId}/objects/${objectId}/download-messages`) +
      `?${sanitizeParameters({ fileType: 'inclusion_exclusion_reports' })}&name=${name}`;
  }

  /**
   * Returns actions applicable to specified Protection Run and its Object.
   *
   * @param    data  Data that contains Protection Run and its Object.
   * @returns  Actions array that can be applied to specified run and its object.
   */
  getRunObjectActions(data: RunObjectActionsData): NavItem[] {
    const {
      group: {
        environment: groupEnvironment,
        isAgentBasedGroup = false,
        isAuroraGroup = false,
        isCSMGroup = false,
        isRDSGroup = false,
        isS3Group = false,
      } = {},
      object,
      run,
    } = data;
    const { id, environment: objectEnvironment, sourceId, name } = object;
    const { jobId, protectionGroupInstanceId, clusterId, clusterIncarnationId, runType } = run;
    let { startTimeUsecs } = run;
    const { RESTORE_MODIFY, CLONE_MODIFY } = this.userService.privs;
    let actions: NavItem[] = [];
    let canRecoverAndClone = true;

    switch (true) {
      // View
      case objectEnvironment === Environment.kView && RESTORE_MODIFY:
        actions = [
          {
            displayName: runObjectActionName[RunObjectAction.CloneView],
            state: runObjectActionStates[RunObjectAction.CloneView],
          },
        ];
        break;
      // Databases
      case object.isDb():
        // Currently In case of log backup run, we don’t have the PIT information.
        // Hence we are hiding Recover & Clone option from database object run details
        // page for Oracle & SQL Adapter. Anyway user can go ahead through Clone & Recover
        // workflow and manually select the PIT value from slider and trigger log backup restore/clone.
        if ([Environment.kOracle, Environment.kSQL].includes(objectEnvironment) &&
          (!['kSuccessful', 'kWarning', ...JobRunFinishedSuccessfullyStates].includes(object.status) ||
          runType === JobRunType.kLog)) {
          canRecoverAndClone = false;
        }

        if (canRecoverAndClone) {
          actions = [
            {
              displayName: runObjectActionName[RunObjectAction.RecoverDB],
              state: runObjectActionStates[RunObjectAction.RecoverDB],
            },
          ];

          if (this.adaptorAccessService.canAccessCloneWorkflow(objectEnvironment,
            HostType[run.objectById[sourceId]?.osType])) {
            actions.push({
              displayName: runObjectActionName[RunObjectAction.CloneDB],
              state: runObjectActionStates[RunObjectAction.CloneDB],
            });
          }
        }

        if (this.canDownloadDebugLog(run)) {
          actions.push({
            displayName: runObjectActionName[RunObjectAction.DownloadDebugLog],
          });
        }
        break;
      // Database server except SQL Log backup run objects.
      case !object.isDb() && isRelationalDatabase(groupEnvironment as Environment) &&
        ([Environment.kSQL].includes(run.environment) && runType !== JobRunType.kLog):
        actions = [
          {
            displayName: runObjectActionName[RunObjectAction.RecoverFiles],
            state: runObjectActionStates[RunObjectAction.RecoverFiles],
          },
        ];
        break;
      // VMs
      case ENV_GROUPS.hypervisor.includes(objectEnvironment) &&
        !(isRDSGroup || isAuroraGroup || isS3Group):
        actions = [
          {
            displayName: runObjectActionName[RunObjectAction.RecoverVM],
            state: runObjectActionStates[RunObjectAction.RecoverVM],
          },
        ];

        if (
          CLONE_MODIFY &&
          !ENV_GROUPS.cloneUnsupported.includes(objectEnvironment) &&
          !(isRDSGroup || isAuroraGroup || isCSMGroup || isS3Group) &&
          runType !== JobRunType.kStorageArraySnapshot
        ) {
          actions.push({
            displayName: runObjectActionName[RunObjectAction.CloneVM],
            state: runObjectActionStates[RunObjectAction.CloneVM],
          });
        }

        if (isAgentBasedGroup) {
          // In case of Cohesity Protection Service for AWS or Azure (which are
          // Agent based), Instant Volume Recovery should not be shown.
          actions = objectEnvironment !== Environment.kAWS &&
              objectEnvironment !== Environment.kAzure
            ? [{
              displayName: runObjectActionName[RunObjectAction.RecoverPhysical],
              state: runObjectActionStates[RunObjectAction.RecoverPhysical],
            }]
            : [];
        }

        if (flagEnabled(this.irisContextService.irisContext, 'enableCancelSingleBackupTask')
          && ['kAccepted', 'kInProgress', 'kRunning'].includes(object.status)) {
          actions = [{
            displayName: runObjectActionName[RunObjectAction.CancelVM],
          }];
        }
        break;
      // RDS
      case isRDSGroup:
        actions = [
          {
            displayName: runObjectActionName[RunObjectAction.RecoverRDS],
            state: runObjectActionStates[RunObjectAction.RecoverRDS],
          },
        ];
        break;
      // Aurora
      case isAuroraGroup:
        actions = [
          {
            displayName: runObjectActionName[RunObjectAction.RecoverAurora],
            state: runObjectActionStates[RunObjectAction.RecoverAurora],
          },
        ];
        break;
      // S3
      case isS3Group:
        actions = [
          {
            displayName: runObjectActionName[RunObjectAction.RecoverS3],
            state: runObjectActionStates[RunObjectAction.RecoverS3],
          },
        ];
        break;
      // Pure
      case envGroups.san.includes(objectEnvironment) && RESTORE_MODIFY:
        if (object.status === 'kSuccessful') {
          actions = [
            {
              displayName: [PureProtectionTypes.kPureProtectionGroup,
                IbmProtectionTypes.kVolumeGroup].includes(data?.object?.objectType as PureProtectionTypes) ?
                runObjectActionName[RunObjectAction.RecoverSanGroup]:
                runObjectActionName[RunObjectAction.RecoverSanVolume],
              state: flagEnabled(this.irisContextService.irisContext, 'restoreStorageVolume')
                ? 'recover-storage-volume.pure-options'
                : 'recover-pure.options',
            },
          ];
        }
        break;
      // NAS
      case envGroups.nas.includes(objectEnvironment) && RESTORE_MODIFY:
        actions = [
          {
            displayName: runObjectActionName[RunObjectAction.RecoverNas],
            state: flagEnabled(this.irisContextService.irisContext, 'restoreStorageVolume')
              ? 'recover-storage-volume.nas-options'
              : 'recover-nas.options',
          },
        ];
        break;
      // Exchange
      case run.environment === Environment.kExchange:
        actions = [];
        break;
      // Physical, except kPhysicalFiles, SQL Log backup runs.
      case envGroups.physical.includes(objectEnvironment) && groupEnvironment !== Environment.kPhysicalFiles &&
        run.environment === Environment.kSQL && run.runType !== JobRunType.kLog:
        {
          let state = 'recover-mount-point.options';
          let displayName = runObjectActionName[RunObjectAction.RecoverPhysical];

          if (objectEnvironment === Environment.kRDSSnapshotManager) {
            // for sources using physical agent, we only support
            // instant volume mount. For native snapshots, we support recovery.
            state = 'recover-rds.options';
            displayName = runObjectActionName[RunObjectAction.RecoverVM];
          }

          actions = [
            {
              displayName,
              state,
            },
          ];
        }
        break;
    }

    const dbParam = {
      [Environment.kSQL]: 'sql',
      [Environment.kOracle]: 'oracle',
    };

    // If there are multiple attempts to complete the backup run then run start time would be
    // different from object backup start time, hence we need to use object.startTimeUsecs here
    // instead of job run startTimeUsecs.
    const snapshotUsecs = object.isDb() ? object.startTimeUsecs : startTimeUsecs;

    // For Reattempt feature for IBM and Pure, updating the startTimeUsecs with the Objects
    // last Run Start time in case of reattempt
    if ([Environment.kIbmFlashSystem, Environment.kPure].includes(object.environment) &&
    object.failedTasks?.length && object?.latestAttemptObjectRunStartTime) {
      startTimeUsecs = object.latestAttemptObjectRunStartTime;
    }

    const params: RawParams = {
      dbType: dbParam[objectEnvironment],
      entityId: id,
      jobId,
      jobInstanceId: protectionGroupInstanceId,
      jobRunStartTime: startTimeUsecs,
      protected: true,
      snapshotUsecs,
      browseSourceId: id,
      browseSourceName: name,
      view: {
        displayName: name,
        id,
        parentId: sourceId,
      },
      jobUid: {
        clusterId,
        clusterIncarnationId,
        objectId: jobId,
        id: jobId,
      },
      runType,
    };

    actions.forEach(item => {
      switch (item.state) {
        // Use ng version stateParam object structure for non-legacy ajs protection worklflows
        // as older workflows are mapped in the restore.guard.ts
        case runObjectActionStates[RunObjectAction.RecoverS3]:
          (item as any).stateParams = this.getRecoveryStateParams(params);
          break;
        default:
          (item as any).stateParams = params;
          break;
      }
    });

    return actions;
  }

  /**
   * Performs pause action on the protection run.
   *
   * @param    groupId   Protection Group ID associated with runId.
   * @param    runId     Protection Run ID.
   * @returns  API observable response when protection run is paused.
   */
  pauseRun(groupId: string, runId: string) {

    let pauseRunDailog$: Observable<any>;

    if (flagEnabled(this.irisContextService.irisContext, 'protectionRunPauseNoteEnabled')) {
      const data = {
        title: 'pauseRunConfirmationDailogTitle',
        subtitle: 'protectionRun.pause.message.sla',
      };

      pauseRunDailog$ = this.dialogService
        .showDialog(ProtectionGroupPauseRunsDialogComponent, data);
    } else {
      const data = {
        confirmButtonLabel: 'pauseRun',
        declineButtonLabel: 'close',
        title: 'pauseRun',
        copy: 'protectionRun.pause.message.sla',
      };
      pauseRunDailog$ = this.dialogService.simpleDialog('pauseRun', data);
    }

    return pauseRunDailog$.pipe(
      filter(value => Boolean(value)),
      switchMap((pauseRunsDialogData: PauseRunsDialogResponse) => {
        const params: PerformActionOnGroupRunParams = {
          id: groupId,
          body: {
            action: 'Pause',
            pauseParams: [{ runId, pausedNote: pauseRunsDialogData?.note }]
          },
          ...this.passthroughOptionsService.requestParams
        };
        return this.groupsServiceV2.PerformActionOnProtectionGroupRun(params);
      }),
      tap(
        res => {
          if (res.pauseParams?.length && res.pauseParams[0].error) {
            this.snackbarService.open(
              this.translate.instant(`protectionRun.pause.message.failure`, {
                messages: res.pauseParams[0].error
              }),
              'error'
            );
          } else {
            this.snackbarService.open(
              this.translate.instant(`protectionRun.pause.message.success`, {
                messages: res.pauseParams[0].runId
              }),
              'success'
            );
          }
        }
      ));
  }

  /**
   * Performs cancel action on the protection run.
   *
   * @param    groupId        Protection Group ID associated with runId.
   * @param    taskId         Task ID associated with the runId.
   * @param    runId          Protection Run ID.
   * @param    taskType       Cancel task type.
   * @returns  API observable response when protection run is cancelled.
   */
  cancelRun(groupId: string, runId: string, taskId: string, taskType?: CancelTask) {
    if (!taskId?.length) {
      this.snackbarService.open(
        this.translate.instant(`protectionRun.cancel.message.noTaskId`),
        'error'
      );

      return;
    }
    const params: PerformActionOnGroupRunParams = {
      id: groupId,
      body: {
        action: 'Cancel',
        cancelParams: [{ runId }]
      },
      ...this.passthroughOptionsService.requestParams
    };
    switch (taskType) {
      case CancelTaskType.Backup:
        params.body.cancelParams[0].localTaskId = taskId;
        break;
      case CancelTaskType.Archival:
      case CancelTaskType.Cad:
        params.body.cancelParams[0].archivalTaskId = [taskId];
        break;
    }
    return this.groupsServiceV2.PerformActionOnProtectionGroupRun(params)
      .pipe(tap((res: PerformRunActionResponse) => {
        // TODO: Update YAML to have 'error' key in CancelProtectionGroupRunResponseParams
        if (res.cancelParams?.length && (res.cancelParams[0] as any).error) {
          this.snackbarService.open(
            this.translate.instant(`protectionRun.cancel.message.failure`, {
              messages: (res.cancelParams[0] as any).error
            }),
            'error'
          );
        } else {
          this.snackbarService.open(
            this.translate.instant(`protectionRun.cancel.message.success`, {
              messages: res.cancelParams[0].runId
            }),
            'success'
          );
        }
      }));
  }

  /**
   * Performs resume action on the protection run.
   *
   * @param    groupId   Protection Group ID associated with runId.
   * @param    runId     Protection Run ID.
   * @returns  API observable response when protection run is resumed.
   */
  resumeRun(groupId: string, runId: string) {
    const params: PerformActionOnGroupRunParams = {
      id: groupId,
      body: {
        action: 'Resume',
        resumeParams: [{ runId }]
      },
      ...this.passthroughOptionsService.requestParams
    };

    return this.groupsServiceV2.PerformActionOnProtectionGroupRun(params)
      .pipe(tap((res: PerformRunActionResponse) => {
        if (res.resumeParams?.length && res.resumeParams[0].error) {
          this.snackbarService.open(
            this.translate.instant(`protectionRun.resume.message.failure`, {
              messages: res.resumeParams[0].error
            }),
            'error'
          );
        } else {
          this.snackbarService.open(
            this.translate.instant(`protectionRun.resume.message.success`, {
              messages: res.resumeParams[0].runId
            }),
            'success'
          );
        }
      }));
  }

  /**
   * Opens modal dialog to confirm canceling task.
   *
   * @param    runId     Protection Run ID.
   * @param    groupId   Protection Group ID associated with runId.
   * @param    taskIds   List of task IDs to cancel.
   * @param    taskType  Cancel task type.
   * @returns  API observable response when task gets canceled.
   */
  cancelTask(runId: string, groupId: string, taskIds: string[], taskType: CancelTask,
    objectIds?: number[]): Observable<unknown> {
    const params: PerformActionOnGroupRunParams = {
      id: groupId,
      body: {
        action: 'Cancel',
        cancelParams: [{ runId }]
      },
      ...this.passthroughOptionsService.requestParams,
    };

    let title: string;
    let message: string;

    switch (taskType) {
      case CancelTaskType.Cad:
        title = 'protectionRun.backup.cancel.title';
        message = 'protectionRun.backup.cancel.body';
        params.body.cancelParams[0].archivalTaskId = taskIds;
        break;
      case CancelTaskType.Archival:
        title = 'protectionRun.archival.cancel.title';
        message = 'protectionRun.archival.cancel.body';
        params.body.cancelParams[0].archivalTaskId = taskIds;
        break;
      case CancelTaskType.Backup:
        title = 'protectionRun.backup.cancel.title';
        message = 'protectionRun.backup.cancel.body';
        params.body.cancelParams[0].localTaskId = taskIds[0];
        if (objectIds?.length) {
          params.body.cancelParams[0].objectIds = objectIds;
        }
        break;
      case CancelTaskType.CloudSpin:
        title = 'protectionRun.cloudSpin.cancel.title';
        message = 'protectionRun.cloudSpin.cancel.body';
        params.body.cancelParams[0].cloudSpinTaskId = taskIds;
        break;
      case CancelTaskType.Replication:
        title = 'protectionRun.replication.cancel.title';
        message = 'protectionRun.replication.cancel.body';
        params.body.cancelParams[0].replicationTaskId = taskIds;
        break;
    }

    return this.dialogService
      .showDialog(ConfirmationDialogComponent, {
        title,
        message,
        confirmLabel: 'confirm',
      })
      .pipe(
        filter(Boolean),
        switchMap(() => this.groupsServiceV2.PerformActionOnProtectionGroupRun(params))
      );
  }

  /**
   * Returns applicable backup actions for provided run.
   */
  getSnapshotActions(run: ProtectionRun, group?: ProtectionGroup): SnapshotAction[] {
    const { PROTECTION_MODIFY } = this.userService.privs;

    const ret = [];

    if (PROTECTION_MODIFY && this.isRunOwner(run) ) {
      if (this.hasSnapshotAction(run, SnapshotAction.Cancel)) {
        ret.push(SnapshotAction.Cancel);
      }

      if (this.hasSnapshotAction(run, SnapshotAction.Delete, group)) {
        ret.push(SnapshotAction.Delete);
      }
    }

    return ret;
  }

  /**
   * Returns true if specified SnapshotAction is applicable to specified protection run and group.
   *
   * @params  run     Protection run to check actions for.
   * @params  action  Protection run backup action to check if it's applicable to specified run.
   * @params  group   Optional protection group to validate specified action.
   */
  hasSnapshotAction(run: IProtectionRun, action: SnapshotAction, group?: ProtectionGroup): boolean {
    switch (action) {
      case SnapshotAction.Cancel:

        // Show cancel backup action in case the run is in progress and it is not externally
        // triggered backup run.
        return run.isInProgress &&
          !run.externallyTriggeredBackupTag && !run.isReplicationRun;
      case SnapshotAction.Delete:
        return this.canDeleteRun(run, group);
    }
  }

  /**
   * Determine if a run is allowed to be deleted based on run/group properties
   *
   * @param run    The run to be deleted
   * @param group  The group which the run belongs to
   */
  canDeleteRun(run: IProtectionRun, group: ProtectionGroup) {
    const { status } = run;
    const validStatus = !status || ['Succeeded', 'SucceededWithWarning', 'Canceled', 'Failed']
      .includes(status);

    if (!this.isRunOwner(run) || !validStatus || run.isLocalSnapshotsDeleted || run.onLegalHold ||
      run.isCdpHydrate || (run.isLogRun && !!group?.isCdpEnabled)) {
      return false;
    }

    // for directArchive jobs, latest run cannot be deleted
    if (group && group.isDirectArchiveGroup) {
      return group.lastRun.runId !== run.runId;
    }

    return true;
  }

  /**
   * Returns true if specified run archive target can be deleted.
   *
   * @param    archiveTarget  Protection run archive target to be deleted.
   * @param    run            Protection run that contains archival target.
   * @param    group          Protection Group that has `lastRun` to check if archival target can be deleted.
   * @returns  Return true if specified run archive target can be deleted.
   */
  canDeleteArchive(archiveTarget: ArchivalTarget, run: ProtectionRun, group: ProtectionGroup): boolean {
    if (!this.isRunOwner(run) || archiveTarget.isDeleted || archiveTarget.canCancel || !archiveTarget.canDelete) {
      return false;
    }

    // for directArchive jobs, latest run cannot be deleted
    if (group && group.isDirectArchiveGroup) {
      return group.lastRun.runId !== run.runId;
    }

    return true;
  }

  /**
   * Opens modal dialog to confirm set legal hold.
   *
   * @param    objects      Protection run objects for which legal hold should be updated.
   * @param    onLegalHold  Set or remove legal hold from specified object.
   * @returns  API observable response when legal hold is update.
   */
  updateLegalHold(objects: ProtectionRunObject[], onLegalHold: boolean): Observable<ObjectSnapshot[]> {
    return this.dialogService
      .showDialog(ConfirmationDialogComponent, {
        title: onLegalHold ? 'addLegalHold' : 'removeLegalHold',
        message: onLegalHold ? 'legalHoldModals.addConfirm' : 'legalHoldModals.removeConfirm',
        confirmLabel: 'confirm',
      })
      .pipe(
        filter(Boolean),
        switchMap(() =>
          forkJoin(
            objects
              .filter(object => object.canChangeLegalHold)
              .map(object =>
                this.objectService.UpdateObjectSnapshot({
                  snapshotId: object.snapshotId,
                  id: object.id,
                  body: {
                    setLegalHold: onLegalHold,
                  },
                  ...this.passthroughOptionsService.requestParams,
                })
              )
          )
        )
      );
  }

  /**
   * Send http request to launch a CloudSpin instance
   *
   * @param params The cloud Spin Launch params
   * @return  An observable with response or error.
   */
  launchInstance(params: DeployTaskRequest): Observable<any> {
    return this.restoreService.CreateDeployTask({ body: params, ...this.passthroughOptionsService.requestParams });
  }

  /**
   * Returns unique error messages from protection group run update API.
   *
   * @param   failedRunDetails  Run update error messages from protection group run update API.
   * @return  Unique error messages when run update failed.
   */
  getRunUpdateErrors(failedRunDetails: FailedRunDetails[]): string[] {
    return uniq(failedRunDetails.map(({ errorMessage }) => errorMessage));
  }

  /**
   * Opens new browser tab to download specified file.
   *
   * @param  id               Job ID
   * @param  jobId            Run Id
   * @param  objectId         Object Id (optional)
   */
  v2DebugLogDownload(id: string, runId: string, objectId?: string) {
    // Omit instanceId, entityId, attemptNumber if undefined.

    const path = Api.publicV2(`data-protect/protection-groups/${id}/runs/${runId}`);
    let clusterPath = '';

    if (isMcm(this.irisContextService.irisContext) &&
      isClusterScope(this.irisContextService.irisContext)) {
      // Add required cluster passthrough information if this call is being
      // made from Helios
      clusterPath = `?clusterId=${this.remoteClusterService.selectedScope.clusterId}`;
    }

    this.windowRef.nativeWindow.open(objectId ? `${path}/objects/${objectId}/debug-logs${clusterPath}` :
      `${path}/debug-logs${clusterPath}`);
  }

  /**
   * Returns runs for Protected Object by Object ID.
   *
   * @params   objectId  Object ID to get runs for.
   * @params   useState  Use ui router states for navigation when clicking run state icons.
   * @params   options   Optional arguments passed to runs API.
   * @returns  Object runs.
   */
  getObjectRuns(
    objectId: number,
    useStates = false,
    options: Partial<GetObjectRunsParams> = {}
  ): Observable<ProtectedObjectRun[]> {
    return this.objectService.GetObjectRuns({
      ...options,
      id: objectId,
      ...this.passthroughOptionsService.requestParams,
    }).pipe(map(runs => runs.protectionRuns.map(run => new ProtectedObjectRun(run, useStates))));
  }

  /**
   * Returns run details for specified Run and Object ID.
   *
   * @params   objectId  Object ID.
   * @params   runId     Run ID.
   * @returns  Object run details.
   */
  getObjectRun(objectId: number, runId: string): Observable<ProtectedObjectRun> {
    return this.objectService.GetObjectRunByRunId({
      id: objectId,
      runId,
      ...this.passthroughOptionsService.requestParams,
    }).pipe(map(run => new ProtectedObjectRun(run)));
  }
}

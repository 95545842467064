import { NgModule } from '@angular/core';
import { UtilPipesModule } from '@cohesity/utils';
import { TranslateModule } from '@ngx-translate/core';

import { BackupTargetIconPipe } from './backup-target-icon.pipe';
import { BackupTargetTypePipe } from './backup-target-type.pipe';
import { BytesFractionPipe } from './bytes-fraction.pipe';
import { CdpIoFilterIconPipe } from './cdp-io-filter-icon.pipe';
import { DatePipeWrapper } from './dates.pipes';
import { DayCountPipe } from './day-count.pipe';
import { DurationPipe } from './duration.pipe';
import { FileIconPipe } from './file-icon.pipe';
import { HighlightTextPipe } from './highlight-text.pipe';
import { HumanizeDurationPipe } from './humanize-duration.pipe';
import { HumanizeFromDaysPipe } from './humanize-from-days.pipe';
import { StatusIconMapPipe, StatusIconReducePipe } from './icon.pipe';
import { IsJobRunFinishedPipe } from './is-job-run-finished.pipe';
import { LabelPipe } from './label.pipe';
import { LargeNumberPipe } from './large-number.pipe';
import { ObjectIconPipe } from './object-icon.pipe';
import { OrgIdDistilledPipe } from './org-id-distilled.pipe';
import { OrgIdSplitPipe } from './org-id-split.pipe';
import { PrincipalIconPipe } from './principal-icon.pipe';
import { PrincipalNamePipe } from './principal-name.pipe';
import { QosPolicyPipe } from './qos-policy.pipe';
import { RecoveryActionIconPipe } from './recovery-action-icon.pipe';
import { RecoveryObjectIconPipe } from './recovery-object-icon.pipe';
import { S3KeyMappingPipe } from './s3-key-mapping.pipe';
import { SimpleListPipe } from './simple-list.pipe';
import { SnapshotLocationPipe } from './snapshot-location.pipe';
import { SourceGroupIconPipe } from './source-group-icon.pipe';
import { TenantIdFormatterPipe } from './tenant-id-formatter.pipe';
import { TimeObjectToDatePipe } from './time-object-to-date.pipe';
import { UserPrivilegesPipe } from './user-privileges.pipe';
import { ValueChangeTrendPipe } from './value-change-trend.pipe';
import { NgViewBoxCompressionPipe } from './view-box-compression.pipe';
import { NgViewBoxDedupeStatusPipe } from './view-box-dedupe-status.pipe';
import { NgViewBoxEncryptionPipe } from './view-box-encryption.pipe';
import { NgViewBoxRedundancyPipe } from './view-box-redundancy.pipe';
import { FilterByPipe } from './filter-list-pipe';

const PIPES = [
  BackupTargetIconPipe,
  BackupTargetTypePipe,
  BytesFractionPipe,
  CdpIoFilterIconPipe,
  DatePipeWrapper,
  DayCountPipe,
  DurationPipe,
  FileIconPipe,
  FilterByPipe,
  HighlightTextPipe,
  HumanizeDurationPipe,
  HumanizeFromDaysPipe,
  IsJobRunFinishedPipe,
  LabelPipe,
  LargeNumberPipe,
  NgViewBoxCompressionPipe,
  NgViewBoxDedupeStatusPipe,
  NgViewBoxEncryptionPipe,
  NgViewBoxRedundancyPipe,
  ObjectIconPipe,
  OrgIdSplitPipe,
  OrgIdDistilledPipe,
  PrincipalIconPipe,
  PrincipalNamePipe,
  QosPolicyPipe,
  RecoveryActionIconPipe,
  RecoveryObjectIconPipe,
  S3KeyMappingPipe,
  SimpleListPipe,
  SnapshotLocationPipe,
  SourceGroupIconPipe,
  StatusIconMapPipe,
  StatusIconReducePipe,
  TenantIdFormatterPipe,
  TimeObjectToDatePipe,
  UserPrivilegesPipe,
  ValueChangeTrendPipe
];

const IMPORT_EXPORT_MODULES = [
  UtilPipesModule,
];

/**
 * Pipes Module.
 */
@NgModule({
  imports: [IMPORT_EXPORT_MODULES, TranslateModule.forChild()],
  declarations: PIPES,
  exports: [PIPES, IMPORT_EXPORT_MODULES],
  providers: PIPES,
})
export class PipesModule {}

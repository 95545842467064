// NOTE: Using styledMode for chart theming requires that a `cog-chart` class is
// added that wraps the chart. This is needed to prevent the highcharts styles
// from being applied erroneously to AngularJS cCharts implementations. This
// can be applied via hostBinding:
// @HostBinding('attr.class') cssClass = 'cog-chart';
import './plugins/tooltip-styles';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import { HighchartsChartModule } from 'highcharts-angular';
import * as highchartsMore from 'highcharts/highcharts-more';
import * as heatmap from 'highcharts/modules/heatmap';
import * as highchartsSolidGauge from 'highcharts/modules/solid-gauge';
import * as highchartsSunburst from 'highcharts/modules/sunburst';
import * as tree from 'highcharts/modules/treemap';
import * as highchartsWordcloud from 'highcharts/modules/wordcloud';

import { AngularMaterialModule } from '../../angular-material.module';
import { SpinnerModule } from '../spinner/spinner.module';
import { BarChartComponent } from './bar-chart/bar-chart.component';
import { BubbleChartComponent } from './bubble-chart/bubble-chart.component';
import { BubbleMapComponent } from './bubble-map/bubble-map.component';
import { DonutChartComponent } from './donut-chart/donut-chart.component';
import { GaugeChartComponent } from './gauge-chart/gauge-chart.component';
import { HeatmapChartComponent } from './heatmap-chart/heatmap-chart.component';
import { LineChartComponent } from './line-chart/line-chart.component';
import { PieChartComponent } from './pie-chart/pie-chart.component';
import { PolarChartComponent } from './polar-chart/polar-chart.component';
import { ProgressDonutChartComponent } from './progress-donut-chart/progress-donut-chart.component';
import { SparklineChartComponent } from './sparkline-chart/sparkline-chart.component';
import { StackedChartComponent } from './stacked-chart/stacked-chart.component';
import { SunburstChartComponent } from './sunburst-chart/sunburst-chart.component';
import { ThermometerChartComponent } from './thermometer-chart/thermometer-chart.component';
import { TreemapChartComponent } from './treemap-chart/treemap-chart.component';
import { TreemapLegendComponent } from './treemap-chart/treemap-legend.component';

const COMPONENTS = [
  BarChartComponent,
  BubbleChartComponent,
  BubbleMapComponent,
  DonutChartComponent,
  GaugeChartComponent,
  HeatmapChartComponent,
  LineChartComponent,
  PieChartComponent,
  ProgressDonutChartComponent,
  PolarChartComponent,
  SparklineChartComponent,
  StackedChartComponent,
  SunburstChartComponent,
  ThermometerChartComponent,
  TreemapChartComponent,
  TreemapLegendComponent,
];

// The AOT compiler does not support function expressions and arrow functions,
// also called lambda functions.
// https://angular.io/guide/aot-compiler#no-arrow-functions
export function highchartsModulesFactory(): any[] {
  return [
    heatmap.default || heatmap,
    highchartsMore.default || highchartsMore,
    highchartsSolidGauge.default || highchartsSolidGauge,
    highchartsSunburst.default || highchartsSunburst,
    tree.default || tree,
    highchartsWordcloud.default || highchartsWordcloud,
  ];
}

@NgModule({
  providers: [
    {
      provide: HIGHCHARTS_MODULES,
      useFactory: highchartsModulesFactory
    },
  ],
  imports: [
    AngularMaterialModule,
    ChartModule,
    CommonModule,
    HighchartsChartModule,
    SpinnerModule,
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class ChartsModule { }

import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectionStrategy, Component, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DataTreeNodeContext, DataTreeNodeDetail } from '@cohesity/helix';
import { AutoDestroyable } from '@cohesity/utils';
import { NodeSelectionType, NodeSettingsType } from 'src/app/shared/constants';
import {
  OracleNodeSettingsComponent,
} from 'src/app/shared/oracle/components/oracle-node-settings/oracle-node-settings.component';

import { OracleSourceDataNode } from '../oracle-source-data-node';

/**
 * Default value for oracle node settings.
 */
const DefaultOracleChannelSettings = {
  channelOption: NodeSelectionType.Auto,
  setDbCredentials: false,
  dbCredentials: {
    password: null,
    username: null,
  },
  setArchiveDelete: false,
  archiveLogRetentionDays: 1,
  continueDgPrimaryBackup: true,
  archiveLogRetentionHours: 1,
  backupOnDgRole: null,
  allowBackupArchivelogOnAnyRole: false,
};

/**
 * TODO(tauseef): Figure out how this will reused during the recovery flow. If needed abstract out the
 * Oracle Mnmc settings component out of the <coh-source-special-parameters/>.
 */
@Component({
  selector: 'coh-oracle-channel-settings',
  templateUrl: './oracle-channel-settings.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class OracleChannelSettingsComponent extends AutoDestroyable
  implements DataTreeNodeDetail<OracleSourceDataNode> {
  /**
   * The node context, including info about the node and it's selection status.
   */
  @Input() nodeContext: DataTreeNodeContext<OracleSourceDataNode>;

  /**
   * Specifies if node settings is for Backup/Recovery.
   */
  @Input() nodeSettingsFor = NodeSettingsType.Backup;

  /**
   * Channel configuration label.
   */
  @Input() selectionLabel: string;

  /**
   * OracleNodeSettingsComponent template reference.
   */
  @ViewChild(OracleNodeSettingsComponent) nodeSettings: OracleNodeSettingsComponent;

  /**
   * The form to contain the options.
   */
  form: UntypedFormGroup;

  /**
   * Stores the selected Oracle hosts for Multi node Multi channel support.
   */
  selectedDatabaseHostList = new SelectionModel<any>(true, []);

  constructor(
    private formBuilder: UntypedFormBuilder,
  ) {
    super();
    this.form = this.formBuilder.group({
      channelOption: NodeSelectionType.Auto,
      databaseHostFormArray: this.formBuilder.array([], Validators.required),
      setDbCredentials: false,
      dbCredentials: this.formBuilder.group({
        password: [{ value: '', disabled: true }, Validators.required],
        username: [{ value: '', disabled: true }, Validators.required],
      }),
      setArchiveDelete: false,
      archiveLogRetentionDays: [{ value: 1, disabled: true }, [
        Validators.required,
        Validators.min(0),
      ]],
      archiveLogRetentionHours: [{ value: 1}, [
        Validators.required,
        Validators.min(0),
      ]],
      continueDgPrimaryBackup: true,
      backupOnDgRole: null,
      allowBackupArchivelogOnAnyRole: false,
    });
  }

  /**
   * Handles the save of the Multi-node/multi channel settings.
   */
  onSave() {
    this.nodeSettings.onSaved();
  }

  /**
   * Update node settings form.
   */
  updateForm() {
    this.nodeSettings.updateForm();
  }

  /**
   * Method called on cancelling MNMC dialog component.
   */
  onCancel() {

    // Resetting form group to default value.
    this.nodeSettings.form.patchValue(DefaultOracleChannelSettings);
  }
}

import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { VlanParameters } from '@cohesity/api/v1';
import {
  CommonPreBackupScriptParams,
  HostBasedBackupScriptParams,
  IndexingPolicy,
  PhysicalFileProtectionGroupParams,
  PhysicalVolumeProtectionGroupParams,
  PrePostScriptParams,
} from '@cohesity/api/v2';
import { IrisContextService, isMcm } from '@cohesity/iris-core';
import { difference, get } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';
import { ClusterInterface, Environment, HostType } from 'src/app/shared';
import { ProtectionSourceDataNode } from 'src/app/shared/source-tree/protection-source';
import { getDateRangeFilter } from '@cohesity/helix';

import {
  CommonPreOrPostScript,
  Indexing,
  RemotePrePostScripts,
  SourceSideDeduplication,
} from '../../protection-builder/models';
import { StateParams } from '@uirouter/core';

/**
 * Interface defining the return type of Time Range
 */
interface TimeRange {
  /**
   * Start time of the query.
   */
  startTimeUsecs: number;

  /**
   * End time of the query.
   */
  endTimeUsecs: number;
}

/**
 * Type for API pre/post script params.
 */
type ApiPrePostScriptParams = HostBasedBackupScriptParams | PrePostScriptParams;

/**
 * A mapping of environment to field names within the ProtectionGroup model
 * for a given environment that contain the 'objects' entry.
 */
const paramTypesForEnvironment = {
  [Environment.kGCP]: [
    'nativeProtectionTypeParams',
  ],
  [Environment.kAWS]: [
    'agentProtectionTypeParams',
    'auroraProtectionTypeParams',
    'rdsPostgresProtectionTypeParams',
    'dynamoDBProtectionTypeParams',
    'nativeProtectionTypeParams',
    'rdsProtectionTypeParams',
    's3ProtectionTypeParams',
    'snapshotManagerProtectionTypeParams'
  ],
  [Environment.kAzure]: [
    'agentProtectionTypeParams',
    'nativeProtectionTypeParams',
    'snapshotManagerProtectionTypeParams'
  ],
  [Environment.kPhysical]: [
    'fileProtectionTypeParams',
    'volumeProtectionTypeParams'
  ],
  [Environment.kSQL]: [
    'fileProtectionTypeParams',
    'volumeProtectionTypeParams',
    'nativeProtectionTypeParams'
  ]
};

@Injectable({
  providedIn: 'root',
})
export class ProtectionUtilsService {

  constructor(private irisContext: IrisContextService) {
  }

  /**
   * Transforms Indexing policy from Protection Group form value to API
   * request
   *
   * @param   indexingParams   Form Indexing parameters
   */
  transformIndexingPolicy(indexingParams: Indexing): IndexingPolicy {
    if (!indexingParams) {
      return;
    }

    return {
      enableIndexing: Boolean(indexingParams.enabled),
      includePaths: get(indexingParams, 'paths.include', []),
      excludePaths: get(indexingParams, 'paths.exclude', []),
    };
  }

  /**
   * Converts the API response indexing policy to form value
   *
   * @param    indexingPolicy   API response indexing object
   * @return   Returns form value of indexing Policy
   */
  untransformIndexingPolicy(indexingPolicy: IndexingPolicy): Indexing {
    if (!indexingPolicy) {
      return;
    }
    return {
      enabled: Boolean(indexingPolicy.enableIndexing),
      paths: {
        include: indexingPolicy.includePaths || [],
        exclude: indexingPolicy.excludePaths || [],
      }
    };
  }

  /**
   * Transforms pre/post Scripts from Protection Group form value to API
   * request
   *
   * @param    prePostScriptParams Form pre/post parameters
   * @return   Returns pre/post Scripts request object
   */
  transformPrePostScripts(prePostScriptParams: CommonPreOrPostScript): CommonPreBackupScriptParams {
    return {
      path: prePostScriptParams.scriptName,
      params: prePostScriptParams.scriptParams,
      timeoutSecs: prePostScriptParams.timeout ? prePostScriptParams.timeout * 60 :
        prePostScriptParams.timeout,
      continueOnError: prePostScriptParams.continueEnabled,
    };
  }

  /**
   * Converts the API response pre/post params to form value
   *
   * @param    prePostScriptParams   API response pre/post object
   * @return   Returns form value of pre or post script
   */
  untransformPrePostScripts(prePostScriptParams: CommonPreBackupScriptParams): CommonPreOrPostScript {
    return {
      scriptName: prePostScriptParams.path,
      scriptParams: prePostScriptParams.params,
      timeout: prePostScriptParams.timeoutSecs ? prePostScriptParams.timeoutSecs / 60 :
        prePostScriptParams.timeoutSecs,
      continueEnabled: prePostScriptParams.continueOnError,
    };
  }

  /**
   * Transforms source side deduplication from Protection Group form value to
   * API request
   *
   * @param    sourceSideDeduplication Form source side deduplication parameters
   * @return   Returns source side deduplication request object
   */
  transformSourceSideDeduplication(sourceSideDeduplication: SourceSideDeduplication) {
    return {
      performSourceSideDeduplication: sourceSideDeduplication.enabled,
      dedupExclusionSourceIds: sourceSideDeduplication.exclusions,
    };
  }

  /**
   * Converts the API response source side deduplication params to form value
   *
   * @param    volumeParams   API response object
   * @return   Returns form value of source side deduplication script
   */
  untransformSourceSideDeduplication(
    volumeParams: PhysicalVolumeProtectionGroupParams | PhysicalFileProtectionGroupParams
  ): SourceSideDeduplication {
    return {
      enabled: Boolean(volumeParams.performSourceSideDeduplication),
      exclusions: volumeParams.dedupExclusionSourceIds || [],
    };
  }

  /**
   * Transforms vlan from Protection Group form value to API request.
   *
   * @param    vlan Form vlan parameters
   * @return   Returns vlan param request object
   */
  transformVlanParams(vlan: ClusterInterface): VlanParameters {
    let result;

    if (!vlan.auto) {
      if (vlan.interfaceGroup.id === null) {
        result = {
          disableVlan: true,
        };
      } else {
        result = {
          vlanId: vlan.interfaceGroup.id,
          interfaceName: vlan.interfaceGroup.ifaceGroupName.split('.')[0],
        };
      }
    }
    return result;
  }

  /**
   * Converts Pre/Post Scripts API model to Form model
   *
   * @param    prePostScript   Pre/Post Scripts API model
   * @return   Returns form value of Pre/Post scripts setting
   */
  getPrePostScriptsFormModel(prePostScript: ApiPrePostScriptParams): RemotePrePostScripts {
    if (!prePostScript) {
      return;
    }

    const prePostScriptsForm: RemotePrePostScripts = {};

    const { host, postBackupScript, postSnapshotScript } = prePostScript as HostBasedBackupScriptParams;

    if (host) {
      prePostScriptsForm.remoteScriptsToggle = true;
      prePostScriptsForm.remoteHost = {
        address: host.hostname,
        username: host.username
      };
    }

    if (postBackupScript) {
      prePostScriptsForm.postBackupScriptToggle = true;
      prePostScriptsForm.postBackupScript = this.untransformPrePostScripts(postBackupScript);
    }

    if (postSnapshotScript) {
      prePostScriptsForm.postSnapshotScriptToggle = true;
      prePostScriptsForm.postSnapshotScript = this.untransformPrePostScripts(postSnapshotScript);
    }

    if (prePostScript.preScript) {
      prePostScriptsForm.preScriptToggle = true;
      prePostScriptsForm.preScript = this.untransformPrePostScripts(prePostScript.preScript);
    }

    if (prePostScript.postScript) {
      prePostScriptsForm.postScriptToggle = true;
      prePostScriptsForm.postScript = this.untransformPrePostScripts(prePostScript.postScript);
    }

    // Set the form values if available or set it to undefined. Empty object renders
    // the form incorrectly.
    return (prePostScriptsForm.preScript || prePostScriptsForm.postScript ||
      prePostScriptsForm.postBackupScript ||
      prePostScriptsForm.postSnapshotScript) ? prePostScriptsForm : undefined;
  }

  /**
   * Converts Pre/Post Scripts Form model to API model
   *
   * @param    prePostScriptsForm   Pre/Post Scripts form model
   * @return   Returns API value of Pre/Post scripts setting
   */
  getPrePostScriptsRequestModel(prePostScriptsForm: RemotePrePostScripts): ApiPrePostScriptParams {
    if (!prePostScriptsForm) {
      return;
    }

    const prePostScripts: ApiPrePostScriptParams = {};

    if (prePostScriptsForm.remoteScriptsToggle) {
      (prePostScripts as HostBasedBackupScriptParams).host = {
        hostname: prePostScriptsForm.remoteHost.address,
        username: prePostScriptsForm.remoteHost.username,
        hostType: prePostScriptsForm.remoteHost.hostType || HostType.kLinux,
      };
    }

    if (prePostScriptsForm.preScriptToggle) {
      prePostScripts.preScript =
        this.transformPrePostScripts(prePostScriptsForm.preScript);
    }

    if (prePostScriptsForm.postScriptToggle) {
      prePostScripts.postScript =
        this.transformPrePostScripts(prePostScriptsForm.postScript);
    }

    if (prePostScriptsForm.postBackupScriptToggle) {
      (prePostScripts as HostBasedBackupScriptParams).postBackupScript =
        this.transformPrePostScripts(prePostScriptsForm.postBackupScript);
    }

    if (prePostScriptsForm.postSnapshotScriptToggle) {
      (prePostScripts as HostBasedBackupScriptParams).postSnapshotScript =
        this.transformPrePostScripts(prePostScriptsForm.postSnapshotScript);
    }

    // Send pre/post scripts only if one of the scripts is present. If only host params
    // are sent or an empty object is sent, it results in error.
    return (prePostScripts.preScript || prePostScripts.postScript ||
      (prePostScripts as HostBasedBackupScriptParams).postBackupScript ||
      (prePostScripts as HostBasedBackupScriptParams).postSnapshotScript) ?
      prePostScripts : undefined;
  }

  /**
   * Function to update protection items behavior subject by removing list of
   * protection items names provided.
   *
   * @param formGroup Form group to remove controls from.
   * @param items Array of all the protection items.
   * @param itemsSubject$ Behavior subject of all the protection items.
   * @param itemsToRemove List of items to be removed.
   */
  removeProtectionItems<T, R>(
    formGroup: UntypedFormGroup,
    items: T[],
    itemsSubject$: BehaviorSubject<T[]>,
    itemsToRemove: R[],
  ) {
    // Update the protection items behavior subject with the new value
    itemsSubject$.next(items.filter(
      protectionItem => !itemsToRemove.includes((protectionItem as any).name)
    ));

    // Remove the form controls of the items which were removed. If they get
    // added back, the form controls are attached by the form component's init
    // method. They need to be removed so that the form remains valid.
    for (const itemToRemove of itemsToRemove) {
      if (formGroup?.get(itemToRemove as any)) {
        formGroup.removeControl(itemToRemove as any);
      }
    }

    // If nothing was removed, update the protection items behavior subject to
    // have initial set of protection items.
    if (!itemsToRemove.length && itemsSubject$.value !== items) {
      itemsSubject$.next(items);
    }
  }

  /**
   * Given the form value, determine whether object protection should be enabled or not.
   * This only applies to cases where objects could be protected as individual objects or
   * as groups. In DMS, only object protection is enabled.
   *
   * @param   selectedObjects  The list of currently selected objects
   * @param   envFlag   The env specific flag to check.
   * @returns Whether object protection is available.
   */
  shouldAllowObjectProtection(selectedObjects: ProtectionSourceDataNode[], envFlag: string): boolean {
    if (!selectedObjects?.length) {
      return false;
    }

    // Fail if the global or env-specific feature flag is not enabled
    const { featureFlags } = this.irisContext.irisContext;

    // Object protection is individually enabled in Helios (DMaaS) and OnPrem.
    const featureFlagEnabled = isMcm(this.irisContext.irisContext)
      ? featureFlags.objectProtectionDmsEnabled
      : featureFlags.objectProtectionOnPremEnabled;

    if (!featureFlagEnabled || !envFlag || !featureFlags[envFlag]) {
      return false;
    }

    // Fail if the objects are already protected
    if (selectedObjects.find(object => object.protected)) {
      return false;
    }
    return true;
  }

  /**
   * This method returns all the objects within param. Sometimes the objects
   * are nested within other fields, so this function extracts them.
   *
   * @param param the protectionGroup model for the environment.
   * @param environment the adapter's Environment.
   */
  getObjectsForEnvironment(param, environment) {
    const extractObjects = (typeParams) => {
      const objects_ = [];

      if (typeParams) {
        objects_.push(
          ...typeParams.objects.map((object) => {
            if (object) {
              if (!object.name) {
                objectNamesToFetch.push(object.id);
              } else {
                objectNamesMap[object.id] = object.name;
              }
            }
            return object.id;
          })
        );
      }

      return { objects: objects_ };
    };

    const objects = [];
    const objectNamesToFetch = [];
    const objectNamesMap = {};

    for (const paramType of  paramTypesForEnvironment[environment]) {
      objects.push(...extractObjects(param[paramType]).objects);
    }

    return {
      objectNamesMap,
      objectNamesToFetch,
      objects,
    };
  }

  /**
   * Wrapper for the difference function from lodash. It takes additional params
   * to model the return object.
   *
   * @param set1 Set of objects to be compared with set2.
   * @param set2 Set of objects to be compared withn set1.
   * @param objectNamesToFetch If set1/ set2 contains an object with no entry
   * for name. add it to this list.
   * @param objectNamesMap If set1/ set2 contains an object with entry
   * for name. add it to this dict.
   * @returns an Object.
   */
  getDifference(set1, set2, objectNamesToFetch, objectNamesMap) {
    return difference(set1, set2).map((each: number) => {
      if (!objectNamesToFetch.includes(each)) {
        return {
          name: objectNamesMap[each],
          id: each,
        };
      }
      return {
        name: undefined,
        id: each,
      };
    });
  }

  /**
   * This function takes in a object of type StateParams and returns an interface
   * of type TimeRange
   *
   * @param urlParams: the state params from the UI router globals object.
   * @returns an instance of the TimeRange interface.
   */
  getStartEndTimeFromUrl(urlParams: StateParams): TimeRange {
    const { timeRange, rangeStart, rangeEnd } = urlParams;
    if (rangeStart && rangeEnd) {
      return {
        startTimeUsecs: rangeStart * 1000,
        endTimeUsecs: rangeEnd * 1000,
      };
    }

    if (timeRange) {
      const range = getDateRangeFilter(timeRange);
      return {
        startTimeUsecs: range.start.valueOf() * 1000,
        endTimeUsecs: range.end.valueOf() * 1000,
      };
    }

    return undefined;
  }
}

<mat-form-field [class.loading]="loading" [floatLabel]="floatLabel">
  <mat-label>{{'storageDomain' | translate}}</mat-label>
  <cog-spinner *ngIf="loading"
    [inline]="true"
    matPrefix
    size="xs">
  </cog-spinner>
  <mat-select
    id="select-storage-domain"
    panelClass="cog-mat-select-panel-lg"
    cogDataId="select-storage-domain"
    (valueChange)="value = $event; hideRecommendedInfo = false;"
    [value]="value"
    [disabled]="readOnly">
    <mat-select-trigger *ngIf="selectedViewBox">
      {{selectedViewBox.name}}
      <span *ngIf="selectedViewBox.recommended">({{'recommended' | translate}})</span>
      <ng-container
        *ngTemplateOutlet="metaTemplate; context:{$implicit: selectedViewBox}">
      </ng-container>
    </mat-select-trigger>

    <mat-option *ngIf="allowSearch"
      [inheritDataId]="true"
      cogDataId="option-search">
      <ngx-mat-select-search
        [formControl]="searchCtrl"
        [noEntriesFoundLabel]="'noMatchingItems' | translate"
        [placeholderLabel]="'search' | translate">
      </ngx-mat-select-search>
    </mat-option>

    <div class="padding-top recommended-group" *ngIf="recommendedViewBoxes?.length">
      <div class="padding-left padding-bottom group-label">
        {{'recommended' | translate}} ({{recommendedViewBoxes?.length}})
      </div>
      <mat-option
        *ngFor="let viewbox of recommendedViewBoxes; index as i"
        [cogDataId]="'option-' + viewbox.name | lowercase"
        inheritDataId="true"
        id="select-storage-domain-{{i}}" [value]="viewbox.id">
        {{viewbox.name}}
        <coh-storage-domain-synopsis [storageDomain]="viewbox">
        </coh-storage-domain-synopsis>
      </mat-option>
    </div>

    <div class="padding-top other-group" *ngIf="unrecommendedViewBoxes?.length">
      <div class="padding-left padding-bottom group-label">
        {{'others' | translate}} ({{unrecommendedViewBoxes?.length}})
      </div>
      <mat-option *ngFor="let viewbox of unrecommendedViewBoxes; index as i"
        [cogDataId]="'option-' + viewbox.name | lowercase"
        inheritDataId="true"
        id="select-storage-domain-{{i}}" [value]="viewbox.id">
        {{viewbox.name}}
        <coh-storage-domain-synopsis [storageDomain]="viewbox">
        </coh-storage-domain-synopsis>
      </mat-option>
    </div>

    <mat-option #add (click)="!add.disabled ? addStorage() : undefined"
      class="sticky-option bottom"
      cogDataId="add-sd-button"
      [disabled]="!('CLUSTER_MODIFY' | userPrivileges)">
      <coh-add-item-option>
        {{'addViewBox' | translate}}
      </coh-add-item-option>
    </mat-option>
  </mat-select>
</mat-form-field>

<!-- Recommended settings description -->
<ng-container *ngTemplateOutlet="recommendedTemplate; context:{$implicit: selectedViewBox,
  template: template}">
</ng-container>

<ng-template #recommendedTemplate let-viewbox let-template="template">

  <ng-container *ngIf="!loading">
    <!-- Recommended storage domain information template -->
    <div *ngIf="showInfoAboutRecommendedStorageDomain(viewbox)"
      cogDataId="recommendedStorageDomains-info-msg"
      class="selected-view flex-row c-message-inline icon-message info">
      <span translate="views.recommendedStorageDomain.description"
        [translateParams]="{
          dedup: this.translate.instant(template.dedup ? 'dedupe' : 'nonDedupe'),
          compression: this.translate.instant(template.compress ? 'compressible' : 'nonCompressible'),
          name: template.name,
          category: this.translate.instant(viewCategoriesMap[template.viewParams.category] || 'naLabel')
        }">
      </span>
      <button mat-icon-button
        type="button"
        cogDataId="recommended-sd-settings-close-action"
        (click)="hideRecommendedInfo = !hideRecommendedInfo;">
        <cog-icon shape="close"></cog-icon>
      </button>
    </div>

    <!-- Add Storage Domain recommended settings template -->
    <div *ngIf="showWarningNoRecommendedStorageDomains()"
      cogDataId="no-recommendedStorageDomains-warning-msg"
      class="selected-view c-message-inline icon-message warn">
      <div class="flex-row">
        <span translate="views.recommendedStorageDomain.doesNotExist"
          [translateParams]="{
            dedup: this.translate.instant(template.dedup ? 'dedupe' : 'nonDedupe'),
            compression: this.translate.instant(template.compress ? 'compressible' : 'nonCompressible'),
            name: template.name,
            category: this.translate.instant(viewCategoriesMap[template.viewParams.category])
          }">
        </span>
        <button mat-icon-button
          type="button"
          cogDataId="recommended-sd-settings-close-action"
          (click)="hideRecommendedInfo = !hideRecommendedInfo;">
          <cog-icon shape="close"></cog-icon>
        </button>
      </div>

      <button mat-button
        class="no-padding"
        type="button"
        color="primary"
        cogDataId="recommended-sd-settings-close-action"
        (click)="!add.disabled ? addStorage() : undefined"
        [disabled]="!('CLUSTER_MODIFY' | userPrivileges)">
        {{'views.addStorageDomainWithRecommendedSettings' | translate}}
      </button>
    </div>
  </ng-container>
</ng-template>

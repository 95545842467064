import { Component, Input } from '@angular/core';
import { HdfsConnectParams, ProtectionSourceTreeInfo } from '@cohesity/api/v1';
import { get } from 'lodash-es';
import { HadoopDistribution } from 'src/app/shared/constants';
import { HadoopSourcesUtilityService } from
 'src/app/modules/sources-shared/services/hadoop-sources-utility.service';

/**
 * HDFS source details settings components. Used for displaying the
 * registered HDFS source's configuration values.
 */
@Component({
  selector: 'coh-hdfs-source-details-settings',
  templateUrl: './source-details-settings.component.html',
  providers: [HadoopSourcesUtilityService]
})
export class HdfsSourceDetailsSettingsComponent {

  constructor(
    public hadoopSourcesUtilityService: HadoopSourcesUtilityService
  ) { }

  /**
   * Input source.
   */
  @Input() source: ProtectionSourceTreeInfo;

  /**
   * Local version of HadoopDistribution type for use within the template.
   */
  hadoopDistribution = HadoopDistribution;

  /**
   * Getter for retrieving hdfsParams from source input.
   */
  get hdfsParams(): HdfsConnectParams {
    return get(this.source, 'registrationInfo.hdfsParams') as HdfsConnectParams;
  }

  /**
   * Get the HDFS Port label from HadoopSourcesUtilityService.
   *
   * @returns HDFS Port label string.
   */
  getHdfsPortLabel(): string {
    return this.hadoopSourcesUtilityService.getHdfsPortLabelStr(
        this.hdfsParams?.hdfsConnectionType);
  }
}

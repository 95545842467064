import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { CommonPrePostCloudScriptParams } from '@cohesity/api/v2';
import { CreateForm, createFormProviders } from '@cohesity/shared-forms';
import { difference } from 'lodash-es';

/**
 * The type of shells available.
 */
type ShellType = 'windows' | 'unix';

/**
 * Interface for Script Config Form.
 */
export interface ScriptConfigForm {
  shellType: ShellType;
  path: string;
  parameters: string;
  timeout: number;
  continueOnError: boolean;
}

/**
 * The interface for the parent form group.
 */
export interface ScriptFormGroupModel {
  enabled: boolean;
  scripts: ScriptConfigForm[];
}

/**
 * Generic form component used for all pre/post/post snapshot scripts.
 */
@Component({
  selector: 'coh-pre-post-script-details-form',
  templateUrl: './pre-post-script-details-form.component.html',
  styleUrls: ['./pre-post-script-details-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: createFormProviders(PrePostScriptDetailsFormComponent),
})

export class PrePostScriptDetailsFormComponent {
  /**
   * The toggle label.
   */
  @Input() label: string;

  /**
   * The scripts form array.
   */
  get scriptsFormArray() {
    return this.form.formGroup.controls.scripts as FormArray;
  }

  /**
   * The scripts form.
   */
  form = new CreateForm<CommonPrePostCloudScriptParams, ScriptFormGroupModel>(this, {
    formControls: {
      enabled: new FormControl(false),
      scripts: new FormArray([]),
    },
    transformToFormGroup: this.transformToFormGroup.bind(this),
    transformFromFormGroup: this.transformFromFormGroup.bind(this),
  });

  /**
   * The avialable shells types.
   */
  shellTypeOptions: ShellType[] = ['windows', 'unix'];

  /**
   * Add the script config form value.
   *
   * @param scriptValue Optional, script config form value.
   */
  addScript(scriptValue?: ScriptConfigForm) {
    let shellTypeValue = scriptValue?.shellType;

    // If no default value is provided, then add the other valid shell type.
    if (!shellTypeValue) {
      // On adding a new script, the value for shellType should be the other
      // available value.
      shellTypeValue = difference(this.shellTypeOptions,
        this.scriptsFormArray.value.map(({ shellType }) => shellType))[0];
    }

    const formGroup = new FormGroup({
      shellType: new FormControl(shellTypeValue),
      path: new FormControl(scriptValue?.path, Validators.required),
      parameters: new FormControl(scriptValue?.parameters),
      timeout: new FormControl(scriptValue?.timeout ?? 15),
      continueOnError: new FormControl(scriptValue?.continueOnError ?? true),
    });

    this.scriptsFormArray.push(formGroup);
  }

  /**
   * Disable the shell type option based on the condition if the other shell is
   * already selected.
   *
   * @param index The formArray index.
   * @param option The option to enable/disable
   * @returns True, if we are disabling the option.
   */
  disableShellTypeOption(index: number, option: ShellType) {
    const otherIndex = index === 1 ? 0 : 1;
    return this.scriptsFormArray.at(otherIndex)?.value?.shellType === option;
  }

  /**
   * Removes the script config.
   *
   * @param index The index of the script formArray.
   */
  removeScript(index: number) {
    this.scriptsFormArray.removeAt(index);
  }

  /**
   * Whether the add action is disabled or not. Currently we only allow adding
   * max of two configs for each shell type.
   *
   * @returns True if the action is disabled.
   */
  addActionDisabled() {
    return this.scriptsFormArray.length === 2;
  }

  /**
   * On toggling the script form, add a default script config when there's no
   * script forms added yet.
   *
   * @param event The mat slide toggle change event.
   */
  toggleScriptForm(event: MatSlideToggleChange) {
    if (event.checked && !this.scriptsFormArray.length) {
      this.addScript();
    }

    // Update the validations on toggling the whole script.
    this.scriptsFormArray.controls.forEach(control => {
      const pathControl = (control as FormGroup).controls.path;

      if (!event.checked) {
        pathControl.clearValidators();
        pathControl.setErrors(null);
      } else {
        pathControl.setValidators(Validators.required);
      }

      pathControl.updateValueAndValidity();
    });
  }

  /**
   * Transforms the API model to form friendly values.
   *
   * @param value The API value
   * @returns The form group value.
   */
  transformToFormGroup(value: CommonPrePostCloudScriptParams): ScriptFormGroupModel {
    if (!value) {
      return this.form.formGroup.getRawValue();
    }

    const { linuxScript, windowsScript } = value ?? {};

    return {
      enabled: !!(linuxScript || windowsScript),
      scripts: [linuxScript, windowsScript]
        .filter(Boolean)
        .map(script => {
          const scriptValue: ScriptConfigForm = {
            shellType: script === linuxScript ? 'unix' : 'windows',
            path: script.path ?? null,
            parameters: script.params ?? null,
            continueOnError: script.continueOnError ?? true,
            timeout: script.timeoutSecs ?? 15,
          };

          this.addScript(scriptValue);
          return scriptValue;
        }),
    };
  }

  /**
   * Transforms the value from the form group to the API model.
   *
   * @param value The form group value.
   * @returns The API model value.
   */
  transformFromFormGroup(value: ScriptFormGroupModel): CommonPrePostCloudScriptParams {
    if (!value.enabled) {
      return null;
    }

    return value.scripts.reduce((config, script) => {
      config[script.shellType === 'windows' ? 'windowsScript' : 'linuxScript'] = {
        continueOnError: script.continueOnError,
        params: script.parameters,
        path: script.path,
        timeoutSecs: script.timeout,
      };

      return config;
    }, {} as CommonPrePostCloudScriptParams);
  }
}

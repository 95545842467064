<h5 class="title">{{'protectionDetails' | translate}}</h5>

<!-- Fallback if there is no info service-->
<div *ngIf="!infoService">
  <ng-container *ngIf="!isNodeProtected">
    {{'sourceTreePub.tooltips.notProtected' | translate}}
  </ng-container>
</div>

<!-- Compliance and group info -->
<cog-spinner *ngIf="(protectionInfo$ | async)?.loading || (objectInfo$ | async)?.loading" size="xs"></cog-spinner>
<div class="flex-row">
  <coh-component-loader *ngIf="(protectionInfo$ | async)?.info; let info" componentId="policy-compliance"
    [componentInputs]="{
      protectionInfo: info
    }">
  </coh-component-loader>
  <ul class="margin-left-sm">
    <li *ngIf="isNodeProtected">
      {{protectionLabel ? protectionLabel : 'sourceTreePub.tooltips.protected' | translate}}
    </li>
    <li *ngIf="(protectionInfo$ | async)?.info?.object?.lastRun?.localSnapshotInfo?.snapshotInfo?.startTimeUsecs;
          let startTimeUsecs">
      <small> {{ "lastBackup" | translate }} : {{ startTimeUsecs | cogDate }} </small>
    </li>
    <li *ngIf="node?.protectionSource?.environment === environment.kPhysical
      && (objectInfo$ | async); let objectInfo">
      <div class="margin-top-lg" *ngIf="objectInfo?.item?.lastRuns">
        <h6>{{'protectedByColon' | translate}}</h6>
        <div class="flex-row align-center" *ngFor="let object of objectInfo?.item?.lastRuns">
          <coh-component-loader *ngIf="object" componentId="policy-compliance" [componentInputs]="{
            protectionInfo: {
              object: object,
              node: node,
              loadingError: objectInfo.error,
              isProtected: true
            }
          }">
          </coh-component-loader>
          <a cogDataId="protection-group-{{object.lastRun.protectionGroupName}}" *ngIf="object?.lastRun"
            class="no-margin-bottom" uiSref="protection-group.group" [uiParams]="{
              groupId: object.lastRun.protectionGroupId,
              fromPage: pageIndex + 1,
              regionId: object.lastRun.regionId
            }" (click)="popoverRef?.close()">
            <span class="user-data-lg auto-truncate">
              {{object.lastRun.protectionGroupName}}
            </span>
          </a>
        </div>
      </div>
    </li>
  </ul>
</div>

<ng-container *ngIf="node?.protectionSource?.environment !== environment.kPhysical
  && (objectInfo$ | async)?.item?.protectionGroupConfigurations; let groups">
  <h6>{{'protectedByColon' | translate}}</h6>
  <a *ngFor="let group of groups"
    cogDataId="protection-group-{{group.protectionGroupName}}"
    uiSref="protection-group.group"
    [uiParams]="{
      groupId: group.protectionGroupId,
      fromPage: pageIndex + 1,
      regionId: group.regionId
    }"
    (click)="popoverRef?.close()">
    <cog-icon [shape]="{status: node.protectionSource.environment, type: 'environment'} | statusIconMap">
    </cog-icon>
    <span>
      {{group.protectionGroupName}}
    </span>
  </a>
</ng-container>

<!-- Object auto protection if applicable -->
<ng-container *ngIf="protectedParent$ | async; let parentStore">
  <cog-spinner *ngIf="parentStore.loading" size="xs"></cog-spinner>

  <ng-container *ngIf="parentStore.item; let parent">
    <h6>{{'autoProtectedByColon' | translate}}</h6>
    <a uiSref="object-details.landing"
      [uiParams]="{
        id: parent.id,
        cid: passthroughOptionsService.accessClusterId,
        regionId: passthroughOptionsService.regionId
      }"
      cogDataId="parent-object-details-{{parent.name}}"
      (click)="popoverRef?.close()">
      {{parent.name}}
    </a>
  </ng-container>
</ng-container>

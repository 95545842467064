import { NgIf } from '@angular/common';
import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { MatLegacyCardModule } from '@angular/material/legacy-card';
import { SpinnerComponent } from '../spinner/spinner.component';

/**
 * A dashcard component should display as a single card/tile on a dashboard. It would ideally make use of one of the
 * complimentary layout components which make use of the dummy components listed below  for content projection. Which
 * dummy components should be used will vary by the particular layout used.
 *
 * @example
 *   Example Usage:
 *   <cog-dashcard [title]="'health' | translate">
 *    <cog-dashcard-header-aux>AUXILIARY HEADER</cog-dashcard-header-aux>
 *     <cog-dashcard-layout-standard>
 *       <cog-dashcard-top>
 *          TOP CONTENT HERE
 *       </cog-dashcard-top>
 *       <cog-dashcard-chart>
 *         CHART CONTENT HERE
 *       </cog-dashcard-chart>
 *       <cog-dashcard-legend>
 *         LEGEND CONTENT HERE
 *       </cog-dashcard-legend>
 *     </cog-dashcard-layout-standard>
 *   </cog-dashcard>
 *
 *    Linked Usage:
 *    <a cogDashcard uiSref="alerts">
 *      [alert dashcard content]
 *    </a>
 */

/**
 * This is the primary wrapper component.
 */
@Component({
  selector: 'cog-dashcard, a[cogDashcard]',
  templateUrl: './dashcard.component.html',
  styleUrls: ['./dashcard.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [MatLegacyCardModule, NgIf, SpinnerComponent],
})
export class DashcardComponent {
  /**
   * Add a class to the component for style targeting. This allows shared styles beween standard and attrib selectors.
   */
  @HostBinding('class.cog-dashcard') cogClass = true;

  /**
   * Title to be displayed on the dashcard.
   */
  @Input() title: string;

  /**
   * Subtitle to be displayed on the dashcard.
   */
  @Input() subtitle: string;

  /**
   * When the card is set to loading, the dash card will displaing a loading mask over the entire card
   * and a spinner to the right of the card's title.
   */
  @Input() loading = false;
}

<div class="oracle-channel-settings-container">
  <ul class="c-ul-inline">
    <li *ngIf="node?.protectionSource?.oracleProtectionSource?.version">
      <strong>{{'serverOptions.oracleVersion' | translate}}</strong>
      {{node.protectionSource.oracleProtectionSource.version}}
    </li>
    <li>
      <strong>{{'serverOptions.oracleEnv' | translate}}</strong>
      {{databaseTypeString}}
    </li>
    <li>
      <strong [translate]="'oracle.mnmc.nodesAvailable'"
        [translateParams]="{
          count: channelOption?.value === 'auto' ? 1 : selectedDatabaseHostList.selected?.length
        }">
      </strong>
    </li>
  </ul>
  <ng-container [formGroup]="form">
    <!-- Select Backup Type -->
    <ng-container *ngIf="backupTypeSelectEnabled && isBackupTask">
      <h3>{{'selectBackupType' | translate}}</h3>
      <mat-radio-group formControlName="backupType">
        <mat-radio-button value="kSbt"
          class="margin-bottom"
          cogDataId="backup-type-backup-sets-radio">
          {{'oracle.backupType.sbt' | translate}}
        </mat-radio-button>
        <mat-radio-button value="kImageCopy"
          class="margin-bottom"
          cogDataId="backup-type-image-copy-radio">
          {{'oracle.backupType.imageCopy' | translate}}
        </mat-radio-button>
      </mat-radio-group>
      <mat-divider class="margin-bottom"></mat-divider>
    </ng-container>

    <!-- Channel Option -->
    <h3>{{'oracle.mnmc.configureNodesChannels' | translate}}</h3>
    <mat-radio-group [class.hidden]="!showAutochannelSelection" formControlName="channelOption">
      <mat-radio-button value="auto"
        class="margin-bottom"
        cogDataId="auto-select-node-radio">
        {{'oracle.mnmc.systemSelect' | translate}}
      </mat-radio-button>
      <mat-radio-button value="manual"
        class="margin-bottom"
        cogDataId="manual-select-node-radio">
        {{'oracle.mnmc.userSelect' | translate}}
      </mat-radio-button>
    </mat-radio-group>

    <!-- Global SBT Library path input -->
    <mat-form-field class="global-sbt-library-path" *ngIf="hasGlobalSbtLibraryPath()">
      <mat-label>{{'oracle.mnmc.sbtPath' | translate}}</mat-label>
      <input cogDataId="auto-sbt-path-input"
        matInput
        type="text"
        formControlName="globalSbtLibraryPath">
    </mat-form-field>

    <!-- List of nodes -->
    <cog-table [hidden]="shouldAutoSelectMnmcSettings || !databaseHostFormArray.controls.length"
      name="availableParentNodeListTable"
      formArrayName="databaseHostFormArray"
      [source]="databaseHostFormArray.controls"
      [selection]="selectedDatabaseHostList"
      [isAllSelectedFn]="isAllSelected"
      [canSelectRowFn]="canSelectRowFn"
      [canSelectAnyRowsFn]="canSelectAnyRowsFn"
      [toggleSelectAllFn]="masterToggle">
      <table mat-table>
        <!-- Hostname -->
        <ng-container matColumnDef="fqdn">
          <th mat-header-cell id="db-host-fqdn-col" *matHeaderCellDef>
            {{'hostname' | translate}}
          </th>
          <td mat-cell *matCellDef="let host; let index = index;" [formGroupName]="index">
            <div class="mnmc-node-fqdn-form-field">
              <cog-icon *ngIf="!isNodeSupported(index)"
                class="margin-right-xs"
                shape="helix:status-warning!warning"
                size="sm"
                [matTooltip]="'oracle.mnmc.unSupportedWarning' | translate">
              </cog-icon>
              <cog-icon *ngIf="hasChannelWarning(index)"
                class="margin-right-xs"
                shape="helix:status-warning!warning"
                size="sm"
                [matTooltip]="(isDisasterRecovery ? 'oracle.mnmc.migrationStreamsWarning' :
                  'oracle.mnmc.channelsWarning') | translate">
              </cog-icon>
              {{getNodeName(index)}}
            </div>
          </td>
        </ng-container>

        <!-- Node IP -->
        <ng-container matColumnDef="hostAddress">
          <th mat-header-cell id="db-host-ip-col" *matHeaderCellDef>
            {{'nodeIp' | translate}}
          </th>
          <td mat-cell *matCellDef="let host; let index = index;" [formGroupName]="index">
            {{databaseHostFormArray?.at(index)?.get('hostAddress').value}}
          </td>
        </ng-container>

        <!-- Channels -->
        <ng-container matColumnDef="channelCount">
          <th mat-header-cell id="db-host-channel-count-col" *matHeaderCellDef>
            {{(isDisasterRecovery ? 'migrationStreams' : 'channels') | translate}}
          </th>
          <td mat-cell *matCellDef="let host; let index = index;" [formGroupName]="index">
            <mat-form-field [class.hidden]="isReadOnly ||
              !isNodeSelected(databaseHostFormArray?.at(index).value?.hostId)" class="col-xs-6">
              <input matInput
                cogDataId="input-channel-field-{{index}}"
                type="number"
                min="1"
                max="255"
                formControlName="channelCount">
              <mat-error *ngIf="databaseHostFormArray?.at(index)?.get('channelCount') as channelControl">
                {{mapErrorMessage(channelControl) | translate: { minValue: 1, maxValue: 255 } }}
              </mat-error>
            </mat-form-field>
            <span *ngIf="isReadOnly || !isNodeSelected(databaseHostFormArray?.at(index).value?.hostId)"
              class="padding-left-sm">
              {{databaseHostFormArray?.at(index)?.get('channelCount')?.value}}
            </span>
          </td>
        </ng-container>

        <!-- Port -->
        <ng-container matColumnDef="port">
          <th mat-header-cell cogDataId="db-host-port-col" *matHeaderCellDef>
            {{'port' | translate}}
          </th>
          <td mat-cell *matCellDef="let host; let index = index;" [formGroupName]="index">
            <mat-form-field class="col-xs-12" [class.hidden]="isReadOnly ||
              !isNodeSelected(databaseHostFormArray?.at(index).value?.hostId)">
              <input matInput
                cogDataId="input-port-field-{{index}}"
                type="number"
                min="1"
                max="65535"
                formControlName="port">
              <mat-error *ngIf="databaseHostFormArray?.at(index)?.get('port') as portControl">
                {{mapErrorMessage(portControl) | translate: { minValue: 1 } }}
              </mat-error>
            </mat-form-field>
            <span *ngIf="isReadOnly || !isNodeSelected(databaseHostFormArray?.at(index).value?.hostId)"
              class="padding-left-sm">
              {{databaseHostFormArray?.at(index)?.get('port')?.value}}
            </span>
          </td>
        </ng-container>

        <!-- SBT Library Path -->
        <ng-container matColumnDef="sbtLibraryPath">
          <th mat-header-cell cogDataId="db-host-sbt-library-path-col" *matHeaderCellDef>
            {{'oracle.mnmc.sbtPath' | translate}}
          </th>
          <td mat-cell *matCellDef="let host; let index = index;" [formGroupName]="index">
            <mat-form-field>
              <input matInput
                cogDataId="input-sbt-library-path-field-{{index}}"
                type="string"
                formControlName="sbtLibraryPath">
            </mat-form-field>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let host; columns: displayedColumns;"></tr>
      </table>
    </cog-table>

    <ng-container *ngIf="blockMnmcSelectionForSbtBackupSets">
      <!-- Hidden input added to form group to block Multi node selection for Oracle SBT backup type -->
      <input cogDataId="remove-mnmc-selection" type="hidden" formControlName="removeMnmcSelection">
      <cog-banner status="warn" class="margin-top">
        {{'oracle.mnmc.blockNodeSelectionForSbtBackupSet' | translate}}
      </cog-banner>
    </ng-container>

    <!-- Database Credentials -->
    <div class="margin-top"
      *ngIf="canUpdateDatabaseCredentials && channelOption.value === 'manual' &&
        selectedDatabaseHostList.selected?.length > 1 && !isReadOnly && !isDisasterRecovery">
      <mat-slide-toggle cogDataId="toggle-update-database-credentials"
        formControlName="setDbCredentials"
        [disabled]="areDbCredentialsRequired">
        {{'oracle.mnmc.updateDbCredsNg' | translate}}
      </mat-slide-toggle>
      <div class="margin-left-lg margin-top" *ngIf="setDbCredentials.value"
        formGroupName="dbCredentials">
        <!-- username -->
        <mat-form-field>
          <input cogDataId="input-database-username"
            name="inputDatabaseUsername"
            type="text"
            matInput
            required
            placeholder="{{'username' | translate}}"
            formControlName="username">
          <mat-error>{{'errors.required' | translate}}</mat-error>
        </mat-form-field>

        <!-- password -->
        <mat-form-field class="margin-left">
          <input cogDataId="input-database-password"
            name="inputDatabasePassword"
            type="password"
            matInput
            required
            placeholder="{{'password' | translate}}"
            formControlName="password">
          <mat-error>{{'errors.required' | translate}}</mat-error>
        </mat-form-field>
      </div>
    </div>

    <!-- Delete Archive Log Settings -->
    <div *ngIf="isArchivalLogDeletionEnabled()"
      [ngClass]="{'margin-top': channelOption?.value === 'manual'}">
      <mat-divider class="margin-bottom"
        [class.hidden]="!canUpdateDatabaseCredentials && channelOption.value === 'manual'">
      </mat-divider>
      <mat-slide-toggle cogDataId="toggle-archive-delete"
        formControlName="setArchiveDelete">
        {{'oracle.mnmc.deleteArchiveLog' | translate}}
      </mat-slide-toggle>
      <div class="indent-box" *ngIf="setArchiveDelete.value">
        <ng-container *ngIf="!this.form.controls.unsetArchiveLogDelete.value">
          <div class="flex-row-xs margin-top">
            <mat-form-field *ngIf="archiveLogRetentionType?.value === archiveLogRetentionUnit.Days"
              class="archive-log-input">
              <mat-label>{{'oracle.mnmc.deleteArchiveLogAfter' | translate}}</mat-label>
              <input cogDataId="input-archive-log-keep-days"
                matInput
                type="number"
                min="0"
                formControlName="archiveLogRetentionDays">
              <mat-error>
                {{mapErrorMessage(archiveLogRetentionDays) | translate: { minValue: 0 } }}
              </mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="archiveLogRetentionType?.value === archiveLogRetentionUnit.Hours"
              class="archive-log-input">
              <mat-label>{{'oracle.mnmc.deleteArchiveLogAfter' | translate}}</mat-label>
              <input cogDataId="input-archive-log-keep-hours"
                matInput
                type="number"
                min="0"
                formControlName="archiveLogRetentionHours">
              <mat-error>
                {{mapErrorMessage(archiveLogRetentionHours) | translate: { minValue: 0 } }}
              </mat-error>
            </mat-form-field>
            <mat-form-field floatLabel="always">
              <mat-select cogDataId="rentention-type-select" formControlName="archiveLogRetentionType">
                <mat-option *ngFor="let type of archiveLogRetentionTimePeriodOptions"
                  [inheritDataId]="true"
                  cogDataId="option-{{type.value}}"
                  [value]="type.value">
                  {{ (((archiveLogRetentionDays?.value > 1 || archiveLogRetentionHours?.value > 1) && type.labelPlural)
                    ? type.labelPlural : type.label) | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <cog-banner status="warn" class="margin-top">
            {{'oracle.mnmc.deleteArchiveLogExplanation' | translate}}
          </cog-banner>
        </ng-container>
        <div class="margin-top">
          <mat-slide-toggle cogDataId="toggle-unset-archive-log-deletion"
            formControlName="unsetArchiveLogDelete">
            {{'oracle.mnmc.notDeleteArchivedLogs' | translate}}
          </mat-slide-toggle>
        </div>
      </div>
    </div>

    <!-- Data Guard Continue Primary backup -->
    <div class="margin-top" *ngIf="isDatabaseDgInfoAvailable() && !isOracleEnableDgRoleBasedBackup">
      <mat-divider class="margin-bottom"></mat-divider>
      <mat-slide-toggle cogDataId="toggle-continue-dg-primary-backup"
        formControlName="continueDgPrimaryBackup">
        {{'oracle.dataguard.continueDgPrimaryBackup' | translate}}
      </mat-slide-toggle>
    </div>
    <ng-container *ngIf="isDatabaseDgInfoAvailable() && isOracleEnableDgRoleBasedBackup">
      <div class="margin-top">
        <mat-divider class="margin-bottom"></mat-divider>
        <mat-slide-toggle cogDataId="toggle-allow-dg-archive-log-backups" formControlName="continueDgPrimaryBackup"
          (change)="handleDataGuardRoleBasedBackupToggle($event)">
          {{'oracle.dataGuardRoleBasedBackup' | translate}}
        </mat-slide-toggle>
      </div>
      <div *ngIf="backupOnDgRole?.value" class="margin-left-xl margin-top">
        <mat-radio-group formControlName="backupOnDgRole">
          <mat-radio-button value="kSbt"
            class="margin-bottom"
            cogDataId="dg-backup-in-primary-role"
            value="kPrimary">
            {{'oracle.dataGuard.backupInPrimaryRole' | translate}}
          </mat-radio-button>
          <mat-radio-button value="kImageCopy"
            class="margin-bottom"
            cogDataId="dg-backup-in-standby-role"
            value="kStandby">
            {{'oracle.dataGuard.backupInStandbyRole' | translate}}
          </mat-radio-button>
        </mat-radio-group>
        <mat-slide-toggle cogDataId="toggle-continue-dg-primary-backup"
          formControlName="allowBackupArchivelogOnAnyRole">
          {{'oracle.dataGuard.allowArchiveLogBackups.description' | translate : {dgRole:
            'oracle.dataGuardRole.' + roleBasedonDgBackup | translate} }}
          <cog-icon class="vertical-align" size="md" shape="info!outline" inline="true"
            [matTooltip]="('oracle.dataGuard.allowArchiveLogBackups.tooltip' | translate : {dgRole:
            'oracle.dataGuardRole.' + roleBasedonDgBackup | translate})"
            matTooltipPosition="below">
          </cog-icon>
        </mat-slide-toggle>
      </div>
      <mat-divider class="margin-top"></mat-divider>
    </ng-container>
  </ng-container>
</div>

import { Injectable } from '@angular/core';
import { ProtectionSourceNode } from '@cohesity/api/v1';
import { DataTreeSelection } from '@cohesity/helix';
import { SourceSelection } from '@cohesity/iris-source-tree';
import { flatten } from 'lodash-es';
import { Environment, ExchangeGroups } from 'src/app/shared/constants';

import { BaseProtectionSourceService } from '../shared/base-protection-source.service';
import { ExchangeSourceDataNode } from './exchange-source-data-node';


/**
 * Tree service for exchange server on-prem.
 */
@Injectable({
  providedIn: 'root'
})
export class ExchangeSourceTreeService extends BaseProtectionSourceService<ExchangeSourceDataNode> {

  /**
   * Exclude special params for Exchange entities from the count. They are not considered object-level settings.
   */
  hideSpecialParamsInSummary = true;

  constructor() {
    super();
  }

  /**
   * Returns whether a node is a leaf or not. This can be used to calculate selection totals.
   */
  isLeaf(treeNode: ExchangeSourceDataNode): boolean {
    return treeNode.isLeaf;
  }

  /**
   * Transforms the node object from the api into a Ad Tree node to pass to the tree.
   *
   * @param   node   The original node.
   * @param   level  The level in the tree.
   * @return  An ExchangeSourceDataNode that can be displayed in the tree.
   */
  transformData(node: ProtectionSourceNode, level: number): ExchangeSourceDataNode {
    return new ExchangeSourceDataNode(node, level, this.treeControl);
  }

  /**
   * Converts the data tree selection model to the job selection model.
   *
   * @param   selection   The selection from the tree.
   * @return  The job selection info.
   */
  transformFromDataTreeSelection(selection: DataTreeSelection<ExchangeSourceDataNode>): SourceSelection {
    const autoSelected = selection.autoSelected.filter(item => !item.isTag);

    // Sources include explicitly selected leaf node, and auto selected entities
    const selectedExchangeNodes = selection.selected.filter(item =>
      ExchangeGroups.exchangeLeafEntities.includes(item.type)).concat(autoSelected);
    const exchangeDbLeafMap = new Map<number, number[]>();

    // Go through selected entities and update exchangeMap.
    selectedExchangeNodes.map((node: ExchangeSourceDataNode) => {
      if (node.protectionSource.environment === Environment.kExchange) {
        if (exchangeDbLeafMap.has(node.parentId)) {
          // Append the container's app entities with the database id and update the Map.
          const appEntities = exchangeDbLeafMap.get(node.parentId);
          appEntities.push(node.id as number);
          exchangeDbLeafMap.set(node.parentId, appEntities);
        } else if (ExchangeGroups.exchangeLeafEntities.includes(node.type)) {
          // Add a new container with the selected database entity.
          exchangeDbLeafMap.set(node.parentId, [node.id as number]);
        } else {
          // Selected node is a non-leaf node (auto protected)
          const entityId = node.type === 'kExchangeNode' ? node.parentId : node.id;
          exchangeDbLeafMap.set(entityId as number, []);
        }
      }
    });

    return {
      // Selected entity ids
      sourceIds: flatten([...exchangeDbLeafMap.values(), ...selection.autoSelected.map(item => Number(item.id))]),

      // Excluded entity ids
      excludeSourceIds: [...selection.excluded.map(item => Number(item.id))],

      // Exchange source special parameters
      sourceSpecialParameters: [...exchangeDbLeafMap.keys()].map((containerId: number) => ({
        sourceId: containerId,
        exchangeSpecialParameters: {
          applicationEntityIds: exchangeDbLeafMap.get(containerId)
        },
      })),
    };
  }

  /**
   * Converts source selection to the data tree selection model.
   *
   * @param   allNodes         The unfiltered list of tree nodes.
   * @param   sourceSelection  The job selection.
   * @return  A data tree selection model.
   */
  transformToDataTreeSelection(
    allNodes: ExchangeSourceDataNode[],
    sourceSelection: SourceSelection
  ): DataTreeSelection<ExchangeSourceDataNode> {
    const selection: DataTreeSelection<ExchangeSourceDataNode> = {
      autoSelected: [],
      excluded: [],
      selected: [],
      options: {},
    };

    if (!sourceSelection) {
      return selection;
    }

    // Set to hold selected entity ids.
    const selectedEntities = new Set<number>();

    const { sourceSpecialParameters, sourceIds } = sourceSelection;

    if (sourceSpecialParameters && sourceSpecialParameters.length) {
      // Adding all selected entity ids to the set.
      sourceSpecialParameters.forEach(params => {
        selectedEntities.add(params.sourceId);
        (params.exchangeSpecialParameters.applicationEntityIds || []).forEach((appId: number) => {
          selectedEntities.add(appId);
        });
      });
    // When protecting from a selected exchange source,
    // sourceSpecialParameters is not available, hence look in sourceIds instead.
    } else if (sourceIds) {
      sourceIds.forEach(id => selectedEntities.add(id));
    }

    allNodes.forEach(node => {
      const nodeId = Number(node.id);

      // Handle source exclusion
      if ((sourceSelection.excludeSourceIds || []).includes(nodeId)) {
        selection.excluded.push(node);
      }

      // Skip if node is not selected or auto-selected.
      if (!selectedEntities.has(nodeId)) {
        return;
      }

      // Leaf database nodes
      if (node.isLeaf) {
        node.inCurrentGroup = true;
        selection.selected.push(node);

      // Auto-select exchange container nodes.
      } else {
        let autoProtectedNode = node;

        // For standalone auto protect, we want to skip one level
        // and make the child as the node to be auto protected.
        if (node.type === 'kHost' && node.expandable) {
          autoProtectedNode = allNodes.find(childNode => childNode.id === node.children[0].protectionSource.id);
        }

        // The auto protected node should be one of the exchange container entity type.
        if (!ExchangeGroups.exchangeContainerEntities.includes(autoProtectedNode.type)) {
          return;
        }

        // Get the ids of this container node's children.
        const childrenIds = autoProtectedNode.children.map(entity => entity.protectionSource.id);
        // If the children nodes are not part of the selected entities,
        // The node is auto selected.
        if (!childrenIds.some(id => selectedEntities.has(id))) {
          selection.autoSelected.push(autoProtectedNode);
          autoProtectedNode.inCurrentGroup = true;
          autoProtectedNode.checkAllDescendants((child: ExchangeSourceDataNode) => child.inCurrentGroup = true);
        }
      }
    });

    selection.options = (sourceSpecialParameters || []).reduce((options, params) => {
      options[params.sourceId] = params;
      return options;
    }, {});


    return selection;
  }
}

import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { cloneDeep } from 'lodash-es';
import { DnsVipsEntryComponent } from '../dns-vips-entry/dns-vips-entry.component';
import { DnsDelegationZone } from '../internal-dns.models';

/**
 * This component is used for adding or editing a DNS Delegation Zone in a dialog.
 */
@Component({
  templateUrl: './dns-vips-entry-dialog.component.html',
})
export class DnsVipsEntryDialogComponent {

  /**
   * The model of the zone.
   */
  model: DnsDelegationZone;

  /**
   * A reference to the DNS VIPs entry component.
   */
  @ViewChild(DnsVipsEntryComponent) dnsVipsEntryComponent: DnsVipsEntryComponent;

  constructor(
    private dialogRef: MatDialogRef<DnsVipsEntryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (this.data.model) {
      this.model = cloneDeep(this.data.model);
    } else {
      this.model = { dnsZoneName: '', dnsZoneVips: [], dnsZoneResolvedVips: [] };
    }
  }

  /**
   * Method to submit the changes.
   */
  submit() {
    if(this.dnsVipsEntryComponent.isFormValid()) {
      this.dialogRef.close(this.model);
    } else {
      this.dnsVipsEntryComponent.formGroup.markAllAsTouched();
    }
  }

  /**
   * Method to cancel the changes.
   */
  cancel() {
    this.dialogRef.close(undefined);
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { isNumber } from 'lodash-es';

/**
 * @description
 * Displays warning banner when number of runs is less than total runs available from API.
 */
@Component({
  selector: 'coh-runs-limit-banner',
  templateUrl: './runs-limit-banner.component.html',
  styleUrls: ['./runs-limit-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RunsLimitBannerComponent {
  /**
   * Total number of runs available from API within specified date range.
   */
  @Input() totalRuns: number;

  /**
   * Runs limit set to API by numRuns param.
   */
  @Input() runsLimit: number;

  /**
   * Dispatches event when "View All Runs" button is clicked.
   */
  @Output() readonly viewAllClicked = new EventEmitter<number>();

  /**
   * Hides banner when number of runs is less than total number of runs.
   */
  @HostBinding(`class.hidden`)
  get hideBanner(): boolean {
    if (isNumber(this.runsLimit) && isNumber(this.totalRuns)) {
      return this.runsLimit >= this.totalRuns;
    }

    return true;
  }

  /**
   * Handles "View All Runs" button click.
   */
  handleAllRunsClick() {
    this.viewAllClicked.emit(this.totalRuns);
  }
}

import { inject, Injectable } from '@angular/core';
import { User } from '@cohesity/api/v1';
import {
  CoreAnalyticsService,
  HeliosAnalyticsUser,
  dmsAwsFreeTrialExpired,
  dmsAzureFreeTrialExpired,
  dmsTenantId,
  dmsTenantName,
  EventProperties,
  hasClusters,
  IrisContextService,
  isAccountLocked,
  isAwsColdFreeTrialUser,
  isAwsWarmFreeTrialUser,
  isAzureColdFreeTrialUser,
  isAzureHotFreeTrialUser,
  isDGaaSUser,
  isDmsFreeTrialExpired,
  isDmsFreeTrialUser,
  isDmsOnlyUser,
  isDmsUser,
  isDraasFreeTrialExpired,
  isDraasFreeTrialUser,
  isDraasUser,
  isHeliosTenantUser,
  isHybridUser,
  isMcmGovCloud,
  isMcmOnPrem,
  isMcmSaaS,
  isPrivilegedUser,
  isRestrictedUser,
  isRpaasFreeTrialUser,
  isRpaasUser,
  isSaasServiceUser,
  isTenantUser,
  isGaiaUser,
  flagEnabled
} from '@cohesity/iris-core';
import { IS_IBM_AQUA_ENV } from '@cohesity/shared/core';
import { TransitionService, UIRouterGlobals } from '@uirouter/core';
import { isEmpty, omitBy } from 'lodash-es';
import mixpanel from 'mixpanel-browser';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, take } from 'rxjs/operators';
import { AppServiceManagerService } from 'src/app/app-services';
import { heliosProductionHostnames } from 'src/environments/app-environment.model';
import { environment } from 'src/environments/environment';

import { BroadcastChannelService } from './broadcast-channel.service';
import { UserService } from './user.service';

/**
 * Overwrite global window object to add keys which will be dynamically added.
 */
declare global {
  interface Window {
    /**
     * Intercom's javascript bundle is stored in the "Intercom" variable in
     * window object.
     */
    Intercom: any;

    /**
     * Window variable for intercom settings.
     */
    intercomSettings: any;

    /**
     * Window variable for intercom app id.
     */
    intercomAppId: any;
  }
}

/**
 * Different environments under which analytics can be loaded.
 */
enum AnalyticsEnvironment {
  /**
   * Used when proxying local code to a cluster.
   */
  development,

  /**
   * Used when the build is not internal.
   */
  production,
}

/**
 * Keys used by new mixpanel projects to load the SDK.
 */
const mixpanelKeys = {
  [AnalyticsEnvironment.development]: '0c556dde792e18b848c1f5a61740108f',
  [AnalyticsEnvironment.production]: '6e300a6ea55f900b117b6d74ae36eaa5',
};

/**
 * Keys used by legacy mixpanel projects to load the SDK.
 */
const mixpanelLegacyKeys = {
  [AnalyticsEnvironment.development]: '75b38e2251f4e0e27c9c4cff3405b1ca',
  [AnalyticsEnvironment.production]: '26ad2ca9c45c0eac7cc55e6a5c4ce18a',
};

/**
 * Keys used by intercom projects to load the SDK.
 */
const intercomKeys = {
  [AnalyticsEnvironment.development]: 'nbc4locn',
  [AnalyticsEnvironment.production]: 'k9ja36ud',
};

@Injectable({
  providedIn: 'root',
})
export class HeliosAnalyticsService extends CoreAnalyticsService {
  /**
   * Behavior subject to hold whether intercom is enabled.
   *
   * AdBlockers prevent intercom (and mixpanel) from loading, in that case the
   * UI may need to show differently (such as showing regular help over intercom
   * messenger help).
   */
  private _intercomEnabled$ = new BehaviorSubject<boolean>(false);

  /**
   * Observable for whether intercom is enabled.
   */
  intercomEnabled$ = this._intercomEnabled$.asObservable();

  /**
   * Behavior subject to hold the current unread messages count.
   */
  private _intercomUnreadCount$ = new BehaviorSubject<number>(0);

  /**
   * Observable to emit the current unread messages count.
   */
  intercomUnreadCount$ = this._intercomUnreadCount$.asObservable();

  /**
   * Whether the intercom messenger is currently visible.
   */
  private intercomMessengerVisible = false;

  /**
   * Whether the user is identified.
   */
  private userIdentified = new BehaviorSubject<boolean>(false);

  /**
   * Transition hook of intercom. Calling this function clears the hook.
   */
  private intercomTransitionHook: Function;

  /**
   * Cache the user details object. This doesn't change until the user logs in
   * again.
   */
  private userDetails: HeliosAnalyticsUser;

  /**
   * Specifies whether the UI is running in IBM Aqua mode.
   */
  private isIBMAquaEnv = inject(IS_IBM_AQUA_ENV);

  constructor(
    private appServiceManager: AppServiceManagerService,
    private broadcastChannelService: BroadcastChannelService,
    protected irisContextService: IrisContextService,
    private transitionService: TransitionService,
    private uiRouterGlobals: UIRouterGlobals,
    private userService: UserService,
  ) {
    super();
  }

  /**
   * Toggle visibility of intercom messenger.
   */
  toggleIntercomMessenger() {
    window.Intercom?.(this.intercomMessengerVisible ? 'hide' : 'show');
  }

  /**
   * The default properties for a tracking event.
   *
   * @return The default properties.
   */
  override getDefaultProperties(): EventProperties {
    const fromUrlObject = new URL(window.location.href);

    return {
      scopeName: this.appServiceManager.getScopeName(),
      searchParams: omitBy(this.uiRouterGlobals.params, isEmpty),
      stateName: this.uiRouterGlobals.current.name,
      pageUrl: `${fromUrlObject.origin}${fromUrlObject.pathname}`,
    };
  }

  /**
   * Load and initialize analytics bundles.
   *
   * @param user The user object which has the user details.
   */
  initHeliosAnalytics(user: User) {
    if (this.isIBMAquaEnv || !this.analyticsEnabled) {
      // Currently, this is only loaded for specific users.
      return;
    }

    // Track when the user logs in.
    this.broadcastChannelService.subscribe('heliosUserLoggedIn', () => {
      // Wait for user to be identified and load before sending the logged in
      // event.
      this.userIdentified.pipe(
        filter(value => Boolean(value)),
        take(1)
      ).subscribe(() => {
        this.track('user_logged_in');
        this.analyticsTrack('User Logged In', this.getDefaultProperties(), false);
      });
    });

    // Clear analytics when the logged-in user logs out.
    this.broadcastChannelService.subscribe('sessionDestroyed', () => {
      this.terminateHeliosAnalytics();
      this.userDetails = null;
    });

    const analyticsEnvironment = this.getAnalyticsEnvironment();

    if (this.intercomEnabled) {
      if (flagEnabled(this.irisContextService.irisContext, 'intercomIdentityVerification')) {
        // Enable Identity Verification.
        window.intercomSettings = {
          api_base: 'https://api-iam.intercom.io',
          app_id: intercomKeys[analyticsEnvironment],
          user_id: user.username,
          user_hash: user.intercomMessengerToken,
        };
      }

      // This needs to be set since the intercom.js which is loaded relies on this.
      window.intercomAppId = intercomKeys[analyticsEnvironment];

      // Initialize intercom
      const intercomSdk = document.createElement('script');
      intercomSdk.setAttribute('src', '/intercom.js');
      window.document.body.appendChild(intercomSdk);

      // Initialize intercom after it is loaded.
      this.setIntercom();
    }

    if (this.mixpanelEnabled) {
      // Initialize mixpanel
      const mixpanelAppId = mixpanelKeys[analyticsEnvironment];
      const mixpanelLegacyAppId = mixpanelLegacyKeys[analyticsEnvironment];
      mixpanel.init(mixpanelAppId);
      mixpanel.init(mixpanelLegacyAppId, {}, 'legacy');
    }

    // Initialize user attributes.
    this.setupUser();
  }

  /**
   * Function to setup user details for analytics.
   */
  private setupUser() {
    combineLatest([
      this.irisContextService.irisContext$,
      this.intercomEnabled$,
    ]).pipe(
      // Only setup user details when a scope is selected (app is fully loaded)
      // and intercom sdk is loaded.
      filter(([irisContext, intercomEnabled]) =>
        !isEmpty(irisContext.selectedScope) && intercomEnabled
      ),

      // Map to scope name
      map(() => this.appServiceManager.getScopeName()),

      // Only emit when scope is changed
      distinctUntilChanged(),
    ).subscribe(() => {
      // Shut down existing Intercom. this.identifyUser will restart Intercom
      // with correct user scope.
      window.Intercom?.('shutdown');

      // Wait on the user to be identified.
      this.identifyUser();

      // Reinitialize intercom as after shutdown, existing hooks for
      // intercom's lifecycle method don't work.
      this.initIntercom();

      if (this.userIdentified.value) {
        // No need to for additional setup if already done once.
        return;
      }

      // Mark user as identified.
      this.userIdentified.next(true);

      // Start listening to click events.
      this.setupTracking();

      // Track state transition as both a page view and an event.
      this.transitionService.onSuccess({}, transition => {
        if (!this.userIdentified.value) {
          return;
        }

        const to = (transition.to() || {}) as any;
        const from = (transition.from() || {}) as any;
        const params = (transition.params() || {}) as any;

        // TODO: This comment is no longer active.
        // In addition to "window.analytics.page", which tracks page views as a
        // special event for analytics tool, also track it as a regular event
        // so that these events make it to data warehouses - which only have
        // access to the regular events and not the special events.
        this.track('page_transition', {
          value: to.title,
          from: from.title,
          hasIdInUrl: Boolean(params.id),
          legacyEvent: true,
        });

        const fromUrl = window.location.href;
        const fromUrlObject = new URL(fromUrl);
        const fromPageUrl = `${fromUrlObject.origin}${fromUrlObject.pathname}`;

        setTimeout(() => {
          const valueUrl = window.location.href;
          const valueUrlObject = new URL(valueUrl);
          const valuePageUrl = `${valueUrlObject.origin}${valueUrlObject.pathname}`;

          if (fromPageUrl === valuePageUrl) {
            // Some UI pages push a new state again after the loading.
            // For example, after visiting "Activity", a state with url
            // /"activity/listing" is pushed first, and then another with
            // "/activity/listing?timeRange=past24hours".
            return;
          }

          this.analyticsTrack('Page Change', {
            ...this.getDefaultProperties(),
            from: fromPageUrl,
            value: window.location.href,
          }, false);
        });
      });
    });
  }

  /**
   * Function to terminate analytics.
   */
  private terminateHeliosAnalytics() {
    this.track('user_logged_out');
    this.analyticsTrack('User Logged Out', this.getDefaultProperties(), false);

    this.resetAnalyticsUser();

    if (this.mixpanelEnabled) {
      // Above reset() call will un-identify the user.
      mixpanel.legacy.reset();
      mixpanel.reset();
    }

    window.Intercom?.('shutdown');
  }

  /**
   * Wait for intercom's bundle to load and initialize intercom.
   */
  private setIntercom() {
    const intercomBundleInterval = window.setInterval(() => {
      // This is not ideal, but a consistent way to ensure intercom is loaded.
      if (!window.Intercom) {
        return;
      }

      window.clearInterval(intercomBundleInterval);
      this._intercomEnabled$.next(true);
    }, 50);
  }

  /**
   * Function to return which analytics environment the app should be
   * loaded in.
   */
  private getAnalyticsEnvironment(): AnalyticsEnvironment {
    if (heliosProductionHostnames.includes(window.document.location.hostname) && environment.production) {
      // Use production key if UI is deployed in production.
      return AnalyticsEnvironment.production;
    }

    // Otherwise use the development key.
    return AnalyticsEnvironment.development;
  }

  /**
   * Method to create a user persona initially and then identify them in
   * subsequent logins.
   */
  private identifyUser() {
    const user = this.userService.user;
    const irisContext = this.irisContextService.irisContext;
    let email = user.username;

    if (email.endsWith('.trial')) {
      // For specific scenarios, an existing Cohesity customer will get a new
      // account just for DMaaS trial. In this case, a ".trial" is appended to
      // the username, which is otherwise generally the email address. This
      // ".trial" needs to be removed to make sure the email address remains
      // valid.
      email = email.slice(0, email.lastIndexOf('.trial'));
    }

    this.userDetails = this.userDetails || {
      // Generic information
      name: `${user.firstName} ${user.lastName}`,
      roles: user.roles,
      rolesValue: user.roles.join(', '),
      privilegeIds: user.privilegeIds,
      privilegeIdsValue: user.privilegeIds.join(', '),
      email,
      salesforceAccountId: user.salesforceAccount?.accountId,
      salesforceUserId: user.salesforceAccount?.userId,
      salesUser: isPrivilegedUser(irisContext, 'sales'),
      supportUser: isPrivilegedUser(irisContext, 'support'),
      saasServiceUser: isSaasServiceUser(irisContext),
      tenantUser: isTenantUser(irisContext),
      heliosTenantUser: isHeliosTenantUser(irisContext),
      restrictedUser: isRestrictedUser(irisContext),
      hasClusters: hasClusters(irisContext),
      accountLocked: isAccountLocked(irisContext),
      dmsTenantId: dmsTenantId(irisContext),
      dmsTenantName: dmsTenantName(irisContext),

      // MCM mode information
      mcmSaasUser: isMcmSaaS(irisContext),
      mcmOnPremUser: isMcmOnPrem(irisContext),
      mcmGovCloudUser: isMcmGovCloud(irisContext),

      // Set MCM scope name below.
      activeMcmScope: '',

      // DMS (DataProtect) related user attributes
      dmsAwsFreeTrialExpired: dmsAwsFreeTrialExpired(irisContext),
      dmsAzureFreeTrialExpired: dmsAzureFreeTrialExpired(irisContext),
      dmsFreeTrialUser: isDmsFreeTrialUser(irisContext),
      dmsOnlyUser: isDmsOnlyUser(irisContext),
      dmsUser: isDmsUser(irisContext),

      // DRaaS (SiteContinuity) related user attributes
      draasFreeTrialExpired: isDraasFreeTrialExpired(irisContext),
      draasFreeTrialUser: isDraasFreeTrialUser(irisContext),
      draasUser: isDraasUser(irisContext),
      hybridUser: isHybridUser(irisContext),

      // RPaaS (FortKnox) related user attributes
      rpaasFreeTrialUser: isRpaasFreeTrialUser(irisContext),
      rpaasAwsWarmTrialUser: isAwsWarmFreeTrialUser(irisContext),
      rpaasAwsColdTrialUser: isAwsColdFreeTrialUser(irisContext),
      rpaasAzureHotTrialUser: isAzureHotFreeTrialUser(irisContext),
      rpaasAzureColdTrialUser: isAzureColdFreeTrialUser(irisContext),
      rpaasUser: isRpaasUser(irisContext),

      // DGaaS (Data Governance) related user attributes
      dgaasUser: isDGaaSUser(irisContext),

      // Data Insights aka gaia related user attributes
      diaasUser: isGaiaUser(irisContext),

      // Deprecated
      trialUser: isDmsFreeTrialUser(irisContext),
      dmsFreeTrialExpired: isDmsFreeTrialExpired(irisContext),
    };

    // MCM scope is not a user attribute, but this is useful for showing
    // dynamic widgets in intercom based on the user's current scope.
    this.userDetails.activeMcmScope = this.appServiceManager.getScopeName();

    // Identify the logged-in user for analytics.
    this.identifyAnalyticsUser(user.username, this.userDetails);
  }

  /**
   * Initialize intercom.
   */
  private initIntercom() {
    if (!this.intercomEnabled) {
      return;
    }

    if (this.intercomTransitionHook) {
      // Clear the previous transition hook.
      this.intercomTransitionHook();
    }

    // Update intercom with the current page.
    this.intercomTransitionHook = this.transitionService.onSuccess({}, () => {
      window.Intercom('update');
    });

    window.Intercom('onUnreadCountChange', unreadCount => {
      this._intercomUnreadCount$.next(unreadCount);
    });

    window.Intercom('onShow', () => {
      this.intercomMessengerVisible = true;
      this.track('messenger_toggled', {value: true});
      this.analyticsTrack('Messenger Toggled', {value: true}, false);
    });

    window.Intercom('onHide', () => {
      this.intercomMessengerVisible = false;
      this.track('messenger_toggled', {value: false});
      this.analyticsTrack('Messenger Toggled', {value: false}, false);
    });

    if (this.uiRouterGlobals.params?.product_tour_id) {
      this.startProductTour(this.uiRouterGlobals.params.product_tour_id);
    }
  }

  /**
   * Function to start an intercom product tour based on product tour id.
   *
   * @param id The product tour id.
   */
  private startProductTour(id: number) {
    const menuButton = document.getElementById('menuButton');

    if (menuButton && !document.querySelector('mat-sidenav.mat-drawer-opened')) {
      // All product tours start with selecting a navigation item.
      // If the navigation menu is hidden, show it at the start of the
      // product tour.
      menuButton.click();
    }

    // If a product tour id was set, start that product tour.
    window.Intercom?.('startTour', id);
  }
}

// Component: cSourceTable

;(function(angular, undefined) {
  'use strict';

  var options = {
    controller: 'SourceTableCtrl',
    templateUrl:
      'app/protection/sources/components/c-source-table/c-source-table.html',
    bindings: {
      sourceGroup: '<',
      environment: '<',
      state: '<',
    },
  };

  angular.module('C.sources')
    .controller('SourceTableCtrl', sourceTableCtrlFn)
    .component('cSourceTable', options);

  function sourceTableCtrlFn(_, PubSourceService, SourceService, SourcesUtil,
    evalAJAX, cMessage, $state, $timeout, ENV_GROUPS, ENUM_ENV_TYPE) {
    var $ctrl = this;

    _.assign($ctrl, {
      $onChanges: onChanges,
      $onInit: onInit,
      displayCount: 4,
      ENV_GROUPS: ENV_GROUPS,
      expandable: true,
      isLogicalSizeAvailable: PubSourceService.isLogicalSizeAvailable,
      isProtectedSizeAvailable: PubSourceService.isProtectedSizeAvailable,
      refreshPhysicalServer: refreshPhysicalServer,
      retryRegistration: retryRegistration,
      showMore: false,
      toggleShowHide: toggleShowHide,
      upgradeSource: upgradeSource,
      upgradeSourceWithConfirmation: upgradeSourceWithConfirmation
    });

    /**
     * init function
     *
     * @method   onInit
     */
    function onInit() {
      _calculateTotalSize($ctrl.sourceGroup);

      if ($ctrl.sourceGroup.length > $ctrl.displayCount) {
        _setupShowMore();
      } else {
        $ctrl.displaySourceGroup = $ctrl.sourceGroup;
      }
    }

    /**
     * Decorates the source group with the stats according to the specifc
     * environment type. The environment for which the stats are being
     * processed, will be only applicable after sourceGroup is generated,
     * inferring the environment from the registration information
     *
     * @method   decorateSourceGroup
     * @param    {object}   sourceGroup   The source group
     */
    function decorateSourceGroup(sourceGroup) {
      sourceGroup.forEach(function addSoureStats(source) {
        source._stats =
          PubSourceService.processSourceStats(source, $ctrl.environment);
      });
    }

    /**
     * when the sourceGroup binding is updated, set the updated data that has
     * been filtered to displaySourceGroup which is being used in the template.
     * This is needed because of the custom filtering happening in the parent
     * controller.
     *
     * @method   onChanges
     */
    function onChanges() {
      decorateSourceGroup($ctrl.sourceGroup);
      if (!$ctrl.state.filtering &&
        $ctrl.sourceGroup.length > $ctrl.displayCount) {
        _setupShowMore();
      } else {
        $ctrl.displaySourceGroup = $ctrl.sourceGroup;
      }
    }

    /**
     * Toggles show/hide functionality.
     *
     * @method   toggleShowHide
     */
    function toggleShowHide() {
      if ($ctrl.showMore) {
        $ctrl.displaySourceGroup = $ctrl.sourceGroup;
      } else {
        $ctrl.displaySourceGroup =
          $ctrl.sourceGroup.slice(0, $ctrl.displayCount);
      }

      $ctrl.showMore = !$ctrl.showMore;
    }

    /**
     * Sets up the show more functionality when necessary.
     *
     * @method   _setupShowMore
     */
    function _setupShowMore() {
      $ctrl.showMore = true;

      $ctrl.displaySourceGroup =
        $ctrl.sourceGroup.slice(0, $ctrl.displayCount);

      $ctrl.nMore =
        ($ctrl.sourceGroup.length - $ctrl.displaySourceGroup.length);
    }

    /**
     * calculates the total size for each source for display purposes.
     *
     * @method   _calculateTotalSize
     * @param    {array}   sourceGroup   array of sources of a certain type. ex:
     *                                   array of kVMware sources
     */
    function _calculateTotalSize(sourceGroup) {
      sourceGroup.forEach(function protectedSize(source) {
        source.stats._totalSize =
          source.stats.protectedSize + source.stats.unprotectedSize;
      });
    }

    /**
     * Triggers an upgrade of a particular source.
     *
     * @method   upgradeSource
     * @param    {object}   source   The source entity
     * @return   {object}   returns the response data or return {}
     */
    function upgradeSource(source) {
      var agentIds = PubSourceService.getAgentsToUpgrade(source);
      SourceService.upgradeSourceApi(agentIds).then(
        function upgradeCallSuccess() {
          $state.reload();
        }
      );
    }

    /**
     * Triggers an upgrade of a particular source.
     *
     * @method   upgradeSourceWithConfirmation
     * @param    {object}   source   The source entity
     */
    function upgradeSourceWithConfirmation(source) {
      var agentIds = PubSourceService.getAgentsToUpgrade(source);
      SourcesUtil.upgradeAgent({
        agentIds: agentIds,
        names: [source.rootNode.name],
        oldVersion: _.get(source, '_agent.version', ''),
        isMulipleAgentsUpgrade: source._isSqlCluster,
        hasVcsSource: source._isVcsCluster
      });
    }

    /**
     * Submits a request to refresh source nodes.
     *
     * @method   refreshPhysicalServer
     * @param    {object}   source   The source entity object
     * @return   {object}   returns the Q promise response or {}
     */
    function refreshPhysicalServer(source) {
      return PubSourceService.refreshSource(source.rootNode.id)
        .catch(evalAJAX.errorMessage);
    }

    /**
     * Retries registration of provided physical entity.
     *
     * @method   retryRegistration
     * @param    {object}   source   The source entity object
     */
    function retryRegistration(source) {
      var requestObj;

      if (!source) {
        return;
      }

      // Handle DB Registration Retries.
      if (ENV_GROUPS.databaseSources.includes($ctrl.environment)) {
        PubSourceService.retrySourceRegistration(source, $ctrl.environment)
          .then(function regRetryAccepted() {
            if ($ctrl.environment === 'kSQL') {
              cMessage.success({
                textKey: 'sourceTreePub.updateDBRegSuccess',
                textKeyContext: {
                  name: source.rootNode.name,

                  // Adding database type to differentiate between MS SQL and
                  // Oracle.
                  database: ENUM_ENV_TYPE[$ctrl.environment],
                },
              });
            }
            $timeout(function refreshState() {
              $state.reload();
            }, 3000);
          });
        return;
      }

      // We use the same API as original registration attempt, so we have to
      // construct the expected request object which is not simply the node from
      // the entity hierarchy.
      requestObj = SourceService.publicEntityToPrivateEntity(source.rootNode);
      requestObj.retryEntityRegistration = true;

      // Adding a temporary workaround until the PUT API is implemented in IRIS
      // backend.
      requestObj.entity = { id: requestObj.id };

      // Clear the registration error so it can be triggered anew, if necessary.
      source._isRegError = false;

      // This needs to be changed when the API is moved to public.
      SourceService.updateSource(requestObj).then(
        function retryRegSuccess() {
          cMessage.success({
            textKey: 'sources.retryRegistration.success',
            textKeyContext: source,
          });
        },
        evalAJAX.errorMessage
      ).finally(function retryRegFinally() {
        // Instead of reload immediately, reload the state after 3s
        $timeout($state.reload, 3000);
      });
    }
  }
})(angular);

import { McmSourceInfo, ObjectProtectionStatsSummary } from '@cohesity/api/v2';

/**
 * This method builds a protection information object for display in the UI
 * from the corresponding protection stats.
 *
 * @param protectionStats
 * @returns protection information object
 */
export function mapProtectionInfoFromProtectionStats(protectionStats: ObjectProtectionStatsSummary) {
  return protectionStats ? {
    protectedCount: protectionStats?.protectedCount,
    unprotectedCount: protectionStats?.unprotectedCount,
    totalCount: protectionStats?.protectedCount + protectionStats?.unprotectedCount,
    missingProtectedCount: protectionStats?.deletedProtectedCount,
  } : undefined;
}

/**
 * This method builds a protection information object for display in the UI
 * from the source information object.
 *
 * @param mcmSourceInfo
 * @param environment
 * @returns protection info from source info
 */
export function getProtectionInfoFromMcmSourceInfo(mcmSourceInfo: McmSourceInfo, environment: string) {
  return mapProtectionInfoFromProtectionStats(
    mcmSourceInfo.protectionStats?.find(s => s?.environment === environment)
  );
}

import { DataProtectAzureInfo, DataProtectInfo, McmUserProfile } from '@cohesity/api/v1';
import { daysRemaining, getSubscriptionInfo } from './common-utils';
import { isDGaaSUser } from './dgaas-utils';
import { isDraasUser } from './draas-utils';
import { hasClusters } from './iris-context-user-util';
import { IrisContext } from './iris-context.model';
import { isRpaasUser } from './rpaas-utils';

/**
 * Returns true if the current user is in DMaaS scope. DMaaS Scope is also
 * available for Self Service user.
 *
 * @param irisContext The current iris context.
 * @returns boolean True if the user in DMaaS context.
 */
export function isDmsScope(irisContext: IrisContext): boolean {
  return Boolean(isDmsUser(irisContext) && irisContext?.selectedScope?._serviceType === 'dms') ||
    isDmsSelfServiceUser(irisContext);
}

/**
 * Indicates if the DMaaS user is using a free trial that has expired.
 *
 * @param irisContext The current iris context.
 * @returns boolean True if user is DMaaS user and their free trial has expired.
 */
export function dmsAwsFreeTrialExpired(irisContext: IrisContext): boolean {
  const entitlement = dmsEntitlement(irisContext);
  return entitlement?.isFreeTrial && !entitlement.isActive;
}

/**
 * Indicates if the DMaaS user is using a free trial that has expired.
 *
 * @param irisContext The current iris context.
 * @returns boolean True if user is DMaaS user and their free trial has expired.
 */
export function dmsAzureFreeTrialExpired(irisContext: IrisContext): boolean {
  const entitlement = azureEntitlement(irisContext);
  return entitlement?.isFreeTrial && !entitlement.isActive;
}

/**
 * Returns true if all free trial subscriptions have expires.
 *
 * @param irisContext Iris context.
 * @returns True if all free trial subscriptions have expires.
 */
export function isDmsFreeTrialExpired(irisContext: IrisContext): boolean {
  const awsInfo = dmsEntitlement(irisContext);
  const azureInfo = azureEntitlement(irisContext);

  if (awsInfo && azureInfo) {
    return dmsAzureFreeTrialExpired(irisContext) && dmsAwsFreeTrialExpired(irisContext);
  } else if (awsInfo) {
    return dmsAwsFreeTrialExpired(irisContext);
  } else if (azureInfo) {
    return dmsAzureFreeTrialExpired(irisContext);
  }

  return true;
}

/**
 * Provides the number of days remaining in DMaaS users free trial or subscription.
 *
 * @param irisContext The current iris context.
 * @returns number of days remaining in free trial or subscription.
 */
export function dmsAwsDaysRemaining(irisContext: IrisContext): number | null {
  const entitlement = dmsEntitlement(irisContext);

  return daysRemaining(entitlement?.endDate);
}

/**
 * Provides the number of days remaining in DMaaS users free trial or subscription.
 *
 * @param irisContext The current iris context.
 * @returns number of days remaining in free trial or subscription.
 */
export function dmsAzureDaysRemaining(irisContext: IrisContext): number | null {
  const entitlement = azureEntitlement(irisContext);

  return daysRemaining(entitlement?.endDate);
}

/**
 * Minimum number of subscription days remaining between all available subscriptions.
 *
 * @param irisContext Iris context
 * @returns Minimum number of subscription days remaining between all available subscriptions.
 */
export function getMinSubscriptionDaysRemaining(irisContext: IrisContext): number {
  const awsInfo = dmsEntitlement(irisContext);
  const azureInfo = azureEntitlement(irisContext);

  if (awsInfo && azureInfo) {
    return Math.min(dmsAwsDaysRemaining(irisContext), dmsAzureDaysRemaining(irisContext));
  } else if (awsInfo) {
    return dmsAwsDaysRemaining(irisContext);
  } else if (azureInfo) {
    return dmsAzureDaysRemaining(irisContext);
  }

  return 0;
}

/**
 * Indicates if the DMaaS user is signed up via AWS subscription.
 *
 * @param irisContext The current iris context.
 * @returns True if DMaaS user is subscribed via AWS.
 */
export function isDmsAwsSubscription(irisContext: IrisContext): boolean {
  const entitlement = dmsEntitlement(irisContext);

  return !!entitlement?.isAwsSubscription;
}

/**
 * Indicates if the DMaaS user's Azure subscription is active.
 *
 * @param irisContext The current iris context.
 * @returns True if DMaaS user Azure subscription is active.
 */
export function isAzureSubscriptionActive(irisContext: IrisContext): boolean {
  const azureInfo = azureEntitlement(irisContext);
  return azureInfo?.isActive ?? false;
}

/**
 * Indicates if the DMaaS user's AWS subscription is active.
 *
 * @param irisContext The current iris context.
 * @returns True if DMaaS user's AWS subscription is active.
 */
export function isDmsAwsSubscriptionActive(irisContext: IrisContext): boolean {
  const azureInfo = dmsEntitlement(irisContext);
  return azureInfo?.isActive ?? false;
}

/**
 * Indicates if the DMaaS user is signed up via Cohesity Purchase Order.
 *
 * @param irisContext The current iris context.
 * @returns True if DMaaS user is signed up via Cohesity PO.
 */
export function isDmsCohesitySubscription(irisContext: IrisContext): boolean {
  const entitlement = dmsEntitlement(irisContext);

  return entitlement?.isCohesitySubscription;
}

/**
 * Returns the DMaaS subscription quantity.
 *
 * @param irisContext The current iris context.
 * @returns the total quantity purchased for the subscription
 */
export function dmsSubscriptionQuantity(irisContext: IrisContext): null | number {
  const subscription = dmsEntitlement(irisContext);

  return subscription?.quantity;
}

/**
 * Helper function to get the Dmaas profile from iris context.
 *
 * @param irisContext The current iris context.
 * @return The Dmaas profile if the user is a DMaaS user or null.
 */
export function dmsProfile(irisContext: IrisContext): null | McmUserProfile {
  if (!isDmsUser(irisContext)) {
    return null;
  }

  return irisContext.user.profiles?.find(profile => profile.tenantType === 'Dmaas') || null;
}

/**
 * Returns the tenant ID for a given DMaaS ID.
 *
 * @param  irisContext  The current iris context.
 * @return The tenant ID if a DMS user or null.
 */
export function dmsTenantId(irisContext: IrisContext): null | string {
  return dmsProfile(irisContext)?.tenantId ?? null;
}

/**
 * Returns the tenant name for a given DMaaS ID.
 *
 * @param  irisContext  The current iris context.
 * @return The tenant Name if a DMS user or null.
 */
export function dmsTenantName(irisContext: IrisContext): null | string {
  return dmsProfile(irisContext)?.tenantName ?? null;
}

/**
 * Returns whether the account has to be retention restricted.
 *
 * @param irisContext The current iris context.
 * @returns Return true if extended retention is set.
 */
export function isDmsRetentionRestricted(irisContext: IrisContext): boolean {
  return isDmsScope(irisContext) && !isDmsTieringEnabled(irisContext);
}

/**
 * Returns whether the account has subscribed for data movement.
 *
 * @param irisContext The current iris context.
 * @returns Return true if extended retention is set.
 */
export function isDmsTieringEnabled(irisContext: IrisContext): boolean {
  return (
    isDmsScope(irisContext) &&
    Boolean(isDmsBackendTieringEnabled(irisContext) || isDmsFrontendTieringEnabled(irisContext))
  );
}

/**
 * Returns whether the account has subscribed for frontend data movement.
 *
 * @param irisContext The current iris context.
 * @returns Return true if frontend tiering is enabled.
 */
export function isDmsFrontendTieringEnabled(irisContext: IrisContext): boolean {
  const tiering = irisContext?.user?.subscriptionInfo?.dataProtect?.tiering;
  return Boolean(tiering?.frontendTiering);
}

/**
 * Returns whether the account has subscribed for backend data movement.
 *
 * @param irisContext The current iris context.
 * @returns Return true if backend tiering is enabled.
 */
export function isDmsBackendTieringEnabled(irisContext: IrisContext): boolean {
  const tiering = irisContext?.user?.subscriptionInfo?.dataProtect?.tiering;
  return Boolean(tiering?.backendTiering);
}

/**
 * Returns true if the current user is a DMaaS configured user.
 * If there is extra entitlement information returned from crm service
 * then it also inspects the isActive boolean of that information.
 *
 * @param irisContext The current iris context.
 * @returns boolean True if user is DMaaS user.
 */
export function isDmsUser(irisContext: IrisContext): boolean {
  return hasEntitlementInfo(irisContext) ?
    Boolean(irisContext?.user?.salesforceAccount?.isDMaaSUser && hasDmsSku(irisContext)) :
    Boolean(irisContext?.user?.salesforceAccount?.isDMaaSUser);
}

/**
 * Returns true if the current user is a DMaas configured user
 * and isActive is set to true.
 *
 * @param irisContext The current iris context.
 * @returns boolean True - returns the subscription property isActive.
 */
export function hasDmsSku(irisContext: IrisContext): boolean {
  return dmsEntitlement(irisContext)?.isActive;
}

/**
 * Returns true if any extra information about the entitlement is
 * present in the irisContext
 *
 * @param irisContext The current iris context.
 * @returns boolean if anything exists in the dataProtect entitlement object
 */
export function hasEntitlementInfo(irisContext: IrisContext): boolean {
  return !!dmsEntitlement(irisContext);
}

/**
 * Indicates if the DMaaS user is currently using a free trial.
 *
 * @param irisContext The current iris context.
 * @returns boolean True if user is DMaaS user and using a free trial.
 */
export function isDmsFreeTrialUser(irisContext: IrisContext): boolean {
  const entitlement = dmsEntitlement(irisContext);
  return !!entitlement?.isFreeTrial;
}

/**
 * Indicates if the current user has only DMaaS accounts.
 *
 * @param irisContext The current iris context.
 * @returns true if user is subscribed only for DMaaS, false otherwise.
 */
export const isDmsOnlyUser = (irisContext: IrisContext): boolean => isDmsUser(irisContext)  &&
  !hasClusters(irisContext) && !isDGaaSUser(irisContext) && !isDraasUser(irisContext) && !isRpaasUser(irisContext);


/**
 * Provides the details for data protect service subscription.
 *
 * @param irisContext The current iris context.
 * @returns The data protect subscription or null.
 */
export function dmsEntitlement(irisContext: IrisContext): DataProtectInfo | null {
  return getSubscriptionInfo(irisContext)?.dataProtect;
}

/**
 * Provides the details for data protect Azure service subscription.
 *
 * @param irisContext The current iris context.
 * @returns The data protect Azyre subscription or null.
 */
export function azureEntitlement(irisContext: IrisContext): DataProtectAzureInfo {
  return irisContext?.user?.subscriptionInfo?.dataProtectAzure;
}

/**
 * DMaaS also supports the concept of Self Service for M365 applications.
 * This determines if the user has logged in through the MSFT OpenID Connect
 * workflow. For details refer:
 * iris/libs/adaptors/office365/dashboard/src/lib/components/authenticator/authenticator.component.ts
 *
 * @param irisContext The current iris context.
 * @returns True, iff the current user is MSFT based self-service user.
 */
export function isDmsSelfServiceUser(irisContext: IrisContext): boolean {
  return !!irisContext?.user?.msftUserInfo?.graphUuid;
}

import { assign } from 'lodash-es';
import { environment } from 'src/environments/environment';
import { isIbmBaaSEnabled, getConfigByKey } from '@cohesity/iris-core';

(function(angular) {
  'use strict';

  angular.module('C.login')
    .controller('logoutPageCtrl', logoutPageCtrlFn)
    .component('logoutPage', {
      controller: 'logoutPageCtrl',
      templateUrl: 'app/login/logout.html',
    });

  function logoutPageCtrlFn(_, $state, UserService, NG_IS_IBM_AQUA_ENV, NgIrisContextService, $timeout) {
    var $ctrl = this;

    const isIBMBaaS = isIbmBaaSEnabled(NgIrisContextService.irisContext);
    const isIBMDefender = NG_IS_IBM_AQUA_ENV;

    // configurations for custom redirect
    // @todo - ENG-426597 - get this from metadata service
    const ibmBaasRedirectUrl = getConfigByKey(NgIrisContextService.irisContext, 'logout.redirectUrl', false);
    const redirectInterval = getConfigByKey(NgIrisContextService.irisContext, 'logout.redirectInterval', 0);

    assign($ctrl, {
      $onInit: $onInit,
      NG_IS_IBM_AQUA_ENV: isIBMDefender,
      isIBMBaaS: isIBMBaaS,
      copyrightYear: moment().year(),
      redirectUrl: ibmBaasRedirectUrl
    });

    /**
     * This function determines if the passed in domain
     * is found in a list of domains.  This is a minor security
     * feature to prevent user being redirected back to
     * an invalid or malicious domain.
     *
     * @param domains - an array of domains to match against.
     * @param domain - the domain to search for in the list of domains.
     * @returns boolean if domain is valid for use.
     */
    function validateUrl(domains, url) {
      let host;
      try {
        host = new URL(url).host
      } catch (e) {
        return false;
      }
      return host ? domains.some(d => host.includes(d)) : false;
    }

    /**
     * initialization function
     *
     * @method   $onInit
     */
    function $onInit() {

      // Allow showing logout page to IBM Aqua ( Defender ) users.
      if (isIBMDefender || isIBMBaaS) {

        /**
         * IBM Specific referrer and redirect validations.
         * For development, you can add more domains if necessary.
         *
         * NOTE: Additional settings in user-service.js logout function
         * login guard, and app transition hooks can affect development
         * based workflow for this feature.
         */
        const validCohesityDomains = [
          'cohesity.com',
          'cohesitycloud.co',
        ];
        const validReferrerDomains = [
          'storage-defender.ibm.com',
          ...validCohesityDomains,
          ...environment.production ? [] : ['localhost', '127.0.0.1'],
        ];

        // In the future, these might be different.
        const validRedirectDomains = validReferrerDomains;

        /**
         * This will allow us to test the redirect functionality
         * on Cohesity domains before we give it to IBM where
         * we have no way to test it.
         *
         * @returns if debug is enabled
         */
        function isDebugEnabled() {
          const host = new URL(document.location.href).host;
          return validCohesityDomains.some(d => host.includes(d)) &&
            $state.params.forceRedirect;
        }

        /**
         * An example of what this looks like in the url for IBM Aqua ( Defender ).
         * /logout?redirect=http%3A%2F%2Fhelios-test2-aqua.cohesitycloud.co
         */
        let redirectUrl = ibmBaasRedirectUrl || $state.params.redirect;

        /**
         * If in dev or IBMBaaS, just redirect without worry,
         * since the redirect urls are hard coded.
         *
         * If in IBM Defender mode, then validate that this request
         * originated from a valid referrer and that the redirect
         * url is from one of the valid domains for Defender.
         * */
        const shouldRedirect = isDebugEnabled() || isIBMBaaS || (
          validateUrl(validReferrerDomains, document.referrer) &&
          validateUrl(validRedirectDomains, redirectUrl)
        );

        // determine if all the factors are correct for a redirect.
        if (shouldRedirect && redirectUrl) {
          $timeout(() => {
            document.location.href = redirectUrl;
          }, redirectInterval);
        }

        // this stops going back to login page.
        return true;
      }

      // Go to the login page if IDP/SSO login page is not enabled or if the
      // certificate only flow is not enabled. TODO(samual): explain "wny".
      //
      // If login banner is enabled, keep the user on this page as it
      // is undesirable to send them to login immediately as it will
      // pop the banner modal asking user to acknowledge the message,
      // which would be strange coming from a logout attempt.
      if (!(UserService.isIdpLogoutPageEnabled() ||
        UserService.isCertificateOnlyAuthentication() ||
        UserService.isLoginBannerEnabled())) {
        $state.go('login');
      }
    }
  }
})(angular);

import { Component, forwardRef, Inject, Optional, ViewEncapsulation } from '@angular/core';
import { MatCalendar, MatDatepickerModule, yearsPerPage } from '@angular/material/datepicker';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { LegacyDateAdapter as DateAdapter } from '@angular/material/legacy-core';
import { HelixIntlService } from '../../../helix-intl.service';
import { DataIdDirective } from '../../data-id/data-id.directive';
import { MomentDatePipe } from '../../date-pipe/moment-date.pipe';
import { DateAdapterUtils } from '../../date-utils/date-adapter-utils';
import { ButtonIconDecoratorDirective } from '../../icon/button-icon-decorator.directive';
import { IconComponent } from '../../icon/icon.component';

// Component instance unique ID counter
let nextUniqueId = 0;

/**
 * @description
 * Customized Material Calendar header component for `cog-calendar`.
 */
@Component({
  selector: 'cog-calendar-header',
  templateUrl: './calendar-header.component.html',
  styleUrls: ['./calendar-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    MatDatepickerModule,
    MatLegacyButtonModule,
    DataIdDirective,
    IconComponent,
    ButtonIconDecoratorDirective,
    MomentDatePipe,
  ],
})
export class CalendarHeaderComponent<D> {
  /**
   * Unique ID counter. Incremented on each instance creation.
   */
  private readonly _uniqueId = `cog-calendar-header-${++nextUniqueId}-`;

  /**
   * Returns the unique id for the visual hidden input.
   */
  get uniqueId(): string {
    return this._uniqueId;
  }

  get activeDate(): Date {
    return this.dateAdapterUtils.dateToMoment(this.calendar.activeDate).toDate();
  }

  constructor(
    @Inject(forwardRef(() => MatCalendar)) readonly calendar: MatCalendar<D>,
    @Optional() private dateAdapter: DateAdapter<D>,
    private dateAdapterUtils: DateAdapterUtils<D>,
    readonly intl: HelixIntlService,
  ) {}

  /**
   * Changes calendar view based on direction.
   *
   * @param  dir  Direction to where calendar changes. Increase by month (1) or decrease by month (-1).
   */
  private changeView(dir: 1 | -1) {
    const isMonthView = this.calendar.currentView === 'month';
    const isYearView = this.calendar.currentView === 'year';
    this.calendar.activeDate = isMonthView
      ? this.dateAdapter.addCalendarMonths(this.calendar.activeDate, dir)
      : this.dateAdapter.addCalendarYears(this.calendar.activeDate, isYearView ? dir : dir * yearsPerPage);

    this.calendar.selectedChange.emit(this.calendar.activeDate);
  }

  /**
   * Handles "Today" button click.
   */
  todayClicked() {
    this.calendar.activeDate = this.dateAdapter.today();
    this.calendar.currentView = 'month';
    this.calendar.selectedChange.emit(this.calendar.activeDate);
  }

  /**
   * Handles user clicks on the previous button.
   */
  previousClicked() {
    this.changeView(-1);
  }

  /**
   * Handles user clicks on the next button.
   */
  nextClicked() {
    this.changeView(1);
  }
}

import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { flagEnabled, IrisContextService, isMcm } from '@cohesity/iris-core';
import { CreateForm, createFormProviders } from '@cohesity/shared-forms';
import { AutoDestroyable } from '@cohesity/utils';

import { ViewCategories, ViewCategory } from '../../../models';
import { CreateViewFormService } from '../../../services';

/**
 * Internal form interface.
 */
interface ViewCategoryForm {
  category: ViewCategory;
}

@Component({
  selector: 'coh-create-view-category',
  templateUrl: './view-category.component.html',
  providers: createFormProviders(ViewCategoryComponent),
})
export class ViewCategoryComponent extends AutoDestroyable implements OnInit {
  /**
   * If opened in Edit mode.
   */
  get isEditMode() {
    return this.createViewFormService.workflow === 'EditView';
  }

  /**
   * If the category is inferred.
   */
  get isCategoryInferred() {
    return this.createViewFormService.createViewData?.viewParams?.isCategoryInferred;
  }

  /**
   * If opened in a Helios session.
   */
  isHelios = isMcm(this.irisCtx.irisContext);

  /**
   * Array of view categories.
   */
  categories = ViewCategories;

  /**
   * Initialize the form.
   */
  form = new CreateForm<ViewCategory, ViewCategoryForm>(this, {
    formControls: {
      category: new UntypedFormControl('FileServices', Validators.required),
    },
    transformFromFormGroup: () => this.form.formGroup.value.category,
    transformToFormGroup: category => ({ category: category ?? 'FileServices' }),
  });

  constructor(
    private createViewFormService: CreateViewFormService,
    private irisCtx: IrisContextService,
  ) {
    super();
  }

  /**
   * Init Component
   */
  ngOnInit() {
    const isArchiveCategoryEnabled = flagEnabled(this.irisCtx.irisContext, 'viewArchiveServicesCategory');
    const index = this.categories.findIndex(category => category === 'ArchiveServices');

    // if ngViewArchiveCategory flag is not enabled, then remove it from view category.
    if (index >= 0 && !isArchiveCategoryEnabled) {
      this.categories.splice(index, 1);
    }
   }

  /**
   * Check whether the category is disabled or not.
   *
   * @param category The category to check
   * @returns True, if disabled.
   */
  isCategoryDisabled(category: ViewCategory): boolean {
    // User should be able to override the category when it was inferred for
    // legacy Views or any View created without a Category.
    if (this.isCategoryInferred) {
      return false;
    }

    // User may not create Object Services Views during Helios sessions.
    if (this.isHelios && category === 'ObjectServices') {
      return true;
    }

    // If editing an Object Services View, user may not change to a different category.
    if (this.isEditMode && this.form.formGroup.value.category === 'ObjectServices') {
      return category !== 'ObjectServices';
    }

    // If editing any other type of View, user may not change to Object Services.
    return this.isEditMode && category === 'ObjectServices';
  }
}

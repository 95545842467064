import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { Template, ViewServiceApi } from '@cohesity/api/v2';
import { AppPanelComponent, SnackBarService } from '@cohesity/helix';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { AutoDestroyable } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from '@uirouter/core';
import { get, groupBy, orderBy, sortBy } from 'lodash-es';
import { finalize } from 'rxjs/operators';
import { ClusterService, DialogService } from 'src/app/core/services';

import { AllViewCategories, ViewCategories } from '../../models';
import { ViewsService } from '../../services';

/**
 * Category based templates data with nested structure.
 * Each node has a name and an optional list of children.
 */
interface ParentNode extends Template {
  /**
   * Child node definition
   */
  children?: ParentNode[];
}

/*
 * Flat node with expandable and level information
 */
interface FlatNode {
  /**
   * Determines whether the node is expanded or not.
   */
  expandable: boolean;

  /**
   * Template information per node.
   */
  template: ParentNode;

  /**
   * Determines the order of the node.
   */
  level: number;
}

/**
 * Template panel width for default.
 */
const TEMPLATE_PANEL_WIDTH = '20rem';

/**
 * List of the General templates for each category.
 */
const GENERAL_TEMPLATES = [
  'BackupGeneral',
  'FileServiceGeneral',
  'ObjectServiceGeneral',
  'ArchiveServicesGeneral',
];

@Component({
  selector: 'coh-template-selector',
  templateUrl: './template-selector.component.html',
  styleUrls: ['./template-selector.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TemplateSelectorComponent extends AutoDestroyable implements OnInit {
  /**
   * Treecontrol definition.
   */
  treeControl: FlatTreeControl<FlatNode>;

  /**
   * Default templates tree control definition.
   */
  defaultTreeControl: FlatTreeControl<FlatNode>;

  /**
   * Custom templates tree control definition.
   */
  customTemplateTreeControl: FlatTreeControl<FlatNode>;

  /**
   * Tree flattener definition.
   */
  treeFlattener: MatTreeFlattener<ParentNode, FlatNode>;

  /**
   * Default templates tree datasource definition.
   */
  defaultTemplates: MatTreeFlatDataSource<ParentNode, FlatNode>;

  /**
   * Custom templates tree datasource definition.
   */
  customTemplates: MatTreeFlatDataSource<ParentNode, FlatNode>;

  /**
   * Specifies to show/hide create view by template information.
   */
  isAcknowledged = false;

  /**
   * Specifies to switch between default templates and custom templates view.
   */
  isDefaultTemplatesMode = true;

  /**
   * Determines whether data is loaded or not.
   */
  loading = false;

  /**
   * Mapping for view categories.
   */
  viewCategoriesMap = AllViewCategories;

  /**
   * Indicates whether cluster is running in Helios mode.
   */
  isHelios: boolean;

  /**
   * Whether there are custom templates present or not.
   */
  noCustomTemplates = true;

  /**
   * The active tab index for default/custom templates.
   */
  activeTabIndex = 0;

  /**
   * Determines whether user have the access to create new templates.
   */
  get hasTemplateViewPermission(): boolean {
    return flagEnabled(this.irisCtx.irisContext, 'ngCreateViewTemplates');
  }

  constructor(
    @Optional() private dialogRef: MatDialogRef<AppPanelComponent>,
    private clusterService: ClusterService,
    private dialogService: DialogService,
    private stateService: StateService,
    private snackbar: SnackBarService,
    private irisCtx: IrisContextService,
    private translate: TranslateService,
    private viewService: ViewServiceApi,
    private viewsService: ViewsService,
  ) {
    super();
    this.treeControl = new FlatTreeControl<FlatNode>(node => node.level, node => node.expandable);
    this.defaultTreeControl = this.treeControl;
    this.customTemplateTreeControl = this.treeControl;
    this.treeFlattener = new MatTreeFlattener(
      this._transformer,
      node => node.level,
      node => node.expandable, node => node.children
    );
    this.defaultTemplates = new MatTreeFlatDataSource(this.defaultTreeControl, this.treeFlattener);
    this.customTemplates = new MatTreeFlatDataSource(this.customTemplateTreeControl, this.treeFlattener);
    this.isHelios = !!clusterService.isMcm;
  }

  /**
   * Init Component
   */
  ngOnInit() {
    // Prevents closing of template panel on outside click.
    if (this.dialogRef) {
      this.dialogRef.updateSize(TEMPLATE_PANEL_WIDTH);
    }
    const isArchiveCategoryEnabled = flagEnabled(this.irisCtx.irisContext, 'viewArchiveServicesCategory');

    // if ngViewArchiveCategory flag is not enabled, then remove it from view category and general template.
    if (!isArchiveCategoryEnabled) {
      const indexInViewCategory = ViewCategories.findIndex(category => category === 'ArchiveServices');
      const indexInGeneralTemplate = GENERAL_TEMPLATES.findIndex(category => category === '"ArchiveServicesGeneral"');
      if (indexInViewCategory >= 0) {
        ViewCategories.splice(indexInViewCategory, 1);
      }
      if (indexInGeneralTemplate >= 0) {
        GENERAL_TEMPLATES.splice(indexInGeneralTemplate, 1);
      }
    }

    this.fetchTemplates();
  }

  /**
   * Method called to transform the node level information.
   */
  _transformer = (node: ParentNode, level: number) => ({
    expandable: !!node.children,
    viewCategory: get(node, 'viewCategory'),
    template: node,
    level: level,
  });

  /**
   * Sort options and push the General option at the start.
   *
   * @param templates Raw list of templates from api.
   * @return Alpha sorted list of templates.
   */
  getSortedTemplates(templates = []) {
    const sortedTemplates = orderBy(templates, template => {
      if (this.noCustomTemplates && !template.isDefault) {
        this.noCustomTemplates = false;
      }

      return template.name.toLowerCase();
    });

    return sortBy(sortedTemplates, template => !GENERAL_TEMPLATES.includes(template.defaultTemplateName));
  }

  /**
   * Method called to fetch templates.
   */
  fetchTemplates() {
    this.loading = true;
    this.noCustomTemplates = true;

    this.viewService.ReadViewTemplates().pipe(
      this.untilDestroy(), finalize(() => this.loading = false)
    ).subscribe(response => {
      const viewCategoryTemplateMap = groupBy(response.Templates, (value) => value.viewParams.category);
      const dataSource = ViewCategories.map(category => ({
        viewCategory: category,
        children: this.getSortedTemplates(viewCategoryTemplateMap[category]),
      }));

      this.defaultTemplates.data = dataSource;
      this.defaultTreeControl.expandAll();

      this.customTemplates.data = dataSource;
      this.customTemplateTreeControl.expandAll();
    });
  }

  /**
   * Determines whether a parent node has child node or not.
   */
  hasChild = (_: number, node: FlatNode) => node.expandable;

  /**
   * Determines whether the node is disabled or not.
   *
   * @param node The node to check.
   * @returns True, if disabeled.
   */
  isDisabled(node: FlatNode): boolean {
    return this.isHelios && node.template.viewParams.category === 'ObjectServices';
  }

  /**
   * Method called to switch between template views.
   *
   * @param   change   Button toggle changes
   */
  onUpdate(change: MatButtonToggleChange) {
    this.isDefaultTemplatesMode = change.value;
  }

  /**
   * Method called to open create view by template dialog.
   *
   * @param   selectedTemplate   Selected template.
   */
  openViewDialog(selectedTemplate: Template) {
    this.viewsService.openCreateViewDialog(selectedTemplate)
      .subscribe(view => {
        this.snackbar.open(this.translate.instant('views.createViewSuccess', { name: view.name }));
        this.stateService.go('ng-views.views.all-views', { showTemplates: true }, { reload: true });
      });
  }

  /**
   * Method called to edit custom view template.
   *
   * @param   template   Selected template information.
   */
  editTemplate(template: Template) {
    this.dialogRef.close();
    // Redirecting user to page take-over theme where user can modify
    // view settings.
    // The state transition actually disregards dialogRef close which disables
    // it from opening again, that's why we need to use setTimeout.
    setTimeout(() => this.stateService.go('ng-templates.modify', {
      actionType: 'edit',
      templateId: template.id,
      templateObj: {
        name: template.name,
        viewParams: template.viewParams,
      },
      togglePageView: true,
    }), 0);
  }

  /**
   * Method called to delete selected template.
   *
   * @param   template   Selected template information.
   */
  deleteTemplate(template: Template) {
    this.loading = true;

    this.viewService.DeleteViewTemplate({id: template.id}).pipe(this.untilDestroy())
      .subscribe(() => {
        this.snackbar.open(this.translate.instant('views.templateDeleted.success',
          { name: template.name }));
        this.fetchTemplates();
      }, (error) => {
        this.snackbar.open(error.error.message, 'error');
      });
  }

  /**
   * Whether to show the parent node or not.
   *
   * @param node The Parent node
   * @param isDefaultTemplate If it's in default or custom template
   */
  showParentNode(node: FlatNode, isDefaultTemplate: boolean) {
    if (isDefaultTemplate) {
      return true;
    }

    return node.template.children?.some(t => !t.isDefault);
  }

  /**
   * Returns either the user-entered custom name or a translated name of the
   * default template.
   *
   * @param template Template object.
   * @returns Template name.
   */
  getTemplateName(template: Template) {
    return !template.isDefault ? template.name :
      this.translate.instant('enum.template.' + template.defaultTemplateName);
  }
}

import { Injectable } from '@angular/core';
import { HeliosUsersServiceApi, User } from '@cohesity/api/v1';
import { BANNER_PRIORITY, BannerConfig } from '@cohesity/helix';
import { BaseBannerConfig, IrisContext, IrisContextService, isMcm } from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from '@uirouter/core';
import { isArray } from 'lodash-es';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SuperAdminInformationBannerConfig extends BaseBannerConfig {
  /**
   * Unique banner representation
   */
  id = 'super-admin-info-banner';

  /**
   * provides banner config as observable
   */
  bannerInfo$ = combineLatest([this.irisCtx.irisContext$, this.getUsers()]).pipe(
    map(([ctx, users]) => ([{
      id: this.id,
      priority: BANNER_PRIORITY.LOW,
      isVisible: this.allowBanner(ctx, users),
      status: 'warn',
      allowClose: true,
      text: this.translateService.instant('access.helios.notEnoughSuperAdmin'),
      actionText: this.translateService.instant('configure'),
      actionCallback: () => {
        this.stateService.go('helios-access-management.users');
      },
      closeCallback: this.closeCallback,
    } as BannerConfig]))
  );

  constructor(
    private irisCtx: IrisContextService,
    private heliosUsersServiceApi: HeliosUsersServiceApi,
    private stateService: StateService,
    private translateService: TranslateService
  ) {
    super();
  }

  /**
   * Allow banner based on context
   *
   * @param ctx Iris context
   * @param users users detail
   * @returns true/false based on context
   */
  allowBanner(ctx: IrisContext, users: User[]) {
    return (
      this.isLoggedIn(ctx) &&
      !this.isBannerAcknowledged(this.id) &&
      isArray(users) &&
      users.filter(user => user.roles.some(role => role.includes('COHESITY_MCM_SUPER_ADMIN')))?.length < 2
    );
  }

  /**
   * Fetch user details if current scope is MCM
   *
   * @returns user details
   */
  getUsers(): Observable<User[]> {
    return isMcm(this.irisCtx.irisContext) ? this.heliosUsersServiceApi.GetMcmUsersOp({}) : of(null);
  }
}

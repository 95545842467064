import { ComponentPortal } from '@angular/cdk/portal';
import {
  Component,
  EventEmitter,
  Inject,
  InjectionToken,
  Injector,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { get, merge } from 'lodash-es';
import { DialogService } from 'src/app/core/services';
import { Environment, RecoveryAction, TimelineDialogComponent, TimelineDialogData } from 'src/app/shared';
import { isNoSqlHadoopSource } from 'src/app/util';

import { Recovery, useTaskStatusActionList } from '../../model/recovery';
import { RECOVERY_FORM_OPTIONS, RecoveryFormConfig, RecoveryFormOptions } from '../../model/recovery-form-options-provider';
import { RecoveryObject } from '../../model/recovery-object';
import { NoSqlService } from '../../services/nosql.services';
import { RecoveryObjectPopoverComponent } from '../recovery-object-popover/recovery-object-popover.component';

export const PORTAL_RECOVERY_OBJECTS = new InjectionToken<{}>('portalRecoveryObjects');

/**
 * Mapping from restore task destroy status (old UI) to recovery destroy status.
 */
const destroyStatusMap = {
  destroyError: 'DestroyError',
  destroyRunning: 'Destroying',
  destroySuccess: 'Destroyed'
};

/**
 * List of recovery actions that have no status.
 */
const noStatusActionList = [
  RecoveryAction.RecoverFiles,
  RecoveryAction.DownloadFilesAndFolders
];

/**
 * Recovery detail page objects table.
 *
 * @example
 * <coh-recovery-object-table [recovery]="recovery"></coh-recovery-object-table>
 */
@Component({
  selector: 'coh-recovery-object-table',
  styleUrls: ['./recovery-object-table.component.scss'],
  templateUrl: './recovery-object-table.component.html',
  encapsulation: ViewEncapsulation.None
})
export class RecoveryObjectTableComponent implements OnInit {
  /**
   * Recovery
   */
  @Input() recovery: Recovery;

  /**
   * Template to render the label of the recovery object item.
   */
  @Input() labelTemplate: TemplateRef<any>;

  /**
   * Old Recovery data (restore task in old UI)
   */
  private _recoveryV1: any;

  /**
   * Setter for old Recovery data (restore task in old UI) and update object teardown data.
   */
  @Input() set recoveryV1(recoveryV1: any) {
    this._recoveryV1 = recoveryV1;
    this.updateTearDownInfo();
  }

  /**
   * Getter for old Recovery data (restore task in old UI).
   */
  get recoveryV1(): any {
    return this._recoveryV1;
  }

  /**
   * Notify parent the protection group when its progress completes.
   */
  @Output() readonly progressComplete = new EventEmitter<Recovery>();

  /**
   * Recovery object popover component.
   */
  popover = RecoveryObjectPopoverComponent;

  /**
   * Recovery object table column names.
   */
  showMultiProgress = false;

  /**
   * RecoverObject for NoSQL and rest of the connectors.
   */
  recoveryObjects: RecoveryObject[];

  /**
   * Recovery object table default column names (only single progress).
   */
  readonly singleProgressColumns = [
    'name',
    'targetType',
    'recoveryPoint',
    'status',
    'startTime',
    'durationMs'
  ];

  /**
   * Recovery object table column names for data with 2 progress bars/status (only VMWare now).
   */
  readonly multiProgressColumns = [
    'name',
    'targetType',
    'recoveryPoint',
    'instantRecoveryStatus',
    'datastoreMigrationStatus',
    'startTime',
    'durationMs'
  ];

  /**
   * Recovery object table without status, e.g., RecoverFiles.
   */
  readonly noStatusColumns = [
    'name',
    'targetType',
    'recoveryPoint'
  ];

  /**
   * Show destroy status columns.
   */
  readonly showTearDownAttemptsColumns = [
    'name',
    'targetType',
    'recoveryPoint',
    'status',
    'startTime',
    'tearDownAttempts'
  ];

  /**
   * Environment variable.
   */
  readonly environment = Environment;

  /**
   * Getter for recovery object table columns.
   */
  get columns(): string[] {
    if (this.showMultiProgress) {

      // Filter out Datastore migration status column for Oracle instant restore tasks.
      if (this.recovery.environment === Environment.kOracle) {
        return this.multiProgressColumns.filter(column => column !== 'datastoreMigrationStatus');
      }

      return this.multiProgressColumns;
    }
    if (noStatusActionList.includes(this.recovery.action)) {
      return this.noStatusColumns;
    }
    if (this.recoveryV1 && this.recoveryV1._destroyAttempted) {
      return this.showTearDownAttemptsColumns;
    }
    return this.singleProgressColumns;
  }

  /**
   * Whether to show paginator for objects table.
   */
  get showPaginator(): boolean {
    if (this.recovery && this.recovery.action) {
      if (noStatusActionList.includes(this.recovery.action)) {
        return false;
      }
      if (useTaskStatusActionList.includes(this.recovery.action)) {
        return false;
      }
    }
    return true;
  }

  /**
   * This is a list of all of the registered form options for each adapter.
   */
  private recoveryFormOptions: RecoveryFormOptions = {};

  /**
   * Custom objects component based on specific environment/action.
   */
  customComponent: ComponentPortal<any>;

  constructor(
    private dialogService: DialogService,
    private injector: Injector,
    private noSqlService: NoSqlService,
    private translate: TranslateService,
    @Inject(RECOVERY_FORM_OPTIONS) recoveryFormConfigs: RecoveryFormOptions[]
  ) {
    merge(this.recoveryFormOptions, ...recoveryFormConfigs);
  }

  /**
   * Initialization.
   */
  ngOnInit() {
    const config = this.getRecoveryFormConfig();
    this.setRecoveryObjects();

    if (config && config.objectsComponent) {
      const injector = Injector.create({
        parent: this.injector,
        providers: [{ provide: PORTAL_RECOVERY_OBJECTS, useValue: this.recovery.objects }],
      });

      this.customComponent = new ComponentPortal(
        config.objectsComponent,
        undefined,
        injector
      );
    }
    if (get(this.recovery, 'objects[0].instantRecoveryStatus')) {
      this.showMultiProgress = true;
    }
  }

  /**
   * Set the recovery objects based on Environment type.
   * NoSQL/Hadoop connectors have different recovery schema.
   * If the recovery job is of type NoSQL or Hadoop create RecoveryObject[]
   * from the SnapshotParams provided in recovery schema.
   */
  private setRecoveryObjects() {
    if (this.recovery.action === RecoveryAction.RecoverVAppTemplates) {
      this.recoveryObjects = this.recovery.childObjects;
    } else {
      this.recoveryObjects = isNoSqlHadoopSource(Environment[this.recovery.environment]) ?
        this.noSqlService.getRecoveryObjectsForNoSqlRecoveryJob(this.recovery) : this.recovery.objects;
    }
  }

  /**
   * Updates object teardown data from old restore task data.
   */
  private updateTearDownInfo() {
    const oldObject = get(this.recoveryV1, 'performRestoreTaskState.objects[0]');

    if (oldObject && this.recoveryObjects) {
      this.recoveryObjects.forEach(object => {
        object.tearDownAttempts = oldObject._destroyAttempts;
        if (oldObject._destroyedStatus) {
          const tearDownStatus = destroyStatusMap[oldObject._destroyedStatus];

          if (tearDownStatus) {
            object.tearDownStatus = tearDownStatus;
          }
        }
      });
    }
  }
  /**
   * Get the form configuration for the specified environment.
   *
   * @returns Configuration used to load the form component and transform the data model.
   */
  private getRecoveryFormConfig(): RecoveryFormConfig {
    return this.recoveryFormOptions[this.recovery.environment] &&
      this.recoveryFormOptions[this.recovery.environment][this.recovery.action];
  }

  /**
   * Opens modal window with timeline for object activities.
   */
  openActivityModal({ name = '-', progressTaskId, messages, environment }: RecoveryObject) {
    if (progressTaskId && environment !== Environment.kOracle) {
      this.dialogService.showDialog(TimelineDialogComponent, {
        title: this.translate.instant('recoveryActivityFor', {name}),
        errorMessages: messages,
        task: progressTaskId,
      } as TimelineDialogData, { disableClose: false });
    }
  }

  /**
   * Track by function for the data table
   *
   * @param    index  Item index.
   * @param    item   The item.
   * @returns  The item id.
   */
  trackById(index: number, item: Recovery): string {
    return item.id;
  }
}

import { ObjectProtectionInfo, SearchObject } from '@cohesity/api/v2';
import { Environment } from '@cohesity/iris-shared-constants';
import { pick } from 'lodash-es';

import { GlobalSearchBaseItem, GlobalSearchItemOptions } from './global-search-base-item.model';
import { GlobalSearchResultType } from './global-search-result-type.model';

/**
 * Interface for associated policies of a global search object. These policies
 * are populated for objects protected using object protection.
 */
interface GlobalSearchItemPolicy {
  /**
   * The name of the policy.
   */
  policyName: string;

  /**
   * The id of the policy.
   */
  policyId: string;

  /**
   * The name of the region where the policy is used.
   */
  regionName: string;
}

/**
 * Interface for associated policies of a global search result. These policies
 * are populated for results protected using object protection.
 */
interface GlobalSearchItemPolicy {
  /**
   * The name of the policy.
   */
  policyName: string;

  /**
   * The id of the policy.
   */
  policyId: string;

  /**
   * The name of the region where the policy is used.
   */
  regionName: string;
}

/**
 * Class for global search object items.
 */
export class GlobalSearchObjectItem extends GlobalSearchBaseItem {
  /**
   * The object's logical size in bytes.
   */
  sizeInBytes: number;

  /**
   * List of policies protecting this object.
   */
  policies: GlobalSearchItemPolicy[];

  /**
   * Whether the object is protected as part of an object protection or not.
   */
  hasObjectProtection: boolean;

  /**
   * The object's source uuid.
   */
  sourceUuid: string;

  /**
   * Map of tenant ids and their names which are associated with the object
   */
  tenantsNameMap: Record<string, string>;

  /**
   * The object's id. In MCM mode, this should be fetched from
   * objectId key in locationObjectMap.
   */
  id: number;

  /**
   * Whether the object is present in multiple locations. The same object can be
   * present on different clusters and regions.
   */
  isMultiLocation: boolean;

  constructor(searchObject: SearchObject, options: GlobalSearchItemOptions) {
    super(searchObject, options);

    this.regionIds = [];
    this.clusterIds = [];
    this.locationObjectMap = {};
    this.protectionGroups = [];
    this.policies = [];

    // Source UUID remain the same across different regions and clusters.
    this.sourceUuid = searchObject.sourceInfo?.uuid;

    // objectProtectionInfos is the array of locations where an object is present,
    // the object is always present in at least one location and therefore this
    // array should always have at least one item.
    const {objectProtectionInfos} = searchObject;

    this.globalId = searchObject.globalId;
    this.sizeInBytes = searchObject.logicalSizeBytes;
    this.environment = searchObject.environment as Environment;
    this.type = searchObject.objectType;
    this.isMultiLocation = objectProtectionInfos.length > 1;

    // For non mcm, the object id and source ids will always be the same.
    this.id = objectProtectionInfos[0].objectId;

    this.sourceId = this.getSourceId(objectProtectionInfos[0]);

    this.tenantIds = objectProtectionInfos[0].tenantIds;

    if (Object.keys(options.tenantsNameMap).length && this.tenantIds?.length) {
      this.tenantsNameMap = pick(options.tenantsNameMap, this.tenantIds);
    }

    let isDeletedCount = 0;

    for (const objectProtectionInfo of objectProtectionInfos) {
      this.setLocationObjectMap(objectProtectionInfo, options);
      this.setProtectionValues(objectProtectionInfo, options);

      if (objectProtectionInfo.isDeleted) {
        isDeletedCount++;
      }
    }

    this.deletedInfo = {
      value: isDeletedCount > 0,
      allLocations: isDeletedCount === objectProtectionInfos.length,
      someLocations: isDeletedCount > 0 && isDeletedCount < objectProtectionInfos.length,
    };

    // Deleted objects can still be protected
    this.isProtected = this.hasObjectProtection || this.hasGroupProtection;
    this.resultType = GlobalSearchResultType.Object;
  }

  /**
   * Function to set the location object map of the result.
   *
   * @param objectProtectionInfo The object protection info of the location.
   * @param options The options associated with the search result.
   */
  private setLocationObjectMap(
    objectProtectionInfo: ObjectProtectionInfo,
    options: GlobalSearchItemOptions
  ) {
    const {locationNameMap, clusterDetailsMap} = options;

    if (!objectProtectionInfo.regionId && !objectProtectionInfo.clusterId) {
      // If the object doesn't exist elsewhere, no entry needed in
      // locationObjectMap.
      return;
    }

    // Either a regionId or a clusterId will be set on objectProtectionInfo.
    if (objectProtectionInfo.regionId) {
      this.regionIds.push(objectProtectionInfo.regionId);
    } else {
      this.clusterIds.push(objectProtectionInfo.clusterId);
    }

    const hasObjectProtection = Boolean(objectProtectionInfo.objectBackupConfiguration?.length);
    const hasGroupProtection = Boolean(objectProtectionInfo.protectionGroups?.length);
    const isProtected = hasObjectProtection || hasGroupProtection;
    const location = objectProtectionInfo.regionId || objectProtectionInfo.clusterId;

    this.locationObjectMap[objectProtectionInfo.regionId || objectProtectionInfo.clusterId] = {
      ...objectProtectionInfo,
      sourceId: this.getSourceId(objectProtectionInfo),
      locationName: locationNameMap[location],
      clusterDetails: clusterDetailsMap[objectProtectionInfo.clusterId],
      hasObjectProtection,
      hasGroupProtection,
      isProtected,
    };
  }

  /**
   * Function to set the different protection values of the result.
   *
   * @param objectProtectionInfo The object protection info of the location.
   * @param options The options associated with the search result.
   */
  private setProtectionValues(
    objectProtectionInfo: ObjectProtectionInfo,
    options: GlobalSearchItemOptions
  ) {
    const {locationNameMap} = options;

    const objectBackupConfiguration = objectProtectionInfo.objectBackupConfiguration || [];
    const protectionGroups = (objectProtectionInfo.protectionGroups || []).map(protectionGroup => ({
      ...protectionGroup,
      clusterId: objectProtectionInfo.clusterId,
      clusterName: locationNameMap[objectProtectionInfo.clusterId],
      regionId: objectProtectionInfo.regionId,
    }));

    if (!this.hasObjectProtection) {
      this.hasObjectProtection = Boolean(objectBackupConfiguration.length);
    }

    if (!this.hasGroupProtection) {
      this.hasGroupProtection = Boolean(protectionGroups.length);
    }

    this.protectionGroups.push(...protectionGroups);

    for (const objectBackupConfigurationItem of objectBackupConfiguration) {
      // If the objectBackupConfigurationItem is in the policy map, add it
      // to policies array of the search result. The policy name here is only
      // present for object protection.
      if (Object.prototype.hasOwnProperty.call(objectBackupConfigurationItem, 'policyName')) {
        this.policies.push({
          policyName: objectBackupConfigurationItem.policyName,
          policyId: objectBackupConfigurationItem.policyId,
          regionName: locationNameMap[objectProtectionInfo.regionId],
        });
      }
    }
  }

  /**
   * Gets the source id of the selected object from objectProtectionInfo.
   * For root objects(i.e. M365 Domain, vCenter) use object id for source id.
   *
   * @param    objectProtectionInfo  The object protection info.
   * @returns  Source id of current object
   */
  private getSourceId(objectProtectionInfo: ObjectProtectionInfo): number {
    return objectProtectionInfo.sourceId === -1
      ? objectProtectionInfo.objectId
      : objectProtectionInfo.sourceId;
  }
}

import { McmClusterInfo } from '@cohesity/api/private';
import { envGroups, Environment, RecoveryAction } from 'src/app/shared/constants';

import { SimpleObjectInfo } from './object-menu-provider';
import { ObjectProtectAction } from './object-protect-action.constants';
import { ObjectProtectionGroupAction } from './object-protection-group-action.constants';

/**
 * Type for actions supported by an object.
 */
type ObjectAction = RecoveryAction | ObjectProtectAction | ObjectProtectionGroupAction | 'Protect';

/**
 * Actions allowed on deleted protected objects.
 */
export const DeletedProtectedObjectActions: ObjectAction[] = [
  /**
   * When an object is deleted, it is not unprotected by default.
   */
  ObjectProtectAction.UnProtect,

  /**
   * When an object is deleted, the protection is not paused by default.
   */
  ObjectProtectAction.Pause,
];

/**
 * List of actions which support multiple objects at the same time.
 */
const BulkActions: ObjectAction[] = [
  // Action to protect a selection of items.
  'Protect',

  // Action to perform on an object protection item.
  ObjectProtectAction.Pause,
  ObjectProtectAction.ProtectNow,
  ObjectProtectAction.Resume,
  ObjectProtectAction.UnProtect,
  ObjectProtectAction.CancelRun,

  // Actions to recover a selection of items.
  // In addition to these, RecoverApps supports multiple objects recovery,
  // but only for specific environments.
  RecoveryAction.RecoverAurora,
  RecoveryAction.RecoverFiles,
  RecoveryAction.RecoverNamespaces,
  RecoveryAction.RecoverObjects,
  RecoveryAction.RecoverMailbox,
  RecoveryAction.RecoverOneDrive,
  RecoveryAction.RecoverRDS,
  RecoveryAction.RecoverS3,
  RecoveryAction.RecoverSharePoint,
  RecoveryAction.RecoverMsTeam,
  RecoveryAction.RecoverMsGroup,
  RecoveryAction.RecoverVAppTemplates,
  RecoveryAction.RecoverVApps,
  RecoveryAction.RecoverVMs,
  RecoveryAction.RecoverAzureSQL,
  RecoveryAction.RecoverRDSPostgres,
  RecoveryAction.RecoverAwsDynamoDB,
];

/**
 * List of environments which do not support protecting more than one object at
 * once.
 */
const NoBulkProtectEnvs = [
  Environment.kRemoteAdapter,
];

/**
 * List of environments which do not support recovering more than one object at
 * once.
 */
const NoBulkRecoverEnvs = [
  ...envGroups.nas,
  Environment.kAD,
  Environment.kExchange,
  Environment.kOracle,
  Environment.kPhysical,
  Environment.kPure,
  Environment.kView,
];

/**
 * List of environments which support object protection actions.
 */
const ObjectProtectActionEnvs = [
  Environment.kGenericNas,
  Environment.kIsilon,
  Environment.kNetapp,
  Environment.kVMware,
  Environment.kO365,
  Environment.kAWS,
  Environment.kSQL,
  Environment.kHyperV,
  Environment.kOracle,
  Environment.kUDA,
  Environment.kSAPHANA,
  Environment.kAzure,
];

/**
 * List of environments which support bulk recover apps action.
 * Currently SQL is the only app which supports bulk recovery, in 6.7,
 * support for Exchange will also be added.
 */
export const BulkRecoverAppsEnvs = [
  Environment.kSQL,
];

/**
 * List of environments which have multiple workloads.
 * Performing protection/recovery across workloads are not permitted.
 */
const MultiWorkloadEnvs = [
  Environment.kO365,
  Environment.kAWS,
  Environment.kAzure
];

/**
 * Set of backup types which support same entity types with multiple workloads.
 */
export const MultiWorkloadEnvBackupTypes = new Set([
  /**
   * Backup types on kO365.
   */
  Environment.kO365Exchange,
  Environment.kO365ExchangeCSM,
  Environment.kO365OneDrive,
  Environment.kO365OneDriveCSM,
  Environment.kO365Sharepoint,
  Environment.kO365SharepointCSM,
  Environment.kO365Teams,
  Environment.kO365Group,

  /**
   * Backup types on kAWS.
   */
  Environment.kAWSNative,
  Environment.kAWSSnapshotManager,

  /**
   * Backup types on kPhysical.
   */
  Environment.kPhysical,
  Environment.kPhysicalFiles,

  /**
   * Backup types on Databases.
   */
  Environment.kOracle,
  Environment.kSQL,
]);

/**
 * Specifies the Backup types whose snapshots are not managed by Cohesity.
 * Such backup types cannot support run now or lega hold actions.
 */
export const externalEnvBackupTypes = new Set([
  Environment.kO365ExchangeCSM,
  Environment.kO365OneDriveCSM,
  Environment.kO365SharepointCSM
]);

/**
 * Specifies the set of actions that are unsupported by backup types whose
 * snapshot is not managed by Cohesity.
 */
export const externalEnvBackupTypeUnsupportedActions = new Set([
  'pauseFutureRuns', 'resumeFutureRuns', 'editProtection', 'runNow'
]);

/**
 * Minimum version of the cluster which supports object store. Clusters earlier
 * than this do not have v2 APIs implemented which are required by object store.
 */
const minObjectStoreClusterVersion = '6.5.1';

/**
 * Minimum version of the cluster which supports bulk actions. Clusters earlier
 * than this do not have APIs compatibility which are required by object actions creator.
 */
const minBulkActionsClusterVersion = '6.6.0a';

/**
 * Enum of reasons when a bulk action may not be applicable to objects.
 */
export enum ObjectsIncompatibleReason {
  /**
   * If the object types are different. This is only used for MultiWorkloadEnvs
   * environments right now.
   */
  ObjectType = 'objectType',

  /**
   * If not all objects share the same environment.
   */
  // eslint-disable-next-line @typescript-eslint/no-shadow
  Environment = 'environment',

  /**
   * If not all objects share the same local source id.
   */
  SourceId = 'sourceId',

  /**
   * If not all objects share the cluster.
   */
  AccessClusterId = 'accessClusterId',

  /**
   * If not all objects share the same region.
   */
  RegionId = 'regionId',

  /**
   * If not all objects share the same storage domain. This is only used for
   * recoveries.
   */
  StorageDomainId = 'storageDomainId',

  /**
   * If not all objects are deleted. Currently mixing not deleted and deleted
   * results is not supported.
   */
  IsDeleted = 'isDeleted',
}

/**
 * Interface for the return value of objects compatibility check.
 */
export interface ObjectsActionCompatibility {
  /**
   * True if objects are compatible for bulk action.
   */
  value: boolean;

  /**
   * If objects are not compatible, the reason why they are not compatible.
   */
  reason?: ObjectsIncompatibleReason;
}

/**
 * Options for areObjectsActionCompatible function.
 */
export interface ObjectsCompatibleOptions {
  /**
   * Whether to skip matching source id
   */
  skipSourceId?: boolean;
}

/**
 * Function to determine if an array of objects supports the given action.
 *
 * @param objects The array of simple objects.
 * @param action The action for the array of simple objects.
 * @return Whether the action is supported.
 */
export function checkObjectsForBulkAction(objects: SimpleObjectInfo[], action: ObjectAction): boolean {
  const options: ObjectsCompatibleOptions = {};

  if (objects.length === 1) {
    // If only one object is selected, then the action can be performed on it.
    return true;
  }

  // Sanity check - this generally shouldn't happen, but it might if only an auto protected object was
  // selected.
  if (!objects.length) {
    return false;
  }

  const {environment} = objects[0];

  if (action === RecoveryAction.RecoverApps
      ? !BulkRecoverAppsEnvs.includes(environment)
      : !BulkActions.includes(action)) {
    // Return false if the action is RecoverApps but environment is not supported
    // for bulk RecoverApps, or if the action is not in the the bulk actions array.
    return false;
  }

  let compatibleAction;

  if (action === 'Protect') {
    compatibleAction = !NoBulkProtectEnvs.includes(environment);
  } else if (ObjectProtectAction[action]) {
    compatibleAction = ObjectProtectActionEnvs.includes(environment);

    // These do not require the objects to be in the same source.
    options.skipSourceId = true;
  } else {
    // Recover actions
    compatibleAction = !NoBulkRecoverEnvs.includes(environment);
  }

  // Bulk actions can be performed on objects if the action is compatible,
  // and the objects have the same characteristics.
  return compatibleAction && areObjectsActionCompatible(objects, options).value;
}

/**
 * For an array of objects, determine whether they are compatible to perform
 * bulk actions on them.
 *
 * Generally this means the object needs to be present in the same location,
 * for example, for protection, the object needs to be in the same cluster,
 * and same source, similarly for recovery, the object needs to be also in the
 * same storage domain if doing bulk recovery.
 *
 * @param objects The array of simple objects.
 * @param options Options fot this function.
 * @return Whether the objects are compatible.
 */
export function areObjectsActionCompatible(
  objects: SimpleObjectInfo[],
  options: ObjectsCompatibleOptions = {}
): ObjectsActionCompatibility {
  const [firstObject, ...restObjects] = objects;
  const {
    environment: firstEnvironment,
    objectType: firstObjectType,
    sourceId: firstSourceId,
    accessClusterId: firstAccessClusterId,
    regionId: firstRegionId,
    storageDomainId: firstStorageDomainId,
    isDeleted: firstIsDeleted,
  } = firstObject;

  for (const object of restObjects) {
    // Either accessClusterId or regionId would be typically present, the same
    // object cannot be present in a cluster and region at the same time.
    // Also, storage domain id will only be populated for protected objects.
    const {
      environment,
      objectType,
      sourceId,
      accessClusterId,
      regionId,
      storageDomainId,
      isDeleted,
    } = object;

    // Additional check of the object type is needed for environment with workloads
    // since we do not allow actions on the objects across workloads.
    if (MultiWorkloadEnvs.includes(environment) && objectType !== firstObjectType) {
      return {value: false, reason: ObjectsIncompatibleReason.ObjectType};
    }

    if (!options.skipSourceId && sourceId !== firstSourceId) {
      // Skip source id check if option for that is set.
      return {value: false, reason: ObjectsIncompatibleReason.SourceId};
    }

    if (environment !== firstEnvironment) {
      return {value: false, reason: ObjectsIncompatibleReason.Environment};
    }

    if (accessClusterId !== firstAccessClusterId) {
      return {value: false, reason: ObjectsIncompatibleReason.AccessClusterId};
    }

    if (regionId !== firstRegionId) {
      return {value: false, reason: ObjectsIncompatibleReason.RegionId};
    }

    if (storageDomainId !== firstStorageDomainId) {
      return {value: false, reason: ObjectsIncompatibleReason.StorageDomainId};
    }

    if (isDeleted !== firstIsDeleted) {
      return {value: false, reason: ObjectsIncompatibleReason.IsDeleted};
    }
  }

  return {value: true};
}

/**
 * Function to determine whether a given cluster supports object store.
 * This is determined by checking the cluster version against the minimum
 * known version which supports v2 APIs, which object store uses.
 *
 * @param cluster The given cluster.
 * @return True if the cluster supports the object store.
 */
export function clusterSupportsObjectStore(cluster: McmClusterInfo): boolean {
  return checkClusterVersion(cluster.softwareVersion, minObjectStoreClusterVersion);
}

/**
 * Function to determine whether a given cluster supports object actions creator.
 * This is determined by checking the cluster version against the minimum
 * known version which supports objects actions creator functionality.
 *
 * @param cluster The given cluster.
 * @return True if the cluster supports the object store.
 */
export function clusterSupportsBulkActions(cluster: McmClusterInfo): boolean {
  return checkClusterVersion(cluster.softwareVersion, minBulkActionsClusterVersion);
}

/**
 * Function to determine whether a given cluster version is newer than the
 * provided minimum cluster version.
 * TODO: update references of this util function to use @cohesity/utils instead.
 *
 * @param version The cluster version.
 * @param minVersion The minimum cluster version to compare to.
 * @return True if version is newer than minVersion.
 */
export function checkClusterVersion(version: string, minVersion: string) {
  // TODO: Update this to more generically accommodate all team branches
  if (version?.includes('master') || version?.includes('_team')) {
    // If the software version is master (internal), return true.
    return true;
  }

  // Get the numerical semver version of the cluster. For example,
  // "6.6.0", "6.4.1", etc.
  const formattedVersion = version?.split('_')[0].replace(/[^0-9.]+/g, '');
  const formattedMinVersion = minVersion?.split('_')[0].replace(/[^0-9.]+/g, '');

  if (formattedVersion === formattedMinVersion) {
    // If the version is same as minimum cluster version.
    return true;
  }

  const clusterVersionParts = formattedVersion?.split('.');
  const minVersionParts = formattedMinVersion?.split('.');

  // Return whether the cluster version is newer than the minimum cluster.
  // For example, "6.6" is newer than "6.5.1".
  for (let i = 0; i < clusterVersionParts?.length; i++) {
    const versionA = Number(clusterVersionParts[i] || 0);
    const versionB = Number(minVersionParts[i] || 0);

    if (versionA < versionB) {
      return false;
    }

    if (versionA > versionB) {
      return true;
    }
  }

  return false;
}

/**
 * Determines whether the 'objectActionKey' is amongst the multiple workload
 * types on any object/entity.
 * This can happen when any entity supports multiple types of backups.
 * Refer 'MultiWorkloadEnvBackupTypes' for details.
 *
 * @param     objectActionKey   Specifies the backup type.
 * @returns   Returns true if the backup type can be present along with other
 *            backup type(s) on same entity type.
 */
export function checkObjectActionKeyForMultiWorkloadSupport(objectActionKey: Environment): boolean {
  if (!objectActionKey) {
    return false;
  }
  return MultiWorkloadEnvBackupTypes.has(objectActionKey);
}

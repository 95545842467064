// DEFINE CONSTANTS

(function(angular, undefined) {
  'use strict';

  // Create a local reference to Highcharts for color fuctions, as Highcharts
  // will be removed from window after being added as an injectable constant.
  var Highcharts = window.Highcharts;

  var constModule = angular.module('C.constants', []);

  var filesizeBase = 1024;
  var secsPerDay = 60 * 60 * 24;
  var minsPerDay = 60 * 24;
  var usecsPerDay = secsPerDay * 1000000;
  var byte = 1;
  var bit = byte / 8;
  var kilobit = filesizeBase * bit;
  var kilobyte = filesizeBase * byte;
  var megabit = filesizeBase * kilobit;
  var megabyte = filesizeBase * kilobyte;
  var gigabit = filesizeBase * megabit;
  var gigabyte = filesizeBase * megabyte;
  var terabit = filesizeBase * gigabit;
  var terabyte = filesizeBase * gigabyte;
  var petabit = filesizeBase * terabit;
  var petabyte = filesizeBase * terabyte;

  // Unicode regex patterns.
  var allUnicodeWordCharacters;

  var standardEnvs;
  var applicationEnvs;
  var nasEnvs;
  var sanEnvs;

  // Default retention time is 90 days or 3 months
  constModule.constant('DEFAULT_DAYS_TO_KEEP', 90);

  constModule.constant('TIME', {
    secsPerDay: secsPerDay,
    usecsPerDay: usecsPerDay,
    msecsPerDay: usecsPerDay / 1000,
    minsPerDay: minsPerDay,
  });

  constModule.constant('VARS', {
    adminUsername: 'admin',

    // value in milliseconds
    sliderOffsetDelay: 200,
  });

  // List of supported email formats in on-prem mode.
  constModule.constant('EMAIL_SCHEDULER_FORMATS', [
    'csv',
    'html',
  ]);

  // List of supported email formats in Helios mode.
  constModule.constant('HELIOS_EMAIL_SCHEDULER_FORMATS', [
    'csv',
    'pdf',
    'html',
  ]);

  // List of ip address families.
  constModule.constant('IP_FAMILY', {
    IPv4: 1,
    IPv6: 2
  });


  // List of vlan routings
  constModule.constant('ROUTING', {
    GLOBAL: 1,
    GATEWAY: 2
  });

  // Boolean values to enable/disable a given feature within the app.
  // FeatureFlags are loaded from the static feature-flags.json file.
  constModule.constant('FEATURE_FLAGS', {});

  constModule.constant('FILESIZES', {
    bit: bit,
    byte: byte,
    kilobit: kilobit,
    kilobyte: kilobyte,
    megabit: megabit,
    megabyte: megabyte,
    gigabit: gigabit,
    gigabyte: gigabyte,
    terabit: terabit,
    terabyte: terabyte,
    petabit: petabit,
    petabyte: petabyte,
  });

  // These values should match with those
  // defined in _mixins.scss
  // TODO(veetesh): consider moving into C.charts module.
  constModule.constant('CHART_COLORS', {
    green: '#009e00',
    yellow: '#ecd25f',
    red: '#f41a2e',
    gold: '#fa9112',
    brand: '#1570c8',
    brandDark: '#105cb9',
    textGrey: '#4b4b4b',
    colorPrimary: '#1570c8',
    lightBlue: '#23befa',
    skyBlue: '#c6dcfa',
    pink: '#ff64b4',
    purple: '#9c78e5',
    white: '#ffffff',
    neutralPair1: [
      // cream orange
      '#fdbb58',

      // lighter purple
      '#706c98',
    ],
    neutralPair2: [
      // brown
      '#b07522',

      // caucasian skin
      '#fdcc91',
    ],
    neutralPair3: [
      // burnt umber
      '#c96d31',

      // green/teal
      '#59928d',
    ],
    neutralPair4: [
      // blue/teal
      '#43b2e5',

      // purple
      '#7d5268',
    ],
    plotBackground: 'transparent',
    plotBorder: '#d7d2c4',
    alternateGridColor: 'transparent',
    axisLabels: '#898989',
    legendText: '#757575',
    redux: '#2e5276',
    pieAndDonutTitle: '#79b0e3',
    pieAndDonutLegendText: '#fff',
    stackedPieOverlayText: '#fff',
    gridLines: '#eee',
    subtle: '#ebebeb',
    tenColorPalette: function chooseColor() {
      return [this.brand]
        .concat(this.neutralPair1, this.neutralPair2,
          this.neutralPair3, this.neutralPair4, this.pink);
    },
    getColorWithOpacity: function getColorWithOpacity(color, opacity) {
      return Highcharts.Color(color).setOpacity(opacity || 1).get();
    },
  });

  constModule.constant('CHART_CONFIG', {
    defaultFontFamily: '"noto-sans", "Roboto", Helvetica, Arial, sans-serif',
    legendFontFamily: '"noto-sans", "Roboto", Helvetica, Arial, sans-serif',
    titleFontFamily: '"noto-sans", "Roboto", Helvetica, Arial, sans-serif',
  });

  // Unicode regex patterns.
  // ES6 supports the powerful unicode regex (\p{L} for word characters) but
  // you have to enumerate ranges in ES5.
  // All Unicode Word characters \p{L} is roughly the following:
  allUnicodeWordCharacters = ['\u00AA-\uFFDC'];

  constModule.constant('PUB_TO_PRIVATE_ENV_STRUCTURES', {
    kAcropolis: {
      entityTypesQueryParam: 'acropolisEntityTypes',
      publicEntityKey: 'acropolisProtectionSource',
      privateEntityKey: 'acropolisEntity',
      envType: 12,
      entityTypes: {
        kPrismCentral: 0,
        kStandaloneCluster: 1,
        kCluster: 2,
        kHost: 3,
        kVirtualMachine: 4,
        kNetwork: 5,
        kStorageContainer: 6,
        kOtherHypervisorCluster: 8,
      },
      sourceEntityTypes: {
        kPrismCentral: 0,
        kStandaloneCluster: 1,
        kOtherHypervisorCluster: 8,
      },
    },
    kAzure: {
      entityTypesQueryParam: 'azureEntityTypes',
      publicEntityKey: 'azureProtectionSource',
      privateEntityKey: 'azureEntity',
      envType: 8,
      entityTypes: {
        kSubscription: 0,
        kResourceGroup: 1,
        kVirtualMachine: 2,
        kStorageAccount: 3,
        kNetworkSecurityGroup: 4,
        kVirtualNetwork: 5,
        kSubnet: 6,
        kComputeOptions: 7,
        kStorageKey: 8,
        kStorageContainer: 9,
        kStorageBlob: 10,
        kAvailabilitySet: 13,
      },
      sourceEntityTypes: {
        kSubscription: 0,
      },
    },
    kElastifile: {
      entityTypesQueryParam: 'elastifileEntityTypes',
      publicEntityKey: 'elastifileProtectionSource',
      privateEntityKey: 'elastifileEntity',
      envType: 37,
      entityTypes: {
        kCluster: 0,
        kContainer: 1,
      },
      sourceEntityTypes: {
        kServer: 1,
      },
    },
    kFlashBlade: {
      entityTypesQueryParam: 'flashbladeEntityTypes',
      publicEntityKey: 'flashBladeProtectionSource',
      privateEntityKey: 'flashbladeEntity',
      envType: 21,
      entityTypes: {
        kStorageArray: 0,
        kFileSystem: 1,
      },
      sourceEntityTypes: {
        kFileSystem: 1,
      },
    },
    kGenericNas: {
      entityTypesQueryParam: 'genericNasEntityTypes',
      publicEntityKey: 'nasProtectionSource',
      privateEntityKey: 'genericNasEntity',
      envType: 11,
      entityTypes: {
        kGroup: 0,
        kHost: 1,
        kDfsGroup: 2,
        kDfsTopDir: 3,
      },
      sourceEntityTypes: {
        kHost: 1,
      },
    },
    kGCP: {
      entityTypesQueryParam: 'gcpEntityTypes',
      publicEntityKey: 'gcpProtectionSource',
      privateEntityKey: 'gcpEntity',
      envType: 20,
      entityTypes: {
        kIAMUser: 0,
        kProject: 1,
        kRegion: 2,
        kAvailabilityZone: 3,
        kVirtualMachine: 4,
        kSubnet: 6,
        kTag: 11,
      },
      sourceEntityTypes: {
        kIAMUser: 0,
      },
    },
    kGPFS: {
      entityTypesQueryParam: 'gpfsEntityTypes',
      publicEntityKey: 'gpfsProtectionSource',
      privateEntityKey: 'gpfsEntity',
      envType: 31,
      entityTypes: {
        kCluster: 0,
        kFilesystem: 1,
        kFileset: 2,
      },
      sourceEntityTypes: {
        kServer: 1,
      },
    },
    kHyperV: {
      entityTypesQueryParam: 'hypervEntityTypes',
      publicEntityKey: 'hypervProtectionSource',
      privateEntityKey: 'hypervEntity',
      envType: 2,
      entityTypes: {
        kSCVMMServer: 0,
        kStandaloneHost: 1,
        kStandaloneCluster: 2,
        kHostGroup: 3,
        kHypervHost: 4,
        kHostCluster: 5,
        kVirtualMachine: 6,
        kNetwork: 7,
        kDatastore: 8,
        kTag: 9,
        kCustomProperty: 10,
      },
      sourceEntityTypes: {
        kSCVMMServer: 0,
        kStandaloneHost: 1,
        kStandaloneCluster: 2,
      },
    },
    kHyperVVSS: {
      entityTypesQueryParam: 'hypervEntityTypes',
      publicEntityKey: 'hypervProtectionSource',
      privateEntityKey: 'hypervEntity',
      envType: 18,
      entityTypes: {
        kSCVMMServer: 0,
        kStandaloneHost: 1,
        kStandaloneCluster: 2,
        kHostGroup: 3,
        kHypervHost: 4,
        kHostCluster: 5,
        kVirtualMachine: 6,
        kNetwork: 7,
        kDatastore: 8,
      },
      sourceEntityTypes: {
        kSCVMMServer: 0,
        kStandaloneHost: 1,
        kStandaloneCluster: 2,
      },
    },
    kNetapp: {
      entityTypesQueryParam: 'netappEntityTypes',
      publicEntityKey: 'netappProtectionSource',
      privateEntityKey: 'netappEntity',
      envType: 9,
      entityTypes: {
        kCluster: 0,
        kVserver: 1,
        kVolume: 2,
      },
      sourceEntityTypes: {
        kServer: 1,
      },
    },
    kNimble: {
      // Nimble sotrage uses the same entity name as Pure
      entityTypesQueryParam: 'pureEntityTypes',
      publicEntityKey: 'pureProtectionSource',
      privateEntityKey: 'pureEntity',
      envType: 35,
      entityTypes: {
        kStorageArray: 0,
        kVolume: 1,
      },
      sourceEntityTypes: {
        kStorageArray: 0,
      },
    },
    kIsilon: {
      entityTypesQueryParam: 'isilonEntityTypes',
      publicEntityKey: 'isilonProtectionSource',
      privateEntityKey: 'isilonEntity',
      envType: 14,
      entityTypes: {
        kCluster: 0,
        kZone: 1,
        kMountPoint: 2,
      },
      sourceEntityTypes: {
        kServer: 1,
      },
    },
    kKVM: {
      entityTypesQueryParam: 'kvmEntityTypes',
      publicEntityKey: 'kvmProtectionSource',
      privateEntityKey: 'kvmEntity',
      envType: 15,
      entityTypes: {
        kOVirtManager: 0,
        kStandaloneHost: 1,
        kDatacenter: 2,
        kCluster: 3,
        kHost: 4,
        kVirtualMachine: 5,
        kNetwork: 6,
        kStorageDomain: 7,
        kVNicProfile: 8,
      },
      sourceEntityTypes: {
        kOVirtManager: 0,
        kStandaloneHost: 1,
      },
    },
    kPure: {
      entityTypesQueryParam: 'pureEntityTypes',
      publicEntityKey: 'pureProtectionSource',
      privateEntityKey: 'pureEntity',
      envType: 7,
      entityTypes: {
        kStorageArray: 0,
        kVolume: 1,
      },
      sourceEntityTypes: {
        kStorageArray: 0,
      },
    },
    kPhysical: {
      entityTypesQueryParam: 'physicalEntityTypes',
      publicEntityKey: 'physicalProtectionSource',
      privateEntityKey: 'physicalEntity',
      envType: 6,
      entityTypes: {
        kHostGroup: 0,
        kHost: 1,
        kWindowsCluster: 2,

        // Oracle RAC Cluster
        kOracleRACCluster: 3,

        // Oracle Active Passive Cluster
        kOracleAPCluster: 4,
      },
      sourceEntityTypes: {
        kHost: 1,
      },
    },
    kVMware: {
      entityTypesQueryParam: 'vmwareEntityTypes',
      publicEntityKey: 'vmWareProtectionSource',
      privateEntityKey: 'vmwareEntity',
      envType: 1,
      entityTypes: {
        kVCenter: 0,
        kFolder: 1,
        kDatacenter: 2,
        kComputerResource: 3,
        kClusterComputeResource: 4,
        kResourcePool: 5,
        kDatastore: 6,
        kHostSystem: 7,
        kVirtualMachine: 8,
        kVirtualApp: 9,
        kStandaloneHost: 10,
        kStoragePod: 11,
        kNetwork: 12,
        kDistributedVirtualPortgroup: 13,
        kTagCategory: 14,
        kTag: 15,
        kvCloudDirector: 17,
      },
      sourceEntityTypes: {
        kVCenter: 0,
        kStandaloneHost: 10,
        kvCloudDirector: 17,
      },
    },
    kSQL: {
      entityTypesQueryParam: 'sqlEntityTypes',
      publicEntityKey: 'sqlProtectionSource',
      privateEntityKey: 'sqlEntity',
      envType: 3,
      entityTypes: {
        kInstance: 0,
        kDatabase: 1,
        kAAG: 2,
        kAAGRootContainer: 3,
        kRootContainer: 4,
      },
      sourceEntityTypes: {
        kAAGRootContainer: 3,
        kRootContainer: 4,
      },
    },
    kAWS: {
      entityTypesQueryParam: 'awsEntityTypes',
      publicEntityKey: 'awsProtectionSource',
      privateEntityKey: 'awsEntity',
      envType: 16,
      entityTypes: {
        kIAMUser: 0,
        kRegion: 1,
        kAvailabilityZone: 2,
        kEC2Instance: 3,
        kNetworkSecurityGroup: 6,
        kVPC: 4,
        kSubnet: 5,
        kInstanceType: 7,
        kKeyPair: 8,
        kTag: 9,
        kService: 10,
        kRDSInstance: 12,
      },
      sourceEntityTypes: {
        kIAMUser: 0,
      },
    },
    kO365: {
      entityTypesQueryParam: 'office365EntityTypes',
      publicEntityKey: 'office365ProtectionSource',
      privateEntityKey: 'o365Entity',
      envType: 24,

      // This is in sync with magneto/base/entities/o365.proto
      entityTypes: {
        kDomain: 1,

        // Deprecated Entity Types.
        kOutlook: 2,
        kMailbox: 3,

        // All Users(kUser) are kept under this entity type
        kUsers: 4,

        // All Groups(kGroup) are kept under this entity type.
        kGroups: 5,

        // All Sharepoint Sites(kSite) are kept under this entity type.
        kSites: 6,

        // Specifies Office 365 User holding corresponding Mailbox & Onedrive
        // account.
        kUser: 7,

        // Specifies Office 365 Group.
        kGroup: 8,

        // Specifies Office 365 Sharepoint Site.
        kSite: 9,

        // All Public folders(kPublicFolder) are kept under this entity type.
        kPublicFolders: 12,

        // Specifies Office 365 Public folder.
        kPublicFolder: 13,

        // All Teams(kTeam) are kept under this entity type.
        kTeams: 14,

        // Specifies Office 365 Teams.
        kTeam: 15,
      },
      sourceEntityTypes: {
        kDomain: 1,
      },
    },
    kOracle: {
      entityTypesQueryParam: 'oracleEntityTypes',
      publicEntityKey: 'oracleProtectionSource',
      privateEntityKey: 'oracleEntity',
      envType: 19,

      entityTypes: {
        kSingleInstance: 1,
        kRACDatabase: 2,
      },
      sourceEntityTypes: { },
    },
  });

  constModule.constant('PRIVATE_TO_PUBLIC_ENV_STRUCTURES', {
    kOracle: {
      databaseTypes: {
        1: 'kSingleInstance',
        2: 'kRACDatabase'
      },
    },
  });

  constModule.constant('ENTITY_KEYS', {
    1: 'vmwareEntity',
    2: 'hypervEntity',
    3: 'sqlEntity',
    4: 'viewEntity',
    6: 'physicalEntity',
    7: 'pureEntity',
    8: 'azureEntity',
    9: 'netappEntity',
    11: 'genericNasEntity',
    12: 'acropolisEntity',
    14: 'isilonEntity',
    15: 'kvmEntity',
    16: 'awsEntity',
    17: 'exchangeEntity',
    18: 'hypervEntity',
    19: 'oracleEntity',
    20: 'gcpEntity',
    21: 'flashbladeEntity',
    24: 'o365Entity',
    29: 'adEntity',
    31: 'gpfsEntity',
    34: 'kubernetesEntity',
    35: 'nimbleEntity',
    37: 'elastifileEntity',
    38: 'cassandraEntity',
    39: 'mongodbEntity',
    40: 'hbaseEntity',
    41: 'hiveEntity',
    42: 'hdfsEntity',
    43: 'couchbaseEntity',
    46: 'udaEntity',
  });

  constModule.constant('SOURCE_SPECIAL_PARAMETERS_KEYS', {
    kAD: 'adSpecialParameters',
    kOracle: 'oracleSpecialParameters',
    kPhysical: 'physicalSpecialParameters',
    kSQL: 'sqlSpecialParameters',
    kVMware: 'vmwareSpecialParameters',
  });

  constModule.constant('HARDWARE_TYPES', {
    HP: [
      'ProliantGen9',
      'ProliantDl380Gen10H4',
      'ProliantDl380Gen10H8',
      'ApolloR2200Gen10H8',
      'ApolloR2200Gen10H12',
      'Apollo4510Gen10',
      'Apollo4510Gen10H8',
      'Apollo4510Gen10H14',
    ],
  });

  constModule.constant('RUN_STATUS', {
    kBackupJobSuccess: 'success',
    kBackupJobRunning: 'running',
    kBackupJobFailure: 'error',
    kBackupJobCanceled: 'canceled',
  });

  /**
   * DB_ENUM_RUN_STATUS_CONVERSION contains mapping b/w job run enum kValues to
   * there numerical values and vice versa.
   *
   * Groot DB is SQL schema for our Magneto and other modules proto for custom
   * reporting use cases.
   *
   * Job run constant are defined in /main/groot/etl/util/dao_utils.cc file.
   */
  constModule.constant('DB_ENUM_RUN_STATUS_CONVERSION', assignWithInverted({
    kJobRunStatusSuccess: 4,
    kJobRunStatusInProgress: 1,
    kJobRunStatusError: 6,
    kJobRunStatusCancelled: 3,
  }));

  /**
   * DB_RUN_STATUS_TEXT contains translation keys for job run status from Groot
   * DB schema.
   */
  constModule.constant('DB_RUN_STATUS_TEXT', {
    kJobRunStatusSuccess: 'success',
    kJobRunStatusInProgress: 'running',
    kJobRunStatusError: 'error',
    kJobRunStatusCancelled: 'canceled',
  });

  standardEnvs = [
    1, 'kVMware',
    2, 'kHyperV',
    6, 'kPhysical',
    7, 'kPure',
    8, 'kAzure',
    9, 'kNetapp',
    11, 'kGenericNas',
    12, 'kAcropolis',
    13, 'kPhysicalFiles',
    14, 'kIsilon',
    15, 'kKVM',
    16, 'kAWS',
    17, 'kExchange',
    18, 'kHyperVVSS',
    20, 'kGCP',
    21, 'kFlashBlade',
    22, 'kAWSNative',
    24, 'kO365',
    25, 'kO365Outlook',
    27, 'kGCPNative',
    28, 'kAzureNative',
    29, 'kAD',
    30, 'kAWSSnapshotManager',
    31, 'kGPFS',
    32, 'kRDSSnapshotManager',
    34, 'kKubernetes',
    35, 'kNimble',
    36, 'kAzureSnapshotManager',
    37, 'kElastifile',
    38, 'kCassandra',
    39, 'kMongoDB',
    40, 'kHBase',
    41, 'kHive',
    42, 'kHdfs',
    43, 'kCouchbase',
    46, 'kUDA',
  ];
  applicationEnvs = [
    3, 'kSQL',
    17, 'kExchange',
    19, 'kOracle',
    29, 'kAD',
  ];
  nasEnvs = [9, 11, 14, 21, 31, 37, 'kNetapp', 'kGenericNas', 'kIsilon', 'kFlashBlade', 'kGPFS', 'kElastifile'];
  sanEnvs = [7, 'kPure', 35, 'kNimble'];

  // Convenience arrays of logical groups of envTypes
  constModule.constant('ENV_GROUPS', {
    // Primary sources which are browsable via c-source-tree
    // TODO: Can be renamed to 'sources'
    browsable: [1, 2, 4, 6, 7, 9, 11, 12, 13, 14, 15, 18, 21],

    // Sources which use Standard Policies and are to be fetched in getSources()
    // TODO: Needs to refactored out
    standard: standardEnvs,

    // All sources.
    all: standardEnvs.concat(applicationEnvs),

    // Sources which has applications
    applicationSources: applicationEnvs,

    // Sources which supports auto protect feature
    autoProtectSupported: [
      'kAcropolis',
      'kAWSNative',
      'kAWSSnapshotManager',
      'kGCPNative',
      'kHyperV',
      'kHyperVVSS',
      'kKubernetes',
      'kKVM',
      'kNetapp',
      'kO365Outlook',
      'kSQL',
      'kVMware',
    ],

    // environments which support azure managed disks while clone/deploy
    azureManagedDisksSupported: [1, 2, 'kVMware', 'kHyperV'],

    // These environment types should not be displayed in global search filters.
    // For example, HyperVVSS is just a subset of HyperV and is not an actual
    // independent object type.
    notGlobalSearchFilterable: ['kHyperVVSS'],

    // External Targets which support bulk retrieval
    vaultsSupportBulkRetrieval:
      ['kAmazonGlacier', 'kAmazonS3GlacierDeepArchive'],

    // Sources that don't support 'clone' operation
    cloneUnsupported: ['kAcropolis', 'kAWSNative', 'kAzureNative', 'kGCPNative',
      'kAzureSnapshotManager', 'kAWSSnapshotManager', 'kRDSSnapshotManager'],

    // Sources which support cloud deploy
    cloudDeploySources: ['kAzure', 'kAWS', 8, 16],

    // Cloud Migration is supported for these sources while backup
    cloudMigrationSupported: ['kVMware', 'kHyperV', 'kHyperVVSS'],

    // Cloud Sources
    cloudSources: ['kAzure', 'kAWS', 'kGCP', 8, 16, 20],

    // Cloud Edition Sources -> Sources which support
    // Cohesity DataPlatform on Cloud
    cloudEditionSources: ['aws', 'azure', 'gcp'],

    // Holds the job's environment types for cloud backup
    // which doesn't need a physical agent to be installed on VMs.
    cloudJobsWithoutPhysicalAgent: [22, 27, 28, 30, 32, 36,
      'kAWSNative', 'kGCPNative', 'kAzureNative',
        'kAzureSnapshotManager', 'kAWSSnapshotManager', 'kRDSSnapshotManager'],

    // These Cloud sources support prtection using native APIs provided by
    // cloud provider.
    cloudSourcesWithNativeSupport: ['kAWS', 'kAzure', 'kGCP', 8, 16, 20],

    // Jobs which do not create any local snapshots
    cloudJobsWithoutLocalSnapshot: [30, 32, 36, 44,
      'kAzureSnapshotManager', 'kAWSSnapshotManager', 'kRDSSnapshotManager', 'kAuroraSnapshotManager'],

    // These cloud sources support native as well as physical agent based
    // snapshots
    awsTypes: ['kAWS', 'kAWSNative',
      'kAWSSnapshotManager', 'kRDSSnapshotManager'],
    azureTypes: ['kAzure', 'kAzureNative', 'kAzureSnapshotManager'],

    // Azure Stack can be AD/ADFS
    azureStackTypes: ['kAzureStackCommercial', 'kAzureStackADFS', 5, 7],

    // cloud native snapshot types
    nativeSnapshotTypes: [22, 27, 28,
      'kAWSNative', 'kGCPNative', 'kAzureNative'],

    // job types which support remote snapshot management
    remoteSnapshotManagementSupported: [30, 36,
      'kAzureSnapshotManager', 'kAWSSnapshotManager'],

    // Job types with tag support which do not have categories
    tagTypesWithoutCategories: ['kGCPNative', 'kAWSNative',
      'kAWSSnapshotManager'],

    // Sources which supports special parameters
    sourceSpecialParamSupported: ['kVMware', 'kPhysical', 'kAD', 'kKubernetes'],

    // environments that use or can use a Cohesity agent/binary.
    usesAgent: ['kPhysical', 'kHyperV', 'kVMware'],

    // Hypervisor types
    hypervisor: [1, 2, 8, 12, 15, 16, 18, 20, 22, 27, 28, 30, 36,
      'kVMware', 'kHyperV', 'kGCP', 'kAzure', 'kHyperVVSS', 'kKVM', 'kAWS',
      'kAcropolis', 'kAWSNative', 'kGCPNative', 'kAzureNative',
      'kAzureSnapshotManager', 'kAWSSnapshotManager', 'kRDSSnapshotManager'],

    // Physical types
    physical: [6, 13, 'kPhysical', 'kPhysicalFiles'],

    // Environments which support PIT (Point in Time) API
    pitSupported: ['kOracle', 'kRDSSnapshotManager', 'kSQL', 'kVMware'],

    // hyperv types
    hyperv: [2, 18, 'kHyperV', 'kHyperVVSS'],

    // kubernetes environment type
    kubernetes: [34, 'kKubernetes'],

    // Sources which are NAS variants
    nas: nasEnvs,

    // Sources for NAS Adapters
    nasAdapters: [9, 14, 21, 31, 37, 'kNetapp', 'kIsilon', 'kFlashBlade', 'kGPFS', 'kElastifile'],

    // Sources for NoSQL Adapters
    nosqlAdapters: [38, 39, 43, 'kCassandra', 'kMongoDB', 'kCouchbase'],

    // Sources for NoSQL Adapters
    hadoopAdapters: [40, 41, 42, 'kHBase', 'kHive', 'kHdfs'],

    // TODO(tauseef): Move Office365 constants within app/shared/constants.
    // Office 365 environment types.
    office365: [24, 25, 49, 50, 51, 'kO365', 'kO365Outlook',  'kO365Exchange', 'kO365OneDrive', 'kO365Sharepoint'],

    san: sanEnvs,

    // Generic grouping for NAS and SAN types.
    storageVolumes: _.concat(nasEnvs, sanEnvs),

    // Remote Pre/Post Script Adapters
    remotePrePostScriptAdapters: _.concat(nasEnvs, sanEnvs),

    // Sources which can host SQL
    sqlHosts: [1, 6, 'kVMware', 'kPhysical'],

    // Sources which can host Oracle
    oracleHosts: [6, 'kPhysical'],

    // Composite SQL source
    sqlSources: [1, 6, 3, 'kVMware', 'kPhysical', 'kSQL'],

    // Oracle Sources
    oracleSources: [6, 19, 'kPhysical', 'kOracle'],

    // Database hosts: includes SQL, Oracle, etc. Does not include the app
    // entities.
    databaseHosts: [1, 6, 'kVMware', 'kPhysical'],

    // Database restore types for Oracle and SQL.
    // 'kCloneAppView' is for expose as a cohesity view.
    databaseRestoreTypes: [4, 18, 'kRecoverApp', 'kCloneAppView'],

    // Database sources
    databaseSources: [3, 19, 'kSQL', 'kOracle'],

    // Sources which can recover as instant volume mount
    instantMount: [1, 6, 8, 16, 22,
      'kVMware', 'kPhysical', 'kAWS', 'kAWSNative', 'kAzure', 'kAzureNative'],

    // these sources have specials cases when being displayed, currently
    // being used in c-source-table
    individuallyListed: ['kPhysical', 'kView', 'kSQL', 'kAD'],

    // Sources from which can backup files directly
    fileBackups: [9, 11, 13, 14, 21, 31, 37],

    // Any Sources which can be browsed.
    // NOTE: Do not use this as a source of truth, as it does not account for
    // FEATURE_FLAGS. Instead use SearchService.getBrowsableEnvironments() to
    // retrieve a copy of this array modified to account for FEATURE_FLAGS
    fileBrowsable:  [
      'kAcropolis',
      'kAD',
      'kAWS',
      'kAWSNative',
      'kAzure',
      'kAzureNative',
      'kElastifile',
      'kFlashBlade',
      'kGCP',
      'kGCPNative',
      'kGenericNas',
      'kGPFS',
      'kHyperV',
      'kHyperVVSS',
      'kIsilon',
      'kKVM',
      'kNetapp',
      'kPhysical',
      'kPhysicalFiles',
      'kSQL',
      'kView',
      'kVMware',
      'kKubernetes'
    ],

    // Should include every ENV_TYPE which can be registered. The only excluded
    // types are: Remote Adapter (5), Azure (8), Agent (10), MS Exchange (17)
    // TODO: Consolidate this with 'browsable' group
    objectTypes: [1, 2, 3, 4, 6, 7, 9, 11, 12, 13, 14, 18, 19, 21],

    // Sources with a wrapping container:
    //   SQL Hosts (3)
    //   Physical Servers (6)
    //   NAS Mount Points (11)
    //   Exchange Servers (17)
    //   Oracle Servers (19)
    //   AD (29)
    cohesityGroups: [3, 6, 11, 17, 19, 29, 'kSQL', 'kPhysical', 'kGenericNas',
      'kExchange', 'kOracle', 'kAD'],

    // Any Sources which can be indexed.
    // NOTE: Do not use this as a source of truth, as it does not account for
    // FEATURE_FLAGS. Instead use JobService.isEnvTypeIndexable(envType) to test
    // a specific envType or JobService.getIndexableEnvTypes() to retrieve a
    // copy of this array modified to account for FEATURE_FLAGS
    indexable:
      [1, 2, 3, 4, 5, 6, 8, 9, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
        24, 25, 27, 29, 31, 37],

    // NOTE: same note as above, but use
    // PubJobServiceFormatter.isEnvironmentIndexable()
    indexableKvals: [
      'kAcropolis',
      'kAWS',
      'kAWSNative',
      'kAzure',
      'kAzureNative',
      'kElastifile',
      'kFlashBlade',
      'kGCP',
      'kGCPNative',
      'kGenericNas',
      'kGPFS',
      'kHyperV',
      'kHyperVVSS',
      'kIsilon',
      'kKVM',
      'kNetapp',
      'kO365',
      'kO365Outlook',
      'kPhysical',
      'kPhysicalFiles',
      'kPuppeteer',
      'kSQL',
      'kView',
      'kVMware',
      'kAD',
      'kExchange',
    ],

    // Indexable entities which do not support Indexing rules of
    // Inclusions or Exclusions of paths within the entity.
    indexableWithoutRules: [24, 35, 29, 'kO365', 'kO365Outlook', 'kAD'],

    // Indexable entities differentiation:

    // indexableEntitiesExposedAsServers specifies the entities which are
    // exposed as volumes/servers and are indexed by Yoda. These can be browsed
    // by mounting the volumes.
    //
    // This group is based on the method -- IsIndexableServer(...) within
    // yoda/util/utils.cc
    //
    // Note:
    //  - In case of kHyperV entities the job type should be kHyperVVSS.
    //  - In case of kPhysical entities the job type should *not* be
    //    kPhysicalFiles.
    //
    // These special conditions are handled within
    // JobServiceFormatter.differentiateIndexableEntity()
    //
    indexableEntitiesExposedAsServers: [
      1,
      6,
      8,
      12,
      15,
      16,
      18,
      20,
      22,
      27,
      28,
      30,
      36,
      'kAcropolis',
      'kAWS',
      'kAWSNative',
      'kAWSSnapshotManager',
      'kAzure',
      // TODO(Tauseef) Investigate if the 'native' values are needed here
      'kAzureNative',
      'kAzureSnapshotManager',
      'kGCP',
      'kGCPNative',
      'kHyperVVSS',
      'kKVM',
      'kPhysical',
      'kVMware',
    ],

    // indexableEntitiesExposedAsViews specifies the entities which are exposed
    // as views and are indexed by Yoda. These can be browsed by the directory
    // structure instead of volumes.
    //
    // This group is based on the method -- IsIndexableView(...) within
    // yoda/util/utils.cc
    //
    // Note:
    //  - In case of kPhysical entities the job type should be kPhysicalFiles.
    //
    // This special condition is handled within
    // JobServiceFormatter.differentiateIndexableEntity()
    indexableEntitiesExposedAsViews: [
      4,
      9,
      11,
      13,
      14,
      21,
      31,
      37,
      'kElastifile',
      'kFlashBlade',
      'kGenericNas',
      'kGPFS',
      'kIsilon',
      'kNetapp',
      'kPhysicalFiles',
      'kView',
    ],

    /**
     * Environment types that support browsing for leaf entities
     */
    entityBrowsable: [1, 2, 4, 6, 7, 8, 9, 11, 12, 14, 16, 18, 20, 21, 31, 37],

    // For these sources we do not support Recover Files to Source. Protected
    // Files may only be downloaded.
    downloadOnly: [
      'viewEntity',
      'kvmEntity',
    ],

    /**
     * View and RA jobs/envs that are SpanFS things. They have no "objects" when
     * backed up.
     */
    spanFS: [4, 5, 'kPuppeteer', 'kView'],

    /**
     * RA jobs/envs that are SpanFS things. They have no "objects" when
     * backed up.
     *
     * note: this list duplicates spanFS for backward compatibility.
     *
     */
    spanFSSansView: [5, 'kPuppeteer'],

    /* Sources which support tagging of entities for autoprotect, ex. KVMware
     * represent 1. kHyperV represent 2.
     */
    taggable: ['kVMware', 'kHyperV', 'kHyperVVSS', 'kAWSNative',
      'kAWSSnapshotManager', 'kAzureNative', 'kAzureSnapshotManager', 'kGCPNative',
        1, 2, 18, 22, 27, 28, 30, 36],

    // List of Entities for which credentials are non-mandatory in
    // file and folder recovery.
    entitiesWithNonMandatoryCredentials: [
      8,
      16,
      20,
      22,
      27,
      28,
      30,
      'kAzure',
      'kAWS',
      'kGCP',
      'kAWSNative',
      'kGCPNative',
      'kAzureNative',
      'kAWSSnapshotManager',
      'kAzureSnapshotManager',
    ],

    // List of Entities(kHyperV, kAcropolis, kHyperVVS) for which credentials
    // are required on Linux in file and folder recovery.
    entitiesWithNonMandatoryCredentialsOnLinuxHost: [
      2,
      12,
      18,
    ],

    /**
     * Types of oracle clusters.
     */
    oracleClusterTypes: ['kOracleAPCluster', 'kOracleRACCluster'],

    // RPO Adapters
    rpoAdapters: [1, 'kVMware'],

    // Type of enitities for which refresh is shown in source tree.
    sourceRefreshSupported: [
      1,
      2,
      18,
      'kHyperV',
      'kHyperVVSS',
      'kVMware',
    ],

    // Hdfs
    hdfs: [42, 'kHdfs'],
  });

  // Convenience arrays of logical groups of source type (protectionSource.type)
  constModule.constant('SOURCE_TYPE_GROUPS', {
    protectableVmEntities: ['vm', 'vapp'],
    hypervHosts: ['kHypervHost', 'kSCVMMServer', 'kStandaloneHost', 'kStandaloneCluster'],

    // There is now a HOST_TYPE_GROUPS constant in TS covering these. These can
    // be deprecated.
    physicalBlockBased: ['kWindows', 'kLinux'],
    physicalFileBased: ['kLinux', 'kAix', 'kSolaris', 'kHPUX', 'kVOS', 'kWindows'],
  });

  // To be used with SourceService.getEntitiesOfType to fetch all leaf entities.
  // This should be updated any time a new adapter is added.
  constModule.constant('GET_ENTITIES_OF_TYPE_LEAVES', {
    environmentTypes: [
      'kAcropolis',
      'kAD',
      'kAWS',
      'kAgent',
      'kAzure',
      'kFlashblade',
      'kGCP',
      'kGenericNas',
      'kGPFS',
      'kHyperFlex',
      'kHyperV',
      'kIsilon',
      'kKVM',
      'kNetapp',
      'kO365',
      'kOracle',
      'kPhysical',
      'kPure',
      'kSQL',
      'kView',
      'kVMware'
    ],
    acropolisEntityTypes: ['kVirtualMachine'],
    adEntityTypes: ['kRootContainer', 'kDomainController'],
    awsEntityTypes: ['kEC2Instance', 'kRDSInstance'],
    agentEntityTypes: ['kGroup', 'kHost'],
    azureEntityTypes: ['kVirtualMachine'],
    flashbladeEntityTypes: ['kFileSystem'],
    gcpEntityTypes: ['kVirtualMachine'],
    genericNasEntityTypes: ['kHost'],
    gpfsEntityTypes: ['kFileset'],
    hyperflexEntityTypes: ['kServer'],
    hypervEntityTypes: ['kVirtualMachine'],
    isilonEntityTypes: ['kMountPoint'],
    kvmEntityTypes: ['kVirtualMachine'],
    netappEntityTypes: ['kVolume'],
    office365EntityTypes: ['kOutlook', 'kMailbox', 'kUsers', 'kGroups',
    'kSites', 'kPublicFolders', 'kTeams', 'kUser', 'kGroup', 'kSite', 'kPublicFolder', 'kTeam'],
    oracleEntityTypes: ['kDatabase'],
    physicalEntityTypes: [ 'kHost', 'kWindowsCluster', 'kOracleRACCluster',
      'kOracleAPCluster'],
    pureEntityTypes: ['kVolume'],
    sqlEntityTypes: ['kDatabase'],
    viewEntityTypes: [ 'kView', 'kViewBox'],
    vmwareEntityTypes: ['kVirtualMachine'],
  });

  // List of hierarchy levels to auto-expand, per source type
  constModule.constant('EXPANDED_NODES', {
    1: [0, 1, 2, 3, 5],
    2: [0, 1, 2, 3, 5],
    3: [0],
    6: [0],
    7: [0],
    9: [0],
    11: [0],
    12: [0, 1, 2, 3, 5],
    13: [0],
    14: [0],
    16: [0],
    18: [0, 1, 2, 3, 5],
  });

  // A hash of envBackupParams keys *only* for those Job types which have backup
  // params.
  constModule.constant('BACKUP_PARAMS_ENUM', {
    1: 'vmwareBackupParams',
    2: 'hypervBackupParams',
    3: 'sqlBackupJobParams',
    6: 'physicalBackupParams',
    9: 'nasBackupParams',
    11: 'nasBackupParams',
    13: 'physicalBackupParams',
    14: 'nasBackupParams',
    18: 'hypervBackupParams',
    21: 'nasBackupParams',
    31: 'nasBackupParams',
    37: 'nasBackupParams',
  });

  // From magneto/base/magneto.proto: enum FullBackupType
  constModule.constant('ENUM_FULL_BACKUP_TYPE', {
    kSqlVSSVolume: 0,
    kSqlVSSFile: 1,
    kSqlNative: 2,
    0: 'kSqlVSSVolume',
    1: 'kSqlVSSFile',
    2: 'kSqlNative',
  });

  // used for to establish a reference to env specific recover task configs.
  constModule.constant('RECOVER_ENV_PARAMS', {
    kVMware: 'vmwareParameters',
  });

  // used to dynamically set proto key during IVM flow
  constModule.constant('IVM_ENV_PARAMS', {
    kVMware: 'vmwareParams',
    kHyperV: 'hypervParams',
  });

  // used for to establish a reference to env specific recover task configs.
  constModule.constant('RECOVER_ENV_PARAMS', {
    kVMware: 'vmwareParameters',
  });

  constModule.constant('POLICY_TYPE', {
    1: 'standard',
    3: 'database',
    4: 'view',
  });

  constModule.constant('JOB_QOS_POLICY_TYPE', {
    0: 'Backup HDD (Default)',
    1: 'Backup SSD',
  });

  // entity type is hashed by envType and then entity.type
  // CAUTION:- any addition/removal of keys here would need to do the same in
  // ui.json as new public API specific keys are residing there.
  constModule.constant('ENUM_ENTITY_TYPE', {
    // VMware
    1: {
      0: 'vCenter',
      1: 'Folder',
      2: 'Datacenter',
      3: 'Compute Resource',
      4: 'Cluster Compute Resource',
      5: 'Resource Pool',
      6: 'Data Store',
      7: 'Host System',
      8: 'Virtual Machine',
      9: 'vApp',

      // kStandaloneHost
      10: 'Standalone ESXi Host',
      11: 'Storage Pod',
      12: 'Network',
      13: 'Distributed Virtual Port Group',
      14: 'Tag Category',
      15: 'Tag',
      17: 'vCloud Director',
    },

    // hyperv
    2: {
      0: 'SCVMM Server',
      1: 'Standalone Host',
      2: 'Failover Cluster',
      3: 'Host Group',
      4: 'HyperV Host',
      5: 'Host Cluster',
      6: 'Virtual Machine',
      7: 'Network',
    },

    // SQL
    3: {
      0: 'MS SQL Instance',
      1: 'MS SQL Database',

      // TODO(spencer): Get wording for this. However, never displays ATM.
      2: 'SQL AAG Entity',
      3: 'SQL AAG Container',
      4: 'MS SQL Hosts and Clusters',
    },

    // view
    4: {
      0: 'Storage Domain',
      1: 'View',
    },

    // physical
    6: {
      0: 'Physical Servers & Databases',
      1: 'Physical Server',
      2: 'MS SQL Cluster',
      3: 'Oracle Servers',
      4: 'Cluster VIP',
    },

    // SAN:Pure
    7: {

      // kStorageArray
      0: 'Storage Array',

      // kVolume
      1: 'Volume',
    },

    // kAzure
    8: {

      // Subscription
      0: 'Azure Subscription',
      1: 'Azure Resource Group',
      2: 'Virtual Machine',
      7: 'Azure Compute Option',
    },

    // kNetApp
    // magneto/base/entities/netapp.proto: 187
    9: {
      0: 'NAS (NetApp Cluster)',
      1: 'NAS (NetApp VServer)',
      2: 'NAS (NetApp Volume)',
    },

    // kAgent
    // 10: {}

    // kGenericNas
    // magneto/base/entities/generic_nas.proto
    11: {
      0: 'NAS Mount Points',
      1: 'NAS Volume',
    },

    // kAcropolis
    12: {
      0: 'Prism Central',
      1: 'Acropolis Standalone Cluster',
      2: 'Cluster',
      3: 'Host',
      4: 'Virtual Machine',
      5: 'Network',
      6: 'Storage Container',
      8: 'Other Hypervisors',
    },

    // kPhysicalFiles envType still uses entity type 6
    // 13: {}

    // kIsilon
    14: {
      0: 'NAS (Isilon Cluster)',
      1: 'NAS (Isilon Zone)',
      2: 'NAS (Isilon Mount Point)',
    },

    // kKVM
    15: {
      0: 'oVirt Manager',
      1: 'Standalone Host',
      2: 'Datacenter',
      3: 'Cluster',
      4: 'Host',
      5: 'Virtual Machine',
      6: 'Network',
      7: 'Storage Domain',
    },

    //kAWS
    16: {
      0: 'IAM User/Role',
      1: 'Region',
      2: 'Availability Zone',
      3: 'EC2 Instance',
      4: 'Network Security Group',
      5: 'VPC',
      6: 'Subnet',
      7: 'Instance Type',
    },

    // kExchange
    17: {
      'kRootContainer': 'Exchange Server',
    },

    // hyperVVSS
    18: {
      0: 'SCVMM Server',
      1: 'Standalone Host',
      2: 'Failover Cluster',
      3: 'Host Group',
      4: 'HyperV Host',
      5: 'Host Cluster',
      6: 'Virtual Machine',
      7: 'Network',
    },

    // Oracle
    19 : {
      1: 'Oracle Servers & Clusters',
      3: 'Oracle Database',
    },

    // GCP
    20: {
      0: 'IAM User',
      1: 'Project',
      2: 'Region',
      3: 'Availability Zone',
      4: 'Virtual Machine',
    },

    // Pure FlashBlade
    21: {
      // kStorageArray
      0: 'FlashBlade Array',

      // kVolume
      1: 'File System',
    },

    // Office 365
    24: {
      // kDomain
      1: 'Office 365 Domain',

      // Deprecated Entity Types.
      2: 'Outlook',
      3: 'Mailbox',

      // New Entity Types.
      4: 'Users',
      5: 'Groups',
      6: 'Sites',
      7: 'User',
      8: 'Group',
      9: 'Site',
    },

    // Storage Snapshot Provider
    26: {
      // kHyperflex
      0: 'Hyperflex',
    },

    29: {
      kDomainController: 'Domain Controller',
    },

    // GPFS
    31: {
      //kCluster
      0: 'NAS (GPFS Cluster)',

      // kFilesystem
      1: 'NAS (GPFS Filesystem)',

      // kFileset
      2: 'NAS (GPFS Fileset)',
    },

    // Kubernetes
    34: {
      // kCluster
      0: 'Kubernetes Cluster',

      // kNamespace
      1: 'Kubernetes Namespace',

      // kService
      2: 'Kubernetes Service',
    },

    // SAN: Nimble
    35: {

      // kStorageArray
      0: 'Storage Array',

      // kVolume
      1: 'Volume',
    },

    // Elastifile
    37: {
      //kCluster
      0: 'NAS (Elastifile Cluster)',

      // kContainer
      1: 'NAS (Elastifile Container)',
    },

    // Cassandra
    38: {
      // kCluster
      0: 'Cassandra Cluster',

      // kKeyspace
      1: 'Cassandra Keyspace',

      // kTable
      2: 'Cassandra Table',
    },

    // MongoDB
    39: {
      // kCluster
      0: 'MongoDB Cluster',

      // kDatabase
      1: 'MongoDB Database',

      // kCollection
      2: 'MongoDB Collection',
    },

    // HBase
    40: {
      // kCluster
      0: 'HBase Cluster',

      // kNamespace
      1: 'HBase Namespace',

      // kTable
      2: 'HBase Table',
    },

    // Hive
    41: {
      // kCluster
      0: 'Hive Cluster',

      // kDatabase
      1: 'Hive Database',

      // kTable
      2: 'Hive Table',
    },

    // HDFS
    42: {
      // kCluster
      0: 'HDFS Cluster',
    },

    // Couchbase
    43: {
      // kCluster
      0: 'Couchbase Cluster',

      // kBucket
      1: 'Couchbase Bucket',
    },

    // Universal Data Adapter
    46: {
      // kCluster
      0: 'Universal Data Adapter Cluster',
    },
  });

  // magneto/base/enums.proto
  constModule.constant('ENUM_HOST_TYPE', {
    '-1': 'Unknown OS',
    0: 'Linux',
    1: 'Windows',
    2: 'Other',
    3: 'AIX',
    4: 'Solaris',
    16: 'HPUX',
    17: 'VOS',
    kLinux: 'Linux',
    kWindows: 'Windows',
    kAix: 'AIX',
    kSolaris: 'Solaris',
    kOther: 'Other',
    kHPUX:  'HPUX',
    kVOS: 'VOS'
  });

  // magneto/base/enums.proto
  constModule.constant('ENUM_HOST_TYPE_CONVERSION', {
    0: 'kLinux',
    1: 'kWindows',
    2: 'kOther',
    3: 'kAix',
    4: 'kSolaris',
    16: 'kHPUX',
    17: 'kVOS',
    kLinux: 0,
    kWindows: 1,
    kOther: 2,
    kAix: 3,
    kSolaris: 4,
    kHPUX: 16,
    kVOS: 17
  });

  // List used for ui-select of View FS protocols.
  constModule.constant('VIEW_FILESYSTEM_PROTOCOL', [
    {
      enum:'kSmb',
      display: 'SMB',
    },
    {
      enum:'kNfs',
      display: 'NFS',
    },
    {
      enum:'kS3',
      display: 'S3',
    },
  ]);

  // View Security Style translation keys
  constModule.constant('ENUM_VIEW_SECURITY_STYLE', {

    // V2 Security modes.
    NativeMode: 'native',
    NtfsMode: 'ntfs',
    UnifiedMode: 'unified',

    // Deprecated kValues of security modes.
    kNativeMode: 'native',
    kNtfsMode: 'ntfs',
    kUnifiedMode: 'unified',
  });

  // NFS view protocol list.
  constModule.constant('NFS_VIEW_PROTOCOLS', ['kNFSOnly', 'kAll', 'NFS']);

  constModule.constant('ENUM_NETAPP_VOLUME_TYPE', {
    0: 'kReadWrite',
    2: 'kDataProtection',
  });

  // NetApp filesystem protocols
  constModule.constant('ENUM_FILESYSTEM_TYPE', {
    0: 'NFS',
    1: 'SMB',
  });

  // FlashBlade filesystem protocols
  constModule.constant('ENUM_FILESYSTEM_TYPE_FLASHBLADE', {
    1: 'NFS',
    2: 'SMB',
    3: 'HTTP',
  });

  // Generic NAS, GPFS and Elastifile filesystem protocols
  constModule.constant('ENUM_FILESYSTEM_TYPE_GENERIC', {
    1: 'NFS',
    2: 'SMB',
  });

  // NFS/SMB enum value map for each NAS source environment.
  constModule.constant('NAS_FILESYSTEM_MAP', {
    // enum values are kNfs = 0, kCifs = 1
    // look for DataProtocol in magneto/base/entities/netapp.proto
    kNetapp: {
      nfs: ['kNfs'],
      smb: ['kCifs'],
    },

    // enum values are kNfs3 = 1, kCifs1 = 2
    // look for NasProtocol in magneto/base/enums.proto
    kGenericNas: {
      nfs: ['kNfs3'],
      smb: ['kCifs1'],
    },

    // enum values used are kNfs = 0, kSmb = 1
    // look for MountPointInfo in magneto/base/entities/isilon.proto
    kIsilon: {
      nfs: ['kNfs'],
      smb: ['kSmb'],
    },

     // enum values used are kNfs = 0, kSmb = 1
    kFlashBlade: {
      nfs: ['kNfs'],
      smb: ['kCifs2'],
      http: ['kHttp'],
    },

    // enum values are kNfs3 = 1, kCifs1 = 2
    // look for NasProtocol in magneto/base/enums.proto
    kGPFS: {
      nfs: ['kNfs3'],
      smb: ['kCifs1'],
    },

    // enum values are kNfs3 = 1, kCifs1 = 2
    // look for NasProtocol in magneto/base/enums.proto
    kElastifile: {
      nfs: ['kNfs3'],
      smb: ['kCifs1'],
    },
  });

  // mapping of NAS data protcol and kValues
  constModule.constant('NAS_KVALUE_DATA_PROTOCOL_MAP', {
    'kNfs' : 'nfs',
    'kNfs3': 'nfs',
    'kCifs': 'smb',
    'kCifs1': 'smb',
    'kSmb': 'smb',
    'kCifs2': 'smb',
    'kHttp': 'http',
  });

  constModule.constant('NAS_ENTITY_KEY_DATA_PROTOCOL_MAP', {
    // look for DataProtocol in magneto/base/entities/netapp.proto
    netappEntity: {
      0: 'kNfs',
      1: 'kCifs',
    },

    // look for NasProtocol in magneto/base/enums.proto
    genericNasEntity: {
      1: 'kNfs3',
      2: 'kCifs1',
    },

    // look for MountPointInfo in magneto/base/entities/isilon.proto
    isilonEntity: {
      0: 'kNfs',
      1: 'kSmb',
    },

    flashbladeEntity: {
      1: 'kNfs',
      2: 'kCifs2',
      3: 'kHttp',
    },

    // look for NasProtocol in magneto/base/enums.proto
    gpfsEntity: {
      1: 'kNfs3',
      2: 'kCifs1',
    },

    // look for NasProtocol in magneto/base/enums.proto
    elastifileEntity: {
      1: 'kNfs3',
      2: 'kCifs1',
    },
  });

  // supported NAS filesystem types
  constModule.constant('SUPPORTED_NAS_FILESYSTEM', {
    // look for DataProtocol in magneto/base/entities/netapp.proto
    kNetapp: ['kNfs', 'kCifs'],

    // look for NasProtocol in magneto/base/enums.proto
    kGenericNas: ['kNfs3', 'kCifs1'],

    // look for MountPointInfo in magneto/base/entities/isilon.proto
    kIsilon: ['kNfs', 'kSmb'],

    // look for FileSystem in magneto/base/entities/flashblade.proto
    kFlashBlade: ['kNfs', 'kCifs2', 'kHttp'],

    // look for NasProtocol in magneto/base/enums.proto
    kGPFS: ['kNfs3', 'kCifs1'],

    // look for NasProtocol in magneto/base/enums.proto
    kElastifile: ['kNfs3', 'kCifs1'],
  });

  constModule.constant('ENUM_GUEST_TOOLS_STATUS', {
    // 0: 'kUnknown',
    // 1: 'kGuestToolsExecutingScripts',
    // 2: 'kGuestToolsNotRunning',
    // 3: 'kGuestToolsRunning',
    UNKNOWN: 0,
    EXECUTING: 1,
    NOT_RUNNING: 2,
    RUNNING: 3,
  });

  constModule.constant('ENUM_CONNECTION_STATE', {
    0: 'Connected',
    1: 'Disconnected',
    2: 'Inaccessible',
    3: 'Invalid',
    4: 'Orphaned',
  });

  // The different kinds of disk types supported by Azure
  constModule.constant('AZURE_DISK_TYPES', {
    1: 'Premium SSD',
    2: 'Standard SSD',
    3: 'Standard HDD',
  });

  constModule.constant('CONNECTION_STATE_DISPLAY_NAME', {
    // TODO: remove this key when public API fixes the typo.
    kInacccessible: 'enum.connectionState.kInaccessible',
    kConnected: 'enum.connectionState.kConnected',
    kDisconnected: 'enum.connectionState.kDisconnected',
    kInaccessible: 'enum.connectionState.kInaccessible',
    kInvalid: 'enum.connectionState.kInvalid',
    kOrphaned: 'enum.connectionState.kOrphaned',
    kNotResponding: 'enum.connectionState.kNotResponding',
  });

  // .../magneto/base/entities/sql.proto 50
  constModule.constant('SQL_RECOVERY_MODEL_DISPLAY', {
    kSimpleRecoveryModel: 'enum.sqlRecoveryModel.kSimpleRecoveryModel',
    kFullRecoveryModel: 'enum.sqlRecoveryModel.kFullRecoveryModel',
    kBulkLoggedRecoveryModel: 'enum.sqlRecoveryModel.kBulkLoggedRecoveryModel',
    0: 'enum.sqlRecoveryModel.kSimpleRecoveryModel',
    1: 'enum.sqlRecoveryModel.kFullRecoveryModel',
    2: 'enum.sqlRecoveryModel.kBulkLoggedRecoveryModel',
  });

  // .../main/magneto/base/entities/sql.proto 62
  constModule.constant('SQL_DATABASE_STATE_DISPLAY_KEY', {
    kOnline: 'enum.sqlDatabaseState.kOnline',
    kRestoring: 'enum.sqlDatabaseState.kRestoring',
    kRecovering: 'enum.sqlDatabaseState.kRecovering',
    kRecoveryPending: 'enum.sqlDatabaseState.kRecoveryPending',
    kSuspect: 'enum.sqlDatabaseState.kSuspect',
    kEmergency: 'enum.sqlDatabaseState.kEmergency',
    kOffline: 'enum.sqlDatabaseState.kOffline',
    kCopying: 'enum.sqlDatabaseState.kCopying',
    kOfflineSecondary: 'enum.sqlDatabaseState.kOfflineSecondary',
    0: 'enum.sqlDatabaseState.kOnline',
    1: 'enum.sqlDatabaseState.kRestoring',
    2: 'enum.sqlDatabaseState.kRecovering',
    3: 'enum.sqlDatabaseState.kRecoveryPending',
    4: 'enum.sqlDatabaseState.kSuspect',
    5: 'enum.sqlDatabaseState.kEmergency',
    6: 'enum.sqlDatabaseState.kOffline',
    7: 'enum.sqlDatabaseState.kCopying',
    8: 'enum.sqlDatabaseState.kOfflineSecondary',
  });

  /**
   * Database state for Oracle DB is currently not defined for Oracle in
   * /main/magneto/base/entities/oracle.proto
   *
   * TODO(Tauseef): If these enums are available add them below.
   */
  constModule.constant('ORACLE_DATABASE_STATE_DISPLAY_KEY', {
    kOnline: 'enum.oracleDatabaseState.kOnline',
    0: 'enum.oracleDatabaseState.kOnline',
  });

  // TODO(Tauseef): Rename the entity icon class once all entites are migrated
  // to /assets/scss/_icons-entities.scss
  constModule.constant('ENUM_ENTITY_ICON_CLASS', {
    1: {
      0: 'vmware-vcenter',
      1: 'vmware-folder',
      2: 'vmware-datacenter',
      3: 'vmware-compute-resource',
      4: 'vmware-cluster-compute-resource',
      5: 'vmware-resource-pool',
      6: 'vmware-data-store',
      7: 'vmware-compute-resource',
      8: 'vmware-virtual-machine',
      9: 'vmware-vapp',
      10: 'vmware-host-system',
      15: 'vmware-tag',
      19: 'vmware-data-store',
      24: 'vmware-vapp-template'
    },

    kVMware: {
      kVCenter: 'vmware-vcenter',
      kFolder: 'vmware-folder',
      kDatacenter: 'vmware-datacenter',
      kComputeResource: 'vmware-compute-resource',
      kClusterComputeResource: 'vmware-cluster-compute-resource',
      kOrganization: 'vcloud-org',
      kResourcePool: 'vmware-resource-pool',
      kDatastore: 'vmware-data-store',
      kHostSystem: 'vmware-compute-resource',
      kVirtualDatacenter: 'vcloud-vapp',
      kVirtualMachine: 'vmware-virtual-machine',
      kVirtualApp: 'vmware-vapp',
      kvAppTemplate: 'vmware-vapp-template',
      kStandaloneHost: 'vmware-stand-alone-ESX-host',
      kTag: 'vmware-tag',
      kvCloudDirector: 'vcloud-dir',
    },

    2: {
      0: 'vmware-datacenter',
      1: '',
      2: '',
      3: 'vmware-host-system',
      4: 'vmware-compute-resource',
      5: 'vmware-cluster-compute-resource',
      6: 'vmware-virtual-machine',
      7: '',
      8: '',
    },

    kHyperV: {
      kSCVMMServer: 'vmware-datacenter',
      kStandaloneHost: 'vmware-compute-resource',
      kStandaloneCluster: 'vmware-cluster-compute-resource',
      kHostGroup: 'vmware-host-system',
      kHypervHost: 'vmware-compute-resource',
      kHostCluster: 'vmware-cluster-compute-resource',
      kVirtualMachine: 'vmware-virtual-machine',
      kNetwork: '',
      kDatastore: '',
      kTag: 'vmware-tag',
    },

    // SQL
    3: {
      // DB App Entity
      0: 'icn-db',

      // DB Entity (Leaf)
      1: 'icn-db',
      2: 'icn-cloud',

      // Some AAG thing (TBD)
      3: 'icn-cloud',

      // SQL kRootContainer
      4: 'vmware-folder',

      // AAG Database
      5: 'icn-volume-aag',
    },

    kSQL: {
      kInstance: 'icn-db-instance',
      kDatabase: 'icn-db',
      kSystemDatabases: 'icn-folder',

      // This kType is fabricated in the UI. It's created for the UI to
      // distinguish a SQL AAG database from a non-AAG SQL database.
      kDatabaseAAG: 'icn-volume-aag',
      kAAG: 'icn-cloud',
      kAAGRootContainer: 'icn-cloud',
      kRootContainer: 'vmware-folder',
      kAAGDatabase: 'icn-volume-aag'
    },

    4: {
      0: '',
      1: 'icn-entity-view',
    },

    kView: {
      kViewBox: '',
      kView: 'icn-entity-view',
    },

    6: {
      // Physical Servers
      0: 'vmware-folder',
      1: 'vmware-compute-resource',
      2: 'vmware-cluster-compute-resource',
      3: 'oracle-rac',
      4: 'vmware-cluster-compute-resource',
    },

    kPhysical: {
      // TODO(veetesh): check 'kHostGroup' is not in magneto physical.proto
      kHostGroup: 'vmware-folder',
      kGroup: 'vmware-folder',
      kHost: 'vmware-compute-resource',
      kOracleAPCluster: 'oracle-rac',
      kOracleRACCluster: 'oracle-rac',
      kWindowsCluster: 'vmware-cluster-compute-resource',
      kUnixCluster: 'os-unix-cluster',
    },

    // SAN:Pure
    7: {
      // kStorageArray
      0: 'vmware-datacenter',

      // kVolume
      1: 'icn-volume',
    },

    kPure: {
      kStorageArray: 'vmware-datacenter',
      kVolume: 'icn-volume',
    },

    // Azure
    8: {
      0: 'vmware-cluster-compute-resource',
      1: 'vmware-compute-resource',
      2: 'vmware-virtual-machine',
    },

    kAzure: {
      kTenant:'object-azure-resting',
      kSubscription: 'object-azure-subscriptions-resting',
      kResourceGroup: 'object-azure-resource-group-resting',
      kVirtualMachine: 'vmware-virtual-machine',
      kComputeOptions: 'azure-generic',
      kStorageAccount: 'azure-generic',
      kStorageKey: 'azure-generic',
      kStorageContainer: 'azure-generic',
      kStorageBlob: 'azure-generic',
      kNetworkSecurityGroup: 'azure-generic',
      kVirtualNetwork: 'azure-generic',
      kSubnet: 'azure-generic',
      kTag: 'vmware-tag',
      kSQLServer: 'icn-db-instance',
      kSQLManagedInstance: 'icn-db-instance',
      kSQLDatabase: 'icn-db',
      kEntraID: 'object-azure-entra-id',
    },

    // NetApp
    9: {
      // kCluster
      0: 'vmware-cluster-compute-resource',

      // kVserver
      1: 'vmware-compute-resource',

      // kVolume
      2: 'icn-volume',
    },

    kNetapp: {
      kCluster: 'vmware-cluster-compute-resource',
      kVserver: 'vmware-compute-resource',
      kVolume: 'icn-volume',
    },

    // SAN:Ibm FlashSystem
    10: {
      // kStorageArray
      0: 'vmware-datacenter',

      // kVolume
      1: 'icn-volume',
    },

    kIbmFlashSystem: {
      kStorageArray: 'vmware-datacenter',
      kVolume: 'icn-volume',
    },

    // NAS
    11: {
      // kGroup
      0: 'vmware-data-store',

      // kHost
      1: 'icn-volume',

      // kDfsGroup
      2: 'vmware-data-store',

      // kDfsTopDir
      3: 'icn-volume',
    },

    kGenericNas: {
      kGroup: 'vmware-data-store',
      kHost: 'icn-volume',
      kDfsGroup: 'vmware-data-store',
      kDfsTopDir: 'icn-volume',
    },

    // kAcropolis
    12: {
      0: '',
      1: 'datacenter',
      2: '',
      3: '',
      4: 'vmware-virtual-machine',
      5: '',
      6: '',
      7: '',
      8: '',
    },

    kAcropolis: {
      kPrismCentral: '',
      kStandaloneCluster: 'datacenter',
      kCluster: '',
      kHost: '',
      kVirtualMachine: 'vmware-virtual-machine',
      kNetwork: '',
      kStorageContainer: '',
      kOtherHypervisorCluster: 'datacenter',
    },

    // Physical File
    13: {
      // Physical Servers
      0: 'vmware-folder',
      1: 'vmware-compute-resource',
      2: 'vmware-cluster-compute-resource',
    },

    kPhysicalFiles: {
      kGroup: 'vmware-folder',
      kHost: 'vmware-compute-resource',
      kWindowsCluster: 'vmware-cluster-compute-resource',
    },

    // Isilon
    // TODO: Update when we get final icons from UX
    14: {
      // kCluster
      0: 'vmware-cluster-compute-resource',

      // kZone
      1: 'vmware-compute-resource',

      // kMountPoint
      2: 'icn-volume',
    },

    kIsilon: {
      kCluster: 'vmware-cluster-compute-resource',
      kZone: 'vmware-compute-resource',
      kMountPoint: 'icn-volume',
    },

    // KVM
    15: {
      // kOVirtManager
      // TODO (SHISHIR): replace with kvm icon after UX provides it
      0: 'vmware-vcenter',
      2: 'vmware-datacenter',
      3: 'vmware-cluster-compute-resource',
      4: 'vmware-host-system',
      5: 'vmware-virtual-machine',

      // kNetwork
      6: 'vmware-host-system',

      // kStorageDomain
      7: 'icn-volume',
    },

    kKVM: {
      // TODO (SHISHIR): replace with kvm icon after UX provides it
      kOVirtManager: 'vmware-vcenter',
      kDatacenter: 'vmware-datacenter',
      kCluster: 'vmware-cluster-compute-resource',
      kHost: 'vmware-host-system',
      kVirtualMachine: 'vmware-virtual-machine',
      kNetwork: 'vmware-host-system',
      kStorageDomain: 'icn-volume',
    },

    // AWS
    16: {
      0: 'aws-iamuser',
      1: 'aws-region',
      2: 'vmware-datacenter',
      3: 'vmware-virtual-machine',
      6: 'icn-volume',
      12: 'icn-db',
    },

    kAWS: {
      kAuroraCluster: 'icn-db',
      kIAMUser: 'aws-iamuser',
      kRegion: 'aws-region',
      kAvailabilityZone: 'vmware-datacenter',
      kEC2Instance: 'vmware-virtual-machine',
      kInstanceType: 'aws-iamuser',
      kKeyPair: 'aws-iamuser',
      kSubnet: 'aws-iamuser',
      kNetworkSecurityGroup: 'aws-iamuser',
      kVPC: 'aws-iamuser',
      kRDSInstance: 'icn-db',
      kTag: 'vmware-tag',
    },

    // Exchange
    17: {
      kExchangeDAG: 'vmware-cluster-compute-resource',
      kExchangeNode: 'vmware-cluster-compute-resource',
      kExchangeDAGDatabase: 'icn-db',
      kExchangeStandaloneDatabase: 'icn-db',
    },
    kExchange: {
      kExchangeDAG: 'vmware-cluster-compute-resource',
      kExchangeNode: 'vmware-cluster-compute-resource',
      kExchangeDAGDatabase: 'icn-db',
      kExchangeStandaloneDatabase: 'icn-db',
    },

    // HyperVVSS
    18: {
      0: 'vmware-datacenter',
      1: 'vmware-compute-resource',
      2: 'vmware-cluster-compute-resource',
      3: 'vmware-host-system',
      4: 'vmware-compute-resource',
      5: 'vmware-cluster-compute-resource',
      6: 'vmware-virtual-machine',
      7: '',
      8: '',
    },

    kHyperVVSS: {
      kSCVMMServer: 'vmware-datacenter',
      kStandaloneHost: 'vmware-compute-resource',
      kStandaloneCluster: 'vmware-cluster-compute-resource',
      kHostGroup: 'vmware-host-system',
      kHypervHost: 'vmware-compute-resource',
      kHostCluster: 'vmware-cluster-compute-resource',
      kVirtualMachine: 'vmware-virtual-machine',
      kNetwork: '',
      kDatastore: '',
    },

    19: {
      // Oracle App Entity
      0: 'icn-db',

      // DB Entity (Leaf)
      1: 'icn-db',
      2: 'icn-db',
      3: 'icn-db',

      // Oracle kRootContainer
      4: 'vmware-folder',
    },

    // GCP
    20: {
      0: 'gcp-iamuser',
      1: 'gcp-project',
      2: 'aws-region',
      3: 'vmware-datacenter',
      4: 'vmware-virtual-machine',
      11: 'vmware-tag',
    },

    kGCP: {
      kIAMUser: 'gcp-iamuser',
      kProject: 'gcp-project',
      kRegion: 'aws-region',
      kAvailabilityZone: 'vmware-datacenter',
      kVirtualMachine: 'vmware-virtual-machine',
      kTag: 'vmware-tag',
    },

    kOracle: {
      kDatabase: 'icn-db',
      kSingleInstance: 'icn-db',
      kRACDatabase: 'icn-db',
    },

    // Pure FlashBlade
    21: {
      // kStorageArray
      0: 'vmware-datacenter',

      // kFileSystem
      1: 'icn-volume',
    },

    kFlashBlade: {
      kStorageArray: 'vmware-datacenter',
      kFileSystem: 'icn-volume',
    },

    // Office 365 sources
    // This is in sync with magneto/base/entities/o365.proto
    24: {
      1: 'icn-office365',
      2: 'icn-outlook',
      3: 'icn-mailbox',
      5: 'icn-msgroup',
      6: 'icn-sharepoint',
      8: 'icn-msgroup',
      9: 'icn-sharepoint',
      12: 'icn-publicfolder',
      13: 'icn-publicfolder',
      14: 'icn-team',
      15: 'icn-team',
    },

    kO365: {
      kDomain: 'icn-office365',
      kEmail: 'icn-email',
      kEmailFolder: 'icn-folder',

      // Deprecated Entity Hierarchy.
      kOutlook: 'icn-outlook',
      kMailbox: 'icn-mailbox',

      // New Entity Hierarchy.
      kGroup: 'icn-msgroup',
      kGroups: 'icn-msgroup',
      kUser: 'icn-user',
      kUsers: 'icn-user',
      kSites: 'icn-sharepoint',
      kSite: 'icn-sharepoint',
      kPublicFolders: 'icn-publicfolder',
      kPublicFolder: 'icn-publicfolder',
      kTeams: 'icn-team',
      kTeam: 'icn-team',
    },

    // HyperFlex
    26: {
      0: 'icn-volume',
    },

    kHyperFlex: {
      kHyperFlex: 'vmware-vcenter',
    },

    // Cohesity Cluster
    '-1': {
      0: 'cohesity-box-logo',
    },

    _kKuiper: {
      _kKuiper: 'cohesity-box-logo',
    },

    // Active Directory
    29: {
      kDomainController: 'icn-active-directory',
    },
    kAD: {
      kDomainController: 'icn-active-directory',
    },

    // GPFS
    31: {
      // kCluster
      0: 'vmware-cluster-compute-resource',

      // kFilesystem
      1: 'vmware-compute-resource',

      // kFileset
      2: 'icn-volume',
    },

    kGPFS: {
      kCluster: 'vmware-cluster-compute-resource',
      kFilesystem: 'vmware-compute-resource',
      kFileset: 'icn-volume',
    },

    // kKubernetes
    34: {
      // kCluster
      0: 'vmware-cluster-compute-resource',

      // kNamespace
      1: 'icn-kubernetes-namespace',
    },

    kKubernetes: {
      kCluster: 'vmware-cluster-compute-resource',
      kNamespace: 'kubernetes-namespace',
    },

    // SAN:Pure
    35: {
      // kStorageArray
      0: 'vmware-datacenter',

      // kVolume
      1: 'icn-volume',
    },

    kNimble: {
      kStorageArray: 'vmware-datacenter',
      kVolume: 'icn-volume',
    },

    // Elastifile
    37: {
      // kCluster
      0: 'vmware-cluster-compute-resource',

      // kContainer
      1: 'icn-volume',
    },

    kElastifile: {
      kCluster: 'vmware-cluster-compute-resource',
      kContainer: 'icn-volume',
    },

    // Cassandra
    38: {
      // kCluster
      0: 'cassandra-cluster',

      // kKeyspace
      1: 'icn-db',

      // kTable
      2: 'object-table'
    },

    kCassandra: {
      kCluster: 'cassandra-cluster',
      kKeyspace: 'cassandra-keyspace',
      kTable: 'object-table',
    },

    // MongoDB
    39: {
      //kCluster
      0: 'icn-db',

      // kDatabase
      1: 'icn-db',

      // kCollection
      2: 'object-table',
    },

    kMongoDB : {
      kCluster: 'icn-db',
      kDatabase: 'icn-db',
      kCollection: 'object-table',
    },

    // HBase
    40: {
      // kCluster
      0: 'icn-db',

      // kNamespace
      1: 'icn-db',

      // kTable
      2: 'object-table',
    },

    kHBase: {
      kCluster: 'icn-db',
      kNamespace: 'icn-db',
      kTable: 'object-table',
    },

    // Hive
    41: {
      // kCluster
      0: 'icn-db',

      // kDatabase
      1: 'icn-db',

      // kTable
      2: 'object-table',
    },

    kHive: {
      kCluster: 'icn-db',
      kDatabase: 'icn-db',
      kTable: 'object-table',
    },

    // HDFS
    42: {
      // kCluster
      0: 'icn-volume',
    },

    kHdfs: {
      kCluster: 'icn-volume',
    },

    // Couchbase
    43: {
      // kCluster
      0: 'icn-db',

      // kBucket
      1: 'icn-db',
    },

    kCouchbase: {
      kCluster: 'icn-db',
      kBucket: 'icn-db',
    },

    // Universal Data Adapter
    46: {
      // kCluster
      0: 'icn-db',

      // kObject
      1: 'icn-db',
    },

    kUDA: {
      kCluster: 'icn-db',
      kObject: 'icn-db',
    },

    kSfdc: {
      kOrg: 'object-salesforce',
      kObject: 'object-table'
    },

    47: {
      0: 'icn-db',
    },

    kSAPHANA: {
      kSapHanaCluster: 'icn-db',
    }

  });

  // To be used via environmentIconClass filter
  constModule.constant('ENVIRONMENT_ICON_CLASS', {
    kAD: 'icn-active-directory',
    kVMware: 'vmware-virtual-machine',
    kHyperV: 'vmware-virtual-machine',
    kSQL: 'icn-db',
    kView: 'icn-entity-view',
    kPhysical: 'vmware-compute-resource',
    kPure: 'icn-volume',
    kAzure: 'azure-generic',
    kNetapp: 'icn-volume',
    kGenericNas:  'icn-volume',
    kGPFS: 'icn-volume',
    kElastifile: 'icn-volume',
    kAcropolis: 'vmware-virtual-machine',
    kPhysicalFiles: 'vmware-compute-resource',
    kIsilon: 'icn-volume',
    kKVM: 'vmware-virtual-machine',
    kGCP: 'gcp-iamuser',
    kAWS: 'aws-iamuser',
    kHyperVVSS: 'vmware-virtual-machine',
    kOracle: 'icn-db',
    kFlashBlade: 'icn-volume',
    kO365: 'icn-mailbox',
    kHyperFlex: 'vmware-vcenter',
    kKubernetes: 'kubernetes-namespace',
    kNimble: 'icn-volume',
  });

  // This enum helps to get class icon type
  constModule.constant('ENUM_ICON_TYPE_MAPPING', {
    job: {
      1: 'vm',
      2: 'vm',
      3: 'sql',
      4: 'view',
      5: 'remote-adapter',
      6: 'physical',
      7: 'pure',
      8: 'vm',
      9: 'nas',
      11: 'nas',
      12: 'vm',
      13: 'physical',
      14: 'nas',
      15: 'vm',
      16: 'vm',
      17: 'exchange',
      18: 'vm',
      19: 'oracle',
      20: 'vm',
      21: 'nas',
      22: 'vm',
      24: 'office365',
      25: 'office365',
      27: 'vm',
      28: 'vm',
      29: 'active-directory',
      30: 'vm',
      31: 'nas',
      32: 'rds',
      34: 'kubernetes',
      36: 'vm',
      35: 'pure',
      37: 'nas',
      44: 'rds',
      kAD: 'active-directory',
      kActiveDirectory: 'active-directory',
      kAcropolis: 'vm',
      kAuroraSnapshotManager: 'rds',
      kAzure: 'vm',
      kAzureNative: 'vm',
      kAzureSnapshotManager: 'vm',
      kAWS: 'vm',
      kAWSNative: 'vm',
      kAWSSnapshotManager: 'vm',
      kElastifile: 'nas',
      kExchange: 'exchange',
      kFlashBlade: 'nas',
      kGCP: 'vm',
      kGCPNative: 'vm',
      kGenericNas: 'nas',
      kGPFS: 'nas',
      kHyperV: 'vm',
      kHyperVVSS: 'vm',
      kIsilon: 'nas',
      kKubernetes: 'kubernetes',
      kKVM: 'vm',
      kNetapp: 'nas',
      kNimble: 'pure',
      kO365: 'office365',
      kO365Outlook: 'office365',
      kOracle: 'oracle',
      kPhysical: 'physical',
      kPhysicalFiles: 'physical',
      kPuppeteer: 'remote-adapter',
      kPure: 'pure',
      kRDSSnapshotManager: 'rds',
      kSQL: 'sql',
      kView: 'view',
      kVMware: 'vm',
    },
    recover: {
      // kRecoverVMs
      1: 'vm',

      // kCloneVms
      2: 'vm',

      // kRestoreFiles
      3: 'file',

      // kRecoverApp,
      4: 'sql',

      // kCloneViews
      5: 'view',

      // kMountVolumes
      6: 'mount',

      // kCloneApp
      7: 'sql',

      // kRecoverSanVolume
      8: 'pure',

      // kConvertAndDeployVMs
      9: 'vm',

      // kMountFileVolume
      10: 'nas',

      // kSystem
      11: 'physical',

      // kRecoverVolumes
      12: 'physical',

      // kCloudDeploy
      13: 'vm',

      // kDownloadFiles
      14: 'file',

      // kRecoverEmails
      15: 'office365',

      // kRecoverDisks
      16: 'vm',

      // kKubernetes
      19: 'kubernetes',

      // kRecoverO365Drive
      20: 'office365',
    },

    // Role-Based Access Control
    rbac: {
      user: 'user',
      group: 'group',
    }
  });

  // This enum helps to get display string for recover task
  constModule.constant('ENUM_RECOVER_TYPE', {
    1: 'vmware',

    // kCloneVms
    2: 'vmware',

    // kRestoreFiles
    3: 'file',

    // kRecoverApp,
    4: 'msSql',

    // kCloneViews
    5: 'view',

    // kMountVolumes
    6: 'mountVolume',

    // kCloneApp
    7: 'msSql',

    // kRecoverSanVolume
    8: 'pureStorageVolume',

    // kConvertAndDeployVMs
    9: 'vmware',

    // kMountFileVolume
    10: 'nas',

    // kSystem
    11: 'physical',

    // kRecoverVolumes
    12: 'Physical',

    // kDownloadFiles
    14: 'file',

    // kRecoverDisks
    16: 'vmware',

    // kRecoverApp
    19: 'oracle',
    kCloneVms: 'vmware',
    kRestoreFiles: 'file',
    kDownloadFiles: 'file',
    kRecoverApp: 'msSql',
    kCloneViews: 'view',
    kMountVolumes: 'mountVolume',
    kCloneApp: 'msSql',
    kRecoverSanVolume: 'pureStorageVolume',
    kConvertAndDeployVMs: 'vmware',
    kMountFileVolume: 'nas',
    kSystem: 'physical',
    kRecoverVolumes: 'physical',
    kRecoverOracle: 'oracle',
    kRecoverDisks: 'vmware',
  });

  // backup job priority
  constModule.constant('ENUM_PRIORITY', {
    0: 'Low',
    1: 'Medium',
    2: 'High',
  });

  /**
   * Enum representing which set of SQL DBs to protect. Defined in
   * magneto/base/magneto.proto
   *
   * Default: kBackupAllDatabases
   */
  constModule.constant('USER_DATABASE_BACKUP_PREFERENCE_TYPE', {
    kBackupAllDatabases: 'backupAllDatabases',
    kBackupAllExceptAAGDatabases: 'skipAagDatabases',
    kBackupOnlyAAGDatabases: 'backupOnlyAagDatabases',
  });

  /**
   * Enum representing which set of SQL DBs to protect. Defined in
   * magneto/base/magneto.proto. Used only in case where private apis are used.
   * This block needs to be removed when the job details page start using public
   * apis.
   *
   * Default: 0
   */
  constModule.constant('USER_DATABASE_BACKUP_PREFERENCE_TYPE_PRIVATE', {
    0: 'backupAllDatabases',
    1: 'skipAagDatabases',
    2: 'backupOnlyAagDatabases',
  });

  /**
   * Enum representing the User's preference for selecting AAG volumes to
   * protect in a SQL PJ. Defined in magneto/base/entities/sql.proto
   *
   * Default: kPrimaryReplicaOnly
   */
  constModule.constant('AAG_BACKUP_PREFERENCE_TYPE', {
    kPrimaryReplicaOnly: 'primaryOnly',
    kSecondaryReplicaOnly: 'secondaryOnly',
    kPreferSecondaryReplica: 'preferredSecondary',
    kAnyReplica: 'any',
  });

  /**
   * Enum representing the User's preference for selecting AAG volumes to
   * protect in a SQL PJ. Defined in magneto/base/entities/sql.proto. Used only
   * in case where private apis are used. This block needs to be removed when
   * the job details page start using public apis.
   *
   * Default: 0
   */
  constModule.constant('AAG_BACKUP_PREFERENCE_TYPE_PRIVATE', {
    0: 'primaryOnly',
    1: 'secondaryOnly',
    2: 'preferredSecondary',
    3: 'any',
  });

  // Array of backupTypes for run now modal
  constModule.constant('JOB_BACKUP_TYPE', [
      {
        name: 'enum.jobRunType.kRegular',
        enum: 'kRegular',
      },
      {
        name: 'enum.jobRunType.kFull',
        enum: 'kFull',
      },
      {
        name: 'enum.jobRunType.kLog',
        enum: 'kLog',
      },
  ]);

  /**
   * Array of Oracle Cluster Types Supported.
   */
  constModule.constant('ORACLE_CLUSTER_TYPE', [
    3, // Oracle RAC Cluster
    4, // Oracle Active Passive Cluster
    6, // Oracle Cluster
  ]);

  /**
   * Mapping for Protection Source Objects Schedule Types
   */
  constModule.constant('SOURCE_OBJECT_SCHEDULE_TYPE', {
    kRegular: 'enum.vm.jobRunType.kRegular',
    kFull: 'enum.vm.jobRunType.kFull',
    kLog: 'enum.vm.jobRunType.kLog',
  });

  constModule.constant('ENUM_GRANULARITY', {
    kDay: 'enum.granularity.singular.kDay',
    kEvery: 'enum.granularity.singular.kEvery',
    kHour: 'enum.granularity.singular.kHour',
    kMinute: 'enum.granularity.singular.kMinute',
    kMonth: 'enum.granularity.singular.kMonth',
    kWeek: 'enum.granularity.singular.kWeek',
    kYear: 'enum.granularity.singular.kYear',
  });

  constModule.constant('ENUM_GRANULARITY_PLURAL', {
    kDay: 'enum.granularity.plural.kDay',
    kEvery: 'enum.granularity.plural.kEvery',
    kHour: 'enum.granularity.plural.kHour',
    kMinute: 'enum.granularity.plural.kMinute',
    kMonth: 'enum.granularity.plural.kMonth',
    kWeek: 'enum.granularity.plural.kWeek',
    kYear: 'enum.granularity.plural.kYear',
  });

  // ScheduledBackupType (magneto/base/enums.proto)
  constModule.constant('ENUM_JOB_RUN_TYPE', {
    // kRegular, backup incrementally. If no previous full snapshot is available
    // (for example, the first run of an incremental only schedule) then this
    // will backup all data making it effectively a kFull run
    0: 'Incremental',

    // kFull, backup all data
    1: 'Full',

    // kLog, SQL related schedule
    2: 'Log',

    // kSystem, Bare Metal Restore capable run
    3: 'BMR',
  });

  constModule.constant('ENUM_ALERT_POLICY', {
    1: ['kSuccess'],
    2: ['kFailure'],
    3: ['kSuccess', 'kFailure'],
    4: ['kSlaViolation'],
    5: ['kSuccess', 'kSlaViolation'],
    6: ['kFailure', 'kSlaViolation'],
    7: ['kSuccess', 'kFailure', 'kSlaViolation'],
  });

  constModule.constant('ENUM_ALERT_SEVERITY', {
    "kCritical": "enum.alertSeverity.kCritical",
    "kInfo": "enum.alertSeverity.kInfo",
    "kSeverityAll": "enum.alertSeverity.kSeverityAll",
    "kWarning": "enum.alertSeverity.kWarning",
  });

  constModule.constant('ENUM_BACKUP_JOB_STATUS', {
    0: 'Ready to Schedule',

    // API calls it Admitted, we're calling it Running
    1: 'Running',
    2: 'Finished',

    // the following won't be returned from the API, we are determining them
    // using JobService.getStatus
    2.1: 'Success',
    2.2: 'Error',
    2.3: 'Warning',
    3: 'Canceled',
    3.1: 'Canceling',
    5: 'Accepted',
  });

  constModule.constant('BACKUP_JOB_RUN_ORIGINATOR_TYPE', {
    1: 'kLocal',
    2: 'kRemote',
    3: 'kArchival',
    kLocal: 1,
    kRemote: 2,
    kArchival: 3,
  });

  constModule.constant('ENUM_BACKUP_JOB_STATUS_LABEL_CLASSNAME', {
    0: 'status-ok',

    // Running Job is displayed in default text color
    1: '',
    2: 'status-ok',
    2.1: 'status-ok',
    2.2: 'status-critical',
    2.3: 'status-warning',
    3: 'status-warning',
    3.1: '',
    5: '',
  });

  constModule.constant('ENUM_BACKUP_LOCAL_JOB_STATUS_ICON_NAME', {
    0: 'status-local-running',
    1: 'status-local-running',
    2: 'status-local-success',
    2.1: 'status-local-success',
    2.2: 'status-local-error',
    2.3: 'status-local-warning',
    3: 'status-local-canceled',
    3.1: 'status-local-canceled',
    5: 'status-local-running',
  });

  constModule.constant('ENUM_BACKUP_SNAPSHOT_MANAGER_JOB_STATUS_ICON_NAME', {
    0: 'status-csm-running',
    1: 'status-csm-running',
    2: 'status-csm-success',
    2.1: 'status-csm-success',
    2.2: 'status-csm-error',
    2.3: 'status-csm-warning',
    3: 'status-csm-canceled',
    3.1: 'status-csm-canceled',
    5: 'status-csm-running',
  });

  // No label class for 0 (running state) in pulse progress bar
  constModule.constant('ENUM_INDEXING_TASK_LABEL_CLASSNAME', {
    0: '',
    1: 'status-ok',
    2: 'status-critical',
    4: 'status-warning'
  });

  // No icon for 0 (running state) in pulse progress bar
  constModule.constant('ENUM_INDEXING_TASK_STATUS_ICON_NAME', {
    0: '',
    1: 'status-indexing-success',
    2: 'status-indexing-error',
    4: 'status-indexing-canceled',
  });

  constModule.constant('ENUM_BACKUP_JOB_FIELD', {
    KBackupJobName: 'Name',
    KBackupJobDeleted: 'Deleted',
    KBackupJobDescription: 'Description',
    KBackupJobSchedule: 'Schedule',
    KBackupJobSources: 'Sources',
    KBackupJobRetrySettings: 'Retry Settings',
    KBackupJobRetentionPolicy: 'Retention Policy',
    KBackupJobIndexingPolicy: 'Indexing Policy',
    KBackupJobAlertingPolicy: 'Alerting Policy',
    KBackupJobPriority: 'Priority',
    KBackupJobSla: 'SLA',
    KBackupJobFullSchedule: 'Full Backup Schedule',
    KBackupJobQuiesce: 'Application Aware Backup',
    KBackupJobPolicyId: 'Policy',
    KBackupJobTimezone: 'Timezone',
    KSnapshotTargetPolicy: 'Archival/Replication Target',
    KBackupJobFutureRunsPaused: 'Future Runs Paused',
    KBackupJobFutureRunsResumed: 'Future Runs Resumed',
    KBackupJobBlackoutWindow: 'Blackout Period',
    KBackupJobQOS: 'QoS Policy',
    KBackupJobInvalidField: 'Invalid Field',
  });

  // From magneto/base/magneto.proto
  constModule.constant('ENUM_RESTORE_TYPE', {
    1: 'kRecoverVMs',
    2: 'kCloneVMs',
    3: 'kRestoreFiles',
    4: 'kRecoverApp',
    5: 'kCloneView',
    6: 'kMountVolumes',
    7: 'kCloneApp',
    8: 'kRecoverSanVolume',
    9: 'kConvertAndDeployVMs',
    10: 'kMountFileVolume',
    11: 'kSystem',
    12: 'kRecoverVolumes',
    13: 'kDeployVMs',
    14: 'kDownloadFiles',
    15:'kRecoverEmails',
    16: 'kRecoverDisks',
    18: 'kCloneAppView',
    19: 'kRecoverNamespaces',
    20: 'kRecoverO365Drive',
    23: 'kRecoverSites',
    25: 'kConvertToPst',
    kRecoverVMs: 1,
    kCloneVMs: 2,
    kRestoreFiles: 3,
    kRecoverApp: 4,
    kCloneView: 5,
    kMountVolumes: 6,
    kCloneApp: 7,
    kRecoverSanVolume: 8,
    kConvertAndDeployVMs: 9,
    kMountFileVolume: 10,
    kSystem: 11,
    kRecoverVolumes: 12,
    kDeployVMs: 13,
    kDownloadFiles: 14,
    kRecoverEmails: 15,
    kRecoverDisks: 16,
    kCloneAppView: 18,
    kRecoverNamespaces: 19,
    kRecoverO365Drive: 20,
    kRecoverSites: 23,
    kConvertToPst: 25,
  });

  constModule.constant('ENUM_RESTORE_TYPE_DISPLAY', {
    kSystem: 'enum.restoretype.bareMetalRecovery',
    kRecoverVolumes: 'enum.restoretype.volume',
  });

  constModule.constant('ENUM_RESTORE_TASK_STATUS', {
    // kReadyToSchedule
    0: 'Ready to Schedule',
    kReadyToSchedule: 0,

    // kAdmitted
    1: 'Admitted',
    kAdmitted: 1,

    // kInProgress
    2: 'Running',
    kInProgress: 2,

    // kFinished
    3: 'Finished',
    kFinished: 3,

    // kProgressMonitorCreated
    4: 'Ready to Schedule',
    kProgressMonitorCreated: 4,

    // kFinishingProgressMonitor
    5: 'Running',
    kFinishingProgressMonitor: 5,

    // kRetrievedFromArchive
    6: 'Retrieved',
    kRetrievedFromArchive: 6,

    // kCancelled
    7: 'Canceled',
    kCancelled: 7,
  });

  constModule.constant('RESTORE_ENTITY_STATUS', {
    // kFilesCloned
    0: 'Running',

    // kFetchedEntityInfo
    1: 'Running',

    // kVMCreated
    2: 'Running',

    // kRelocationStarted
    3: 'Running',

    // kFinished
    4: 'Finished',

    // kAborted
    5: 'Error',

    // kDataCopyStarted
    6: 'Running',

    // kInProgress
    7: 'Running',

    // kCancelled
    8: 'Canceled',

    kError: [5],

    kFinished: [4],

    kRunning: [0, 1, 2, 3, 6, 7],

    kCancelled: [8],
  });

  // Public Status
  // TODO: To use this status throughout the application.
  // This status will eventually be applicable to the whole application
  constModule.constant('ENUM_PUBLIC_STATUS', {
    kAccepted: 'enum.status.accepted',
    kRunning: 'enum.status.running',
    kCanceling: 'enum.status.canceling',
    kCanceled: 'enum.status.canceled',
    kCancelled: 'enum.status.canceled',
    kSuccess: 'enum.status.success',
    kWarning: 'enum.status.warning',
    kFailure: 'enum.status.error',
    kError: 'enum.status.error',
  });


  constModule.constant('ENUM_PUBLIC_STATUS_LABEL_CLASSNAME', {
    // kAccepted: '',
    // kRunning: '',
    // kCanceling: '',
    kCanceled: 'status-warning',
    kCancelled: 'status-warning',
    kSuccess: 'status-ok',
    kWarning: 'status-warning',
    kFailure: 'status-critical',
    kError: 'status-critical',
  });

  constModule.constant('ENUM_PUBLIC_STATUS_ICON_CLASS', {
    // kAccepted: '',
    // kRunning: '',
    // kCanceling: '',
    kCanceled: 'status-general-canceled',
    kSuccess: 'status-general-success',
    kWarning: 'status-general-warning',
    kFailure: 'status-general-error',
  });

  constModule.constant('ENUM_BACKUP_PUBLIC_TASK_STATUS_ICON_NAME', {
    kCanceled: 'status-local-canceled',
    kError: 'status-local-error',
    kFailure: 'status-local-error',
    kRunning: 'status-local-running',
    kSuccess: 'status-local-success',
    kWarning: 'status-local-warning',
  });

  constModule.constant('ENUM_COPY_TASK_STATUS', {
    // kStarted
    0: 'Queued',

    // kAssigned
    1: 'Running',

    // kAccepted
    2: 'Running',
    3: 'Finished',
    4: 'Canceled',

    // kScheduled
    5: 'Queued',

    // kFinishing
    6: 'Running',
  });

  constModule.constant('ENUM_COPY_SUBTASK_STATUS', {
    0: 'enum.copySubTaskStatus.started',
    1: 'enum.copySubTaskStatus.scheduled',
    2: 'enum.copySubTaskStatus.assigned',
    3: 'enum.copySubTaskStatus.running',
    4: 'enum.copySubTaskStatus.finished',
    5: 'enum.copySubTaskStatus.canceled',
  });

  // Defined in magneto/master/base/enums.proto
  constModule.constant('ENUM_COPY_TASK_TYPE', {
    0: 'kRegular',
    1: 'kRetry',
    2: 'kRecopy',
  });

  // File data types (used in file search)
  constModule.constant('ENUM_FILE_DATA_TYPE', {
    0: 'kFile',
    1: 'kDirectory',
    2: 'kSymlink',
    3: 'kEmail',
  });

  constModule.constant('ENUM_RESTORE_FILE_STATUS', {
    // kNotStarted
    0: 'Not Started',

    // kCopyInProgress
    1: 'Running',

    // kFinished
    2: 'Finished',

    // kEstimationDone
    3: 'Not Started',
  });

  constModule.constant('ENUM_RETRIEVAL_TASK_STATUS', {
    0: 'Started',
    1: 'Scheduled',
    2: 'Finished',
    3: 'Canceled',
  });

  constModule.constant('ENUM_PULSE_PROGRESS_TASK_STATUS', {
    0: 'enum.pulseStatus.kRunning',
    1: 'enum.pulseStatus.kSuccess',
    2: 'enum.pulseStatus.kError',
    3: 'enum.pulseStatus.kGarbageCollected',
    4: 'enum.pulseStatus.kCanceled',
  });

  // The first two map directly to ENUM_RESTORE_TASK_STATUS and then
  // ENUM_RESTORE_TASK_STATUS[3] will either be success or error depending on
  // the value of the error object. Destroyed status values are also derived.
  // All of this happens privately in RestoreService's getStatus().
  constModule.constant('ENUM_UI_RESTORE_TASK_STATUS', {
    0: 'Scheduled',

    // 'Admitted' displayed as running
    1: 'Running',

    // 'Migrating' displayed as running
    2: 'Running',
    3: 'Success',
    4: 'Error',
    5: 'Destroy Scheduled',
    6: 'Destroying',
    7: 'Destroyed',
    8: 'Destroy Error',
    9: 'Canceled',
    10: 'Refreshing',
    11: 'Refresh Error',
  });

  constModule.constant('ENUM_UI_RESTORE_TASK_STATUS_LABEL_CLASSNAME', {
    0: '',
    1: 'status-warning',
    2: 'status-warning',
    3: 'status-ok',
    4: 'status-critical',
    5: '',
    6: 'status-warning',
    7: 'status-ok',
    8: 'status-critical',
    9: 'status-warning',
    10: 'status-warning',
    11: 'status-critical',
  });

  // derived status for a destroyed clone object. referenced only in
  // RestoreService.
  constModule.constant('ENUM_UI_CLONE_OBJECT_STATUS', {
    cloneScheduled: 'Clone Scheduled',
    cloneInProgress: 'Clone In Progress',
    cloneSuccess: 'Clone Success',
    destroyError: 'Destroy Error',
    destroySuccess: 'Destroy Successful',
    destroyRunning: 'Destroy Running',
  });

  constModule.constant('ENUM_UI_CLONE_OBJECT_STATUS_CLASSNAME', {
    cloneScheduled: '',
    cloneInProgress: 'status-warning',
    cloneSuccess: 'status-ok',
    destroyError: 'status-critical',
    destroySuccess: 'status-ok',
    destroyRunning: 'status-warning',
  });

  constModule.constant('ENUM_RESTORE_TYPE', {
    1: 'VM Recovery',
    2: 'Clone',
    3: 'File Recovery',
    5: 'Clone Azure',
  });

  // Requires momentjs to be loaded first.
  constModule.constant('TIMEZONES', moment.tz.names());

  constModule.constant('DAY_PERIODICITY', [
    'kSunday', 'kMonday', 'kTuesday', 'kWednesday', 'kThursday', 'kFriday',
    'kSaturday',
  ]);

  // TODO: Remove old number enums once orphaned.
  constModule.constant('ENUM_DAY', {
    kSunday: 'Sunday',
    kMonday: 'Monday',
    kTuesday: 'Tuesday',
    kWednesday: 'Wednesday',
    kThursday: 'Thursday',
    kFriday: 'Friday',
    kSaturday: 'Saturday',
    0: 'Sunday',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
  });

  // translates values provided by Date.prototype.getDay() into kValue
  constModule.constant('DAY_KVAL', {
    0: 'kSunday',
    1: 'kMonday',
    2: 'kTuesday',
    3: 'kWednesday',
    4: 'kThursday',
    5: 'kFriday',
    6: 'kSaturday',
  });

  // Get the position of day in a week.
  constModule.constant('DAY_POS', {
    kSunday: 0,
    kMonday: 1,
    kTuesday: 2,
    kWednesday: 3,
    kThursday: 4,
    kFriday: 5,
    kSaturday: 6,
  });

  constModule.constant('ENUM_DAY_SHORT', {
    0: 'Sun',
    1: 'Mon',
    2: 'Tue',
    3: 'Wed',
    4: 'Thu',
    5: 'Fri',
    6: 'Sat',
  });

  constModule.constant('ENUM_DAY_TINY', {
    kSunday: 'enum.dayTiny.kSunday',
    kMonday: 'enum.dayTiny.kMonday',
    kTuesday: 'enum.dayTiny.kTuesday',
    kWednesday: 'enum.dayTiny.kWednesday',
    kThursday: 'enum.dayTiny.kThursday',
    kFriday: 'enum.dayTiny.kFriday',
    kSaturday: 'enum.dayTiny.kSaturday',
  });

  constModule.constant('ENUM_MONTH', {
    0: 'January',
    1: 'February',
    2: 'March',
    3: 'April',
    4: 'May',
    5: 'June',
    6: 'July',
    7: 'August',
    8: 'September',
    9: 'October',
    10: 'November',
    11: 'December',
  });

  constModule.constant('ENUM_MONTH_SHORT', {
    0: 'Jan',
    1: 'Feb',
    2: 'Mar',
    3: 'Apr',
    4: 'May',
    5: 'June',
    6: 'July',
    7: 'Aug',
    8: 'Sep',
    9: 'Oct',
    10: 'Nov',
    11: 'Dec',
  });

  constModule.constant('ENUM_DAY_COUNT_IN_MONTH', {
    1: 'First',
    2: 'Second',
    3: 'Third',
    4: 'Fourth',
    5: 'Last',
    kFirst: 'enum.dayCountInMonth.first',
    kSecond: 'enum.dayCountInMonth.second',
    kThird: 'enum.dayCountInMonth.third',
    kFourth: 'enum.dayCountInMonth.fourth',
    kLast: 'enum.dayCountInMonth.last',
  });

  constModule.constant('NODE_REMOVAL_STATE_CLASS', {
    kDontRemove: 'status-ok',
    DontRemove: 'status-ok',
    kMarkedForRemoval: 'status-warning',
    MarkedForRemoval: 'status-warning',
    kOkToRemove: 'status-critical',
    OkToRemove: 'status-critical',
  });

  constModule.constant('NODE_REMOVAL_STATE_LABEL', {
    kDontRemove: 'Active',
    DontRemove: 'Active',
    kMarkedForRemoval: 'Removal in Progress',
    MarkedForRemoval: 'Removal in Progress',
    kOkToRemove: 'Ready to Remove Node',
    OkToRemove: 'Ready to Remove Node',
  });

  constModule.constant('ENUM_ALERTS_STATUS_LABEL_CLASSNAME', {
    kNote: 'status-ok',
    kOpen: 'status-critical',
    kSuppressed: 'status-ok',
    kResolved: 'status-ok',
    kCritical: 'status-critical',
    kWarning: 'status-warning',
    kInfo: 'status-ok',
  });

  constModule.constant('SOURCE_OBJECT_STATUS_LABEL_CLASSNAME', {
    kSuccess: 'status-ok',
    kError: 'status-critical',
    kCancelled: 'status-warning',
    kWarning: 'status-warning',
  });

  constModule.constant('SHARED_CLASSNAME', {
    //createJobButton and createRecoverButton components uses this class
    contextBtn: 'context-btn',
  });

  constModule.constant('ENUM_SNAPSHOT_STATUS', {
    kSuccess: 'enum.snapshotStatus.kSuccess',
    kError: 'enum.snapshotStatus.kError',
    kCancelled: 'enum.snapshotStatus.kCancelled',
    kRunning: 'enum.snapshotStatus.kRunning',
    kWarning: 'enum.snapshotStatus.kWarning',
  });

  constModule.constant('PHYSICAL_SERVER_STATUS', {
    kUnknown: 'unknown',
    kUnreachable:'unreachable',
    kHealthy: 'healthy',
    kUnHealthy: 'unhealthy',
    kDegraded: 'degraded',
  });

  constModule.constant('PHYSICAL_SERVER_STATUS_CLASSNAME', {
    kUnknown: 'status-warning',
    kUnreachable:'status-critical',
    kHealthy: 'status-ok',
    kDegraded: 'status-warning',
  });

  constModule.constant('SNAPSHOT_TARGET_TYPE', {
    unknown: 0,
    kLocal: 1,
    kRemote: 2,
    kArchival: 3,
    3: 'kArchival',
    2: 'kRemote',
    1: 'kLocal',
  });

  // Based on `ObjectSnapshotType` in magneto/base/magneto.proto
  constModule.constant('OBJECT_SNAPSHOT_TYPE', {
    0: 'enum.snapshotType.kUnknown',
    1: 'enum.snapshotType.kCrashConsistent',
    2: 'enum.snapshotType.kAppConsistent',
    3: 'enum.snapshotType.kNotPoweredOn',
  });

  constModule.constant('SNAPSHOT_TARGET_TYPE_STRINGS', {
    0: 'Unknown',
    1: 'Local',
    2: 'Replication',

    // kArchival
    3: {
      // sub hashed by target.archivalTarget.type
      0: 'Cloud Archive',
      1: 'Tape Archive',
      2: 'Cloud Archive',
    },

    // CSM
    5: 'CSM Replication',
  });

  /**
   * SNMP Enums & Constants
   *************************************************************/
  constModule.constant('ENUM_SNMP_OPERATION_TYPE', {
    kOperationEnable: 1,
    kOperationDisable: 2,
  });

  constModule.constant('ENUM_SNMP_VERSION', {
    kSnmpV2: 2,
    kSnmpV3: 3,
  });

  constModule.constant('ENUM_SNMP_USER_TYPE', {
    kReadUser: 1,
    kReadWriteUser: 2,
    kTrapUser: 3,
  });
  /** END SNMP Enums *******************************************/

  constModule.constant('ARCHIVE_EXTERNAL_TARGETS', {
    aws: {
      name: 'AWS',
      vaults : {
        standard: 'kAmazon',
        gov: 'kS3Gov',
        c2s: 'kAmazonC2S',
      },
      types: [
        {
          name: 'S3',
          tier: 'kAmazonS3Standard',
          hasC2S: true,
          hasGov: true,
          oldKey: 'kS3',
          oldGovKey: 'kAWSGovCloud',
          cloudSpillSupported: true,
        }, {
          name: 'S3-IA',
          hasC2S: true,
          hasGov: true,
          tier: 'kAmazonS3StandardIA',
          oldKey: 'kAmazonS3StandardIA',
        }, {
          name: 'Glacier (Legacy)',
          hasC2S: true,
          hasGov: true,
          tier: 'kAmazonGlacier',
          oldKey: 'kGlacier',
        }, {
          name: 'Snowball Edge',
          cloudSpillSupported: true
        },
        // Note: The following targets are currently added based on feature_flag
        // in function `getTargetTypeOptions` in /platform/external/targets.js.
        // Eventually, the flag will be removed and these options should be
        // enabled outright.
        //
        // {
        //   name: 'S3-One-Zone',
        //   tier: 'kAmazonS3OneZoneIA',
        //   hasGov: true,
        //   cloudSpillSupported: true,
        // }, {
        //   name: 'S3-Intelligent',
        //   tier: 'kAmazonS3IntelligentTiering',
        //   hasGov: true,
        //   cloudSpillSupported: true,
        // },
      ],
    },
    azure: {
      name: 'Azure',
      vaults : {
        standard: 'kAzure',
        gov: 'kAzureGovCloud',
      },
      types: [{
        name: 'Archive Blob',
        tier: 'kAzureTierArchive',
        oldKey: 'kAzureArchive',
        hasGov: true
      }, {
        name: 'Cool Blob',
        tier: 'kAzureTierCool',
        hasGov: true,
      }, {
        name: 'Hot Blob',
        tier: 'kAzureTierHot',
        hasGov: true,
        oldKey: 'kAzureStandard',
        oldGovKey: 'kAzureGovCloud',
        cloudSpillSupported: true,
      }],
    },
    google: {
      name: 'GCP',
      vaults: {
        standard: 'kGoogle',
      },
      types: [{
        name: 'Standard',
        tier: 'kGoogleStandard',
        oldKey: 'kGoogleStandard',
        cloudSpillSupported: true,
      }, {
        name: 'Coldline',
        tier: 'kGoogleColdline',
        oldKey: 'kColdline',
      }, {
        name: 'Nearline',
        tier: 'kGoogleNearline',
        oldKey: 'kNearline',
      }],
    },
    oracle: {
      name: 'Oracle',
      vaults : {
        standard: 'kOracle',
      },
      types: [{
        name: 'Object Storage',
        tier: 'kOracleTierStandard',
        oldKey: 'kOracleTierStandard',
        cloudSpillSupported: true,
      }, {
        name: 'Archive Storage',
        tier: 'kOracleTierArchive',
        oldKey: 'kOracleTierStandard',
      }],
    },
    other: {
      types: [{
        name: 'NAS',
        vault: 'kNAS',
        oldKey: 'kNAS',
      }, {
        name: 'QStar Tape',
        vault: 'kQStarTape',
        oldKey: 'kQStarTape',
      }, {
        name: 'S3 Compatible',
        vault: 'kS3Compatible',
        oldKey: 'kS3Compatible',
        cloudSpillSupported: true,
      }],
    },
  });

  constModule.constant('EXTERNAL_TARGET_COMPRESSION_SETTINGS', {
    kCompressionNone: 'Disabled',
    kCompressionLow: 'Enabled',
    kCompressionHigh: 'Enabled',
  });

  constModule.constant('EXTERNAL_TARGET_ENCRYPTION_SETTINGS', {
    kEncryptionNone: 'Disabled',
    kEncryptionWeak: 'Enabled',
    kEncryptionStrong: 'Enabled',
  });

  constModule.constant('EXTERNAL_TARGET_USAGE_TYPES', {
    kCloudSpill: 'kCloudSpill',
    kArchival: 'kArchival',
  });
  constModule.constant('EXTERNAL_TARGET_USAGE_TYPE_STRINGS', {
    kCloudSpill: 'Cloud Tier',
    kArchival: 'Archival',
  });

  // Deprecate this after DB_RECOVER_TYPE is working
  constModule.constant('SQL_RECOVER_TYPE', {
    recover: 'kRecoverVMs',
    clone: 'kCloneVMs',
  });

  constModule.constant('DB_RECOVER_TYPE', {
    recover: 'kRecoverVMs',
    clone: 'kCloneVMs',
  });

  // Deprecate this after DB_RECOVER_APP_TYPE is working
  constModule.constant('SQL_RECOVER_APP_TYPE', {
    recover: 'kRecoverApp',
    clone: 'kCloneApp',
  });

  constModule.constant('DB_RECOVER_APP_TYPE', {
    recover: 'kRecoverApp',
    clone: 'kCloneApp',
    exposeView: 'kCloneAppView',
  });

  // Ref main/magneto/base/enums.proto: VerificationStatus
  constModule.constant('REGISTRATION_VERIFICATION_STATUS', {
    0: 'kPending',
    1: 'kScheduled',
    2: 'kFinished',
    3: 'kRefreshInProgress',
    kFinished: 2,
    kPending: 0,
    kRefreshInProgress: 3,
    kScheduled: 1,
  });

  // Ref: iris/base/enums.proto: IrisVaultJobStatus
  constModule.constant('IRIS_VAULT_JOB_STATUS', {
    kJobCanceled: 'iceboxJobStatus.kJobCanceled',
    kJobFailed: 'iceboxJobStatus.kJobFailed',
    kJobPaused: 'iceboxJobStatus.kJobPaused',
    kJobRunning: 'iceboxJobStatus.kJobRunning',
    kJobSucceeded: 'iceboxJobStatus.kJobSucceeded',
  });

  // Target location for recovery
  constModule.constant('TARGET_LOCATION', {
    cohesityView: 'cohesityView',
    original: 'original',
    alternate: 'alternate',
  });

  /**
   * List of Well-Known Principals which need special handling.
   *
   * Reference:
   * https://docs.microsoft.com/en-us/windows/desktop/secauthz/well-known-sids
   */
  constModule.constant('WELL_KNOWN_PRINCIPALS', [
    {
      // 'Everyone'
      fullName: 'Everyone',
      objectClass: 'kGroup',
      sid: 'S-1-1-0',
      builtIn: true,
    },
    {
      // 'Administrators'
      fullName: 'Administrators',
      objectClass: 'kGroup',
      sid: 'S-1-5-32-544',
      builtIn: true,
    },
    {
      // 'Users'
      fullName: 'Users',
      objectClass: 'kGroup',
      sid: 'S-1-5-32-545',
      builtIn: true,
    },
    {
      // 'Guests'
      fullName: 'Guests',
      objectClass: 'kGroup',
      sid: 'S-1-5-32-546',
      builtIn: true,
    },
    {
      // 'Power Users'
      fullName: 'Power Users',
      objectClass: 'kGroup',
      sid: 'S-1-5-32-547',
      builtIn: true,
    },
    {
      // 'Account Operators'
      fullName: 'Account Operators',
      objectClass: 'kGroup',
      sid: 'S-1-5-32-548',
    },
    {
      // 'Server Operators'
      fullName: 'Server Operators',
      objectClass: 'kGroup',
      sid: 'S-1-5-32-549',
    },
    {
      // 'Print Operators'
      fullName: 'Print Operators',
      objectClass: 'kGroup',
      sid: 'S-1-5-32-550',
    },
    {
      // 'Backup Operators'
      fullName: 'Backup Operators',
      objectClass: 'kGroup',
      sid: 'S-1-5-32-551',
      builtIn: true,
    },
    {
      // 'Replicators'
      fullName: 'Replicators',
      objectClass: 'kGroup',
      sid: 'S-1-5-32-552',
    },
    {
      // 'Storage Replica Administrators'
      fullName: 'Storage Replica Administrators',
      objectClass: 'kGroup',
      sid: 'S-1-5-32-582',
    }
  ]);

  // A list of built-in Groups which may not be deleted from the Cluster.
  constModule.constant('BUILT_IN_GROUPS', [
    // Administrators
    'S-1-5-32-544',

    // Users
    'S-1-5-32-545',

    // Guests
    'S-1-5-32-546',

    // Power Users
    'S-1-5-32-547',

    // Backup Operators
    'S-1-5-32-551',

    // Everyone
    'S-1-1-0',
  ]);

  // A list of built-in Users which may not be deleted from the Cluster.
  constModule.constant('BUILT_IN_USERS', ['admin']);

  /**
   * List of kValue-to-nameKey mappings for AD User Mapping types.
   */
  constModule.constant('ENUM_AD_USER_MAPPINGS', {
    'kCentrify': 'ad.mappingType.centrify',
    'kCustomAttributes': 'ad.mappingType.customId',
    'kFixed': 'ad.mappingType.fixed',
    'kLdapProvider': 'ad.mappingType.ldapProvider',
    'kRid': 'ad.mappingType.rid',
    'kRfc2307': 'ad.mappingType.rfc2307',
    'kSfu30': 'ad.mappingType.sfu30',
  });

  // List of different external auth principal types
  constModule.constant('ENUM_AUTH_PRINCIPAL_TYPES', ['ad', 'sso']);

  constModule.constant('ENUM_HEALTH_ENTITY_TYPE_CLASSNAME', {
    // Entity types that belong to cluster category.
    kNode: 'Node',
    kDataSSD: 'DataSSD',
    kDataHDD: 'DataHDD',
    kSystemDisk: "SystemDisk",
    kNetworkInterface: "NetworkInterface",

    // Entity types that belong to internal services category.
    kApollo: 'Garbage Collection',
    kBridge: 'Data Services',
    kMagneto: 'Data Protection Software',
    kScribe: 'Data Store',

    // Entity types related related to external entities.
    kAD : "Active Directory",
    kDNS : "DNS",
  });

  constModule.constant('ENUM_HEALTH_STATUS_CLASSNAME', {
    kError: 'Critical',
    kWarning: 'Warning',
    kSuccess: 'Healthy',
  });

  constModule.constant('ENUM_HEALTH_STATUS_CATEGORY_CLASSNAME', {
    kClusterType: 'CLUSTER HARDWARE',
    kInternalServices: 'CLUSTER SOFTWARE',
    kExternalServices: 'EXTERNAL SERVICES',
  });

  constModule.constant('ENUM_HEALTH_STATUS_ICN_CLASSNAME', {
    kError: 'icn-health-hex-critical',
    kWarning: 'icn-health-hex-warning',
    kSuccess: 'icn-green-tick',
  });

  constModule.constant('NETWORK', {
    defaultInterface: 'bond0',
  });

  constModule.constant('NETWORK_INTERFACE_TYPE', {
    0: 'KIfUnknown',
    1: 'KIfPhysical',
    2: 'KIfBondMaster',
    3: 'KIfBondSlave',
    4: 'KIfVlanTagged',

    KIfUnknown: 0,
    KIfPhysical: 1,
    KIfBondMaster: 2,
    KIfBondSlave: 3,
    KIfVlanTagged: 4,
  });

  //  Defines different states of VM while cloning on cohesity cluster
  constModule.constant('ENUM_KUIPER_VM_STATE', {
    kCreating: 'Creating',
    kCreated: 'Created',
    kRunning: 'Running',
    kStopped: 'Stopped',
    kPaused: 'Paused',
    kRemoving: 'Removing',
    kMigrating: 'Migrating',
    kMigrated: 'Migrated',
    kConverting: 'Converting',
    kConverted: 'Converted',
    kFailed: 'Failed',
  });

  constModule.constant('HELIOS_ENDPOINT', 'https://helios.cohesity.com');

  // MomentJS as an injectable
  constModule.constant('moment', window.moment);

  // Lodash as an injectable
  constModule.constant('_', window._);

  // Highcharts as an injectable
  constModule.constant('Highcharts', window.Highcharts);

  // Now that highcharts is an injectable, remove it from window so typescript
  // imports of Highcharts won't throw errors about Highcharts already being
  // loaded.
  window.Highcharts = undefined;

  // Constant to store how frequently to reload the Jobs page
  constModule.constant('JOBS_RELOAD_INTERVAL_MILLISECONDS', 60000);

  // To be filled with dynamically fetched locale settings by ClusterService
  constModule.constant('LOCALE_SETTINGS', {});

  // Supported Locales.
  constModule.constant('LOCALES', [
    'en-us',
    'ja-jp',
  ]);

  constModule.constant('CONTROLLER_TYPE', {
    0: 'SCSI',
    1: 'IDE',
  });

  // The limit of number of nodes that can be added in specified type of
  // cluster.
  constModule.constant('LIMIT_NODES', {
    virtualEditionCluster: {
      min: 3,
      max: 24,
    },
  });

  // Cluster networking info resource type mapping
  constModule.constant('NETWORKING_INFO_MAP', {
    0: 'kServer',
    1: 'kCluster',
    2: 'kSQL',
    3: 'kOracle',
    4: 'kAD',
    'kAD': 4,
    'kCluster': 1,
    'kOracle': 3,
    'kServer': 0,
    'kSQL': 2,
  });

  // Maps BackgroundIoRate enum values to the percentage they denote.
  constModule.constant('BACKGROUND_IO_RATE_VALUE', {
    'kHigh': 100,
    'kMedium': 66,
    'kLow': 33,
    'kNoActivity': 0,
  });


  // Each chassis has different node arrangements. The position will be provided
  // by backend.
  // The first number represents row; the seconde number represents column.
  // Start from top left (0:0).
  constModule.constant('NODE_POSITION', {
    fourNode: ['0:0', '0:1', '1:0', '1:1'],
  });

  // List of Encryption Types used for AD authentication.
  constModule.constant('AD_ENCRYPTION_TYPES', [
    { id: 1, name: 'DES-CBC-CRC' },
    { id: 2, name: 'DES-CBC-MD5' },
    { id: 4, name: 'RC4-HMAC' },
    { id: 8, name: 'AES128-CTS-HMAC-SHA1-96' },
    { id: 16, name: 'AES256-CTS-HMAC-SHA1-96' },
  ]);

  // List of cluster types used for cluster deployment via helios.
  constModule.constant('CLUSTER_TYPE', {
    kLegacy: 'kLegacy',
    kNextGen: 'kNextGen',
  });

  // List of instance types used for cluster deployment via helios.
  constModule.constant('INSTANCE_TYPE', {
    kNGCE: 'nextGen',
    kPremiumV2: 'PremiumV2_LRS'
  });

  // List of cluster versions that can be used to deploy an NGCE Gcp cluster.
  constModule.constant('CLUSTER_VERSION', {
    gcp: ['7-1-1', '7-2'],
  });


  // List of availability zones used for cluster deployment via helios.
  constModule.constant('AVAILABILITY_ZONE', {
    kZone1: 'Zone 1',
    kZone2: 'Zone 2',
    kZone3: 'Zone 3',
  });
  /**
   * Returns a new object with inverted provided config and used to add kValue
   * to numerial value conversion inverse logic.
   *
   * @method   assignWithInverted
   * @param    {Object}    config   The config to be inverted and assigned.
   * @return   {Object}    The modified config object.
   */
  function assignWithInverted(config) {
    return _.assign(config, _.invert(config));
  }

}(angular));

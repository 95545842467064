import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ProtectionJob, ProtectionSourceNode } from '@cohesity/api/v1';
import { ProtectionPolicyResponse } from '@cohesity/api/v2';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { Environment } from '@cohesity/iris-shared-constants';
import { AutoDestroyable } from '@cohesity/utils';
import { isNil } from 'lodash-es';
import { ObservableInput } from 'ngx-observable-input';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, shareReplay, startWith, switchMap, take, tap } from 'rxjs/operators';
import { DialogService } from 'src/app/core/services';
import { PolicyResponse } from 'src/app/shared/policy';
import { ProtectionSourceDataNode } from 'src/app/shared/source-tree/protection-source';

import { BackupScope, ProtectionGroup, ProtectionRun, RunType } from '../../../models';
import { GroupActionForm } from '../group-action-modal.component';
import { ObjectsSelectDialogComponent } from '../objects-select-dialog/objects-select-dialog.component';

/**
 * @description
 * Group actions form backup target section.
 */
@Component({
  selector: 'coh-group-backup-form',
  templateUrl: './group-backup-form.component.html',
  styleUrls: ['./group-backup-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupBackupFormComponent extends AutoDestroyable implements OnInit {
  /**
   * Protection group instance.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @ObservableInput() @Input('protectionGroup') protectionGroup$: Observable<ProtectionGroup>;

  /**
   * Protection group policy data.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @ObservableInput() @Input('policy') policy$: Observable<PolicyResponse>;

  /**
   * Source tree.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @ObservableInput() @Input('sourceNodes') sourceNodes$: Observable<ProtectionSourceNode[]>;

  /**
   * Protection job.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @ObservableInput() @Input('job') job$: Observable<ProtectionJob>;

  /**
   * Last run information that includes object details.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @ObservableInput() @Input('run') run$: Observable<ProtectionRun>;

  /**
   * All nodes protected by this job.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @ObservableInput() @Input('jobNodes') jobNodes$: Observable<ProtectionSourceDataNode[]>;

  /**
   * Parent group action form group.
   */
  @Input() formGroup: GroupActionForm;

  /**
   * Run types array to populate backup type dropdown.
   */
  runTypes$: Observable<RunType[]>;

  /**
   * Backup scope array to populate object backup type dropdown.
   */
  backupScopes$: Observable<BackupScope[]>;

  /**
   * checking whether form is in edit mode or not.
   */
  formEditMode = false;

  /**
   * BackupScope enum
   */
  readonly backupScope = BackupScope;

  /**
   * selected sourceIds in object selection dialog.
   */
  selectedSourceIds = [];

  constructor(
    private cdr: ChangeDetectorRef,
    private dialogService: DialogService,
    private irisContextService: IrisContextService,
  ) {
    super();
  }

  ngOnInit() {
    this.runTypes$ = combineLatest([
      this.policy$,
      this.protectionGroup$
    ]).pipe(
      map(([policy, protectionGroup]) => {
        const runTypes = [RunType.Full, RunType.Regular];
        if (this.isStorageArraySnapshotSupported(protectionGroup, policy)) {
          runTypes.push(RunType.StorageArraySnapshot);
        }
        return runTypes;
      })
    );

    this.backupScopes$ = this.run$.pipe(
      map(protectionRun => {
        const objectsScopeSelection = [BackupScope.All];

        if (protectionRun?.hasFailedObjects) {
          objectsScopeSelection.push(BackupScope.Failed);
        }

        if (protectionRun?.hasSuccessfulObjects) {
          objectsScopeSelection.push(BackupScope.Succeeded);
        }

        objectsScopeSelection.push(BackupScope.Selected);

        return objectsScopeSelection;
      }),
      shareReplay(1)
    );

    if (!this.formGroup) {
      return;
    }

    const backupScopesChanged$ = this.formGroup.backupFormScope.valueChanges.pipe(
      this.untilDestroy(),
      startWith(this.formGroup.backupFormScope.value)
    );

    const leafNodes$ = this.jobNodes$.pipe(map(nodes => nodes.filter(node => node.isLeaf)));

    // handle when all objects are selected separately
    combineLatest([backupScopesChanged$, leafNodes$, this.jobNodes$]).subscribe(([scope, leafNodes, jobNodes]) => {
      if (scope === BackupScope.All) {
        // leafnodes are required for all adpaters except for pure
        // for pure only root nodes need to selected when protection group has ppg
        this.formGroup.backupFormObjects.setValue(leafNodes.map(node => node.id));
        if ([Environment.kPure, Environment.kIbmFlashSystem].includes(jobNodes?.[0]?.environment)) {
          const levels = new Set(jobNodes.map(node => node.level));
          if (levels.size > 1) {
            this.formGroup.backupFormObjects.setValue(jobNodes.filter(node => node.level === 1)
              .map(node => node.id));
          }
        }
      }
    });

    backupScopesChanged$
      .pipe(
        switchMap((scope: BackupScope) => {
          switch (true) {
            case scope === BackupScope.Selected:
              return this.openObjectSelectionDialog();

            case scope === BackupScope.Succeeded:
              return this.run$.pipe(
                take(1),
                tap(lastRun =>
                  this.formGroup.backupFormObjects.setValue(
                    lastRun.objects
                      .filter(object => ['kSuccessful', 'Succeeded'].includes(object.status))
                      .map(object => object.id)
                  )
                )
              );

            case scope === BackupScope.Failed:
              return this.run$.pipe(
                take(1),
                tap(lastRun => {
                  this.formGroup.backupFormObjects.setValue(
                    lastRun.objects
                      .filter(object => ['Failed', 'kFailed'].includes(object.status))
                      .map(object => object.id)
                  );
                })
              );
            default:
              return of([]);
          }
        })
      )
      .subscribe(() => this.cdr.markForCheck());
  }

  /**
   * Check whether based on the selected protection group and the linked policy,
   * storage array snapshots are sipported or not.
   *
   * @param   group    Selected protection group.
   * @param   policy   Policy linked to the selected protection group.
   * @returns True if storage array snapshot is supported, false otherwise.
   */
  isStorageArraySnapshotSupported(group: ProtectionGroup, policy: PolicyResponse) {
    const environment = group.environment;
    switch(environment) {
      case Environment.kIbmFlashSystem: {
        const ibmFlashSystemStorageArraySnapshotEnabled =
          flagEnabled(this.irisContextService.irisContext, 'ibmFlashSystemStorageArraySnapshotEnabled');
        if (
          ibmFlashSystemStorageArraySnapshotEnabled &&
          (policy as ProtectionPolicyResponse)?.backupPolicy?.storageArraySnapshot
        ) {
          return true;
        }
        break;
      }
    }

    return false;
  }

  /**
   * open dialog for object selection.
   */
  openObjectSelectionDialog() {
    return combineLatest([this.protectionGroup$, this.run$, this.job$, this.sourceNodes$]).pipe(
      filter(([protectionGroup, protectionRun]) => !isNil(protectionGroup) && !isNil(protectionRun)),
      take(1),
      switchMap(([protectionGroup, protectionRun, job, sourceNodes]) =>
        this.dialogService.showDialog(ObjectsSelectDialogComponent, {
          protectionGroup,
          protectionRun,
          job,
          sourceNodes,
          selectedIds: this.selectedSourceIds || []
        })
      ),
      tap((sourceIds: number[]) => {
        if (sourceIds?.length) {
          this.selectedSourceIds = sourceIds;
          this.formGroup.backupFormObjects.setValue(sourceIds);
        } else {
          this.formGroup.backupFormScope.setValue(BackupScope.All);
        }
      })
    );
  }

  /**
   * open object selection dialog and update current form on close.
   */
  editObjectSelectionDialog() {
    this.openObjectSelectionDialog().subscribe(() => this.cdr.markForCheck());
  }

  /**
   * set formEditMode to boolean
   *
   * @param $event boolean
   */
  setEditMode($event) {
    this.formEditMode = $event;
  }
}

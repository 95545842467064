import { HostSettingsCheckResult, ProtectionSourceNode } from '@cohesity/api/v1';
import { DataTreeControl, DataTreeSelection } from '@cohesity/helix';
import { flagEnabled, IrisContextService, isDmsScope } from '@cohesity/iris-core';
import { Memoize } from '@cohesity/utils';
import { get, keyBy } from 'lodash-es';
import {
  Environment,
  ObjectTypeToIconMap,
  OsVariantIconMap,
  rxSystemDatabase,
  selectableHostEntities,
  SqlBackupTypes,
} from 'src/app/shared/constants';

import { ProtectionSourceDataNode, TooltipContext } from '../shared/protection-source-data-node';

export type SqlSelection = DataTreeSelection<SqlSourceDataNode>;

/**
 * Utility function to determine if a protection source node is a system database.
 *
 * @param   node   The node to check.
 * @returns True if this is a system datbase.
 */
export function isSystemDatabase(node: ProtectionSourceNode) {
  const sourceInfo = node.protectionSource.sqlProtectionSource;
  return rxSystemDatabase.test(sourceInfo && sourceInfo.databaseName);
}

/**
 * Represents an SQL source node and job tree selection behavior.
 */
export class SqlSourceDataNode extends ProtectionSourceDataNode {
  /**
   * This indicates whether the node is part of the job currently being edited
   * or viewed.
   */
  inCurrentJob = false;

  /**
   * Tells us if this node is being used in a job-edit context or not.
   */
  isEditing = false;

  /**
   * This indicates that for a particular non-leaf node, the children are async
   * loaded or not. Helps in determining that children are loaded and should not
   * be loaded again when they are accessed.
   */
  asyncLoadedChildren = false;

  /**
   * Current selection of sql source data nodes.
   */
  private currentSelection: SqlSelection;

  /**
   * Hash of protectionSummaries form this node keyed by environment enum.
   */
  private protectionSummaryMap: Object;

  /**
   * Placeholder for the flattened current selection.
   */
  private flatSelection: SqlSourceDataNode[] = [];

  /**
   * True if this node is selected. False otherwise.
   */
  isSelected = false;

  /**
   * True if anything is selected. False otherwise.
   */
  private jobHasSelection = false;

  /**
   * Gets this node's ID. Always a number in the SQL context.
   */
  get id(): number {
    return Number(this.protectionSource.id);
  }

  /**
   * Returns volumes of a node.
   */
  get nodeVolumes(): any {
    return this.type === 'kHost' ? this.envSource.volumes : [];
  }

  /**
   * Gets the health checks for the SQL environment, if there are any.
   */
  @Memoize()
  get healthChecks(): HostSettingsCheckResult[] {
    if (!this.isSQLHost) {
      return [];
    }

    const appsInfo = (this.data.registrationInfo.registeredAppsInfo || []).find(
      (info: any) => info.environment === 'kSQL'
    );

    return (appsInfo && appsInfo.hostSettingsCheckResults) || [];
  }

  /**
   * Determines if this node has health check problems.
   */
  @Memoize()
  get hasHealthCheckProblems(): boolean {
    return this.isSQLHost && this.healthChecks.some(test => test.resultType !== 'kPass');
  }

  /**
   * Is this object part of an Always Available Group (AAG)?
   */
  @Memoize()
  get isAagNode(): boolean {
    return this.isSQLHost && this.checkSomeDescendants(child => Boolean(child.envSource.dbAagEntityId) ||
      ['kAAG', 'kAAGDatabase'].includes(child.envSource.type));
  }

  /**
   * Itself is an AAG node or its parent host is a aagNode so we mark the node is a AAG node.
   * This property is used to filter AAG nodes.
   */
  @Memoize()
  get parentHostIsAagNode(): boolean {
    return this.isAagNode || this.parentHost.isAagNode;
  }

  /**
   * Its parent host is kWindowsCluster so we mark the node is a FCI node.
   * This proterty is used to filt FCI nodes.
   */
  @Memoize()
  get isFciNode(): boolean {
    return this.parentHost.type === 'kWindowsCluster';
  }

  /**
   * Is this object part of an Always Available Group (AAG)?
   */
  @Memoize()
  get isAagHost(): boolean {
    return this.isSQLHost && this.parentHost.type === 'kAAG';
  }

  /**
   * Is this a fake group rolling up all system databases.
   */
  @Memoize()
  get isSystemDbGroup(): boolean {
    return this.type === 'kSystemDatabases';
  }

  /**
   * Is this a system Database. See rxSystemDatabase for details of what
   * matches.
   */
  @Memoize()
  get isSystemDb(): boolean {
    return isSystemDatabase(this.data);
  }

  /**
   * Determines if this node is a Filesrteam object or not.
   */
  @Memoize()
  get isFilestreamDb(): boolean {
    return !this.isSQLDatabase
      ? false
      : (this.protectionSource.sqlProtectionSource.dbFiles || []).some(file => file.fileType === 'kFileStream');
  }

  /**
   * Gets whether is this node is a physical host or not.
   */
  @Memoize()
  get isPhysicalHost(): boolean {
    return this.type === 'kHost';
  }

  /**
   * Gets whether this node is a Virtual Machine host or not.
   */
  @Memoize()
  get isVmHost(): boolean {
    return this.type === 'kVirtualMachine';
  }

  /**
   * Determines if this node is ever selectable, discreet from if this one can
   * actually be selected.
   */
  @Memoize()
  get isSelectable(): boolean {
    // System dbs can't be directly selected, instead they are selected all at
    // once as a group.
    if (this.isSystemDb) {
      return false;
    }

    return this.isSQLHost ? true : !this.parentHost?.isVmHost;
  }

  /**
   * Gets SQL specific registration info
   */
  get sqlRegistrationInfo(): any {
    if (!this.data?.registrationInfo?.registeredAppsInfo?.length) {
      return;
    }
    return this.data.registrationInfo.registeredAppsInfo.find(app => app.environment === Environment.kSQL);
  }

  /**
   * Gets whether this node has a registration error or not.
   */
  get hasRegistrationError(): boolean {
    return this.isSQLHost && this.sqlRegistrationInfo?.authenticationStatus !== 'kFinished' &&
      this.sqlRegistrationInfo?.authenticationStatus !== undefined;
  }

  /**
   * If this node has sub nodes, it is expandable.
   */
  @Memoize()
  get expandable(): boolean {
    const children = this.children;
    if (flagEnabled(this.irisContextService.irisContext, 'ngSqlAsyncMode')) {
      return (Array.isArray(children) && children.length > 0) || this.isSQLInstance;
    } else {
      return Array.isArray(children) && children.length > 0;
    }
  }

  /**
   * Returns this node's parent host SqlSourceDataNode. Will be undefined for
   * hosts themselves, and either a kPhysical or kVMware node everything else.
   */
  @Memoize()
  get parentHost(): SqlSourceDataNode {
    // Exit early if this node has no parentHost (it's a host itself).
    if (this.isSQLHost) {
      return this;
    }

    const myOwnerId = this.ownerId || 0;

    let parentHost: SqlSourceDataNode;

    // Query the ancestor list for the host matching this node's ownerId.
    this.checkAnyAncestor((parent: SqlSourceDataNode): boolean => {
      if (myOwnerId === parent.id) {
        parentHost = parent;
        return true;
      }
    });

    return parentHost;
  }

  /**
   * Does this node have children protected by another Job?
   */
  get hasChildrenInCurrentJob(): boolean {
    return this.checkSomeDescendants((child: SqlSourceDataNode) => child.inCurrentJob);
  }

  /**
   * This nodes' ownerId, if it's an app entity. Will be undefined for hosts.
   */
  get ownerId(): number {
    return Number(this.envSource.ownerId);
  }

  /**
   * Get this nodes database name (without the instance name).
   */
  get databaseName(): string {
    return this.envSource.databaseName;
  }

  /**
   * Is this node a SQL host?
   */
  get isSQLHost(): boolean {
    return selectableHostEntities.includes(this.type);
  }

  /**
   * Is this node a SQL database?
   */
  get isSQLDatabase(): boolean {
    return this.type === 'kDatabase';
  }

  /**
   * Is this node a SQL AAG database?
   */
  get isSQLAagDatabase(): boolean {
    return this.type === 'kAAGDatabase';
  }

  /**
   * Is this node a SQL Instance?
   */
  get isSQLInstance(): boolean {
    return this.type === 'kInstance';
  }

  /**
   * Return its parentHost.
   */
  get sqlHost(): any {
    return get(this, 'parentHost.data.protectionSource');
  }

  /**
   * Returns true if the node is a leaf.
   */
  get isLeaf(): boolean {
    return this.isSQLDatabase || this.isSQLAagDatabase;
  }

  /**
   * Return the node level in auto protected view.
   */
  get levelInAutoProtectedView() {
    if (this.isSystemDbGroup) {
      return 1;
    } else if (this.isSystemDb) {
      return 2;
    }
    return super.levelInAutoProtectedView;
  }

  /**
   * Determines if this is a SQL host without children or not.
   */
  @Memoize()
  get isHostWithoutChildren(): boolean {
    return this.isSQLHost && !this.expandable;
  }

  /**
   * Get this nodes. isntanceNam, whether it's an instance itself, or a
   * database.
   */
  get instanceName(): string {
    return !this.isSQLHost ? this.envSource.name : undefined;
  }

  /**
   * Gets the name of this node.
   */
  get name(): string {
    return this.databaseName || this.instanceName || super.name;
  }

  /**
   * Is this node protected by any type of SQL Protection Job?
   */
  get isSqlProtected(): boolean {
    return this.isEnvironmentProtected(Environment.kSQL);
  }

  /**
   * Is this node protected by a VMware Protection Job?
   */
  get isVmwareProtected(): boolean {
    return this.isEnvironmentProtected(Environment.kVMware);
  }

  /**
   * Is this node protected by a Physical Volume-based Protection Job?
   */
  get isPhysicalVolumeProtected(): boolean {
    return this.isEnvironmentProtected(Environment.kPhysical);
  }

  /**
   * Is this node protected by a Physical File-based Protection Job?
   */
  get isPhysicalFileProtected(): boolean {
    return this.isEnvironmentProtected(Environment.kPhysicalFiles);
  }

  /**
   * Is this node protected by a SQL FCBT Protection Job?
   */
  get isSqlFileProtected(): boolean {
    return this.isSqlProtected && !(this.isPhysicalVolumeProtected || this.isVmwareProtected);
  }

  /**
   * Is this node protected by a SQL CBT Protection Job?
   */
  get isSqlVolumeProtected(): boolean {
    return this.isSqlProtected && (this.isPhysicalVolumeProtected || this.isVmwareProtected);
  }

  /**
   * Is this node protected by a VMware SQL Job (CBT)
   */
  get isSqlVmwareProtected(): boolean {
    return this.isSqlProtected && this.isVmwareProtected;
  }

  /**
   * Gets the backupType of the current SQL Job.
   */
  get jobSqlBackupType(): SqlBackupTypes {
    return get(this.job, 'msSqlSettings.msSqlBackupType');
  }

  /**
   * Finds the first host in the current selection and returns it's environment
   * type.
   *
   * @param   selection  The selection to query through.
   * @return  The Environment of the current selection set in the Protection
   *          Job.
   */
  getJobsHostEnvironment(selection: SqlSelection): string {
    const { selected = [], autoSelected = [] } = selection || {};

    // If there is an existing job and no selection, then this is determined from the protection type.
    if (this.jobSqlBackupType && !selected.length && !autoSelected.length) {
      if (this.jobSqlBackupType === 'kVolume' && this.isVmHost) {
        return Environment.kVMware;
      } else {
        return Environment.kPhysical;
      }
    }

    // Otherwise, it is determined by the currently selected items.
    if (selected.length) {
      return selected[0].hostEnvironment;
    }
    if (autoSelected.length) {
      return autoSelected[0].hostEnvironment;
    }
  }

  /**
   * Gets the environment of this current node. In SQL trees, there can be as
   * many as 3 concurrent environments: kVMware or kPhysical at the host level,
   * and kSQL everywhere else (root and children).
   */
  get nodeEnvironment(): string {
    return this.protectionSource.environment;
  }

  /**
   * Gets the environment of this nodes host.
   */
  get hostEnvironment(): string {
    const parent = this.parentHost;

    return parent ? parent.protectionSource.environment : this.environment;
  }

  /**
   * Gets the host type of the node (kWindows or KLinux).
   */
  get hostType(): string {
    return (
      this.parentHost?.protectionSource?.physicalProtectionSource?.hostType ||
      this.parentHost?.protectionSource?.vmWareProtectionSource?.hostType
    );
  }

  /**
   * Determines if this node can be SQL Volume (CBT) protected.
   *
   * @returns   True if it can be SQL Volume protected.
   */
  get canSqlVolumeProtect(): boolean {
    // Cases [0,0], [0,1], and [5,5] in matrix,
    return !this.isSqlVolumeProtected && !this.isPhysicalVolumeProtected;
  }

  /**
   * Gets whether or not this node can be sql native protected.
   *
   * @returns   True if this can be SQL Native protected.
   */
  get canSqlNativeProtect(): boolean {
    // Cases [0,7] and [7,7]
    return this.hostEnvironment !== Environment.kVMware && !this.isSqlFileProtected && !this.isSqlVolumeProtected;
  }

  /**
   * Determines if this node can be SQL File (FCBT) protected. This includes VDI
   * (SQL Native).
   *
   * @returns   True if it can be SQL File protected.
   */
  get canSqlFileProtect(): boolean {
    // Cases [0,7] and [7,7]
    return (
      this.hostEnvironment !== Environment.kVMware &&
      !this.isSqlFileProtected &&
      !this.isSqlVolumeProtected &&
      !this.isFilestreamDb
    );
  }

  /**
   * Gets volume options, if any, for this node.
   */
  get volumeOptions(): any {
    return this.currentSelection && this.currentSelection.options[this.id];
  }

  /**
   * Returns parent host name of the selected Sql source.
   *
   * @return parent host name.
   */
  get parentHostName(): string {
    return this.parentHost?.name;
  }

  /**
   * Updates a suite of caches based on the current selection. These are
   * necessary for lookup because the selectability of a given node depends on
   * the other selected things to restrict selection to homogeneous host types.
   *
   * @param   selection   The current selection.
   */
  set selectionCaches(selection: SqlSelection) {
    this.currentSelection = selection;
    this.flatSelection = selection ? [...this.currentSelection.selected, ...this.currentSelection.autoSelected] : [];
    this.isSelected = this.flatSelection.includes(this);
    this.jobHasSelection = this.flatSelection.length > 0;
  }

  constructor(
    data: ProtectionSourceNode,
    public level: number,
    readonly treeControl: DataTreeControl<SqlSourceDataNode>,
    readonly job: any,
    private irisContextService: IrisContextService
  ) {
    super(Environment.kSQL, data, level);
    this.protectionSummaryMap = keyBy(this.data.protectedSourcesSummary, 'environment');
    this.isEditing = this.job ? !!this.job.id : false;
  }

  /**
   * Can this node be auto-protected?
   *
   * @param    selection   The current selection.
   * @return   True if this node can be autoSelected/auto-protected.
   */
  canAutoSelect(selection: SqlSelection = {}): boolean {
    this.selectionCaches = selection;

    const nonAutoSelectEntities = ['kSystemDatabases', 'kDatabase'];

    if (
      isDmsScope(this.irisContextService.irisContext) ||
      flagEnabled(this.irisContextService.irisContext, 'sqlDisableHostAutoProtection')
    ) {
      nonAutoSelectEntities.push('kHost');
    }

    // Any non DB and non VM entities can be auto-protected. System database
    // groups cannot be auto protected either.
    return (
      !nonAutoSelectEntities.includes(this.type) &&
      // Remove this predicate if we ever enable auto-protect or sub-selection
      // of VMware thingies.
      this.hostEnvironment !== Environment.kVMware &&
      (this.canSelect(selection) || this.isHostWithoutChildren) &&
      !this.hasRegistrationError
    );
  }

  /**
   * Exclusion at individual nodes is not yet supported for SQL yet at object level.
   * They can exclude the objects globally using exclude option via entering a wildcard expression
   * "(Host/*instance/DB*?)" or enable checkbox and exclude via RegEx (Host1./*SQL./*DB$). Only Host,
   * Instance or DBs can be excluded.
   *
   * @return   True if this node can be excluded.
   */
  canExclude(): boolean {
    return false;
  }

  /**
   * A node can be selected if it is not protected or if it is in the current
   * job. This function is defined as a property so that 'this' is bound
   * correctly.
   *
   * @return   True if this node can be selected.
   */
  canSelect(selection: SqlSelection): boolean {
    const jobsHostEnvironment = this.getJobsHostEnvironment(selection);
    const backupType = this.jobSqlBackupType;
    const isJobVolumeBased = backupType === SqlBackupTypes.Volume;

    // Allow only one type of DBs i.e. either Linux or Windows in a single protection job.
    if (
      (this.isLinuxDbSelected(selection) && this.hostType === 'kWindows') ||
      (this.isWindowsDbSelected(selection) && this.hostType === 'kLinux')
    ) {
      return false;
    }

    // If the SQL tree is used in any context except for protection group
    // workflow, any node can be selected with appropriate actions.
    if (!this.job) {
      return true;
    }

    if (this.inCurrentJob || this.hasChildrenInCurrentJob) {
      return true;
    }

    if (
      this.hasRegistrationError ||
      // A host with no children is not selectable.
      this.isHostWithoutChildren
    ) {
      return false;
    }

    // If this is a system db group, check each descendant that is a systemDb
    // and return whether each of them can be selected.
    if (this.isSystemDbGroup) {
      return this.treeControl.checkAllDescendants(this, child => child.isSystemDb && child.canSelect(selection));
    }

    // Already selected objects are from a different host environment than this
    // node, so this can't be selected.
    if (jobsHostEnvironment && this.hostEnvironment !== jobsHostEnvironment) {
      return false;
    }

    // These checks only apply if this node is not in the current job. Algorithm
    // derived from the matrix @ https://confluence.cohesity.com/x/E4DLFw
    if (!this.inCurrentJob && this.protected) {
      // [0, 0], [0, 1], [0, 7] (and [1, 0], [7, 0])
      if (this.isPhysicalVolumeProtected) {
        return false;
      }

      // [5, 5]
      if (isJobVolumeBased && this.isSqlVmwareProtected) {
        return false;
      }

      // [7, 7]
      if (this.isSqlFileProtected) {
        return false;
      }
    }

    // This disables all other instances and dbs inside the host
    // which are not involved during editing an object protection.
    if (this.job.editingProtectedObject && !this.inCurrentJob) {
      return false;
    }

    return true;
  }

  /**
   * Helper to determine if this node is protected by the given environment
   * kValue job type, ie. 'kPhysical'.
   *
   * @param    environment  The environment to check protection status for.
   * @return   True if the node is protected by a Job for the given Environment.
   */
  @Memoize()
  isEnvironmentProtected(environment: Environment): boolean {
    const summary = this.protectionSummaryMap[environment] || {};

    return summary.leavesCount > 0;
  }

  /**
   * Helper to determine if any Linux object is selected in the current selection.
   *
   * @param    selection  current selection.
   * @return   True if Linux db is selected.
   */
  isLinuxDbSelected(selection: SqlSelection): boolean {
    if (!selection) {
      return false;
    }
    return [...selection.selected, ...selection.autoSelected].some(node => node.hostType === 'kLinux');
  }

  /**
   * Helper to determine if any Windows object is selected in the current selection.
   *
   * @param    selection  current selection.
   * @return   True if Windows db is selected.
   */
  isWindowsDbSelected(selection: SqlSelection): boolean {
    if (!selection) {
      return false;
    }
    return [...selection.selected, ...selection.autoSelected].some(node => node.hostType === 'kWindows');
  }

  /**
   * Memoized helper to query this node's children for a single match.
   *
   * @param   predicate   Predicate callback function to determine if any node
   *                      is a match.
   * @return  True if there's a match.
   */
  checkSomeDescendants(predicate: (child: SqlSourceDataNode) => boolean): boolean {
    return this.expandable && this.treeControl.checkSomeDescendants(this, predicate);
  }

  /**
   * Memoized helper to query this node's children for all matches.
   *
   * @param   predicate   Predicate callback function to determine if each node
   *                      is a match.
   * @return  True if all descendants match.
   */
  checkAllDescendants(predicate: (child: SqlSourceDataNode) => boolean): boolean {
    return this.expandable && this.treeControl.checkAllDescendants(this, predicate);
  }

  /**
   * Memoized helper to query this node's parent for a single match.
   *
   * @param   predicate   Predicate callback function to determine if any node
   *                      is a match.
   * @return  True if there's a match.
   */
  @Memoize()
  checkAnyAncestor(predicate: (ancestor: SqlSourceDataNode) => boolean): boolean {
    return this.treeControl.checkAnyAncestor(this, predicate);
  }

  /**
   * Gets object select check box tooltip.
   *
   * @returns check box tooltip object.
   */
  getCheckBoxToolTip(): TooltipContext {
    switch (true) {
      case this.hasRegistrationError:
        return { translateKey: 'registrationInProgress' };
    }
  }
  /**
   * Is this object part of an Always Available Group (AAG)?
   */
  @Memoize()
  get isAagDatabase(): boolean {
    return (this.isSQLDatabase && Boolean(this.protectionSource?.sqlProtectionSource?.dbAagEntityId)) ||
      this.isSQLAagDatabase;
  }

  /**
   * Determines the icon for the given sql entity.
   */
  get objectIcon(): string {
    const envIconMap = ObjectTypeToIconMap[this.protectionSource.environment];
    let iconName = envIconMap && envIconMap[this.type];

    if (!iconName) {
      return null;
    }

    if (OsVariantIconMap[iconName] && this.hostType) {
      iconName = OsVariantIconMap[iconName][this.hostType] || iconName;
    }

    if (this.isAagDatabase) {
      iconName = envIconMap['kAAGDatabase'];
    }

    // Object nodes which are auto protected should show the auto protected icon too
    const suffix = (this.isAutoProtectedByObject || (!this.isLeaf && this.isObjectProtected)) ? '-auto' :
      this.protected ? '-protected' :
      this.partialProtected ? '-partial' : '';

    return iconName.concat(suffix);
  }
}

import { UntypedFormControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { Validator as IpNumValidator } from 'ip-num';
import { isValidIPv4Mask, REGEX_FORMATS } from '@cohesity/utils';

/**
 * Validator for only ipv4 address.
 *
 * @param control The form control whose value will be validated.
 * @return null if a valid string, otherwise error object.
 */
export const Ipv4AddressValidator: ValidatorFn = (control: UntypedFormControl) => {
  if (!control.value) {
    return null;
  }

  const isIpv4 = REGEX_FORMATS.IPv4.test(control.value || '');
  return isIpv4 ? null : { ipv4AddressError: true };
};

/**
 * Validator for only ipv6 address.
 *
 * @param control The form control whose value will be validated.
 * @return null if a valid string, otherwise error object.
 */
export const Ipv6AddressValidator: ValidatorFn = (control: UntypedFormControl) => {
  if (!control.value) {
    return null;
  }
  const isIpv6 = IpNumValidator.isValidIPv6String(control.value || '')[0];
  return isIpv6 ? null : { ipv6AddressError: true };
};

/**
 * Validator for both ipv4 and ipv6 address.
 *
 * @param control The form control whose value will be validated.
 * @return null if a valid string, otherwise error object.
 */
export const IpAddressValidator: ValidatorFn = (control: UntypedFormControl) => {
  if (!control.value) {
    return null;
  }
  const isIpAddress = REGEX_FORMATS.IPv4AndIPv6.test(control.value || '');
  return isIpAddress ? null : { ipAddressError: true };
};

/**
 * Validator for ipv4 subnet cidr address.
 *
 * @param control The form control whose value will be validated.
 * @return null if a valid string, otherwise error object.
 */
export const Ipv4CidrAddressValidator: ValidatorFn = (control: UntypedFormControl) => {
  if (!control.value) {
    return null;
  }
  const isIpv4Cidr = IpNumValidator.isValidIPv4CidrNotation(control.value || '')[0];
  return isIpv4Cidr ? null : { ipv4CidrError: true };
};

/**
 * Validator to check if the input is a valid private IPv4 CIDR address.
 * Ensures the subnet mask is within the acceptable range and belongs to a private IP address.
 *
 * @param maxLimit - The maximum allowed subnet mask length.
 * @param valdatePrivateSubnetAsPerRFC1918 - Whether to validate if the IP range private as per RFC1918.
 * @returns An object with the validation error if validation fails, or null if valid.
 */
export const IPv4SubnetValidator =
  (maxCidrLimit: number, valdatePrivateSubnetAsPerRFC1918 = true): ValidatorFn =>
    (control: UntypedFormControl): ValidationErrors | null => {
      const cidrValue = control.value;

      // If no value is provided, return null (no validation error).
      if (!cidrValue) {
        return null;
      }

      // Validate if the input is a valid IPv4 CIDR notation.
      const isValidCidr = IpNumValidator.isValidIPv4CidrNotation(cidrValue || '')[0];
      if (!isValidCidr) {
        return { privateSubnetRangeError: true };
      }

      // Extract the IP address part from the CIDR notation.
      const ipAddress = cidrValue.split('/')[0];

      // Extract the subnet mask length from the CIDR notation.
      const subnetMaskLength = parseInt(cidrValue.split('/')[1], 10);

      if (valdatePrivateSubnetAsPerRFC1918) {
        // List of valid private IP address with their CIDR lengths.
        const privateIpRanges = [
          { cidr: 8, ip: '10.0.0.0' },
          { cidr: 12, ip: '172.16.0.0' },
          { cidr: 16, ip: '192.168.0.0' },
        ];

        // Check if the IP address belongs to a valid private IP range.
        const matchingRange = privateIpRanges.find(range => range.ip === ipAddress);
        if (!matchingRange) {
          return { privateSubnetRangeError: true };
        }

        // Validate if the subnet mask length is within the allowed minimum limit.
        if (subnetMaskLength < matchingRange.cidr) {
          return { minPrefixError: true };
        }
      }

      // Validate if the subnet mask length exceeds the maximum allowed limit.
      if (subnetMaskLength > maxCidrLimit) {
        return { maxPrefixError: true };
      }

      // If all validations pass, return null indicating no validation errors.
      return null;
    };

/**
 * Validator for ipv4 subnet cidr address.
 *
 * @param control The form control whose value will be validated.
 * @return null if a valid string, otherwise error object.
 */
export const Ipv4CidrAddressValidatorWithSubnetFixed: ValidatorFn = (control: UntypedFormControl): ValidationErrors | null => {
  // Check for no values
  if (!control.value) {
    return { ipv4CidrError: true };
  }

  // Check if not valid ip addresses
  if (!IpNumValidator.isValidIPv4CidrNotation(control.value || '')[0]) {
    return { ipv4CidrError: true };
  }

  // Check if valid netmaskBits addresses
  const [, netmaskBits] = control.value && control.value.split('/') || '';
  if (Number(netmaskBits) > 22) {
    return { ipv4CidrSubnetUpto22Bits: true };
  }

  return null;
};

/**
 * Validator for ipv4 subnet mask address.
 *
 * @param control The form control whose value will be validated.
 * @return null if a valid string, otherwise error object.
 */
export const Ipv4SubnetMaskValidator: ValidatorFn = (control: UntypedFormControl) => {
  if (!control.value) {
    return null;
  }
  const isIpv4SubnetMask = IpNumValidator.isValidIPv4Mask(control.value || '')[0];
  return isIpv4SubnetMask ? null : { ipv4SubnetMaskError: true };
};

/**
 * Validator for ipv6 subnet cidr address.
 *
 * @param control The form control whose value will be validated.
 * @return null if a valid string, otherwise error object.
 */
export const Ipv6CidrAddressValidator: ValidatorFn = (control: UntypedFormControl) => {
  if (!control.value) {
    return null;
  }
  const address: string = control.value || '';
  const isIpv6Cidr = IpNumValidator.isValidIPv6CidrNotation(address)[0] && address.indexOf('/') !== -1;
  return isIpv6Cidr ? null : { ipv6CidrError: true };
};

/**
 * Validator for both ipv4 and ipv6 address/subnet.
 *
 * @param control The form control whose value will be validated.
 * @return null if a valid string, otherwise error object.
 */
export const IpAddressCidrValidator: ValidatorFn = (control: UntypedFormControl) => {
  if (!control || !control.value) {
    return null;
  }
  const isIpAddressOrCidrAddress = isValidIpOrSubnet(control.value);
  return isIpAddressOrCidrAddress ? null : {ipOrCidrAddress: true};
};

/**
 * Validator which returns true if control has either ipv4/ipv6 cidr address.
 *
 * @param control The form control whose value will be validated.
 * @return null if a valid string, otherwise error object.
 */
export const CidrValidator: ValidatorFn = (control: UntypedFormControl) => {
  const address = control.value;
  if (!control || !address) {
    return null;
  }
  return [
    IpNumValidator.isValidIPv4CidrNotation(address || '')[0],
    IpNumValidator.isValidIPv6CidrNotation(address || '')[0],
  ].some(Boolean) ? null : {cidrAddressError: true};
};

/**
 * Return true if address is valid ipv4/ipv6 address or subnet.
 *
 * @param address IP address string to validate.
 * @return True/false based on validity of address.
 */
export function isValidIpOrSubnet(address: string): boolean {
  return [
    IpNumValidator.isValidIPv4String(address || '')[0],
    IpNumValidator.isValidIPv6String(address || '')[0],
    IpNumValidator.isValidIPv4CidrNotation(address || '')[0],
    IpNumValidator.isValidIPv6CidrNotation(address || '')[0],
  ].some(Boolean);
}

/**
 * Validator for FQDN or IPv4/IPv6 addresses.
 *
 * @param control The form control whose value will be validated.
 * @returns null if a valid string, otherwise error object.
 */
export function FqdnIpv4Ipv6Validator(control: UntypedFormControl): ValidationErrors | null {
  if (!control ||!control.value) {
    return null;
  }
  const isValid = REGEX_FORMATS.fqdnRegExp.test(control.value) || REGEX_FORMATS.IPv4AndIPv6.test(control.value);
  return isValid ? null : { invalidFqdnOrIp: true };
};


/**
 * Validator for IPv4 subnet mask or CIDR notation.
 */
export function Ipv4SubnetMaskOrCidrValidator(control: UntypedFormControl): ValidationErrors | null {
  if (!control || !control.value) {
    return null;
  }
  const isValidSubnetMask = isValidIPv4Mask(control.value);
  const isValidCidrNotation = IpNumValidator.isValidIPv4CidrNotation(control.value)[0];
  return isValidSubnetMask || isValidCidrNotation ? null : { ipv4CidrError: true };
}

/**
 * Validator for IPv6 subnet mask or CIDR notation.
 */
export function Ipv6SubnetMaskOrCidrValidator(control: UntypedFormControl): ValidationErrors | null {
  if (!control || !control.value) {
    return null;
  }

  // TODO(MK): The current implementation of IpNumValidator.isValidIPv6Mask may not be accurate.
  // Consider evaluating alternative packages or implementing a custom validation
  // method similar to isValidIPv4Mask to ensure reliable IPv6 subnet mask validation.
  const isValidSubnetMask = !control.value.includes('/') && IpNumValidator.isValidIPv6Mask(control.value)[0];
  const isValidCidrNotation = IpNumValidator.isValidIPv6CidrNotation(control.value)[0];
  return isValidSubnetMask || isValidCidrNotation ? null : { ipv6CidrError: true };
}

import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { get } from 'lodash-es';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith } from 'rxjs/operators';

import { ProtectionGroupObject } from '../models';

/**
 * Modify Protection Service can be injected into individual forms and can be used to simplify interacting
 * with other form controls.
 */
@Injectable()
export class ModifyProtectionService {
  /**
   * The main, parent form group
   */
  parentForm: UntypedFormGroup;

  /**
   * The protection group, if this is an edit, or abbreviated workflow
   */
  protectionGroup: ProtectionGroupObject;

  /**
   * Returns true if the form is in edit mode.
   */
  get isEdit(): boolean {
    return this.protectionGroup && !!this.protectionGroup.id;
  }

  /**
   * Sets the value for a given control.
   *
   * @param   name  The control name.
   * @param   value The control value.
   */
  setControlValue(name: string, value: any) {
    const control = this.getControl(name);
    if (control) {
      control.setValue(value);
    }
  }

  /**
   * Gets a control's current value.
   *
   * @param   name  The control name.
   * @returns The control value.
   */
  getControlValue(name: string): any {
    const control = this.getControl(name);
    return control ? control.value : undefined;
  }

  /**
   * Returns an observable of value changes for a control value.
   *
   * @param   name   The control name.
   * @param   emitOnUndefined   Whether the observable should emit if the value is still undefined.
   * @returns An observable of the control changes.
   */
  onControlChange(name: string, emitOnUndefined: boolean = false): Observable<any> {
    return this.parentForm.valueChanges.pipe(
      // Only emit when the value changes
      // Note that this uses get so that we can extract a nested value from the form.
      // If the value doesn't exist yet, this will still work.
      distinctUntilChanged((x: any, y: any) => get(x, name) === get(y, name)),

      // return the form value
      map(formValue => get(formValue, name)),

      // Start with the current value
      startWith((this.getControl(name) || { value: undefined }).value),

      // Only return if the value is has been set.
      filter(value => !!value || emitOnUndefined)
    );
  }

  /**
   * Gets a from control.
   *
   * @param name The control to get
   * @returns The control. Note, that this can return undefined if the control does not exist yet.
   */
  private getControl(name: string): AbstractControl {
    const controlNames = name.split('.');
    let control: AbstractControl = this.parentForm;
    controlNames.forEach(controlName => {
      control = control.get(controlName);
    });
    return control;
  }
}

import { Injectable } from '@angular/core';
import { ClusterMetadataDocsPurpose, PlatformServiceApi } from '@cohesity/api/v2';
import { AjaxHandlerService } from '@cohesity/utils';

/**
 * SLA model
 */
export interface Sla {
  full: number;
  incremental: number;
  log?: number;
}

@Injectable({
  providedIn: 'root',
})
export class ClusterMetadataService {
  /**
   * Default SLA value for a cluster
   */
  defaultSla: Sla = { full: 120, incremental: 60, log: 60 };

  /**
   * Record of Docs URL by purpose
   */
  urlByPurpose: Partial<Record<ClusterMetadataDocsPurpose['purpose'], string>> = {
    APIDocs: '',
    BackupServiceConnectionDocs: '',
    HelpDocs: '',
    SupportDocs: '',
    LicenseDocs: '',
  };

  constructor(private ajaxService: AjaxHandlerService, private platformServiceApi: PlatformServiceApi) {}

  /**
   * Fetch cluster metadata
   */
  fetchClusterMetadata() {
    this.platformServiceApi.GetClusterMetadata()
      .subscribe(
        metadata => {
          metadata.docs.forEach(doc => (this.urlByPurpose[doc.purpose] = doc.url));
          if (metadata.sla.defaultSLA) {
            this.defaultSla = { full: metadata.sla.defaultSLA, incremental: metadata.sla.defaultSLA, log: 60 };
          }
        },

        error => this.ajaxService.handler(error)
      );
  }

  /**
   * Get documentation URL based on usage purpose
   *
   * @param purpose purpose of the docs URL
   * @returns URL of the requested documentation
   */
  getDocUrl(purpose: ClusterMetadataDocsPurpose['purpose'] = 'HelpDocs'): string {
    return this.urlByPurpose[purpose];
  }

  /**
   * Default SLA values for a protection job
   *
   * @returns default SLA value
   */
  getDefaultSla(): Sla {
    return this.defaultSla;
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AutoDestroyable } from '@cohesity/utils';
import { clamp } from 'lodash-es';
import { Observable } from 'rxjs';
import { finalize, map, shareReplay, takeUntil, takeWhile, tap, withLatestFrom } from 'rxjs/operators';
import { RunProgressStatsPollerService } from 'src/app/core/services';

/**
 * The properties to walk when trying to determine duration or percentage
 * complete for a given progress indicator.
 */
const propertiesToWalk = [
  // NOTE: archivalRunParams is used in DMaaS activity.
  'archivalRunParams',

  // NOTE: These properties are used in on-prem protection.
  'archivalRun',
  'replicationRun',
];

/**
 * @description
 *
 * Component for displaying run progress
 *
 * @example
 *   <coh-run-progress
 *      [runId]="runId"
 *      (completed)="runFinishedCallback()">
 *   </coh-run-progress>
 */
@Component({
  selector: 'coh-run-progress',
  templateUrl: './run-progress.component.html',
  styleUrls: ['./run-progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RunProgressComponent extends AutoDestroyable implements OnInit {
  /**
   * Cluster from where to fetch the progress.
   */
  @Input() accessClusterId: string;

  /**
   * Run ID to monitor run progress.
   */
  @Input() runId: string;

  /**
   * Region from where to fetch the progress.
   */
  @Input() regionId: string;

  /**
   * Optional start time to be used to calculate duration instead of run progress API start time.
   */
  @Input() overrideStartTimeUsecs?: number;

  /**
   * Callback triggered when all run's targets have finished.
   */
  @Output() readonly completed = new EventEmitter();

  /**
   * Percentage upper limit.
   */
  @Input() percentUpperLimit = 95;

  /**
   * Label for percentage finished.
   */
  @Input() label: string;

  /**
   * Observable will return overall completion percentage across all run's targets.
   */
  percentageCompleted$: Observable<number>;

  /**
   * Overall run's duration in milliseconds between lowest start time and highest end time.
   */
  runDuration$: Observable<number>;

  /**
   * Callback triggered when fetch duration value.
   */
  @Output() readonly updateDuration = new EventEmitter<any>();

  constructor(private pollerService: RunProgressStatsPollerService) {
    super();
  }

  ngOnInit() {
    // TODO(alex): Due to NG bug have to implement takeUntil: https://github.com/angular/angular/issues/17624
    const runProgress$ = this.pollerService.pollRunProgress(
      this.runId,
      undefined,
      this.regionId,
      true,
      null,
      null,
      null,
      this.accessClusterId
    ).pipe(this.untilDestroy(), shareReplay(1));

    const percentageCompleted$ = runProgress$.pipe(
      // Don't fire this after completed event is fired.
      takeUntil(this.completed),
      map(runProgress => {
        // accumulated progress across all targets to calculate average progress across all tasks and objects
        let totalProgress = 0;
        // number of targets that have active progress
        let targetCount = 0;

        if (runProgress.localRun?.percentageCompleted) {
          totalProgress += runProgress.localRun.percentageCompleted;
          targetCount++;
        } else {
          runProgress.localRun?.objects?.forEach(object => {
            totalProgress += object.percentageCompleted || 0;
            targetCount++;
          });
        }

        propertiesToWalk.forEach(prop => {
          runProgress[prop]?.forEach(run => {
            if (run.percentageCompleted) {
              totalProgress += run.percentageCompleted;
              targetCount++;
            } else {
              run.objects?.forEach(object => {
                totalProgress += object.percentageCompleted || 0;
                targetCount++;
              });
            }
          });
        });

        if (targetCount > 0) {
          return totalProgress / targetCount;
        } else {
          return 0;
        }
      }),
      takeWhile(percentage => percentage < 100, true),
      finalize(() => this.completed.emit()),
      shareReplay(1),
    );

    this.runDuration$ = runProgress$.pipe(
      withLatestFrom(percentageCompleted$),
      map(([ runProgress, percentCompleted ]) => {
        const inProgress = percentCompleted < 100;

        let startTimeUsecs = this.overrideStartTimeUsecs;
        let endTimeUsecs: number;

        if (inProgress) {
          endTimeUsecs = new Date().getTime() * 1000;
        }

        if (runProgress.localRun) {
          startTimeUsecs = runProgress.localRun.startTimeUsecs;

          if (!inProgress && runProgress.localRun.endTimeUsecs > 0) {
            endTimeUsecs = runProgress.localRun.endTimeUsecs;
          }
        }

        propertiesToWalk.forEach(prop => {
          runProgress[prop]?.forEach(run => {
            if (!startTimeUsecs || startTimeUsecs > run.startTimeUsecs) {
              startTimeUsecs = run.startTimeUsecs;
            }

            if (!inProgress && run.endTimeUsecs &&
              ((endTimeUsecs && run.endTimeUsecs > endTimeUsecs) || !endTimeUsecs)
            ) {
              endTimeUsecs = run.endTimeUsecs;
            }
          });
        });

        return (endTimeUsecs - startTimeUsecs) / 1000;
      }),
      tap(res => this.updateDuration.emit(res)),
      shareReplay(1),
    );

    // TODO: temp util API is fixed, ensure progress bar never looks static
    this.percentageCompleted$ = percentageCompleted$.pipe(
      map(percentage => clamp(percentage, 5, this.percentUpperLimit)),
      shareReplay(1),
    );
  }
}

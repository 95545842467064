import { registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import localeJa from '@angular/common/locales/ja';
import localeJaExtra from '@angular/common/locales/extra/ja';
import { Injectable, isDevMode } from '@angular/core';
import { BasicClusterInfo, PreferencesServiceApi } from '@cohesity/api/v1';
import { TranslateService } from '@ngx-translate/core';
import { get, map as _map, merge } from 'lodash-es';
import { BehaviorSubject, forkJoin, from, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { Locale, LocaleItem } from 'src/app/models';
import { DefaultLocale, LangFile, LocaleFile, Locales, LanguageOverrideFile } from 'src/app/shared/constants';

import { AjsUpgradeService } from './ajs-upgrade.service';
import { ClusterService } from './cluster.service';
import { TranslationLoaderService } from './translation-loader.service';
import { UserPreferencesServiceApi } from '@cohesity/api/v2';
import { FeatureFlagsService } from './feature-flags.service';

/**
 * A service to modify locale related configs. Lets you get all the available
 * locales, save/get the locale preferences etc.
 */
@Injectable({
  providedIn: 'root'
})
export class LocaleService {

  private _text$ = new BehaviorSubject<any>(undefined);
  private _dateLocale$ = new BehaviorSubject<any>(undefined);
  private _activeLocale$ = new BehaviorSubject<Locale>(undefined);
  private _userLocale$ = new BehaviorSubject<Locale>(undefined);

  /**
   * Exposed the user locale subject as observable.
   */
  readonly userLocale$ = this._userLocale$.asObservable();

  /**
   * Expose text properties as an observable.
   */
  readonly text$ = this._text$.asObservable();

  /**
   * Expose date locale as an observable.
   */
  readonly dateLocale$ = this._dateLocale$.asObservable();

  /**
   * Getter for locale date subject.
   */
  get dateLocale(): any {
    return this._dateLocale$.value;
  }

  /**
   * The active locale for the session used as an observer to watch the changes
   * elsewhere.
   */
  readonly activeLocale$ = this._activeLocale$.asObservable();

  /**
   * The saved user locale.
   */
  userLocale: Locale;

  /**
   * This is $translate
   */
  private ajsTranslate: any;

  constructor(ajsUpgrade: AjsUpgradeService,
    private clusterService: ClusterService,
    private http: HttpClient,
    private preferencesService: PreferencesServiceApi,
    private userPreferencesService: UserPreferencesServiceApi,
    private translationLoader: TranslationLoaderService,
    private translate: TranslateService,
    private featureFlagService: FeatureFlagsService

  ) {
    // Need it for DatePipe support for other language like Japanese.
    registerLocaleData(localeJa, 'ja-JP', localeJaExtra);

    this.ajsTranslate = ajsUpgrade.get('$translate');

    // This listens for changes to basic cluster info, and will set the locale if it has
    // not already been set.
    clusterService.basicClusterInfo$.pipe(
      filter(info => !!info && !this.activeLocale),
      switchMap(info => this.loadLocale(info.languageLocale as Locale,
        this.readLocalizationOverride(info))),
    ).subscribe();
  }

  /**
   * This function helps determines the additional parameter
   * necessary to load the localization override file.
   *
   * @param basicClusterInfo
   * @returns - the string to use to load the localization file.
   */
  readLocalizationOverride(basicClusterInfo: BasicClusterInfo) {
    // KISS for now.  But as this logic gets more complicated, we may need to revisit this.
    return basicClusterInfo.clusterDeploymentType === 'kIBMBaaS' ? basicClusterInfo.clusterDeploymentType : basicClusterInfo.softwareType;
  }


  /**
   * Getter method to get the active Locale by getting the value from the
   * subscription.
   */
  get activeLocale(): Locale {
    return this._activeLocale$.value;
  }

  /**
   * Returns the locale translate keys.
   *
   * @method   getTranslatedLocaleKey
   * @param    locale   The locale key.
   * @returns  The locale translate key.
   */
  getTranslatedLocaleKey(locale: Locale): string {
    // Locale translate keys are in the form of 'locale.en-us' etc.
    return this.translate.instant(`locale.${locale}`);
  }

  /**
   * Function which returns locales list with name and value of the locale.
   *
   * @method   getLocalesList
   * @returns  Array of the locales.
   */
  getLocalesList(): LocaleItem[] {
    return _map(Locales, (locale: Locale) => ({
      name: this.getTranslatedLocaleKey(locale),
      value: locale,
    }));
  }

  /**
   * Gets the user locale from the saved preferences. If nothing is saved then
   * return 'en-us' by default.
   *
   * @method   getUserLocale
   * @returns  Promise resolved with the locale.
   */
  getUserLocale(): Observable<Locale> {
    if (!this.featureFlagService.featureFlags.mcmUserPreferences
      && this.clusterService.basicClusterInfo?.mcmMode) {
        this._userLocale$.next(DefaultLocale);
        return of(DefaultLocale);
    }

    if(this.clusterService.basicClusterInfo?.mcmMode) {
      return this.userPreferencesService.GetUserPreferences().pipe(
        catchError(() => of({ locale: DefaultLocale })),
        map(preferences => preferences?.locale ?? DefaultLocale)).pipe(
          tap(locale => this._userLocale$.next(locale)));
    } else {
      return this.preferencesService.GetUserPreferences()
      .pipe(map(({ preferences }) => {
        // Set the user locale.
        this.userLocale = (get(preferences, 'locale') || DefaultLocale) as Locale;
        return this.userLocale;
      })).pipe(
        tap(locale => this._userLocale$.next(locale))
      );
    }
  }

  /**
   * Sets the user locale as a preference of the user.
   * NOTE: As this method is only being used in AJS, thus converting this to a
   * promise.
   *
   * @method   setUserLocale
   * @param    locale   The locale to be saved.
   * @deprecated Use CustomizationService.saveUserCustomizations instead.
   */
  setUserLocale(locale: Locale): Observable<any> {
    this.userLocale = locale;
    return this.preferencesService.UpdateUserPreferences({
      preferences: {locale}
    });
  }

  /**
   * Loads the language and date locale for the specified locale. This returns
   * when both have been loaded and will also update the activeLocale
   */
  loadLocale(locale: Locale = DefaultLocale, overrideParameter = 'kRegular'): Observable<boolean> {
    return forkJoin([
      // Load language text for legacy $rootScope.text
      this.loadLanguage(locale),
      // Load date locales
      this.loadDateLocale(locale),
      // Load any overrides for specific software type
      overrideParameter !== 'kRegular' ? this.loadLanguageOverride(locale, overrideParameter) : of({}),
    ]).pipe(
      tap(([messages, dateLocale, overrides]) => {
        this._activeLocale$.next(locale);
        const messagesWithOverrides = merge({}, messages, overrides);
        this._text$.next(messagesWithOverrides);
        this._dateLocale$.next(dateLocale);

        // Set the text on the translation loader so that angular-translate and
        // ngx-translate will have the correct values when they change their locale.
        this.translationLoader.translations = messagesWithOverrides;
      }),

      // After the translation text has been loaded have the translation services
      // load the new locales. The loaders will simply return the value of
      // translationLoader.translations.
      switchMap(() => forkJoin([
        // Load NG translate locale
        this.translate.use(locale),

        // Load AJS translate locale
        from(this.ajsTranslate.use(locale))
      ])),
      map(() => true)
    );
  }

  /**
   * Loads a language object from static/messages/
   *
   * @param      languageLocale   The languageLocale to load
   * @return     Returns an observable of the request to load a
   *             language file. Resolves with the text content.
   */
  private loadLanguage(languageLocale: Locale = DefaultLocale): Observable<any> {
    if (this.activeLocale === languageLocale) {
      return of(this._text$.value);
    }
    return this.http.get(this.getLanguageFilePath(languageLocale));
  }

  /**
   * Loads a language override file
   *
   * @param languageLocale The languageLocale to load
   * @param softwareType The software type
   * @return
   */
  private loadLanguageOverride(
    languageLocale: Locale = DefaultLocale,
    overrideParameter) {
    if (this.activeLocale === languageLocale) {
      return of({});
    }
    return this.http.get(this.getLanguageOverridePath(languageLocale, overrideParameter))
      .pipe(catchError(() => {
        if (isDevMode()) {
          console.warn(`There is no software type override file located at
            ${this.getLanguageOverridePath(languageLocale, overrideParameter)}`);
        }
        return of({});
      }));
  }

  /**
   * Returns the path to the language file for the given locale.
   *
   * @param languageLocale The locale for which to retrieve the language file.
   * @returns
   */
  public getLanguageFilePath(languageLocale: Locale = DefaultLocale) {
    return `messages/${languageLocale.toLowerCase()}${LangFile}`;
  }

  /**
   * Returns the path to the language file for the given locale and software type.
   *
   * @param languageLocale The locale for which to retrieve the language file.
   * @param softwareType The software type
   * @returns
   */
  public getLanguageOverridePath(languageLocale: Locale, overrideParameter) {
    return `messages/${languageLocale.toLowerCase()}${LanguageOverrideFile(overrideParameter)}`;
  }

  /**
   * Loads a date locale object from static/messages/
   *
   * @param    dateLocale   The languageLocale to load
   * @return   Returns an observable of the request to load a date locale file.
   */
  private loadDateLocale(dateLocale: Locale = DefaultLocale): Observable<any> {
    if (this.activeLocale === dateLocale) {
      return of(this._dateLocale$.value);
    }

    return this.http.get(`messages/${dateLocale.toLowerCase()}${LocaleFile}`);
  }
}

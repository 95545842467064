import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { EBSTag, ProtectionSourceNode, TagParams } from '@cohesity/api/v1';
import { AntlrSuggester, GetSuggestionFn, maybeEnquote, Result, TranslationResultFn } from '@cohesity/helix';
import { SourceTreeService } from '@cohesity/iris-source-tree';
import { TranslateService } from '@ngx-translate/core';
import { isEqual } from 'lodash-es';
import { of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, take, tap } from 'rxjs/operators';
import { AwsSourceDataNode } from 'src/app/shared/source-tree/protection-source/aws/aws-source-data-node';

import {
  BaseProtectionBuilderComponent,
} from '../../../../protection-builder/components/base-protection-builder/base-protection-builder.component';
import { SourceTreeState } from '../../../../protection-builder/components/form-controls';
import { ProtectionItemName } from '../../../../protection-builder/models';
import { ModifyProtectionService } from '../../../../protection-builder/services/modify-protection.service';
import { AveGrammarOpts } from './ave-grammar-opts/ave-grammar-opts';

interface VolumeExclusionParams {
  rawQuery?: string;
  tagParamsArray?: TagParams[];
}

const DefaultVolumexclusions: VolumeExclusionParams = {
  rawQuery: '',
  tagParamsArray: [],
};

@Component({
  selector: 'coh-settings-list-global-ebs-volume-exclusions',
  templateUrl: './settings-list-global-ebs-volume-exclusions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsListGlobalEbsVolumeExclusionsComponent extends
  BaseProtectionBuilderComponent<VolumeExclusionParams, any> implements OnInit {

  /**
   * Form Value.
   */
  _value: VolumeExclusionParams = DefaultVolumexclusions;

  /**
   * The raw query grammar options for advanced search component.
   */
  readonly aveGrammarOpts = new AveGrammarOpts();

  /**
   * The suggester class used to get suggestions for value formats based on grammar.
   */
  readonly antlrSuggester = new AntlrSuggester(this.aveGrammarOpts);

  /**
   * Arrays used for Tag Exclusions.
   */
  volumeTags: EBSTag[] = [];

  /**
   * Contains all possible keys attached to the EBS Volumes of a source.
   */
  volumeTagKeys: string[] = [];

  /**
   * Contains a map of Key -> Values for tags on EBS Volumes.
   */
  volumeKeyValuesMap: Map<string, string[]> = new Map<string, string[]>();

  /**
   * Form Group for Volume Exclusions.
   */
  volExclusionFormGroup = new UntypedFormGroup({
    rawQuery: new UntypedFormControl(''),
    tagParamsArray: new UntypedFormArray([]),
  });

  /**
   * Function to add to form group.
   */
  addFormControl() {
    this.formGroup.addControl(this.name, this.volExclusionFormGroup);
  }

  constructor(
    private modifyProtectionService: ModifyProtectionService,
    private translateService: TranslateService,
    public cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.initializeSearchQueryUpdate();
  }

  /**
   * Gets the current Tag Params array for the form control.
   */
  get tagParamsArray(): TagParams[] {
    return this.value.tagParamsArray as TagParams[];
  }

  /**
   * Sets the new Tag Params array for the form control.
   *
   * @param array The tagParams Array.
   */
  set tagParamsArray(array: TagParams[]) {
    this.value.tagParamsArray = array;
  }

  /**
   * Gets the current query string for the form control.
   */
  get rawQuery(): string {
    return this.value.rawQuery as string;
  }

  /**
   * Sets the new query string for the form control.
   */
  set rawQuery(query: string) {
    this.value.rawQuery = query;
  }

  /**
   * Get Tag Params Array Form Control.
   */
  get tagParamsArrayControl(): UntypedFormArray {
    return this.volExclusionFormGroup?.get('tagParamsArray') as UntypedFormArray;
  }

  /**
   * Get Raw Query Form Control.
   */
  get rawQueryControl(): UntypedFormControl {
    return this.volExclusionFormGroup?.get('rawQuery') as UntypedFormControl;
  }

  /**
   * Called upon search query input change
   */
  initializeSearchQueryUpdate() {
    this.rawQueryControl.valueChanges
      .pipe(
        this.untilDestroy(),
        distinctUntilChanged(),
        debounceTime(500),
      )
      .subscribe((query) => {
        this.rawQuery = query;
        this.tagParamsArray = this.aveGrammarOpts.tagParamsArray;
        this.setFormArrayVolumes();
        this.cdr.detectChanges();
      });
  }

  /**
   * Used to set Tag Params Form Array Control as there is no input field
   * in HTML for Array but it needs to be sent to the backend.
   */
  setFormArrayVolumes() {
    this.tagParamsArrayControl.clear();

    for (const tagParams of this.tagParamsArray) {
      const formArrayIncl = new UntypedFormArray(
        tagParams.inclusionTagArray.map(tag => new UntypedFormControl(tag))
      );
      const formArrayExcl = new UntypedFormArray(
        tagParams.exclusionTagArray.map(tag => new UntypedFormControl(tag))
      );
      const fg = new UntypedFormGroup({
        inclusionTagArray: formArrayIncl,
        exclusionTagArray: formArrayExcl,
      });
      this.tagParamsArrayControl.push(fg);
    }
  }

  /**
   * Fetch the suggested values for a given suggestion by advance search component.
   *
   * @param  suggestion The suggestion from advance search.
   * @return            Observable stream of updated suggestion with values.
   */
  getSuggestions: GetSuggestionFn = (suggestion) => {
    switch (true) {
      // return keys.
      case suggestion.field === 'key': {
        return of({
          ...suggestion,
          resultValues: this.volumeTagKeys.sort().map((key) => ({
            label: null,
            value: maybeEnquote(key, /=/i.test(key)),
          })),
        }).pipe(take(1));
      }

      // return selected key's values.
      case !!suggestion.field: {
        // Remove the quotes that may possibly have been added by getSuggestions function.
        const originalKey = suggestion.field.replace(/^["'`](.*)["'`]$/, '$1');

        // Check if the key exists in the map.
        if (this.volumeKeyValuesMap.has(originalKey)) {
          return of({
            ...suggestion,
            resultValues: this.volumeKeyValuesMap
              .get(originalKey)
              .sort()
              .map((value) => ({
                label: null,
                value: maybeEnquote(value, /=/i.test(value)),
              })),
          }).pipe(take(1));
        }
        return of(suggestion).pipe(take(1));
      }

      default: {
        return of(suggestion).pipe(take(1));
      }
    }
  };

  /**
   * Return the transaltion for a given input. Used by the advanced search component
   * to translate value formats.
   * Must be an arrow function to bind the context.
   *
   * @param  result The result object whose label needs to be translated.
   * @return        The translated text.
   */
  translationResult: TranslationResultFn = (result: Result): string =>
    this.translateService.instant(`ave.token.${result.label}`);

  /**
   * Used to combine all Volume Tags from Source Tree.
   *
   * @param  treeService The Source Tree Service.
   * @return             Tags on EBS Volumes.
   */
  combineVolumeTags(treeService: SourceTreeService<ProtectionSourceNode,
    AwsSourceDataNode>): EBSTag[] {
    const volumeTags: EBSTag[] = [];
    treeService.treeControl.allDataNodes.forEach(node => {
      if (node.ebsVolumeTags.length) {
        node.ebsVolumeTags.forEach(tag => volumeTags.push(tag));
      }
    });
    return volumeTags;
  }

  /**
   * For form edit state.
   */
  initFormControl() {
    this.value = {rawQuery: '', tagParamsArray: []};
    if (this.protectionGroup?.globalEbsVolumeExclusions) {
      let {rawQuery, tagParamsArray} =
        this.protectionGroup.globalEbsVolumeExclusions;

      rawQuery = rawQuery || '';
      tagParamsArray = tagParamsArray || [];

      this.value = {
        rawQuery: rawQuery,
        tagParamsArray: tagParamsArray
      };
    }

    // Whenever the source is changed, we need to change volume tags.
    this.modifyProtectionService.onControlChange(ProtectionItemName.Objects)
    .pipe(
      this.untilDestroy(),
      distinctUntilChanged(isEqual, (x) => x.sourceTree.data),
      filter(value => !!value.selection),
      tap(() => {
        this.volumeTags = [];
        this.volumeTagKeys = [];
        this.volumeKeyValuesMap.clear();
        this.rawQueryControl.reset('');
        this.tagParamsArrayControl.clear();
      }),
    )
    .subscribe((value: SourceTreeState) => {
      this.volumeTags = this.combineVolumeTags(value.sourceTree.treeService);
      this.volumeTagKeys = [...new Set(this.volumeTags.map(tag => tag.key))];
      this.volumeTags.forEach((tag: EBSTag) => {
        if (this.volumeKeyValuesMap.has(tag.key)) {
          if (!this.volumeKeyValuesMap.get(tag.key).includes(tag.value)) {
            this.volumeKeyValuesMap.get(tag.key).push(tag.value);
          }
        } else {
          this.volumeKeyValuesMap.set(tag.key, [tag.value]);
        }
      });
      this.rawQueryControl.reset(this.rawQuery);
      this.cdr.detectChanges();
    });
  }
}

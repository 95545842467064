import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';

import { DataIdModule } from '../data-id';
import { DateRangePickerModule } from '../date-range-picker';
import { IconModule } from '../icon';
import { TimeframePickerComponent } from './timeframe-picker.component';

@NgModule({
  imports: [
    CommonModule,
    DataIdModule,
    DateRangePickerModule,
    IconModule,
    MatButtonModule,
    MatMenuModule,
    TimeframePickerComponent,
  ],
  exports: [TimeframePickerComponent],
})
export class TimeframePickerModule {}

<form [formGroup]="formGroup">
  <div class="cog-modal-title dialog-title" mat-dialog-title>
    <button *ngIf="!config?.isNewConnection"
      mat-icon-button
      (click)="cancelForm()"
      cogDataId="cancel-saas-connection">
      <cog-icon shape="arrow_back"></cog-icon>
    </button>
    <span *ngIf="currentConnection; else createConnectionTitle"
      translate
      [translateParams]="{ name: currentConnection.groupName }">
      createName
    </span>
  </div>
  <p *ngIf="showIBMNewConnectionDesc" cogDataId="ibm-new-conn-desc" [innerHTML]="'ibm.createBackupServiceConnectionDesc' | translate : { link: helpLink }"></p>
  <mat-dialog-content>
    <ng-container *ngIf="verifying === 'NotDone'; else verification">
      <div class="connection-select-new">
        <!-- Region Selector -->
        <span translate="dms.regionTitle"></span>
        <mat-form-field>
          <mat-select [formControlName]="formControlNames.region" cogDataId="saas-region-select"
            (selectionChange)="handleRegion($event.value)">
            <mat-option cogDataId="saas-region-option-{{region.name}}"
              *ngFor="let region of availableRegions$ | async" [value]="region.id">
              {{region.id | translate}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="formGroupErrors?.region">
            {{ 'errors.required' | translate }}
          </mat-error>
        </mat-form-field>
        <a [class.port-disabled]="!(connectionService.portInformation$ | async)"
          (click)="viewPortInformation()">
          {{ (showPorts ? 'dms.port.hideRequirement' : 'dms.port.requirement') | translate}}
        </a>
      </div>
      <coh-connection-port-table *ngIf="showPorts" [ports]="connectionService.portInformation$ | async">
      </coh-connection-port-table>
      <!-- Connection Information -->
      <!-- 1. Show the connection token, when region is selected
        2. Enable the download button, when imageUrl is available. -->
      <div class="connection-steps">
        <div class="connection-step">
          <div class="step-number">1</div>
          <div class="step-content">
            <div class="step-title">
              {{ deployConnectorStepTitle }}
            </div>
            <div class="image-container">
              <!-- Hide deployment platform drop-down depending on source type -->
              <!-- Deployment Platform Selector -->
              <mat-form-field *ngIf="showDeploymentPlatform">
                <mat-label translate="dms.deploymentPlatformLabel"></mat-label>
                <mat-select [formControlName]="formControlNames.deploymentPlatform" cogDataId="saas-deploy-platform-select">
                  <mat-option cogDataId="saas-deploy-platform-option-{{platform.title}}"
                    *ngFor="let platform of deploymentPlatform" [value]="platform.value">
                    {{ platform.title | translate }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="formGroupErrors?.deploymentPlatform">
                  {{ 'errors.required' | translate }}
                </mat-error>
              </mat-form-field>
              <div>
                <button type="button" mat-button color="primary" cogDataId="copy-ova-url"
                  [disabled]="isImageUrlUnavailable" (click)="copyUrl()">
                  <cog-icon shape="content_copy"></cog-icon>
                  {{ copyUrlText }}
                </button>
                <span>|</span>
                <button type="button" mat-button color="primary" cogDataId="download-rigel-image"
                  [disabled]="isImageUrlUnavailable" (click)="downloadImage()">
                  <cog-icon shape="get_app"></cog-icon>
                  {{'downloadSaasConnector' | translate}}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="connection-step">
          <div class="step-number">2</div>
          <div class="step-content">
            <div class="step-title" translate>dms.tokenDesc</div>
            <coh-dms-token class="token-code" [value]="currentConnection?.claimToken" cogDataId="connection-token"
              placeholder="dms.waitingForToken" fileName="connection-token.txt" applicationType="application/txt"
              [tokenTimeout]="currentConnection?.tokenExpiryTimeUsecs / 1000000">
            </coh-dms-token>
          </div>
        </div>
      </div>

      <!-- Verify Connection -->
      <div class="step-sub-title" [innerHTML]="'dms.verifyConnection.desc' | translate"></div>
    </ng-container>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button *ngIf="verifying !== 'NotDone'"
      cogDataId="close-dialog"
      type="button"
      mat-flat-button
      color="primary"
      (click)="skipVerificationAndCloseDialog()">
      {{'close' | translate}}
    </button>

    <ng-container *ngIf="verifying === 'NotDone'">
      <button
        cogSubmitButton
        cogDataId="cancel-create-connection"
        class="cancel-create-connection-btn"
        type="button"
        mat-stroked-button
        color="primary"
        [loading]="connectionDeleting"
        (click)="cancel()">
        {{'cancel' | translate}}
      </button>
      <button
        cogDataId="close-dialog"
        type="button"
        mat-stroked-button
        color="primary"
        cdkFocusInitial
        [disabled]="!connectionCreated || !currentConnection?.groupId || connectionDeleting"
        (click)="skipVerificationAndCloseDialog()">
        {{'skipVerification' | translate}}
      </button>
      <button cogDataId="verify-connection"
        type="button"
        mat-flat-button
        color="primary"
        [disabled]="!currentConnection || isImageUrlUnavailable || connectionDeleting"
        (click)="verify()">
        {{'verifyConnection' | translate}}
      </button>
    </ng-container>
  </mat-dialog-actions>
</form>

<ng-template #verification>
  <div class="verification" *ngIf="verifying === 'InProgress'">
    <cog-spinner size="xs" class="waiting"></cog-spinner>
    <span class="waiting-text">{{'dms.waitingForConnection' | translate}}</span>
  </div>
  <div class="verification" *ngIf="verifying === 'Verified'">
    <cog-icon shape="helix:status-success!success" size="sm" class="verified-icn"></cog-icon>
    <span [innerHTML]="'dms.selectedRegion' | translate:{connectionName: currentConnection.groupName}">
    </span>
  </div>
  <div class="connection-actions">
    <button cohStopPropagation cogDataId="region-action-copy" mat-icon-button [matTooltip]="'copyToken' | translate"
      [disabled]="!currentConnection?.claimToken.length" (click)="connectionService.copyClaimToken()">
      <cog-icon shape="content_copy"></cog-icon>
    </button>
    <button cohStopPropagation cogDataId="region-action-download" mat-icon-button
      [matTooltip]="'dms.downloadRigelImage' | translate" [disabled]="isImageUrlUnavailable" (click)="downloadImage()">
      <cog-icon shape="get_app!outline"></cog-icon>
    </button>
    <button cohStopPropagation cogDataId="connection-action-edit" [matTooltip]="'editConnection' | translate"
      mat-icon-button (click)="editConnection()">
      <cog-icon shape="edit!outline"></cog-icon>
    </button>
  </div>
</ng-template>

<ng-template #createConnectionTitle let-ctx>
  <span translate>createSaasConnection</span>
</ng-template>

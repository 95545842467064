import { AppModuleConfig, CanAccess, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';
import { Environment } from 'src/app/shared/constants';
import { hasSourceModifyPrivilege } from 'src/app/shared/helper-utils';

// Removing the preceding `k` character to make it compatible with hasSourceModifyPrivilege().
const key = Environment.kMongoDBPhysical.slice(1).toLowerCase();
const modifyAccess: CanAccess = (ctx) => hasSourceModifyPrivilege(ctx, key);

const mongodbPhysicalServerModify = (ctx: StateAccessContext) =>
  ctx.FEATURE_FLAGS.ngProtectionGroupGlobalModify &&
  modifyAccess && ctx.workflow.backupAndRecovery.kMongoDBPhysical &&
  ctx.FEATURE_FLAGS.mongodbPhysicalProtection;

export const MongodbPhysicalConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'mongodbPhysical.**',
      url: '/protection/protect/register',
      loadChildren: () => import('./mongodb-physical.module').then(m => m.MongoDBPhysicalModule),
    }
  ],
  allClusterMap: {
  },
  getStateAccessMap(): StateAccessMap {
    return {
      'physicalServer.new': mongodbPhysicalServerModify,
      'physicalServer.edit': mongodbPhysicalServerModify,
    };
  },
};

import { AggregatedSubtreeInfo, ProtectionSourceNode } from '@cohesity/api/v1';
import { get } from 'lodash-es';
import { Environment, EnvironmentType, HostType, JOB_GROUPS, PhysicalEntityType } from 'src/app/shared/constants';

import { ConnectionState, HasConnectionState } from '../shared/behaviors/has-connection-state';
import { ProtectionSourceDataNode } from '../shared/protection-source-data-node';

/**
 * Represents an Physical block source node and job tree selection behavior.
 */
export class PhysicalSourceDataNode extends ProtectionSourceDataNode implements HasConnectionState {
  readonly asConnectionState = this as HasConnectionState;

  constructor(env: Environment, data: ProtectionSourceNode, readonly level: number) {
    super(env, data, level);
  }

  /**
   * The connection state status for the node.
   */
  get connectionState(): ConnectionState {
    // Using vmware kInaccessible connection state here to show physical agent status.
    // TODO(Anurag): Make separate interface for physical agents connection problem.
    return 'kInaccessible';
  }

  /**
   * Determines if the node is expandable or not.
   */
  get expandable(): boolean {
    return this.isRoot;
  }

  /**
   * Returns whether there is a connection error with the source.
   */
  get hasConnectionError(): boolean {
    return !!get(this.data, 'registrationInfo.refreshErrorMessage');
  }

  /**
   * Returns whether there is a registration error with the source.
   */
  get hasRegistrationError(): boolean {
    return !!get(this.data, 'registrationInfo.authenticationErrorMessage');
  }

  /**
   * Returns true if agent has connection problem.
   */
  get hasConnectionStateProblem(): boolean {
    return this.hasRegistrationError || this.hasConnectionError;
  }

  /**
   * Gets the host type of the node
   */
  get nodeHostType(): string {
    return this.isPhysicalLeafEntity ? this.envSource.hostType : '';
  }

  /**
   * Returns true if node if windows.
   */
  get isWindows(): boolean {
    return this.nodeHostType === HostType.kWindows;
  }

  /**
   * Returns true if node if linux.
   */
  get isLinux(): boolean {
    return this.nodeHostType === HostType.kLinux;
  }

  /**
   * Returns true if node if aix.
   */
  get isAix(): boolean {
    return this.nodeHostType === HostType.kAix;
  }

  /**
   * Returns true if node if linux.
   */
  get isSolaris(): boolean {
    return this.nodeHostType === HostType.kSolaris;
  }

  /**
   * Returns true if node is HPUX.
   */
  get isHPUX(): boolean {
    return this.nodeHostType === HostType.kHPUX;
  }

  /**
   * Returns true if node is VOS.
   */
  get isVOS(): boolean {
    return this.nodeHostType === HostType.kVOS;
  }

  /**
   * Returns true if node is Linux Cluster.
   */
  get isUnixCluster(): boolean {
    return this.envSource.type === PhysicalEntityType.kUnixCluster;
  }

  /**
   * Returns true if node is a root node.
   */
  get isRoot(): boolean {
    return this.envSource.type === 'kGroup';
  }

  /**
   * Returns if node is a physical leaf entity.
   */
  get isPhysicalLeafEntity(): boolean {
    return JOB_GROUPS.leafEntitiesForPhysicalJob.includes(this.envSource.type);
  }

  /**
   * The cluster type of the object, based on its environment..
   */
  get clusterSourceType(): string {
    return this.envSource.clusterSourceType;
  }

  /**
   * Gets protected environment of source.
   * Returns 'kPhysicalFiles' if source is protected by
   * only file-based job.
   */
  get protectedEnvironment(): EnvironmentType {
    let blockBasedExists = false, fileBasedExists = false;

    this.data.protectedSourcesSummary.forEach(
      protectedSummary => {
        if ((protectedSummary?.leavesCount || 0) > 0
          && protectedSummary.environment === Environment.kPhysical) {
          blockBasedExists = true;
        }

        if ((protectedSummary?.leavesCount || 0) > 0
          && protectedSummary.environment === Environment.kPhysicalFiles) {
          fileBasedExists = true;
        }
      }
    );

    if (blockBasedExists) {
      return Environment.kPhysical;
    } else if (fileBasedExists) {
      return Environment.kPhysicalFiles;
    } else {
      return null;
    }
  }

  /**
   * Gets the protection summary for the tree's environment.
   * Note that this uses the object's environment rather than the protection source's so that
   * information will match the tree's environment.
   */
  get protectedSummary(): AggregatedSubtreeInfo {
    return (this.data.protectedSourcesSummary || [])
      .find(summary => summary.environment === this.protectedEnvironment) || {};
  }

  /**
   * Gets the on protected objects summary for the tree's environment.
   * Note that this uses the object's environment rather than the protection source's so that
   * information will match the tree's environment.
   */
  get unprotectedSummary(): AggregatedSubtreeInfo {
    return (this.data.unprotectedSourcesSummary || [])
      .find(summary => summary.environment === this.protectedEnvironment) || {};
  }

  /**
   * Gets a count of protected objects for this environment.
   */
  get protectedCount(): number {
    return this.protectedSummary.leavesCount || 0;
  }

  /**
   * Gets a count of unprotected objects for this environment.
   */
  get unprotectedCount(): number {
    return this.unprotectedSummary.leavesCount || 0;
  }

  /**
   * True if all objects are protected or the object has an object protection spec applied to it.
   */
  get protected(): boolean {
    return (!!this.protectedCount && !this.unprotectedCount) || this.isObjectProtected;
  }

  /**
   * Auto select is not available for physical servers
   */
  canAutoSelect(): boolean {
    return false;
  }

  /**
   * Exclusion is not available for physical servers
   */
  canExclude(): boolean {
    return false;
  }
}

import { Injectable } from '@angular/core';
import { ProtectionSourceNode } from '@cohesity/api/v1';
import { DataTreeNode, DataTreeSelection, NavItem } from '@cohesity/helix';
import { flagEnabled, IrisContextService, isDmsScope } from '@cohesity/iris-core';
import { Environment, RecoveryAction } from '@cohesity/iris-shared-constants';
import { SourceSelection } from '@cohesity/iris-source-tree';
import { StateService } from '@uirouter/core';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { DialogService, PassthroughOptionsService, StateManagementService } from 'src/app/core/services';
import { AzureActionContextData, MenuProviderWithContextData } from 'src/app/modules/object-details-shared';
import { RestoreConfigService } from 'src/app/modules/restore/restore-shared';
import {
  AzureBackupType,
  AzureObjectTypeWorkloadMap,
  AzureSQLEntityTypes,
  AzureSupportedWorkloadType,
  CloudJobType,
} from 'src/app/shared';
import { ProtectionSourceDataNode } from 'src/app/shared/source-tree';

import { AzureObjectActionCreator } from './azure-object-action-creator';
import { ObjectActionOptions } from './object-action-options.model';
import { ObjectActionProvider } from './object-action-provider';
import { ObjectInfoService } from './object-info.service';
import { SimpleObjectInfo } from './object-menu-provider';
import { ObjectProtectAction } from './object-protect-action.constants';

/**
 * This is a simple implementation for construction object menu actions for Azure.
 */
@Injectable()
export class AzureObjectActionProvider extends ObjectActionProvider implements MenuProviderWithContextData<AzureActionContextData> {

  /**
   * The provider for this service is manually set up in object-actions-menu.service, which must provide
   * the list of providers as an array in the correct order. In order to maintain some kind of sanity,
   * the providers are listed here, they should match the order of the constructor args.
   */
  static azureObjectActionProviderDependencies = [
    ObjectInfoService,
    RestoreConfigService,
    StateManagementService,
    StateService,
    IrisContextService,
    AzureObjectActionCreator,
    PassthroughOptionsService,
    DialogService,
  ];

  /**
   * Holds additional context information for the current object relative to the source information
   */
  private serviceContextInfo$ = new BehaviorSubject<AzureActionContextData>(null);

  /**
   * Returns the Additional context data for an azure object containing relative object source details
   */
  private get contextData(): AzureActionContextData {
    return this.serviceContextInfo$.value;
  }

  /**
   * Observable for the context data
   */
  get contextData$(): Observable<AzureActionContextData> {
    return this.serviceContextInfo$.asObservable();
  }

  constructor(
    readonly objectStore: ObjectInfoService,
    readonly restoreConfig: RestoreConfigService,
    readonly stateManagementService: StateManagementService,
    readonly stateService: StateService,
    readonly irisContextService: IrisContextService,
    private azureActionCreator: AzureObjectActionCreator,
    private passthroughOptionsService: PassthroughOptionsService,
    private dialogService: DialogService,
  ) {
    super(objectStore, restoreConfig, stateManagementService, stateService, azureActionCreator, irisContextService);
  }


  /**
   * Determines if RecoverFiles action is to be enabled for action.
   *
   * @param action  The action to check.
   * @returns True if RecoverFiles action is to be enabled or if action is not applicable to FLR.
   */
  private filterRecoverFilesAction(action: NavItem) {
    const isDms = isDmsScope(this.irisContextService.irisContext);
    return (action.displayName === 'recoverFiles') ? !isDms ||
      (isDms && flagEnabled(this.irisContextService.irisContext, 'dmsAzureFileRecovery')) : true;
  }


  /**
   *  Returns an updated object with additional parameters required for determining applicable actions
   *
   * @param object  The protected object.
   * @returns List of available actions.
   * */
  getUpdatedAzureObject(object: SimpleObjectInfo): Observable<SimpleObjectInfo> {
    return this.objectStore.getObjectInfo(object.id, this.passthroughOptionsService.requestParams).pipe(
      map((info) => {
        if (info.item?.objectBackupConfiguration) {
          // TODO: Confirm need for line below on api availability
          // For Azure tags, Magneto doesn't send protection info in entity hierarchy.
          object.isProtected = true;

          object.protectionType = object.protectionType??
            info.item.objectBackupConfiguration.azureParams?.protectionType;
        }

        // If context is on-prem where protectionGroup applies,
        // the protection type is available on the snapshot detail and set
        if (!object.protectionType && object.useRestorePointSelection) {
          object.protectionType = object.restorePointSelection?.snapshot?.azureParams?.protectionType;
        }

        // Set Azure SQL workload type based on entity object type.
        if (AzureSQLEntityTypes.includes(object.objectType)) {
          object.workloadType = AzureSupportedWorkloadType.kSQL;
        }
        return object;
      })
    );
  }

  /**
   * Determines if Azure recovery action is application to the protection type.
   * Overrides implementation in base class
   *
   * @param actionType The recovery action type.
   * @param environment The environment.
   * @param protectionType The object protection type.
   *
   * @returns True if the config is set.
   */
  isRecoveryConfigSet(actionType: RecoveryAction, environment: string, protectionType?: string): boolean {
    const config = this.restoreConfig.recoveryFormOptions[environment];
    let recoveryActionAllowed = false;
    switch (protectionType) {
      case CloudJobType.kNative:
        recoveryActionAllowed = actionType === RecoveryAction.RecoverVMs || actionType === RecoveryAction.RecoverFiles;
        break;
      case CloudJobType.kSnapshotManager:
        recoveryActionAllowed = actionType === RecoveryAction.RecoverVMs;
        break;
      case Environment.kAzureSQL:
        recoveryActionAllowed = actionType === RecoveryAction.RecoverAzureSQL;
        break;
      case Environment.kAzureEntraID:
        recoveryActionAllowed = actionType === RecoveryAction.RecoverAzureEntraID;
        break;
    }

    return recoveryActionAllowed && Boolean(config?.[actionType]);
  }


  /**
   * Get list of actions available for an object.
   *
   * @param object  The protected object.
   * @returns List of available actions.
   */
  getObjectActions(object: SimpleObjectInfo): Observable<NavItem[]> {
    const isAzureSqlWorkload = object?.workloadType === AzureBackupType.kSQLDatabase;
    return this.getUpdatedAzureObject(object)
      .pipe(
        switchMap(updatedObject => combineLatest(
          [
            super.getObjectActions(updatedObject),
            this.getManageSaasConnectionAction(updatedObject)
          ])
        ),
        map(([baseActions, ...azureActions]) => [...baseActions, ...azureActions]),
        map(actions => actions.filter(action => !!action && this.filterRecoverFilesAction(action))),
        map(actions => actions.filter(action =>
          ['unprotect', 'runNow', 'protect', 'pauseFutureRuns', 'manageSaasConnections'].includes(action?.displayName) &&
            isAzureSqlWorkload ? object?.objectType === AzureBackupType.kSQLDatabase : true)
        ),
        map(actions => {
          const sortOrder = ['protect', 'recover', 'recoverFiles', 'runNow',
            'pauseFutureRuns', 'editProtection', 'unprotect', 'manageSaasConnections'];
          return actions.sort((it1, it2) => {
            let o1 = sortOrder.indexOf(it1.displayName);
            let o2 = sortOrder.indexOf(it2.displayName);
            if (o1 === -1) {
              o1 = sortOrder.length;
            }
            if (o2 === -1) {
              o2 = sortOrder.length;
            }
            return o1 - o2;
          });
        }),
      );
  }

  /**
   * Gets a list of nav item options available for one or more objects.
   *
   * @param   objects   The list of objects to get actions for
   * @returns Any applicable actions that can be run for all items
   */
  getBulkObjectActions(objects: SimpleObjectInfo[]): Observable<NavItem[]> {
    return combineLatest(
      objects.map(object => this.getUpdatedAzureObject(object))
    ).pipe(switchMap((updatedObjects: SimpleObjectInfo[]) => super.getBulkObjectActions(updatedObjects)));
  }

  /**
   * Returns true if the action exists and the user can access its state. It will also return true
   * if there is no state associated with the action.
   *
   * @param action  The action to check.
   * @returns True if the user can access the item.
   */
  filterActionByAccess(action: NavItem): boolean {
    return super.filterActionByAccess(action) && this.filterRecoverFilesAction(action);
  }

  /**
   * Creates and returns the edit protection action for protected Azure object.
   *
   * @param object The object to edit protection settings for.
   * @returns An observable, which yields the NavItem or Null.
   */
  getEditObjectProtectionAction(object: SimpleObjectInfo): Observable<NavItem> {
    if (!this.objectStore.getObjectInfo || !object.isProtected) {
      return of(null);
    }

    // If no object's have region ids, we are dealing with a cluster rather than on prem, and the
    // protected object actions do not apply.
    if (!object.regionId && !object.isObjectProtected) {
      return of(null);
    }

    return  this.objectStore.getObjectInfo(
      object.id,
      { accessClusterId: object.accessClusterId, regionId: object.regionId }
    ).pipe(
      filter(entry => !entry.loading),
      map(entry => entry.item),
      map(item => {
        if (!item || !item.objectBackupConfiguration || item?.objectBackupConfiguration?.isAutoProtectConfig) {
          return null;
        }

        let environment = Environment[item.objectBackupConfiguration.environment];
        let workloadType = (object.workloadType || object.objectType);
        const protectionType = item?.objectBackupConfiguration.azureParams?.protectionType;

        if (protectionType === Environment.kAzureSQL) {
          workloadType = AzureBackupType.kSQLDatabase;
          environment = Environment.kAzureSQL;
        } else if (protectionType === Environment.kAzureEntraID) {
          workloadType = AzureBackupType.kAzureEntraID;
          environment = Environment.kAzureEntraID;
        }

        return this.actionCreator.createEditObjectProtectionAction(
          environment,
          item.id,
          { accessClusterId: object.accessClusterId, regionId: object.regionId },
          false,
          false,
          workloadType
        );
      })
    );
  }

  /**
   * Gets a list of nav item options available for one or more objects. This assumes that
   * the selection is done within the context of a source tree, and will have additional
   * information about autoprotected, selected, or excluded items.
   *
   * @param   selection        The selected objects selection
   * @param   sourceSelection  The transformed api tree selection including excluded and special
   *                           params
   * @param   objectOptions    The object's action options
   * @returns Any applicable actions that can be run for all items
   */
  getBulkTreeObjectActions(
    selection: DataTreeSelection<DataTreeNode<ProtectionSourceNode>>,
    sourceSelection: SourceSelection,
    objectOptions: ObjectActionOptions = {}
  ): Observable<NavItem[]> {
    if (!selection || (!selection.selected.length && !selection.autoSelected.length)) {
      return of([]);
    }
    const workloadType = objectOptions.workloadType;
    const toObjectInfo = (object: ProtectionSourceDataNode) => ({
      id: object.protectionSource.id,
      environment: object.environment,

      // If a root node is selected, it will not have a parent source id, so we can just use its own id instead.
      sourceId: object.protectionSource.parentId || object.protectionSource.id,
      isProtected: object.protected,
      objectType: object.type,
      accessClusterId: objectOptions.accessClusterId,
      regionId: objectOptions.regionId,
      v1Object: object,
      workloadType: AzureObjectTypeWorkloadMap[object.type] ?? workloadType,
    });

    // For protection and recovery, we should remove the auto selected nodes from our list and include the specfic
    // auto protecte nodes instead. Otherwise, a selected non-leaf node will end up beingg auto protected in the tree
    // even though that is not the user's intent.
    // However, for protected object actions, we should include the selected nodes and _not_ the auto selected nodes,
    // since the user can perform actions directly on these nodes.
    const selectedLeafNodes = selection.selected.filter(node => node.isLeaf);
    const objects = [...selectedLeafNodes, ...selection.autoSelected];
    const objectInfos: SimpleObjectInfo[] = objects.map(toObjectInfo);
    const allInfos = selection.selected.map(toObjectInfo);

    return combineLatest([
      this.getBulkProtectAction(objectInfos, sourceSelection),
      this.getBulkRecoveryAction(objectInfos, RecoveryAction.RecoverVMs),
      this.getBulkRecoveryAction(objectInfos, RecoveryAction.RecoverAzureSQL),
      this.getBulkRecoveryAction(objectInfos, RecoveryAction.RecoverAzureEntraID),
      this.getBulkRecoveryAction(objectInfos, RecoveryAction.RecoverApps),
      this.getBulkRecoveryAction(objectInfos, RecoveryAction.RecoverNasVolume),
      this.getBulkRecoveryAction(objectInfos, RecoveryAction.RecoverObjects),
      this.getProtectedObjectAction(objectInfos, ObjectProtectAction.ProtectNow),
      this.getProtectedObjectAction(objectInfos, ObjectProtectAction.UnProtect),
      this.getProtectedObjectAction(objectInfos, ObjectProtectAction.Pause),
      this.getProtectedObjectAction(objectInfos, ObjectProtectAction.Resume),
      this.getDbAuthorizeAction(allInfos)
    ]).pipe(
      map(actions => actions.filter(action => this.filterActionByAccess(action))),
      map(actions => this.sortActions(actions))
    );
  }

  /**
   * Creates and returns authorize db action for Azure SQL Database
   *
   * @param objects The list of protected objects.
   * @returns An observable, which yields the NavItem or null.
   */
  getDbAuthorizeAction(objects: SimpleObjectInfo[]): Observable<NavItem | null> {
    const protectionSources = objects.map(obj => obj.v1Object?.protectionSource).filter(source =>
      ['kSQLServer', 'kSQLManagedInstance'].includes(source?.azureProtectionSource?.type));

    // Return null if it's not an SQL Server or feature flag is disabled.
    // Only applicable for DMaaS.
    if (!protectionSources?.length ||
      !this.objectStore.getProtectedObject ||
      !isDmsScope(this.irisContextService.irisContext) ||
      !flagEnabled(this.irisContextService.irisContext, 'dmsAzureSqlWorkload')) {
      return of(null);
    }

    return of(this.azureActionCreator.createDbAuthorizeAction(() =>
      this.dialogService.showDialog('azure-authorize-sql-db-dialog', protectionSources)
    ));
  }

  /**
   * Returns action for to manage saas connection for subscriptions
   *
   * @param object  The protected object.
   * @returns List of available actions.
   */
  getManageSaasConnectionAction(object: SimpleObjectInfo): Observable<NavItem> {
    const subscription = object?.v1Object?.protectionSource?.name;

    return this.contextData$.pipe(map((contextData) => {
      if (!contextData || !subscription || object.objectType !== 'kSubscription' || !isDmsScope(this.irisContextService.irisContext)) {
        return null;
      }
      const linkedSaasConnection = (contextData.connections ?? [])
        .some(conn => conn.rigelCloudInfraInfo?.azureRigelInfraInfo?.subscriptionId === subscription);
      const stateParams = {
        source: contextData?.sourceDetails?.azureParams?.azureTenantId,
        subscription,
        regionId: this.passthroughOptionsService.regionId,
      };

      return {
        id: `${linkedSaasConnection ? 'edit' : 'create'}-saas-connection`,
        displayName: 'manageSaasConnections',
        state: `dms-azure-connections.${linkedSaasConnection ? 'edit': 'create'}`,
        stateParams
      };
    }));

  }

  /**
   * Sets service context data to determine applicable object actions
   *
   * @param contextData The context data object
   */
  setContextData(contextData: AzureActionContextData): void {
    this.serviceContextInfo$.next(contextData);
  }

}

<div class="nav-item-menu-container">
<ng-container *ngFor="let item of exposedNavItemIcons; index as index">
  <button *ngIf="!item.href"
    mat-icon-button
    type="button"
    cogDataId="action-button-{{item.displayName}}"
    [inheritDataId]="true"
    [uiSref]="!item.disabled ? item.state : ''"
    [uiParams]="item.stateParams"
    [uiOptions]="item.stateOpts"
    [disabled]="item.disabled"
    [matTooltip]="item.displayName | translate"
    (click)="onClickNavItem(item)">
    <cog-icon [shape]="item.icon"></cog-icon>
  </button>

  <a *ngIf="item.href" href="{{item.href}}" target="_blank" id="{{item.displayName}}-nav-anchor">
    <button mat-icon-button type="button">
      <cog-icon [shape]="item.icon"></cog-icon>
    </button>
  </a>
</ng-container>

<ng-container *ngFor="let item of exposedNavItemButtons; index as index">
  <button *ngIf="!item.href"
    mat-flat-button
    type="button"
    color="primary"
    class="margin-right"
    cogDataId="action-button-{{item.displayName}}"
    [inheritDataId]="true"
    [uiSref]="!item.disabled ? item.state : ''"
    [uiParams]="item.stateParams"
    [uiOptions]="item.stateOpts"
    [disabled]="item.disabled"
    [matTooltip]="item.displayName | translate"
    (click)="onClickNavItem(item)">
    {{ item.displayName | translate }}
  </button>

  <a *ngIf="item.href" href="{{item.href}}" target="_blank" id="{{item.displayName}}-nav-anchor">
    <button *ngIf="!item.href"
      mat-flat-button
      type="button"
      color="primary">
      {{ item.displayName | translate }}
    </button>
  </a>
</ng-container>

<button [attr.aria-label]="ariaLabel || title"
  id="{{menuId}}"
  cogDataId="button"
  [inheritDataId]="true"
  class="nav-item-menu-button"
  mat-icon-button
  [title]="title"
  [matTooltip]="tooltip"
  [matMenuTriggerFor]="navItemMenu?.childMenu"
  (menuOpened)="isOpen = true"
  (menuClosed)="isOpen = false"
  type="button"
  *ngIf="unexposedNavItems?.length || matMenuItems?.length">
  <cog-icon [shape]="icon"></cog-icon>
</button>
<cog-actions-menu #navItemMenu
  [inheritDataId]="true"
  cogDataId="nav"
  class="nav-item-menu-panel"
  [itemTemplate]="itemTemplate"
  [navList]="unexposedNavItems"
  [size]="size"
  (enterKeyPressed)="onEnter($event)">
  <ng-content></ng-content>
</cog-actions-menu>

<ng-template #itemTemplate let-item>
  <a id="{{item.displayName}}-nav-anchor"
    *ngIf="!item.href"
    (click)="onClickNavItem(item)"
    [uiSref]="!item.subNavList?.length && !item.disabled ? item.state : ''"
    [uiParams]="item.stateParams"
    [uiOptions]="item.stateOpts">
    <cog-action-menu-item [item]="item" [showIcon]="showIconInDropdown" [size]="size">
      <span [innerHTML]="item.displayName | translate"></span>
    </cog-action-menu-item>
  </a>
  <a *ngIf="item.href" href="{{item.href}}" target="_blank" id="{{item.displayName}}-nav-anchor">
    <cog-action-menu-item [item]="item" [size]="size">
      <span [innerHTML]="item.displayName | translate"></span>
    </cog-action-menu-item>
  </a>
</ng-template>
</div>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

import { DataIdModule } from '../data-id/data-id.module';
import { IconModule } from '../icon/icon.module';
import { BannerModule } from './../banner';
import { AppBannerComponent } from './app-banner.component';

const components = [AppBannerComponent];

@NgModule({
  imports: [BannerModule, CommonModule, DataIdModule, IconModule, MatButtonModule, MatTooltipModule, ...components],
  exports: components,
})
export class AppBannerModule {}

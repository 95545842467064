import { Injectable } from '@angular/core';
import { Transition } from '@uirouter/core';
import { isEmpty } from 'lodash-es';
import { GuardPriority, GuardResult, StateGuard } from '../state-guard';

@Injectable({
  providedIn: 'root',
})
export class GlobalSearchGuard implements StateGuard {
  /**
   * This guard priority is set to App, which indicates it should run as part of
   * the main application after all bootstrapping and initializing is completed.
   */
  guardPriority = GuardPriority.App;

  /**
   * Match on transitions to global search.
   */
  matchCriteria = {
    to: 'ng-search',
  };

  /**
   * Guard to check global search state transitions.
   *
   * @param   transition  The UI router transition
   * @returns a redirect transition.
   */
  onStart(transition: Transition): GuardResult {
    if (transition.from().name === 'ng-search' && isEmpty(transition.paramsChanged())) {
      // Don't reload global search if no params have changed.
      // TODO: state.reload() will no longer work for global search
      return false;
    }
  }
}

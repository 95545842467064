// Controller: Cluster Setup, Cluster Details
// TODO(spencer): CLean up indentation

import { createNetmaskAddr } from '@cohesity/utils';

;(function(angular, undefined) {

  angular.module('C.clusterSetup')
    .controller(
      'clusterSetupDetailsController', clusterSetupDetailsControllerFn);

  function clusterSetupDetailsControllerFn($rootScope, $scope, $state,
    PartitionService, ClusterService, ngDialogService, FORMATS, evalAJAX, cUtils, cMessage,
    IP_FAMILY, FEATURE_FLAGS) {

    /**
     * Activation function
     */
    function activate() {

      if (!$scope.shared.discoveredNodes.length && $scope.setupMode) {
        $state.go('cluster-setup.detect');
      }

      $scope.forms = {
        clusterDetails: {},
        vip: {},
      };

      $scope.restoreSettingsFromLocalStorage();

      // If sharedCluster is not yet set, copy the default properties
      if (!$scope.shared.cluster.nodes) {
        $scope.shared.cluster =
          angular.copy($scope.clusterDefaultProperties);
      }

      $scope.numNodesSelected = $scope.getSelectedNodeCount();
    }

    $scope.FORMATS = FORMATS;
    $scope.IP_FAMILY = IP_FAMILY;

    // Default configuration object for bringup cluster API
    $scope.clusterDefaultProperties = {
      clusterName: null,
      ntpServers: [],
      dnsServers: [],
      domainNames: [],
      clusterGateway: null,
      clusterSubnetCidrLen: null,
      ipmiGateway: null,
      ipmiSubnetCidrLen: null,
      ipmiUsername: null,
      ipmiPassword: null,
      enableSoftwareEncryption: true,
      enableHardwareEncryption: false,
      metadataFaultTolerance: undefined,
      rotationalPolicy: 90,
      nodes: [],
      ntpAuthenticationEnabled: false
    };

    // Manual validation flags for c-input-tags
    $scope.invalidDnsServers = [];
    $scope.invalidDomainNames = [];
    $scope.invalidNtpServers = [];

    // ntp auth key for see-more.
    $scope.ntp = {
      //We want the NTP auth key see-more default closed.
      disableAuthKey: true,
    };
    $scope.ntpData = [];


    // c-vips directive two way binding model
    $scope.cluster = {
      vips: []
    };

    // List of failure options for ui-select
    $scope.failuresList = [
      {
        value: 0,
      },
      {
        value: 1,
      },
      {
        value: 2,
      }
    ];

    // For selection of cache policy
    $scope.isCachePolicySelected = false;

    // For selection of no resiliency policy
    $scope.isNoResiliencyPolicySelected = false;

    // Whether internal network conflict is there or not.
    $scope.internalNetworkConflict = false;

    // Internal network ip
    $scope.athenaSubnet = '192.168.0.0/16';

    // Internal network ip v4 and v6
    // Don't autofill with any value in case of IPv6 cluster
    $scope.athenaSubnetV4 = '';
    $scope.athenaSubnetV6 = '';

    /**
     * pass through function for adding a search domain
     * to cluster configuration.
     *
     * @param      {string}  str     the search domain to add
     */
    $scope.addDomainName = function addDomainName(str) {
      addUiSelectVal(str, $scope.shared.cluster.domainNames,
        $scope.invalidDomainNames);
    };

    /**
     * pass through function for adding a dns server
     * to cluster configuration
     *
     * @param      {string}  str     the dns server to add
     */
    $scope.addDnsServer = function addDnsServer(str) {
      // Clear invalid dns servers to clear error.
      $scope.invalidDnsServers = [];

      if (!str) {
        return;
      }
      // Clean ui select tag.
      str = cUtils.cleanUiSelectTag(str);
      if (!~$scope.shared.cluster.dnsServers.indexOf(str)) {
        if (($scope.shared.ipFamily === IP_FAMILY.IPv6 && (str.match($scope.FORMATS.IPv6) || str.match($scope.FORMATS.IPv4))) ||
          ($scope.shared.ipFamily === IP_FAMILY.IPv4 && str.match($scope.FORMATS.IPv4))) {
            $scope.shared.cluster.dnsServers.push(str);
          } else {
            $scope.invalidDnsServers.push(str);
        }
      }
    };

    /**
     * pass through function for adding an ntp server
     * to cluster configuration
     *
     * @param      {string}  str     the ntp server to add
     */
    $scope.addNtpServer = function addNtpServer(value) {
      // Clean out commas because ui-select doesn't do it
      value = cUtils.cleanUiSelectTag(value);
      if(value === null || value === '') {
        return;
      } else if (value.match(FORMATS.alphanumericPlus) || value.match(FORMATS.IPv4AndIPv6)) {
        $scope.addNTPAuthField(value);
        $scope.invalidNtpServers = [];
        return $scope.ntpData[$scope.ntpData.length - 1];
      } else {
        $scope.invalidNtpServers.push(value);
        return false;
      }
    };

    /**
     * Adds a string value to the provided array if not already present.
     * If invalid, adds the value to the provided invalid list
     *
     * @param      {string}  str     value to be added to arr
     * @param      {array}   arr     arr to add the str value to
     * @param      {array}  invalidArr  list of invalid values provided
     */
    function addUiSelectVal(str, arr, invalidArr) {
      if (!str) {
        return;
      }

      // clean up custom tokens that uiSelect leaves behind
      str = cUtils.cleanUiSelectTag(str);
      if (!~arr.indexOf(str)) {
        if (str.match($scope.FORMATS.alphanumericPlus) ||
          str.match($scope.FORMATS.IPv6)) {
          arr.push(str);
        } else {
          invalidArr.push(str);
        }
      }

    }

    /**
     * handles form submitting, calling the API to bring up the cluster
     */
    $scope.bringUpCluster = function bringUpCluster() {

      let v6present = false
      let v4present = false

      if ($scope.shared.ipFamily === IP_FAMILY.IPv4) {
        const appSubnet = cUtils.cidrToIpBits($scope.forms.clusterDetails.athenaSubnet.$modelValue);
        $scope.shared.cluster.appsSubnet = appSubnet[0];
        $scope.shared.cluster.appsSubnetMask = createNetmaskAddr(Number(appSubnet[1]));
      }else if ($scope.shared.ipFamily === IP_FAMILY.IPv6) {

        if($scope.forms.clusterDetails.athenaSubnetV4.$modelValue !== ''){
          const appSubnet = cUtils.cidrToIpBits($scope.forms.clusterDetails.athenaSubnetV4.$modelValue);
          $scope.shared.cluster.appsSubnet = appSubnet[0];
          $scope.shared.cluster.appsSubnetMask = createNetmaskAddr(Number(appSubnet[1]));
          v4present = true
        } else {
          delete $scope.shared.cluster.appsSubnet;
          delete $scope.shared.cluster.appsSubnetMask;
        }

        if($scope.forms.clusterDetails.athenaSubnetV6.$modelValue !== ''){
          const appSubnetV6 = cUtils.cidrToIpBits($scope.forms.clusterDetails.athenaSubnetV6.$modelValue);
          $scope.shared.cluster.appsSubnetV6 = appSubnetV6[0];
          $scope.shared.cluster.appsSubnetMaskV6 = appSubnetV6[1]?.toString();
          v6present = true
        } else {
           delete $scope.shared.cluster.appsSubnetV6;
           delete $scope.shared.cluster.appsSubnetMaskV6;
        }

        if(v6present !== v4present){
          cMessage.error({
            textKey: "Either provide both IPv4 and IPv6 app management network or none of them."
          });
          return;
        }
      }

      if ($scope.forms.clusterDetails.$invalid) {
        return;
      }

      // Show spinner while posting
      $scope.showSpinner = true;

      // Set New Cluster Node Count to 0 to support back button useage
      $scope.shared.newClusterNodeCount = 0;

      // Set cluster vips to the given range from c-vip
      $scope.shared.cluster.vips = $scope.cluster.vips;

      // Fips is enabled if encryption is enabled.
      $scope.shared.cluster.enableFipsMode = $scope.shared.cluster.enableSoftwareEncryption;

      // Set ip preference of cluster.
      $scope.shared.cluster.ipPreference = $scope.shared.ipFamily;

      // Convert cidr suffix length to string which is number in case of ipv6.
      $scope.shared.cluster.clusterSubnetCidrLen = String($scope.shared.cluster.clusterSubnetCidrLen);

      // Add ntp auth field
      if (!$scope.ntp.disableAuthKey) {
        $scope.shared.cluster.ntpServerAuthInfo = $scope.ntpData;
      }
      $scope.shared.cluster.ntpAuthenticationEnabled = !$scope.ntp.disableAuthKey;

      // Add ntpServers
      $scope.shared.cluster.ntpServers = $scope.ntpData.map(function(ntpData) {
        return ntpData.ntpServer;
      });

      $scope.shared.cluster.nodes = [];
      angular.forEach($scope.shared.discoveredNodes, function(node) {
        if (node.selected) {
          var nodeIp = $scope.shared.cluster.ipPreference === IP_FAMILY.IPv6 ?
            node._ipv6 : node._ipv4;
          $scope.shared.newClusterNodeCount++;
          if (!$scope.setupMode) {
            $scope.shared.cluster.nodes.push({
              id: node.nodeId,
              ip: $scope.isVirtualRobo ? $scope.shared.cluster.nodeIp : nodeIp,
              ipmiIp: node._ipmiIp,
              ipmiGateway: node._ipmiGateway,
              ipmiSubnetMask: node._ipmiSubnetMask,
            });
          } else {
            $scope.shared.cluster.nodes.push({
              id: node.nodeId,
              ip: $scope.isVirtualRobo ? $scope.shared.cluster.nodeIp : nodeIp,
              ipmiIp: node._ipmiIp
            });
          }
        }
      });

      // Add domainName to domainNames array if it does not already exist.
      if ($scope.shared.cluster.clusterDomain &&
        !$scope.shared.cluster.domainNames.includes($scope.shared.cluster.clusterDomain)) {
        $scope.shared.cluster.domainNames.unshift($scope.shared.cluster.clusterDomain);
      }

      if (!$rootScope.isVirtualEditionCluster) {
        $scope.shared.cluster.metadataFaultTolerance = undefined;
      }

      // Store setting values in local storage in case of errors
      $scope.storeSettingsInLocalStorage();
      ClusterService.bringupCluster($scope.shared.cluster).then(
          function bringupSuccess(response) {
            // Let's go to the next page
            $state.go('cluster-setup.confirm');
          },
          function bringupError(resp) {
            $scope.checkAppsSubnetErrorStatus(resp);
            cMessage.error({
              textKey: resp.data ?
                resp.data.message : clusterSetup.details.serviceNotRunning,
            });
          }
        )
        .finally(
          function afterBringUp() {
            // Hide Spinner
            $scope.showSpinner = false;
          }
        );
    };

    /**
     * Populate selected nodes from disocvered ndoes
     */
    $scope.getSelectedNodeCount = function getSelectedNodeCount() {
      let count = 0;
      angular.forEach($scope.shared.discoveredNodes, function(node) {
        if (node.selected) {
          count++;
        }
      });
      return count;
    };

    /**
     * Go back to previous state
     */
    $scope.goBack = function goBack() {
      var target = ($rootScope.isPhysicalCluster || $rootScope.isPhysicalRobo)?
        'cluster-setup.nodes' : 'cluster-setup.detect';
      $state.go(target);
    };

    /**
     * Reset Encryption Properties
     */
    $scope.resetEncryptionProps = function resetEncryptionProps() {
      $scope.shared.cluster.rotationalPolicy = 90;
    };

    /**
     * Add ntp auth field.
     */
    $scope.addNTPAuthField = function addNTPAuthField(ntpServerInput) {
      var ntpObject = {ntpServer: ntpServerInput, ntpServerAuthKeyId: null, ntpServerAuthKeyValue: null};
      $scope.ntpData.push(ntpObject);
    }

    /**
     * Remove ntp auth field.
     */
    $scope.removeNTPAuthField = function removeNTPAuthField(index) {
      if ($scope.ntpData.length > 1) {
        $scope.ntpData.splice(index, 1);
      }
    }

    /**
     * ntp Settings Toggle change
     */
    $scope.ntpSettingsToggleChange = function ntpSettingsToggleChange() {
      if ($scope.ntpData.length < 1) {
        $scope.addNTPAuthField('');
      }
    }

    /**
     * Ntp Settings for auth on ntp Server remove.
     */
    $scope.onNtpServerRemove = function onNtpServerRemove(ntpServer) {
      if ($scope.ntpData.length > 1) {
        var index = $scope.ntpData.findIndex(x => x.ntpServer === ntpServer);
        $scope.removeNTPAuthField(index);
        $scope.ntpData.splice(index, 1);
      }

    }

    $scope.changeCachePolicy = function changeCachePolicy() {
      $scope.isCachePolicySelected = !$scope.isCachePolicySelected;
    }

    $scope.changeNoResiliencyPolicy =  function changeNoResiliencyPolicy(event) {
      if ($scope.isNoResiliencyPolicySelected) {
        $scope.isNoResiliencyPolicySelected = !$scope.isNoResiliencyPolicySelected;
        return;
      }

      event.preventDefault();

      const dialogData = {
        copy: 'useNoResiliencyPolicy.warning',
        confirmButtonLabel: 'ok',
        declineButtonLabel: 'cancel'
      }

      ngDialogService.simpleDialog(null, dialogData)
        .toPromise()
        .then(function dialogResolved(confirmation) {
          if (confirmation) {
            $scope.isNoResiliencyPolicySelected = true;
          } else {
            $scope.isNoResiliencyPolicySelected = false;
          }
        });
    }
    /**
    * Checking whether the IP is within the Apps Subnet IP range.
    *
    * @param appIpStr    App IP Address
    * @param subNetMask  App subnet mask
    * @param matchIpStr  IP to match against the App CIDR
    * @returns true if the IP is matched else false
    */
    $scope.checkForIpMatch = function checkForIpMatch(appIpStr, subNetMask, matchIpStr) {
      const ipMatchCount = 4 - (subNetMask / 8);
      const appIpArr = appIpStr.split('.');
      const matchIpArr = matchIpStr.split('.');
      let isMatched = true;

      for (let i = 0; i < ipMatchCount; i++) {
        if (appIpArr[i] !== matchIpArr[i]) {
          isMatched = false;
        }
      }
      return isMatched;
    };

    $scope.checkAppsSubnetErrorStatus = function checkAppsSubnetErrorStatus(resp) {
      if (resp.data.message.toLowerCase().includes("apps subnet")) {
        $scope.internalNetworkConflict = true;
      }
    }
    activate();

  }

})(angular);

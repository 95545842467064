import { Pipe, PipeTransform } from '@angular/core';
import { escapeRegExp } from 'lodash-es';

/**
 * Highlight all occurrences of a given text in a value.
 *
 * @example
 * <span [innerHtml]="name | highlightText: 'text'"></span>
 */
@Pipe({
  name: 'highlightText'
})
export class HighlightTextPipe implements PipeTransform {
  /**
   * The transform function for highlight text pipe.
   *
   * @param value The string to highlight in.
   * @param text The string to highlight.
   * @param className Optional. Class name for the highlighted item.
   * @param parseAsterisk Optional. Whether to use "*" as a pattern or not.
   * @return Html of the string with the highlights.
   */
  transform(
    value: string,
    text: string,
    className = 'mat-body-strong',
    parseAsterisk = true,
  ): unknown {
    let regExp;

    if (parseAsterisk) {
      regExp = new RegExp(text.split('*').map(escapeRegExp).join('.*'), 'gi');
    } else {
      regExp = new RegExp(escapeRegExp(text), 'gi');
    }

    return value.replace(
      regExp,
      matched => `<span class="${className}">${matched}</span>`
    );
  }
}

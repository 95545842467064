/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { SearchIndexedObjectsResponseBody } from '../models/search-indexed-objects-response-body';
import { SearchIndexedObjectsRequest } from '../models/search-indexed-objects-request';
import { ObjectsSearchResponseBody } from '../models/objects-search-response-body';
import { ProtectedObjectsSearchResponseBody } from '../models/protected-objects-search-response-body';
import { HeliosSearchIndexedObjectsResponseBody } from '../models/helios-search-indexed-objects-response-body';
import { HeliosSearchIndexedObjectsRequest } from '../models/helios-search-indexed-objects-request';
@Injectable({
  providedIn: 'root',
})
class SearchService extends __BaseService {
  static readonly SearchIndexedObjectsPath = '/data-protect/search/indexed-objects';
  static readonly SearchObjectsPath = '/data-protect/search/objects';
  static readonly SearchProtectedObjectsPath = '/data-protect/search/protected-objects';
  static readonly GlobalSearchIndexedObjectsPath = '/mcm/search/indexed-objects';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * List indexed objects.
   *
   * List all the indexed objects like files and folders, emails, mailboxes etc., that match the specified search and filter criteria from protected objects.
   * @param params The `SearchService.SearchIndexedObjectsParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to search for indexed objects.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  SearchIndexedObjectsResponse(params: SearchService.SearchIndexedObjectsParams): __Observable<__StrictHttpResponse<SearchIndexedObjectsResponseBody>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/data-protect/search/indexed-objects`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SearchIndexedObjectsResponseBody>;
      })
    );
  }
  /**
   * List indexed objects.
   *
   * List all the indexed objects like files and folders, emails, mailboxes etc., that match the specified search and filter criteria from protected objects.
   * @param params The `SearchService.SearchIndexedObjectsParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to search for indexed objects.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  SearchIndexedObjects(params: SearchService.SearchIndexedObjectsParams): __Observable<SearchIndexedObjectsResponseBody> {
    return this.SearchIndexedObjectsResponse(params).pipe(
      __map(_r => _r.body as SearchIndexedObjectsResponseBody)
    );
  }

  /**
   * List Objects.
   *
   * List objects.
   * @param params The `SearchService.SearchObjectsParams` containing the following parameters:
   *
   * - `threatTags`: Specifies the threat tag's names to filter the tagged snapshots
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be returned.
   *
   * - `tagTypes`: Specifies the tag type to filter the objects and snapshots.
   *
   * - `tagSubCategories`: Specifies the tag subcategory to filter the objects and snapshots
   *
   * - `tagSearchName`: Specifies the tag name to filter the tagged objects and snapshots. User can specify a wildcard character '*' as a suffix to a string where all object's tag names are matched with the prefix string.
   *
   * - `tagNamesExcluded`: Specifies the tag names to not include in the tagged snapshots response
   *
   * - `tagNames`: Specifies the tag names to filter the tagged objects and snapshots only for non system tags
   *
   * - `tagCategories`: Specifies the tag category to filter the objects and snapshots.
   *
   * - `storageDomainIds`: Specifies the list of storage domain ids. Format is clusterId:clusterIncarnationId:storageDomainId. Only objects having protection in these storage domains will be returned.
   *
   * - `sourceUuids`: Specifies a list of Protection Source object uuids to filter the objects. If specified, the object which are present in those Sources will be returned.
   *
   * - `sourceIds`: Specifies a list of Protection Source object ids to filter the objects. If specified, the object which are present in those Sources will be returned.
   *
   * - `searchString`: Specifies the search string to filter the objects. This search string will be applicable for objectnames. User can specify a wildcard character '*' as a suffix to a string where all object names are matched with the prefix string. For example, if vm1 and vm2 are the names of objects, user can specify vm* to list the objects. If not specified, then all the objects will be returned which will match other filtering criteria.
   *
   * - `requestInitiatorType`: Specifies the type of request from UI, which is used for services like magneto to determine the priority of requests.
   *
   * - `regionIds`: Specifies a list of region ids. Only records from clusters having these region ids will be returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `protectionTypes`: Specifies the protection type to filter objects.
   *
   * - `protectionGroupIds`: Specifies a list of Protection Group ids to filter the objects. If specified, the objects protected by specified Protection Group ids will be returned.
   *
   * - `paginationCookie`: Specifies the pagination cookie with which subsequent parts of the response can be fetched.
   *
   * - `osTypes`: Specifies the operating system types to filter objects on.
   *
   * - `objectIds`: Specifies a list of Object ids to filter.
   *
   * - `o365ObjectTypes`: Specifies the object types to filter objects on. Only applicable if the environment is o365.
   *
   * - `mustHaveTagIds`: Specifies tags which must be all present in the document.
   *
   * - `mustHaveSnapshotTagIds`: Specifies snapshot tags which must be all present in the document.
   *
   * - `mightHaveTagIds`: Specifies list of tags, one or more of which might be present in
   *   the document. These are OR'ed together and the resulting criteria
   *   AND'ed with the rest of the query.
   *
   * - `mightHaveSnapshotTagIds`: Specifies list of snapshot tags, one or more of which might be
   *   present in the document. These are OR'ed together and the resulting
   *   criteria AND'ed with the rest of the query.
   *
   * - `lastRunStatusList`: Specifies a list of status of the object's last protection run. Only objects with last run status of these will be returned.
   *
   * - `isProtected`: Specifies the protection status of objects. If set to true, only protected objects will be returned. If set to false, only unprotected objects will be returned. If not specified, all objects will be returned.
   *
   * - `isDeleted`: If set to true, then objects which are deleted on atleast one cluster will be returned. If not set or set to false then objects which are registered on atleast one cluster are returned.
   *
   * - `includeTenants`: If true, the response will include Objects which belongs to all tenants which the current user has permission to see.
   *
   * - `includeHeliosTagInfoForObjects`: Specifies whether to include helios tags information for objects in response. Default value is false
   *
   * - `includeDeletedObjects`: Specifies whether to include deleted objects in response. These objects can't be protected but can be recovered. This field is deprecated.
   *
   * - `environments`: Specifies the environment type to filter objects.
   *
   * - `dataClassificationTags`: Specifies the Data classification's tag names to filter the tagged snapshots
   *
   * - `count`: Specifies the number of objects to be fetched for the specified pagination cookie.
   *
   * - `clusterIdentifiers`: Specifies the list of cluster identifiers. Format is clusterId:clusterIncarnationId. Only records from clusters having these identifiers will be returned.
   *
   * - `azureObjectTypes`: Specifies the object types to filter objects on. Only applicable if the environment is Azure.
   *
   * - `anomalyTags`: Specifies the Anomaly's tag names to filter the tagged snapshots
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  SearchObjectsResponse(params: SearchService.SearchObjectsParams): __Observable<__StrictHttpResponse<ObjectsSearchResponseBody>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.threatTags || []).forEach(val => {if (val != null) __params = __params.append('threatTags', val.toString())});
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    (params.tagTypes || []).forEach(val => {if (val != null) __params = __params.append('tagTypes', val.toString())});
    (params.tagSubCategories || []).forEach(val => {if (val != null) __params = __params.append('tagSubCategories', val.toString())});
    if (params.tagSearchName != null) __params = __params.set('tagSearchName', params.tagSearchName.toString());
    (params.tagNamesExcluded || []).forEach(val => {if (val != null) __params = __params.append('tagNamesExcluded', val.toString())});
    (params.tagNames || []).forEach(val => {if (val != null) __params = __params.append('tagNames', val.toString())});
    (params.tagCategories || []).forEach(val => {if (val != null) __params = __params.append('tagCategories', val.toString())});
    (params.storageDomainIds || []).forEach(val => {if (val != null) __params = __params.append('storageDomainIds', val.toString())});
    (params.sourceUuids || []).forEach(val => {if (val != null) __params = __params.append('sourceUuids', val.toString())});
    (params.sourceIds || []).forEach(val => {if (val != null) __params = __params.append('sourceIds', val.toString())});
    if (params.searchString != null) __params = __params.set('searchString', params.searchString.toString());
    if (params.requestInitiatorType != null) __headers = __headers.set('requestInitiatorType', params.requestInitiatorType.toString());
    (params.regionIds || []).forEach(val => {if (val != null) __params = __params.append('regionIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.protectionTypes || []).forEach(val => {if (val != null) __params = __params.append('protectionTypes', val.toString())});
    (params.protectionGroupIds || []).forEach(val => {if (val != null) __params = __params.append('protectionGroupIds', val.toString())});
    if (params.paginationCookie != null) __params = __params.set('paginationCookie', params.paginationCookie.toString());
    (params.osTypes || []).forEach(val => {if (val != null) __params = __params.append('osTypes', val.toString())});
    (params.objectIds || []).forEach(val => {if (val != null) __params = __params.append('objectIds', val.toString())});
    (params.o365ObjectTypes || []).forEach(val => {if (val != null) __params = __params.append('o365ObjectTypes', val.toString())});
    (params.mustHaveTagIds || []).forEach(val => {if (val != null) __params = __params.append('mustHaveTagIds', val.toString())});
    (params.mustHaveSnapshotTagIds || []).forEach(val => {if (val != null) __params = __params.append('mustHaveSnapshotTagIds', val.toString())});
    (params.mightHaveTagIds || []).forEach(val => {if (val != null) __params = __params.append('mightHaveTagIds', val.toString())});
    (params.mightHaveSnapshotTagIds || []).forEach(val => {if (val != null) __params = __params.append('mightHaveSnapshotTagIds', val.toString())});
    (params.lastRunStatusList || []).forEach(val => {if (val != null) __params = __params.append('lastRunStatusList', val.toString())});
    if (params.isProtected != null) __params = __params.set('isProtected', params.isProtected.toString());
    if (params.isDeleted != null) __params = __params.set('isDeleted', params.isDeleted.toString());
    if (params.includeTenants != null) __params = __params.set('includeTenants', params.includeTenants.toString());
    if (params.includeHeliosTagInfoForObjects != null) __params = __params.set('includeHeliosTagInfoForObjects', params.includeHeliosTagInfoForObjects.toString());
    if (params.includeDeletedObjects != null) __params = __params.set('includeDeletedObjects', params.includeDeletedObjects.toString());
    (params.environments || []).forEach(val => {if (val != null) __params = __params.append('environments', val.toString())});
    (params.dataClassificationTags || []).forEach(val => {if (val != null) __params = __params.append('dataClassificationTags', val.toString())});
    if (params.count != null) __params = __params.set('count', params.count.toString());
    (params.clusterIdentifiers || []).forEach(val => {if (val != null) __params = __params.append('clusterIdentifiers', val.toString())});
    (params.azureObjectTypes || []).forEach(val => {if (val != null) __params = __params.append('azureObjectTypes', val.toString())});
    (params.anomalyTags || []).forEach(val => {if (val != null) __params = __params.append('anomalyTags', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/search/objects`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ObjectsSearchResponseBody>;
      })
    );
  }
  /**
   * List Objects.
   *
   * List objects.
   * @param params The `SearchService.SearchObjectsParams` containing the following parameters:
   *
   * - `threatTags`: Specifies the threat tag's names to filter the tagged snapshots
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be returned.
   *
   * - `tagTypes`: Specifies the tag type to filter the objects and snapshots.
   *
   * - `tagSubCategories`: Specifies the tag subcategory to filter the objects and snapshots
   *
   * - `tagSearchName`: Specifies the tag name to filter the tagged objects and snapshots. User can specify a wildcard character '*' as a suffix to a string where all object's tag names are matched with the prefix string.
   *
   * - `tagNamesExcluded`: Specifies the tag names to not include in the tagged snapshots response
   *
   * - `tagNames`: Specifies the tag names to filter the tagged objects and snapshots only for non system tags
   *
   * - `tagCategories`: Specifies the tag category to filter the objects and snapshots.
   *
   * - `storageDomainIds`: Specifies the list of storage domain ids. Format is clusterId:clusterIncarnationId:storageDomainId. Only objects having protection in these storage domains will be returned.
   *
   * - `sourceUuids`: Specifies a list of Protection Source object uuids to filter the objects. If specified, the object which are present in those Sources will be returned.
   *
   * - `sourceIds`: Specifies a list of Protection Source object ids to filter the objects. If specified, the object which are present in those Sources will be returned.
   *
   * - `searchString`: Specifies the search string to filter the objects. This search string will be applicable for objectnames. User can specify a wildcard character '*' as a suffix to a string where all object names are matched with the prefix string. For example, if vm1 and vm2 are the names of objects, user can specify vm* to list the objects. If not specified, then all the objects will be returned which will match other filtering criteria.
   *
   * - `requestInitiatorType`: Specifies the type of request from UI, which is used for services like magneto to determine the priority of requests.
   *
   * - `regionIds`: Specifies a list of region ids. Only records from clusters having these region ids will be returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `protectionTypes`: Specifies the protection type to filter objects.
   *
   * - `protectionGroupIds`: Specifies a list of Protection Group ids to filter the objects. If specified, the objects protected by specified Protection Group ids will be returned.
   *
   * - `paginationCookie`: Specifies the pagination cookie with which subsequent parts of the response can be fetched.
   *
   * - `osTypes`: Specifies the operating system types to filter objects on.
   *
   * - `objectIds`: Specifies a list of Object ids to filter.
   *
   * - `o365ObjectTypes`: Specifies the object types to filter objects on. Only applicable if the environment is o365.
   *
   * - `mustHaveTagIds`: Specifies tags which must be all present in the document.
   *
   * - `mustHaveSnapshotTagIds`: Specifies snapshot tags which must be all present in the document.
   *
   * - `mightHaveTagIds`: Specifies list of tags, one or more of which might be present in
   *   the document. These are OR'ed together and the resulting criteria
   *   AND'ed with the rest of the query.
   *
   * - `mightHaveSnapshotTagIds`: Specifies list of snapshot tags, one or more of which might be
   *   present in the document. These are OR'ed together and the resulting
   *   criteria AND'ed with the rest of the query.
   *
   * - `lastRunStatusList`: Specifies a list of status of the object's last protection run. Only objects with last run status of these will be returned.
   *
   * - `isProtected`: Specifies the protection status of objects. If set to true, only protected objects will be returned. If set to false, only unprotected objects will be returned. If not specified, all objects will be returned.
   *
   * - `isDeleted`: If set to true, then objects which are deleted on atleast one cluster will be returned. If not set or set to false then objects which are registered on atleast one cluster are returned.
   *
   * - `includeTenants`: If true, the response will include Objects which belongs to all tenants which the current user has permission to see.
   *
   * - `includeHeliosTagInfoForObjects`: Specifies whether to include helios tags information for objects in response. Default value is false
   *
   * - `includeDeletedObjects`: Specifies whether to include deleted objects in response. These objects can't be protected but can be recovered. This field is deprecated.
   *
   * - `environments`: Specifies the environment type to filter objects.
   *
   * - `dataClassificationTags`: Specifies the Data classification's tag names to filter the tagged snapshots
   *
   * - `count`: Specifies the number of objects to be fetched for the specified pagination cookie.
   *
   * - `clusterIdentifiers`: Specifies the list of cluster identifiers. Format is clusterId:clusterIncarnationId. Only records from clusters having these identifiers will be returned.
   *
   * - `azureObjectTypes`: Specifies the object types to filter objects on. Only applicable if the environment is Azure.
   *
   * - `anomalyTags`: Specifies the Anomaly's tag names to filter the tagged snapshots
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  SearchObjects(params: SearchService.SearchObjectsParams): __Observable<ObjectsSearchResponseBody> {
    return this.SearchObjectsResponse(params).pipe(
      __map(_r => _r.body as ObjectsSearchResponseBody)
    );
  }

  /**
   * List Protected Objects.
   *
   * List protected objects and corresponding detail information from registered sources filtered by specified query parameters. If no search pattern or filter parameters are specified, all protected objects currently found are returned.
   * @param params The `SearchService.SearchProtectedObjectsParams` containing the following parameters:
   *
   * - `useCachedData`: Specifies whether we can serve the GET request to the read replica cache cache. There is a lag of 15 seconds between the read replica and primary data source.
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be returned.
   *
   * - `subResultSize`: Specifies the size of objects to be fetched for a single subresult.
   *
   * - `storageDomainIds`: Specifies the Storage Domain ids to filter objects for which Protection Groups are writing data to Cohesity Views on the specified Storage Domains.
   *
   * - `sourceIds`: Specifies a list of Protection Source object ids to filter the objects. If specified, the object which are present in those Sources will be returned.
   *
   * - `snapshotActions`: Specifies a list of recovery actions. Only snapshots that applies to these actions will be returned.
   *
   * - `searchString`: Specifies the search string to filter the objects. This search string will be applicable for objectnames and Protection Group names. User can specify a wildcard character '*' as a suffix to a string where all object and their Protection Group names are matched with the prefix string. For example, if vm1 and vm2 are the names of objects, user can specify vm* to list the objects. If not specified, then all the objects with Protection Groups will be returned which will match other filtering criteria.
   *
   * - `runInstanceIds`: Specifies a list of run instance ids. If specified only objects belonging to the provided run id will be retunrned.
   *
   * - `requestInitiatorType`: Specifies the type of request from UI, which is used for services like magneto to determine the priority of requests.
   *
   * - `regionIds`: Specifies a list of region ids. Only records from clusters having these region ids will be returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `protectionGroupIds`: Specifies a list of Protection Group ids to filter the objects. If specified, the objects protected by specified Protection Group ids will be returned.
   *
   * - `osTypes`: Specifies the operating system types to filter objects on.
   *
   * - `objectIds`: Specifies a list of Object ids to filter.
   *
   * - `objectActionKey`: Filter by ObjectActionKey, which uniquely represents protection of an object. An object can be protected in multiple ways but atmost once for a given combination of ObjectActionKey. When specified, latest snapshot info matching the objectActionKey is for corresponding object.
   *
   * - `includeTenants`: If true, the response will include Objects which belongs to all tenants which the current user has permission to see.
   *
   * - `filterSnapshotToUsecs`: Specifies the timestamp in Unix time epoch in microseconds to filter the objects if the Object has a successful snapshot before this value.
   *
   * - `filterSnapshotFromUsecs`: Specifies the timestamp in Unix time epoch in microseconds to filter the objects if the Object has a successful snapshot after this value.
   *
   * - `environments`: Specifies the environment type to filter objects.
   *
   * - `cdpProtectedOnly`: Specifies whether to only return the CDP protected objects.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  SearchProtectedObjectsResponse(params: SearchService.SearchProtectedObjectsParams): __Observable<__StrictHttpResponse<ProtectedObjectsSearchResponseBody>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.useCachedData != null) __params = __params.set('useCachedData', params.useCachedData.toString());
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.subResultSize != null) __params = __params.set('subResultSize', params.subResultSize.toString());
    (params.storageDomainIds || []).forEach(val => {if (val != null) __params = __params.append('storageDomainIds', val.toString())});
    (params.sourceIds || []).forEach(val => {if (val != null) __params = __params.append('sourceIds', val.toString())});
    (params.snapshotActions || []).forEach(val => {if (val != null) __params = __params.append('snapshotActions', val.toString())});
    if (params.searchString != null) __params = __params.set('searchString', params.searchString.toString());
    (params.runInstanceIds || []).forEach(val => {if (val != null) __params = __params.append('runInstanceIds', val.toString())});
    if (params.requestInitiatorType != null) __headers = __headers.set('requestInitiatorType', params.requestInitiatorType.toString());
    (params.regionIds || []).forEach(val => {if (val != null) __params = __params.append('regionIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.protectionGroupIds || []).forEach(val => {if (val != null) __params = __params.append('protectionGroupIds', val.toString())});
    (params.osTypes || []).forEach(val => {if (val != null) __params = __params.append('osTypes', val.toString())});
    (params.objectIds || []).forEach(val => {if (val != null) __params = __params.append('objectIds', val.toString())});
    if (params.objectActionKey != null) __params = __params.set('objectActionKey', params.objectActionKey.toString());
    if (params.includeTenants != null) __params = __params.set('includeTenants', params.includeTenants.toString());
    if (params.filterSnapshotToUsecs != null) __params = __params.set('filterSnapshotToUsecs', params.filterSnapshotToUsecs.toString());
    if (params.filterSnapshotFromUsecs != null) __params = __params.set('filterSnapshotFromUsecs', params.filterSnapshotFromUsecs.toString());
    (params.environments || []).forEach(val => {if (val != null) __params = __params.append('environments', val.toString())});
    if (params.cdpProtectedOnly != null) __params = __params.set('cdpProtectedOnly', params.cdpProtectedOnly.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/search/protected-objects`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProtectedObjectsSearchResponseBody>;
      })
    );
  }
  /**
   * List Protected Objects.
   *
   * List protected objects and corresponding detail information from registered sources filtered by specified query parameters. If no search pattern or filter parameters are specified, all protected objects currently found are returned.
   * @param params The `SearchService.SearchProtectedObjectsParams` containing the following parameters:
   *
   * - `useCachedData`: Specifies whether we can serve the GET request to the read replica cache cache. There is a lag of 15 seconds between the read replica and primary data source.
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be returned.
   *
   * - `subResultSize`: Specifies the size of objects to be fetched for a single subresult.
   *
   * - `storageDomainIds`: Specifies the Storage Domain ids to filter objects for which Protection Groups are writing data to Cohesity Views on the specified Storage Domains.
   *
   * - `sourceIds`: Specifies a list of Protection Source object ids to filter the objects. If specified, the object which are present in those Sources will be returned.
   *
   * - `snapshotActions`: Specifies a list of recovery actions. Only snapshots that applies to these actions will be returned.
   *
   * - `searchString`: Specifies the search string to filter the objects. This search string will be applicable for objectnames and Protection Group names. User can specify a wildcard character '*' as a suffix to a string where all object and their Protection Group names are matched with the prefix string. For example, if vm1 and vm2 are the names of objects, user can specify vm* to list the objects. If not specified, then all the objects with Protection Groups will be returned which will match other filtering criteria.
   *
   * - `runInstanceIds`: Specifies a list of run instance ids. If specified only objects belonging to the provided run id will be retunrned.
   *
   * - `requestInitiatorType`: Specifies the type of request from UI, which is used for services like magneto to determine the priority of requests.
   *
   * - `regionIds`: Specifies a list of region ids. Only records from clusters having these region ids will be returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `protectionGroupIds`: Specifies a list of Protection Group ids to filter the objects. If specified, the objects protected by specified Protection Group ids will be returned.
   *
   * - `osTypes`: Specifies the operating system types to filter objects on.
   *
   * - `objectIds`: Specifies a list of Object ids to filter.
   *
   * - `objectActionKey`: Filter by ObjectActionKey, which uniquely represents protection of an object. An object can be protected in multiple ways but atmost once for a given combination of ObjectActionKey. When specified, latest snapshot info matching the objectActionKey is for corresponding object.
   *
   * - `includeTenants`: If true, the response will include Objects which belongs to all tenants which the current user has permission to see.
   *
   * - `filterSnapshotToUsecs`: Specifies the timestamp in Unix time epoch in microseconds to filter the objects if the Object has a successful snapshot before this value.
   *
   * - `filterSnapshotFromUsecs`: Specifies the timestamp in Unix time epoch in microseconds to filter the objects if the Object has a successful snapshot after this value.
   *
   * - `environments`: Specifies the environment type to filter objects.
   *
   * - `cdpProtectedOnly`: Specifies whether to only return the CDP protected objects.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  SearchProtectedObjects(params: SearchService.SearchProtectedObjectsParams): __Observable<ProtectedObjectsSearchResponseBody> {
    return this.SearchProtectedObjectsResponse(params).pipe(
      __map(_r => _r.body as ProtectedObjectsSearchResponseBody)
    );
  }

  /**
   * Search for indexed objects.
   *
   * Search for indexed objects like files, emails etc across clusters.
   * @param body Specifies the parameters to search for indexed objects.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  GlobalSearchIndexedObjectsResponse(body: HeliosSearchIndexedObjectsRequest,
    regionId?: string): __Observable<__StrictHttpResponse<HeliosSearchIndexedObjectsResponseBody>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/mcm/search/indexed-objects`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HeliosSearchIndexedObjectsResponseBody>;
      })
    );
  }
  /**
   * Search for indexed objects.
   *
   * Search for indexed objects like files, emails etc across clusters.
   * @param body Specifies the parameters to search for indexed objects.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  GlobalSearchIndexedObjects(body: HeliosSearchIndexedObjectsRequest,
    regionId?: string): __Observable<HeliosSearchIndexedObjectsResponseBody> {
    return this.GlobalSearchIndexedObjectsResponse(body, regionId).pipe(
      __map(_r => _r.body as HeliosSearchIndexedObjectsResponseBody)
    );
  }
}

module SearchService {

  /**
   * Parameters for SearchIndexedObjects
   */
  export interface SearchIndexedObjectsParams {

    /**
     * Specifies the parameters to search for indexed objects.
     */
    body: SearchIndexedObjectsRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for SearchObjects
   */
  export interface SearchObjectsParams {

    /**
     * Specifies the threat tag's names to filter the tagged snapshots
     */
    threatTags?: Array<'Threats Matched' | 'No Threats Matched'>;

    /**
     * TenantIds contains ids of the tenants for which objects are to be returned.
     */
    tenantIds?: Array<string>;

    /**
     * Specifies the tag type to filter the objects and snapshots.
     */
    tagTypes?: Array<'System' | 'Custom' | 'ThirdParty'>;

    /**
     * Specifies the tag subcategory to filter the objects and snapshots
     */
    tagSubCategories?: Array<'Classification' | 'Threats' | 'Anomalies' | 'Dspm'>;

    /**
     * Specifies the tag name to filter the tagged objects and snapshots. User can specify a wildcard character '*' as a suffix to a string where all object's tag names are matched with the prefix string.
     */
    tagSearchName?: string;

    /**
     * Specifies the tag names to not include in the tagged snapshots response
     */
    tagNamesExcluded?: Array<string>;

    /**
     * Specifies the tag names to filter the tagged objects and snapshots only for non system tags
     */
    tagNames?: Array<string>;

    /**
     * Specifies the tag category to filter the objects and snapshots.
     */
    tagCategories?: Array<'Security'>;

    /**
     * Specifies the list of storage domain ids. Format is clusterId:clusterIncarnationId:storageDomainId. Only objects having protection in these storage domains will be returned.
     */
    storageDomainIds?: Array<string>;

    /**
     * Specifies a list of Protection Source object uuids to filter the objects. If specified, the object which are present in those Sources will be returned.
     */
    sourceUuids?: Array<string>;

    /**
     * Specifies a list of Protection Source object ids to filter the objects. If specified, the object which are present in those Sources will be returned.
     */
    sourceIds?: Array<number>;

    /**
     * Specifies the search string to filter the objects. This search string will be applicable for objectnames. User can specify a wildcard character '*' as a suffix to a string where all object names are matched with the prefix string. For example, if vm1 and vm2 are the names of objects, user can specify vm* to list the objects. If not specified, then all the objects will be returned which will match other filtering criteria.
     */
    searchString?: string;

    /**
     * Specifies the type of request from UI, which is used for services like magneto to determine the priority of requests.
     */
    requestInitiatorType?: 'UIUser' | 'UIAuto' | 'Helios';

    /**
     * Specifies a list of region ids. Only records from clusters having these region ids will be returned.
     */
    regionIds?: Array<string>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies the protection type to filter objects.
     */
    protectionTypes?: Array<'kAgent' | 'kNative' | 'kSnapshotManager' | 'kRDSSnapshotManager' | 'kAuroraSnapshotManager' | 'kAwsS3' | 'kAwsRDSPostgresBackup' | 'kAwsAuroraPostgres' | 'kAwsDynamoDB' | 'kAwsRDSPostgres' | 'kAzureSQL' | 'kAzureEntraID' | 'kFile' | 'kVolume'>;

    /**
     * Specifies a list of Protection Group ids to filter the objects. If specified, the objects protected by specified Protection Group ids will be returned.
     */
    protectionGroupIds?: Array<string>;

    /**
     * Specifies the pagination cookie with which subsequent parts of the response can be fetched.
     */
    paginationCookie?: string;

    /**
     * Specifies the operating system types to filter objects on.
     */
    osTypes?: Array<'kLinux' | 'kWindows' | 'kAix' | 'kSolaris' | 'kSapHana' | 'kOther' | 'kHPUX' | 'kVOS'>;

    /**
     * Specifies a list of Object ids to filter.
     */
    objectIds?: Array<number>;

    /**
     * Specifies the object types to filter objects on. Only applicable if the environment is o365.
     */
    o365ObjectTypes?: Array<'kDomain' | 'kOutlook' | 'kMailbox' | 'kUsers' | 'kGroups' | 'kSites' | 'kUser' | 'kGroup' | 'kSite' | 'kApplication' | 'kGraphUser' | 'kPublicFolders' | 'kPublicFolder' | 'kTeams' | 'kTeam' | 'kRootPublicFolder' | 'kO365Exchange' | 'kO365OneDrive' | 'kO365Sharepoint'>;

    /**
     * Specifies tags which must be all present in the document.
     */
    mustHaveTagIds?: Array<string>;

    /**
     * Specifies snapshot tags which must be all present in the document.
     */
    mustHaveSnapshotTagIds?: Array<string>;

    /**
     * Specifies list of tags, one or more of which might be present in
     * the document. These are OR'ed together and the resulting criteria
     * AND'ed with the rest of the query.
     */
    mightHaveTagIds?: Array<string>;

    /**
     * Specifies list of snapshot tags, one or more of which might be
     * present in the document. These are OR'ed together and the resulting
     * criteria AND'ed with the rest of the query.
     */
    mightHaveSnapshotTagIds?: Array<string>;

    /**
     * Specifies a list of status of the object's last protection run. Only objects with last run status of these will be returned.
     */
    lastRunStatusList?: Array<'Accepted' | 'Running' | 'Canceled' | 'Canceling' | 'Failed' | 'Missed' | 'Succeeded' | 'SucceededWithWarning' | 'OnHold' | 'Finalizing' | 'Skipped'>;

    /**
     * Specifies the protection status of objects. If set to true, only protected objects will be returned. If set to false, only unprotected objects will be returned. If not specified, all objects will be returned.
     */
    isProtected?: boolean;

    /**
     * If set to true, then objects which are deleted on atleast one cluster will be returned. If not set or set to false then objects which are registered on atleast one cluster are returned.
     */
    isDeleted?: boolean;

    /**
     * If true, the response will include Objects which belongs to all tenants which the current user has permission to see.
     */
    includeTenants?: boolean;

    /**
     * Specifies whether to include helios tags information for objects in response. Default value is false
     */
    includeHeliosTagInfoForObjects?: boolean;

    /**
     * Specifies whether to include deleted objects in response. These objects can't be protected but can be recovered. This field is deprecated.
     */
    includeDeletedObjects?: boolean;

    /**
     * Specifies the environment type to filter objects.
     */
    environments?: Array<'kVMware' | 'kHyperV' | 'kAzure' | 'kKVM' | 'kAWS' | 'kAzureSQL' | 'kAzureEntraID' | 'kAcropolis' | 'kGCP' | 'kPhysical' | 'kPhysicalFiles' | 'kIsilon' | 'kNetapp' | 'kGenericNas' | 'kFlashBlade' | 'kElastifile' | 'kGPFS' | 'kPure' | 'kIbmFlashSystem' | 'kNimble' | 'kSQL' | 'kOracle' | 'kExchange' | 'kAD' | 'kView' | 'kO365' | 'kHyperFlex' | 'kKubernetes' | 'kCassandra' | 'kMongoDB' | 'kCouchbase' | 'kHdfs' | 'kHive' | 'kHBase' | 'kUDA' | 'kSfdc' | 'kSAPHANA'>;

    /**
     * Specifies the Data classification's tag names to filter the tagged snapshots
     */
    dataClassificationTags?: Array<'High Sensitivity Detected' | 'Medium Sensitivity Detected' | 'Low Sensitivity Detected' | 'No Sensitivity Detected'>;

    /**
     * Specifies the number of objects to be fetched for the specified pagination cookie.
     */
    count?: number;

    /**
     * Specifies the list of cluster identifiers. Format is clusterId:clusterIncarnationId. Only records from clusters having these identifiers will be returned.
     */
    clusterIdentifiers?: Array<string>;

    /**
     * Specifies the object types to filter objects on. Only applicable if the environment is Azure.
     */
    azureObjectTypes?: Array<'kTenant' | 'kSubscription' | 'kResourceGroup' | 'kVirtualMachine' | 'kStorageAccount' | 'kStorageKey' | 'kStorageContainer' | 'kStorageBlob' | 'kApplicationSecurityGroup' | 'kNetworkSecurityGroup' | 'kVirtualNetwork' | 'kSubnet' | 'kComputeOptions' | 'kSnapshotManagerPermit' | 'kTag' | 'kAvailabilitySet' | 'kSQLServer' | 'kSQLDatabase'>;

    /**
     * Specifies the Anomaly's tag names to filter the tagged snapshots
     */
    anomalyTags?: Array<'Critical Ransomware Detected' | 'Warning Ransomware Detected' | 'No Ransomware Detected'>;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for SearchProtectedObjects
   */
  export interface SearchProtectedObjectsParams {

    /**
     * Specifies whether we can serve the GET request to the read replica cache cache. There is a lag of 15 seconds between the read replica and primary data source.
     */
    useCachedData?: boolean;

    /**
     * TenantIds contains ids of the tenants for which objects are to be returned.
     */
    tenantIds?: Array<string>;

    /**
     * Specifies the size of objects to be fetched for a single subresult.
     */
    subResultSize?: number;

    /**
     * Specifies the Storage Domain ids to filter objects for which Protection Groups are writing data to Cohesity Views on the specified Storage Domains.
     */
    storageDomainIds?: Array<number>;

    /**
     * Specifies a list of Protection Source object ids to filter the objects. If specified, the object which are present in those Sources will be returned.
     */
    sourceIds?: Array<number>;

    /**
     * Specifies a list of recovery actions. Only snapshots that applies to these actions will be returned.
     */
    snapshotActions?: Array<'RecoverVMs' | 'RecoverFiles' | 'InstantVolumeMount' | 'RecoverVmDisks' | 'MountVolumes' | 'RecoverVApps' | 'RecoverRDS' | 'RecoverAurora' | 'RecoverS3Buckets' | 'RecoverApps' | 'RecoverNasVolume' | 'RecoverPhysicalVolumes' | 'RecoverSystem' | 'RecoverSanVolumes' | 'RecoverNamespaces' | 'RecoverObjects' | 'DownloadFilesAndFolders' | 'RecoverPublicFolders' | 'RecoverVAppTemplates' | 'RecoverMailbox' | 'RecoverOneDrive' | 'RecoverMsTeam' | 'RecoverMsGroup' | 'RecoverSharePoint' | 'ConvertToPst' | 'RecoverSfdcRecords' | 'DownloadChats' | 'RecoverAzureEntraID' | 'RecoverAwsDynamoDB'>;

    /**
     * Specifies the search string to filter the objects. This search string will be applicable for objectnames and Protection Group names. User can specify a wildcard character '*' as a suffix to a string where all object and their Protection Group names are matched with the prefix string. For example, if vm1 and vm2 are the names of objects, user can specify vm* to list the objects. If not specified, then all the objects with Protection Groups will be returned which will match other filtering criteria.
     */
    searchString?: string;

    /**
     * Specifies a list of run instance ids. If specified only objects belonging to the provided run id will be retunrned.
     */
    runInstanceIds?: Array<number>;

    /**
     * Specifies the type of request from UI, which is used for services like magneto to determine the priority of requests.
     */
    requestInitiatorType?: 'UIUser' | 'UIAuto' | 'Helios';

    /**
     * Specifies a list of region ids. Only records from clusters having these region ids will be returned.
     */
    regionIds?: Array<string>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies a list of Protection Group ids to filter the objects. If specified, the objects protected by specified Protection Group ids will be returned.
     */
    protectionGroupIds?: Array<string>;

    /**
     * Specifies the operating system types to filter objects on.
     */
    osTypes?: Array<'kLinux' | 'kWindows' | 'kAix' | 'kSolaris' | 'kSapHana' | 'kOther' | 'kHPUX' | 'kVOS'>;

    /**
     * Specifies a list of Object ids to filter.
     */
    objectIds?: Array<number>;

    /**
     * Filter by ObjectActionKey, which uniquely represents protection of an object. An object can be protected in multiple ways but atmost once for a given combination of ObjectActionKey. When specified, latest snapshot info matching the objectActionKey is for corresponding object.
     */
    objectActionKey?: null | 'kVMware' | 'kHyperV' | 'kVCD' | 'kAzure' | 'kGCP' | 'kKVM' | 'kAcropolis' | 'kAWS' | 'kAWSNative' | 'kAwsS3' | 'kAWSSnapshotManager' | 'kRDSSnapshotManager' | 'kAuroraSnapshotManager' | 'kAwsRDSPostgresBackup' | 'kAwsRDSPostgres' | 'kAwsAuroraPostgres' | 'kAwsDynamoDB' | 'kPhysical' | 'kPhysicalFiles' | 'kGPFS' | 'kElastifile' | 'kNetapp' | 'kGenericNas' | 'kIsilon' | 'kFlashBlade' | 'kPure' | 'kIbmFlashSystem' | 'kSQL' | 'kExchange' | 'kAD' | 'kOracle' | 'kView' | 'kRemoteAdapter' | 'kO365' | 'kO365PublicFolders' | 'kO365Teams' | 'kO365Group' | 'kO365Exchange' | 'kO365OneDrive' | 'kO365Sharepoint' | 'kKubernetes' | 'kCassandra' | 'kMongoDB' | 'kCouchbase' | 'kHdfs' | 'kHive' | 'kHBase' | 'kUDA' | 'kSfdc' | 'kSAPHANA';

    /**
     * If true, the response will include Objects which belongs to all tenants which the current user has permission to see.
     */
    includeTenants?: boolean;

    /**
     * Specifies the timestamp in Unix time epoch in microseconds to filter the objects if the Object has a successful snapshot before this value.
     */
    filterSnapshotToUsecs?: number;

    /**
     * Specifies the timestamp in Unix time epoch in microseconds to filter the objects if the Object has a successful snapshot after this value.
     */
    filterSnapshotFromUsecs?: number;

    /**
     * Specifies the environment type to filter objects.
     */
    environments?: Array<'kVMware' | 'kHyperV' | 'kAzure' | 'kKVM' | 'kAWS' | 'kAcropolis' | 'kGCP' | 'kPhysical' | 'kPhysicalFiles' | 'kIsilon' | 'kNetapp' | 'kGenericNas' | 'kFlashBlade' | 'kElastifile' | 'kGPFS' | 'kPure' | 'kIbmFlashSystem' | 'kNimble' | 'kSQL' | 'kOracle' | 'kExchange' | 'kAD' | 'kView' | 'kO365' | 'kHyperFlex' | 'kKubernetes' | 'kCassandra' | 'kMongoDB' | 'kCouchbase' | 'kHdfs' | 'kHive' | 'kHBase' | 'kUDA' | 'kSfdc' | 'kSAPHANA'>;

    /**
     * Specifies whether to only return the CDP protected objects.
     */
    cdpProtectedOnly?: boolean;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { SearchService }

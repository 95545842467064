import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { RegistrationServiceApi } from '@cohesity/api/v2';
import { SnackBarService } from '@cohesity/helix';
import { AjaxHandlerService, AutoDestroyable } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, from, interval, of } from 'rxjs';
import { catchError, debounceTime, filter, finalize, map, repeatWhen, switchMap, take, takeWhile, tap } from 'rxjs/operators';
import { HeliosStatus } from '../models';
import { AjsUpgradeService } from '../services';
import { UserService } from 'src/app/core/services';

/**
 * @description
 *
 * Helios token claim component opened by app panel service
 */
@Component({
  selector: 'coh-helios-claim',
  templateUrl: './helios-claim.component.html',
  styleUrls: ['./helios-claim.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class HeliosClaimComponent extends AutoDestroyable implements OnDestroy, AfterViewInit {
  /**
   * Helios token claim main form group
   */
  formGroup = new FormGroup({
    tokenInput: new FormControl<string>(''),
    heliosPermission: new FormControl<boolean>(false),
    clusterPermission: new FormControl<boolean>(false),
  });

  /**
   * Ref AJS Helios Service
   */
  readonly heliosService: any;

  /**
   * Helios connection status.
   */
  heliosStatus$: Observable<HeliosStatus>;

  /**
   * Max mcm/config calls to server to fetch registered status.
   */
  private readonly MAX_TRIES = 100;

  /**
   * Polling time for mcm/config calls
   */
  private readonly CONFIG_CALL_POLL_INTERVAL = 3_000;

  /**
   * Debounce time for saving settings.
   */
  private readonly SETTINGS_DEBOUNCE_TIME = 1_000;

  /**
   * Indicates form submission in progress
   */
  readonly submitting$ = new BehaviorSubject<boolean>(false);

  constructor(
    private ajaxHandler: AjaxHandlerService,
    private ajsUpgrade: AjsUpgradeService,
    private registrationService: RegistrationServiceApi,
    private snackBarService: SnackBarService,
    private translateService: TranslateService,
    private userService: UserService,
  ) {
    super();

    this.heliosService = this.ajsUpgrade.get('HeliosService');

    const triggers$ = interval(this.CONFIG_CALL_POLL_INTERVAL).pipe(
      this.untilDestroy(),
      take(this.MAX_TRIES)
    );

    this.heliosStatus$ = from(
      this.heliosService.getHeliosConfiguration() as Promise<HeliosStatus>
      ).pipe(
      this.untilDestroy(),
      repeatWhen(() => triggers$),
      takeWhile(status => status._isNotRegistered, true)
    );

  }

  ngAfterViewInit(): void {
    this.heliosStatus$.pipe(
      take(1),
      tap((status) => {
        const { clusterPermission, heliosPermission } = this.formGroup.controls;
        clusterPermission.setValue((status?.licenseOnly as boolean) ?? false);
        heliosPermission.setValue(!status?.mcmReadOnly ?? false);

        if (!this.userService.privs.MCM_MODIFY) {
          this.formGroup.disable();
        }
      }),
      filter(() => this.userService.privs.MCM_MODIFY),
      switchMap(() => this.formGroup.valueChanges.pipe(
        debounceTime(this.SETTINGS_DEBOUNCE_TIME),
        map(formValue => ([formValue.heliosPermission, formValue.clusterPermission]))
      )),
      this.untilDestroy(),
      switchMap(([mcmPermission, readOnlyCluster]) => from(
        this.heliosService.updateHeliosConfiguration({
          mcmReadOnly: !mcmPermission,
          licenseOnly: readOnlyCluster,
        })
      ))
    ).subscribe(() => this.snackBarService.open(this.translateService.instant('heliosClaim.saveSettings')));
  }

  /**
   * Handles form submission
   */
  onSubmit() {
    this.submitting$.next(true);
    this.registrationService
      .HeliosClaim({
        body: {
          registrationToken: this.formGroup.controls.tokenInput.value,
        },
      })
      .pipe(
        this.untilDestroy(),
        catchError(error => {
          this.ajaxHandler.errorMessage(error);
          return of(null);
        }),
        finalize(() => this.submitting$.next(false))
      )
      .subscribe();
  }

  /**
   * @returns Domain name part of url without the protocol and port.
   */
  getHeliosEndpoint(endpoint) {
    try {
      const url = new URL(endpoint);

      // Done to remove port (if any) from url host.
      const domainName = url?.host?.split(':')[0];

      return domainName ? `( ${domainName} )` : '';
    } catch (error) {
      return ''; // If an error is thrown, it's not a valid URL
    }
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { IconModule } from '../icon/icon.module';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

import {
  ExpansionCardComponent,
  ExpansionCardContentComponent,
  ExpansionCardHeaderComponent,
  ExpansionCardIconComponent,
} from './expansion-card.component';

const COMPONENTS = [
  ExpansionCardComponent,
  ExpansionCardContentComponent,
  ExpansionCardHeaderComponent,
  ExpansionCardIconComponent,
];

@NgModule({
  exports: COMPONENTS,
  imports: [CommonModule, IconModule, MatButtonModule, MatCardModule, ...COMPONENTS],
})
export class ExpansionCardModule {}

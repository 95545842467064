import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { BaseRendererComponent } from '../base-renderer.component';

/**
 * Text width types for text data renderer.
 */
export type TextDateRendererWidth = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

/**
 * Available params that can be specified for text data
 */
export interface TextDataRendererParams {
  /**
   * Whether the value should be translated
   */
  translate?: boolean;

  /**
   * Params to pass to the translate method
   */
  translateParams?: any;

  /**
   * Value to pass to the translate method
   */
  translateValue?: string;

  /**
   * Expected width of text.
   */
  width?: TextDateRendererWidth;

  /**
   * No wrap text if true.
   */
  nowrap?: boolean;

  /**
   * empty string handler config
   */
  emptyKey?: string;
}

/**
 * Renders a simple text value.
 */
@Component({
  selector: 'cog-text-data-renderer',
  template: '<ng-container *ngIf="text">{{text}}</ng-container>',
  styleUrls: ['text-data-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf],
})
export class TextDataRendererComponent extends BaseRendererComponent<string, TextDataRendererParams> {
  /**
   * Bind the xs class to the host
   */
  @HostBinding('class.cog-text-data-renderer-xs')
  get xs(): boolean {
    return this.renderParams?.width === 'xs' && !!this.value;
  }

  /**
   * Bind the sm class to the host
   */
  @HostBinding('class.cog-text-data-renderer-sm')
  get sm(): boolean {
    return this.renderParams?.width === 'sm' && !!this.value;
  }

  /**
   * Bind the md class to the host
   */
  @HostBinding('class.cog-text-data-renderer-md')
  get md(): boolean {
    return this.renderParams?.width === 'md' && !!this.value;
  }

  /**
   * Bind the lg class to the host
   */
  @HostBinding('class.cog-text-data-renderer-lg')
  get lg(): boolean {
    return this.renderParams?.width === 'lg' && !!this.value;
  }

  /**
   * Bind the xl class to the host
   */
  @HostBinding('class.cog-text-data-renderer-xl')
  get xl(): boolean {
    return this.renderParams?.width === 'xl' && !!this.value;
  }

  /**
   * Bind the xxl class to the host
   */
  @HostBinding('class.cog-text-data-renderer-xxl')
  get xxl(): boolean {
    return this.renderParams?.width === 'xxl' && !!this.value;
  }

  /**
   * Bind no wrap class to the host
   */
  @HostBinding('class.cog-text-data-renderer-nowrap')
  get nowrap(): boolean {
    return this.renderParams?.nowrap;
  }

  /**
   * Gets the rendered cell text.
   */
  get text(): string {
    if (!this.value) {
      return this.renderParams?.emptyKey ? this.translate(this.renderParams.emptyKey) : null;
    }
    if (this.translate && this.renderParams?.translate) {
      return this.renderParams?.translateValue
        ? this.translate(this.renderParams?.translateValue, { value: this.value })
        : this.translate(this.value, this.renderParams?.translateParams);
    }
    return this.value;
  }
}

<mat-calendar-header>
  <button [attr.aria-label]="intl.previous" mat-icon-button (click)="previousClicked()" class="prev-button"
    [cogDataId]="uniqueId + 'prev-btn'">
    <cog-icon shape="navigate_before"></cog-icon>
  </button>

  <div class="cog-calendar-head-date">
    {{ activeDate | cogDate: 'MMMM y' }}
  </div>

  <button [attr.aria-label]="intl.next" mat-icon-button (click)="nextClicked()" class="next-button"
    [cogDataId]="uniqueId + 'next-btn'">
    <cog-icon shape="navigate_next"></cog-icon>
  </button>

  <div class="cog-calendar-head-spacer"></div>

  <button mat-stroked-button (click)="todayClicked()" class="today-button"
    [cogDataId]="uniqueId + 'today-btn'">
    {{ intl.today }}
  </button>
</mat-calendar-header>

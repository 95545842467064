import { Component, ChangeDetectionStrategy } from '@angular/core';
import { BaseRendererComponent } from '../base-renderer.component';
import { ByteSizePipe } from '../../../byte-size/byte-size.pipe';

/**
 * Renders a simple bytes value.
 */
@Component({
  selector: 'cog-bytes-data-renderer',
  template: '{{ (value || 0) | byteSize }}',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ByteSizePipe],
})
export class BytesDataRendererComponent extends BaseRendererComponent<number> {}

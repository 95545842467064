import { Pipe, PipeTransform } from '@angular/core';

/**
 * @ngdoc Pipe
 * @description
 *    Filters a list by removing items that match the given predicate.
 *    The predicate is a simple object where each key corresponds to a property
 *    of the item, and the value specifies the condition to exclude. The predicate
 *    only supports matching simple property-value pairs (e.g., strings, numbers, booleans)
 *    and does not handle complex values like arrays or nested objects.
 *
 * @example
 *    <ng-container *ngIf="(itemsList$ | async | filterBy: { hidden: true }) as filteredItemsList">
 *      <!-- filteredItemsList will contain items where hidden !== true -->
 *    </ng-container>
 *
 *    Example:
 *    Input: [{ hidden: true }, { hidden: false }]
 *    Predicate: { hidden: true }
 *    Output: [{ hidden: false }]
 */
@Pipe({
  name: 'filterBy'
})
export class FilterByPipe implements PipeTransform {
  transform<T>(items: T[], predicate: Partial<Record<keyof T, T[keyof T]>>): T[] {
    // If the items list or predicate is missing, return the original items list
    if (!items || !predicate) {
      return items;
    }

    // Filter the items based on the predicate provided
    return items.filter(item =>
      // For each key in the predicate, ensure that:
      // 1. The key either doesn't exist on the item (i.e., item does not have the property), OR
      // 2. The value of the key on the item is not equal to the value in the predicate
      Object.keys(predicate).every(key =>
        !Object.prototype.hasOwnProperty.call(item, key) || item[key] !== predicate[key as keyof T]
      )
    );
  }
}

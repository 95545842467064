import { RecoveredOrClonedVmsRenameConfig, Recovery as RecoveryApiModel, SystemRecoveryParams, Tenant as TenantApi } from '@cohesity/api/v2';
import { NavItem } from '@cohesity/helix';
import { get } from 'lodash-es';
import {
  Environment,
  HasTenant,
  JobEnvParamsV2,
  mixinTenant,
  RecoveryAction,
  recoveryActionToParamsMap,
  targetToParamsMap,
  Tenant,
} from 'src/app/shared';

import { RecoveryFileObject } from './recovery-file-object';
import { RecoveryObject } from './recovery-object';
import { SnapshotTargetType } from './snapshot-target-type';

/**
 * List of recovery actions that will use task level status, start time, duration for object.
 */
export const useTaskStatusActionList = [
  RecoveryAction.InstantVolumeMount,
  RecoveryAction.RecoverNasVolume
];

/**
 * Data model for Recovery landing page table.
 */
export class RecoveryBase implements HasTenant {
  /**
   * Recovery ID (colon delimited).
   */
  id: string;

  /**
   * Legacy Recovery ID.
   */
  legacyId: number;

  /**
   * Name.
   */
  name: string;

  /**
   * Environment.
   */
  environment: string;

  /**
   * The start time of last run task.
   */
  startTime: number;

  /**
   * Duration of last run.
   */
  durationMs?: number;

  /**
   * The status.
   */
  status: string;

  /**
   * The tear down status.
   */
  tearDownStatus: string;

  /**
   * The tear down error message.
   */
  tearDownMessage: string;

  /**
   * Whether the recovery can be torn down.
   */
  canTearDown: boolean;

  /**
   * Storage domain id for the recovery.
   */
  storageDomainId: number;

  /**
   * Recovery Action.
   */
  action: RecoveryAction;

  /**
   * An ID used to query the progress of currently running task.
   */
  progressTaskId: string;

  /**
   * Snapshot target type (Local, Archival).
   */
  snapshotTargetType: SnapshotTargetType = 'Local';

  // TODO(ang): Check if type update is needed when more recovery environments are supported.
  /**
   * Recovery objects.
   */
  objects: RecoveryObject[];

  /**
   * childObjects are used in case of vApp templates
   */
  childObjects: RecoveryObject[];

  /**
   * Recovery file objects (for recover file only).
   */
  files?: RecoveryFileObject[];

  /**
   * Parameters based on specific recovery action.
   */
  actionParams: any;

  /**
   * Parameters based on specific snapshot target environment.
   */
  targetParams: any;

  /**
   * Name of user created the recovery.
   */
  userName: string;

  /**
   * Messages of the recovery. The messages field would get deprecated. To use warningMessages and errorMessages
   */
  messages: string[];

   /**
    * Error Messages of the recovery.
    */
  errorMessages: string[];

   /**
    * Warning Messages of the recovery.
    */
  warningMessages: string[];

  /**
   * UI mapping of permissions to TenantColumnComponent compatible object.
   * Named in the singular as that's how old APIs were implemented.
   */
  tenant: Tenant[];

  /**
   * The id of the tenant associated with this recovery. For simple filtering.
   */
  tenantId: string;

  /**
   * Recovery menu items.
   */
  availableActions?: NavItem[];

  /**
   * Indicates if this recovery is Oracle migration recovery task.
   */
  isOracleMigrationTask = false;

  /**
   * Indicates if this recovery is SQL migration recovery task.
   */
  isSqlMigrationTask = false;

  /**
   * Indicates if this recovery is VM(Vmware) migration recovery task.
   */
  isVmMigrationTask = false;

  /**
   * Indicates if this recovery is a Cassandra migration recovery task.
   */
  isCassandraMigrationTask = false;

  /**
   * Indicates if this recovery is Oracle overwrite recovery task.
   */
  isOracleOverwriteRestore = false;

  /**
   * Check if recovery is netapp snapdiff recovery.
   */
  isNetappSnapDiffRecovery = false;

  /**
   * Indicates the migration status if this recovery is Oracle migration recovery task.
   */
  migrationStatus: string;

  /**
   * Indicates the migration status if this recovery is VM migration recovery task.
   */
  vmMigrationStatus: string;

  /**
   * Indicates the migration status if this recovery is a Cassandra migration recovery task.
   */
  cassandraMigrationStatus: string;

  /**
   * Determines whether task type is oracle recovery or recovery validation.
   */
  isOracleRecoveryValidation?: boolean;

  /**
   * Indicates the validation status if this recovery is Oracle recovery validation task.
   */
  validationStatus: string;

  /**
   * Specifies the parameters to perform a system recovery.
   */
  systemRecoveryParams: null | SystemRecoveryParams;

  /**
   * ID of the protection group to which the recovered volume belongs.
   */
  protectionGroupId: string;

  /**
   * Recovery type.
   */
  get type(): string {
    if ([
      Environment.kAD,
      Environment.kOracle,
      Environment.kSQL,
      Environment.kExchange
    ].includes(this.environment as Environment)) {
      return this.environment;
    }
    return this.action;
  }

  /**
   * Constructor.
   *
   * @param   reference       Return data structure of Recovery from API
   * @param   specialParams   Provide any specific request when building the recovery object
   */
  constructor(public reference: RecoveryApiModel, public specialParams?: any) {
    const {
      id,
      name,
      startTimeUsecs,
      endTimeUsecs,
      status,
      tearDownStatus,
      tearDownMessage,
      canTearDown,
      progressTaskId,
      snapshotEnvironment,
      recoveryAction,
      permissions,
      messages,
      warningMessages,
      errorMessages,
      creationInfo: {
        userName
      } = {}
    }: RecoveryApiModel = reference || {};
    const [ , , legacyId ]: string[] = id ? id.split(':') : [];
    const params = reference[JobEnvParamsV2[snapshotEnvironment]];

    this.id = id;
    this.legacyId = +legacyId;
    this.name = name;
    this.userName = userName;
    this.environment = snapshotEnvironment;
    this.status = status;
    this.tearDownStatus = tearDownStatus;
    this.tearDownMessage = tearDownMessage;
    this.canTearDown = canTearDown;
    this.startTime = startTimeUsecs;
    if (endTimeUsecs && startTimeUsecs) {
      this.durationMs = Math.round((endTimeUsecs - startTimeUsecs) / 1000);
    }
    this.action = recoveryAction as RecoveryAction;
    this.messages = messages || [];
    this.warningMessages = warningMessages;
    this.errorMessages = errorMessages;
    this.progressTaskId = progressTaskId;
    if (params) {
      this.actionParams = params[recoveryActionToParamsMap[params.recoveryAction]];

      this.systemRecoveryParams = params.systemRecoveryParams;

      // Special case for SQL with parent-child task model.
      if (this.environment === Environment.kSQL && Array.isArray(this.actionParams)) {
        this.actionParams = this.actionParams[0];
      }

      this.targetParams = (this.actionParams || {})[targetToParamsMap[snapshotEnvironment]];

      this.objects = this.getObjects(
        params,
        this.targetParams ? this.targetParams.renameRecoveredVmsParams : undefined
      );

      this.childObjects = this.getChildObjects(
        params,
        this.targetParams ? this.targetParams.renameRecoveredVmsParams : undefined
      );

      if (this.actionParams &&
        [RecoveryAction.RecoverFiles, RecoveryAction.DownloadFilesAndFolders].includes(this.action)) {
        const osType = this.objects && this.objects[0] ? this.objects[0].osType : undefined;

        this.files = (this.actionParams.filesAndFolders || []).map(file => new RecoveryFileObject(file, osType));
      }
    }
    if (this.objects && this.objects.length) {
      this.snapshotTargetType = this.objects[0].snapshotTargetType || 'Local';
      this.storageDomainId = this.objects[0].storageDomainId;
      this.protectionGroupId = this.objects[0].protectionGroupId;

      // Update object status, start time, duration if using task level data.
      if (useTaskStatusActionList.includes(this.action) ||
        this.environment === Environment.kOracle) {
        this.objects.forEach(object => {
          object.status = this.status;
          object.tearDownStatus = this.tearDownStatus;
          object.startTime = this.startTime;
          object.durationMs = this.durationMs;
        });
      }
    }

    this.tenant = (permissions || []).map((permission: TenantApi) => {
      // A recovery can only belong to a single tenant. Safe to assign
      // this id as the group's tenantId.
      this.tenantId = permission.id;

      return {
        tenantId: permission.id,
        name: permission.name
      };
    });

    if (this.environment === Environment.kSQL && this.targetParams) {
      const sourceConfig = this.targetParams.recoverToNewSource ?
        this.targetParams.newSourceConfig :
        this.targetParams.originalSourceConfig;

      // It is a SQL migration task if EnableAutoSync is present whether true/false.
      this.isSqlMigrationTask = sourceConfig?.multiStageRestoreOptions?.enableAutoSync != null;
    }

    if (this.environment === Environment.kOracle && this.targetParams) {
      const sourceConfig = this.targetParams.recoverToNewSource ?
        this.targetParams.newSourceConfig :
        this.targetParams.originalSourceConfig;
      const delaySecs = get(sourceConfig, 'recoverDatabaseParams.oracleUpdateRestoreOptions.delaySecs', null);
      this.isOracleOverwriteRestore = Boolean(!this.targetParams.recoverToNewSource &&
        this.targetParams?.originalSourceConfig &&
        !this.targetParams?.originalSourceConfig?.oracleArchiveLogInfo &&
        !this.targetParams?.originalSourceConfig?.oracleRecoveryValidationInfo);
      this.isOracleMigrationTask = Boolean(
        (delaySecs !== null && delaySecs !== undefined) &&
        get(sourceConfig, 'recoverDatabaseParams.isMultiStageRestore')
      );

      if (this.isOracleMigrationTask && params?.objects?.length) {
        this.migrationStatus = params.objects[0].instantRecoveryInfo?.status;
      }

      // Populate recovery validation info.
      const recoveryValidationInfo = sourceConfig?.recoverDatabaseParams?.oracleRecoveryValidationInfo ||
        sourceConfig?.oracleRecoveryValidationInfo;

      if (recoveryValidationInfo) {
        this.isOracleRecoveryValidation = Object.keys(recoveryValidationInfo)?.length > 0;

        if (this.isOracleRecoveryValidation) {
          this.validationStatus = status;
        }
      }

      // Populating granular restore info in case of CDB/PDB Oracle restore.
      const pdbObjects = (sourceConfig?.recoverDatabaseParams || sourceConfig)?.granularRestoreInfo?.
        pdbRestoreParams?.pdbObjects;

      if (pdbObjects?.length && this.objects?.length) {
        this.objects[0].granularRestoreInfo = pdbObjects.map(pdb => pdb?.dbName).join(', ');
      }
    }

    if (this.environment === Environment.kVMware) {
      const sourceConfig = this.targetParams;

      this.isVmMigrationTask = Boolean(sourceConfig?.isMultiStageRestore);

      if (this.isVmMigrationTask && params?.objects?.length) {
        this.vmMigrationStatus = this.status;
      }
    }

    if (this.environment === Environment.kCassandra) {
      this.isCassandraMigrationTask = Boolean(params.isMultiStageRestore);

      if (this.isCassandraMigrationTask && params?.objects?.length) {
        this.cassandraMigrationStatus = this.status;
      }
    }
  }

  /**
   * Get the list of childobjects from params.
   *
   * @param   params   Specific environment parameters object
   * @param   renameParams rename params for object
   *
   */
   getChildObjects(params: any, renameParams: RecoveredOrClonedVmsRenameConfig): RecoveryObject[] {
    if (this.action === RecoveryAction.RecoverVAppTemplates
      && !this.specialParams?.treatAsVapp && params.objects && params?.objects?.length ) {
      // If there are VMs under the vApp, loading those VMs rather than the vApp (for detail page).
      if (params.objects[0].childSnapshots && params.objects[0]?.childSnapshots?.length) {
        return params.objects[0].childSnapshots.map(childVm => new RecoveryObject(childVm, renameParams));
      }
      return params.objects.map(object => new RecoveryObject(object, renameParams));
    }
    return null;
  }

  /**
   * Get the list of objects from params.
   *
   * @param   params   Specific environment parameters object
   */
  getObjects(params: any, renameParams: any): RecoveryObject[] {
    if (this.action === RecoveryAction.RecoverVApps && !this.specialParams?.treatAsVapp &&
      params.objects && params.objects.length ) {
      // If there are VMs under the vApp, loading those VMs rather than the vApp (for detail page).
      if (params.objects[0].childSnapshots && params.objects[0].childSnapshots.length) {
        return params.objects[0].childSnapshots.map(childVm => new RecoveryObject(childVm, renameParams));
      }
      return params.objects.map(object => new RecoveryObject(object, renameParams));
    } else if (params.objects && params.objects.length) {
      return params.objects.map(object => new RecoveryObject(object, renameParams));
    } else if (params.recoverNamespaceParams && params.recoverNamespaceParams.kubernetesTargetParams.objects) {
      return params.recoverNamespaceParams.kubernetesTargetParams.objects.map(
        object => new RecoveryObject(object, renameParams));
    } else if (params.recoverExchangeDbsParams?.exchangeTargetParams?.objects) {
      return params.recoverExchangeDbsParams.exchangeTargetParams.objects.map(
        object => new RecoveryObject(object, renameParams));
    }
    return [];
  }
}


/**
 * A placeholder for recovery with tenant info (cannot be used directly as class).
 */
const _RecoveryBase = mixinTenant(RecoveryBase);

/**
 * The actual class used in the application.
 */
export class Recovery extends _RecoveryBase {}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgIf } from '@angular/common';

import { IconComponent } from '../../../icon/icon.component';
import { BaseRendererComponent } from '../base-renderer.component';

/**
 * Renders a simple icon value.
 */
@Component({
  selector: 'cog-icon-data-renderer',
  template: '<cog-icon *ngIf="value" [shape]="value"></cog-icon>',
  styleUrls: ['./icon-data-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, IconComponent],
})
export class IconDataRendererComponent extends BaseRendererComponent<string> {}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ProtectionPolicyResponse } from '@cohesity/api/v2';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';
import { get } from 'lodash-es';
import { PolicyUtils } from 'src/app/modules/policy-shared/protection-policy-utils';

import { PolicyScheduleUnit } from '@cohesity/shared-forms';

@Component({
  selector: 'coh-policy-synopsis',
  templateUrl: './policy-synopsis.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PolicySynopsisComponent {
  /**
   * Whether to show policy name.
   */
  @Input() showName = false;

  /**
   * Protection Policy details.
   */
  @Input() policy: ProtectionPolicyResponse;

  /**
   * Protection policy type.
   */
  @Input() policyType: string;

  /**
   * Determines if Storage Array Snapshot feature is enabled or not.
   *
   * @return true if the user can access Storage Array Snapshot.
   */
  get storageArraySnapshotEnabled(): boolean {
    return flagEnabled(this.irisContextService.irisContext, 'storageArraySnapshotPolicyEnabled') ||
      flagEnabled(this.irisContextService.irisContext, 'ibmFlashSystemStorageArraySnapshotEnabled');
  }

  constructor(
    private translate: TranslateService,
    private irisContextService: IrisContextService) {}

  /**
   * Gets the backup label for incremental schedule.
   *
   * @return  Translated incremental backup label.
   */
  get incrementalBackUpLabel(): string {
    let periodicity: string;
    let labelParams = {};

    const { schedule } = get(this.policy, 'backupPolicy.regular.incremental');

    if (!schedule.unit) {
      return '';
    }

    switch (schedule.unit) {
      case PolicyScheduleUnit.Minutes:
        periodicity = 'minutes';
        labelParams = { backupIntervalMins: schedule.minuteSchedule.frequency || 0 };
        break;
      case PolicyScheduleUnit.Hours:
        periodicity = 'hours';
        labelParams = { backupIntervalHours: schedule.hourSchedule.frequency || 0 };
        break;
      case PolicyScheduleUnit.Days:
        periodicity = 'days';
        labelParams = { backupIntervalDays: schedule.daySchedule.frequency || 0};
        break;

      // Weeks and Months are guaranteed to be weekly and monthly periodicity for run schedule.
      case PolicyScheduleUnit.Weeks:
        periodicity = 'weekly';
        break;
      case PolicyScheduleUnit.Months:
        periodicity = 'monthly';
        break;
      case PolicyScheduleUnit.Years:
        periodicity = 'yearly';
        break;
    }

    return this.translate.instant(`policySynopsis.incremental.${periodicity}`, labelParams);
  }

  /**
   * Gets the label for incremental retention duration.
   *
   * @return  Translated incremental retention label.
   */
  get incrementalRetentionLabel(): string {
    const { duration, unit } = get(this.policy, 'backupPolicy.regular.retention');
    return this.translate.instant(`policySynopsis.retention.${unit}`, {duration});
  }

  /**
   * Gets the label for datalock duration.
   *
   * @return  Translated datalock label.
   */
  get dataLockLabel(): string {
    const { duration, unit } = get(this.policy, 'backupPolicy.regular.retention.dataLockConfig');
    return this.translate.instant(`policySynopsis.datalock.${unit}`, {duration});
  }

  /**
   * Returns true if the current policy is protect once.
   */
  isProtectOnce(): boolean {
    return PolicyUtils.isProtectOncePolicy(this.policy);
  }
}

import { Pipe, PipeTransform } from '@angular/core';

/**
 * @summary
 * A pipe that formats a list of items to display a maximum number of items
 * with a count of the remaining items.
 *
 * @description
 *
 * Usage:
 *   {{ items | truncateWithNumber:maxVisibleItems }}
 *
 * Pipe argument 'maxVisibleItems' is optional and defaults to 2.
 *
   @example 1:
 * {{ ['item1','item2','item3','item4'] | truncateWithNumber }}
 * Result: item1, item2, +2
 *
 * @example 2:
 * {{ ['item1','item2','item3','item4'] | truncateWithNumber:3 }}
 * Result: item1, item2, item3, +1
 *
 * @param items - The array of items to be summarized.
 * @param maxVisibleItems - The maximum number of items to display.
 * @returns A string containing the formatted items list.
 */

@Pipe({
  name: 'truncateWithNumber',
})
export class TruncateWithNumberPipe implements PipeTransform {
  /**
   * Transforms the input array of items into a formatted string.
   *
   * @param items - The array of items to be processed.
   * @param maxVisibleItems - The maximum number of items to display. @default 2
   * @returns A formatted string of the items.
   */
  transform(items: string[], maxVisibleItems = 2): string {
    if (!items || items.length === 0) {
      return '';
    }

    if (items.length > maxVisibleItems) {
      items = [...items.slice(0, maxVisibleItems), `+${items.length - maxVisibleItems}`];
    }

    return items.join(', ');
  }
}

/**
 * List of help urls used in apps.
 */
export const helpUrls: Record<string, string> = {
  addNewUser: 'https://docs.cohesity.com/WebHelios/Content/Helios/Access_Management.htm#ManageUsers',
  addSsoProvider: 'https://docs.cohesity.com/WebHelios/Content/Helios/SingleSignOn.htm#AddanSSOProvider',
  addSsoUsersGroups: 'https://docs.cohesity.com/WebHelios/Content/Helios/Access_Management.htm#ManageUsers',
  awsRequirements: 'https://docs.cohesity.com/baas/data-protect/aws-account-requirements.htm',
  azureRequirements: 'https://docs.cohesity.com/baas/data-protect/azure-vm/azure-prereq.htm',
  m365Requirements: 'https://docs.cohesity.com/baas/data-protect/M365/m365-requirements/m365-requirements.htm',
  msSqlRequirements: 'https://docs.cohesity.com/baas/data-protect/mssql-requirements.htm',
  nasRequirements: 'https://docs.cohesity.com/baas/data-protect/concepts/nas-overview.htm',
  oracleRequirements: 'https://docs.cohesity.com/baas/data-protect/oracle-requirements.htm',
  physicalRequirements: 'https://docs.cohesity.com/baas/data-protect/physical-server-requirements.htm',
  registerM364SourcesExpress: 'https://docs.cohesity.com/baas/data-protect/M365/register-m365-sources-express.htm',
  reporting: 'https://docs.cohesity.com/WebHelios/Content/Helios/HeliosReports.htm',
  saasConnection: 'https://docs.cohesity.com/baas/data-protect/saas-connection.htm',
  saasConnectionRequirements: 'https://docs.cohesity.com/baas/data-protect/saas-connection-requirements.htm',
  sapHanaRequirements: 'https://docs.cohesity.com/baas/data-protect/physical-server-requirements.htm',
  sharePointAdditionalPermissions: 'https://docs.cohesity.com/baas/data-protect/M365/m365-requirements/set-additional-permissions-sharepoint-online.htm',

  // TODO: update url when it is ready.
  sourceMissingItems: 'https://docs.cohesity.com/WebHelios/Content/Helios',
  registerCustomAzureApp: 'https://docs.cohesity.com/baas/data-protect/M365/m365-requirements/register-custom-app.htm',
  microsoftOrganizationSetting: 'https://docs.cohesity.com/baas/data-protect/M365/m365-requirements/org-settings.htm',
  vmWareLearnMoreURl: 'https://docs.cohesity.com/baas/data-protect/concepts/virtual-machines.htm',
  kubernetesLearnMoreURL: 'https://docs.cohesity.com/baas/data-protect/saas-connection-requirements.htm',
};

<mat-toolbar class="onboarding-toolbar toolbar-image" style.backgroundImage="url({{ backgroundImage }})">
  <mat-toolbar-row class="flex-row flex-justify-flex-end">
    <button
      mat-icon-button
      type="button"
      (click)="exit.emit()"
      cogDataId="toolbar-close"
      [inheritDataId]="true"
      [matTooltip]="intl.close"
      [attr.aria-label]="intl.close">
      <cog-icon shape="close"></cog-icon>
    </button>
  </mat-toolbar-row>
  <mat-toolbar-row>
    <div>
      <img [alt]="intl.cohesity" src="{{ 'i/cohesity-logo-white.svg' | helixAssetUrl }}" />
      <h4>{{ productName }}</h4>
    </div>
  </mat-toolbar-row>
  <mat-toolbar-row class="flex-justify-flex-end">
    <ng-content select="[onboarding-stepper]"></ng-content>
  </mat-toolbar-row>
</mat-toolbar>

<cog-value-property-filter
  *cogFilterDef="
    let params = params;
    let filter;
    quickFilter: !filterGroupLabel;
    key: filterName;
    label: 'objectType' | translate;
    filterGroup: filterGroupLabel;
    allowSelectionFromSingleFilter: !!filterGroupLabel;
    filterType: 'valueProperty'
  "
  [filterDefParams]="params"
  [filter]="filter"
  [allowMultiple]="allowMultiple"
  [filterValues]="filterValues"
  [inheritDataId]="true"
  cogDataId="env-type-filter">
</cog-value-property-filter>

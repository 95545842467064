import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { HelixIntlService } from '../../helix-intl.service';
import { OnChange, OnTouched } from '../../util';
import { DataIdDirective } from '../data-id/data-id.directive';
import { DateRangePickerModule } from '../date-range-picker';
import { DateRange, Timeframe, UncommonTimeframes } from '../date-utils/public_api';
import { ButtonIconDecoratorDirective } from '../icon/button-icon-decorator.directive';
import { IconComponent } from '../icon/icon.component';

/**
 * The default timeframe options for timeframe picker component.
 */
export const defaultTimeframeOptions = [
  Timeframe.Past12Hours,
  Timeframe.Past7Days,
  Timeframe.Past30Days,
  Timeframe.Past90Days,
];

@Component({
  selector: 'cog-timeframe-picker',
  templateUrl: './timeframe-picker.component.html',
  styleUrls: ['./timeframe-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimeframePickerComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [
    MatLegacyButtonModule,
    ButtonIconDecoratorDirective,
    DataIdDirective,
    MatLegacyMenuModule,
    IconComponent,
    DateRangePickerModule,
  ],
})
export class TimeframePickerComponent<D> implements ControlValueAccessor {
  /** The selected date range values. */
  private dateRange: DateRange<D>;

  @Input()
  get value(): DateRange<D> | null {
    return this.dateRange;
  }

  set value(dateRange: DateRange<D> | null) {
    this.writeValue(dateRange);
  }

  /** The private timeframe option  */
  private _timeframeOptions: Timeframe[];

  /** The timeframe option. */
  @Input() set timeframeOptions(timeframeOptions: Timeframe[]) {
    this._timeframeOptions = (timeframeOptions || []).filter(timeframe => !UncommonTimeframes.includes(timeframe));
  }

  get timeframeOptions() {
    return this._timeframeOptions?.length ? this._timeframeOptions : defaultTimeframeOptions;
  }

  /** Event emitted with new time on time selection. */
  @Output() changes = new EventEmitter<DateRange<D>>();

  constructor(
    private intlSvc: HelixIntlService,
  ) {}

  /**
   * Get the label of date range timeframe option.
   *
   * @param dateRange The date range.
   * @returns The translated label value for given date range.
   */
  getLabel(dateRange: DateRange<D>): string {
    return dateRange?.timeframe ? this.intlSvc.timeframe[Timeframe[dateRange.timeframe]] : '';
  }

  /**
   * Select the date range.
   *
   * @param dateRange The date range.
   */
  selectDateRange(dateRange: DateRange<D> | null) {
    this.value = dateRange;
    this.onChange(this.value);
  }

  /**
   * Update the view on model changes is request programmatic via forms API.
   *
   * This method is called by the forms API to write to the view when programmatic changes from model to view are
   * requested.
   *
   * @param   time   The new date time value..
   */
  writeValue(dateRange: DateRange<D> | null) {
    this.dateRange = dateRange;
  }

  /**
   * This method is called by the forms API on initialization to update the form
   * model when values propagate from the view to the model.
   */
  registerOnChange(fn: OnChange<D>) {
    const orgOnChange = this.onChange;

    this.onChange = (...args) => {
      // calling the original on changes callback defined below which will emit the changes output event.
      orgOnChange.call(this, ...args);

      // calling to on changes fn provided by FormControl.
      fn.call(this, ...args);
    };
  }

  /**
   * Registers a callback function is called by the forms API on initialization
   * to update the form model on blur.
   */
  registerOnTouched(fn: OnTouched) {
    this.onTouched = fn;
  }

  /**
   * The placeholder method populated by forms API registerOnChange method which
   * is used to update changes from view to modal.
   */
  onChange: OnChange<DateRange<D>> = dateRange => {
    this.changes.emit(dateRange);
  };

  /**
   * The placeholder method populated by forms API registerOnTouched method which
   * is used to mark a form field should be considered blurred or "touched".
   */
  onTouched: OnTouched = () => {};

  /**
   * Function that is called by the forms API when the control status changes to
   * or from 'DISABLED'. Depending on the status, it enables or disables the
   * appropriate DOM element.
   */
  setDisabledState(_isDisabled: boolean) {}
}

import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ControlContainer, UntypedFormControl, UntypedFormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { MatLegacyOption as MatOption } from '@angular/material/legacy-core';
import { Role } from '@cohesity/api/v1';
import { AjaxHandlerService, AutoDestroyable } from '@cohesity/utils';
import { filter, find } from 'lodash-es';
import { debounceTime, distinctUntilChanged, map, takeUntil, filter as rxjsFilter, tap } from 'rxjs/operators';
import { HeliosAccessManagementService, UserService } from 'src/app/core/services';
import { CohRole } from 'src/app/models/roles.decorator';
import { MCM_ROLES, UNASSIGNABLE_ROLES } from '../constants';

/**
 * Role options for showing in the mat-select
 */
interface RoleOptions {
  /**
   * All the admin role list options
   */
  adminRoleOptions: CohRole[];
  /**
   * Non admin role list options
   */
  otherRoleOptions: CohRole[];
  /**
   * Custom role options
   */
  customRoleOptions: CohRole[];
};

/**
 * Threshold count of roles above which
 * UI should start showing search bar for role
 * list.
 */
const SHOW_SEARCH_THRESHOLD = 20;

@Component({
  selector: 'coh-role-select',
  templateUrl: './role-select.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
  styles: [
    `
    .mat-chip.selected-chip {
      background: transparent;
      border: 1px solid;
      margin: 0.5rem;
    }
    `
  ],
})
export class RoleSelectComponent extends AutoDestroyable
  implements OnInit, OnChanges {

  /**
   * Form Group for the component.
   */
  @Input() formGroup: UntypedFormGroup;

  /**
   * Holds the label of the form control.
   */
  @Input() label = 'roles';

  /**
   * Form Control.
   */
  @Input() rolesFormControl: UntypedFormControl = new UntypedFormControl('');

  /**
   * Holds whether the form component is required.
   */
  @Input() isRequired = true;

  /**
   * Holds whether to disable form control, if true disable, enable otherwise
   */
  @Input() disableRole = false;

  /**
   * List of roles
   */
  @Input() rolesProp: CohRole[] = [];

  /**
   * Presentation mode, if enabled it will not make
   * roles call, and will depend on rolesProp for roles.
   */
  @Input() presentationMode: boolean;

  /**
   * Mat-select component instance for role selection
   */
  @ViewChild('role', { static: true }) roleSelectComp: MatSelect;

  /**
   * Show search control in the role list if there
   * are too many options (Greater than Threshold)
   */
  showRoleSearch = false;

  /**
   * Filtered role list based on search control
   * value. Use it for display list purpose only.
   */
  filteredOptions: RoleOptions = {
    adminRoleOptions: [],
    otherRoleOptions: [],
    customRoleOptions: []
  };

  /**
   * Current selected labels to be displayed in pills.
   */
  selectedRolesLabel: Role[] = [];

  /**
   * Search control for role list
   */
  roleSearchCtrl = new UntypedFormControl('');

  /**
   * Keys of CohRole class which are searchable
   */
  roleSearchKeys: (keyof CohRole)[] = [
    'label'
  ];

  /**
   * List of allowed admin roles.
   */
  private adminRoles: string[] = [
    MCM_ROLES.COHESITY_MCM_SUPER_ADMIN,
    MCM_ROLES.COHESITY_ADMIN
  ];

  /**
   * Global Variable which holds the roles state.
   */
  adminRoleList: CohRole[] = [];
  customRoleList: CohRole[] = [];
  roleList: CohRole[] = [];
  roles: CohRole[] = [];
  selectedRole = [];

  get secondOptions(): RoleOptions {
    const searchValue = this.roleSearchCtrl?.value ?? '';
    return {
      adminRoleOptions: this.doSearchOnRoleList(this.adminRoleList, searchValue),
      otherRoleOptions: this.doSearchOnRoleList(this.roleList, searchValue),
      customRoleOptions: this.doSearchOnRoleList(this.customRoleList, searchValue)
    };
  }

  /**
   * constructor
   *
   * @param   accessManagementService   Helios Access Management Service.
   * @param   evalAjax   Eval AJAX Service.
   */
  constructor(private accessManagementService: HeliosAccessManagementService,
    private evalAjax: AjaxHandlerService,
    private userService: UserService
  ) {
    super();
  }

  /**
   * Init the controller.
   */
  ngOnInit() {

    // Update the validators for the controls.
    if (this.isRequired) {
      this.rolesFormControl.setValidators(Validators.required);
    } else {
      this.rolesFormControl.clearValidators();
    }
    this.rolesFormControl.updateValueAndValidity();

    this.subscribeToSearchChanges();

    /**
     * Do not fetch roles if presentation mode
     * is enabled.
     */
    if (!this.presentationMode) {
      this.accessManagementService.getRoles()
      .pipe(
        takeUntil(this._destroy),
        map(roles => roles?.filter(role => !(UNASSIGNABLE_ROLES.includes(role.name)))),
      )
      .subscribe(
        roles => {
          this.postProcessRoles(roles);
        },
        this.evalAjax.errorMessage
      );
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const rolePropChange = changes?.rolesProp?.currentValue ?? [];
    const processRoles = rolePropChange.length || changes.disableRole;
    // create a copy so that original internal object refs
    // do not get modified.
    const updatedRoles = rolePropChange.length ? [...rolePropChange] : this.roles;
    if (processRoles) {
      this.postProcessRoles(updatedRoles);
    }
  }

  /**
   * Roles post processing
   *
   * @param roles List of available roles
   */
  postProcessRoles(roles: CohRole[]) {
    if (this.disableRole) {
      this.assignRoles(roles);
    } else {
      this.filterRolesByPrivileges(roles);
    }
  }

  /**
   * Filter roles by Logged In user privileges
   *
   * @param roles List of available roles
   */
  filterRolesByPrivileges(roles: CohRole[]) {
    const filteredRoles: CohRole[] = [];
    roles.forEach((role) => {
      if (this.userService.canCurrentUserModifyRole(role)) {
        filteredRoles.push(role);
      }
    });
    this.assignRoles(filteredRoles);
  }

  /**
   * Assign Roles
   *
   * @param roles List of filtered roles
   */
  assignRoles(roles: CohRole[] = []) {
    let mergedList: CohRole[] = [];
    this.roles = roles;
    this.adminRoleList = this.adminRoles.map(admin => find(roles, { name: admin }))
      .filter(role => role !== undefined) as CohRole[];
    this.customRoleList = this.roles.filter(a => a.isCustomRole).sort();

    // Merge the admin and custom roles, the difference is the non-admin roles.
    mergedList = [...this.adminRoleList, ...this.customRoleList];
    this.roleList = roles.filter(role => !mergedList.some(r => r.name === role.name)) as CohRole[];

    this.updateSearchControl();
    this.fixCompareWithOperator();
    this.updateRoleOptions(null);

    // Update the pills to reflect the selected values if any.
    if (this.rolesFormControl.value) {
      this.selectedRolesLabel =
        this.rolesFormControl.value.map(role => find(roles, { name: role }));
      this.handleRoleSelection(this.rolesFormControl.value);
    }
  }

  /**
   * Clear the current selection of selected roles.
   */
  clearSelection() {
    this.adminRoleList = this.adminRoleList.map(adminRole => ({...adminRole, _disabled: false}));
    this.roleList = this.roleList.map(role => ({...role, _disabled: false}));
    this.customRoleList = this.customRoleList.map(role => ({...role, _disabled: false}));
  }

  /**
   * Select the role based on hierarchy.
   *
   * @param   selectedRoles   Current SelectedRoles
   */
  handleRoleSelection(selectedRoles) {
    this.clearSelection();
    switch (true) {
      // If Super Admin is selected, make sure only MCM Super Admin or custom roles are selected.
      case selectedRoles.includes(MCM_ROLES.COHESITY_MCM_SUPER_ADMIN): {
        // if admin or cohesity predefined role is already selected -> remove it.
        selectedRoles = selectedRoles.filter(role => role !== MCM_ROLES.COHESITY_ADMIN
          && !this.roleList.some(cohesityRole => cohesityRole['name'] === role));
        this.selectedRole = selectedRoles;
        // Admin Roles are not available for DMaaS only accounts.
        const adminRole = this.adminRoleList.find(role => role.name === MCM_ROLES.COHESITY_ADMIN);
        if (adminRole) {
          adminRole._disabled = true;
        }
        this.roleList = this.roleList.map(role => ({ ...role, _disabled: true }));
        break;
      }
      // If Admin is selected, super admin and custom roles are selectable, but not other roles.
      case selectedRoles.includes(MCM_ROLES.COHESITY_ADMIN):
        // if Cohesity predefined role is already selected -> remove it.
        selectedRoles = selectedRoles.filter(role => !this.roleList.some(
          cohesityRole => cohesityRole['name'] === role));
        this.selectedRole = selectedRoles;
        this.roleList = this.roleList.map(role => ({ ...role, _disabled: true }));
        break;
      // Everything is selectable.
      default:
        this.selectedRole = selectedRoles;
        break;
    }

    this.updateRoleOptions(null);
    if (this.showRoleSearch) {
      this.rolesFormControl.setValue([...this.selectedRole], { emitEvent: false });
    } else {
      this.rolesFormControl.setValue(this.selectedRole);
    }

    this.rolesFormControl.markAsTouched();
    this.rolesFormControl.updateValueAndValidity();

    // Update the pills with right information.
    this.selectedRolesLabel = filter(this.roles,
      role => this.selectedRole.includes(role.name)) as Role[];
  }

  /**
   * Test whether an option can be included in
   * the filtered list or not based on search
   * value.
   *
   * @param option role option to test
   * @param key coh role property to apply search on
   * @param target search string
   * @returns true if option satisfies the predicate
   */
  roleSearchPredicate(option: CohRole, key: keyof CohRole, target: string = ''): boolean {
    return option?.[key]?.toString()?.toLowerCase()?.includes(target?.toLowerCase());
  }

  /**
   * Update the visiblity of role search control
   */
  updateSearchControl(): void {
    this.showRoleSearch = this.roles?.length > SHOW_SEARCH_THRESHOLD;
  }

  /**
   * Perform search on the given list of roles
   *
   * @param list source list
   * @param target search term
   * @returns list matching search term on {@link roleSearchKeys}
   */
  doSearchOnRoleList(list: CohRole[], target: string = ''): CohRole[] {

    if (!target) {
      return list;
    }

    return list?.filter((option) => this.roleSearchKeys?.some(
      (key) => this.roleSearchPredicate(option, key, target)
    )) ?? [];
  }

  /**
   * Filter the role options based on current search term.
   * Call this method everytime there's an update in any of
   * the role list model.
   *
   * @param targetSearch search term. NULL value in the search term
   * will take the value from search control if it's visible
   */
  updateRoleOptions(targetSearch: string | null) {
    // Role search not enabled
    // Return models as it is
    if (!this.showRoleSearch || targetSearch === '') {
      this.filteredOptions = {
        adminRoleOptions: this.adminRoleList,
        otherRoleOptions: this.roleList,
        customRoleOptions: this.customRoleList
      };
    }

    let searchValue = targetSearch;

    // targetSearch is not empty string
    // take the value from search control to filter
    // the options with the actual value. This is for the
    // cases where search term has not updated and the role list
    // models have been updated
    if (!targetSearch) {
      searchValue = this.roleSearchCtrl.value;
    }

    // do filter
    this.filteredOptions = {
      adminRoleOptions: this.doSearchOnRoleList(this.adminRoleList, searchValue),
      otherRoleOptions: this.doSearchOnRoleList(this.roleList, searchValue),
      customRoleOptions: this.doSearchOnRoleList(this.customRoleList, searchValue)
    };
  }

  /**
   * Observe the changes in role list search control.
   * It will filter the role list options only if
   * role search control is visible.
   */
  subscribeToSearchChanges() {
    this.roleSearchCtrl.valueChanges.pipe(
      this.untilDestroy(),
      rxjsFilter(() => this.showRoleSearch),
      debounceTime(400),
      distinctUntilChanged(),
      // update the role options in mat-select component
      tap((targetSearch) => this.updateRoleOptions(targetSearch)),
      // wait for some random time to mat-select component
      // to get updated with the options set in `updateRoleOptions`
      // method.
      // debounceTime(400)
    ).subscribe(() => setTimeout(() => this.reconcileSelection(), 0));
  }

  /**
   * Reconcile the value of selected roles {@link selectedRole} with the Mat-Select
   * component {@link roleSelectComp}. This is required because of the bug that exists in
   * ngx-mat-select-search (https://github.com/bithost-gmbh/ngx-mat-select-search)
   * when currently not visible options are brought back in the select options list.
   *
   * Bug details:
   * On selection of Admin roles, if the role was previously selected and
   * is not visible currently, should be unselected if it comes in future search
   * results. But select-search component caches the previous selection and marks
   * them selected if they re-appear in the search results. The implementation
   * done by ngx-mat-select-search does not consider value overrides (using the setValue)
   * that could be programatically. The implementation works if there's no custom logic
   * (as we have in role selection) and selection/de-selection is done via UI only.
   * Ref: [https://github.com/bithost-gmbh/ngx-mat-select-search/blob/master/src/app/mat-select-search/mat-select-search.component.ts#L601]
   *
   * NOTE: de-selecting an option here calls {@link handleRoleSelection} method again. So, the
   * ultimate value of select component is expected to be according to the manipulations done
   * in the {@link handleRoleSelection} method.
   */
  reconcileSelection() {
    this.roleSelectComp.options.forEach((option) => {
      if (!this.selectedRole.includes(option.value)) {
        option.deselect();
      }
    });
  }

  /**
   * Fixes the compareWith operator which is used internally by
   * ngx-mat-select-search (https://github.com/bithost-gmbh/ngx-mat-select-search)
   * to compare options. This is an implementation bug in the library
   * because Angular MatSelect compares the option values and not option
   * themseleves.
   * Ref: https://github.com/bithost-gmbh/ngx-mat-select-search/blob/092aaf963d2b9cb3cb36f79cab1f6d3c6f35aa63/src/app/mat-select-search/mat-select-search.component.ts#L392
   *
   * At the point of amending this function, this role component was used
   * at different places, so, instead of changing the type of value exposed by
   * FormControl (from 'string' to 'CohRole'), quick fix is to proxy `compareWith`
   * operator and override for the faulty call of ngx-mat-select-search.
   *
   * If compareWith isnt fixed, Options list will scroll to top every time
   * an option is selected because of
   * https://github.com/bithost-gmbh/ngx-mat-select-search/blob/092aaf963d2b9cb3cb36f79cab1f6d3c6f35aa63/src/app/mat-select-search/mat-select-search.component.ts#L392.
   *
   * This is required because role options are updated every time role selection is changed.
   * So, referential equality is lost every time role selection is changed even if
   * options havent actually changed.
   */
  fixCompareWithOperator() {
    if (this.roleSelectComp && this.showRoleSearch) {
      const compareWith = this.roleSelectComp.compareWith;

      // override `compareWith` operation
      this.roleSelectComp.compareWith = (
        a: MatOption | string,
        b: MatOption | string
      ): boolean => {

        // `compareWith` will be called with MatOption only from
        // `ngx-mat-select-search`. For the cases where it is called
        // by native Angular MatSelect, it would be string value since
        // value of `CohRole` option is set as string
        if (a instanceof MatOption && b instanceof MatOption) {
          return compareWith(a.value, b.value);
        }

        return compareWith(a, b);
      };
    }
  }
}

import { SelectionModel } from '@angular/cdk/collections';
import { CdkColumnDef, CdkHeaderRowDef, CdkRowDef } from '@angular/cdk/table';
import {
  AfterContentInit,
  Component,
  ContentChild,
  ContentChildren,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
  Output,
  EventEmitter,
} from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTable as MatTable } from '@angular/material/legacy-table';
import { get } from 'lodash-es';
import { takeUntil } from 'rxjs/operators';
import { AutoDestroyable, PersistService } from '@cohesity/utils';

import { PaginatorComponent } from './paginator/paginator.component';
import { TableDataSource } from './table-data-source';
import { DataFilterValue } from '@cohesity/helix';

/**
 * Type definition for isAllSelectedFn callback
 */
export type IsAllSelectedFn = () => boolean;

/**
 * Type definition for CanSelectRowFn callback
 */
export type CanSelectRowFn<T> = (row: T) => boolean;

/**
 * Type definition for CanSelectAnyRowsFn callback
 */
export type CanSelectAnyRowsFn = () => boolean;

/**
 * Type definition for ToggleSelectAllFn callback
 */
export type ToggleSelectAllFn = () => void;

/**
 * This component assists with configuring and using an angular material data table.
 * It provides utilities and helper directiveas to automatically configure the a
 * TableDataSource object which supports client-side, filtering, paging, and sorting.
 *
 * @example
 *   Simple Usage:
 *   <coh-table name="exampleTable" [source]="data" [selection]="tableSelection">
 *     <table mat-table>
 *        ...table contents
 *     </table>
 *   </coh-table>
 */
@Component({
  selector: 'coh-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TableComponent<T> extends AutoDestroyable implements AfterContentInit, OnChanges, OnDestroy {
  /**
   * Adds horizontal cell padding to the table cells. Usually this can be off, but
   * it is especially useful for tables that need to add additional styling such as
   * backgrounds or borders to individual columns.
   */
  @HostBinding('class.cell-padding') @Input() useCellPadding = false;

  /**
   * Configures the table to top align row content. This is useful for tables that
   * may need to show multiple lines of text in rows. This will not top align the
   * header rows.
   */
  @HostBinding('class.top-align') @Input() topAlign = false;

  /**
   * Indicates whether the table implement is using the flex classes or the standard html
   * table elements.
   */
  @HostBinding('class.flex-table') get isFlex(): boolean {
    // _isNativeHtmlTable is a private property of MatTable, but it's the
    // cleanest way to determine if this is a flex table or a native html table.
    return this.table && !(this.table as any)._isNativeHtmlTable;
  }

  /**
   * The viewport size do we want to add horizontal scrolling to the component
   */
  @Input() reflowScrollSize: 'xs' | 'sm' | 'disabled' | '' = '';

  /**
   * Conditionally add horizontal scrolling at extra small viewport
   */
  @HostBinding('class.reflow-table-x-scrollable@xs')
  get reflowScrollAtXs() {
    return this.reflowScrollSize === 'xs' || this.reflowScrollSize === '' ;
  }

  /**
   * Conditionally add horizontal scrolling at small viewport
   */
  @HostBinding('class.reflow-table-x-scrollable@sm')
  get reflowScrollAtSm() {
    return this.reflowScrollSize === 'sm';
  }

  /**
   * Conditionally disable the reflow horizontal scrolling
   */
  @HostBinding('class.reflow-table-x-scrollable-disabled')
  get reflowScrollDisabled() {
    return this.reflowScrollSize === 'disabled';
  }

  /**
   * Sets a name for the table. This name will be used to persist the pagination
   * preferences in local storage. And will also be used to generate ids for
   * pagination buttons
   */
  @Input() name: string;

  /**
   * An array of data to use as a source for the table. The table is currently
   * configured for a client side filter. In the future, a complete data source
   * could be taken as an input which would support server side filtering, etc...
   */
  @Input() source: T[] = [];

  /**
   * A selection model for the table based on the @angular/cdk selectionModel
   * This supports single or multiple selection.
   */
  @Input() selection: SelectionModel<T>;

  /**
   * Enables automatically adding the selection columns whenever the selectionModel
   * is set. This is disabled by default to prevent breaking changes in existing
   * tables.
   */
  @Input() enableSelectionColumn = false;

  /**
   * Optional user-provided function for determining if all items on the page
   * are selected. This can be used to add custom selection logic to a table.
   * The function should return true if all items are selected, false otherwise.
   */
  @Input() isAllSelectedFn: IsAllSelectedFn;

  /**
   * Optional user-provided function to determine whether a row can be selected
   * or not. This can be used to add custom selection logic to a table.
   * The function accepts a row parameter and return true if it should be enabled
   * false to disable it.
   */
  @Input() canSelectRowFn: CanSelectRowFn<T>;

  /**
   * Optional user-provided function to determine whether the select all button
   * should be enabled or not. This can be used to add custom selection logic to
   * a table. The function should return true if the select all button should be
   * enabled, false if not.
   */
  @Input() canSelectAnyRowsFn: CanSelectAnyRowsFn;

  /**
   * Optional user-provided function to toggle the select all button.
   * This can be used to add custom selection logic to a table.
   */
  @Input() toggleSelectAllFn: ToggleSelectAllFn;


  @Input() filters: DataFilterValue<any, T>[] = [];

  private _contentInitialized = false;

  /**
   * An array of the data currently rendered on the page, after filtering and
   * pagination are applied.
   */
  public renderedData: T[] = [];

  /**
   * A reference to the material data table.
   */
  @ContentChild(MatTable) public table: MatTable<T>;

  /**
   * Column def for a select column that can be dynamically added to any table with
   * a selection model set.
   */
  @ViewChild('selectColumn', { read: CdkColumnDef, static: true } ) private selectColumn: CdkColumnDef;

  /**
   * All row definitions applied to the table. If the table has a selection model,
   * a select column will be added to the first row def.
   */
  @ContentChildren(CdkRowDef, {descendants: true}) private rowDefs: QueryList<CdkRowDef<any>>;

  /**
   * All header row definitions applied to the table. If the table has a selection model,
   * a select column will be added to the first header row def.
   */
  @ContentChildren(CdkHeaderRowDef, {descendants: true}) private headerRowDefs: QueryList<CdkHeaderRowDef>;

  /**
   * Optional. If present, it will automatically configure the paginator
   * to work with the data table
   *
   * @example
   * <coh-table>
   *   <table mat-table>...</table>
   *   <coh-paginator></coh-paginator>
   * </coh-table>
   */
  @ContentChild(PaginatorComponent) public cohPaginator: PaginatorComponent;


  /**
   * Optional. If present, it will automatically configure the paginator
   * to work with the data table
   *
   * @example
   * <coh-table>
   *   <table mat-table>...</table>
   *   <mat-paginator></mat-paginator>
   * </coh-table>
   */
  @ContentChild(MatPaginator) public matPaginator: MatPaginator;

  private get paginator() {
    return this.cohPaginator || this.matPaginator;
  }

  /**
   * Optional. When the mat-sort directive is added to the table, this table
   * will automatically configure it to work with the data source. To enable
   * sorting on a column, add the mat-sort-header directive to the header.
   *
   * @example
   * <coh-table>
   *   <table mat-table matSort>
   *     ...
   *     <ng-container matColumnDef="name">
   *       <th mat-header-cell *matHeaderCellDef mat-sort-header>name</th>
   *       <td mat-cell *matCellDef="let row">{{row.name}}</td>
   *     </ng-container>
   *     ...
   *   </table>
   * </coh-table>
   */
  @ContentChild(MatSort) public sort: MatSort;

  /**
   * The data source for the table. Eventually, this will be able to be swapped
   * out for a version that support server side filtering.
   */
  @Input() public dataSource: TableDataSource<T> = new TableDataSource([]);

  /**
   * When data rendering is complete, emit an event.
   * This can be used for pre-selecting values in table.
   * Capture this event in host component and add selection values in SelectionModel.
   */
  @Output() renderedDataChange = new EventEmitter<T[]>();

  /**
   * Component constructor
   *
   * @param   persist   service to save pagination preferences to local storage
   */
  constructor(private persist: PersistService) {
    super();
  }

  /**
   * Initialize the Content Children to configure the table and data source
   */
  ngAfterContentInit() {
    if (!this.table) {
      throw new Error('No Material Table Found');
    }
    this._contentInitialized = true;
    this.updateColumnDefs();
    this.initializeDataSource();
  }

  /**
   * Add or remove the select column definition to the table object. If selection is enabled,
   * this will add the column def and update the row definitions to include the column.
   * If selection is not enabled, it will ensure that they are not include in the table.
   */
  private updateColumnDefs() {
    if ( !(this.selectColumn instanceof CdkColumnDef) || !this.table || !this.enableSelectionColumn) {
      return;
    }
    const rowColumns = get(this.rowDefs, 'first.columns', []) as string[];
    const headerRowColumns = get(this.headerRowDefs, 'first.columns', []) as string[];

    if (this.selection) {
      this.table.addColumnDef(this.selectColumn);

      if (!rowColumns.includes('select')) {
        rowColumns.unshift('select');
      }

      if (!headerRowColumns.includes('select')) {
        headerRowColumns.unshift('select');
      }

    } else {
      this.table.removeColumnDef(this.selectColumn);

      if (rowColumns.includes('select')) {
        rowColumns.splice(0, 1);
      }

      if (headerRowColumns.includes('select')) {
        headerRowColumns.splice(0, 1);
      }
    }
  }

  /**
   * Configure the datasource for the table.
   */
  private initializeDataSource() {

    // Don't do anything if this gets called before the component has finished
    // initializing.
    if (!this.table || !this._contentInitialized) {
      return;
    }

    // Connects to the data source and configures a subscription to keep track of
    // the currently rendered data in the table.
    this.dataSource
      .connect()
      .pipe(takeUntil(this._destroy))
      .subscribe(data => {
        // Clear the selection when the visible data changes
        if (this.selection) {
          this.selection.clear();
        }
        this.renderedData = data;

        // Emit event after data rendering is complete
        this.renderedDataChange.emit(this.renderedData);
      });

    // If the paginator is present, it needs to be configured. Use the
    if (this.paginator) {
      // If the table's name is configured, use it to look up preferences in local
      // storage and update the disabled property on the paginator
      if (this.name) {
        this.paginator.disabled = this.persist.get(this.name + '.paginator.disabled') || false;
      }


      // Only set the paginator on the datasource if it's enabled.
      this.dataSource.paginator = this.paginator.disabled ? undefined : this.paginator;

      if (this.cohPaginator) {
        this.cohPaginator.name = this.name;

        // Subscribe to changes on the disabled property
        this.cohPaginator.disabledChange
          // Clean up subscription on component destory
          .pipe(takeUntil(this._destroy))
          .subscribe(disabled => {
            this.paginator.disabled = disabled;
            this.dataSource.paginator = this.paginator.disabled ? undefined : this.paginator;

            // Update local storage only if the datagride name is configured.
            if (this.name) {
              this.persist.set(this.name + '.paginator.disabled', disabled);
            }
          });
      }
    }

    if (this.sort) {
      this.dataSource.sort = this.sort;
    }

    this.table.dataSource = this.dataSource;
  }

  /**
   * Toggles the select all checkbox
   */
  toggleSelectAll() {
    if (this.toggleSelectAllFn) {
      this.toggleSelectAllFn();
    } else {
      this.isAllSelected() ? this.selection.clear() : this.renderedData.forEach(row => this.selection.select(row));
    }
  }

  /**
   * Check if all items are selected
   *
   * @return  true if the number of selected items matches the number of items
   *          current rendered.
   */
  isAllSelected(): boolean {
    if (this.isAllSelectedFn) {
      return this.isAllSelectedFn();
    }
    const numSelected = this.selection.selected.length;
    const numRows = this.renderedData.length;
    return numSelected === numRows;
  }

  /**
   * Determine if row selection should be enabled or not.
   *
   * @param   row   The current row.
   * @return  True if the row can be selected, false if not.
   */
  canSelectRow(row: T): boolean {
    if (this.canSelectRowFn) {
      return this.canSelectRowFn(row);
    }
    return true;
  }

  /**
   * Determine if the select all button should be disabled or not.
   *
   * @return  True if select all should be enabled false if not.
   */
  canSelectAnyRows(): boolean {
    if (this.canSelectAnyRowsFn) {
      return this.canSelectAnyRowsFn();
    }
    return true;
  }

  /**
   * Disconnect from the datasource.
   */
  ngOnDestroy() {
    super.ngOnDestroy();
    this.dataSource.disconnect();
  }

  /**
   * Listen for changes to the component inputs and make updates as needed
   *
   * @param   changes   summary of input changes
   */
  ngOnChanges(changes: SimpleChanges) {

    if (changes.dataSource) {
      if (changes.dataSource.previousValue) {
        // Clear the old data source and subscriptions
        changes.dataSource.previousValue.disconnect();
        this.cleanUpSubscriptions();
      }
      this.initializeDataSource();
    }

    if (changes.selection) {
      this.updateColumnDefs();
    }

    // If the source has changed, just update the data source
    if (changes.source) {
      this.dataSource.data = this.source;
    }

    // If the filters have changed, we need to clean up the old ones and then
    // configure a new listener.
    if (changes.filters) {
      this.dataSource.dataFilters = this.filters;
    }
  }
}

import { Component, Input } from '@angular/core';
import { HBaseConnectParams, ProtectionSourceTreeInfo } from '@cohesity/api/v1';
import { get } from 'lodash-es';

/**
 * HBase source details settings components. Used for displaying the
 * registered HBase source's configuration values.
 */
@Component({
  selector: 'coh-hbase-source-details-settings',
  templateUrl: './source-details-settings.component.html'
})
export class HBaseSourceDetailsSettingsComponent {

  /**
   * Input source.
   */
  @Input() source: ProtectionSourceTreeInfo;

  /**
   * Getter for retrieving hBaseParams from source input.
   */
  get hBaseParams(): HBaseConnectParams {
    return get(this.source, 'registrationInfo.hbaseParams') as HBaseConnectParams;
  }
}

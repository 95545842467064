import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ProtectionPolicyResponse } from '@cohesity/api/v2';
import { PolicyScheduleUnit } from '@cohesity/shared-forms';
import { userTimezone } from '@cohesity/utils';
import { get } from 'lodash-es';
import { PolicyUtils } from 'src/app/modules/policy-shared/protection-policy-utils';
import { BaseProtectionBuilderComponent } from '../../base-protection-builder/base-protection-builder.component';

/**
 * Default value for start time.
 */
export const DefaultStartTime = (now: Date) => ({
  time: {
    hour: now.getHours(),
    minute: now.getMinutes(),
  },
  timeZone: userTimezone,
});

interface StartTime {
  time: {
    hour: number;
    minute: number;
  };
  timeZone: string;
}

@Component({
  selector: 'coh-settings-list-start-time',
  templateUrl: './settings-list-start-time.component.html',
  styleUrls: ['settings-list-start-time.component.scss'],
})
export class SettingsListStartTimeComponent
  extends BaseProtectionBuilderComponent<StartTime, any> {
  /**
   * Default value for this settings list FormControl.
   */
  defaultValue = DefaultStartTime(new Date());

  /**
   * Internal FormGroup for managing time and timezone values.
   */
  startTimeFormGroup: UntypedFormGroup = new UntypedFormGroup({
    time: new UntypedFormControl(this.defaultValue.time, Validators.required),
    timeZone: new UntypedFormControl(this.defaultValue.timeZone, Validators.required),
  });

  /**
   * Override of addFormControl from BaseProtectionBuilderComponent so this component's FormControl isn't required.
   */
  addFormControl() {
    this.formGroup.addControl(this.name, this.startTimeFormGroup);
  }

  /**
   * Indicates if the selected policy requires a start time
   * In order to require start time the policy needs not having a continuous schedule
   * or to be the Protect Once policy.
   */
  get requiresStartTime(): boolean {
    return !this.policyHasContinuousScheduleOnly &&
      !PolicyUtils.isProtectOncePolicy(this.formGroup.parent.value.policy);
  }

  /**
   * Indicates if the selected policy has only a continuous schedule (minutes/hourly).
   * If so, Start Time is irrelevant as the job will run on minute or hour based intervals.
   */
  get policyHasContinuousScheduleOnly(): boolean {
    // List of all continuous schedule unit
    const continuousScheduleUnits = [
      PolicyScheduleUnit.Runs,
      PolicyScheduleUnit.Minutes,
      PolicyScheduleUnit.Hours
    ];
    const policy: ProtectionPolicyResponse = this.formGroup.parent.value.policy;

    if (!policy || !get(policy, 'backupPolicy.regular.incremental.schedule')) {
      return false;
    }

    const { incremental, full } = policy.backupPolicy.regular;

    // In order for startTime to be needed/shown, at least one non-continuous schedule must be present on the policy.
    // Continuous schedules run on an interval (for example every X minutes, or every 2 hours), while "non-continuous"
    // run every day/week/month (at a given start-time).
    // Full backup schedule can never be a continuous schedule, so if it's present then start time is needed, otherwise
    // we need to check the value for the incremental backup schedule.
    return !full && continuousScheduleUnits.includes(incremental.schedule.unit as PolicyScheduleUnit);
  }

  /**
   * Form control init.
   */
  initFormControl() {
    if (this.protectionGroup && this.protectionGroup.time && this.protectionGroup.timeZone) {
      const { time, timeZone } = this.protectionGroup;
      this.startTimeFormGroup.setValue({ time, timeZone });
    }
  }
}

import { Injectable } from '@angular/core';
import {
  GetRegistrationInfoResponse,
  HeliosStatsServiceApi,
  ProtectedObjectsSummary,
  ProtectedObjectsSummaryByEnv,
  ProtectionSourcesServiceApi,
  StatsServiceApi,
} from '@cohesity/api/v1';
import { IrisContextService, isMcm, isTenantUser } from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';
import { SeriesPieOptions, PointOptionsObject } from 'highcharts';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppStateService } from 'src/app/core/services';
import { enumGroupMap, Environment, GroupData } from 'src/app/shared/constants';

/**
 * Type for excluded environments.
 */
type ExcludedEnvType = Environment.kView | Environment.kAgent;
type ProtectionSummaryEnv =
  'kVMware' | 'kHyperV' | 'kSQL' | 'kPuppeteer' | 'kPhysical' | 'kPure' | 'kIbmFlashSystem'
  | 'kNimble' | 'kAzure' | 'kNetapp' | 'kGenericNas' | 'kAcropolis' | 'kAWSSnapshotManager';

/**
 * Processed protection data based on APIs.
 */
export interface ProtectionData {
  /**
   * Total number of protected objects.
   */
  totalProtected?: number;

  /**
   * Total number of unprotected objects.
   */
  totalUnprotected?: number;

  /**
   * Column chart series.
   */
  series?: SeriesPieOptions[];

  /**
   * Column chart categories.
   */
  categories?: string[];
}

/**
 * @description
 * Protection card service.
 */
@Injectable()
export class ProtectionCardService {
  constructor(
    private heliosStatsService: HeliosStatsServiceApi,
    private statsService: StatsServiceApi,
    private protectionSourcesServiceApi: ProtectionSourcesServiceApi,
    private appStateService: AppStateService,
    private irisContextService: IrisContextService,
    private translate: TranslateService,
  ) {}

  /**
   * Returns observable of `ProtectedObjectsSummary` based on MCM or standalone cluster.
   */
  private getSummary(): Observable<ProtectedObjectsSummary> {
    // Exclude kView and kAgent because backend does not have the unprotected objects data
    // for these 2 environments.
    const excludeTypes: ExcludedEnvType[] = [Environment.kView, Environment.kAgent];

    const irisCtx = this.irisContextService.irisContext;

    if (isMcm(irisCtx)) {
      const { clusterId, clusterIncarnationId } = this.appStateService.selectedScope;
      const mcmParams: HeliosStatsServiceApi.McmProtectionSummaryParams = {
        excludeTypes,
      };

      if (clusterId && clusterIncarnationId) {
        mcmParams.clusterIdentifiers = [`${clusterId}:${clusterIncarnationId}`];
      }

      return this.heliosStatsService.McmProtectionSummary(mcmParams);
    } else if(isTenantUser(irisCtx)) {
      return this.getSummaryFromRegistrationInfo(excludeTypes);
    }

    return this.statsService.GetProtectedObjectsSummary({ excludeTypes });
  }

  /**
   * Retrieves protection source registration information and maps it to the ProtectionSummary API data points.
   *
   * This mapping is necessary because the ProtectionSummary API is not tenant-aware, and we need to provide
   * tenant-specific data based on the protection source registration information.
   *
   * @params    excludeTypes Env types to exclude.
   * @returns   Observable of ProtectedObjectsSummary.
   */
  private getSummaryFromRegistrationInfo(excludeTypes = []): Observable<ProtectedObjectsSummary> {
    // ignoring kPhysical file since it was excluded in ProtectionSummary API response
    excludeTypes = [
      ...excludeTypes,
      'kPhysicalFiles'
    ];
    return this.protectionSourcesServiceApi
      .ListProtectionSourcesRegistrationInfo({
        pruneNonCriticalInfo: true,
        includeExternalMetadata: true,
        includeEntityPermissionInfo: true,
        includeApplicationsTreeInfo: false,
        allUnderHierarchy: true,
      })
      .pipe(
        map((sources: GetRegistrationInfoResponse) => {
          const {
            stats = { protectedCount: 0, unprotectedCount: 0, protectedSize: 0, unprotectedSize: 0 },
            statsByEnv = [],
          } = sources || {};

          return {
            numObjectsProtected: stats.protectedCount,
            numObjectsUnprotected: stats.unprotectedCount,
            protectedSizeBytes: stats.protectedSize,
            unprotectedSizeBytes: stats.unprotectedSize,
            statsByEnv: statsByEnv
              // Filter out invalid environments Excluded 'kView and 'kAgent'
              .filter(statByEnv => !excludeTypes.includes(statByEnv.environment))
              .map(
                (statByEnv) => ({
                  environment: statByEnv.environment as ProtectionSummaryEnv,
                  numObjectsProtected: statByEnv.protectedCount,
                  numObjectsUnprotected: statByEnv.unprotectedCount,
                  protectedSizeBytes: statByEnv.protectedSize,
                  unprotectedSizeBytes: statByEnv.unprotectedSize,
                })
              ),
          };
        })
      );
  }

  /**
   * Gets protection data.
   *
   * @returns   Observable of ProtectionData.
   */
  getProtectionData(): Observable<ProtectionData> {
    return this.getSummary().pipe(map((summary: ProtectedObjectsSummary) => {
      const protectionData: ProtectionData = {
        totalProtected: 0,
        totalUnprotected: 0,
        categories: [],
        series: []
      };

      const objectData: GroupData = {};

      summary.statsByEnv.forEach((stat: ProtectedObjectsSummaryByEnv) => {
        const { environment, numObjectsProtected, numObjectsUnprotected } = stat;

        // Adding new category label for AWS environment pending when api support is available
        // for More granular AWS environment types (EC2, RDS, S3)
        const category = environment === Environment.kAWS ? 'aws' : enumGroupMap[environment] || 'other';

        if (!(category in objectData)) {
          objectData[category] = [0, 0];
        }

        const data = objectData[category];
        data[0] += numObjectsProtected;
        data[1] += numObjectsUnprotected;

        // Update the total count of all protected and unprotected objects
        protectionData.totalProtected += numObjectsProtected;
        protectionData.totalUnprotected += numObjectsUnprotected;
      });

      // Parse objectData into an array: [ category, protectedVal, unprotectedVal ]
      let categoryValues = Object.keys(objectData).map(cat => [cat, ...objectData[cat]]);

      // Sort values by cumulative number of objects first so top object types
      // will be displayed explicitly in the chart and others will get rolled up
      // into "other."
      categoryValues.sort((a, b) => (b[1] + b[2]) - (a[1] + a[2]));

      if (categoryValues.length > 3) {
        const other = categoryValues.slice(2).reduce((c, a) => [c[0], c[1] + a[1], c[2] + a[2]], ['other', 0, 0]);
        categoryValues = categoryValues.slice(0, 2).concat([other]);
      }

      // Now sort values by percentage protected. so that the protected percentage
      // decreases in the chart's concentric circles (visually pleasing).
      categoryValues.sort((a, b) => (b[1] / (b[1] + b[2])) - (a[1] / (a[1] + a[2])));

      // And finally, build the chart data.
      categoryValues.forEach(catValue => {
        const categoryName = this.translate.instant(`enum.envGroup.${catValue[0]}`);
        const catData: PointOptionsObject[] = [
          {
            name: this.translate.instant('protected'),
            y: catValue[1],
          },
          {
            name: this.translate.instant('unprotected'),
            y: catValue[2],
          },
        ].map(({ name, y }) => {
          const description = [y, categoryName, name].join(' ');

          return { name, y, description, accessibility: { description }};
        });

        protectionData.series.push({
          type: 'pie',
          name: categoryName,
          accessibility: {
            point: {
              // Need to have empty content here but not empty string which will
              // cause Highcharts to insert unnecessary default content.
              valueDescriptionFormat: ' ',
            },
          },
          data: catData,
        });
      });

      return protectionData;
    }));
  }
}


import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormGroup } from '@angular/forms';
import { MsalRedirectComponent, MsalService } from '@azure/msal-angular';
import { AccountInfo, AuthenticationResult, EventMessage, EventType, SilentRequest } from '@azure/msal-browser';
import { DeleteAzureApplicationRequestParams } from '@cohesity/api/v2';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map, switchMap, take, takeUntil } from 'rxjs/operators';

import {
  AzureUseCaseType,
  AzureRegistrationType,
  ExpressRegistrationStatus,
  ExpressRegistrationStep,
  GraphScope,
  ManagementScope,
  statusIconMap,
  stepTitleMap,
  subcription,
  SubscriptionUrl
} from 'src/app/shared';
import {
  AzureSourceFormModel,
  AzureSubscriptionsResponse,
  DmsAzureSourceDetailModel
} from './azure-source-registration-form.model';

/**
 * Maps each step in the process to its status.
 *
 * This type is used to track the status of different steps like authorization or deletion
 * during registration or unregistration workflows.
 */
export type StepTypeStatus = {
  [key in ExpressRegistrationStep]?: ExpressRegistrationStatus;
};

/**
 * Service for managing Azure Express Registration operations.
 *
 * This service handles authentication, creation, and management of Azure
 * applications. It interacts with various Azure services to create or update
 * applications, acquire tokens, and fetch subscription data. It also manages
 * the state of the application registration process and provides utility
 * methods for handling the registration steps and statuses.
 */
@Injectable()
export class AzureExpressRegistrationService extends MsalRedirectComponent {
  /**
   * BehaviorSubject to hold subscriptions data.
   */
  subscriptionsData$ = new BehaviorSubject<AzureSubscriptionsResponse>({
    count: 0,
    subscriptions: []
  });

  /**
   * Observable for cleaning up subscriptions.
   */
  private destroyed$ = new Subject<void>();

  /**
   * Constructs an instance of the AzureExpressRegistrationService.
   *
   * @param http HttpClient for making HTTP requests.
   * @param msalAuthService MsalService for handling authentication.
   * @param translateService Service for handling translations.
   */
  constructor(
    private http: HttpClient,
    private msalAuthService: MsalService,
    private translateService: TranslateService,
  ) {
    super(msalAuthService);
  }

  /**
   * Acquires management token silently.
   *
   * This function ensures the active account is set before attempting to
   * acquire the management token. It then creates a SilentRequest object
   * using the createMgmtAuthRequest function and attempts to acquire the
   * token silently.
   *
   * @returns Observable of the management token acquisition result.
   */
  acquireMgmtTokenSilently(): Observable<AuthenticationResult> {
    this.checkAndSetActiveAccount();
    const mgmtAuthRequest = this.createMgmtAuthRequest();

    return this.msalAuthService.acquireTokenSilent(mgmtAuthRequest).pipe(
      catchError(error => throwError(error)),
      takeUntil(this.destroyed$)
    );
  }

  /**
   * Fetches Azure subscriptions using the management token.
   *
   * @returns Observable of the fetched subscriptions.
   */
  fetchSubscriptions(): Observable<AzureSubscriptionsResponse> {
    return this.acquireMgmtTokenSilently().pipe(
      switchMap((response: AuthenticationResult) => {
        const headers = new HttpHeaders({
          'Authorization': `Bearer ${response?.accessToken}`
        });

        return this.http.get<any>(SubscriptionUrl, { headers });
      }),
      map((response) => {
        const subscriptions = response?.value.map((sub: any) => ({
          id: sub.id.replace(subcription, ''),
          displayName: sub.displayName
        })) || [];
        const count = subscriptions.length;

        // Update BehaviorSubject with new data
        this.subscriptionsData$.next({ count, subscriptions });
        return { count, subscriptions };
      }),
      // TODO (Abhishek): Add more error handling in separate CR.
      catchError(error => throwError(error))
    );
  }

  /**
   * Sets the active account within the MSAL Authentication service if
   * no active account is set but the user is logged in.
   *
   * @returns void
   */
  checkAndSetActiveAccount(): void {
    const activeAccount = this.msalAuthService.instance.getActiveAccount();
    if (!activeAccount
      && this.msalAuthService.instance.getAllAccounts().length > 0) {

    const accounts = this.msalAuthService.instance.getAllAccounts();
    this.msalAuthService.instance.setActiveAccount(accounts[0]);
    }
  }

  /**
   * Creates and returns a SilentRequest object for Microsoft Graph API
   * authentication.
   *
   * This function constructs a SilentRequest object that includes the necessary
   * scopes for accessing the Microsoft Graph API and sets the provided account
   * or the active account from the MSAL Authentication service instance.
   *
   * @param account Optional account information. If not provided, the active
   * account is used.
   * @returns The authentication request object for Microsoft Graph API.
   */
  createGraphAuthRequest(account?: AccountInfo): SilentRequest {
    const selectedAccount = account
      || this.msalAuthService.instance.getActiveAccount();

    return {
      scopes: [GraphScope],
      account: selectedAccount,
    };
  }

  /**
   * Creates and returns a SilentRequest object for Azure Management API
   * authentication.
   *
   * This function constructs a SilentRequest object that includes the
   * necessary scopes for accessing the Azure Management API and sets the
   * active account from the MSAL Authentication service instance.
   *
   * @returns The authentication request object for Azure Management API.
   */
  createMgmtAuthRequest(): SilentRequest {
    return {
      scopes: [ManagementScope],
      account: this.msalAuthService.instance.getActiveAccount(),
    };
  }

  /**
   * Checks if the provided use cases include either kVirtualMachine or kSQL.
   *
   * @param useCases An array of AzureUseCaseType to be checked.
   * @returns True if kVirtualMachine or kSQL is included, otherwise false.
   */
  isVmOrSqlUseCase(useCases: AzureUseCaseType[]): boolean {
    return useCases.includes(AzureUseCaseType.kVirtualMachine) ||
          useCases.includes(AzureUseCaseType.kSQL);
  }

  /**
   * Retrieves the Azure use cases based on the provided source details.
   * The source details object should contain information about various Azure services.
   *
   * @param sourceDetails The source details containing information about services.
   *                      Should be an object of type DmsAzureSourceDetailModel.
   * @returns An array of AzureUseCaseType based on the provided source details.
   *          Returns an empty array if sourceDetails is null or undefined.
   */
  getUseCases(sourceDetails: DmsAzureSourceDetailModel): AzureUseCaseType[] {
    const useCases: AzureUseCaseType[] = [];

    if (sourceDetails) {
      const { vmService, sqlService, entraIdService } = sourceDetails;

      if (vmService) {
        useCases.push(AzureUseCaseType.kVirtualMachine);
      }
      if (sqlService) {
        useCases.push(AzureUseCaseType.kSQL);
      }
      if (entraIdService) {
        useCases.push(AzureUseCaseType.kEntraID);
      }
    }

    return useCases;
  }

  /**
   * Generates the status details and translated message for a given
   * registration step.
   *
   * @param step The current step of the express registration process.
   * @param status The current status of the step. If not provided, it
   * defaults to the status from registrationStatus.
   * @param registrationStatus An object containing the statuses of all
   * registration steps.
   * @param subscriptionCount The number of subscriptions discovered,
   * used as a parameter for the translation message if applicable.
   * @returns An object containing the implicit context for the template,
   * including the message, icon state, and title for the step.
   */
  getStepStatusDetailsWithMessage(
    step: ExpressRegistrationStep,
    status: ExpressRegistrationStatus,
    registrationStatus: { [key in ExpressRegistrationStep]?: ExpressRegistrationStatus },
    subscriptionCount?: number
  ): { $implicit: any } {
    const currentStatus = status ?? registrationStatus[step];
    const params = (step === ExpressRegistrationStep.SUBSCRIPTION_DISCOVERY &&
                    currentStatus === ExpressRegistrationStatus.SUCCESS)
      ? { n: subscriptionCount }
      : null;

    // Example key format: 'sources.azure.apps.authorizationInProgress'
    const translationKey = `sources.azure.apps.${step}${currentStatus}`;
    const message = this.translateService.instant(translationKey, params);

    // Return an object containing the message, icon state, and title for the step,
    // to be used as the implicit context in the template.
    return {
      $implicit: {
        message,
        iconState: statusIconMap[currentStatus],
        title: stepTitleMap[step]
      }
    };
  }

  /**
   * Retrieves the translated message for each step and status.
   *
   * @param step The registration step.
   * @param status Current status.
   * @param params Additional translation key mappings.
   * @returns Translated message.
   */
  getStatusMessage(
    step: ExpressRegistrationStep,
    status: ExpressRegistrationStatus,
    params: Record<string, string | number> = {}
  ): string {
    // Example key format: 'sources.azure.apps.authorizationInProgress'
    const translationKey = `sources.azure.apps.${step}${status}`;

    return this.translateService.instant(translationKey, params);
  }

  /**
   * Creates a validator function for the encryption key control.
   *
   * @param sourceFormGroup The source form group containing the registration workflow.
   * @param editData$ Observable value for the edit model.
   * @returns AsyncValidatorFn function.
   */
   encryptionKeyValidationFn(
    sourceFormGroup: FormGroup,
    editData$: Observable<AzureSourceFormModel>
  ): AsyncValidatorFn {
    const regTypeControl = sourceFormGroup.get('registrationWorkflow');
    return (control: AbstractControl) => {
      // Validate encryption key based on registration type
      if (
        control.value?.length ||
        !regTypeControl ||
        regTypeControl.value === AzureRegistrationType.EXPRESS
      ) {
        return of(null);
      }
      return editData$.pipe(
        take(1),
        map((editData) => {
          if (editData) {
            return null;
          }
          if (regTypeControl.value === AzureRegistrationType.MANUAL) {
            return { 'required': true };
          }
          return null;
        })
      );
    };
  }

  /**
   * Handles authentication events emitted by the MsalBroadcastService.
   * Updates the registration status based on the type of event received.
   *
   * @param eventMessage The event message containing details about th
   * authentication event.
   * @param regOrUnregStepTypeStatus The status to update, which may represent
   * either registration or unregistration.
   */
  handleAuthEvent(eventMessage: EventMessage, regOrUnregStepTypeStatus: StepTypeStatus) {
    const statusMapping = {
      [EventType.ACQUIRE_TOKEN_START]: ExpressRegistrationStatus.IN_PROGRESS,
      [EventType.ACQUIRE_TOKEN_FAILURE]: ExpressRegistrationStatus.FAILED,
      [EventType.ACQUIRE_TOKEN_SUCCESS]: ExpressRegistrationStatus.SUCCESS
    };

    const status = statusMapping[eventMessage.eventType];
    if (status) {
      this.updateStatus(
        regOrUnregStepTypeStatus,
        ExpressRegistrationStep.AUTHORIZATION,
        status
      );
    }
  }

  /**
   * Updates the status for a specific registration step in the given registration status object.
   *
   * @param registrationStatus An object mapping registration steps to their current statuses.
   * @param step The registration step to update.
   * @param status The new status to set for the specified registration step.
   */
  updateStatus(
    registrationStatus: { [key in ExpressRegistrationStep]?: ExpressRegistrationStatus },
    step: ExpressRegistrationStep,
    status: ExpressRegistrationStatus
  ): void {
    registrationStatus[step] = status;
  }

  /**
   * Generates the parameters for deleting Azure applications.
   *
   * This method acquires an access token and constructs the required parameters
   * from the provided `formGroup` to be used in the deletion request.
   *
   * @param formGroup - The form group containing details needed for the request.
   * @returns An `Observable` emitting the parameters for the delete API call.
   */
  generateAzureAppDeleteParams(formGroup: FormGroup): Observable<DeleteAzureApplicationRequestParams> {
    const graphAuthRequest = this.createGraphAuthRequest();

    return this.msalAuthService.acquireTokenSilent(graphAuthRequest).pipe(
      switchMap(response => {
        const azureAppDeleteParams: DeleteAzureApplicationRequestParams = {
          accessToken: response.accessToken,
          azureTenantId: formGroup.value?.sourceDetails?.domainName ?? '',
          azureApplicationsList: formGroup.value?.azureApps?.applicationId
            ? [{ clientId: formGroup.value.azureApps.applicationId }]
            : []
        };

        return of(azureAppDeleteParams); // Return as Observable
      })
    );
  }

}

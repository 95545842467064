import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatStepperModule } from '@angular/material/stepper';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

import { DataIdModule } from '../data-id/data-id.module';
import { HelixAssetsModule } from '../helix-assets';
import { IconModule } from '../icon';
import { StepperHeaderModule } from '../stepper-header';
import { OnboardingToolbarComponent } from './onboarding-toolbar/onboarding-toolbar.component';

@NgModule({
  imports: [
    CommonModule,
    DataIdModule,
    HelixAssetsModule,
    IconModule,
    MatButtonModule,
    MatStepperModule,
    MatToolbarModule,
    MatTooltipModule,
    StepperHeaderModule,
    OnboardingToolbarComponent,
  ],
  exports: [OnboardingToolbarComponent],
})
export class OnboardingWizardModule {}

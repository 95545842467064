import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '../icon/icon.module';
import { StepperHeaderComponent } from './stepper-header.component';
import { StepperDirective } from './stepper.directive';

@NgModule({
  imports: [CommonModule, IconModule, StepperHeaderComponent, StepperDirective],
  exports: [StepperHeaderComponent, StepperDirective],
})
export class StepperHeaderModule {}

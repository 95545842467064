import { BANNER_PRIORITY, BannerConfig } from '@cohesity/helix';
import { BaseBannerConfig, flagEnabled, IrisContext, IrisContextService } from '@cohesity/iris-core';
import { ClusterService, PlatformService } from 'src/app/core/services';
import { combineLatest } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { StateService } from '@uirouter/core';
import { TranslateService } from '@ngx-translate/core';
import { uniq } from 'lodash-es';
/**
 * Banner to notify user that disks are identified for physical removal.
 */
@Injectable({
  providedIn: 'root',
})
export class DiskRemovalBannerConfig extends BaseBannerConfig {
  /**
   * Unique banner representation
   */
  id = 'ng-cluster-disk-removal-banner';

  diskCount: number;

  /**
   * provides banner config as observable
   */
  bannerInfo$ = combineLatest([
    this.irisCtx.irisContext$,
    this.platformService.getAllClusterDisks()
    ]).pipe(
      filter(([_, disks]) => !!disks),
      tap(() => {
        this.diskCount = 0;
        for (const key in this.platformService.disksGroupedByNodeId) {
          if (this.platformService.disksGroupedByNodeId[key].autoRemoved?.length) {
            this.diskCount += uniq(this.platformService.disksGroupedByNodeId[key].autoRemoved).length;
          }
        }
      }),
      map(([ctx, _]) =>
        ([{
          id: this.id,
          priority: BANNER_PRIORITY.HIGH,
          isVisible: this.allowBanner(ctx),
          status: 'warn',
          allowClose: true,
          text: this.translateService.instant(
            `cluster.disk.removal.banner.info.${this.diskCount > 1 ? 'plural' : 'singular'}`,
            {number: this.diskCount}),
          closeCallback: this.closeCallback,
          actionText: this.translateService.instant('view'),
          actionCallback: () => {
            this.stateService.go('health.alerts', { alertCategoryList: 'kDisk' });
          },
        } as BannerConfig])
      )
  );

  constructor(
    private clusterService: ClusterService,
    private irisCtx: IrisContextService,
    private platformService: PlatformService,
    private stateService: StateService,
    private translateService: TranslateService
  ) {
    super();
  }

  /**
   * Allow banner based on context
   *
   * @param ctx iris context
   * @returns true/false based on context
   */
  allowBanner(ctx: IrisContext): boolean {
    return (
      this.isLoggedIn(ctx) &&
      !ctx.selectedScope._allClusters &&
      !this.isBannerAcknowledged(this.id) &&
      flagEnabled(ctx, 'hardwareNodeAndDiskRemovalNewFlow') &&
      this.diskCount > 0
    );
  }
}

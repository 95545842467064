import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { IconModule } from '../icon/icon.module';
import { StatlistItemComponent } from './statlist-item/statlist-item.component';
import { StatlistComponent } from './statlist.component';

const COMPONENTS = [StatlistComponent, StatlistItemComponent];

@NgModule({
  imports: [CommonModule, IconModule, MatIconModule, ...COMPONENTS],
  exports: COMPONENTS,
})
export class StatlistModule {}

// MODULE: Cluster Details Page
import { getConfigByKey, isOneHeliosAppliance } from '@cohesity/iris-core';

import { createNetmaskAddr } from '@cohesity/utils';
import { KmsModel } from 'src/app/modules/cluster/kms/models/kms.model';

;(function(angular, undefined) {

  angular.module('C.clusterDetails', ['C.twoFactorAuth', 'C.kmsSelector'])
    .config(configFn)
    .controller('clusterDetailsController', clusterDetailsControllerFn);

  function configFn($stateProvider, $urlRouterProvider) {
    $stateProvider
      .state('cluster-settings', {
        name: 'Cohesity Cluster Details',
        url: '/admin/cluster-settings',
        help: 'admin_cluster_details',
        title: 'Cluster Details',
        canAccess: 'CLUSTER_MODIFY',
        parentState: 'cluster',
        templateUrl: 'app/admin/cluster/details/details.html',
        controller: 'clusterDetailsController as $ctrl',
      });
  }


  function clusterDetailsControllerFn($rootScope, $q, $scope, $state,
    ClusterService, SmtpService, cUtils, cMessage, evalAJAX, UserService,
    FORMATS, cModal, FEATURE_FLAGS, _, ProxyService, IP_FAMILY,
    NetworkService, DateTimeService, TIME, DAY_POS, BACKGROUND_IO_RATE_VALUE,
    NgClusterService, ngDialogService, NgClusterGflagService, $translate, NgIrisContextService,
    NgAppStateService) {

    var $ctrl = this;

    $ctrl.isOneHelios = isOneHeliosAppliance(NgIrisContextService.irisContext);
    $ctrl.clusterEditFlags = {
      organizationManagement: getConfigByKey(
        NgIrisContextService.irisContext,
        'cluster.editConfigurations.organizationManagement',
        true
      ),
      cloudTier: getConfigByKey(NgIrisContextService.irisContext, 'cluster.editConfigurations.cloudTier', true),
      certificateBasedAuth: getConfigByKey(
        NgIrisContextService.irisContext,
        'cluster.editConfigurations.certificateBasedAuth',
        true
      ),
      loginBanner: getConfigByKey(NgIrisContextService.irisContext, 'cluster.editConfigurations.loginBanner', true),
      documentation: getConfigByKey(NgIrisContextService.irisContext, 'cluster.editConfigurations.documentation', true),
      customFailureDomain: getConfigByKey(
        NgIrisContextService.irisContext,
        'cluster.editConfigurations.customFailureDomain',
        true
      ),
      s3VirtualHostedDomainNames: getConfigByKey(
        NgIrisContextService.irisContext,
        'cluster.editConfigurations.s3VirtualHostedDomainNames',
        true
      ),
      autoPatchesDownload: getConfigByKey(
        NgIrisContextService.irisContext,
        'cluster.editConfigurations.autoPatchesDownload',
        true
      ),
      ldapOverSSL: getConfigByKey(NgIrisContextService.irisContext, 'cluster.editConfigurations.ldapOverSSL', true),
      backgroundTaskThrottlingEnabled: getConfigByKey(
        NgIrisContextService.irisContext,
        'cluster.editConfigurations.backgroundTaskThrottlingEnabled',
        true
      ),
      domainNames: getConfigByKey(
        NgIrisContextService.irisContext,
        'cluster.editConfigurations.domainNames',
        true
      ),
      appsInternalNetwork: getConfigByKey(
        NgIrisContextService.irisContext,
        'cluster.editConfigurations.appsInternalNetwork',
        true
      ),
    };

    var mcmMode = _.get($rootScope, 'basicClusterInfo.mcmMode');
    var isPhysicalInstall = $rootScope.clusterInfo._isPhysicalInstall;

    // Set proxyVisible boolean as proxy settings should not be shown on Helios
    var proxyVisible = !mcmMode;

    var tieringInfo;

    _.assign($ctrl, {
      FORMATS: FORMATS,
      addNTPAuthField: addNTPAuthField,
      appsManagementEnabled: !mcmMode && FEATURE_FLAGS.appsManagementEnabled,

      // Apps Management
      apps : {
        subnet: {},
        subnetCIDR: '',
        subnetV6: {},
        subnetCIDRV6: '',
        subnets: [],
      },

      $onInit: $onInit,
      openAssignRacksDialog: openAssignRacksDialog,
      removeNTPAuthField: removeNTPAuthField,
      ntpSettingsToggleChange: ntpSettingsToggleChange
    });

    // Proxy Setup
    if(proxyVisible){
      _.assign($ctrl, {
        proxy: {
          info: {
            services: [],
            isDisabled: true,
            schemes:['http','https'],
          },
          requiresPassword: false,
          supportedServices: [],
          allowAllSelection: false,
          selectAllServices: false,
          serviceSelectionChanged: false,
          showNtpWithAuth: false,
        },

        // functions
        toggleProxyServiceSelection : toggleProxyServiceSelection,
        toggleProxySelectAll : toggleProxySelectAll,
      });
    }

    // List of failure options for ui-select
    $scope.failuresList = [
      {
        value: 1,
        percentage: 50,
        minNodes: 3,
      }, {
        value: 2,
        percentage: 33,
        minNodes: 5,
      }
    ];

    $scope.cUtils = cUtils;

    $scope.text = $rootScope.text.clusterDetails;

    $scope.errorText = $rootScope.text.clusterDetails.errorText;

    $scope.IP_FAMILY = IP_FAMILY;

    $scope.clusterDetails = {
      ntps: [],
      dnsServerIps: [],
    };
    $scope.smtp = {
      // We want the SMTP see-more default closed.
      disableSmtp: true,
    };

    $scope.bandwidthLimit = {};
    $scope.oldBandwidthLimit = {};
    $scope._bandwidthLimit = {};
    // TODO(akshith): Move BackgroundIoRate constants to app/shared/constants
    // (ENG-105576).
    $scope.ioRateValues = ['kHigh', 'kMedium', 'kLow', 'kNoActivity'];
    $scope.bgThrottlingThreshold = 50.0;

    $scope.sendTestEmail = false;

    // Manual validation flags for c-input-tags
    $scope.DNSServerValid = true;
    $scope.ntpsValid = true;
    $scope.domainNamesValid = true;
    $scope.s3virtualHostedDomainNamesValid = true;

    // Enable multi-tenancy checkboxes
    $scope.tenants = {
      multiTenancyEnabled: false,
      tenantViewboxSharingEnabled: false,
    };

    // Agent Port Upgrade Settings
    $scope.portSettings = {
      attemptAgentPortsUpgrade: null
    };

    // Assign _allChassisAssigned to false by default.
    $scope.clusterDetails._allChassisAssigned = false;

    // Assign submitting subnets boolean variable to false.
    $scope.submittingSubnets = false;

    // Validation regex pattern for FQDN and IPv4/IPv6 addresses.
    $scope.fqdnIpv4Ipv6Pattern = new RegExp(`${FORMATS.fqdnRegExp.source}|${FORMATS.IPv4AndIPv6.source}`).valueOf();

    /**
     * Initialization
     *
     * @method     $onInit
     */
    function $onInit() {
      var getClusterParams = {
        fetchStats: true
      };

      var promises = {
        clusterInfo: ClusterService.getClusterInfo(getClusterParams, {}, true),
        ntpServers: ClusterService.getNTPservers(),
        apolloThrottlingInfo: ClusterService.getApolloThrottlingInfo(),
        gflagResponse: NgClusterGflagService.getGflagData().toPromise(),
        basicClusterInfo: ClusterService.getBasicClusterInfo(),
        tieringInfo: ClusterService.getTieringInfo(),
      };

      if (!FEATURE_FLAGS.applianceManagerUIChangesForGA) {
        _.assign(promises, {
          smtpConfig: SmtpService.getSMTPConf(),
        });
      }

      if (FEATURE_FLAGS.loginBanner) {
        _.assign(promises, {
          loginBanner: ClusterService.getLoginBannerInfo(),
        });
      }

      if (proxyVisible) {
        // Get static values for proxy services
        $ctrl.proxy.supportedServices = ProxyService.getProxySupportedServices();
        $ctrl.proxy.allowAllSelection = $ctrl.proxy.supportedServices.length > 1;

        // If there is only one supported service; then add that as default selection
        // And avoid showing all selection div
        if (!$ctrl.proxy.allowAllSelection) {
          $ctrl.proxy.info.services = $ctrl.proxy.supportedServices;
        }

        // Add proxy service call
        promises.proxyInfo = ProxyService.getProxyConf();
      }

      // Get cluster subnets
      promises.subnets = NetworkService.getClusterSubnets();

      NgClusterService.getChassisInfo(true).toPromise().then(
        function getChassisSuccess(res) {
          // Determine if all chassis have been assigned to racks.
          $scope.clusterDetails._allChassisAssigned =
            !res.chassis.length;
      }, evalAJAX.errorMessage);

      $q.all(promises).then(
        function getAllSuccess(r) {
          // Integrate cluster info
          $scope.clusterDetails =
            angular.extend($scope.clusterDetails, r.clusterInfo.data);

          // IP Preference for the cluster: 1 means ipv4 and 2 means ipv6
          $ctrl.isIpPreferenceV6 = $scope.clusterDetails.ipPreference === 2;

          if ($ctrl.isIpPreferenceV6) {
            document.querySelector("label[for='apps-management-subnet']").classList.remove('required');
          }

          // Enable Custom Failure Domain selection if
          $scope.customFailureDomainsEnabled =
            // Feature Flag is enabled AND
            FEATURE_FLAGS.customFailureDomain &&

            // Cluster is physical. It needs to have chassis and racks.
            $scope.clusterDetails._isPhysicalInstall;

          //Check gflag value and set ntp auth field.
          $scope.showNtpWithAuth = NgClusterGflagService.getBoolValue('kNexusProxy','nexus_proxy_use_auth_ntp_server', true);

          // Integrate NTP servers
          $scope.clusterDetails.ntps = r.ntpServers.data.ntpServers || [];

          // Integrate NTP servers auth data
          $scope.ntpData = getNtpObject(r.ntpServers.data.ntpServers, r.ntpServers.data.ntpServerAuthInfo);

          // Determine if Multi-tenancy is enabled
          if (FEATURE_FLAGS.multiTenancyEnabled) {
            $scope.tenants = _.pick($scope.clusterDetails,
              ['multiTenancyEnabled', 'tenantViewboxSharingEnabled']);
          }

          if (!FEATURE_FLAGS.applianceManagerUIChangesForGA) {
            // Integrate SMTP config
            angular.extend($scope.smtp, r.smtpConfig);

            // Password is never exposed in the response, but we want to know if a
            // password is already stored. => If username is stored then we know the
            // password was stored.
            $scope.savedSmtpPassword = $scope.smtp.username;

            // The backend disallows nil fields in PUT, so we used placeholder
            // values if user cleared those fields. Now we need to remove those
            // placeholders if present.
            $scope.smtp.port = $scope.smtp.port === 0 ? '' : $scope.smtp.port;
            $scope.smtp.server = $scope.smtp.server === 'smtp.server' ? '' : $scope.smtp.server;
          }

          // Integrate apollo bandwidth info.
          $scope.bandwidthLimit = r.apolloThrottlingInfo.data || {};
          $scope.initializeBgTaskThrottling();

          // Save the entire tieringInfo object, because after we update one
          // value we have to send back the whole object during the put
          // operation.
          tieringInfo = r.tieringInfo;
          $scope.clusterDetails.downWaterfallThresholdPct =
            tieringInfo.downTierUsagePercentThresholds[2];

          if (proxyVisible && r.proxyInfo.length > 0) {
            $ctrl.proxy.info.isDisabled = false;
            // Process proxy information to set additional values.
            processProxyInfo(r.proxyInfo[0]);
          }

          // Subnet information for apps management.
          _updateAppsSubnet(r.subnets);

          // Login Banner
          if (FEATURE_FLAGS.loginBanner) {
            $ctrl.bannerText = r.loginBanner.content;
          }

          $scope.kmsServerId = $scope.clusterDetails.kmsServerId;

          // Copy to use it  for checking if the value was updated
          $scope.hardwareEncryptionEnabled = $scope.clusterDetails.hardwareEncryptionEnabled;

          if (FEATURE_FLAGS.agentPortUpgradeSettingsEnabled) {
            // Attempt agent port upgrade
            $scope.portSettings.attemptAgentPortsUpgrade = $scope.clusterDetails.attemptAgentPortsUpgrade;
          }
        },
        evalAJAX.errorMessage
      ).finally(
        function getAllFinally() {
          $scope.dataReady = true;
        }
      );
    }

    /**
     * Validates NTP server before adding tag to clusterDetails.ntps
     *
     * @method     addNTP
     * @param      {string}  value   The value
     * @return     {String/Boolean}  String value if valid, false if
     *                               invalid
     */
    $scope.addNTP = function addNTP(value) {
      // Clean out commas because ui-select doesn't do it
      value = value.replace(',', '');

      // Don't do anything if emtpy value is entered.
      if (!value) {
        return false;
      }
      if ($scope.fqdnIpv4Ipv6Pattern.test(value)) {
        $scope.ntpsValid = true;
        $ctrl.addNTPAuthField(value);
        return $scope.ntpData[$scope.ntpData.length - 1];
      } else {
        $scope.ntpsValid = false;
        return false;
      }
    };

    /**
     * On ntp Server choice delete.
     *
     * @method onNtpServerRemove
     * @param ntpServer name.
     */
    $scope.onNtpServerRemove = function onNtpServerRemove(ntpServer) {
      var index = $scope.ntpData.findIndex(x => x.ntpServer === ntpServer);
      $scope.ntpData.splice(index, 1);
    }

    /**
     * Initialize the missing fields in $scope.bandwidthLimit to default values
     * and maintain a copy of it.
     *
     * @method   initializeBgTaskThrottling
     */
    $scope.initializeBgTaskThrottling = function initializeBgTaskThrottling() {
      if (!$scope.bandwidthLimit.ioRate) {
        $scope.bandwidthLimit.ioRate = 'kHigh';
      }

      $scope.oldBandwidthLimit = _.cloneDeep($scope.bandwidthLimit);
      $scope.newThrottlingWindow = $scope.getDefaultThrottlingWindow();
    }

    /**
     * Create a default bandwidth override window.
     *
     * @method   getDefaultThrottlingWindow
     */
    $scope.getDefaultThrottlingWindow = function getDefaultThrottlingWindow() {
      return {
        timePeriods: {
          days: undefined,

          // The time input requires a Date object, but the date portion is
          // discarded by the input since it only handles time. Therefore the
          // incorrect date is irrelevant.
          startTime: {
            value: moment('09:00', 'HH:mm').toDate(),
          },
          endTime: {
            value: moment('17:00', 'HH:mm').toDate(),
          },
        },
        ioRate: 'kMedium',
      };
    };

    /**
     * Adds a new throttling window config to list.
     *
     * @method   addThrottlingWindow
     */
    $scope.addThrottlingWindow = function addThrottlingWindow() {
      if (!$scope.bandwidthLimit.bandwidthLimitOverrides) {
        $scope.bandwidthLimit.bandwidthLimitOverrides = [];
      }

      $scope.transformThrottlingWindow($scope.newThrottlingWindow);

      // if all day checkbox is selected, modify the times
      if ($scope.newThrottlingWindow.timePeriods.isAllDay) {
        $scope.newThrottlingWindow.timePeriods.startTime = {
          hour: 0,
          minute: 0,
        };
        $scope.newThrottlingWindow.timePeriods.endTime = {
          hour: 23,
          minute: 59,
        };
      }

      $scope.bandwidthLimit.bandwidthLimitOverrides.push(
        angular.copy($scope.newThrottlingWindow)
      );

      $scope.newThrottlingWindow = $scope.getDefaultThrottlingWindow();
      $ctrl.newThrottlingWindowForm.$setPristine();
    };

    /**
     * Transform throttle window object to have hour and minutes property to match api interface.
     * @method transformThrottlingWindow
     *
     * @param {object} windowObject Throttle window object.
     *
     */
    $scope.transformThrottlingWindow = function transformThrottlingWindow(windowObject) {

      // set hour and minute value for start time.
      windowObject.timePeriods.startTime.hour = windowObject.timePeriods.startTime.value.getHours();
      windowObject.timePeriods.startTime.minute = windowObject.timePeriods.startTime.value.getMinutes();

      // set hour and minute value for end time.
      windowObject.timePeriods.endTime.hour = windowObject.timePeriods.endTime.value.getHours();
      windowObject.timePeriods.endTime.minute = windowObject.timePeriods.endTime.value.getMinutes();

    };

    /**
     * Transform each window object to have start and end time value from hour and minute property.
     *
     * @param {object} windowList List of windows object.
     */
    $scope.transformFromThrottlingWindow = function transformFromThrottlingWindow(windowList) {

      // Iterate window object and set value property based on hour and minute.
      windowList.forEach(windowObject => {
        const startDate = new Date();
        const endDate = new Date();
        const startTime = windowObject.timePeriods.startTime;
        const endTime = windowObject.timePeriods.endTime;

        startTime.value = startDate.setHours(startTime.hour, startTime.minute);
        endTime.value = endDate.setHours(endTime.hour, endTime.minute);
      });
    };

    /**
     * deletes a throttling window setting from the list.
     *
     * @method   deleteThrottlingWindow
     * @param    {number}   indexToRemove   The index of throttling window
     *                                      config to remove
     */
    $scope.deleteThrottlingWindow = function deleteThrottlingWindow(
      indexToRemove) {
      $scope.bandwidthLimit.bandwidthLimitOverrides.splice(
        indexToRemove,
        1
      );
    };

    /**
     * Compares if the two bandwidth limits are equal or not.
     *
     * @method   hasBgTaskThrottlingChanged
     * @param    {object}   oldBwLimit   bandwidth limit before update
     * @param    {object}   curBwLimit   bandwidth limit after update
     *
     * @return   {Boolean}   Returns true if there is a change after update.
     */
    $scope.hasBgTaskThrottlingChanged = function hasBgTaskThrottlingChanged(
      oldBwLimit, curBwLimit) {
      if(oldBwLimit.timezone !== curBwLimit.timezone) return true;

      if(_.isEmpty(oldBwLimit.bandwidthLimitOverrides) &&
          _.isEmpty(curBwLimit.bandwidthLimitOverrides)) return false;

      if(_.isEmpty(oldBwLimit.bandwidthLimitOverrides) ||
          _.isEmpty(curBwLimit.bandwidthLimitOverrides)) return true;

      if(oldBwLimit.bandwidthLimitOverrides.length !==
          curBwLimit.bandwidthLimitOverrides.length) return true;

      var isChanged = false;

      oldBwLimit.bandwidthLimitOverrides.forEach(function(window, index) {
        if ((window.ioRate !==
          curBwLimit.bandwidthLimitOverrides[index].ioRate) ||
          (!_.isEqual(window.timePeriods,
          curBwLimit.bandwidthLimitOverrides[index].timePeriods))) {
            isChanged = true;
        }
      });

      return isChanged;
    };

    /**
     * getter setter for upload rate limit
     *
     * @method   getSetBgTaskThrottling
     * @param    {Boolean}  toSet        to set or get
     *
     * @return   {Object}   The rate limit configuration.
     */
    $scope.getSetBgTaskThrottling = function getSetBgTaskThrottling(toSet) {

      // arguments list would be present if used as setter
      if (angular.isDefined(toSet)) {
        if (toSet) {
          // load the previous value if it exists, else load default
          $scope.bandwidthLimit.bandwidthLimitOverrides =
            $scope._bandwidthLimit &&
              $scope._bandwidthLimit.bandwidthLimitOverrides ?
              $scope._bandwidthLimit.bandwidthLimitOverrides : [];
        } else {
          $scope._bandwidthLimit = $scope._bandwidthLimit || {};
          // cache the current value
          $scope._bandwidthLimit.bandwidthLimitOverrides =
            $scope.bandwidthLimit.bandwidthLimitOverrides;

          $scope.bandwidthLimit.bandwidthLimitOverrides = undefined;
        }
      }

      if ($scope.bandwidthLimit && $scope.bandwidthLimit.bandwidthLimitOverrides) {
        $scope.transformFromThrottlingWindow($scope.bandwidthLimit.bandwidthLimitOverrides);
      }

      return !!$scope.bandwidthLimit &&
        !!$scope.bandwidthLimit.bandwidthLimitOverrides;
    };

    /**
     * Given a time period and limit value to be applied across this duration,
     * update the existing mapping of background resource usage allocated at
     * various times of the day.
     *
     * @method   addLimitsForWindow
     * @param    {Object}  bgActivityLimits  Contains the mapping of time
     *                                       slots to the allocated resource
     *                                       percentage during that slot.
     *                                       End time of the slot in minutes
     *                                       is stored as key.
     * @param    {Object}  startTime         start time of the time period in
     *                                       which the limit values have to be
     *                                       applied
     * @param    {Object}  endTime           end time of the time period in
     *                                       which the limit values have to be
     *                                       applied
     * @param    {Object}  limitVal          The limit value to be applied
     */
    $scope.addLimitsForWindow = function addLimitsForWindow(bgActivityLimits,
      startTime, endTime, limitVal) {
      var keys = [];
      if (!!bgActivityLimits) keys = Object.keys(bgActivityLimits);

      // To add [startTime - endTime, limitVal] to the exisiting limit mapping,
      // we find the current key value present during the startTime
      // (let's assume it to be preVal), delete all the keys present between
      // startTime to endTime in the object, and add (startTime : preVal) and
      // (endTime : limitVal) to the object.

      // Sentinel value, to calcuate the current limit value during the start
      // time.
      var preStartBwLimit = -2;
      for (var i = 0; i < keys.length; ++i) {
        // Ignore the keys which are less than startTime.
        if (keys[i] < startTime) continue;

        // The limit value at startTime can be calculated by finding the
        // minimum key which is >= startTime. preStartBwLimit is modified
        // apporpriately and we don't enter this loop in subsequent iterations.
        if (keys[i] >= startTime && preStartBwLimit === -2) {
          preStartBwLimit = bgActivityLimits[keys[i]];
        }

        // Delete the keys present between [startTime , endTime].
        if (keys[i] >= startTime && keys[i] <= endTime) {
          delete bgActivityLimits[keys[i]];
        }
      }

      // Add (startTime : preStartBwLimit) to the object if preStartBwLimit
      // exists.
      if (preStartBwLimit !== -2) {
        bgActivityLimits[startTime] = preStartBwLimit;
      }

      // Add (endTime : limitVal) to the object.
      bgActivityLimits[endTime] = limitVal;
    };

    /**
     * Given the background resource usage allocated at various times of the
     * day, compute the average resource allocated for the day.
     *
     * @method   computeAverageBgResourceAllocated
     * @param    {Object}  bgActivityLimits   Contains the mapping of time
     *                                        slots to the allocated resource
     *                                        percentage during that slot.
     *                                        End time of the slot in minutes
     *                                        is stored as key.
     *
     * @return   {number}                     Average usage value.
     */
    $scope.computeAverageBgResourceAllocated = function
      computeAverageBgResourceAllocated(bgActivityLimits) {

      var totalResourceUsage = 0;
      var keys = [];
      if (!!bgActivityLimits) keys = Object.keys(bgActivityLimits);

      if (keys.length === 0) return 100;

      keys.forEach(function addResourceUsage(key, index) {
        if (index === 0) {
          totalResourceUsage += keys[index] * bgActivityLimits[key];
        } else {
          totalResourceUsage += (keys[index] - keys[index - 1]) *
            bgActivityLimits[key];
        }
      });

      return totalResourceUsage / TIME.minsPerDay;
    };

    /**
     * Validates Domain name before adding tag to
     * clusterDetails.domainNames
     *
     * @method     addDomainName
     * @param      {string}  value   The value
     * @return     {String/Boolean}  String value if valid, false if
     *                               invalid
     */
    $scope.addDomainName = function addDomainName(value) {
      // Clean out commas because ui-select doesn't do it
      value = value.replace(',', '');
      if (value.match(FORMATS.alphanumericPlus)) {
        $scope.domainNamesValid = true;
        return value;
      } else {
        $scope.domainNamesValid = false;
        return false;
      }
    };

    /**
     * Validates S3 Virtual Hosted Domain Name before adding tag to
     * clusterDetails.s3virtualHostedDomainNamesValid
     *
     * @method     addVirtualHostedDomainName
     * @param      {string}  value   The value
     * @return     {String/Boolean}  String value if valid, false if
     *                               invalid
     */
    $scope.adds3VirtualHostedDomainName = function adds3VirtualHostedDomainName(value) {
      // Clean out commas because ui-select doesn't do it
      value = value.replace(',', '');
      if (value.match(FORMATS.alphanumericPlus)) {
        $scope.s3virtualHostedDomainNamesValid = true;
        return value;
      } else {
        $scope.s3virtualHostedDomainNamesValid = false;
        return false;
      }
    };

    /**
     * handles saving cluster information on form submit
     *
     * @method   submitClusterDetailForm
     * @return   {boolean}   Returns false if validation fails, else true
     */
    $scope.submitClusterDetailForm = function submitClusterDetailForm() {
      var subnetChanged = false;

      var form = $scope.frmClusterDetails;

      if (form.$invalid) {
        return false;
      }

      if (proxyVisible && isProxyInvalid()) {
        return false;
      }

      if (FEATURE_FLAGS.backgroundTaskVerifyThrottlingThreshold &&
          !hasMinBgResourcesAllocated(true, $scope.bgThrottlingThreshold)) {
        cMessage.error({
          titleKey: 'backgroundActivity.thresholdError.title',
          textKey: 'backgroundActivity.thresholdError.text',
        });
        return false;
      }


      subnetChanged =
        _.get(form, 'appsManagementSubnet.$dirty', false) || _.get(form, 'appsManagementSubnetv6.$dirty', false);

      const v4present = $ctrl.apps.subnetCIDR !== '';
      const v6present = $ctrl.apps.subnetCIDRV6 !== '';
      if ($ctrl.isIpPreferenceV6 && v6present !== v4present) {
        cMessage.error({
          textKey: $translate.instant('backgroundActivity.provideBothIPsOrNone')
        });
        return;
      }
      // Both are empty
      const bothEmpty = !v4present && !v6present;

      if(bothEmpty) {
        $ctrl.apps.subnets = [];
      }
      _untransformAppsSettings($ctrl.apps, subnetChanged, bothEmpty);

      // Execute calls which are precondition for form submission
      if (subnetChanged) {
        $scope.submittingSubnets = true;
        NetworkService.createClusterSubnets($ctrl.apps.subnets).then(
          function createSubnetSuccess(subnets) {
            // Update received subnets
            _updateAppsSubnet(subnets);

            // Now submit rest of the config
            _submitClusterDetail();
          },
          evalAJAX.errorMessage
        ).finally(
          function allPromisesFinally() {
            $scope.submittingSubnets = false;
          }
        );
        return true;
      }

      _submitClusterDetail();
      return true;
    };

    /**
     * Returns whether form is in submitting state.
     *
     * @method    isSubmitting
     * @return    {boolean}   Returns whether form is in submitting state.
     */
    $scope.isSubmitting = function isSubmitting() {
      return !!$scope.submitting || !!$scope.submittingSubnets;
    };

    const executePromises = promises => {
      let errors = [];
      let promiseChain = Promise.resolve();
    
      promises.forEach(promise => {
        promiseChain = promiseChain
          .then(promise)
          .then(() => {
            return null; // Continue the chain
          })
          .catch(e => {
            errors.push(e.data.message);
            return null; // Continue the chain
          });
      });
    
      return promiseChain.then(() => {
        if (errors.length > 0) {
          return Promise.reject(errors);
        }
        return Promise.resolve();
      });
    };


    /**
     * Submit cluster config for independant services.
     * Please note that if your service needs to be update before cluster
     * config or handle validations, call it from submitClusterdetailform
     *
     * @method   _submitClusterDetail
     */
    function _submitClusterDetail() {
      var promises = [];
      var updateNavOnSuccess;
      var form = $scope.frmClusterDetails;

      if (proxyVisible) {
        // Check if Proxy is invalid and determine if any changes were made
        var isProxyDirty = form.configureProxyToggle.$dirty ||
          (!$ctrl.proxy.info.isDisabled && (form.proxyHost.$dirty ||
          form.proxyPort.$dirty || form.proxyPasswordEnabled.$dirty ||
          form.proxyUser.$dirty || form.proxyPass.$dirty ||
          $ctrl.proxy.serviceSelectionChanged));
      }

      var isSmtpDirty = false;
      if (!FEATURE_FLAGS.applianceManagerUIChangesForGA) {
        // Determine whether user made changes to any of the SMTP settings.
        isSmtpDirty =
          form.smtpSettingsToggle.$dirty ||
          (!$scope.smtp.disableSmtp &&
            (form.SMTPServer.$dirty ||
              form.SMTPPort.$dirty ||
              form.useSmtps.$dirty ||
              form.SMTPUsername.$dirty ||
              form.SMTPPassword.$dirty ||
              form.smtpSenderEmailAddress.$dirty));
      }

      if (proxyVisible && !$ctrl.proxy.requiresPassword) {
        // If proxy password checkbox is not checked,
        // then remove username and password.
        $ctrl.proxy.info.username = undefined;
        $ctrl.proxy.info.password = undefined;
      }

      // Add multiTenancyEnabled key in the cluster details
      if (FEATURE_FLAGS.multiTenancyEnabled) {
        if (!$scope.tenants.multiTenancyEnabled) {
          $scope.tenants.tenantViewboxSharingEnabled = false;
        }

        if ($scope.tenants.multiTenancyEnabled !==
          $scope.clusterDetails.multiTenancyEnabled) {
          updateNavOnSuccess = true;
        }

        _.assign($scope.clusterDetails, $scope.tenants);
      }

      if (!FEATURE_FLAGS.applianceManagerUIChangesForGA) {
        // Backend disallows nil smtp fields. If user cleared the fields, then we
        // set placeholder values.
        _.assign($scope.smtp, {
          server: $scope.smtp.server ? $scope.smtp.server : 'smtp.server',
          port: $scope.smtp.port ? parseInt($scope.smtp.port, 10) : 0,
        });
      }

      if(FEATURE_FLAGS.agentPortUpgradeSettingsEnabled) {
        _.assign($scope.clusterDetails, {
          attemptAgentPortsUpgrade: Boolean($scope.portSettings.attemptAgentPortsUpgrade)
        });
      }

      // Add promises, but only for those APIs which involve dirty fields.
      $scope.submitting = true;
      promises.push(ClusterService.updateClusterInfo.bind(this, $scope.clusterDetails));

      // Update if there is change in toggle level or when the toggle is set,
      // any change in overrides set is observed.
      if($scope.hasBgTaskThrottlingChanged($scope.oldBandwidthLimit,
        $scope.bandwidthLimit)) {
        promises.push(ClusterService.updateApolloThrottlingInfo.bind(this, $scope.bandwidthLimit));
      }

      // Update Ntp servers.
      var ntpServersData = $scope.ntpData.map(function(ntpData) {
        return ntpData.ntpServer;
      });
      var ntpServerAuthDataReqParams = {
        ntpServers: ntpServersData,
        ntpServerAuthInfo: $scope.ntpData,
        ntpAuthenticationEnabled: $scope.clusterDetails.ntpSettings.ntpAuthenticationEnabled
      };
      promises.push(ClusterService.updateNTPServers.bind(this, ntpServerAuthDataReqParams));
      if (isSmtpDirty) {
        promises.push(SmtpService.updateSMTPConf.bind(this, $scope.smtp));
      }
      if (FEATURE_FLAGS.loginBanner && $scope.clusterDetails.bannerEnabled &&
        form.loginBanner.$dirty) {
        promises.push(
          ClusterService.updateLoginBannerInfo.bind(this, {
            content: $ctrl.bannerText,
          })
        );
      }

      if (form.cloudTierThreshold?.$dirty) {
        // Update the one value we are modifying and send the saved object to
        // the put API.
        tieringInfo.downTierUsagePercentThresholds[2] =
          parseInt($scope.clusterDetails.downWaterfallThresholdPct, 10);
        promises.push(ClusterService.updateTieringInfo.bind(this, tieringInfo));
      }

      if (proxyVisible && isProxyDirty) {
        if (!$ctrl.proxy.info.isDisabled) {
          promises.push(ProxyService.updateUIProxyConf.bind(this, $ctrl.proxy.info));
        } else if ($ctrl.proxy.info.name) {
          promises.push(ProxyService.deleteUIProxyConf.bind(this, $ctrl.proxy.info.name));
        }
      }

      if (
          FEATURE_FLAGS.showHardwareEncryption &&
          $scope.clusterDetails.hardwareEncryptionEnabled !== $scope.hardwareEncryptionEnabled
        ) {
        var data = {
          enableHardwareEncryption: $scope.clusterDetails.hardwareEncryptionEnabled,
          onlyValidate: false
        };
        promises.push(ClusterService.updateHardwareEncryption.bind(this, data));
      }

      executePromises(promises)
        .then(() => {

          let subPromises = [];

          if (updateNavOnSuccess) {
            subPromises.push(UserService.updateSessionUserPrivs.bind(this));
          }
          if ($scope.sendTestEmail === true && $ctrl.testEmailAddress) {
            subPromises.push(SmtpService.sendTestEmail.bind(this, $ctrl.testEmailAddress));
          }
          return executePromises(subPromises);
        })
        .then(() => {
          NgAppStateService.selectedScope.name = $scope.clusterDetails.name;
          $rootScope.$broadcast('multi-tenancy-enabled');
          $state.go('cluster');
        })
        .catch(errors => {
          // Handle any errors that might occur during the chain
          console.error("Error during promise chain:", errors);
          evalAJAX.errorMessage({
            error: {
              message: errors.join('\n'),
            },
          });
        })
        .finally(() => {
          $scope.submitting = false;
        });


    }

    /**
     * validates DNS value before adding tag to
     * clusterDetails.dnsServerIps
     *
     * @method     addDNSServer
     * @param      {String}          value   to be validated
     * @return     {String/Boolean}  String value if valid, false if
     *                               invalid
     */
    $scope.addDNSServer = function addDNSServer(value) {
      // Clean out commas because ui-select doesn't do it
      value = value.replace(',', '');
      if (value.match(FORMATS.alphanumericPlus) || value.match(FORMATS.IPv4AndIPv6)) {
        $scope.DNSServerValid = true;
        return value;
      } else {
        $scope.DNSServerValid = false;
        return false;
      }
    };

    /**
     * when updating the auth setting open a modal and caution user
     */
    $scope.onTwoFactorToggleChange = function onTwoFactorToggleChange() {
      var modalConfig = {
        size: 'md',
        templateUrl: 'app/admin/modals/two-factor-auth.html',
        controller: 'TwoFactorAuthController',
        resolve: {
          AuthType: function() {
            return $scope.clusterDetails.authType;
          },
        },
      };

      var windowOptions = {
        actionButtonKey: false,
        closeButtonKey: false,
        titleKey: 'clusterSettings.certAuthConfirmation',
      };

      cModal.standardModal(modalConfig, windowOptions)
        .then(UserService.logout)
        .catch(function closeModal(authType) {
          $scope.clusterDetails.authType =
            authType === 'kCertificateOnly' ? 'kPasswordOnly' : 'kCertificateOnly';
        });
    };

    /**
     * Determines whether to show a metadata utilization warning after a change
     * to the number of failures tolerated.
     *
     * @method     onChangeFailuresTolerated
     * @param      {Number}     num     new number of failures tolerated
     */
    $scope.onChangeFailuresTolerated = function onChangeFailuresTolerated(num) {
      $scope.showMetadataWarning = false;

      if (num === 2 && $scope.clusterDetails.usedMetadataSpacePct * 1.5 >= 85) {
        // Allow the change but show warning message.
        $scope.showMetadataWarning = true;
      }
    };

    /**
     * Gets the value RF3 or RF5, according to failure tolerance.
     *
     * @method     getRfValue
     * @param      {Number}     tolerance     Number of of failures tolerated.
     * @return     {Number}     RF Value.
     */
    $scope.getRfValue = function getRfValue(tolerance) {
      return tolerance === 2 ? '5' : '3';
    };

    /**
     * Prepare apps settings for submission
     *
     * @method    _transformAppsSettings
     * @param     {object}     apps             apps settings object
     * @param     {boolean}    subnetChanged    Set if subnet value is changed
     */
    function _untransformAppsSettings(apps, subnetChanged, bothEmpty) {
      // Update subnets if changed
      if (subnetChanged && !bothEmpty) {
        var subnet;
        var subnetV6;
        var cidr;
        var cidrV6;
        $ctrl.apps.athenaSubnets = NetworkService.getAthenaClusterSubnets($ctrl.apps.subnets) || [];
        if (!$ctrl.apps.athenaSubnets.length) {
          return;
        }
        subnet = $ctrl.apps.athenaSubnets.find(s => s.ip?.match(FORMATS.IPv4));
        subnetV6 = $ctrl.apps.athenaSubnets.find(s => s.ip?.match(FORMATS.IPv6));

        // If subnet is not already present, add to the list
        if (!subnet) {
          subnet = { component: 'athena' };
          $ctrl.apps.subnets.push(subnet);
        }

        // Check if ipPreference is for both ipv4 and ipv6
        if ($ctrl.isIpPreferenceV6) {
          // If IPv6 subnet is not already present, add to the list
          if (!subnetV6) {
            subnetV6 = { component: 'athena' };
            $ctrl.apps.subnets.push(subnetV6);
          }
          if(apps.subnetCIDRV6) {
            cidrV6 = cUtils.cidrToIpBits(apps.subnetCIDRV6);
            subnetV6.ip = cidrV6[0];
          }
        }

        cidr = cUtils.cidrToIpBits(apps.subnetCIDR);
        subnet.ip = cidr[0];

        if (FEATURE_FLAGS.athenaSubnetUpdateReady) {
          subnet.netmaskIp4 = createNetmaskAddr(cidr[1]);

          // Delete netmaskBits key from object
          if (subnet.netmaskBits) {
            delete subnet.netmaskBits;
          }
        } else {
          subnet.netmaskBits = cidr[1];
          if ($ctrl.isIpPreferenceV6) {
            subnetV6.netmaskBits = cidrV6[1];
          }
        }
      }
    }

    /**
     * Initialize apps management settings
     *
     * @method    _initAppsManagement
     * @param     {object}    clusterDetails    cluster details object
     * @param     {array}     subnets           cluster subnets
     */
    function _initAppsManagement(clusterDetails, subnets) {
      _updateAppsSubnet(subnets);
    }

    /**
     * Update apps management subnet information.
     *
     * @method    _updateAppsSubnet
     * @param     {array}     subnets    cluster subnets
     */
    function _updateAppsSubnet(subnets) {
      // Get cluster subnets and also update athena subnet in cidr format
      $ctrl.apps.subnets = subnets;
      $ctrl.apps.athenaSubnets = _.clone(NetworkService.getAthenaClusterSubnets(subnets)) || [];
      if (!$ctrl.apps.athenaSubnets.length) {
        return;
      }
      $ctrl.apps.subnet = $ctrl.apps.athenaSubnets.find(s => s.ip.match(FORMATS.IPv4));
      $ctrl.apps.subnetV6 = $ctrl.apps.athenaSubnets.find(s => s.ip.match(FORMATS.IPv6));

      // Convert to CIDR format
      if (!_.isEmpty($ctrl.apps.subnet)) {
        $ctrl.apps.subnetCIDR = $ctrl.apps.subnet.ip + '/'
          + $ctrl.apps.subnet.netmaskBits;
      }
      if (!_.isEmpty($ctrl.apps.subnetV6)) {
        $ctrl.apps.subnetCIDRV6 = $ctrl.apps.subnetV6.ip + '/'
          + $ctrl.apps.subnetV6.netmaskBits;
      }
    }

    /**
     * This method processes proxy information and updates values to be used
     * in template.
     *
     * @method    processProxyInfo
     * @param     {Object}    proxyInfo    Proxy details
     */
    function processProxyInfo(proxyInfo) {
      if(!proxyInfo) {
        return;
      }

      $ctrl.proxy.info = _.merge($ctrl.proxy.info, proxyInfo);

      // Check for selectAll checkbox
      $ctrl.proxy.selectAllServices = ($ctrl.proxy.info.services &&
        $ctrl.proxy.info.services.includes('kAll'));

      // if username is present then set password toggle on
      $ctrl.proxy.requiresPassword = !!$ctrl.proxy.info.username;
    }

    /**
     * This method processesntpObject and updates values to be used
     * in template.
     *
     * @method    getNtpObject
     * @param     {Object}    ntpServers    ntp server list
     * @param     {Object}    ntpServerAuthData ntp server auth data list
     */
    function getNtpObject(ntpServers, ntpServerAuthData) {
      return ntpServers.map((ntpServer, index) => {
        let authData = {};
        if (ntpServerAuthData && ntpServerAuthData[index]) {
          authData = ntpServerAuthData[index];
        } else {
          authData = {
            ntpServerAuthKeyId: null,
            ntpServerAuthKeyValue: null
          };
        }
        return {
          ntpServer,
          ntpServerAuthKeyId: authData.ntpServerAuthKeyId,
          ntpServerAuthKeyValue: authData.ntpServerAuthKeyValue,
        };
      });
    }

    /**
     * This method verifies proxy information before submitting.
     *
     * @method    isProxyInvalid
     * @returns   {Boolean}   true/false depending on conditions
     */
    function isProxyInvalid() {
      return !$ctrl.proxy.info.isDisabled &&
          $ctrl.proxy.info.services.length === 0;
    }

    /**
     * Given the bandwidth override windows, compute if the background
     * resources allocated on average exceed the threshold.
     *
     * @method    hasMinBgResourcesAllocated
     * @param     {Boolean} isWeek     if set to true/false, the average
     *                                 resource allocation is computed over
     *                                 week/day.
     * @param     {number}  threshold  min threshold required
     * @returns   {Boolean}   true/false depending on conditions.
     */
    function hasMinBgResourcesAllocated(isWeek, threshold) {
      // Store the resource usage for all the days.
      var bgActivityLimitsArr = [];
      for (var i = 0; i < 7; ++i) {
        bgActivityLimitsArr.push({[TIME.minsPerDay] : 100});
      }

      // No need to verify if bandwidthLimit or bandwidthLimitOverrides is
      // set to undefined.
      if (!$scope.bandwidthLimit ||
        !$scope.bandwidthLimit.bandwidthLimitOverrides) {
          return true;
      }

      $scope.bandwidthLimit.bandwidthLimitOverrides.forEach(window => {
        var daysInWindow = [0, 1, 2, 3, 4, 5, 6];
        if (!!(window.timePeriods.days)) {
          daysInWindow = window.timePeriods.days.map(day => DAY_POS[day]);
        }

        var startTimeInMin = DateTimeService.timeInMins(window.timePeriods.
          startTime);
        var endTimeInMin = DateTimeService.timeInMins(window.timePeriods.
          endTime);

        daysInWindow.forEach(day => {
          $scope.addLimitsForWindow(bgActivityLimitsArr[day], startTimeInMin,
            endTimeInMin, BACKGROUND_IO_RATE_VALUE[window.ioRate]);
        });

      });

      var avgBgResourceArr = bgActivityLimitsArr.map(limitMap =>
        $scope.computeAverageBgResourceAllocated(limitMap));

      if (isWeek) {
        return cUtils.arrayFilteredAverage(avgBgResourceArr) >= threshold;
      } else {
        return Math.min(...avgBgResourceArr) >= threshold;
      }
    }

    /**
     * This method updates the service selection for which the proxy should
     * be enabled.
     *
     * @method    toggleServiceSelection
     * @param     {String}  kService    kvalue of checked/unchecked service
     */
    function toggleProxyServiceSelection(kService) {
      var index;
      $ctrl.proxy.serviceSelectionChanged = true;

      // Case: user unchecks an option when all items are checked
      if ($ctrl.proxy.info.services.includes('kAll')) {
        $ctrl.proxy.selectAllServices = false;
        $ctrl.proxy.info.services = _.reduce($ctrl.proxy.supportedServices,
          function filterKeys(acc, prop) {
            if (prop !== kService) {
              acc.push(prop);
            }
            return acc;
          }, []);
        return;
      }

      // Case: regular check/uncheck
      index = $ctrl.proxy.info.services.indexOf(kService);
      (index > -1) ? $ctrl.proxy.info.services.splice(index, 1) :
        $ctrl.proxy.info.services.push(kService);

      // Case: update select all checkbox post selection
      if ($ctrl.proxy.allowAllSelection && ($ctrl.proxy.info.services.length ===
        $ctrl.proxy.supportedServices.length)) {
        $ctrl.proxy.selectAllServices = true;
        $ctrl.proxy.info.services = ['kAll'];
      }
    }

    /**
     * This method toggles selection of all proxy supported services
     * @method    toggleSelectAll
     */
    function toggleProxySelectAll(){
      $ctrl.proxy.serviceSelectionChanged = true;
      $ctrl.proxy.selectAllServices = !$ctrl.proxy.selectAllServices;
      $ctrl.proxy.info.services = $ctrl.proxy.selectAllServices ? ['kAll'] : [];
    }

    /**
     * Opens the rack assignment dialog.
     */
    function openAssignRacksDialog() {
      ngDialogService.assignRacks()
      .toPromise()
      .then(function afterClose(response){
        // Determine whether  all chassis are assigned to racks.
        $scope.clusterDetails._allChassisAssigned =
          $scope.clusterDetails._allChassisAssigned || response.allChassisAssigned;
      });
    }

    /**
     * This method adds the NTP Auth Key Configuration input fields.
     *
     * @method  addNTPAuthField
     *
     */
    function addNTPAuthField(ntpServerInput) {
      var ntpObject = {ntpServer: ntpServerInput, ntpServerAuthKeyId: null, ntpServerAuthKeyValue: null};
      $scope.ntpData.push(ntpObject);
    }

    /**
     * This method removes the NTP Auth Key Configuration input fields.
     *
     * @method  removeNTPAuthField
     * @param   {number}  index  index of the ntp to be removed.
     *
     */
    function removeNTPAuthField(index) {
      $scope.ntpData.splice(index, 1);
    }

    /**
     * Ntp Settings Toggle change.
     *
     * @method ntpSettingsToggleChange
     */
    function ntpSettingsToggleChange() {
      if ($scope.ntpData.length < 1) {
        addNTPAuthField('');
      }
    }

    /**
     * Rules to determine whether the kms key selector should
     * be disabled or not
     *
     * @param   {string}  keyType             selected KMS key type
     * @param   {number}  key                 selected KMS Key
     *
     * @returns {boolean} True if KMS selection is to be disabled.
     */
    $scope.disableKmsSelectorFn = function disableKmsSelectorFn(keyType, key) {
      return keyType !== KmsModel.KeyTypes.kInternalKMS &&
        key && key === $scope.kmsServerId;
    }
  }

})(angular);

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';

import { DataIdModule } from '../data-id';
import { TimePickerComponent } from './time-picker.component';

@NgModule({
  imports: [
    CommonModule,
    DataIdModule,
    FormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    MatInputModule,
    TimePickerComponent,
  ],
  exports: [TimePickerComponent],
})
export class TimePickerModule {}

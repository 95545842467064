import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { SharepointItemMetadataType } from '@cohesity/api/private';
import { KeyedSelectionModel } from '@cohesity/helix';
import { CreateShareDialogParam } from 'src/app/modules/views/components';
import { AutoDestroyable, Document, folderBrowserProviderFn, FolderBrowserService } from 'src/app/shared';

import { ViewFileBrowserService } from './view-file-browser.service';

@Component({
  selector: 'coh-view-file-browser',
  templateUrl: './view-file-browser.component.html',
  styleUrls: ['./view-file-browser.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [folderBrowserProviderFn(ViewFileBrowserService), FolderBrowserService],
})
export class ViewFileBrowserComponent extends AutoDestroyable implements OnInit {

  @Input() directoryData: CreateShareDialogParam;

  /**
   * Emit selected item to parent component.
   */
  @Output() selectItem = new EventEmitter<Document>();

  /**
   * Currently selected items.
   */
  selection = new KeyedSelectionModel((file: Document) => file.fullPath, false);

  /**
   * Decides whether the entry in the directory list is selectable.
   *
   * @param doc The document entry.
   * @returns A boolean value to decide whether the item can be selected.
   */
  canSelectItemInDirectoryListFn: (doc: Document) => boolean = doc =>
    doc.dirEntry?.fstatInfo?.sharepointItemMetadata?.type !== SharepointItemMetadataType.kSiteList;

  /**
   * Decides whether the entry in the directory list is navigable.
   *
   * @param doc The document entry.
   * @returns A boolean value to decide whether the item can be navigated through.
   */
  canNavigateItemInDirectoryListFn: (doc: Document) => boolean = doc => {
    if (doc.dirEntry?.fstatInfo?.sharepointItemMetadata?.type === SharepointItemMetadataType.kSiteList) {
      return false;
    }
    return doc.isFolder;
  };

  constructor(
    public fbContext: FolderBrowserService,
    private viewFileBrowserService: ViewFileBrowserService,
  ) {
    super();
  }

  ngOnInit() {
    this.viewFileBrowserService.initData(this.directoryData);
    this.fbContext.browseToPath();
  }

  /**
   * Emit selected item to parent component.
   *
   * @param   item   The selected item.
   */
  selectItemFn(item: Document) {
    this.selectItem.emit(item);
  }

}

import { DecimalPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Poller } from '@cohesity/utils';
import { get } from 'lodash-es';
import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { PollingService } from 'src/app/core/services';

import { AttributeVec, ResultGroupVec } from '../models';

/**
 * Values to display from progress monitor entity attributes.
 */
interface EntityStatus {
  fileWalkDone?: boolean;
  totalChangedEntities?: number | string;
  totalEntities?: number | string;
}

/**
 * @description
 *
 * Component for displaying entities progress from progress monitor API attributeVec.
 */
@Component({
  selector: 'coh-pulse-entity',
  templateUrl: './pulse-entity.component.html',
  styleUrls: ['./pulse-entity.component.scss'],
})
export class PulseEntityComponent implements OnInit, OnDestroy {
  /**
   * The taskPath to fetch pulse for.
   */
  @Input() taskPath: string;

  /**
   * Type of data to be rendered.
   */
  @Input() type: 'duration' | 'backupStatus';

  /**
   * Emits event taskFinish when perecentFinished is 100%
   */
  @Output() readonly taskFinish = new EventEmitter();

  /**
   * The Poller instance for polling `taskPath`.
   */
  private taskPoll: Poller<ResultGroupVec[]>;

  /**
   * Transformed attributeVec data from progress monitor API.
   */
  entityStatus$: Observable<EntityStatus>;

  constructor(private pollingService: PollingService, private decimalPipe: DecimalPipe) {}

  ngOnInit() {
    this.taskPoll = this.pollingService.pollTask(this.taskPath, 5);
    this.entityStatus$ = this.taskPoll.poller.pipe(
      map((groupVec: ResultGroupVec[]) => {
        const attributeVec: AttributeVec[] = get(groupVec, '[0].taskVec[0].progress.attributeVec');

        if (attributeVec) {
          const ret: EntityStatus = {};

          attributeVec.forEach(attr => {
            const { key } = attr;
            const value = get(attr, 'value.data.int64Value');
            const valueStr = this.decimalPipe.transform(value || 0);

            switch (key) {
              case 'file_walk_done':
                ret.fileWalkDone = !!value;
                break;
              case 'total_changed_entity_count':
                ret.totalChangedEntities = valueStr;
                break;
              case 'total_entity_count':
                ret.totalEntities = valueStr;
            }
          });

          return ret;
        }

        return {
          fileWalkDone: false,
          totalChangedEntities: 0,
          totalEntities: 0,
        };
      }),
      finalize(() => this.taskFinish.emit())
    );
  }

  ngOnDestroy() {
    if (this.taskPoll) {
      this.taskPoll.finish();
    }
  }
}

import { AbstractControl } from '@angular/forms';

/**
 * This function returns error messages for form controls corresponding to error keys
 * returned by validators.
 *
 * @param formControl Form control object to get error key from.
 * @returns   Error string.
 */
export function GetValidationError(formControl: AbstractControl): string {
  // TODO(ankitshubham): Merge ip-address-errors-mapping.ts with the current file.
  let errorMsg = '';
  const { errors } = formControl;

  if (!errors || !Object.keys(errors).length) {
    return errorMsg;
  }
  /**
   * Get error code from  the formControl errors object.
   * There should be only one error code at a time.
   */
  const error = errors ? Object.keys(errors)[0] : null;

  // Return corresponding translation key
  switch (error) {
    case 'required':
      errorMsg = 'errors.required';
      break;
    case 'emptyString':
      errorMsg = 'errors.patterns.noEmptyString';
      break;
    case 'whitespace':
      errorMsg = 'errors.patterns.noWhiteSpace';
      break;
    case 'invalidSmtpNicknameError':
      errorMsg = 'errors.patterns.invalidSmtpNickname';
      break;
    case 'invalidClusterName':
      errorMsg = 'errors.patterns.invalidClusterName';
      break;
  }
  return errorMsg;
}

import { isMcm } from '@cohesity/iris-core';
import { OwnershipContext } from 'src/app/shared';
import { recoveryGroup } from 'src/app/shared/constants';
import { maximumNumTags } from 'src/app/shared/constants/cloud.constants';

// Component: Restore VM (Recover & Clone) Controllers
;(function(angular, undefined) {
  'use strict';

  // Module basics
  var moduleName = 'C.restore';
  var moduleDeps = ['C.jobRunsService', 'C.devOpsService',
    'C.retrievalOptions', 'C.azureManagedDisks'];

  // These will be set by other task types. They're just defaults.
  var taskType = 'recover';
  var isRecover = true;
  var isClone = false;

  // Default prefix to be used when renaming VMs.
  var defaultPrefix = 'copy-';

  /**
   * The things to be shared across Recover & Restore VMs
   */
  angular
    .module(moduleName, moduleDeps)
    .config(ConfigFn)
    .controller('sharedParentController', sharedParentControllerFn)
    .controller('sharedTaskOptionsController', sharedTaskOptionsControllerFn)
    .controller('sharedTaskObjectSelectionController',
      sharedTaskObjectSelectionControllerFn)
    .controller('sharedCartModalController', sharedCartModalControllerFn)
    .controller('sharedSnapshotSelectorController',
      sharedSnapshotSelectorControllerFn)
    .controller('sharedArchivalTargetDecisionModal',
      sharedArchivalTargetDecisionModalFn);

  /**
   * Config Fn for this module.
   */
  function ConfigFn($stateProvider) {
    var restoreModifyAccess = ctx => {
      return ctx.RESTORE_MODIFY && ctx.canAccessSomeEnv(recoveryGroup.vm);
    };
    var cloneModifyAccess = ctx => {
      return ctx.CLONE_MODIFY && ctx.canAccessSomeEnv(recoveryGroup.vm, 'clone');
    };
    var kuiperModifyAccess = ctx => {
      if (ctx.isTenantUser) {
        return false;
      }
      return ctx.CLONE_VIEW && ctx.FEATURE_FLAGS.testDevEnabled && ctx.canAccessSomeEnv(recoveryGroup.vm, 'kuiper');
    };

    $stateProvider
      .state('recover-vm', {
        controller: 'sharedParentController',
        help: 'protection_recoverynew_vm_steps_confirm',
        templateUrl: 'app/protection/recovery/vm/vm.restore.parent.html',
        title: 'Recover VM Source',
        canAccess: restoreModifyAccess,
        parentState: 'recover',
        url: '^/protection/recovery/vm',
        params: { recover: true },
        controllerAs: '$ctrl',
        redirectTo: 'recover-vm.select-vms'
      })
      .state('clone-vms', {
        controller: 'sharedParentController',
        help: 'protection_recovery_vm',
        templateUrl: 'app/protection/recovery/vm/vm.restore.parent.html',
        title: 'Clone VM Source',
        canAccess: cloneModifyAccess,
        parentState: 'devops',
        url: '^/devops/clone/vm',
        params: { clone: true },
        controllerAs: '$ctrl',
        redirectTo: 'clone-vms.select-vms'
      })
      .state('kuiper-vms', {
        controller: 'sharedParentController',
        templateUrl: 'app/protection/recovery/vm/vm.restore.parent.html',
        title: 'Hypervisor VM Source',
        canAccess: kuiperModifyAccess,
        parentState: 'devops',
        url: '^/devops/kuiper/vm',
        params: { isKuiper: true },
        controllerAs: '$ctrl',
        redirectTo: 'kuiper-vms.select-vms'
      })

      .state('recover-vm.select-vms', {
        help: 'protection_recoverynew_vm_steps_select',
        url: '/select',
        canAccess: restoreModifyAccess,
        parentState: 'recover-vm',
        views: {
          'canvas@recover-vm': {
            controller: 'sharedTaskObjectSelectionController',
            templateUrl:
              'app/protection/recovery/vm/shared-select-objects.html',
            controllerAs: '$ctrl',
          },
        },
      })
      .state('clone-vms.select-vms', {
        help: 'testdev_clonenew_new_select',
        url: '/select',
        canAccess: cloneModifyAccess,
        parentState: 'clone-vms',
        views: {
          'canvas@clone-vms': {
            controller: 'sharedTaskObjectSelectionController',
            templateUrl:
              'app/protection/recovery/vm/shared-select-objects.html',
            controllerAs: '$ctrl',
          },
        },
      })
      .state('kuiper-vms.select-vms', {
        url: '/select',
        canAccess: kuiperModifyAccess,
        parentState: 'kuiper-vms',
        views: {
          'canvas@kuiper-vms': {
            controller: 'sharedTaskObjectSelectionController',
            templateUrl:
              'app/protection/recovery/vm/shared-select-objects.html',
            controllerAs: '$ctrl',
          },
        },
      })

      .state('recover-vm.recover-options', {
        help: 'protection_recoverynew_vm_steps_options',
        url: '/options?' + [
          '{jobId}',
          '{jobInstanceId}',
          '{jobRunStartTime}',
          '{entityId}',
          '{vaultId}',
          '{vaultName}',
          '{vaultType}',
          '{taskId}',
        ].join('&'),
        canAccess: restoreModifyAccess,
        parentState: 'recover-vm',
        params: {
          sourceEntity: 0,
          jobId: { type: 'string' },
          jobIds: { type: 'any' },
          jobInstanceId: { type: 'string' },
          jobRunStartTime: { type: 'int' },
          jobUid: { type: 'any' },
          entityId: { type: 'string' },
          entityIds: { type: 'any' },
          vaultId: { type: 'string' },
          vaultName: { type: 'string' },
          vaultType: { type: 'string' },
          taskId: { type: 'int' },
          backupType: { type: 'any' },
          resubmitRecoveryObject: { type: 'any' },
        },
        views: {
          'canvas@recover-vm': {
            controller: 'sharedTaskOptionsController',
            controllerAs: '$ctrl',
            templateUrl: 'app/protection/recovery/vm/shared-task-options.html',
          },
        },
      })
      .state('clone-vms.clone-options', {
        url: '/options?' + [
          '{jobId}',
          '{jobInstanceId}',
          '{entityId}',
          '{vaultId}',
          '{vaultName}',
          '{vaultType}',
          '{entityType}',
        ].join('&'),
        help: 'testdev_clonenew_new_options',
        cloudHelp: 'devops_clone_new_options',
        canAccess: cloneModifyAccess,
        parentState: 'clone-vms',
        params: {
          sourceEntity: 0,
          jobId: { type: 'string' },
          jobInstanceId: { type: 'string' },
          entityId: { type: 'string' },
          vaultId: { type: 'string' },
          vaultName: { type: 'string' },
          vaultType: { type: 'string' },
          entityType: { type: 'string' },
        },
        views: {
          'canvas@clone-vms': {
            controller: 'sharedTaskOptionsController',
            controllerAs: '$ctrl',
            templateUrl: 'app/protection/recovery/vm/shared-task-options.html',
          },
        },
      })
      .state('kuiper-vms.clone-options', {
        url: '/options?' + [
          '{jobId}',
          '{jobInstanceId}',
          '{entityId}',
          '{vaultId}',
          '{vaultName}',
          '{vaultType}',
          '{entityType}',
        ].join('&'),
        canAccess: kuiperModifyAccess,
        parentState: 'kuiper-vms',
        params: {
          sourceEntity: 0,
          jobId: { type: 'string' },
          jobInstanceId: { type: 'string' },
          entityId: { type: 'string' },
          vaultId: { type: 'string' },
          vaultName: { type: 'string' },
          vaultType: { type: 'string' },
          entityType: { type: 'string' },
        },
        views: {
          'canvas@kuiper-vms': {
            controller: 'sharedTaskOptionsController',
            templateUrl: 'app/protection/recovery/vm/shared-task-options.html',
            controllerAs: '$ctrl',
          },
        },
      });
  }

  function sharedParentControllerFn(_, $scope, $rootScope, $q, $state, $timeout,
    $stateParams, $log, cMessage, evalAJAX, SlideModalService,
    JobRunsService, SearchService, cSearchService, ViewBoxService,
    SourceService, RestoreService, ExternalTargetService, ENV_GROUPS,
    $translate, SOURCE_TYPE_GROUPS, featureFlagsService, ENV_TYPE_CONVERSION,
    SCHEDULING_POLICY_PERIODICITY, FEATURE_FLAGS, cUtils) {

    var $ctrl = this;
    var defaultEmptyTask;

    // holder for jobRun request promises so we can cancel existing if another
    // is issued
    var cart;
    var task;

    $scope.now = Date.now();

    // Some of this is here so that it can be reinitialized after a shared
    // parent changes the $scope.taskType
    $ctrl.isKuiper = $stateParams.isKuiper;
    taskType = $scope.taskType = $stateParams.clone || $ctrl.isKuiper ?
      'clone' : 'recover';
    $scope.isClone = isClone = (taskType === 'clone') && !$ctrl.isKuiper;
    $scope.isRecover = isRecover = !isClone && !$ctrl.isKuiper;

    // While cloning vms on cohesity cluster, we don't want to select more
    // than one vm, allowSingleSelection property will handle these kind of
    // scenarios.
    $ctrl.allowSingleSelection = $ctrl.isKuiper;

    // A list of state names in parent through steps order, 0-3
    $scope.stateNames = [
      'recover',
      'recover-vm.select-vms',
      'recover-vm.recover-options',
    ];

    // Fleet Instance Locations for AWS
    $scope.fleetInstanceLocations = [
      {
        display: 'sameSubnetAsVM',
        value: 'kSourceVM',
      }, {
        display: 'selectNew',
        value: 'kCustom',
      }
    ];

    if ($rootScope.clusterInfo._isCloudInstall) {
      $scope.fleetInstanceLocations.unshift({
        display: 'sameSubnetAsCE',
        value: 'kCluster',
      });
    }

    angular.extend($scope, {
      disablePowerStateChackbox: false,
      itemsPerPage: 10,
      jobsList: [],
      loadingRuns: {},
      loadingVms: {},
      searchAPI: SearchService.getSearchUrl('vm'),
      searchDebounce: 450,
      showOptions: false,
      showVMsubList: {},
      SOURCE_TYPE_GROUPS: SOURCE_TYPE_GROUPS,
      SourceService: SourceService,

      // DISPLAY STRINGS
      text: $rootScope.text.protection_sharedRestore_restoreText,
      errorText: $rootScope.text.protection_sharedRestore_restore1ErrorText,

      // $SCOPE METHODS

      addToCart: addToCart,
      deselectAll: deselectAll,
      downloadVMXfile: downloadVMXfile,
      downloadVulScanReport: downloadVulScanReport,
      filterVMsForVapp: filterVMsForVapp,
      getJobRunHistory: getJobRunHistory,
      getVulScanStatusClass: getVulScanStatusClass,
      getTargetTooltip: getTargetTooltip,
      getTargetType: getTargetType,
      isEntityInCart: isEntityInCart,
      isInCart: isInCart,
      isEntitysJobInCart: isEntitysJobInCart,
      openSnapshotSelector: openSnapshotSelector,
      openTaskCart: openTaskCart,
      preProcessSearchResults: preProcessSearchResults,
      processVmsForEntity: processVmsForEntity,
      removeFromCart: removeFromCart,
      resetTask: resetTaskDefaults,
      selectAll: selectAll,
      startTaskOver: startTaskOver,
      startVulScan: startVulScan,
      toggleInCart: toggleInCart,
      updateConvenienceProps: updateConvenienceProps,
      decidePrefix: decidePrefix,
    });
    $scope.taskSteps = [
      {
        label: $scope.text.steps.selectVMs,
        active: $state.current.name === $scope.stateNames[1],
      },
      {
        label: $scope.text.steps.recoverOptions,
        active: $state.current.name === $scope.stateNames[2],
      },
    ];

    // WATCHERS
    $scope.$on('$destroy', destroyTask);

    /**
     * Initialization routine for this controller
     *
     * @method   activate
     */
    function activate() {

      preFetchReferences();

      // When in Clone variant, init clone-specific stuff now.
      if ($scope.isClone) {
        activateClone();
      }
      if($ctrl.isKuiper) {
        activateKuiper();
      }

      defaultEmptyTask = {
        name: RestoreService.getDefaultTaskName(taskType, 'VMs'),
        objects: [],
        powerStateConfig: {
          // This is conditionally set later in initRestoreVM()
          // NOTE: The UI language is oriented around the inverse of this. So
          // this is false when the checkbox is checked.
          powerOn: isRecover,
        },
        restoredObjectsNetworkConfig: {},
        renameRestoredObjectParam: {
          prefix: defaultPrefix,
        },
        continueRestoreOnError: false,
      };

      // For shared access by child scopes
      $scope.shared = {
        startNetworkConnected: true,
        failoverFlow: false,
        searchData: [],
        searchDisplay: [],
        selectedRows: [],
        task: angular.copy(defaultEmptyTask),
        taskCart: [],
        vmSearchId: 'vmSearch',
        selectedVlanTarget: undefined,
        allowPreserveTag: true,
        preserveTags: true,
        allowPreserveCustomAttributes: true,
        preserveCustomAttributes: true,
      };

      cart = $scope.shared.taskCart;
      task = $scope.shared.task;

      // Get the appropriate search URL.
      $scope.searchAPI = SearchService.getSearchUrl([taskType, 'vm'].join(''));

      $scope.shared.networkingOptions = 'original';
      $rootScope.backupJobRuns = $rootScope.backupJobRuns || {};

      if (!isRecover) {
        $scope.shared.networkingOptions = 'detach';
        $scope.shared.task.restoredObjectsNetworkConfig.detachNetwork = true;
      }

      $scope.shared.task.MAXIMUM_NUM_TAGS = maximumNumTags;
      $scope.shared.task.displayCustomTagsWarning = false;

      // Check for CyberScan app status
      RestoreService.getVulScanAppStatus().then(function getStatus() {
        $scope._isVulScanAppPresent = true;
      }).catch(function appNotInstalled() {
        $log.warn('CyberScan is not installed');
      });
    }

    /**
     * Clone flow specific activation overrides.
     *
     * @method   activateClone
     */
    function activateClone() {
      var baseState = 'devops.clone';

      angular.extend($scope, {
        stateNames: [
          baseState,
          'clone-vms.select-vms',
          'clone-vms.clone-options',
        ],
      });

      $scope.taskSteps = [
        {
          label: $scope.text.steps.selectVMs,
          active: $state.current.name === $scope.stateNames[1],
        },
        {
          label: $scope.text.steps.cloneOptions,
          active: $state.current.name === $scope.stateNames[2],
        },
      ];
    }

    /**
     * Cohesity cluster clone flow specific activation overrides.
     *
     * @method   activateKuiper
     */
    function activateKuiper() {
      angular.extend($scope, {
        stateNames: [
          'devops.sources',
          'kuiper-vms.select-vms',
          'kuiper-vms.clone-options',
        ],
      });

      $scope.taskSteps = [
        {
          label: $translate.instant('selectVMs'),
          active: $state.current.name === $scope.stateNames[1],
        },
        {
          label: $translate.instant('setCloneOptions'),
          active: $state.current.name === $scope.stateNames[2],
        },
      ];
    }

    /**
     * Function to pre process search results for VMs.
     *
     * @method   preProcessSearchResults
     * @param    {object}    resp                  Server response.
     * @param    {boolean}   [excludeJobs=false]   True to exclude jobs.
     * @return   {array}     List of processed search results (Jobs + VMs).
     */
    function preProcessSearchResults(resp, excludeJobs) {
      if ($scope.isClone && !featureFlagsService.enabled('enableVcdClone')) {
        // Filter out V Cloud Director from clone list unless the feature flag
        // is enabled for it.
        resp.data.vms =
          _.get(resp, 'data.vms', []).filter(function filterRespVm(vm) {
            return !_.get(
              vm, 'vmDocument.objectId.entity.vmwareEntity.vcdAttributes'
            );
          });
      }

      return SearchService.processVmsSearchResults(resp, excludeJobs, true);
    }

    /**
     * Updates some envType scope convenience properties used to fork the logic.
     *
     * @method   updateConvenienceProps
     */
    function updateConvenienceProps() {
      var cart = $scope.shared.taskCart;

      // If the cart is empty, these are all false.
      if (!cart.length) {
        $scope.shared.isHyperV =
          $scope.shared.isAcropolis =
          $scope.shared.isVMware =
          $scope.shared.isVapp =
          $scope.shared.isKVM = false;
        $scope.shared.allowPreserveTag = false;
        $scope.shared.allowPreserveCustomAttributes = false;
        return;
      }

      $scope.shared.isHyperV = /hyperv/i.test(cart[0]._entityKey);
      $scope.shared.isAcropolis = /acropolis/i.test(cart[0]._entityKey);
      $scope.shared.isVMware = /vmware/i.test(cart[0]._entityKey);
      $scope.shared.isKVM = /kvm/i.test(cart[0]._entityKey);
      $scope.shared.isVapp = cart[0]._type === 'vapp';
      $scope.shared.allowPreserveTag =
        // Must be a clone operation AND
        $scope.isClone &&

        // Not Acropolis AND
        !$scope.shared.isAcropolis &&

        // Not KVM AND
        !$scope.shared.isKVM;

      $scope.shared.allowPreserveCustomAttributes =
        // Must be a clone operation AND
        $scope.isClone &&

        // Not hyperV AND
        !$scope.shared.isHyperV &&

        // Not Acropolis AND
        !$scope.shared.isAcropolis &&

        // Not KVM AND
        !$scope.shared.isKVM;
    }

    /**
     * For a job run, we get a list of VMs. A run can have more than one vApps,
     * when we show VMs for a vApp for a particular run, we need to filter
     * the VMs list to show only the ones belonging to the vApp selected.
     *
     * @method   filterVMsForVapp
     * @param    {Object}   entity   The entity
     */
    function filterVMsForVapp(entity) {
      var snapshot;
      var vappUuid;
      if (entity._type !== 'vapp') { return; }

      snapshot = _.get($rootScope.backupJobRuns,
        entity._uniqueId + '.runs.' + entity._snapshotIndex);

      vappUuid = _.get(entity.vmDocument,
        'objectId.entity.vmwareEntity.uuid').split('vapp:')[1];

      snapshot.vms = snapshot.vms.filter(function filterVMs(vm) {
        return vappUuid === _.get(vm.vmwareEntity,
          'parentVappUuid').split('vapp-')[1];
      });
    }

    /**
     * This function filters the runs of the job that does not contain the vApp
     * entity which the user is looking for.
     *
     * @method   filterRunsForVapp
     * @param    {Object}   entity   The entity in search
     */
    function filterRunsForVapp(entity) {
      return _.filter(entity.vmDocument.versions,
        function filterRuns(version) {
          var vappUuid = _.get(entity.vmDocument,
            'objectId.entity.vmwareEntity.uuid', '').split('vapp:')[1];

          // If somehow we do not get vApp uuid then we cannot determine if the
          // run has vApp or not. We would return the run as accepted. The UI
          // will show VMs if vApp exists or the list would be empty on UI.
          if (!vappUuid) {
            return true;
          }

          return _.some(version.vms, function (vm) {
            return vappUuid === _.get(vm.vmwareEntity,
              'parentVappUuid', '').split('vapp-')[1];
          });
        }) || [];
    }

    /**
     * Fetches the Job Run history for the given job entity.
     *
     * @method   getJobRunHistory
     * @param    {Object}   entity   Job entity
     */
    function getJobRunHistory(entity) {
      RestoreService.getJobRunHistory(entity).then(processUpdatedEntity);
    }

    /**
     * When a snapshot of a job is selected, this will retrieve the vm
     * information for that snapshot if not already present in the global cache.
     *
     * @method   processVmsForEntity
     * @param    {object}   entity   The job entity
     */
    function processVmsForEntity(entity) {
      var snapshot = $rootScope.backupJobRuns[entity._uniqueId]
        .runs[entity._snapshotIndex];
      var snapshotStartTimeUsecs = snapshot.instanceId.jobStartTimeUsecs;

      // If we already have the vms for the selected snapshot in the global
      // cache then we can return from here.
      if (entity._runHash[entity._snapshotIndex].vms) {
        return;
      }

      $scope.loadingVms[entity._uniqueId] = true;

      RestoreService.updateJobRunWithVms(entity, snapshotStartTimeUsecs)
        .then(processUpdatedEntity);
    }

    /**
     * Updates global backupJobRuns hash from the updated job entity. Also takes
     * care of the vApp related filtering if the job is vApp job.
     *
     * @method   processUpdatedEntity
     * @param    {object}   entity   The updated job entity
     */
    function processUpdatedEntity(entity) {
      $rootScope.backupJobRuns[entity._uniqueId] = entity._backupJobRuns;

      $scope.loadingVms[entity._uniqueId] = false;

      // Process Runs and VMs for vApp entity
      if (entity._type === 'vapp') {
        // Filter VMs which are not part of vApp for the first selected run
        filterVMsForVapp(entity);

        // Filter runs that we should not show for a vApp selected
        entity.vmDocument.versions = filterRunsForVapp(entity);
      }
    }

    /**
     * Deselects all rows in the search results (different from removing from
     * cart)
     *
     * @method     deselectAll
     */
    function deselectAll() {
      $scope.shared.searchData.forEach(function deselectorLoop(entity) {
        entity._selected = false;
      });
    }

    /**
     * Selects all rows in the search results (different from adding to the
     * cart)
     *
     * @method     selectAll
     */
    function selectAll() {
      $scope.shared.searchData.forEach(function selectorLoop(entity) {
        entity._selected = true;
      });
    }

    /**
     * Open the task cart/box
     *
     * @method     openTaskCart
     */
    function openTaskCart() {
      SlideModalService.newModal({
        controller: 'sharedCartModalController',
        templateUrl: 'app/protection/recovery/vm/shared-modal-task-cart.html',
        size: 'md',
        resolve: {
          taskCart: depsResolver($scope.shared.taskCart),
          $$scope: depsResolver($scope),
        },
      });
    }

    /**
     * Opens the snapshot selector modal for the given Entity (Job or VM)
     *
     * @method     openSnapshotSelector
     * @param      {object}  entity  Job or VM to pick a snapshot from
     */
    function openSnapshotSelector(entity) {
      var canUsePitCalendar = featureFlagsService.enabled('cdpEnabled') &&
          _.get(entity, '_policy.cdpSchedulingPolicy.periodicity') ===
          SCHEDULING_POLICY_PERIODICITY['cdp'];
      var modalSize = canUsePitCalendar ? 'full' : 'lg';
      var config = {
        controller: 'sharedSnapshotSelectorController',
        templateUrl:
          'app/protection/recovery/vm/shared-modal-snapshot-selector.html',
        size: modalSize,
        resolve: {
          sharedScope: depsResolver($scope.shared),
          entity: depsResolver(entity),
          backupJobRuns: depsResolver($rootScope.backupJobRuns),
          canUsePitCalendar: canUsePitCalendar,
        },
      };

      SlideModalService

        // Open the selector modal
        .newModal(config)

        // Then handle the outcome of the closed modal
        .then(function snapshotSelected(configs) {
          if (configs.isPIT) {
            entity._pointInTimeRestoreTimeUsecs = configs.pointInTime;
            $scope.snapshotsDate = configs.pointInTime / 1000;
          } else {
            // Use provided index, or 0 if that's bunk
            entity._snapshotIndex = configs.snapshotIndex || 0;
            entity._snapshot = configs.snapshot;
            entity._pointInTimeRestoreTimeUsecs = undefined;

            // Set or remove the archivalTarget on the entity
            if (configs.archivalTarget) {
              entity._archivalTarget = configs.archivalTarget;
            } else {
              entity._archivalTarget = undefined;
            }

            // Remove the incompatible objects from the cart (no object
            // if user canceled the modal)
            if (configs.incompatibleObjects) {
              removeFromCart(configs.incompatibleObjects);
            }

            if (configs.tapeListParams) {
              // this is a tape restore.
              // save tapeListParams to shared object for template
              // consumption
              $scope.shared.tapeListParams = configs.tapeListParams;
            } else if (!!$scope.shared.tapeListParams) {
              $scope.shared.tapeListParams = undefined;
            }
          }
        });
    }

    /**
     * Download the VMX file for the given set
     *
     * @method     downloadVMXfile
     * @param      {array|object}  set     One or more entities to retrieve
     *                                     the VMX file for
     */
    function downloadVMXfile(set) {
      if (!set) {
        return;
      }

      set = [].concat(set);

      set.forEach(function eachSet(vm, ii) {
        var params;

        // Sanity check in addition to those in the template for action
        // visibility
        if (!angular.isDefined(vm.vmDocument.objectId.entity &&
          vm.vmDocument.viewBoxId)) {
          return false;
        }

        var docVersion = vm.vmDocument.versions[vm._snapshotIndex];

        // Construct the file request params
        params = {
          jobId: _.get(vm.vmDocument.objectId.jobUid, 'objectId') ||
            vm.vmDocument.objectId.jobId,
          vmwareVmxFile: true,
          entityId: vm.vmDocument.objectId.entity.id,
          jobInstanceId: docVersion.instanceId.jobInstanceId,
          jobStartTimeUsecs: docVersion.instanceId.jobStartTimeUsecs,
          viewBoxId: vm.vmDocument.viewBoxId,
          clusterId: _.get(vm.vmDocument.objectId.jobUid, 'clusterId'),
          clusterIncarnationId:
            _.get(vm.vmDocument.objectId.jobUid, 'clusterIncarnationId'),
        };

        RestoreService.downloadFile(params);
      });
    }

    /**
     * Pass through function to add or remove an item from the cart based on
     * its current inclusion/exclusion.
     *
     * @method    toggleInCart
     * @param     {object}    entity      entity to add to cart
     * @param     {boolean}   [proceed]   true if user should be sent on to
     *                                    options page, false otherwise.
     * @return    {object}    promise     Promise that is resolved once entity
     *                                    is added to cart
     */
    function toggleInCart(entity, proceed) {
      var isInCart = isEntityInCart(entity);

      // Clearing the task cart here and only allowing to add the selected
      // entity, when allowSingleSelection is true.
      if($ctrl.allowSingleSelection) {
        $scope.shared.taskCart.length = 0;
        isInCart = false;
      }
      var actionFn = isInCart ? removeFromCart : addToCart;

      // If removing from cart, never proceed. Otherwise do as instructed via
      // function cart.
      proceed = isInCart ? false : proceed;

      return actionFn(entity, proceed);
    }

    /**
     * Add the set of Entities to the task cart/box
     *
     * @method    addToCart
     * @param     {object}    entity      entity to add to cart
     * @param     {boolean}   [proceed]   true if user should be sent on to
     *                                    options page, false otherwise.
     * @return    {object}    promise     Promise that is resolved once entity
     *                                    is added to cart
     */
    function addToCart(entity, proceed) {
      var deferredObj = $q.defer();
      var entityKey = entity._entityKey;
      var virtualDiskInfo = (entity._type === 'job' ?
        _.get(entity, 'vmDocument.objectId.entity.' + entityKey + '.virtualDiskInfo') :
        _.get(entity, '_protectionSource.' + entityKey + '.virtualDiskInfo')) || [];

      if (!entity) {
        return deferredObj.reject();
      }

      if (isInCart(entity)) {
        deferredObj.resolve();
      } else {
        // In the event that this is a Job being added to cart, remove any
        // entities/VMs from the cart if they belong to the Job,  as they'll be
        // represented implicitly by putting the Job in the cart.
        if (entity._type === 'job') {
          _removeJobsEntitiesFromCart(entity);
        }

        // If we don't already have the jobRuns history for this
        // job, get it when adding it to the cart.
        if (!entity.vmDocument.versions ||
          !entity.vmDocument.versions.length) {
            RestoreService.getJobRunHistory(entity).then(
            function entityUpdated(updatedEntity) {
              processUpdatedEntity(entity);
              $scope.shared.taskCart.push(updatedEntity);
              deferredObj.resolve();

              // Scanner call
              _getVulScanResult(updatedEntity);


              if (proceed) {
                $state.go($scope.stateNames[2]);
              }
            }
          );
        } else {
          $scope.shared.taskCart.push(entity);
          deferredObj.resolve();

          // Scanner call
          _getVulScanResult(entity);

          if (proceed) {
            $state.go($scope.stateNames[2]);
          }
        }
      }

      // Use null to disable the checkbox in the template
      entity._selected = null;

      if (_.isUndefined($scope.shared.areNativeSnapshots)) {
        $scope.shared.areNativeSnapshots =
          ENV_GROUPS.nativeSnapshotTypes.includes(entity._jobType) ||

            // remoteSnapshotManager uses a different 'jobType' but is a native
            // snapshot internally
            ENV_GROUPS.remoteSnapshotManagementSupported
              .includes(entity._jobType);
      }

      if (entity._type === 'job' || entity._osType === 'Linux') {
        $scope.shared.linuxVMSelected = true;
      }

      $scope.shared.largeVMSelected =
        !!virtualDiskInfo.find(function findVirtualDiskInfo(info) {
          // check for disk greater than 1TB
          return info.logicalSizeBytes >= Math.pow(1024, 4);
        });

      if ($scope.shared.sourcesList && FEATURE_FLAGS.awsFleetRecovery) {
        prepareFleetInstanceSettings();
      }

      return deferredObj.promise;
    }

    /**
     * Makes call for scanner results and updates entity
     *
     * @method    _getVulScanResult
     * @param    {object}    entity    entity added in recovery cart
     */
    function _getVulScanResult(entity) {
      var reqBody;

      // Go ahead only if app is installed
      if(!$scope._isVulScanAppPresent) {
        return;
      }

      // Create request body
      reqBody = {
        objectId: entity.vmDocument.objectId,
        type: entity._type,
        versions: entity.vmDocument.versions,
      }

      // Scanner call
      RestoreService.getVulScanResult(reqBody).then(function updateResult(result) {
        if (!_.isEmpty(result.versions)) {
          // Set flag to indicate scan results are presnt for this entity
          entity._isVulScanResultPresent = true;

          // Update the entity with received scanner result
          _updateEntityWithVulScanResults(result.versions, entity);
        }
      });
    }

    /**
     * Update the selected entity with scanner result
     *
     * @method    _updateEntityWithVulScanResults
     * @param    {object}    versions   list of recovery points
     * @param    {object}    entity   selected entity
     */
    function _updateEntityWithVulScanResults(versions, entity) {
      var scanResult;
      var snapshots;

      // Transform versions into key:value for easy access
      var scanResults = _.reduce(versions, function reduceVersion(output, version) {
          output[version.instanceId.jobInstanceId] = version;
          return output;
        }, {});

      if (entity._type === 'vm') {
        snapshots = entity.vmDocument.versions;
      } else if (entity._type === 'job') {
        snapshots = $rootScope.backupJobRuns[entity._uniqueId].runs;
      }

      // Update snapshot with scan result
      _.forEach(snapshots, function getScanResult(snapshot) {
        scanResult = scanResults[snapshot.instanceId.jobInstanceId];
        if (scanResult) {
          snapshot.scanInfo = {
            status: scanResult.scanStatus,
            result: scanResult.result,
          };
        }
      });
    }

    /**
     * Start scan for job/VM
     *
     * @method    startVulScan
     * @param    {object}    entity   selected entity
     */
    function startVulScan(entity) {
      var reqBody = {
        objectId: entity.vmDocument.objectId,
        snapshot: entity._snapshot,
        type: entity._type,
      };

      // Service call to create scan task
      RestoreService.createVulScanTask(reqBody).then(function taskSuccess() {
        cMessage.info({
          titleKey: 'tenableScan.title',
          textKey: 'tenableScan.description',
          textKeyContext: entity,
          timeout: 6000,
        });
      }, evalAJAX.errorMessage);
    }

    /**
     * Downloads scan report
     *
     * @method    downloadVulScanReport
     * @param    {object}    entity   selected entity
     */
    function downloadVulScanReport(entity) {
      RestoreService.downloadVulScanReport(
        entity._snapshot.scanInfo.result.reportUrl);
    }

    /**
     * Removes any entities/VMs from the cart if they belong to the provided
     * job entity.
     *
     * @method   _removeJobsEntitiesFromCart
     * @param    {object}   entity   The search entity
     */
    function _removeJobsEntitiesFromCart(entity) {
      var getJobId = SearchService.getJobId;
      var entityJobId = getJobId(entity);

      if (entity._type !== 'job' || !cart.length) {
        return;
      }

      // This entity is a Job and there are items in the cart. Check for and
      // remove any VMs and vApps from this Job that may already be in the cart.
      // Using forEachRight to iterate through the array backwards, so indexing
      // isn't negatively impacted when/if items are removed from the cart
      // inside the loop.
      _.forEachRight(cart, function checkCartEntities(cartEntity) {
        if (['vm', 'vapp'].includes(cartEntity._type) &&
          getJobId(cartEntity) === entityJobId) {

          // This VM is already represented in the cart and its Job is being
          // added to the cart. Remove it since it will now be represented
          // by the Job object.
          removeFromCart(cartEntity);
        }
      });
    }

    /**
     * Removes the given entity from the task cart
     *
     * @method   removeFromCart
     * @param    {entity|array}   entities   Instance or Array of EntityProto to
     *                                       remove from the cart
     */
    function removeFromCart(entities) {
      var currentIndex;
      var removed;

      entities = [].concat(entities);
      entities.forEach(function cartRemovalLoop(entity) {
        currentIndex = $scope.shared.taskCart.indexOf(entity);
        removed = $scope.shared.taskCart.splice(currentIndex, 1);
        removed._selected = false;
      });
    }

    /**
     * Resets the recovery task to its default settings, retaining any
     * selected objects and name changes.
     *
     * @method     resetTaskDefaults
     */
    function resetTaskDefaults() {

      // Copy any existing name and objects
      var task = $scope.shared.task;
      var name = angular.copy(task.name) || undefined;
      var objects = angular.copy(task.objects) || undefined;

      // Blow away the task object and update it with the saved values
      // above
      task = angular.extend(angular.copy(defaultEmptyTask), {
        name: name,
        objects: objects,
      });

      // copy updated object back to $scope object
      $scope.shared.task = task;

      // TODO-spencer: Consider moving to a helper function.
      $scope.shared.networkingOptions = 'original';

      if (!isRecover) {
        $scope.shared.networkingOptions = 'detach';
        $scope.shared.task.restoredObjectsNetworkConfig.detachNetwork = true;
      }

      if ($scope.shared.taskCart.length) {
        switch ($scope.shared.taskCart[0]._entityKey) {
          case 'hypervEntity':
            task.hypervParams = {};
            break;

          case 'acropolisEntity':
            task.acropolisParams = {};
            break;

          case 'kvmEntity':
            task.kvmParams = {};
            break;

          /**
           * The vmwareEntity being the first environment type originally
           * implemented, doesn't fork like these newer environment types, so
           * there is no change to accomodate that.
           */
        }
      }
    }

    /**
     * Start the recover VM flow over again
     *
     * @method     startTaskOver
     */
    function startTaskOver() {
      $state.go($scope.stateNames[1], {reload: true, location: 'replace'});
    }

    /**
     * Tests if the provided entity is represented in the cart, either directly
     * or implicitly via its Job.
     *
     * @method     isInCart
     * @param      {object}    entity  The entity to evaluate
     * @return     {boolean}           True if the entity is already represented
     *                                 in the cart, False if it's not
     */
    function isInCart(entity) {
      return isEntityInCart(entity) || isEntitysJobInCart(entity);
    }

    /**
     * Indicates if the provided entity is represented in the cart.
     *
     * @method   _isEntityInCart
     * @param    {object}    entity   The entity to check
     * @return   {boolean}   true if entity is in cart, false otherwise
     */
    function isEntityInCart(entity) {
      // Loop and look for this object until a match is found
      return _.some(cart, function findInCart(cartEntity) {
        // Must check types, as 'job' entities are derived from first object/vm
        // instance found in search results so only comparing objectId would
        // generate a false positive.
        return entity._type === cartEntity._type &&
          _.isEqual(entity.vmDocument.objectId, cartEntity.vmDocument.objectId);
      });
    }

    /**
     * Indicates if the provided entity is represented in the cart by its Job.
     *
     * @method   _isEntitysJobInCart
     * @param    {object}    entity   The entity to check
     * @return   {boolean}   true if entity's job is in cart, false otherwise
     */
    function isEntitysJobInCart(entity) {
      var getJobId = SearchService.getJobId;

      // Not a VM entity? Exit early to avoid the loop.
      if (!['vm', 'vapp'].includes(entity._type)) {
        return false;
      }

      // Loop and look for this object until one is found
      return (cart || []).some(function findInCart(cartEntity) {
        return cartEntity._type === 'job' &&
          getJobId(entity) === getJobId(cartEntity);
      });
    }

    /**
     * Gets the backup target type.
     *
     * @method     getTargetType
     * @param      {object}  object  The VM or Job entity
     * @return     {string}  The target type.
     */
    function getTargetType(object) {
      switch (true) {
        case object._archivalTarget?.ownershipContext === 1:
          return 'vault';

        case object._archivalTarget && object._archivalTarget.type !== 1:
          return 'cloud';

        case object._archivalTarget && object._archivalTarget.type === 1:
          return 'tape';

        case ENV_GROUPS.remoteSnapshotManagementSupported
          .includes(object._jobType):
          return 'csm';

        default:
          return 'local';
      }
    }

    /**
     * Gets the target tooltip.
     *
     * @method     getTargetTooltip
     * @param      {object}  object  The VM or Job entity
     * @return     {string}  The tooltip that contains the name of the vault for archival target.
     *                       If target is not archival vault, then it returns the target type.
     */
    function getTargetTooltip(object) {
      var vaultName;
      if (object._archivalTarget &&
        object._archivalTarget.vaultId &&
        $rootScope.externalTargets) {
        vaultName = $rootScope.externalTargets[object._archivalTarget.vaultId];
        if (vaultName) {
          return vaultName;
        }
      }

      return $scope.text.targetTypeTooltips[getTargetType(object)];
    }

    /**
     * Return the proper string which indicates it's a PIT, shanpshot, or a
     * general term.
     *
     * @method  decidePrefix
     * @param   {object}  object   The recoverd object.
     * @return  {string}  The string should be displayed.
     */
    function decidePrefix(object) {
      if ($scope.SOURCE_TYPE_GROUPS.protectableVmEntities.includes(object._type)) {
        return object._pointInTimeRestoreTimeUsecs ?
          $translate.instant('pointInTime') : $translate.instant('snapshot');
      } else {
        return $translate.instant('from');
      }
    }

    // PRIVATE METHODS

    /**
     * Destroys the search & task config.
     *
     * @method     destroyTask
     */
    function destroyTask() {
      // Destroy the cSearch isntance
      cSearchService.destroy($scope.shared.vmSearchId);

      // Gut the task config
      $rootScope.viewBoxes =
        $rootScope.parentSources =
        $rootScope.jobs =
        $rootScope.externalTargets =
        $rootScope.backupJobRuns = undefined;
    }

    /**
     * Prefetch several pieces of data we're going to need along the way here.
     *
     * @method     preFetchReferences
     * @return     {object}       $q promise
     */
    function preFetchReferences() {
      var promises = {
        // viewBoxes
        viewBoxes: ViewBoxService.getOwnViewBoxes(),

        // ParentSources
        parentSources: SourceService.getEntitiesOfType({
          environmentTypes:
            ['kVMware', 'kHyperV', 'kHyperVVSS', 'kAcropolis',
              'kAWS', 'kKVM', 'kGCP'],
          awsEntityTypes: ['kIAMUser'],
          gcpEntityTypes: ['kIAMUser'],
          vmwareEntityTypes: ['kVCenter', 'kStandaloneHost'],
          hypervEntityTypes: ['kSCVMMServer', 'kStandaloneHost',
            'kStandaloneCluster'],
          acropolisEntityTypes: ['kPrismCentral', 'kStandaloneCluster'],
          kvmEntityTypes: ['kOVirtManager'],
        }),

        // Update the hash of vaults
        vaults: $rootScope.user.privs.CLUSTER_EXTERNAL_TARGET_VIEW ?
          ExternalTargetService.getTargets() : $q.resolve([]),

        // Get jobs' summaries for use in the filters
        jobs: JobRunsService.getJobSummary({
          includeJobsWithoutRun: false,
          isDeleted: false,
          onlyReturnJobDescription: true,
          numRuns: 0,
        }),

        sourcesList: SourceService.getSources({
          envTypes: cUtils.onlyNumbers(ENV_GROUPS.hypervisor),
          pruneNonCriticalInfo: true
        }),
      };

      return $q.all(promises)
        .then(function allFetched(responses) {
          $rootScope.viewBoxes = responses.viewBoxes;
          $rootScope.parentSources = responses.parentSources;
          $rootScope.externalTargets = ExternalTargetService.targetNameMapById;
          $rootScope.vaults = responses.vaults;

          $scope.sourcesList = responses.sourcesList.entityHierarchy.children;
          // aliasing to prevent isolated scope problem
          $scope.shared.sourcesList = $scope.sourcesList;

          $rootScope.jobs = (responses.jobs.data || [])
            .map(function jobFilterMapperFn(job) {
              var jobDescription = job.backupJobSummary.jobDescription;

              return angular.extend({
                jobName: jobDescription.name,
              }, jobDescription);
            });
        });
    }

    /**
     * Aws uses fleet instances for recovery.
     * Lay some groundwork for them.
     *
     * @method prepareFleetInstanceSettings
     */
    function prepareFleetInstanceSettings() {
      var registeredSourceType =
        _.get($scope, 'shared.taskCart[0].registeredSource.type');

      if (registeredSourceType !== ENV_TYPE_CONVERSION['kAWS']) {
        return;
      }

      // This will be used if "Use same Subnet as Protected VMs" is selected
      $ctrl.awsFleetInstanceEntity = $scope.shared.sourcesList.find(
        function findSource(source) {
          return source.entity.id ===
            $scope.shared.taskCart[0].registeredSource.id;
        }
      )

      // For "Restore to original Location", fleet region should be pre-poulated
      $ctrl.awsFleetRegion =
        $ctrl.awsFleetInstanceEntity.children.find(function findEntity(child) {
          if (child.children) {
            return child.children.some(findEntity);
          } else {
            return child.entity.id ===
              $scope.shared.taskCart[0].vmDocument.objectId.entity.id;
          }
        });

      $scope.shared.setDefaultFleetRegion = setDefaultFleetRegion;
      setDefaultFleetRegion();
    }

    /**
     * By default, Fleet Instance will have the same region as the
     * VM being protected
     *
     * @method  setDefaultFleetRegion
     */
    function setDefaultFleetRegion() {
      if ($scope.state && $scope.state.showParentSourceOptions) {
        $scope.shared.task.selectedAWSRegion = undefined;
      } else {
        if ($ctrl.awsFleetInstanceEntity) {
          $scope.shared.task.selectedAWSRegion =
            angular.copy($ctrl.awsFleetRegion ? $ctrl.awsFleetRegion.entity: undefined);
          // Allow shared scope to get populated
          $timeout(function afterTimeout() {
            if ($ctrl.awsFleetRegion) {
              $scope.shared.selectAWSRegion(
                $ctrl.awsFleetRegion.entity, $ctrl.awsFleetRegion);
            }
          });
        }
      }

      $scope.shared.task.selectedAWSFleetVpc =
        $scope.shared.task.selectedAWSFleetSubnet = undefined;
    }

    activate();
  }

  // Shared controller for replication task cart modal
  function sharedCartModalControllerFn(
    $rootScope, $$scope, $scope, $filter, $uibModalInstance,
    taskCart) {
    angular.extend($scope, {
      taskCart: taskCart || [],
      text: $$scope.text || {},
      restrictedViewBox: $$scope.restrictedViewBox,
      snapshotsByJob: $$scope.snapshotsByJob,
      jobsList: $$scope.jobsList,
      parent: $$scope,

      // Passing the default value to the shared-modal-task-cart
      isRecover: isRecover,

      // $SCOPE METHODS
      removeFromCart: $$scope.removeFromCart,
    });
  }

  // ControllerFn for the incompatible objects decision modal (restore from
  // archivalTarget)
  function sharedArchivalTargetDecisionModalFn($scope, $rootScope, archiveType,
    incompatibleObjects, $uibModalInstance) {
    angular.extend($scope, {
      objects: incompatibleObjects,
      denseList: incompatibleObjects.length > 4,
      archiveType: archiveType,
      text: $rootScope.text.protection_sharedRestore_restore2Text,

      cancel: cancel,
      close: close,
    });

    /**
     * dismiss this modal
     *
     * @method     cancel
     * @param      {Any=}  reason  Any reason
     */
    function cancel(reason) {
      $uibModalInstance.dismiss(reason || false);
    }

    /**
     * Close the modal (decision is made)
     *
     * @method     close
     * @param      {Any=}  reason  Any reason
     */
    function close(reason) {
      $uibModalInstance.close(reason || 'remove');
    }
  }

  // Shared controller for snapshot selector modal
  function sharedSnapshotSelectorControllerFn(_,
    $rootScope, backupJobRuns, entity, $scope, $filter, $q, RestoreService,
    $uibModalInstance, $uibModal, SNAPSHOT_TARGET_TYPE_STRINGS, moment,
    sharedScope, ENV_GROUPS, canUsePitCalendar, NgIrisContextService) {

    var isJob = entity._type === 'job';
    var isVM = !isJob;
    var uniqueId = entity._uniqueId;

    // These are sorted on load by stTable in DESC order
    var Versions = getVersions();

    // For abbreviated flow, fallback on entity's versions
    var Runs = isJob ?
      (entity.vmDocument.versions && entity.vmDocument.versions.length ?
        entity.vmDocument.versions : backupJobRuns[uniqueId].runs) : [];

    var availableTargets = getReplicaVecs(entity._snapshotIndex);

    // We have a preselected target, lets populate the scope with that
    // for display.
    if (entity._archivalTarget) {
      availableTargets = $filter('filter')(availableTargets, {
        target:{ archivalTarget: entity._archivalTarget }
      });
    }

    angular.extend($scope, {
      areSnapshotsRemotelyManaged:
        ENV_GROUPS.remoteSnapshotManagementSupported.includes(entity._jobType),
      backupJobRuns: backupJobRuns,
      entity: entity,
      isClone: isClone,
      isJob: isJob,
      isRecover: isRecover,
      isVM: isVM,
      loadingVms: {},
      selectedSnapshotIndex: entity._snapshotIndex,
      selectedSnapshotTarget: (Array.isArray(availableTargets)) ?
        availableTargets[0] : undefined,
      showVMs: {},
      snapshotSearchId: 'snapshotSearch',
      snapshotsList: Versions,

      // PIT variables
      timezone: moment.tz.guess(),
      snapshotsDate: entity._pointInTimeRestoreTimeUsecs > -1 ?
        entity._pointInTimeRestoreTimeUsecs/1000 :
        entity._snapshot.instanceId.jobStartTimeUsecs/1000,
      selections: {
        selectedSnapshot: undefined,
      },
      canUsePitCalendar: canUsePitCalendar,

      // Scope Methods
      getEntitySnapshotIndex: getEntitySnapshotIndex,
      getJobRunByInstanceId: getJobRunByInstanceId,
      getVulScanStatusClass: getVulScanStatusClass,
      getSnapshotTargetString: getSnapshotTargetString,
      getTargets: getReplicaVecs,
      isSnapshotSelected: isSnapshotSelected,
      isTargetSelected: isTargetSelected,
      processVmsForSnapshot: processVmsForSnapshot,
      saveSnapshotSelection: saveSnapshotSelection,
      selectSnapshot: selectSnapshot,
      selectSnapshotTarget: selectSnapshotTarget,
      shouldDisableRowSelection: shouldDisableRowSelection,
      shouldDisableTargetSelection: shouldDisableTargetSelection
    });

    // Watch pagination changes and clear the showVMs hash.
    $scope.$watchCollection('thisPage', function paginationChangeWatcher() {
      $scope.showVMs = {};
    });

    /**
     * Gets the replicaVecs for a VM or Job (or other??)
     *
     * @method     getReplicaVecs
     * @param      {Integer}  snapshotIndex  Selected snapshotIndex
     * @return     {Array}                   List of ReplicaVecs, if any
     */
    // NOTE(spencer): This function is deprecated and nothing is using it.
    function getReplicaVecs(snapshotIndex) {
      var out = [];

      // Default to the Entity's pre-selected snapshotIndex
      snapshotIndex = snapshotIndex || entity._snapshotIndex || 0;

      switch (true) {
        case isVM:
          out = Versions[snapshotIndex].replicaInfo.replicaVec;
          break;

        case isJob:
          out = getJobRunByInstanceId(snapshotIndex);
          out = (out && out.length) ? out[0].replicaInfo.replicaVec : [];
          break;
      }

      // Filter out replicaTargets (type 2)
      out = $filter('filter')(out, {target:{type: '!2'}});

      // Return the list sorted by target.type
      return $filter('orderBy')(out, 'target.type');
    }

    /**
     * Determines if the given snapshot target is the selected one.
     *
     * @method     isTargetSelected
     * @param      {Target}  target  snapshot target to check on
     * @return     {Bool}    True if the currently selected target.
     */
    function isTargetSelected(target) {
      return angular.equals($scope.selectedSnapshotTarget, target);
    }

    /**
     * Determine if a snapshot is currently selected
     *
     * @method     isSnapshotSelected
     * @param      {object}   row     EntityProto to detect selection of
     * @return     {boolean}
     */
    function isSnapshotSelected(row) {
      var version;
      switch (true) {
        case isVM:
          version = Versions[$scope.selectedSnapshotIndex];
          break;
        case isJob:
          if (!backupJobRuns[uniqueId]) {
            return false;
          }
          version = backupJobRuns[uniqueId].runs[$scope.selectedSnapshotIndex];
          break;
      }
      return angular.equals(version.instanceId,
                 row.instanceId);
    }

    /**
     * Helper function to retrieve a JobRun by it's instanceId (sometimes
     * referred to as snapshotIndex in various places--in the context of a
     * job).
     *
     * @method     getJobRunByInstanceId
     * @param      {String}  instanceId  InstanceId of a job runinstanceId
     *                                   (Integer at this time)
     * @return     {Object}              JobRun object
     */
    function getJobRunByInstanceId(instanceId) {
      return $filter('filter')(Versions, {instanceId:{jobInstanceId: instanceId}});
    }

    /**
     * Selects the given replicationTarget
     *
     * @method     selectSnapshotTarget
     * @param      {Target}  target  ReplicationTarget to select
     */
    function selectSnapshotTarget(target) {
      var isPermittedType = !target ? false :
        ([1, 2].indexOf(target.target.type) < 0);
      var replicaVecs = [];

      if (target && isPermittedType) {
        // Is the target present and allowed (not local or replication types)
        $scope.selectedSnapshotTarget = target;
      } else {
        // Guess not. Lets use to the first that we have
        replicaVecs = $scope.getTargets($scope.selectedSnapshotIndex);
        if (replicaVecs.length) {
          $scope.selectedSnapshotTarget = replicaVecs[0];
        }
      }
    }

    /**
     * Determine if a snapshot row should be disabled
     *
     *
     * @param     {Object} row  The current snapshot row.
     * @return    {boolean} True if roww should be disabled.
     */
    function shouldDisableRowSelection(row) {
      if ( row.replicaInfo?.replicaVec?.every(snapshotTarget =>
        snapshotTarget.target.archivalTarget?.ownershipContext === 1)) {
        return !isMcm(NgIrisContextService.irisContext)
      }
      return false;
    }

    /**
     * Determine if the snapshot selection should be disabled.
     *
     *
     * @param     {Object} snapshotTarget  The current snapshot target.
     * @return    {boolean} True if selection should be disabled.
     */
    function shouldDisableTargetSelection(snapshotTarget) {
      if (!isMcm(NgIrisContextService.irisContext) &&
        snapshotTarget.target?.archivalTarget?.ownershipContext === 1) {
        return true;
      }
      return snapshotTarget.target.type === 5;
    }

    /**
     * Get a display string for a snapshot target
     *
     * @method   getSnapshotTargetString
     * @param    {Object}   target   The target object
     * @return   {String}   The display string
     */
    function getSnapshotTargetString(target) {
      switch (target.type) {
        case 1:

          // Local target
          return ($scope.areSnapshotsRemotelyManaged ? 'awsS3' :
            SNAPSHOT_TARGET_TYPE_STRINGS[target.type]);

        case 3:
          if (!isMcm(NgIrisContextService.irisContext) && target.archivalTarget?.ownershipContext === 1) {
            return 'rpaas.snapshotNotAccessible';
          }

          // Archival target (cloud/tape)
          if (target.archivalTarget &&
            target.archivalTarget.vaultId &&
            $rootScope.externalTargets) {
            return $rootScope.externalTargets[target.archivalTarget.vaultId] ||

              // Fallback on type in case the vaultId isn't hashed yet
              SNAPSHOT_TARGET_TYPE_STRINGS[target.type][target.archivalTarget.type];
          }

          // Fallback on type in case the vaultId isn't hashed yet
          return SNAPSHOT_TARGET_TYPE_STRINGS[target.type][target.archivalTarget.type] ||
            // Call this Fn again to trigger the default case.
            getSnapshotTargetString({});

        case 5:
          return SNAPSHOT_TARGET_TYPE_STRINGS[target.type];

        default:
          // Replication or unknown target
          return 'vmSnapshotSelectModal.invalidVaultType';
      }
    }

    /**
     * Saves the selected snapshot. Also handles detection of incompatible
     * task objects if choosing an archiveTarget snapshot. Opens a modal to
     * remove incompatible objects from task cart, or reselect targets.
     *
     * @method     saveSnapshotSelection
     * @return     {object}     $q promise with results of selection
     */
    function saveSnapshotSelection() {
      var selectedSnapshot = $scope.selections.selectedSnapshot || {};
      var deferred = $q.defer();
      var incompatibleObjects = [];
      var promises = [];
      var snapshot;
      var out;

      if ($scope.canUsePitCalendar &&
        !_.get(selectedSnapshot.snapshot, 'snapshotType')) {
        out = {
          pointInTime: selectedSnapshot.pointInTime * 1000000,
          snapshot: selectedSnapshot.snapshot,
          isPIT: true,
        };
        return $uibModalInstance.close(out);
      }
      else {
        // If the user selects a snapshot from PIT slider, find out the index of
        // the selected snapshot.
        if ($scope.canUsePitCalendar) {
          selectSnapshot(selectedSnapshot.snapshot.instanceId, undefined, true);
        }

        snapshot = ((isVM) ? Versions[$scope.selectedSnapshotIndex] :
          Runs.find(function findRun(run) {
            return run.instanceId.jobInstanceId ===
              $scope.selectedSnapshotIndex;
          }));

        out = {
          incompatibleObjects: false,
          snapshot: snapshot,
          snapshotIndex: $scope.selectedSnapshotIndex,
        };

        if (_.get($scope.selectedSnapshotTarget, 'target.archivalTarget')) {

          incompatibleObjects = getIncompatibleObjects(snapshot.instanceId);

          // Check task cart for incompatible objects
          // If incompatible, open modal
          if (incompatibleObjects.length) {
            promises.push(openDecisionModal(incompatibleObjects)
              .then(function decisionModalClosed(resp) {
                if (resp === 'remove') {
                  out.archivalTarget =
                    $scope.selectedSnapshotTarget.target.archivalTarget;

                  // Add flag to remove incompatible objects and continue
                  out.incompatibleObjects = incompatibleObjects;
                }

                return resp;
              })
          );
          } else {
            out.archivalTarget = $scope.selectedSnapshotTarget.target.archivalTarget;
          }

        } else {
          promises.push(deferred.resolve(false));
        }

        return $q.all(promises)
          .then(function allPromisesDone(resp) {

            // if the snapshot is archive and archive is a tape, we need to also
            // return tapeListParams.
            if (out.archivalTarget && out.archivalTarget.type === 1) {
              out.tapeListParams = angular.copy($scope.selectedSnapshotTarget.archiveUid);

              // rename objectId to qstarArchiveJobId for API call
              out.tapeListParams.qstarArchiveJobId = out.tapeListParams.objectId;
              out.tapeListParams.objectId = undefined;
            }

            return $uibModalInstance.close(out);
          });
      }
    }

    /**
     * Sets the selected snapshot index from the given snapshot
     *
     * @method     selectSnapshot
     * @param      {Object}  entity   EntityProto to select the index of
     * @param      {Object}  target   snapshotTarget Object
     * @param      {Boolean} isPointInTime  If the snapshot is selected from
     *                                      snapshot picker
     */
    function selectSnapshot(entity, target, isPointInTime) {
      $scope.selectedSnapshotIndex = isPointInTime ?
        getPITSnapshotIndex(entity) : getEntitySnapshotIndex(entity);
      selectSnapshotTarget(target);
    }

    /**
     * Retrieves the snapshotIndex (or jobInstanceId) of the given entity,
     * depending on whether or not it's a VM or a Job.
     *
     * @method     getEntitySnapshotIndex
     * @param      {object}  entity  Entity to get the index of
     * @return     {String}          snapshotIndex, or jobIstnanceId of the
     *                               given entity
     */
    function getEntitySnapshotIndex(entity) {
      return (isVM) ? Versions.indexOf(entity) : entity.instanceId.jobInstanceId;
    }

    /**
     * Find the index of the snapshot selected from PIT time line.
     *
     * @method  getPITSnapshotIndex
     * @param   {object}  entity  A restored object instance which contents
     *                            jobInstanceId, jobStartTimeUsecs, and
     *                            attemptNum.
     * @return  {integer} The index of the snapshot that has the same instanceId.
     */
    function getPITSnapshotIndex(entity) {
      return Versions.findIndex(function findSelectedSnapshotIndex(version) {
        return _.isEqual(version.instanceId, entity);
      });
    }

    // PRIVATE METHODS

    /**
     * Opens the modal to let a user deselect task cart objects that aren't
     * compatible with a selected archivalTarget snapshot
     *
     * @method     openDecisionModal
     * @param      {Array}  incompatibleObjects  List of incompatible objects
     * @return     {object}                     Promised response from the modal (choice)
     */
    function openDecisionModal(incompatibleObjects) {
      var options = {
        animation: true,
        backdrop: 'static',
        templateUrl: 'app/protection/recovery/vm/shared-modal-archival-target-decision.html',
        controller: 'sharedArchivalTargetDecisionModal',
        size: incompatibleObjects.length > 4 ? 'lg' : undefined,
        resolve: {
          // archive type mapping can be seen in constants.js:
          // SNAPSHOT_TARGET_TYPE_STRINGS
          archiveType:
            depsResolver(
              $scope.selectedSnapshotTarget.target.archivalTarget.type),
          incompatibleObjects: depsResolver(incompatibleObjects),
        },
      };
      var modalInstance = $uibModal.open(options);
      return modalInstance.result;
    }

    /**
     * Gets a list of objects that are incompatible with a selected
     * archivalTarget for the given snapshot. Compares instanceIds of the
     * selected snapshot against the selected ones in other objects in the
     * task.
     *
     * @method     getIncompatibleObjects
     * @param      {Object}  instanceId  InstanceId of a given snapshot
     * @return     {Array}               List of incompatible objects from
     *                                   the taskCart
     */
    function getIncompatibleObjects(instanceId) {
      // Incompatible =
      //   different job + different snapshot (instanceId?)
      var out = $filter('filter')(sharedScope.taskCart, function(obj) {
        if (obj === entity) {
          // Same entity, skip it: it's compatible.
          return false;
        } else {
          // Not the same entity, comparing instanceIds
          if (obj._type === 'job') {
            return !angular.equals(instanceId,
              $scope.backupJobRuns[obj._uniqueId].runs[obj._snapshotIndex].instanceId);
          } else {
            return !angular.equals(instanceId,
              obj.vmDocument.versions[obj._snapshotIndex].instanceId);
          }
        }
      });

      return out;
    }

    /**
     * Get the list of versions filtered by if they're usable
     *
     * @method     getVersions
     * @return     {Array}  List of snapshots for a VM or a Job
     */
    function getVersions() {
      return RestoreService.getRestorableVersions(entity.vmDocument.versions || []);
      // var nowUsecs = Date.clusterNow() * 1000;

      /**
       * Array.some Function to test if an archivalTarget is usable. To be
       * usable a target must not be a replication target AND must not be
       * expired.
       *
       * @method     usableTargetFinder
       * @param      {object}   target  The target
       * @return     {boolean}  True if usable, otherwise False.
       */
      // function usableTargetFinder(target) {
      //   // True if not a replication target AND not expired.
      //   return (2 !== target.target.type) && (nowUsecs < target.expiryTimeUsecs);
      // }

      // return (entity.vmDocument.versions || [])
      //   .filter(function filterVersionsFn(version) {
      //     if ('job' === entity._type) {
      //       return !!version.isUsable;
      //     } else {
      //       return (version.replicaInfo &&
      //         version.replicaInfo.replicaVec &&
      //         version.replicaInfo.replicaVec.length &&
      //         version.replicaInfo.replicaVec.some(usableTargetFinder));
      //     }
      //   });
    }

    /**
     * When view VMs is clicked for a certain snapshot, we get the vm
     * information for that snapshot if not already present in the global cache.
     *
     * @method   processVmsForSnapshot
     * @param    {object}   row   The snapshot version object
     */
    function processVmsForSnapshot(row) {
      var snapshotStartTimeUsecs = row.instanceId.jobStartTimeUsecs;
      $scope.showVMs[snapshotStartTimeUsecs] =
        !$scope.showVMs[snapshotStartTimeUsecs];

      // If hide vms is being clicked or we already have the vms of a snapshot
      // selected then return from here.
      if (!$scope.showVMs[snapshotStartTimeUsecs] ||
        $scope.entity._backupJobRuns.runs[row.instanceId.jobInstanceId].vms) {
        return;
      }

      $scope.loadingVms[snapshotStartTimeUsecs] = true;
      updateJobRunWithVms(snapshotStartTimeUsecs);
    }

    /**
     * Get the vms for all runs.
     *
     * @method  updateJobRunWithVms
     * @param   {integer} snapshotStartTimeUsecs
     */
    function updateJobRunWithVms(snapshotStartTimeUsecs) {
      RestoreService.updateJobRunWithVms($scope.entity, snapshotStartTimeUsecs)
        .then(function processUpdatedEntity(entity) {
          $scope.backupJobRuns[entity._uniqueId] = entity._backupJobRuns;
          $scope.loadingVms[snapshotStartTimeUsecs] = false;
        });
    }
  }

  // Shared object select controller
  function sharedTaskObjectSelectionControllerFn(
    _, $rootScope, $scope, $filter, $interpolate, cSearchService,
    moment, ENUM_ENV_TYPE, NavStateService, $state) {

    var $ctrl = this;

    // Default filter options
    var defaultFilterProps = [
      {
        property: 'registeredSourceIds',
        display: 'source',
        transformFn: sourceIdFromName,
        locked: false,
      },
      {
        property: 'viewBoxIds',
        display: 'viewBox',
        transformFn: viewBoxIdFromName,
        locked: false,
      },
      {
        property: 'jobIds',
        display: 'protectionJob',
        transformFn: jobIdFromName,
        locked: false,
      },
      {
        property: 'fromTimeUsecs',
        display: 'startDate',
        transformFn: formatFilterDate,
        locked: false,
      },
      {
        property: 'toTimeUsecs',
        display: 'endDate',
        transformFn: formatFilterDate,
        locked: false,
      },
    ];
    var cart = $scope.shared.taskCart;
    var task = $scope.shared.task;

    _.assign($ctrl, {
      // UI States
      allowSingleSelection: $state.params.isKuiper,
      excludeJobs: $state.params.isKuiper,
    });

    angular.extend($scope, {

      // Flag to indicate we've parsed the View Box from the first cart
      // item which is used to restrict additional searches (via filter)
      firstItemProcessed: false,

      // Hash of context menu action arrays. Updated when
      // shared.searchDisplay changes.
      contextActions: {},


      // $SCOPE METHODS
      isForbidden: isForbidden,
      updateJobSnapshotInfo: updateJobSnapshotInfo,
      buildContextItems: buildContextItems,
    });

    /**
     * Initialization routine for this controller.
     *
     * @method     activate
     */
    function activate() {
      cSearchService.purgeResults($scope.shared.vmSearchId);

      // Copy of the above properties
      $scope.filterProps = angular.copy(defaultFilterProps);

      // WATCHERS
      // Watch the cart for changes and do some stuff, like detect ViewBox from
      // first item and restrict subsequent searches to that ViewBox.
      $scope.$watch('shared.taskCart', _taskCartWatcherFn, true);

      // Watch the paged search results set (smaller) for changes and update the
      // contextActions{} hash accordingly
      $scope.$watchCollection('shared.searchDisplay', _pagedSetWatcherFn);
    }



    function _taskCartWatcherFn(cart) {
      // Get the viewBox filter from the list of available filters.
      var viewBoxFilter = $filter('filter')(
        $scope.filterProps,
        { property: 'viewBox' }
      )[0];

      // Get the registeredSource filter from the list of available filters.
      var registeredSourceFilter = $filter('filter')(
        $scope.filterProps,
        { property: 'registeredSourceIds' }
      )[0];

      var viewBox;

      if (!$scope.firstItemProcessed && cart.length) {
        // Populate the viewbox with the filter by viewBoxId
        viewBox = $filter('viewBox')(
          cart[0].viewBoxId || cart[0].vmDocument.viewBoxId
        );

        // Apply and lock the viewBox filter.
        angular.extend(viewBoxFilter, {
          locked: true,
          value: viewBox.name,
        });

        // Apply and lock the registered sources filter.
        angular.extend(registeredSourceFilter, {
          locked: true,
          value: cart[0].registeredSource[cart[0]._entityKey].name,
        });

        // Track the locked filters on the scope
        $scope.restrictedViewBox = viewBox;
        $scope.restrictedSource = cart[0].registeredSource;

        // Restrict subsequest search to the selected backup types (either
        // HyperV or HyperV2012) for hypervEntity
        if (cart[0]._entityKey === 'hypervEntity') {
          $scope.filterProps.push({
            locked: true,
            property: 'jobTypes',
            display: 'Job Type',
            value: ENUM_ENV_TYPE[cart[0].vmDocument.backupType],
            transformFn: jobTypeFromEntityName,
          });
        }

        // Set some convenience bools in scope for forking functionality
      } else if (!cart.length) {
        // The cart was emptied. Unlock any locked filters.
        $scope.restrictedViewBox =
          $scope.restrictedSource = undefined;
        cSearchService.unlockFilters($scope.shared.vmSearchId, false);
      }

      $scope.updateConvenienceProps();

      $scope.firstItemProcessed = !!cart.length;
    }

    // PRIVATE FUNCTIONS

    /**
     * $watch handler function for _pagedSet.
     *
     * @method   _pagedSetWatcherFn
     * @param    {array}   newSet   The new set
     */
    function _pagedSetWatcherFn(newSet) {
      $scope.showVMsubList = {};
      [].concat(newSet).forEach(function contextActionsMapperFn(row) {
        $scope.contextActions[row._uniqueId] = buildContextItems(row);
      });
    }

    /**
     * Transform an array of Source names to Source IDs
     *
     * @method     sourceIdFromName
     * @param      {Array}  names   Array Source names
     * @return     {Array}          Array of Source IDs
     */
    function sourceIdFromName(names) {
      names = names ? [].concat(names) : [];
      return ((names.length) ? $rootScope.parentSources : [])
        .reduce(function sourceNameReducerFn(sources, source) {
          if (names.includes(source.displayName)) {
            sources.push(source.id);
          }

          return sources;
        }, []);
    }

    /**
     * Transform an array of Job names to Job IDs
     *
     * @method     jobIdFromName
     * @param      {Array}  names   Array of Job names
     * @return     {Array}          Array of Job IDs
     */
    function jobIdFromName(names) {
      names = names ? [].concat(names) : [];
      return (!names.length) ? [] : $rootScope.jobs
        .reduce(function jobNameReducerFn(jobIds, job) {
          if (names.includes(job.jobName)) {
            jobIds.push(job.jobId);
          }

          return jobIds;
        }, []);
    }

    /**
     * Transforms a date into microseconds
     *
     * @method     formatFilterDate
     * @param      {String}  date    Date String to transform
     * @return     {Int}             Date in microseconds
     */
    function formatFilterDate(date) {
      return moment(date).toUsecDate();
    }

    /**
     * TransformFn for viewBox search filter
     *
     * @method     viewBoxIdFromName
     * @param      {Object}  viewBoxes  The viewBox names to get the IDs for
     * @return     {Array}              The viewBox ids
     */
    function viewBoxIdFromName(viewBoxes) {
      viewBoxes = viewBoxes ? [].concat(viewBoxes) : [];
      return (!viewBoxes.length) ? [] : $rootScope.viewBoxes
        .reduce(function viewBoxReducerFn(viewBoxIds, viewBox) {
          if (viewBoxes.includes(viewBox.name)) {
            viewBoxIds.push(viewBox.id);
          }

          return viewBoxIds;
        }, []);
    }

    /**
     * Transform an array of entity names to Job types
     *
     * @method     jobTypeFromName
     * @param      {Array}  names   Array of Job names
     * @return     {Array}          Array of Job Types
     */
    function jobTypeFromEntityName(names) {
      names = names ? [].concat(names) : [];
      return (!names.length) ? [] : NavStateService.getObjectTypes()
        .reduce(function jobTypeReducerFn(jobTypes, job) {
          if (names.includes(job.name)) {
            jobTypes.push(job.enum);
          }

          return jobTypes;
        }, []);
    }

    /**
     * Determines if a given Entity is forbidden from selection.
     *
     * @method     isForbidden
     * @param      {object}   entity  EntityProto to evaluate
     * @return     {Bool}             True if the Entity is forbidden from
     *                                selection. False otherwise.
     */
    function isForbidden(entity) {
      var forbidden = false;
      var selectedRows = $scope.shared.selectedRows;

      if ($scope.restrictedViewBox && entity.vmDocument) {
        // if using a default storage
        if ($scope.restrictedViewBox.id !== undefined) {
          forbidden = entity.vmDocument.viewBoxId !== $scope.restrictedViewBox.id;
        }
      }
      // Selections already made
      if (!forbidden && selectedRows.length) {
        forbidden =
          // Check against the viewBox of the first selected item
          entity.vmDocument.viewBoxId !== selectedRows[0].vmDocument.viewBoxId ||

          // Check against the envType of the first selected item
          entity._entityKey !== selectedRows[0]._entityKey;
      }

      // VMs and vApps cannot be grouped together for restore.
      // vApp cannot be grouped with other vApps, VMs, and any Jobs.
      switch (entity._type) {
        case 'vm':
          forbidden = forbidden || (_.get(cart, '[0]._type') === 'vapp');
          break;

        case 'vapp':
          forbidden = forbidden || ['job', 'vm', 'vapp'].includes(_.get(cart, '[0]._type'));
          break;

        case 'job':
          forbidden = forbidden || (_.get(cart, '[0]._type') === 'vapp');
          break;
      }

      // if not already forbidden, prevent interacting with VMs that are
      // represented by a Job in the cart, as they should not be individually
      // added to the cart.
      forbidden = forbidden || $scope.isEntitysJobInCart(entity);

      return forbidden;
    }

    /**
     * Sets the index of the selected snapshot for a job for reuse later on
     *
     * @method     updateJobSnapshotInfo
     * @param      {Object}  row    The row we're operating on
     * @param      {Int}     model  The index of the selected snapshot
     */
    function updateJobSnapshotInfo(row, model) {
      model = model || row._snapshotIndex;
      var snapshot = $scope.snapshotsByJob[row._id][model];
      row._snapshotIndex = model;
      row._snapshotStartTimeUsecs = snapshot.run.base.startTimeUsecs;
      row._jobInstanceId = snapshot.run.base.jobInstanceId;
    }

    /**
     * Builds and returns an array of c-context-menu compatible items
     *
     * @method     buildContextItems
     * @param      {Object}  obj     The VM Entity
     * @return     {Array}  array of objects to be used displayed via cContextMenu
     */
    function buildContextItems(obj) {
      var config = [];
      var lastSnapshot;

      // Sanity check. Bail if these conditions are met
      if (!obj.vmDocument) {
        return config;
      }

      lastSnapshot =
        (obj.vmDocument.versions && obj.vmDocument.versions.length) ?
          obj.vmDocument.versions[0] : {};

      switch (obj._type) {
        case 'job':
          config.push({
            display: obj._isVmTemplate ? $scope.text.addVMTemplates :
              $scope.text.addVMsInJobToCart,
            icon: 'icn-add',
            action: function addVmsInJobToCartFn() {
              $scope.addToCart(obj);
            },
          }, {
            display: $scope.text.addVMsInJobAndContinue,
            icon: 'icn-add',
            action: function addVmsInJobAndContinueFn() {
              $scope.addToCart(obj, true);
            },
          });
          break;
        case 'vm':
          if (!obj._selected) {
            config.push({
              display: obj._isVmTemplate ? $scope.text.addVMTemplateToCart :
                $scope.text.addVMtoCart,
              icon: 'icn-add',
              action: function andVmToCartFn() {
                $scope.addToCart(obj);
              },
            }, {
              display: obj._isVmTemplate ?
                $scope.text.addVMTemplateAndContinue :
                $scope.text.addVMandContinue,
              icon: 'icn-add',
              action: function andVmToCartAndContinueFn() {
                $scope.addToCart(obj, true);
              },
            });
          }
          if (obj._entityKey === 'vmwareEntity' && !obj._isVmTemplate) {
            config.push({
              display: $interpolate($scope.text.downloadVMXfile)(obj),
              icon: 'icn-download',
              action: function downloadVmxFileFn() {
                $scope.downloadVMXfile(obj);
              },

              note: $interpolate($scope.text.downloadVMXfileSubnote)(lastSnapshot)
            });
          }
          break;
      }

      return config;
    }

    activate();

  }

  // Shared step 2, task options controller
  function sharedTaskOptionsControllerFn(_, $rootScope, $scope, $state, $filter,
    $q, RestoreService, cMessage, SourceService, evalAJAX, FORMATS,
    SearchService, ViewService, PUB_TO_PRIVATE_ENV_STRUCTURES, ENUM_ENV_TYPE,
    ENV_TYPE_CONVERSION, ENV_GROUPS, ENUM_ARCHIVAL_TARGET, FEATURE_FLAGS,
    ENUM_RESTORE_TYPE, cUtils, HypervisorVmService, $translate, localStorageService,
    JobService, PolicyService, SCHEDULING_POLICY_PERIODICITY, CLOUD_SUBSCRIPTION,
    NgStateManagementService) {

    var $ctrl = this;
    var backupRenameRestoredObjectParam;
    var showParentSourceOptions =
      isClone || !!$scope.shared.task.restoreParentSource;
    var showRenameOptions = !!$scope.shared.task.renameRestoredObjectParam;

    // Use local storage to store user preferences to whether restore
    // to parent and to rename the VM or not. It is stored in local storage so
    // that the next time when a user go through this workflow, this page
    // remembers the settings used last time.
    var vmRestorePreferencesKey =
      ['vmRestorePreferences', $rootScope.user.username].join();
    var vmRestorePreferences = localStorageService.get(vmRestorePreferencesKey);

    if (vmRestorePreferences) {
      if (!_.isNil(vmRestorePreferences.showParent)) {
        showParentSourceOptions = vmRestorePreferences.showParent;
      }

      if (!_.isNil(vmRestorePreferences.showRename)) {
        showRenameOptions = vmRestorePreferences.showRename;
      }
    }

    angular.extend($scope, {
      ENUM_ENTITY_TYPE: $filter('cCONST')('ENUM_ENTITY_TYPE'),
      acropolisStorageContainers: $scope.acropolisStorageContainers || [],
      areNativeSnapshots: $scope.shared.areNativeSnapshots,
      parentSources: SourceService.flatSources || [],
      resourcePools: $scope.resourcePools || [],
      datastores: $scope.datastores || [],
      vmFolders: $scope.vmFolders || [],
      datastoreFolders: $scope.datastoreFolders || [],
      NFSViews: $scope.NFSViews || [],
      computeOptions: $scope.computeOptions || [],
      networkEntities: $scope.networkEntities || [],
      vnicEntities: $scope.vnicEntities || [],
      storageAccounts: $scope.storageAccounts || [],
      hypervVolumes: $scope.hypervVolumes || [],
      storageContainers: $scope.storageContainers || [],
      subnets: $scope.subnets || [],
      virtualNetworks: $scope.virtualNetworks || [],
      cloneAndRecoverDataLoaded: true,
      disablePreserveTagToggle: false,
      isLowerCaseRequired: false,
      showOptions: showTaskOptions(),
      showRenameOptions: showRenameOptions,
      state: {showParentSourceOptions: showParentSourceOptions},
      targetSourceIsVcd: false,
      storageProfiles: $scope.storageProfiles || [],

      // Specifies if warning banner should be rendered for selected objects
      showAzureCloneWarning: false,

      // List of objects that do not meet the disk size requirements for azure
      invalidAzureCloneObjects: [],

      // a pseudo RegEx Object with overridden 'test' method for prefix matching
      handlePrefixRenaming: {
        test: _.bind(_regexTest, this, 'prefix')
      },

      // a pseudo RegEx Object with overridden 'test' method for suffix matching
      handleSuffixRenaming: {
        test: _.bind(_regexTest, this, 'suffix')
      },

      // a pseudo RegEx Object with overridden 'test' method for tagName matching
      handleTagNameValidation: {
        test: _.bind(_tagNameValidator, this)
      },

      // $SCOPE METHODS
      enableMultipleDatastore: enableMultipleDatastore,
      preserveTagTogglePerformance: preserveTagTogglePerformance,
      hideRecoverOptions: hideRecoverOptions,
      hypervComputeChanged: hypervComputeChanged,
      isNetworkSelectAllowed: isNetworkSelectAllowed,
      getKVMDataSources: getKVMDataSources,
      filterKVMVnicEntity: filterKVMVnicEntity,
      filterKVMNetworkEntity: filterKVMNetworkEntity,
      NFSViewChanged: NFSViewChanged,
      parentSourceChanged: parentSourceChanged,
      resetRecoverOptions: resetRecoverOptions,
      resetRecoveryLocation: resetRecoveryLocation,
      resourceGroupEntityChanged: resourceGroupEntityChanged,
      resourcePoolEntityChanged: resourcePoolEntityChanged,
      showRecoverOptions: showRecoverOptions,
      storageAccountChanged: storageAccountChanged,
      submitTask: submitTask,
      isSubmitDisabled: isSubmitDisabled,
      validateViewName: validateViewName,
      virtualNetworkEntityChanged: virtualNetworkEntityChanged,
      toggleFleetSettings: toggleFleetSettings,
      toggleRenameOptions: toggleRenameOptions,
      toggleVappRenameOptions: toggleVappRenameOptions,
      selectVcloudVdc: selectVcloudVdc,
      setPrefixSuffixMaxLength: setPrefixSuffixMaxLength,
      maxSelectableDatastores: maxSelectableDatastores,
      getObjectForCart: getObjectForCart,
    });

    $scope.shared.networkingOptions = isRecover ? 'original' : 'detach';

    _.assign($ctrl, {
      // UI states
      excludeNetworkConfig: false,
      excludeSourceSelection: false,
      isSubmitting: false,
      kuiperSelected: false,
      recoveryParams: {},
      task: {},
      vCenterIds: [],
      resubmitWorkflow: false,

      // Methods
      $onInit: onInit,
    });

    // WATCHERS

    // When the task cart changes, update the task objects to match
    $scope.$watch('shared.taskCart', function cartChangeHandler(cart) {
      populateRestoreObjects(cart);
      $scope.updateConvenienceProps();

      updateSourceLinkInfo(cart);
    }, true);

    // Watch to toggle display of task rename config fields
    $scope.$watch('showRenameOptions',
      function showRenameOptionsWatcher(nv) {
        // This is to intelligently delete and restore any current-configured
        // rename options while still in this view.
        if ($scope.shared.task.renameRestoredObjectParam) {
          if (!nv) {
            // If true, lets backup the existing value and delete the property.
            backupRenameRestoredObjectParam = angular.copy($scope.shared.task.renameRestoredObjectParam || {});
            $scope.shared.task.renameRestoredObjectParam = undefined;
          }
        } else {
          if (nv && !!backupRenameRestoredObjectParam) {
            $scope.shared.task.renameRestoredObjectParam = angular.copy(backupRenameRestoredObjectParam);
            backupRenameRestoredObjectParam = undefined;
          }
        }
      });

    // Watch to toggle display of restoreParentSource config fields. This is
    // only used in recover case.
    $scope.$watch('state.showParentSourceOptions',
      function showParentSourceOptionsWatcher(nv, ov) {
        if (nv === false) {
          // Recover to original location case.
          $scope.shared.task.restoreParentSource = undefined;
          $scope.shared.task.restoredObjectsNetworkConfig.detachNetwork =
            undefined;
          if ($scope.shared.task.restoredObjectsNetworkConfig) {
            // Default to 'Keep original network'.
            $scope.shared.networkingOptions = 'original';
            $scope.shared.task.restoredObjectsNetworkConfig.networkEntity =
              undefined;
          }

          $scope.shared.task.datastoreEntity =
            $scope.shared.task.resourcePoolEntity =
            $scope.shared.task.viewName = undefined;

          if ($scope.shared.task.vmwareParams) {
            $scope.shared.task.vmwareParams.storageProfileName = undefined;
            $scope.shared.task.vmwareParams.StorageProfileVcdUuid = undefined;
          }
        } else {
          // Recover to new location case. Reset to detach network.
          $scope.shared.networkingOptions = 'detach';
          $scope.shared.task.restoredObjectsNetworkConfig.detachNetwork = true;
        }

        // Update the preferences in local storage
        var vmRestorePreferences =
          localStorageService.get(vmRestorePreferencesKey) || {};
        vmRestorePreferences.showParent = nv;
        localStorageService.set(vmRestorePreferencesKey, vmRestorePreferences);
      }
    );

    /**
     * Set/Unset the ability to add tags to the aws source based on
     * "specifyFleetSettings" value.
     *
     * @method  toggleFleetSettings
     */
    function toggleFleetSettings() {
      $ctrl.tags =
        $ctrl.specifyFleetSettings ? [] : undefined;
    }

    // PRIVATE FUNCTIONS

    /**
     * Sets some options to manage the link between the sources.
     *
     *  - Checks if the VM to be recovered has the same source registered on the
     *    Tx side by finding the intersections between the sources registered on
     *    both the sides.
     *
     * @method   updateSourceLinkInfo
     * @param    {Object}   cart   the VMs selected.
     */
    function updateSourceLinkInfo(cart) {
      $scope.disableRecoveryOptionToOrginalLocation =
        !_.intersection(_.map(cart, '_registeredSourceUuid'),
        _.map($scope.shared.sourcesList, '_typeEntity.uuid')).length;

      $scope.state.showParentSourceOptions =
        $scope.disableRecoveryOptionToOrginalLocation ||
        $scope.state.showParentSourceOptions;
    }

    /**
     * initialize the controller
     *
     * @method   $onInit
     */
    function onInit() {
      _syncNativeSnapshotsCondition();

      // we have come to this state after the "Save and Add more" flow
      if ($scope.shared.addMore) {

        // source was pre selected in previous step
        if (_.get($scope, 'shared.task.restoreParentSource')) {
          $scope.parentSourceChanged($scope.shared.task.restoreParentSource);
        }

        // Reset data source array
        if (_.get($scope, 'shared.task.vmwareParams.datastoreEntityVec')) {
          delete $scope.shared.task.vmwareParams.datastoreEntityVec;
        }

        // reset the state
        $scope.shared.addMore = undefined;
      }

      if ($scope.areNativeSnapshots) {
        $scope.shared.hideVlanSettings = true;
      }

      // If it's a HyperV VM, set instantRecover to true as default.
      if (_.get($scope.shared.taskCart, '[0].registeredSource.hypervEntity')) {
        $scope.shared.instantRecover = true;
      }
    }

    /**
     * returns the correct envTypes for the select-source component to retrieve
     * according to the selected 'Clone' or 'Recover' flow.
     * @method    getSourceEnvTypes
     * @return    {array}    envTypes of sources for the select-source component
     *                       to retrieve
     */
    function getSourceEnvTypes() {
      var task = $scope.shared.taskCart[0];
      var sourceEnvType = task.registeredSource.type;
      var hyperVSources = cUtils.onlyNumbers(ENV_GROUPS.hyperv);

      // If the registeredSource has an environment of kSQL, then the
      // environment type of the entity being recovered should be used instead,
      // as its not possible to recover/clone to the kSQL wrapper since it is
      // just a Cohesity construct.
      if (sourceEnvType === 3) {
        sourceEnvType = _.get(task.vmDocument.objectId, 'entity.type');
      }

      if (hyperVSources.includes(sourceEnvType)) {
        if ($scope.$parent.isClone) {
          return [ENV_TYPE_CONVERSION.kAzure].concat(
            FEATURE_FLAGS.hyperVClone ? hyperVSources : []);
        }
      }

      if (sourceEnvType === ENV_TYPE_CONVERSION.kVMware &&
          $scope.$parent.isClone) {

        // vCenter, Azure, AWS
        return [ENV_TYPE_CONVERSION.kVMware, ENV_TYPE_CONVERSION.kAzure,
          ENV_TYPE_CONVERSION.kAWS];

      }

      return sourceEnvType;
    }

    /**
     * Update sourceEnvTypes in scope
     *
     * @method    updateSourceEnvTypes
     */
    function updateSourceEnvTypes() {
      var registeredSource =
        _.get($scope, 'shared.taskCart[0].registeredSource');
      var cloudEntityKey;

      if (registeredSource) {
        setupPowerOption();
        $scope.sourceEnvTypes = getSourceEnvTypes();

        if ([ENV_TYPE_CONVERSION.kAWS, ENV_TYPE_CONVERSION.kAzure]
          .includes(registeredSource.type)) {

          cloudEntityKey = registeredSource.azureEntity ?
            'azureEntity' : 'awsEntity';

          // Add a source filter to filter cloud sources based on
          // subscriptionType so that 'standard' cannot be recovered to 'gov' and
          // vice versa
          $scope.sourceFilter = makeCloudEntitySourcesFilter(
            _.get(registeredSource,
              cloudEntityKey + '.commonInfo.subscriptionType'), cloudEntityKey);
        } else if (_.get($scope, 'shared.taskCart[0].vmDocument.objectId.entity.vmwareEntity.type') === 9) {
          // If the target is a Vapp, only vcd is allowed to be target source.
          $scope.sourceFilter = vcdEntitySourceFilter();
        } else {
          $scope.sourceFilter = filterUnsupportedSourcesWrapper();
        }
      }
    }

    /**
     * to filter sources based on subscriptionType
     * if the selected source is an cloudEntity
     *
     * @method    makeCloudEntitySourcesFilter
     * @param     {string}  type  cloud entity subscription type to match
     * @param     {string}  entityType  cloud entity type to match
     * @return    {function} a function that filters the sources based on type
     */
    function makeCloudEntitySourcesFilter(type, entityType) {
      return function cloudEntitySourcesFilter(sources) {
        if (!type) {
          return sources;
        }

        return _.filter(sources,
          [entityType + '.commonInfo.subscriptionType', type]);
      };
    }

    /**
     * filter unsupprted sources for clone
     *
     * @method    filterUnsupportedSourcesWrapper
     * @return    {function} a function that filters the sources based on type
     */
    function filterUnsupportedSourcesWrapper() {
      return function filterUnsupportedSources(sources) {
        return _.filter(sources, function filterSources(source) {
          return !ENV_GROUPS.azureStackTypes.includes(_.get(source, 'azureEntity.commonInfo.subscriptionType'));
        });
      }
    }

    /**
     * To filter sources based on its type. Only keep vCD source.
     *
     * @method  vCDEntitySourceFilter
     * @return  {function} a function that filters the sources based on type
     */
    function vcdEntitySourceFilter() {
      return function vcdEntitySourceFilter(sources) {
        return _.filter(sources, function keepVcdTarget(source) {
          return _.get(source, 'vmwareEntity.type') === 17;
        })
      }
    }

    /**
     * Removes VM rename params when hiding the options.
     *
     * @method     toggleRenameOptions
     * @param      {boolean}  showRename  Show or hide the rename options
     */
    function toggleRenameOptions(showRename) {
      $scope.shared.task.renameRestoredObjectParam = showRename ? {
        prefix: defaultPrefix,
      } : undefined;

      // Update the preferences in local storage
      var vmRestorePreferences =
        localStorageService.get(vmRestorePreferencesKey) || {};
      vmRestorePreferences.showRename = showRename;
      localStorageService.set(vmRestorePreferencesKey, vmRestorePreferences);
    }

    /**
     * Removes Vapp rename params when hiding the options.
     *
     * @method     toggleVappRenameOptions
     * @param      {boolean}  showRename  Show or hide the rename options
     */
    function toggleVappRenameOptions(showRename) {
      if (!showRename) {
        $scope.shared.task.renameRestoredObjectParam =
          $scope.shared.task.renameRestoredVappParams = undefined;
      }
    }

    /**
     * Determine if we should show the task options.
     *
     * @method   showTaskOptions
     * @return   {boolean}   True if task options shoudl be shown.
     */
    function showTaskOptions() {
      switch (true) {
        case $scope.$parent.isClone:
          return true;
        case $scope.showOptions:
          return true;
        default:
          return false;
      }
    }

    /**
     * Pre-processes The server response for NFSViews. Returns a list of
     * name strings.
     *
     * @method     processNFSViewsResponse
     * @param      {Array}  data    response.data
     * @return     {Array}          Transformed list
     */
    function processNFSViewsResponse(data) {
      return filterNFSViewsByViewBox(data);
    }

    /**
     * Shared Function that filters a given list of Views against either
     * the restricted ViewBox id, or the first item in the taskCart
     * (previously restricted to same viewBox)
     *
     * @method     filterNFSViewsByViewBox
     * @param      {Array}  list    List of Views
     * @return     {Array}          Filtered subset of the list
     */
    function filterNFSViewsByViewBox(list) {
      var out = [];
      var cart = $scope.shared.taskCart;
      var viewBoxId;

      if (!list || !list.length) {
        return out;
      }

      if ($scope.restrictedViewBox) {
        viewBoxId = $scope.restrictedViewBox.id;
      } else if (cart[0]) {
        viewBoxId = cart[0].vmDocument.viewBoxId;
      }

      // Reduce this response to a list filtered by matching viewBoxIds and
      // compatible OS type.
      out = list.reduce(function mapper(_views, view) {
        if (viewBoxId === view.viewBoxId &&
          isCompatibleProtocol(view.protocolAccess, cart[0]._osType)) {
          _views.push(view.name);
        }

        return _views;
      }, []);

      return out;
    }

    /**
     * Determines if the View Protocol is compatible with the Source OS.
     *
     * @param      {String}   protocol  The View protocol
     * @param      {String}   os        The source VM operating system
     * @return     {Boolean}  True if compatible protocol, False otherwise.
     */
    function isCompatibleProtocol(protocol, os) {
      if (protocol === 'kSMBOnly') {
        return os === 'Windows';
      } else if (['kNFSOnly', 'kS3Only'].includes(protocol)) {
        return os === 'Linux';
      } else {
        return true;
      }
    }

    /**
     * Pre-processes The server response for NetworkEntities
     *
     * @method     processNetworkEntitiesResponse
     * @param      {Array}  data    response.data
     * @return     {Array}          Transformed list
     */
    function processNetworkEntitiesResponse(data) {
      var out = [];
      if (!data) {
        return out;
      }

      // Pass-through for now.
      out = data;
      return out;
    }

    /**
     * Creates a local backup of the recover task config. Optionally includes
     * the name & objects properties. Used to restore the config if user
     * toggles various options on/off.
     *
     * @method  backupTaskConfig
     * @param   {boolean}  [includeBase]  True to include the `name` & `objects`
     *                                    properties. False to exclude them.
     *                                    Default: False
     */
    function backupTaskConfig(includeBase) {
      var taskBackup = angular.copy($scope.shared.task);
      if (!includeBase) {
        taskBackup.name =
          taskBackup.objects = undefined;
      }
    }

    /**
     * Disable and turn off Preserve Tag toggle if the clone location is AWS or
     * Azure.
     *
     * @param {object} restoreParentSource
     */
    function preserveTagTogglePerformance(restoreParentSource) {
      if ($scope.$parent.isClone && !$scope.shared.isVapp) {
        if (_.get(restoreParentSource, 'awsEntity') ||
          _.get(restoreParentSource, 'azureEntity')) {
          $scope.shared.preserveTags = false;
          $scope.disablePreserveTagToggle = true;
        } else {
          $scope.disablePreserveTagToggle = false;
        }
      }
    }

    /**
     * Submit the task to the server.
     *
     * Some task arg reformatting is done here. For all non-VMware type
     * restorations, the task arg is assembled here. See detailed comments
     * within.
     *
     * @method   submitTask
     */
    function submitTask(form) {
      var shared = $scope.shared;
      var task = shared.task;
      var netConfig = task.restoredObjectsNetworkConfig;
      var serviceMethod = $scope.taskType;
      var taskCart = shared.taskCart[0];

      // Params for the Choesity Cluster hosted clone.
      var attemptNum = -1;
      var params = {};
      var snapshots = [];
      var snapshotIndex = -1;

      /**
       * Placeholder for the env-specific params (non-VMware restorations). This
       * is set dynamically below.
       *
       * @type   {object}
       */
      var taskPropsContainer = {};

      if (form.$invalid) { return; }

      // Check for duplicate keys
      if (task.customTags && task.customTags.length) {
        const keys = task.customTags.map(tag => tag.key.toLowerCase());
        const duplicateKeys = keys.filter((item, index) => keys.indexOf(item) !== index);
        if (duplicateKeys.length > 0) {
          task.customTags.forEach(tag => {
            tag.duplicate = duplicateKeys.includes(tag.key.toLowerCase());
          })
          return;
        }
      }

      $ctrl.isSubmitting = true;

      task.restoreVlanParams = {};

      if ($ctrl.kuiperSelected) {
        snapshots = taskCart.vmDocument._versions ||
          taskCart.vmDocument.versions;
        snapshotIndex = taskCart._snapshotIndex;
        attemptNum = _.get(snapshots[snapshotIndex],
          'instanceId.attemptNum');

        params = {
          attemptNum: attemptNum,
          doLaunch: !$ctrl.task.powerOffVM,
          interface: $ctrl.task.interface,
          jobInstanceId: task.objects[0].jobInstanceId,
          memory: $ctrl.task.memory,
          vcpus: $ctrl.task.vcpus,
          vmName: $ctrl.task.name || shared.task.name,
        };

        HypervisorVmService.createHypervisorVm(Object.assign(params,
          $ctrl.recoveryParams)).then(
            function createHypervisorSuccess(response) {
              $state.go('devops-hypervisor');
          }, evalAJAX.errorMessage).finally(function createVmFinally() {
            $ctrl.isSubmitting = false;
        });
        return;
      }

      _setCloudSpecificParamsForSubmit(task);
      serviceMethod = task._serviceMethod || serviceMethod;

      // VMware specific network setting correction. This property may be moved
      // later in this routine for non VMware evironments.
      if ($scope.shared.networkingOptions === 'original') {
        netConfig.networkEntity = undefined;
      }
      netConfig.detachNetwork =
        $scope.shared.networkingOptions === 'detach' || undefined;

      if (shared.isVMware && shared.startNetworkConnected !== undefined) {
        netConfig.disableNetwork = !shared.startNetworkConnected;
      }

      /**
       * Starting with HyperV and Acropolis support (4.2), task params are moved
       * from the task level to task.typeParams level, ie. `task.hypervParams`,
       * to keep things tidy. To accommodate this change for new Env types, we
       * handle their RestoreTaskArg assembly upon submit instead of as the user
       * sets them.
       *
       * TODO: Migrate construction of the VMware-centric task to this same
       * pattern. No ETA for this.
       */
      if (!shared.isVMware) {
        // Assemble env-specific params
        switch (shared.taskCart[0]._entityKey) {
          case 'hypervEntity':
            taskPropsContainer = task.hypervParams;

            angular.extend(taskPropsContainer, {
              // copyRecovery: bool,
              datastoreEntity: shared.datastoreEntity,
              resourceEntity: shared.resourceEntity,
              uuidConfig: shared.uuidConfig,
              copyRecovery: !isClone && !shared.instantRecover,
            });
            break;

          case 'acropolisEntity':
            taskPropsContainer = task.acropolisParams;
            taskPropsContainer.storageContainer = shared.storageContainer;
            break;

          case 'kvmEntity':
            taskPropsContainer = task.kvmParams;
            if (shared.kvmOVirtManager) {
              angular.extend(taskPropsContainer, {
                oVirtManagerEntity: shared.kvmOVirtManager.entity,
                datacenterEntity: shared.kvmDatacenter.entity,
                clusterEntity: shared.kvmCluster.entity,
                storageDomainEntity: shared.kvmStorageDomain.entity,
              });
            }
            break;
        }

        // Common Env property relocations
        angular.extend(taskPropsContainer, {
          powerStateConfig: task.powerStateConfig,
          renameRestoredObjectParam: task.renameRestoredObjectParam,
          restoredObjectsNetworkConfig: task.restoredObjectsNetworkConfig,
        });
      }

      task.restoreVlanParams = RestoreService.getVlanParams(
        shared.selectedVlanTarget
      );

      if ((shared.isHyperV || shared.isVMware) && $scope.$parent.isClone &&
        !$scope.cloudSourceSelected) {
          var entityParam = shared.isVMware ? 'vmwareParams' : 'hypervParams'
          _.set(task, entityParam + '.preserveCustomAttributesDuringClone',
            $scope.shared.preserveCustomAttributes);

          _.set(task, 'preserveTags', $scope.shared.preserveTags);
      }

      // if target is cloud, vlan settings must be disabled
      if ($scope.hideVlanSettings) {
        task.restoreVlanParams = undefined;
      }

      if ($scope.shared.retrievalOption) {
        _.set(task, 'vaultRestoreParams.glacier.retrievalType',
          $scope.shared.retrievalOption);
      }

      RestoreService[serviceMethod](task)
        .then(redirectToTaskDetails, evalAJAX.errorMessage)
        .finally(function restoreFinally() {
          $ctrl.isSubmitting = false;
      });;
    }

    /**
     * Add cloud specific metadata to task object
     *
     * @method   _setCloudSpecificParamsForSubmit
     * @param   {Object}   task   The restore task object
     */
    function  _setCloudSpecificParamsForSubmit(task) {
      var areNativeSnapshots = $scope.areNativeSnapshots;
      var serviceMethod;

      if ($scope.taskType === 'clone' ||
        ($scope.taskType === 'recover' && areNativeSnapshots)) {

        // Cloning to Azure Source
        if ($scope.azureSourceSelected) {

          task.deployVmsToCloudParams = {
            deployVmsToAzureParams: {
              resourceGroup: $ctrl.resourceGroup.entity,
              tempVmResourceGroup: _.get($ctrl, 'resourceGroupTemp.entity'),

              // compute option may/may not be present depending on
              // whether the task is clone/restore
              computeOptions: _.get($ctrl, 'computeOption.entity'),
              availabilitySet: _.get($ctrl, 'availabilitySet.entity'),
              storageAccount: $ctrl.storageAccount.entity,
              tempVmStorageAccount: _.get($ctrl, 'storageAccountTemp.entity'),
              storageKey:
                $ctrl.storageKey ? $ctrl.storageKey.entity : undefined,
              storageContainer: $ctrl.storageContainer.entity,
              tempVmStorageContainer:
                _.get($ctrl, 'storageContainerTemp.entity'),
              storageResourceGroup: $ctrl.storageAccount._resourceGroup.entity,
              virtualNetwork: $ctrl.virtualNetwork.entity,
              tempVmVirtualNetwork: _.get($ctrl, 'virtualNetworkTemp.entity'),
              networkResourceGroup: $ctrl.virtualNetwork._resourceGroup.entity,
              subnet: $ctrl.subnet.entity,
              tempVmSubnet: _.get($ctrl, 'subnetTemp.entity'),
            }
          };

          // User has checked "Use Same Azure Resources"
          if ($ctrl.useSameAzureResources) {
            _.assign(task.deployVmsToCloudParams.deployVmsToAzureParams, {
              tempVmResourceGroup: $ctrl.resourceGroup.entity,
              tempVmStorageAccount: $ctrl.storageAccount.entity,
              tempVmStorageContainer: $ctrl.storageContainer.entity,
              tempVmVirtualNetwork: $ctrl.virtualNetwork.entity,
              tempVmSubnet: $ctrl.subnet.entity,
            });
          }

          if ($ctrl.azureManagedDiskParams) {
            _.assign(task.deployVmsToCloudParams.deployVmsToAzureParams,
              $ctrl.azureManagedDiskParams);
          }

          if ($scope.taskType === 'clone') {
            task.action = ENUM_RESTORE_TYPE.kConvertAndDeployVMs;
          } else {
            serviceMethod = 'restoreVM';
          }
        }

        if ($scope.gcpSourceSelected) {

          serviceMethod = 'restoreVM';

          task.deployVmsToCloudParams = {
            deployVmsToGcpParams: {
              projectId: task.selectedGCPProject,
              region: task.selectedGCPRegion.entity,
              zone: task.selectedGCPZone.entity,
              subnet: task.selectedGCPSubnet.entity,
            },
          };
        }

        if ($scope.awsSourceSelected) {
          // Cloning to AWS Source
          if ($scope.taskType === 'clone') {
            task.action = ENUM_RESTORE_TYPE.kConvertAndDeployVMs;

          // Restoring to alternate AWS location
          } else {
            serviceMethod = 'restoreVM';
          }

          task.deployVmsToCloudParams = {
            deployVmsToAwsParams: {
              networkSecurityGroups: task.awsSecurityGroups,
              region: task.selectedAWSRegion,
              subnet: task.selectedAWSubnet.entity,
              vpc: task.selectedAWSVpc.entity,
              proxyVmVpc: task.selectedAWSVpcTemp ?
                task.selectedAWSVpcTemp.entity : undefined,
              proxyVmSubnet: task.selectedAWSSubnetTemp ?
                task.selectedAWSSubnetTemp.entity : undefined,
            },
          };

          // User has checked "Use Same Aws Resources"
          if ($ctrl.useSameAwsResources) {
            _.assign(task.deployVmsToCloudParams.deployVmsToAwsParams, {
              proxyVmVpc: task.selectedAWSVpc.entity,
              proxyVmSubnet: task.selectedAWSubnet.entity,
            });
          }

          if ($ctrl.specifyFleetSettings) {
            task.deployVmsToCloudParams.deployFleetParams = {
              awsFleetParams: {
                fleetSubnetType: $ctrl.fleetInstanceLocation,
                networkParams: $ctrl.fleetInstanceLocation === 'kCustom' ?
                  {
                    vpc: $scope.shared.task
                      .selectedAWSFleetVpc.entity.displayName,
                    subnet: $scope.shared.task
                      .selectedAWSFleetSubnet.entity.displayName,
                  } : undefined,
                fleetTagVec: $scope.shared.task.tags,
              },
            };
          }

          if (task.selectedAWSInstance) {
            task.deployVmsToCloudParams.deployVmsToAwsParams.instanceType =
              task.selectedAWSInstance.entity;
          }

          if (task.customTags && task.customTags.length) {
            task.deployVmsToCloudParams.deployVmsToAwsParams.customTagVec =
              angular.copy(task.customTags.concat());
          }

          // keyPairName is only needed for native restore
          if (task.selectedAWSKeyPair) {
            task.deployVmsToCloudParams.deployVmsToAwsParams.keyPairName =
              task.selectedAWSKeyPair.entity;
          }

        // Restoring to original AWS location
        } else if (areNativeSnapshots) {
          serviceMethod = 'restoreVM';
        }
      } else {
        serviceMethod = 'restoreVM';
      }

      task._serviceMethod = serviceMethod;
    }

    /**
     * Check if submission of form is allowed or not
     *
     * @method   isSubmitDisabled
     * @param    {object}   form   The form to be submitted.
     * @return   {boolean}  True if disabled. False otherwise.
     */
    function isSubmitDisabled(form) {
      var sharedTaskCartLength = _.get($scope , 'shared.taskCart.length');

      return ((form.$submitted && form.$invalid) ||
        !sharedTaskCartLength || $scope.shared.isFailoverDisallowed ||

        // hyperV is not being cloned to azure and feature flag is OFF
        (isClone && !FEATURE_FLAGS.hyperVClone && $scope.shared.isHyperV &&
          !_.get($scope.shared, 'task.restoreParentSource.azureEntity'))) ||

        // user has selected more than permitted data stores
        ($scope.enableMultipleDatastore() ?
          _.get(
            $scope, 'shared.task.vmwareParams.datastoreEntityVec.length'
          ) > maxSelectableDatastores() : false );
    }

    /**
     * Success callback handler for submitTask().
     *
     * @method   redirectToTaskDetails
     * @param    {object}   task   The Task to test for completion.
     */
    function redirectToTaskDetails(task) {
      var stateParams = {
        id: task?.performRestoreTaskState.base.taskId,
      };

      // If there was no error, but also no task, then it's likely that this request is in quorum. If so,
      // just go back to the previous state instead of trying to go to the task detail.
      if (!task) {
        NgStateManagementService.goToPreviousState('clone-vms');
      }

      if ($scope.isRecover) {
        $state.go(
          task.performRestoreTaskState.objects[0].archivalTarget ?
            'recover-detail-archive' : 'recover-detail-local',
          stateParams
        );
        return;
      }

      // else clone task
      $state.go('clone-detail', stateParams);
    }

    /**
     * Resets the recover task to initial state
     *
     * @method     resetRecoverOptions
     */
    function resetRecoverOptions() {
      backupTaskConfig();
      hideRecoverOptions();
      if (angular.isFunction($scope.$parent.resetTask)) {
        $scope.$parent.resetTask();
      }

      resetRecoveryLocation();

      resetRestoreSource();
    }

    /**
     * Resets the recovery location to initial state
     *
     * @method     resetRecoveryLocation
     */
    function resetRecoveryLocation() {
      $scope.shared.resourceEntity =
        $scope.shared.datastoreEntity =
        $scope.shared.storageContainer =
        $scope.shared.kvmOVirtManager =
        $scope.shared.kvmDatacenter =
        $scope.shared.kvmCluster =
        $scope.shared.kvmStorageDomain =
        $scope.shared.task.datastoreEntity =
        $scope.shared.task.resourcePoolEntity =
        $scope.shared.task.restoreParentSource =
        $scope.shared.hideContinueRestoreOnError =
        $scope.awsSourceSelected =
        $scope.azureSourceSelected =
        $scope.gcpSourceSelected =
        $scope.selectedCloudSourceTypeLabel =
        $scope.tagNameError =
        $scope.cloudSourceSelected = undefined;

      if ($scope.shared.task.vmwareParams) {
        $scope.shared.task.vmwareParams.storageProfileName = undefined;
        $scope.shared.task.vmwareParams.StorageProfileVcdUuid = undefined;
      }

      // Since 'detach' is available in both cases (recovering to
      // original/alternate), leave it untouched if it is already selected
      if ($scope.shared.networkingOptions !== 'detach') {
        $scope.shared.networkingOptions =
          $scope.isRecover ? 'original' : 'detach';
      }

      $scope.state.showParentSourceOptions = false;

      if ($scope.areNativeSnapshots) {
        $scope.shared.hideVlanSettings = true;
      }

      if ($scope.shared.setDefaultFleetRegion) {
        $scope.shared.setDefaultFleetRegion();
      }

      // If the selected source is a vCloud, set powerOn to false and disable the
      // checkbox in recoevry workflow.
      if ($scope.isVCD) {
        $scope.shared.task.powerStateConfig.powerOn = false;
      }
      $scope.disablePowerStateChackbox = $scope.isVCD;
    }

    /**
     * Resets restoreSource options
     *
     * @method     resetRestoreSource
     */
    function resetRestoreSource() {
      $scope.shared.networkingOptions = isRecover ? 'original' : 'detach';
      $scope.showRenameOptions = undefined;
      $scope.shared.task.restoredObjectsNetworkConfig = {};
    }

    /**
     * Displays the recover task options config fields
     *
     * @method     showRecoverOptions
     */
    function showRecoverOptions() {
      $scope.showOptions = true;
    }

    /**
     * Hides the recover task options config fields
     *
     * @method     hideRecoverOptions
     */
    function hideRecoverOptions() {
      $scope.showOptions = false;
    }

    /**
     * Builds task objects for restore based on the contents of the taskCart
     *
     * @method     populateRestoreObjects
     */
    function populateRestoreObjects() {
      var out;
      var vmDoc;
      var snapshotIndex = 0;
      var version;
      var replica;

      if ($scope.shared.taskCart.length) {
        // Do some entityType specific setup
        switch ($scope.shared.taskCart[0]._entityKey) {
          case 'hypervEntity':
            $scope.shared.task.hypervParams =
              $scope.shared.task.hypervParams || {};
            break;

          case 'acropolisEntity':
            $scope.shared.task.acropolisParams =
              $scope.shared.task.acropolisParams || {};
            break;

          case 'kvmEntity':
            $scope.shared.task.kvmParams =
              $scope.shared.task.kvmParams || {};
            break;

          default:

            // It's none of these. Make sure those objects are cleared if
            // continuing.
            $scope.shared.task.acropolisParams =
              $scope.shared.task.hypervParams =
              $scope.shared.task.kvmParams = undefined;
        }
      }

      $scope.shared.task.objects = $scope.shared.taskCart.map(function cartToCloneTaskObjectMapper(object) {
        vmDoc = object.vmDocument;
        snapshotIndex = object._snapshotIndex || 0;

        // This gets the jobId, jobInstanceId, jobUid, etc if they're in the
        // response object.
        out = vmDoc ? angular.copy(vmDoc.objectId || {}) : {};

        switch (object._type) {
          // 'vm' and 'vapp' are both vmware entities and have the same
          // properties. We define them when we get yoda results from search.
          case 'vm':
          case 'vapp':
            // Fetch the policy if not already decorated as _policy.
            // Setting _pointInTimeRestoreTimeUsecs to -1 by default means
            // select any latest record which can be snapshot or PIT.
            if (object._type === 'vm' && FEATURE_FLAGS.cdpEnabled && !object._policy) {
              JobService.getJob(object._jobId).then(function getJobSuccess(job) {
                PolicyService.getPolicy(job.policyId).then(
                  function getPolicySuccess(policy) {
                    if (_.get(policy, 'cdpSchedulingPolicy.periodicity') ===
                      SCHEDULING_POLICY_PERIODICITY['cdp']) {
                      _.assign(object, {
                        _policy: policy,
                        _pointInTimeRestoreTimeUsecs: -1,
                      });
                    }
                  });
              });
            }
            angular.extend(out, {
              jobId: vmDoc.objectId.jobId,
              jobInstanceId: parseInt(vmDoc.versions[snapshotIndex].instanceId.jobInstanceId, 10),
              startTimeUsecs: vmDoc.versions[snapshotIndex].instanceId.jobStartTimeUsecs,
              pointInTimeRestoreTimeUsecs: object._pointInTimeRestoreTimeUsecs,
            });
            if (!object._archivalTarget) {
              // If archival target is not specified and local replica does not
              // exist, default to an archival target.
              replica = vmDoc.versions[snapshotIndex].replicaInfo.replicaVec[0];

              // target.type should not be local.
              if (replica && replica.target && replica.target.type !== 1) {
                object._archivalTarget = replica.target.archivalTarget;
              }
            }

            break;

          case 'job':
            out.jobInstanceId = parseInt(snapshotIndex, 10);

            // NOTE: In abbreviated flow (like 'failover and continue to clone')
            // the versions are present in object itself. So the backupJobRuns
            // variable is not populated for them. In such cases, fallback to
            // the object versions
            if (_.get($scope,
              'backupJobRuns.' + object._uniqueId + '.runs.' + snapshotIndex)) {

              version =
                $scope.backupJobRuns[object._uniqueId].runs[snapshotIndex];
            } else {
              // NOTE: versions[] are effectively job runs that are retrieved
              // when the job is added to cart, whereas _versions is sourced
              // from the search result object when this "job" was created in
              // search-service.js. Failing back to the _versions[] associated
              // with the object ensures there is a snapshot to recover to if
              // the most recent job run has no recoverable objects.
              version = object._snapshot || vmDoc.versions[0] || vmDoc._versions[0];
              out.jobInstanceId = version.instanceId.jobInstanceId;
            }

            out.startTimeUsecs = version.instanceId.jobStartTimeUsecs;
            out.jobId = object._id;

            // Set the jobUid from version which we set previously.
            // This allows us to pass correct jobUid when multiple jobUids are
            // mapped to the same local Job Id.
            out.jobUid = version.jobUid;
            if (!object._archivalTarget) {
              // If archival target is not specified and local replica does not
              // exist, default to an archival target.
              replica = version.replicaInfo.replicaVec[0];

              // target.type should not be local.
              if (replica && replica.target && replica.target.type !== 1) {
                object._archivalTarget = replica.target.archivalTarget;
              }
            }

            if (out.entity) {
              out.entity = undefined;
            }

            break;
        }

        out._jobType = object._jobType;

        if (object._archivalTarget) {
          out.archivalTarget = object._archivalTarget;
        }

        return out;
      });
    }

    /**
     * Determines if new network selection allowed.
     *
     * @method   isNetworkSelectAllowed
     * @return   {boolean}   True if network select allowed, False otherwise.
     */
    function isNetworkSelectAllowed() {
      var task = $scope.shared.task;
      var objects = task.objects || [];

      // Some quick obvious requirements checks
      if (!task.restoreParentSource ||
        !objects[0] ||
        !$scope.networkEntities.length) {
        return false;
      }

      switch (true) {
        // VMware
        case !!objects[0].vmwareEntity:
          return !!task.resourcePoolEntity;

        // HyperV
        case !!objects[0].hypervEntity:
          return !!$scope.shared.resourceEntity;

        // Acropolis: handled by the simple quick-checks above
      }
    }

    /**
     * Determines if power option is disabled and sets the power on condition
     * on basis on registered parent source
     *
     * @method   setupPowerOption
     */
    function setupPowerOption(){
      var entityType = _.get($scope.shared.taskCart,
        '[0].registeredSource.vmwareEntity.type');

      // Check if it's vcloud Director
      $scope.isVCD = entityType === 17;

      // Power on is true of all except vcloud director
      $scope.shared.task.powerStateConfig.powerOn = entityType !== 17;

      if (isRecover) {
        $scope.disablePowerStateChackbox = entityType === 17;
      }
    }

    /**
     * Change handler when parentSourceEntity is changed
     *
     * @method     parentSourceChanged
     */
    function parentSourceChanged(item) {
      var sourcesList = $scope.shared.sourcesList || [];
      var foundSource;
      var sourcesPromise;

      if (!item) { return; }

      // 'register new source' has been used. So the newly added source is not
      // present in sourcesList. Therefor Update the sourcesList.
      if (!_.find(sourcesList, ['entity.id', item.id])) {
        $scope.sourcesLoading = true;
        sourcesPromise = SourceService.getSources({}, true);
      } else {
        sourcesPromise = $q.resolve();
      }

      sourcesPromise.then(function sourcesFetched(sources) {
        $scope.sourcesLoading = false;

        if (sources && sources.entityHierarchy) {
          $scope.shared.sourcesList = sources.entityHierarchy.children;
          foundSource =
            _.find($scope.shared.sourcesList, ['entity.id', item.id]);
          item._entityKey = foundSource._entityKey;
          item._typeEntity = foundSource._typeEntity;
        }
        configureRestoreTaskParams(item);
        preserveTagTogglePerformance(item);
      });

      // If the selected source is a vCloud, set powerOn to false and disable
      // the checkbox in recoevry workflow.
      if (isRecover) {
        var entityKey = item._entityKey || 'vmwareEntity';
        if (item[entityKey] && item[entityKey].type === 17) {
          $scope.shared.task.powerStateConfig.powerOn = false;
        }
        $scope.disablePowerStateChackbox = $scope.targetSourceIsVcd =
          item[entityKey] && item[entityKey].type === 17;
      }

      // If it's a clone context and selected target source is azure, validate VM object size
      if(isClone && item._entityKey === 'azureEntity' && item.type === 8) {
        const cart = Array.from($scope.shared.taskCart ?? []);
        $scope.invalidAzureCloneObjects = [];
        while(cart.length) {
          const cartItem = cart.shift();
          if(cartItem._type === 'job') {
            // Get objects in job and check accordingly
            const jobObjects = $scope.shared.searchDisplay.filter(obj => obj._type === 'vm' && obj._jobId === cartItem._id);
            cart.push(...jobObjects);
          } else {
            const size = _.get(cartItem, 'vmDocument.objectId.entity.' + cartItem._entityKey + '.frontEndSizeInfo.sizeBytes');
            const validDiskSize = cartItem._entityKey === 'vmwareEntity' && ((size % 1024) === 0) || size <= cUtils.sizeToBytes(8191, 'GiB');
            if (!validDiskSize) {
              $scope.invalidAzureCloneObjects.push(cartItem.vmDocument.objectName);
            }
          }
        }
        $scope.showAzureCloneWarning = !!$scope.invalidAzureCloneObjects.length;
      } else {
        $scope.showAzureCloneWarning = false;
      }

    }
    /**
     * Sets the corresponding source type properties based on selected value
     * including description/hint and validationError text and maximum allowed for the custom tags
     *
     * @param {String|Number} type represents thw source type (e.g AWS, Azure, GCP)
     * */
    function _setCloudSourceTypeProperties(type) {
      // Sets defaults for environments not requiring custom constraints or labels
      const setPageDefaults = function() {
        $scope.shared.task.MAXIMUM_NUM_TAGS = maximumNumTags;
        $scope.tagNameError = 'errors.invalid';
      };

      // Based on cloudSources constant @See ENV_GROUPS.cloudSources
      switch (type) {
        case 'kAzure':
        case 8:
          $scope.shared.task.MAXIMUM_NUM_TAGS = 50;
          $scope.selectedCloudSourceTypeLabel = 'Azure VM';
          $scope.tagNameError = 'cloneRecover.errorText.azureTagNameError';
          break;
        case 'kAWS':
        case 16:
          $scope.selectedCloudSourceTypeLabel = 'AWS EC2';
          setPageDefaults();
          break;
        case 'GCP':
        case 20:
          $scope.selectedCloudSourceTypeLabel = 'GCP';
          setPageDefaults();
          break;
        default:
          $scope.selectedCloudSourceTypeLabel = '';
          setPageDefaults();
          break;
      }
    }

    /**
     * Configure the restoreTask params based on the source selected
     * @param   {Object}  item  The selected source object
     */
    function configureRestoreTaskParams(item) {
      var sourcesList = $scope.shared.sourcesList || [];
      var vCloudEntity;
      var task = $scope.shared.task;

      $scope.vmwareSelected = !!item.vmwareEntity;
      $scope.awsSourceSelected = !!item.awsEntity;
      $scope.azureSourceSelected = !!item.azureEntity;
      $scope.gcpSourceSelected = !!item.gcpEntity;
      $scope.cloudSourceSelected =
        ENV_GROUPS.cloudSources.includes(item.type);

      $scope.vCloudSelected =
        !!(item.vmwareEntity && item.vmwareEntity.type === 17);

      $scope.networkEntities.length = 0;

      $scope.shared.hideVlanSettings = undefined;
      $scope.shared.hideContinueRestoreOnError = undefined;

      _setCloudSourceTypeProperties(item.type);

      // if vmware source selected
      if (item.vmwareEntity) {
        // If our parent source is properly defined as a VM entity
        $scope.datastores.length = 0;

        if (!$ctrl.resubmitWorkflow) {
          task.resourcePoolEntity =
            task.datastoreEntity = undefined;
        }

        // Reset to detach network.
        $scope.shared.networkingOptions = 'detach';
        $scope.shared.task.restoredObjectsNetworkConfig.detachNetwork = true;
        $scope.isStandaloneEsxi = (10 === item._typeEntity.type);

        // If Vcloud Director is selected
        if ($scope.vCloudSelected) {
          vCloudEntity = sourcesList.find(function filterSources(src) {
            if (_.get(src, 'entity.vmwareEntity.type') === 17) {
              return SourceService.isSameEntity(item._typeEntity, src._typeEntity);
            }
          });

          // Get list of vdcs from selected vcloud director
          $scope.vCloudVdcs = _getVcloudVdcs(vCloudEntity);

          // Get list of vcenter ids from selected vcloud director
          $ctrl.vCenterIds = _getVCenterIds(vCloudEntity);
        } else {
          getResourcePools(item)
            .then(function receivedResourcePools() {
              if ($scope.isStandaloneEsxi) {

                // Do some scripted things if the user has selected an standalone
                // ESXi host
                setESXiTargetTaskSettings($scope.resourcePools[0] && $scope.resourcePools[0].resourcePool);
              }
            });
        }
      }

      // HyperV action
      if (item.hypervEntity) {
        task.hypervParams = task.hypervParams || {};
        // Clear previous settings
        task.hypervParams.computeOptions =
          $scope.shared.datastoreEntity =
          task.hypervParams.volume = undefined;

        // Clear the following option lists
        $scope.hypervVolumes.length =
          $scope.computeOptions.length = 0;

        $scope.isStandaloneHyperV = 1 === item._typeEntity.type;
        if ($scope.isStandaloneHyperV) {
          // Hyper-V standalone would not have computer selected.
          // Here: Just need rootId to get all volumns back.
          hypervComputeChanged({
            parentId: item.id,
            type: 2,
          });
          $scope.shared.resourceEntity = item;
        } else {
          // Get compute options anew
          getComputeOptions(item);
        }
      }

      // Acropolis action
      if (item.acropolisEntity) {
        task.acropolisParams.storageContainer = undefined;

        $scope.acropolisStorageContainers.length = 0;
        $scope.networkEntities.length = 0;
        getAcropolisStorageContainers(item);
        getAcropolisNetworkEntities(item);
      }

      // KVM action
      if (item.kvmEntity) {
        task.kvmParams.oVirtManagerEntity =
          task.kvmParams.datacenterEntity =
          task.kvmParams.clusterEntity =
          task.kvmParams.storageDomainEntity = undefined;

        $scope.kvmDatacenters = [];
        $scope.kvmClusters = [];
        $scope.kvmStorageDomains = [];
        $scope.networkEntities.length = 0;
        $scope.vnicEntities.length = 0;
        getKVMDataSources({entity: item});
        getKVMNetworkEntities(item);
        getKVMVNicEntities(item);
      }

      if (task.restoredObjectsNetworkConfig &&
        task.restoredObjectsNetworkConfig.networkEntity) {
        task.restoredObjectsNetworkConfig.networkEntity = undefined;
      }

      _setCloudSpecificParams(item);
    }

    /**
     * sets the cloud specific parameters for restore/clone
     *
     * @method  _setCloudSpecificParams
     * @param   {Object}  source  the selected source object
     */
    function _setCloudSpecificParams(source) {
      var hideContinueRestoreOnError;
      var actionFn;
      var sourceEntityStr;

      if (!$scope.cloudSourceSelected) {
        return;
      }

      $scope.shared.allowPreserveTag = false;
      $scope.shared.allowPreserveCustomAttributes = false;

      _syncNativeSnapshotsCondition();

      // populate the scope with the correct entity hierarchy
      // based on the source selected
      $scope[source._entityKey] = ($scope.shared.sourcesList || [])
        .find(function filterSources(src) {
          return src.entity.id === source.id;
        });

      switch (source.type) {
        case ENV_TYPE_CONVERSION.kAWS:
          sourceEntityStr = 'awsEntity';
          actionFn = getAWSRegionsOptions;
          hideContinueRestoreOnError =
            $scope.areNativeSnapshots ? undefined : true;
          break;

        case ENV_TYPE_CONVERSION.kAzure:
          sourceEntityStr = 'azureEntity';
          actionFn = getResourceGroupOptions;
          hideContinueRestoreOnError = true;
          break;

        case ENV_TYPE_CONVERSION.kGCP:
          sourceEntityStr = 'gcpEntity';
          actionFn = getGCPProjectOptions;
          hideContinueRestoreOnError = undefined;
          break;
      }

      if (!$scope.shared.addMore) {
        // This may not be available in "re-submit" flow. Check and add it.
        if (!$scope[sourceEntityStr]) {
          $scope[sourceEntityStr] = ($scope.sourcesList || [])
            .find((src) => src.entity.id === source.id);
        }

        actionFn($scope[sourceEntityStr]);

        $scope.shared.networkingOptions = 'detach';
        $scope.shared.hideVlanSettings = true;
        $scope.shared.task.continueRestoreOnError = false;
        $scope.shared.hideContinueRestoreOnError = hideContinueRestoreOnError;
      }
    }

    /**
     * Sync local scope with shared scope for nativeSnapshots.
     *
     * @method  _syncNativeSnapshotsCondition
     */
    function _syncNativeSnapshotsCondition() {
      $scope.areNativeSnapshots = $scope.shared.areNativeSnapshots;
    }

    /**
     * Gets the vcenter ids from a Vcloud Director
     *
     * @method   _getVCenterIds
     * @param    {Object}   vCloudEntity   The vCloud Director entity
     * @return   {array}   The list of vcenter ids.
     */
    function _getVCenterIds(vCloudEntity) {
      var vCenters = [];

      if (_.get(vCloudEntity, 'children.length')) {
        // 2: vcenter entity
        vCenters = _collectChildren(vCloudEntity, 2);
      }

      return _.map(vCenters, 'entity.id');
    }

    /**
     * Called when a vDC is selected
     *
     * @method   selectVcloudVdc
     * @param    {object}   vdc   The vdc entity
     */
    function selectVcloudVdc(vdc) {
      // Save entity to vcdConfigParams
      $scope.shared.task.vcdConfigParams = {
        vdcEntity: vdc.entity,
      };
      $scope.vCloudvApps = _getVcloudVapps(vdc);

      var resgrpEntityId =
        _.get(vdc, 'entity.vmwareEntity.vcdAttributes.resgrpEntityId');

      var datastoreParams = {
        resourcePoolId: resgrpEntityId,

        // TODO(maulik): Currently Iris backend cannot handle list of vcenters
        // in the get datastore or get vm folders API call. When the support
        // is added, we should uncomment this
        //vCenterId: $ctrl.vCenterIds,
      };

      var vmFolderParams = {
        resourcePoolId: resgrpEntityId,
        //vCenterId: $ctrl.vCenterIds,
      };

      // Contruct the Resource Pool Entity
      $scope.shared.task.resourcePoolEntity = {
        parentId: vdc.entity.parentId,
        id: resgrpEntityId,
      }

      if ($scope.targetSourceIsVcd && FEATURE_FLAGS.enableStorageProfile) {
        getStorageProfile(vdc.entity.id);
      } else {
        getDatastores(undefined, datastoreParams);
      }
      getVmFolders(undefined, vmFolderParams);

      // Get network entities of the source
      getNetworkConfigs();
    }

    /**
     * wrapper method used to collect all vApps in vCloudDirectory Hierarchy
     *
     * @param     {Object}    vdc    vdc entity hierarchy
     * @return    {Array}    Array of found entity objects
     */
    function _getVcloudVapps(vdc) {
      if (_.get(vdc, 'children.length')) {
        // 9: vapp entity
        return _collectChildren(vdc, 9);
      }
    }

    /**
     * Gets the list of vdc entities from the
     *
     * @method   _getVcloudVdcs
     * @param    {object}   vcloudDirector   Vcloud entity hierarchy
     * @return   {Array}    The list of vdc entities.
     */
    function _getVcloudVdcs(vcloudDirector) {
      if (_.get(vcloudDirector, 'children.length')) {
        // 19: vdc entity
        return _collectChildren(vcloudDirector, 19);
      }
    }

    /**
     * generic method used to find all entity objects of a certain
     * type(searchType) in an entityHierarchy
     *
     * @method   _collectChildren
     * @param    {Object}   entityHierarchy   object containing entity details
     *                                        and children
     * @param    {Number}   searchType        the type of entity to look for
     * @return   {Array}    List of entities which matches the searchType
     */
    function _collectChildren(entityHierarchy, searchType) {
      var entityKey = SourceService.getEntityKey(entityHierarchy.entity.type);
      var collection = [];

      entityHierarchy.children.forEach(function forChildren(node) {
        if (node.entity[entityKey].type === searchType) {
          collection.push(node);
        }

        if (node.children && node.children.length) {
          collection = collection.concat(_collectChildren(node, searchType));
        }
      });

      return collection;
    }

    /**
     * get all related 'Storage Policy(s)' for selected vApp
     *
     * @param    {Object}    vApp    selected vApp entity object
     */
    $scope.selectVcloudVapp = function selectVcloudVapp(vApp) {
      // Save entity to vcdConfigParams
      $scope.shared.task.vcdConfigParams.vappEntity = vApp.entity;
    };

    /**
     * Get storage profile info.
     *
     * @param    {Object}    selectStorageProfile    selected storage profile.
     */
    $scope.selectStorageProfile = function selectStorageProfile(selectStorageProfile) {
      if (!$scope.shared.task.vmwareParams) {
        $scope.shared.task.vmwareParams = {
          storageProfileName: '',
          storageProfileVcdUuid: '',
        }
      }
      $scope.shared.task.vmwareParams.storageProfileName = selectStorageProfile.name;
      $scope.shared.task.vmwareParams.storageProfileVcdUuid = selectStorageProfile.uuid;
    };

    /**
     * gets all region options by mapping the aws entity selected.
     * Regions are defined as type 1 in the entity hierarchy tree
     *
     * @param    {Object}    awsEntity   selected aws entity object
     */
    function getAWSRegionsOptions(awsEntity) {
      $scope.regions = [];
      $scope.shared.task.selectedAWSRegion = undefined;

      $scope.regions = awsEntity.children.map(function mapSource(child) {
        if (child.entity.awsEntity.type ===
            PUB_TO_PRIVATE_ENV_STRUCTURES.kAWS.entityTypes.kRegion) {

          return child.entity;
        }
      });
    }

    /**
     * Sets the max allowed length for prefix/suffix
     *
     * @method  setPrefixSuffixMaxLength
     */
    function setPrefixSuffixMaxLength() {
      $scope.prefixMaxLength = _getMaxLength('prefix');
      $scope.suffixMaxLength = _getMaxLength('suffix');
    }

    /**
     * Returns the max allowed length for prefix/suffix
     *
     * @method  _getMaxLength
     *
     * @param   {String}  type  The type of input (prefix/suffix)
     * @return  {Number}  Max allowed length
     */
    function _getMaxLength(type) {
      // Map Containing the max lengths of VM names on different platforms
      var lengthMap = {
        gcp: 63,
        azure: 63,
      };

      switch (true) {
        case _isGCPRecovery():
          return _getVmNameLength(type, lengthMap.gcp);

        case _isAzureRecovery():
          return _getVmNameLength(type, lengthMap.azure);

        default:
          return undefined;
      }
    };

    /**
     * Returns the maximum allowed length for a prefix/suffix for Platform VMs
     *
     * @method  _getVmNameLength
     * @param   {String}  type       The type of input (prefix/suffix)
     * @param   {Number}  maxLength  The maximum length for VM name
     * @return  {Number}  The max allowed length
     */
    function _getVmNameLength(type, maxLength) {
      var prefixLength = _.get($scope.shared.task.renameRestoredObjectParam,
        'prefix.length') || 0;
      var suffixLength = _.get($scope.shared.task.renameRestoredObjectParam,
        'suffix.length') || 0;
      var usedLength = (type === 'prefix' ? suffixLength : prefixLength);
      var jobVms = [];
      var namesLengthArr =
        $scope.shared.taskCart.map(function getNames(obj) {
          // Get Job's VMs
          if (obj._type === 'job') {
            jobVms = jobVms.concat(
              $scope.backupJobRuns[obj._uniqueId].runs[obj._snapshotIndex].vms);

            return 0;
          }
          return obj._name.length;
        });

      if (jobVms.length) {
        namesLengthArr =
          namesLengthArr.concat(jobVms.map(function getNames(obj) {
            return obj.objectName.length;
          }));
        }

      return Math.max(maxLength - (_.max(namesLengthArr) + usedLength), 0);
    }

    /**
     * Returns whether the recovery is for GCP VMs/source
     *
     * @method  _isGCPRecovery
     * @return  {Boolean}  True for GCP Recovery. False otherwise
     */
    function _isGCPRecovery() {
      return !!_.find($scope.shared.taskCart, function(obj) {
        return [ENV_TYPE_CONVERSION.kGCP ,ENV_TYPE_CONVERSION.kGCPNative]
          .includes(obj._jobType);
        });
    }

    /**
     * Returns whether the recovery is for Azure VMs/source
     *
     * @method  _isAzureRecovery
     * @return  {Boolean}  True for Azure Recovery. False otherwise
     */
    function _isAzureRecovery() {
      return !!_.find($scope.shared.taskCart, function(obj) {
        return [ENV_TYPE_CONVERSION.kAzure ,ENV_TYPE_CONVERSION.kAzureNative]
          .includes(obj._jobType);
        });
    }

    /**
     * Function for ng-pattern for validating tag name/key
     *
     * @param  {String}  value   the tag name
     * @returns {Boolean} True if string is valid. False otherwise.
     */
    function _tagNameValidator(value) {
      switch (true) {
        case $scope.azureSourceSelected:
          return FORMATS.azureTagName.test(value);
        default:
          return true
      }
    }


    /**
     * Custom function for ng-pattern for validating prefix/suffix of VM name.
     *
     * @method regexTest
     *
     * @param  {String}  type   prefix/suffix
     * @param  {String}  value  The value of input string which has to
     *                          be validated
     * @return {Boolean} True if string is valid. False otherwise.
     */
    function _regexTest(type, value) {
      var isAlreadyGcpSource = _isGCPRecovery();
      var isAlreadyAzureSource = _isAzureRecovery();
      var isInvalid = false;
      var regex;
      var message;

      switch (true) {
        // GCP VMs or GCP target is selected
        case (isAlreadyGcpSource || $scope.gcpSourceSelected):
          regex = type === 'prefix' ? FORMATS.gcpPrefix :
            FORMATS.gcpSuffix;

          isInvalid = !regex.test(value);
          message = 'cloneRecover.errorText.gcpSpecificError';

          break;

        // Azure VM or Azure target is selected
        case (isAlreadyAzureSource || $scope.azureSourceSelected):
          regex = type === 'prefix' ? FORMATS.azurePrefix :
            FORMATS.azureSuffix;

          isInvalid = !regex.test(value);
          message = 'cloneRecover.errorText.azureSpecificError';

          break;

        // All other cases
        default:
          isInvalid = !FORMATS.names.test(value);
          message = 'cloneRecover.errorText.nameInvalid';

          break;
      }

      if (isInvalid) {
        $ctrl[type + 'ErrorMessage'] = {
          message: message,
        };
      }

      return !isInvalid;
    }

    /**
     * when selecting an AWS Region we need to find the selected regions entity
     * inside the awsEntity which will allow use to get all instance types
     * related to the selected region.
     *
     * @param    {Object}   region         object that contains region details
     * @param    {Object}   regionEntity   region Entity object
     */
    $scope.shared.selectAWSRegion = $scope.selectAWSRegion =
      function selectAWSRegion(region, regionEntity) {
        _.assign($scope.shared.task, {
          selectedAWSInstance: undefined,
          selectedAWSVpc: undefined,
          selectedAWSVpcTemp: undefined,
          selectedAWSubnet: undefined,
          selectedAWSubnetTemp: undefined,
          selectedAWSecurityGroup: undefined,
          selectedAWSKeyPair: undefined,
        });

        $scope.regionEntity = regionEntity || $scope.awsEntity.children.find(
          function findRegion(child) {
            return child.entity.displayName === region.displayName;
          });

        // once the selected region is found populate instanceTypes, keyPairs
        // and VPC
        $scope.instanceTypes =
          filterEntityChildrenByType($scope.regionEntity.children,
          PUB_TO_PRIVATE_ENV_STRUCTURES.kAWS.entityTypes.kInstanceType,
          'awsEntity');

        $scope.keyPairs =
          filterEntityChildrenByType($scope.regionEntity.children,
            PUB_TO_PRIVATE_ENV_STRUCTURES.kAWS.entityTypes.kKeyPair,
            'awsEntity');

        $scope.vpcOptions =
          filterEntityChildrenByType($scope.regionEntity.children,
            PUB_TO_PRIVATE_ENV_STRUCTURES.kAWS.entityTypes.kVPC,
            'awsEntity');
      };

    /**
     * instanceType selection handler. Resets VPC related fields.
     */
    $scope.selectAWSInstanceType = function selectAWSInstanceType() {
      _.assign($scope.shared.task, {
        selectedAWSVpc: undefined,
        selectedAWSubnet: undefined,
        selectedAWSecurityGroup: undefined,
      });
    };

    /**
     * Adds an empty custom tag.
     */
    $scope.addCustomTag = function addCustomTag() {
      if (!$scope.shared.task.customTags) {
        $scope.shared.task.customTags = [];
      }
      if ($scope.shared.task.customTags.length === $scope.shared.task.MAXIMUM_NUM_TAGS) {
        $scope.shared.task.displayCustomTagsWarning = true;
      }
      else {
        $scope.shared.task.customTags.push({key:'', value:''})
      }
    };

    /**
     * Removes tag at position index.
     */
    $scope.removeCustomTag = function removeCustomTag(index) {
      $scope.shared.task.displayCustomTagsWarning = false;
      $scope.shared.task.customTags.splice(index, 1);
      if ($scope.shared.task.customTags.length === 0) {
        $scope.shared.task.customTags = undefined;
      }
    };

    /**
     * calls method to populate Subnet options dropdown when a VPC is selected
     *
     * @param    {Object}    vpc     vpc object
     * @param    {Boolean}   isTemp  is a temporary entity modified
     */
    $scope.selectAWSVpcOption = function selectAWSVpcOption(vpc, isTemp) {
      if (!isTemp) {
        _.assign($scope.shared.task, {
          selectedAWSubnet: undefined,
          selectedAWSecurityGroup: undefined,
        });

        // when a VPC is selected get all subnet options from the selected vpc
        // entity. Subnets are of type 10
        $scope.subnets = filterEntityChildrenByType(vpc.children,
          PUB_TO_PRIVATE_ENV_STRUCTURES.kAWS.entityTypes.kSubnet,
          'awsEntity');
      } else {
        $scope.shared.task.selectedAWSubnetTemp = undefined;
        $scope.subnetsTemp = filterEntityChildrenByType(vpc.children,
          PUB_TO_PRIVATE_ENV_STRUCTURES.kAWS.entityTypes.kSubnet,
          'awsEntity');
      }

      getAWSNetworkSecurityGroups(vpc);
    };

    /**
     * gets all network security groups from the VPC entity.
     * Network Securty Groups are of type 8
     *
     * @param    {Object}    vpc    vpc entity object
     */
    function getAWSNetworkSecurityGroups(vpc) {
      $scope.securityGroups = filterEntityChildrenByType(vpc.children,
        PUB_TO_PRIVATE_ENV_STRUCTURES.kAWS.entityTypes.kNetworkSecurityGroup,
        'awsEntity');
    }

    /**
     * map the selected network security groups to return the entity objects.
     * API expects the entity objects of the selected groups.
     *
     * @param    {Array}    groups    array of group entity objects
     */
    $scope.selectAWSSecurityGroup = function selectAWSSecurityGroup(groups) {
      $scope.shared.task.awsSecurityGroups = groups.map(
        function mapSecurityGroups(group) {
          return group.entity;
        });
    };

    /**
     * Gets all project options by mapping the gcp entity selected.
     * Projects are defined as type 1 in the entity hierarchy tree
     *
     * @param    {Object}    gcpEntity   selected gcp entity object
     */
    function getGCPProjectOptions(gcpEntity) {
      $scope.projects = [];

      $scope.projects = gcpEntity.children.map(function mapSource(child) {
        if (child.entity.gcpEntity.type ===
            PUB_TO_PRIVATE_ENV_STRUCTURES.kGCP.entityTypes.kProject) {

          return child.entity;
        }
      });
    }

    /**
     * After selecting a GCP Project, find all the regions inside
     * the selected gcpEntity
     *
     * @param    {Object}   project   object that contains project details
     */
    $scope.selectGCPProject = function selectGCPProject(project) {
      _.assign($scope.shared.task, {
        selectedGCPRegion: undefined,
        selectedGCPZone: undefined,
        selectedGCPSubnet: undefined,
      });

      $scope.projectEntity = $scope.gcpEntity.children.find(
        function findProject(child) {
          return child.entity.id === project.id;
        });

      // once the selected project is found populate regions
      $scope.regions =
        filterEntityChildrenByType($scope.projectEntity.children,
        PUB_TO_PRIVATE_ENV_STRUCTURES.kGCP.entityTypes.kRegion,
        'gcpEntity');
    };

    /**
     * After selecting a GCP Region, find all the zones inside
     * the selected projectEntity
     *
     * @param    {Object}   project   object that contains region details
     */
    $scope.selectGCPRegion = function selectGCPRegion(region) {
      _.assign($scope.shared.task, {
        selectedGCPZone: undefined,
        selectedGCPSubnet: undefined,
      });

      $scope.regionEntity = $scope.projectEntity.children.find(
        function findRegion(child) {
          return child.entity.id === region.entity.id;
        });

      // once the selected region is found populate zones
      $scope.zones =
        filterEntityChildrenByType($scope.regionEntity.children,
        PUB_TO_PRIVATE_ENV_STRUCTURES.kGCP.entityTypes.kAvailabilityZone,
        'gcpEntity');

      // once the selected region is found populate regions
      $scope.subnets =
        filterEntityChildrenByType($scope.regionEntity.children,
        PUB_TO_PRIVATE_ENV_STRUCTURES.kGCP.entityTypes.kSubnet,
        'gcpEntity');
    };

    /**
     * Groups the Subnets by the host ID
     *
     * @param   {object}   subnet  The entity of the GCP Subnets.
     * @return  {string}   The name of the group.
     */
    $scope.gcpSubnetGroup = function gcpSubnetGroup(subnet) {
      return !!_.get(subnet, 'entity.gcpEntity.hostProjectId') ?
        $translate.instant('shared') : $translate.instant('local');
    };

    /**
     * Gets the acropolis storage containers.
     *
     * @method   getAcropolisStorageContainers
     * @param    {object}   source   The source.
     */
    function getAcropolisStorageContainers(source) {
      getAcropolisResources({
        acropolisEntityTypes: ['kStorageContainer'],
        rootEntityId: source.id,
      })
      .then(function getSuccess(containers) {
        $scope.acropolisStorageContainers = containers;
      });
    }

    /**
     * Gets the kvm data sources.
     *
     * @method   getKVMDataSources
     * @param    {object}   source   The source.
     */
    function getKVMDataSources(source) {
      var types = PUB_TO_PRIVATE_ENV_STRUCTURES.kKVM.entityTypes;
      var sourcesList = $scope.shared.sourcesList;
      var childrenType;
      var nodes;

      switch(source.entity.kvmEntity.type) {
        case types.kOVirtManager:
          $scope.shared.kvmOVirtManager =
            sourcesList.find(function findKVMSource(node) {
              return (ENUM_ENV_TYPE[node.entity.type] ===
                ENUM_ENV_TYPE.kKVM &&
                  node.entity.id === source.entity.id);
            });

          childrenType = 'kDatacenter';
          nodes = $scope.shared.kvmOVirtManager.children;
          break;
        case types.kDatacenter:
          childrenType = 'kCluster';
          nodes = $scope.shared.kvmDatacenter.children;
          break;
        case types.kCluster:
          childrenType = 'kStorageDomain';
          nodes = $scope.shared.kvmDatacenter.children;
          break;
      }

      // Based on the kvm parent type, find relevant child entities.
      // Add them to the scope with a proper key.
      // So 'kCluster' -> 'kvmClusters'.
      // The name conversion is done to indicate the clusters on
      // scope are specific to kvm since kCluster sounds generic.
      $scope[(childrenType + 's').replace('k', 'kvm')] =
        findKVMNodesOfType(nodes, childrenType);
    }

    /**
     * Filters only the VnicProfiles of KVM source based on networkId.
     * Early exit is to make sure that VNic Entities of other sources
     * are not filtered based on their networkId.
     *
     * @method   filterKVMVnicEntity
     * @param    {object}   vnicEntity.
     */
    function filterKVMVnicEntity(vnicEntity) {
      if (!vnicEntity.kvmEntity) {
        return true;
      }
      return _.get(
          $scope.shared,
          'task.restoredObjectsNetworkConfig.networkEntity.kvmEntity.uuid'
        ) === vnicEntity.kvmEntity.networkId;
    }

    /**
     * Filters only the Networks of KVM source based on clusterId.
     * Early exit is to make sure that network Entities of other sources
     * are not filtered based on their clusterId.
     *
     * @method   filterKVMNetworkEntity
     * @param    {object}   networkEntity.
     */
    function filterKVMNetworkEntity(networkEntity) {
      if (!networkEntity.kvmEntity) {
        return true;
      }
      return _.get($scope.shared, 'kvmDatacenter.entity.kvmEntity.uuid') ===
        networkEntity.kvmEntity.dcId;
    }

    /**
     * Given a KVM node array, this function returns all nodes
     * of a given 'type'
     *
     * @method   findKVMNodesOfType
     * @param    {Array}    nodes   The list of nodes
     * @param    {String}   type    The type
     * @return   {Array}   All nodes of given type
     */
    function findKVMNodesOfType(nodes, type) {
      return nodes.filter(function filterEntities(node) {
        return node.entity.kvmEntity.type ===
          PUB_TO_PRIVATE_ENV_STRUCTURES.kKVM.entityTypes[type];
      });
    }

    /**
     * Builds Resource Group options for clone Azure
     *
     * @param  {Object} item Source entity
     */
    function getResourceGroupOptions(item) {
      $ctrl.computeOptions = [];
      $ctrl.availabilitySets = [];
      $ctrl.storageAccounts = [];
      $ctrl.storageContainers = [];
      $ctrl.virtualNetworks = [];
      $ctrl.subnets = [];

      $ctrl.storageAccountsTemp = [];
      $ctrl.storageContainersTemp = [];
      $ctrl.virtualNetworksTemp = [];
      $ctrl.subnetsTemp = [];

      $ctrl.resourceGroup =
      $ctrl.computeOption =
      $ctrl.availabilitySet =
      $ctrl.storageResourceGroup =
      $ctrl.storageAccount =
      $ctrl.storageContainer =
      $ctrl.networkResourceGroup =
      $ctrl.virtualNetwork =
      $ctrl.subnet =
      $ctrl.storageAccountTemp =
      $ctrl.storageContainerTemp =
      $ctrl.virtualNetworkTemp =
      $ctrl.subnetTemp = undefined;

      $ctrl.resourceGroupsTemp = $ctrl.resourceGroups =
        (item.children || []).filter( function getResourceGroups(child) {
          return (child.entity.azureEntity.type === 1 && !!child.children);
        });
    }

    /**
     * Builds Compute options for clone Azure
     *
     * @param  {Object} item Source entity
     */
    function getComputeOptions(item) {
      // HyperV routines
      if (item.hypervEntity) {
        getHypervResources({
          hypervEntityTypes: ['kHypervHost'],
          rootEntityId: item.id,
        })
        .then(function getSuccess(computeResources) {
          $scope.computeOptions = computeResources;
        });
      }
    }

    /**
     * Handler Fn when HyperV Compute option is selected.
     *
     * @method   hypervComputeChanged
     * @param    {object}   compute   The selected Compute object.
     */
    function hypervComputeChanged(compute) {
      $scope.hypervVolumes.length = 0;
      $scope.shared.task.hypervParams.volume =
        $scope.shared.datastoreEntity = undefined;
      getHypervVolumes(compute);
      getNetworkConfigs(compute);
    }

    /**
     * Gets the hyperv volumes (datastores).
     *
     * @method   getHypervVolumes
     * @param    {object}   compute   The selected COmpute object.
     */
    function getHypervVolumes(compute) {
      getHypervResources({
        hypervEntityTypes: ['kDatastore'],
        rootEntityId: compute.parentId,
        parentEntityId: compute.id,
      })
      .then(function getSuccess(volumes) {
        $scope.hypervVolumes = volumes.filter(filterUnmountedVolumes);
      });
    }

    /**
     * JS filter Function to filter the volumes list according to
     * mount point.
     *
     * @method     filterTargetType
     * @param      {object}   volume     The Volume object
     * @return     {boolean}  True keeps the val row. False filters it out.
     */
    function filterUnmountedVolumes(volume) {
      var mountpoints = volume.hypervEntity.datastoreInfo.mountPointVec;
      return Array.isArray(mountpoints) && mountpoints.length > 0;
    }

    /**
     * Gets the acropolis network entities.
     *
     * @method   getAcropolisNetworkEntities
     * @param    {object}   parentSource   The chosen Source
     */
    function getAcropolisNetworkEntities(parentSource) {
      getAcropolisResources({
        acropolisEntityTypes: ['kNetwork'],
        rootEntityId: parentSource.id,
        parentEntityId: parentSource.id,
      }).then(function getNetworkEntitiesSuccess(resp) {
        $scope.networkEntities = resp;
      });
    }

    /**
     * Gets the kvm network entities.
     *
     * @method   getKVMNetworkEntities
     * @param    {object}   parentSource   The chosen Source
     */
    function getKVMNetworkEntities(parentSource) {
      getKVMResources({
        kvmEntityTypes: ['kNetwork'],
        rootEntityId: parentSource.id,
        parentEntityId: parentSource.id,
      }).then(function getNetworkEntitiesSuccess(resp) {
        $scope.networkEntities = resp;
      });
    }

    /**
     * Gets the kvm VNic Profile entities.
     *
     * @method   getKVMVnicEntities
     * @param    {object}   parentSource   The chosen Source
     */
    function getKVMVNicEntities(parentSource) {
      getKVMResources({
        kvmEntityTypes: ['kVNicProfile'],
        rootEntityId: parentSource.id,
        parentEntityId: parentSource.id,
      }).then(function getVNicEntitiesSuccess(resp) {
        $scope.vnicEntities = resp;
      });
    }

    /**
     * Gets various hyperv resources.
     *
     * See PUB_TO_PRIVATE_ENV_STRUCTURES for environment specific types.
     *
     * @method   getHypervResources
     * @param    {object}   [params]   Additional entitiesOfType params.
     * @return   {object}   Promise to resolve with the selected objects.
     */
    function getHypervResources(params) {
      return SourceService.getEntitiesOfType(
        angular.extend({
          environmentTypes: ['kHyperV', 'kHyperVVSS'],
        }, params)
      );
    }

    /**
     * Gets various Acropolis resources.
     *
     * See PUB_TO_PRIVATE_ENV_STRUCTURES for environment specific types.
     *
     * @method   getHyperVResources
     * @param    {object}   [params]   Additional entitiesOfType params.
     * @return   {object}   Promise to resolve with the selected objects.
     */
    function getAcropolisResources(params) {
      return SourceService.getEntitiesOfType(
        angular.extend({
          environmentTypes: ['kAcropolis'],
        }, params)
      );
    }

    /**
     * Gets various KVM resources.
     *
     * See PUB_TO_PRIVATE_ENV_STRUCTURES for environment specific types.
     *
     * @method   getKVMResources
     * @param    {object}   [params]   Additional entitiesOfType params.
     * @return   {object}   Promise to resolve with the selected objects.
     */
    function getKVMResources(params) {
      return SourceService.getEntitiesOfType(
        angular.extend({
          environmentTypes: ['kKVM'],
        }, params || {})
      );
    }

    /**
     * Change handler when resourcePoolEntity is selected
     *
     * @method     resourcePoolEntityChanged
     */
    function resourcePoolEntityChanged(item) {
      if (item && item.vmwareEntity) {
        $scope.networkEntities.length = 0;
        $scope.shared.task.datastoreEntity = undefined;

        // Reset to detach network.
        $scope.shared.networkingOptions = 'detach';
        $scope.shared.task.restoredObjectsNetworkConfig.detachNetwork = true;

        if (isRecover) {
          getDatastores(item);
        }

        getVmFolders(item.id);
        getNetworkConfigs();
      }
    }

    /**
     * Updates assorted option arrays supporting clone Azure.
     * Options include Storage Account, Security Group, Virtual Network
     *
     * @param  {Object}  group   selected resource group
     * @param  {Boolean} isTemp  is this temporary resource group
     */
    function resourceGroupEntityChanged(group, isTemp) {
      var suffix = isTemp ? 'Temp' : '';

      $ctrl['storageAccounts' + suffix].length =
      $ctrl['storageContainers' + suffix].length =
      $ctrl['virtualNetworks' + suffix].length =
      $ctrl['subnets' + suffix].length = 0;

      $ctrl['storageResourceGroup' + suffix] =
      $ctrl['storageAccount' + suffix] =
      $ctrl['networkResourceGroup' + suffix] =
      $ctrl['virtualNetwork' + suffix] =
      $ctrl['subnet' + suffix] = undefined;

      if (!isTemp) {
        $ctrl.computeOptions.length = 0;
        $ctrl.availabilitySets.length = 0;

        $ctrl.computeOption =
        $ctrl.availabilitySet =
        $ctrl.storageContainer = undefined;
      }

      if (!group.children) {
        return;
      }

      if (!isTemp) {
        // compute options have to be under the selected resource group
        $ctrl.computeOptions =
          group.children.filter(function eachChild(child) {
            // Compute Options
            return child.entity.azureEntity.type ===
              PUB_TO_PRIVATE_ENV_STRUCTURES.kAzure.entityTypes.kComputeOptions;
          });

        $ctrl.availabilitySets =
          group.children.filter(function eachChild(child) {
            // Availability Sets
            return child.entity.azureEntity.type ===
              PUB_TO_PRIVATE_ENV_STRUCTURES.kAzure.entityTypes.kAvailabilitySet;
          });
      }

      // storage account and virtual network can be under any resource group
      // but need to be in the same location
      $ctrl['resourceGroups' + suffix].forEach(function eachResourceGroup(rg) {
        rg.children.forEach(function eachChild(child) {
          switch (child.entity.azureEntity.type) {
            // Storage Account
            case PUB_TO_PRIVATE_ENV_STRUCTURES
              .kAzure.entityTypes.kStorageAccount:

              // The location of selected resourceGroup, selected storageAccount
              // and the parent resourceGroup of the selected storageAccount
              // must match.
              // If backend does not send location, allow all storage groups to
              // be selected and let backend fail
              if (!child.entity.azureEntity.location ||
                (rg.entity.azureEntity.location ===
                group.entity.azureEntity.location &&
                child.entity.azureEntity.location ===
                group.entity.azureEntity.location)) {

                child._resourceGroup = rg;
                child._groupName = rg.entity.displayName;
                $ctrl['storageAccounts' + suffix].push(child);
              }
              break;

            // Virtual Network
            case PUB_TO_PRIVATE_ENV_STRUCTURES
              .kAzure.entityTypes.kVirtualNetwork:

              // The location of selected resourceGroup and the selected
              // virtualNetwork must match.
              // If backend does not send location, allow all storage groups to
              // be selected and let backend fail
              if (!child.entity.azureEntity.location ||
                (child.entity.azureEntity.location ===
                group.entity.azureEntity.location)) {

                child._resourceGroup = rg;
                child._groupName = rg.entity.displayName;
                $ctrl['virtualNetworks' + suffix].push(child);
              }
              break;
          }
        });
      });
    }

    /**
     * Updates assorted configuration and options associated with Storage
     * Account for support with clone Azure.
     *
     * @param  {Object}  group   selected resource group
     * @param  {Boolean} isTemp  is this temporary storage account
     */
    function storageAccountChanged(account, isTemp) {
      var suffix = isTemp ? 'Temp' : '';

      $ctrl['storageContainer' + suffix] = undefined;
      $ctrl['storageContainers' + suffix].length = 0;

      if (!account.children) {
        return;
      }

      account.children.forEach(function eachChild(child) {
        switch (child.entity.azureEntity.type) {
          // Storage Key
          case 8:
            $ctrl.storageKey = child;
            break;

          // Storage Container
          case 9:
            $ctrl['storageContainers' + suffix].push(child);
        }
      });

    }

    /**
     * Updates Subnet option array Fires on Virtual Network change
     *
     * @method  virtualNetworkEntityChanged
     * @param   {Object}  virtualNetwork  The virtualNetwork entity object
     * @param  {Boolean}  isTemp          is this temporary virtual network
     */
    function virtualNetworkEntityChanged(virtualNetwork, isTemp) {
      var suffix = isTemp ? 'Temp' : '';

      $ctrl['subnet' + suffix] = undefined;

      $ctrl['subnets' + suffix] = (virtualNetwork.children || []).filter(
        function eachVN(child) {
          return child.entity.azureEntity.type ===
              PUB_TO_PRIVATE_ENV_STRUCTURES.kAzure.entityTypes.kSubnet;
        });
    }

    /**
     * Change handler for when NFSView is selected
     *
     * @class
     * @param      {String}  item    View name
     */
    function NFSViewChanged(item) {
      if (item) {
        $scope.shared.task.viewName = item;
      } else {
        removeView();
      }
    }

    /**
     * Scripted routine to handle a selected ESXi host. See internals for
     * details.
     *
     * @method  setESXiTargetTaskSettings
     * @param   {object}                   resourcePool  ResourcePool object
     */
    function setESXiTargetTaskSettings(resourcePool) {
      // Apply the resourcePool
      $scope.shared.task.resourcePoolEntity = resourcePool;

      // Trigger resourcePool changed method
      resourcePoolEntityChanged(resourcePool);

      // Enforce the new network option
      $scope.shared.networkingOptions = 'new';
      $scope.shared.task.restoredObjectsNetworkConfig.detachNetwork = undefined;
    }

    /**
     * local helper function to remove the viewName from shared.task object
     * and clear the model for the ui-select (shared.selectedViewName)
     *
     * @method removeView
     */
    function removeView() {
      $scope.shared.selectedViewName = undefined;
      $scope.shared.task.viewName = undefined;
    }

    /**
     * Raises warnings for a View name with certain characters and protocols.
     *
     * @param      {Object}  task    The task
     */
    function validateViewName(task) {
      $scope.shared.nameWarningKeys =
        ViewService.getViewNameWarnings(task.viewName);
    }

    /**
     * Fetches the ResourcePoolEntities list from the server
     *
     * @method     getResourcePools
     * @param      {object}  item    ParentSourceEntity
     */
    function getResourcePools(item) {
      $scope.resourcePoolsLoading = true;
      $scope.resourcePools.length = 0;
      return SourceService.getResourcePool({
          vCenterId: item.id,
        })
        .then(function resourcePoolGotten(resp) {
          $scope.resourcePools = resp;
          return resp;
        }, evalAJAX.errorMessage)
        .finally(function resourcePoolsFinally(resp) {
          $scope.resourcePoolsLoading = false;
        });
    }

    /**
     * Fetches the DatastoreEntities form the server
     *
     * @method   getDatastores
     * @param    {object}   item      ResourcePoolEntity
     * @param    {object}   [params]  The overriding parameters
     */
    function getDatastores(item, params) {
      var datastoreParams = params || {
        resourcePoolId: item.id,
        vCenterId: $scope.shared.task.restoreParentSource.id,
      };
      $scope.datastoresLoading = true;
      $scope.datastores.length = 0;

      SourceService.getDatastores(datastoreParams)
        .then(function datastoresSuccess(datastores) {
          $scope.datastores = datastores;
        }, evalAJAX.errorMessage)
        .finally(function datastoresFinally() {
          $scope.datastoresLoading = false;
        });
    }

    /**
     * Fetches the FolderEntities form the server
     *
     * @method   getVmFolders
     * @param    {object}   resgrpEntityId   ResourcePoolEntity id
     * @param    {object}   [params]         The overriding parameters
     */
    function getVmFolders(resgrpEntityId, params) {
      var vmFolderParams = params || {
        resourcePoolId: resgrpEntityId,
        vCenterId: $scope.shared.task.restoreParentSource.id
      };
      $scope.vmFoldersLoading = true;
      $scope.vmFolders.length = 0;
      $scope.datastoreFolders.length = 0;

      SourceService.getVmFolders(vmFolderParams)
        .then(function getVmFoldersGotten(resp) {
          $scope.vmFolders = resp.vmFolders || [];
          $scope.datastoreFolders = resp.datastoreFolders || [];
        }, evalAJAX.errorMessage)
        .finally(function getVmFoldersFinally() {
          $scope.vmFoldersLoading = false;
        });
    }

    function getStorageProfile(vdcId) {
      $scope.storageProfileLoading = true;
      SourceService.getStorageProfile(vdcId)
        .then(function getStorageProfile(resp) {
          $scope.storageProfiles = resp.storageProfiles;
        }, evalAJAX.errorMessage)
        .finally(function storageProfileFinally() {
          $scope.storageProfileLoading = false;
        });
    }

    /**
     * Fetches clone-compatible NFSViews from the server
     *
     * @method     getNFSViews
     */
    function getNFSViews() {
      var params = {
        includeInactive: false,
        maxCount: 1000,
      };
      $scope.NFSViewsLoading = true;
      $scope.NFSViews.length = 0;
      return ViewService.getViews(params)
        .then(function NFSViewsGotten(resp) {
          $scope.NFSViews = processNFSViewsResponse(resp);
          $scope.NFSViewsLoading = false;
          return resp;
        }, evalAJAX.errorMessage);
    }

    /**
     * FEtches Available NetworkConfigEntities from the server
     *
     * @method     getNetworkConfigs
     */
    function getNetworkConfigs(compute) {
      var poolId;
      var parentId;

      $scope.networkConfigsLoading = true;
      $scope.networkEntities.length = 0;

      // If HyperV
      if (compute && compute.type === 2) {
        return getHypervResources({
          hypervEntityTypes: 'kNetwork',
          parentEntityId: compute.id,
          rootEntityId: compute.parentId,
        }).then(
          function getSuccess(networks) {
            $scope.networkEntities = networks;
          },

          evalAJAX.errorMessage
        ).finally(
          function getFinally() {
            $scope.networkConfigsLoading = false;
          }
        );
      }

      poolId = $scope.shared.task.resourcePoolEntity.id;
      parentId = $scope.shared.task.restoreParentSource.id;

      return SourceService
        .getNetworkEntities({
          resourcePoolId: poolId,
          vCenterId: parentId,
        })
        .then(function networkEntitiesGotten(resp) {
          $scope.networkEntities = processNetworkEntitiesResponse(resp);
          $scope.networkConfigsLoading = false;
          return resp;
        }, evalAJAX.errorMessage);
    }

    /**
     * Initialization routine for this controller
     *
     * @method     initStep2
     */
    function initStep2() {
      var objectId = {};

      if ($state.params.jobId || $state.params.jobIds) {
        // If jobId params is set, we can assume that you are in the fail over
        // flow. let's get the job object, add it to cart, then set source
        // property.
        setupAbbreviatedFlow();
      } else if (!angular.isArray($scope.shared.taskCart) ||
        !$scope.shared.taskCart.length) {

        // If jobId and Source Object params are not set, and your cart is
        // empty, then GTFO.
        $scope.startTaskOver();

        return;
      } else {
        updateSourceEnvTypes();
      }

      $scope.parentSources = $rootScope.parentSources || [];

      getEsxHostEndpoints();

      populateRestoreObjects();

      // Since getNFSViews is independent of any preselection of options,
      // lets load it immediately for clone flows.
      if (isClone) {
        getNFSViews();
      }

      // Check if source is cohesity cluster
      if($state.params.isKuiper) {
        $ctrl.kuiperSelected = $ctrl.excludeNetworkConfig =
          $ctrl.excludeSourceSelection = true;

        // While cloning a vm on cohesity cluster, if vm name is 'test_ubuntu',
        // and default task name is 'Clone-VMs_Jan_17_2019' then tak name will
        // become test_ubuntu_Clone-VMs_Jan_17_2019.
        $scope.shared.task.name = $scope.shared.taskCart[0]._name + '_' +
          $scope.shared.task.name;

        objectId = _.get($scope.shared.taskCart[0].vmDocument, 'objectId');
        $ctrl.recoveryParams = {
          jobId: objectId.jobId,
          type: objectId.entity.type,
          uuid: SourceService.getTypedEntity(objectId.entity).uuid,
        };
      }
    }

    /**
     * Note: If we need to get the endpoints for ESX hosts in other workflows
     * consider moving this method to SourceService to be consumed by getSources
     *
     * This method takes the sourceList and filters out the ESX hosts. For each
     * ESX host we make an api call and get its endpoint which is unique (ESX
     * names may not be unique). We then add each endpoint to the _typEntity
     * object on the sourceList to be used in the template
     *
     * @method     getEsxHostEndpoints
     */
    function getEsxHostEndpoints() {
      var esxHostsPromises;

      if (!$scope.parentSources.length) { return; }

      /**
       * Here we are generating an object of promises since the
       * protectionSources endpoint only takes in one id param at a time. The
       * benefit of using a hashmap of promises over an array of promises is
       * that $q.all will return an object with the key as the source.id and the
       * value as the API response. This helps us by removing the need to loop
       * over both the response and the sourceList to match the response against
       * the correct index in the sourceList as shown below.
       */
      esxHostsPromises = $scope.parentSources.reduce(
        function sourceReducer(_hosts, source) {
          if (source._typeEntity.type === 10) {
            _hosts[source.id] =
              SourceService.getProtectionSources({ id: source.id });
          }

          return _hosts;
        }, {});

      if (esxHostsPromises) {
        $q.all(esxHostsPromises).then(function allFetched(responses) {
          $scope.parentSources.forEach(
            function processProtectionSources(value) {
              if (responses[value.id]) {
                value._endpoint =
                  responses[value.id].registrationInfo.accessInfo.endpoint;
              }
          });
        });
      }
    }

    /**
     * Performs abbreviated flow & failover setup. Specifically, it detects if
     * this is a failover flow, or a simple abbreviated flow (no search step).
     * Then it fetches the requested entity, and if specified, preselects the
     * requested snapshot (run). Then it adds that to the taskCart.
     *
     * @method    setupAbbreviatedFlow
     */
    function setupAbbreviatedFlow() {
      var object;
      var promiseObject = {};
      var params = {
        entityIds: $state.params.entityId || $state.params.entityIds,
        jobIds: $state.params.jobId || $state.params.jobIds,
      };
      var cartPromise;
      var taskData;

      $scope.shared.abbreviatedFlow = true;
      $scope.cloneAndRecoverDataLoaded = false;

      $scope.taskSteps.shift();
      $scope.stateNames.splice(1, 1);

      // Auto-detect Failover: Recover + jobId + !entityId + !jobInstanceId
      if (isRecover && !params.entityIds && params.jobIds &&
        !$state.params.jobInstanceId && !$state.params.taskId) {
          $scope.shared.failoverFlow = true;
          $state.current.help =
            'failover_protection_recoverynew_vm_steps_options';
      }

      // when failing-over to a cloud source, clone flow requires that we have
      // our cloud sources hierarchy to function correctly
      if (ENV_GROUPS.cloudDeploySources
          .includes(ENV_TYPE_CONVERSION[$state.params.entityType])) {
        $scope.shared.sourceDefaultId = $state.params.entityId;

        // entityIds needs to be undefined. searchVM(s) API call will
        // fail if defined because a cloud source is not techinically an
        // entity
        params.entityIds = undefined;

      }

      promiseObject.sourcesList = SourceService.getSources({}, true);
      promiseObject.foundVMs = SearchService.vmSearch(params);
      promiseObject.taskData = $state.params.taskId ?
        RestoreService.getTask($state.params.taskId) : undefined;

      $q.all(promiseObject).then(function getData(responses) {
        $scope.sourcesList = responses.sourcesList ?
          responses.sourcesList.entityHierarchy.children :
          [];

        // aliasing to prevent isolated scope problem
        $scope.shared.sourcesList = $scope.sourcesList;

        // It's re-submit workflow
        if ($state.params.taskId && responses.taskData) {
          taskData = responses.taskData[0].performRestoreTaskState;
          $ctrl.resubmitWorkflow = true;
          object = responses.foundVMs;
          responses.foundVMs.forEach(vm => cartPromise = $scope.addToCart(vm));
        } else if (
          Array.isArray(params.entityIds) && Array.isArray(params.jobIds)
        ) {
          // If "entityIds" and "jobIds" fields are arrays, add each item
          // individually.
          // Length of array to iterate through
          const length = Math.min(
            params.entityIds.length, params.jobIds.length
          );

          for (let i = 0; i < length; i++) {
            const newParams = {
              ...params,

              // The singular key for entityId is called "entityIds".
              entityIds: params.entityIds[i],

              // The singular key for jobId is called "jobIds".
              jobIds: params.jobIds[i],
            };

            cartPromise = $scope.addToCart(
              $scope.getObjectForCart(responses, newParams)
            );
          }
        } else {
          cartPromise = $scope.addToCart(
            $scope.getObjectForCart(responses, params)
          );
        }

        return object;
      })
      .finally(function afterSearch() {
        // User is trying to failover but no versions were found: pop a
        // cmessage.
        if ($scope.shared.failoverFlow &&
          (!object ||
          (!object.vmDocument.versions ||
            (object.vmDocument.versions &&
            !object.vmDocument.versions.length)) &&
          (!object.vmDocument._versions ||
            (object.vmDocument._versions &&
            !object.vmDocument._versions.length)))) {

          cMessage.error({
            textKey: 'restoreFailover.noSnapshotsAvailableForFailover',
          });

          $scope.shared.isFailoverDisallowed = true;
        }

        // Set the page display to show preselected source
        $scope.showOptions = true;

        // Add parentSource object to Task if one was specified
        if ($state.params.sourceEntity) {
          $scope.state.showParentSourceOptions = $ctrl.resubmitWorkflow ?
            taskData.restoredToDifferentSource : true;

          $scope.shared.task.restoreParentSource = $state.params.sourceEntity;

          $scope.shared.task.restoreParentSource._typeEntity =
            SourceService.getTypedEntity($scope.shared.task.restoreParentSource);

          $scope.parentSourceChanged(
            $scope.shared.task.restoreParentSource || object.registeredSource
          );

          // If it's in resubmit workflow, restore the previous settings about the
          // new recovery location.
          if ($ctrl.resubmitWorkflow && taskData.restoredToDifferentSource) {
            getDatastores(taskData.resourcePoolEntity);
            if (taskData.vcdConfig) {
              _.assign($scope.shared.task, {
                datastoreEntity: FEATURE_FLAGS.enableStorageProfile ? undefined : taskData.datastoreEntityVec[0],
                vcdConfigParams: {
                  vdcEntity: taskData.vcdConfig.vdcEntity,
                }
              });

              $scope.vdcEntity = {
                entity: taskData.vcdConfig.vdcEntity,
              };
            } else {
              _.assign($scope.shared.task, {
                resourcePoolEntity: taskData.resourcePoolEntity,
                datastoreEntity: enableMultipleDatastore() ?
                  taskData.datastoreEntityVec : taskData.datastoreEntityVec[0],
                vmwareParams: {
                  targetVmFolder:
                    _.get(taskData, 'restoreVmwareVmParams.targetVmFolder'),
                },
              });
            }
          }
        }

        $scope.cloneAndRecoverDataLoaded = true;

        (cartPromise || $q.resolve()).then(updateSourceEnvTypes);
        _syncNativeSnapshotsCondition();
      });

    }

    /**
     * Function to add an item to the cart from api responses.
     *
     * @param {object} responses Object containing Job and Entity responses
     * @param {object} params Passed state params
     */
    function getObjectForCart(responses, params) {
      var object;

      // Using == here because stateParams on the URL are always strings
      // Find the matching item in the response and continue.
      object = responses.foundVMs.find(function findJob(item) {
        // We matched a VM. Ensure it's the same VM entity.
        return (item._type === 'vm' &&
          item._jobId == params.jobIds &&
          params.entityIds == item.vmDocument.objectId.entity.id) ||

          // We matched a Job. Ensure the job matches the id we passed in,
          // and that we only add a job to the cart.
          (item._jobId == params.jobIds && item._type === 'job');
      });


      // Abbreviated flow: This is a VM we're adding
      if (params.entityIds && params.jobIds) {

        // We've specified a jobInstanceId AND entityId, so identify its
        // snapshot.
        if ($state.params.jobInstanceId) {
          object._snapshot = object.vmDocument.versions.find(function eachRun(run, ii) {
            if ($state.params.jobInstanceId == run.instanceId.jobInstanceId) {
              object._snapshotIndex = ii;
              return true;
            }
          });

          // If a vaultId param was provided, add archivalTarget{} to the
          // object so it will be used as the pre-selected snapshot.
          if ($state.params.vaultId) {
            object._archivalTarget = {
              vaultId: +$state.params.vaultId,
              name: $state.params.vaultName,
              type: ENUM_ARCHIVAL_TARGET[$state.params.vaultType],
            };
          }
        }
      }

      return object;
    }

    /**
     * generic function that will filter children according to a
     * type that is passed
     *
     * @param   {object[]}  children          the array of children entities
     * @param   {Number}    type              the entity type being searched
     * @param   {String}    sourceEntityKey   the source entity type
     * @return  {Array}     new array of filtered entities
     */
    function filterEntityChildrenByType(children, type, sourceEntityKey) {
     return children.filter(function accumulateChildren(child) {
        return child.entity[sourceEntityKey].type === type;
      });
    }

    /**
     * Check if multiple datastore selection is allowed.
     *
     * @method    enableMultipleDatastore
     * @returns   {boolean}   If it's allowed, return true; otherwise return false.
     */
    function enableMultipleDatastore() {
      return FEATURE_FLAGS.enableMultipleDatastores &&
        // cannot be vCD
        !$scope.isVCD &&

        // More than one recovered vms.
        $scope.maxSelectableDatastores() > 1
    }

    /**
     * Function to return max number of datastores which can be selected for
     * vm recovery.
     *
     * @return   {number}   Count of max datastores allowed.
     */
    function maxSelectableDatastores() {
      var result = 0;

      $scope.shared.taskCart.forEach(
        function processSelectedObject(selectedObject) {
          if (selectedObject._type === 'job') {
            // If job object is in the cart, use the number of vms to be
            // recovered in that job towards datastores count.
            result += _.get(selectedObject, '_backupJobRuns.runs[' +
              selectedObject._snapshotIndex + '].vms.length', 1);
          } else {
            result += 1;
          }
        }
      );

      return result;
    }

    initStep2();
  }

  /**
   * Ng-friendly wrapper for deps resolve functions. Reduces typing.
   *
   * @method   depsResolver
   * @param    {Object}   dep   Value to return in the resolve.
   * @return   {*}              The dependency passed through.
   */
  function depsResolver(dep) {
    return function innerResolver() {
      return dep;
    };
  }

  /**
   * Returns class as per status
   * This is defined here as it's being used in parent ctrl and selector modal.
   *
   * @method  getVulScanStatusClass
   * @param   {string}  status    kCritical/kHigh etc.
   * @returns   {string}    'scan-status critical'
   */
  function getVulScanStatusClass(status) {
    return 'vul-scan-status ' + status.toLowerCase().substring(1);
  }

})(angular);

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { LineChartComponent } from '@cohesity/helix';
import { TranslateService } from '@ngx-translate/core';
import { Options, Point, YAxisLabelsOptions, YAxisOptions } from 'highcharts';
import { get, isUndefined, merge } from 'lodash-es';
import { ChartDataPoint } from 'src/app/shared/models/chart.model';
import { DatePipeWrapper } from 'src/app/shared/pipes';

/**
 * Interface for statlist multi series chart info
 * containing name of a series and corresponding legend label.
 */
export interface StatlistMultiSeriesInfo {
  name: string;
  colorSet?: string;
  labelLegend?: string;
  stillLabel?: string;
  stillValue?: string;
  chartName?: string;
  entityId?: string;
  index?: number;
  yAxisLabelsOptions?: YAxisLabelsOptions;
}

/**
 * Multi series chart with integrated stat-list that serves as legend and tooltip.
 *
 * @example
 *   <coh-statlist-multi-series-chart
 *     [chartSeriesInfo]="chartSeriesInfo"
 *     [chartSeriesData]="chartSeriesData"
 *     colorSetClass="run-activity-chart-colors">
 *   </coh-statlist-multi-series-chart>
 */
@Component({
  selector: 'coh-statlist-multi-series-chart',
  templateUrl: './statlist-multi-series-chart.component.html',
  styleUrls: ['./statlist-multi-series-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [DatePipeWrapper],
})
export class StatlistMultiSeriesChartComponent implements OnInit, AfterViewInit {

  /**
   * Chart name is used to generate unique gradient url name.
   */
  @Input() name = '';

  /**
   * Chart series info containing series name and label legend class for stat-list.
   */
  @Input() chartSeriesInfo: StatlistMultiSeriesInfo[] = [];

  /**
   * Chart data
   */
  @Input() chartSeriesData = [];

  /**
   * Optionally provide custom chart config.
   */
  @Input() customChartConfig: Options;

  /**
   * Custom colorset className
   */
  @Input() colorSetClass: string;

  /**
   * Optional classname for the chart.
   */
  @Input() className: string;

  /**
   * Chart height in px.
   */
  @Input() chartHeight = 180;

  /**
   * Whether to show stat list still value when chart is inactive.
   * Default is true.
   */
  @Input() showStillStatListValue = true;

  /**
   * Date format for tooltip.
   * Default to 'shortTime'.
   */
  @Input() tooltipDateFormat = 'shortTime';

  /**
   * Indicates whether it's shared tooltip across all series.
   */
  @Input() sharedTooltip = false;

  /**
   * Indicated whether it shows still legend or dynamic legend. Default to false.
   */
  @Input() stillLegend = false;

  /**
   * Indicated whether to show yAxis or not. Default to false.
   */
  @Input() showYAxis = false;

  /**
   * Emits event to indicate whether the chart is being hovered on.
   */
  @Output() chartHovered = new EventEmitter<boolean>(false);

  /**
   * Tooltip content for supporting accessibility.
   */
  tooltipContent: string;

  /**
   * Highcharts YAxis config object.
   */
  yAxisConfig: YAxisOptions = {
    min: 0,
    minRange : 0.1,
    visible: false,
    title: {
      text: null,
    },
  };

  /**
   * Multi Series Highcharts options.
   */
  chartConfig: Options = {
    defs: {},
    chart: {
      spacing: [0, 0, 15, 0],
      styledMode: true,
      marginTop: 24,
      zoomType: 'x',
    },
    accessibility: {
      point: {
        descriptionFormatter: (p) => [this.datePipe.transform(p.x, 'shortTime'), p.series.name,
          (p as ChartDataPoint).yFormatted].join(' ')
      },
      series: {
        pointDescriptionEnabledThreshold: false,
      },
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      area: {
        marker: {
          enabled: false,
        }
      },
      areaspline: {
        marker: {
          enabled: false,
        },
        softThreshold: false,
      },
      series: {
        turboThreshold: 1000,
        events: {
          mouseOver: () => {
            if (!this.isChartActive) {
              this.isChartActive = true;
              this.chartHovered.emit(true);
            }
          },
          mouseOut: () => {
            this.isChartActive = false;
            this.chartHovered.emit(false);
          },
        },
        states: {
          hover: {
            enabled: false,
          },
          inactive: {
            opacity: 1,
          },
        },
      },
    },
    tooltip: {
      className: 'statlist-multi-service-tooltip',
      borderRadius: 10,
      enabled: true,
      hideDelay: 100,
      shadow: false,
      shared: this.sharedTooltip,
      useHTML: true,
      ...this.formatTooltip(),
    },
    xAxis: {
      crosshair: true,
    },
  };

  /**
   * Instance of line chart component.
   */
  @ViewChild('lineChart') lineChart: LineChartComponent;

  /**
   * Current statlist
   */
  currentStatList = {};

  /**
   * Statlist of last data point.
   */
  lastStatList = {};

  /**
   * Time of last data point.
   */
  lastPointTime: string;

  /**
   * Indicates whether the chart is being hovered on or not to update the stat-list.
   */
  isChartActive = false;

  constructor(
    private datePipe: DatePipeWrapper,
    private changeDetector: ChangeDetectorRef,
    private translateService: TranslateService
  ) { }

  /**
   * OnInit lifecycle hook.
   */
  ngOnInit() {
    // Override default config with custom config if provided.
    this.chartConfig = merge(this.chartConfig, this.customChartConfig);
    this.chartConfig.yAxis = [];
    this.yAxisConfig = merge(this.yAxisConfig, this.customChartConfig?.yAxis);

    this.chartSeriesInfo.forEach((seriesInfo: StatlistMultiSeriesInfo, i: number) => {

      const id = `${this.name}gradient-${i}`;

      // Define linearGradient SVG structure for each chart series.
      this.chartConfig.defs[id] = {
        tagName: 'linearGradient',
        id,
        x1: 0,
        y1: 0,
        x2: 0,
        y2: 1,
        children: [{
          tagName: 'stop',
          offset: 0,
        }, {
          tagName: 'stop',
          offset: 1,
        }],
      };

      this.chartConfig.tooltip.shared = this.sharedTooltip;

      if (this.showYAxis) {
        this.yAxisConfig.visible = true;
      }

      if (seriesInfo.yAxisLabelsOptions) {
        const temp = {...this.yAxisConfig};
        temp.opposite = i !== 0;
        temp.labels = seriesInfo.yAxisLabelsOptions;
        (this.chartConfig.yAxis as YAxisOptions[]).push(temp);
      } else {
        (this.chartConfig.yAxis as YAxisOptions[]).push(this.yAxisConfig);
      }

      // Use still value if given explicitly else use series last data point.
      this.currentStatList[seriesInfo.name] = seriesInfo.stillValue;

      // If custom labelLegend is not set, default to higcharts-color set.
      if (!seriesInfo.labelLegend) {
        seriesInfo.labelLegend = `highcharts-color-${i}`;
      }
    });
  }

  /**
   * AfterViewInit lifecycle hook.
   */
  ngAfterViewInit() {

    // TODO(Tung): update when cog-line-chart is switched to use highcharts-angular.
    const chart = this.lineChart.chart.ref;
    const { series = [] } = chart || {};
    series.forEach(seriesObj => {
      const { data } = seriesObj;
      const lastPoint = data[data.length - 1];

      // Don't use last point if still value already given at chartSeriesInfo[seriesObj.name].
      if (isUndefined(this.currentStatList[seriesObj.name]) && lastPoint) {
        this.currentStatList[seriesObj.name] = (lastPoint as ChartDataPoint).yFormatted || lastPoint.y;
      }

      // If stillLegend is true, set last point.
      if (this.stillLegend && lastPoint) {
        this.lastStatList[seriesObj.name] = (lastPoint as ChartDataPoint).yFormatted || lastPoint.y;
        this.lastPointTime = this.datePipe.transform(lastPoint.x, 'medium');
      }
    });
    this.changeDetector.detectChanges();
  }

  /**
   * On hovering over the chart, updates the current statlist.
   *
   * @param   points   Array of hovered points of all series in the chart.
   */
  onHoveringChart(points: Point[]) {
    // Pick formated or raw for y axis value for showing stats list value.
    points.forEach((point: Point) => {
      // Exit if unable to find the point series info.
      if (!get(point, 'series')) {
        return;
      }
      this.currentStatList[point.series.name] = ((point as ChartDataPoint).yFormatted || point.y);
    });
  }

  /**
   * Set screen reader tooltip content.
   *
   * @param contents string content in an array.
   * @param timeout  Timeout in ms to reset content.
   */
  setScreenReaderTooltipContent(contents: string[], timeout = 100) {
    this.tooltipContent = contents.join(' ');
    this.changeDetector.detectChanges();

    // For some unknown reason, screen reader is reading the previous change.
    // This is a hack to make the screen reader to read the above content.
    setTimeout(() => {
      this.tooltipContent = '';
      this.changeDetector.detectChanges();
    }, timeout);
  }

  /**
   * Custom tooltip formatter for highcharts series.
   */
  formatTooltip() {
    // Cache component context for value formatting.
    const self = this;

    return {
      formatter(): string {
        const chartName = self.chartSeriesInfo[0]?.chartName;
        if (self.sharedTooltip) {
          let content = `<div class="statlist-multi-service-tooltip-inner">
            <strong>${self.datePipe.transform(this.x, self.tooltipDateFormat)}</strong><br>`;

          if (chartName && chartName !== '') {
            content += `<p class="chart-name">${self.translateService.instant(self.chartSeriesInfo[0].chartName)}</p>`;
          }

          content += `${this.points.map(dataPoint =>
            `<div class="flex-row-center margin-top-xs">
            <div class="label-legend ${dataPoint.point.series.name} ${dataPoint.point.series.index}"></div>
            <span class="tooltipSeriesName">${self.translateService.instant(dataPoint.point.series.name)}</span>
            <span class="tooltipYData">${dataPoint.point.yFormatted}</span>
            </div>`).join('')}
            </div>`;
          if (chartName && chartName !== '') {
            self.setScreenReaderTooltipContent(
              [
                self.datePipe.transform(this.x, self.tooltipDateFormat),
                self.translateService.instant(chartName),
                ...[].concat(...this.points.map(dataPoint => [
                  self.translateService.instant(dataPoint.point.series.name),
                  dataPoint.point.yFormatted,
                ])),
              ],

              // Note that 300 is the minimum to get the screen reader to read full content. With a
              // smaller value, the screen reader will only read partial content (reason unknown).
              300
            );
          } else {
            self.setScreenReaderTooltipContent(
              [
                self.datePipe.transform(this.x, self.tooltipDateFormat),
                ...[].concat(...this.points.map(dataPoint => [
                  self.translateService.instant(dataPoint.point.series.name),
                  dataPoint.point.yFormatted,
                ])),
              ], 300
            );
          }
          return content;
        } else {
          // Set screen reader content
          self.setScreenReaderTooltipContent([
            self.datePipe.transform(this.x, self.tooltipDateFormat),
            self.translateService.instant(this.point.series.name),
            this.point.yFormatted,
          ]);

          // If point.y is available show on tooltip, else hide.
          if (!isUndefined(this.point.yFormatted)) {
            return `<strong>${self.datePipe.transform(this.x, self.tooltipDateFormat)}</strong><br>
              ${self.translateService.instant(this.point.series.name)}: ${this.point.yFormatted}`;
          } else {
            return `<strong>${self.datePipe.transform(this.x, self.tooltipDateFormat)}</strong><br>
              ${self.translateService.instant(this.point.series.name)}`;
          }
        }
      },
    };
  }
}

// Module: Partition Create/Edit Content

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.partitions')
    .controller('partitionDetailController', partitionDetailControllerFn);

  function partitionDetailControllerFn($rootScope, $scope,
    $stateParams, $q, $state, PartitionService, ClusterService, VlanService,
    NodeService, ViewBoxService, cMessage, cModal, nodeList,
    evalAJAX, cUtils, cFocus, VARS, FORMATS, SlideModalService,
    NODE_REMOVAL_STATE_CLASS, NODE_REMOVAL_STATE_LABEL, FEATURE_FLAGS,
    StateManagementService) {

    $scope.FORMATS = FORMATS;
    $scope.NODE_REMOVAL_STATE_CLASS = NODE_REMOVAL_STATE_CLASS;
    $scope.NODE_REMOVAL_STATE_LABEL = NODE_REMOVAL_STATE_LABEL;
    $scope.FEATURE_FLAGS = FEATURE_FLAGS;

    $scope.nodes = [];
    $scope.text = $rootScope.text.partitionDetail;

    $scope.mode = 'new';
    $scope.partition = {
      name: null,
      hostName: null,
    };

    $scope.selectedVips = [];
    $scope.vipRange = {
      vipLow: '',
      vipHighSuffix: '',
    };
    var vipHighPrefixDefault = 'xxx.xxx.xxx';

    $scope.vipHighPrefix = vipHighPrefixDefault;

    $scope.viewBoxes = [];
    $scope.nodeIds = [];
    $scope.minNodes = $scope.clusterInfo.supportedConfig.minNodesAllowed;
    $scope.canDropNodes = true;

    $scope.validateForm = false;
    $scope.vipsHighFormatError = false;
    $scope.vipsLowFormatError = false;
    $scope.invalidVipRange = false;

    $scope.errorText = $rootScope.text.partitionDetail.errorText;

    /**
     * gets the Partition information from the API via PartitionService
     *
     * @method     getPartition
     */
    function getPartition() {
      PartitionService.getPartition($stateParams.id).then(
        function getPartitionSuccess(r) {
          // if no partition is returned, there is no partition with
          // the provided id, redirect user to partitions list
          if (!r.data) {
            $state.go('cluster.partitions');

            return;
          }

          angular.extend($scope.partition, r.data);
          getPartitionNodes();
          getVlans();
          getHostMapping();
          angular.extend($scope.nodeIds, r.data.nodeIds);
          angular.extend($scope.selectedVips, r.data.vips);
        },

        evalAJAX.errorMessage
      );
    }

    /**
     * Gets the hosts file information from the API via PartitionService
     *
     * @method     getHostMapping
     */
    function getHostMapping() {
      PartitionService.getHostMapping().then(
        function getHostMappingSuccess(hosts) {
          $scope.hosts = hosts.hosts || [];
          $scope.version = hosts.version;
        }, evalAJAX.errorMessage);
    }

    /**
     * Add a new entry to add host IP mapping
     *
     * @method     addNewHostMapping
     */
    $scope.addNewHostMapping = function addNewHostMapping() {
      $scope.hosts = $scope.hosts || [];
      $scope.hosts.push({
        ip: '',
        domainName: [],
      });
    };

    /**
     * gets the Partition's Nodes from the API via NodesService and
     * ClusterService
     *
     * @method     getPartitionNodes
     */
    function getPartitionNodes() {
      var opts = {
        clusterPartitionIds: $stateParams.id,
      };

      NodeService.getClusterNodes(opts).then(
        function getClusterNodesSuccess(r) {
          for (var i = 0; i < r.data.length; i++) {
            // only one node drop can be in progress at a time.
            // if any nodes are currently marked for removal we
            // won't allow dropping of additional nodes
            if (['kMarkedForRemoval', 'MarkedForRemoval'].includes(r.data[i].removalState)) {
              $scope.canDropNodes = false;
            }
          }

          angular.extend($scope.nodes, r.data);
          ClusterService.getClusterStatus().then(
            function getClusterStatusSuccess(response) {
              $scope.fetchingNodeStatus = true;
              angular.forEach(response.data.nodeStatuses, function(node, index) {
                for (var x = 0, len = $scope.nodes.length; x < len; x++) {
                  if (node.id === $scope.nodes[x].id) {
                    if (!node.services) {
                      node._unreachable = true;
                    }

                    angular.extend($scope.nodes[x], node);
                    break;
                  }
                }
              });
            },

            evalAJAX.errorMessage
          ).finally(
            function getClusterStatusFinally() {
              $scope.fetchingNodeStatus = false;
            }
          );
        },

        evalAJAX.errorMessage
      );
    }

    /**
     * call nodeList(Service) to present a modal for selecting nodes to add
     * to the partition
     *
     * @method     addNodes
     */
    $scope.addNodes = function addNodes() {
      // Prepare the module.
      var options = {
        closeButtonText: $scope.text.buttons.cancel,
        actionButtonText: $scope.text.buttons.labelAddNode,
        title: $scope.text.labelAddNodes,
        text: $scope.text,
        existingNodes: $scope.nodes,
        noFreeNodes: $scope.text.noFreeNodesToAdd,
      };

      // Show the modal with the node list.
      nodeList.showModal({}, options).then(
        function nodeSelectSuccess(selectedNodes) {
          addNodesToPartition(selectedNodes);
          showUnchangedMessage();
        }
      );
    };

    /**
     * calls the API via ViewBoxService to get all of the View Boxes
     * associated with this partition
     *
     * @method     getViewBoxes
     */
    function getViewBoxes() {

      var opts = {
        clusterPartitionIds: $stateParams.id,
      };

      ViewBoxService.getViewBoxes(opts).then(
        function getViewBoxesSuccess(viewBoxes) {
          angular.extend($scope.viewBoxes, viewBoxes);
        },

        evalAJAX.errorMessage
      );

    }

     /**
     * Gets the vlans.
     *
     * @method   getVlans
     */
    function getVlans() {
      VlanService.getVlans().then(
        function getVlansSuccess(vlans) {
          $scope.vlans = availableVlans(vlans, $scope.partition.vlans);
        }, evalAJAX.errorMessage);
    }

    /**
     * Returns the difference of used vlans from all vlans
     * @method   availableVlans
     * @param    {Array}   vlans       The vlans
     * @param    {Array}   usedVlans   The used vlans
     * @return   {Array}   difference array, vlans - usedVlans
     */
    function availableVlans(vlans, usedVlans) {
      var diff = [];
      var usedVlansMap = {};

      angular.forEach(usedVlans, function put(v) {
        usedVlansMap[v.id] = v;
      });

      angular.forEach(vlans, function filter(v) {
        if (!usedVlansMap[v.id]) {
          diff.push(v);
        }
      });

      return diff;
    }

    /**
     * toggle the add vlan to the partition section
     *
     * @method   toggleAddVlan
     */
    $scope.toggleAddVlan = function toggleAddVlan() {
      // Toggle only if there's vlans in ui-select otherwise pops
      // up slider modal to add a new vlan
      if ($scope.vlans.length) {
        $scope.showAddVlan = !$scope.showAddVlan;
      } else {
        $scope.addNewVlan();
      }
    };

    /**
     * Adds a vlan to partition.
     *
     * @method   addVlanToPartition
     */
    $scope.addVlanToPartition = function() {
      if ($scope.partition._vlans) {
        // Add temporary selected vlans to $scope.partition.vlans list
        $scope.partition.vlans = ($scope.partition.vlans || []).concat(
          $scope.partition._vlans);

        $scope.vlans = availableVlans($scope.vlans, $scope.partition._vlans);
        // Clear temporary selected vlans
        $scope.partition._vlans.length = 0;
      }
      $scope.showAddVlan = false;
    };

    /**
     * Adds a new vlan through modal and then fetch all the vlans
     *
     * @method   addNewVlan
     */
    $scope.addNewVlan = function addNewVlan() {
      VlanService.addVlanModal().then(function(vlan) {
        // Add new added vlan in partition's vlan list
        $scope.partition.vlans = $scope.partition.vlans || [];
        $scope.partition.vlans.push(vlan);
      });
    };

    /**
     * present the node list modal so user can select nodes to add to
     * partition
     *
     * @method     addNodesToPartition
     * @param      {Array}  nodes   list of all nodes (?) for the cluster
     */
    var addNodesToPartition = function addNodesToPartition(nodes) {
      var existingNodes = $scope.nodes;
      var len = nodes.length;

      // If this nodeId doesn't already exist in $scope.nodes, then we add it.
      for (var i = 0; i < len; i++) {
        if (existingNodes.indexOf(nodes[i]) === -1) {
          $scope.nodes.push(nodes[i]);
        }
      }

      angular.extend($scope.nodes, existingNodes);
    };

    /**
     * If there is only a single node, then automatically add it to the
     * partition. Only for VirtualRobo.
     *
     * @method     automaticallyAddSingleNode
     */
    function automaticallyAddSingleNode() {
      var opts = {
        includeOnlyUnassignedNodes: true,
      };

      var unassignedNodes = [];

      NodeService.getClusterNodes(opts).then(
        function getNodesSuccess(response) {
          unassignedNodes = response.data || [];

          if (unassignedNodes.length === 1) {
            addNodesToPartition(unassignedNodes);
          }
        },

        evalAJAX.errorMessage
      );
    }

    /**
     * cancels the view/edit/create
     *
     * @method     cancel
     */
    $scope.cancel = function cancel() {
      StateManagementService.goToPreviousState(
        FEATURE_FLAGS.multiplePartitions ?
          'cluster.partitions' : 'cluster.summary');
    };

    /**
     * form submit routing based on new vs edit mode
     *
     * @method     frmPartitionSubmit
     */
    $scope.frmPartitionSubmit = function frmPartitionSubmit(frmPartition) {
      if (frmPartition.$invalid) {
        return;
      }

      if ($scope.mode === 'new') {
        $scope.createPartition();
      } else {
        $scope.savePartition();
      }
    };

    /**
     * if form is valid, submits a request to create a new partition via
     * PartitionService
     *
     * @method     createPartition
     */
    $scope.createPartition = function createPartition() {

      var valid = isValid();

      if (valid) {
        $scope.validateForm = false;

        // Prepare the AJAX call, format the nodeIds as an array.
        var nodeIds = updateLocalNodeIds();
        var data = {
          name: $scope.partition.name,
          nodeIds: nodeIds,
          hostName: $scope.partition.hostName,
          vips: $scope.selectedVips,
        };

        PartitionService.createPartition(data).then(
          function createPartitionSuccess(response) {
            $rootScope.$broadcast('partitions:update');

            if (nodeIds.length >= $scope.minNodes) {
              // assume user wants to add a View Box to the new Partition. Pop a
              // message and redirect.
              cMessage.info({
                titleKey: 'partitionDetails.addViewBoxes.title',
                textKey: 'partitionDetails.addViewBoxes.text',
                persist: true,
                timeout: 8000,
              });
              $state.go('cluster.viewboxes', { partition: response.data.id });
            } else {
              // not enough nodes for a partition, send user back to partitions
              // liting.
              cMessage.info({
                titleKey: 'partitionDetails.partitionSavedButNotActive.title',
                textKey: 'partitionDetails.partitionSavedButNotActive.text',
                timeout: 8000,
              });
              $state.go(FEATURE_FLAGS.multiplePartitions ?
                'cluster.partitions' : 'cluster.summary');
            }
          },

          evalAJAX.errorMessage
        );
      }
    };

    /**
     * Gets the vlan ips.
     *
     * @method   getPartitionVlanIps
     * @param    {Array}   vlans   The vlans
     * @return   {Array}    The vlan ips.
     */
    function getPartitionVlanIps(vlans) {
      var vlanIps = [];

      angular.forEach(vlans, function(vlan) {
        if (vlan.ips) {
          vlanIps = vlanIps.concat(vlan.ips);
        }
      });

      return vlanIps;
    }

    /**
     * if form is valid, submits a request to update the existing partition
     * via PartitionService
     *
     * @method     savePartition
     */
    $scope.savePartition = function savePartition() {
      if (isValid()) {
        $scope.validateForm = false;

        // Prepare the AJAX call, format the nodeIds as an array.
        var nodeIds = updateLocalNodeIds();
        var partitionData = {
          id: $scope.partition.id,
          name: $scope.partition.name,
          nodeIds: nodeIds,
          hostName: $scope.partition.hostName,
          vips: $scope.selectedVips,
          vlans: $scope.partition.vlans,
          vlanIps: getPartitionVlanIps($scope.partition.vlans),
        };

        // Prepare the partition AJAX post.
        PartitionService.updatePartition(partitionData).then(
          function updatePartitionSuccess(response) {
            $rootScope.$broadcast('partitions:update');
            $scope.version = $scope.version + 1;
            PartitionService.updateHostMapping({
              hosts: $scope.hosts,
              version: $scope.version
            });
            $state.go(FEATURE_FLAGS.multiplePartitions ?
              'cluster.partitions' : 'cluster.summary');
          },
          evalAJAX.errorMessage
        );
      }
    };

    /**
     * remove a node from the partition before it has been saved (no impact)
     *
     * @method     removeTempNode
     * @param      {Number}  id      the id for the node to be removed
     */
    $scope.removeTempNode = function removeTempNode(id) {
      for (var i = 0; i < $scope.nodes.length; i++) {
        if ($scope.nodes[i].id == id) {
          $scope.nodes.splice(i, 1);

          return;
        }
      }
    };

    /**
     * remove a vlan from the list of vlans
     *
     * @method     removeVlan
     * @param      {String}  theVIP  to be removed
     */
    $scope.removeVlan = function removeVlan(vlan) {
      for (var index = 0; index < $scope.partition.vlans.length; index++) {
        if ($scope.partition.vlans[index] === vlan) {
          $scope.partition.vlans.splice(index, 1);
          $scope.vlans.unshift(vlan);
          break;
        }
      }
    };


    /**
     * remove a node that has been previously assigned to the partition.
     * this process takes time and only one existing node can be removed at
     * a time
     *
     * @method     dropNode
     * @param      {Object}  node    The node Object to be removed from the
     *                               partition
     */
    $scope.dropNode = function dropNode(node) {
      // Set up the modal.
      var deleteId = node.id;
      var options = {
        closeButtonText: $scope.text.buttons.cancel,
        actionButtonText: $scope.text.buttons.dropNode,
        title: $scope.text.deleteNodeTitle1 + node.id + $scope.text.deleteNodeTitle2 + node.clusterPartitionName + '?',
        contentTitle: $scope.text.deleteNodeContentTitle,
        content: $scope.text.deleteNodeContentText,
        node: node,
        text: $scope.text,
      };

      // Present user challenge modal.
      cModal.showModal({
        templateUrl: 'app/platform/nodes/nodeDropModal.html',
      }, options).then(
        function dropNodeConfirmed() {
          var nodeIds = [];

          // Generate array of nodeIds.
          for (var n in $scope.nodes) {
            if (deleteId !== $scope.nodes[n].id) {
              nodeIds.push($scope.nodes[n].id);
            }
          }

          // TODO: we are unsafely assuming the rest of the form is in a valid state
          var partitionData = {
            id: $scope.partition.id,
            name: $scope.partition.name,
            hostName: $scope.partition.hostName,
            nodeIds: nodeIds,
            vips: $scope.selectedVips,
          };

          // Prepare AJAX post.
          PartitionService.updatePartition(partitionData).then(
            function updatePartitionSuccess(r) {
              $state.go('partition', {
                id: $stateParams.id,
                mode: 'view',
              });
            },

            evalAJAX.errorMessage
          );
        }
      );
    };

    /**
     * manually validates the create/edit form
     *
     * @method     isValid
     * @return     {Boolean}  indicates if form is valid
     */
    function isValid() {
      $scope.validateForm = true;

      if (!$scope.partition.name) {
        return false;
      }

      if (!$scope.partition.hostName) {
        return false;
      }

      if ($scope.clusterInfo._isPhysicalInstall) {
        if ($scope.vipRange.vipLow !== '' || $scope.vipRange.vipHighSuffix !== '') {
          // user input some VIP, it needs to be validated
          $scope.invalidVipRange = true;
          $scope.vipsLowFormatError = true;

          return false;
        }

        if ($scope.selectedVips.length === 0) {
          $scope.invalidVipRange = true;
          $scope.noVIPsError = true;

          return false;
        }
      }

      return true;
    }

    /**
     * provides an array of all nodeIds.
     *
     * @method     updateLocalNodeIds
     * @return     {Array}  all nodeIds
     */
    function updateLocalNodeIds() {
      var nodeIds = [];
      var n = 0;
      var numNodes = $scope.nodes.length;

      for (n; n < numNodes; n++) {
        nodeIds.push($scope.nodes[n].id);
      }

      return nodeIds;
    }

    /**
     * Gets the hostname information.
     *
     * @method   getHostnameInfo
     * @return   {string}   returns the hostname info text based on cluster type.
     */
    $scope.getHostnameInfo = function getHostnameInfo() {
     switch(true) {
      // On-prem edition
      case $rootScope.clusterInfo._isPhysicalInstall:
        return $scope.text.hostnameInfo.default;

      // Cloud edition
      case $rootScope.clusterInfo._isCloudInstall:
        return $scope.text.hostnameInfo.cloudInstall;

      // VM edition
      case $rootScope.clusterInfo._isVirtualRobo:
        return $scope.text.hostnameInfo.robo;
     }
    };

    /**
     * indicates if a node was newly added to the partition. i.e. it hasn't
     * been saved to the API and can be easily removed
     *
     * @method     isNewNode
     * @param      {Number}   id      of the node to check
     * @return     {Boolean}  indicates if this is a new node
     */
    $scope.isNewNode = function isNewNode(id) {
      return ($scope.nodeIds.indexOf(id) === -1);
    };

    /**
     * displays a cMessage informing user that they need to save their
     * changes
     *
     * @method     showUnchangedMessage
     */
    function showUnchangedMessage() {
      cMessage.info({
        titleKey: 'partitionDetails.unsavedChanges.title',
        textKey: 'partitionDetails.unsavedChanges.text',
        timeout: 6000,
      });
    }

    /**
     * adds a VIP range to the partition if range validates
     *
     * @method     addVIPRange
     */
    $scope.addVIPRange = function addVIPRange() {

      var vipRangeList = [];
      var duplicateIPs = [];

      $scope.invalidVipRange = true;
      $scope.vipsLowFormatError = false;
      $scope.vipsHighFormatError = false;

      var resetVipForm = function resetVipForm() {
        $scope.vipRange.vipLow = '';
        $scope.vipHighPrefix = vipHighPrefixDefault;
        $scope.vipRange.vipHighSuffix = '';
        $scope.invalidVipRange = false;
        $scope.vipsLowFormatError = false;
        $scope.noVIPsError = false;
        cFocus('vipRangeLow');
      };

      // Validate low range is valid IP
      if (!cUtils.validateIp($scope.vipRange.vipLow, 'full')) {
        $scope.vipsLowFormatError = true;
      } else {
        // Check for high suffix value
        if ($scope.vipRange.vipHighSuffix !== '') {
          // range of IP addresses
          if (!isNaN($scope.vipRange.vipHighSuffix) && parseInt($scope.vipRange.vipHighSuffix, 10) > parseInt($scope.vipRange.vipLow.split('.')[3], 10) && parseInt($scope.vipRange.vipHighSuffix, 10) <= 255) {
            $scope.vipsHighFormatError = false;
            var newRange = cUtils.buildRange($scope.vipRange.vipLow, $scope.vipRange.vipHighSuffix);

            for (var i = 0; i < newRange.length; i++) {
              var found = false;

              loop2:
                for (var x = 0; x < $scope.selectedVips.length; x++) {
                  if (newRange[i] === $scope.selectedVips[x]) {
                    found = true;
                    break loop2;
                  }
                }

              if (found === false) {
                vipRangeList.push(newRange[i]);
              } else {
                duplicateIPs.push(newRange[i]);
              }
            }

            $scope.selectedVips = vipRangeList.concat($scope.selectedVips);
            resetVipForm();
          } else {
            $scope.vipsHighFormatError = true;
          }
        } else {
          // single IP address
          for (var y = 0; y < $scope.selectedVips.length; y++) {
            if ($scope.selectedVips[y] === $scope.vipRange.vipLow) {
              duplicateIPs.push($scope.vipRange.vipLow);
              break;
            }
          }

          if (!duplicateIPs.length) {
            $scope.selectedVips.unshift($scope.vipRange.vipLow);
            resetVipForm();
          }
        }

        if (duplicateIPs.length) {
          // duplicate IPs were found (and not added)
          // surface a message informing user.
          cMessage.error({
            textKey: 'partitionDetails.duplicateIPs',
            textKeyContext: {
              duplicateIPs: duplicateIPs,
            },
          });
        }
      }
    };

    /**
     * updates the range string displayed in front of the high end of the
     * VIP range input
     *
     * @method     updateRange
     */
    $scope.updateRange = function updateRange() {
      if (!$scope.vipRange.vipLow) {
        $scope.vipHighPrefix = vipHighPrefixDefault;

        return;
      }

      $scope.invalidVipRange = true;
      var low = $scope.vipRange.vipLow;

      var highSuffix = $scope.vipRange.vipHighSuffix;
      var valid = false;

      if (low && low.length >= 1) {
        valid = cUtils.validateIp(low, 'full');
      } else {
        $scope.vipHighPrefix = vipHighPrefixDefault;
      }

      var array = low.split('.');
      var len = array.length;

      if (len <= 3) {
        $scope.vipHighPrefix = low;
      }

      if (valid && parseInt(highSuffix, 10) < 255 && parseInt(highSuffix, 10) > 0) {
        $scope.invalidVipRange = false;
      }

    };

    /**
     * remove a VIP from the list of VIPs and then showUnchangedMessage
     *
     * @method     removeVIP
     * @param      {String}  theVIP  to be removed
     */
    $scope.removeVIP = function removeVIP(theVIP) {
      for (var index = 0; index < $scope.selectedVips.length; index++) {
        if ($scope.selectedVips[index] === theVIP) {
          $scope.selectedVips.splice(index, 1);

          if ($scope.mode === 'edit') {
            showUnchangedMessage();
          }

          break;
        }
      }
    };

    /**
     * initialize the view
     *
     * @method   activate
     */
    function activate() {
      $scope.mode = $stateParams.mode;

      if (!$stateParams.id &&
        $stateParams.mode !== 'new') {
        // Invalid URL pattern, so go back to partitions index.
        return $state.go('cluster.partitions');
      }

      switch ($stateParams.mode) {
        case 'new':
          if ($rootScope.clusterInfo._isVirtualRobo) {
            automaticallyAddSingleNode();
          }

          $state.current.help = 'admin_cluster_partition_new';
          break;

        case 'edit':
          getPartition();
          getViewBoxes();
          $state.current.help = 'admin_cluster_partition_new';
          break;

        case 'view':
          getPartition();
          getViewBoxes();
          $state.current.help = 'admin_cluster_partitions';
          break;
      }
    }

    activate();

  }

})(angular);

import { Component, Input } from '@angular/core';
import { CassandraConnectParams, ProtectionSourceTreeInfo } from '@cohesity/api/v1';
import { isEmpty } from 'lodash-es';

/**
 * Cassandra source details settings components. Used for displaying the
 * registered Cassandra source's configuration values.
 */
@Component({
  selector: 'coh-cassandra-source-details-settings',
  templateUrl: './source-details-settings.component.html'
})
export class CassandraSourceDetailsSettingsComponent {

  /**
   * Input source.
   */
  @Input() source: ProtectionSourceTreeInfo;

  /**
   * Getter for retrieving cassandraParams from source input.
   */
  get cassandraParams(): CassandraConnectParams {
    return this.source &&
      this.source.registrationInfo &&
      this.source.registrationInfo.cassandraParams ?
      this.source.registrationInfo.cassandraParams : null;
  }

  /**
   * Returns the custom name set for this source.
   */
  get sourceCustomName(): string {
    return this.source &&
      this.source.rootNode &&
      this.source.rootNode.customName ?
      this.source.rootNode.customName : '';
  }

  /**
   * Getter for checking if DSE tiered storage has been enabled on the source.
   */
  get isDseTieredStorage(): boolean {
    return this.cassandraParams && this.cassandraParams.isDseTieredStorage;
  }

  /**
   * Getter for checking if DSE solr has been enabled on the source.
   */
  get isDseSolr(): boolean {
    return this.cassandraParams && !isEmpty(this.cassandraParams.solrNodes);
  }
}

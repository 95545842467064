import { BytesDataRendererComponent } from './bytes-data-renderer/bytes-data-renderer.component';
import { DateDataRendererComponent } from './date-data-renderer/date-data-renderer.component';
import { DurationDataRendererComponent } from './duration-data-renderer/duration-data-renderer.component';
import { IconDataRendererComponent } from './icon-data-renderer/icon-data-renderer.component';
import { NumberDataRendererComponent } from './number-data-renderer/number-data-renderer.component';
import { TextDataRendererComponent } from './text-data-renderer/text-data-renderer.component';

export * from './bytes-data-renderer/bytes-data-renderer.component';
export * from './date-data-renderer/date-data-renderer.component';
export * from './duration-data-renderer/duration-data-renderer.component';
export * from './icon-data-renderer/icon-data-renderer.component';
export * from './number-data-renderer/number-data-renderer.component';
export * from './text-data-renderer/text-data-renderer.component';
export * from './base-renderer.component';

export const renderers = [
  BytesDataRendererComponent,
  DateDataRendererComponent,
  DurationDataRendererComponent,
  IconDataRendererComponent,
  NumberDataRendererComponent,
  TextDataRendererComponent,
];

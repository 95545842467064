import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Api } from '@cohesity/api/private';
import {
  LinuxSupportUserBashShellAccessResult,
  PrincipalsServiceApi,
  UpdateLinuxPasswordResult,
} from '@cohesity/api/v1';
import { Observable } from 'rxjs';

import { SuccessResp, SupportServiceApi, UserServiceApi } from '@cohesity/api/v2';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { map } from 'rxjs/operators';
import { GetSupportUserAccessInfoResp } from './models';

export const SUPPORT_USER = 'support';

/**
 * Service to handle update of linux user credentials, sudo and bash access of support user.
 */
@Injectable({
  providedIn: 'root'
})
export class LinuxUserService {
  constructor(private principalsService: PrincipalsServiceApi,
    private supportService: SupportServiceApi,
    private http: HttpClient,
    private irisCtx: IrisContextService,
    private userService: UserServiceApi) { }

  /**
   * Call iris api to update linux user credentials with provided username and password.
   * Current password is optional when password is set for first time.
   *
   * @param     username Username of linux user.
   * @param     newPassword New password of linux user.
   * @param     currentPassword Optional, Current password of linux user.
   * @returns   Observable of UpdateLinuxPasswordResult
   */
  updateLinuxUserCredentials(username: string, newPassword: string,
    currentPassword?: string): Observable<UpdateLinuxPasswordResult> {
    // For support user, use the respective API, for other users, use
    // linux credentials API.
    if (username === SUPPORT_USER) {
      const params: SupportServiceApi.UpdateSupportUserConfigParams = {
        body: {
          newPassword: newPassword,
          // Send blank string for current password to avoid API error when
          // setting up new password.
          currentPassword: currentPassword ? currentPassword : ''
        }
      };
      return this.supportService.UpdateSupportUserConfig(params).pipe(
        map((r: SuccessResp) => ({
            verifyPasswordResult: true,
            message: r.message,
        } as UpdateLinuxPasswordResult)),
      );
    } else {
      // Call v2 API if feature flag  platformBucket3v2ApiMigration is enabled
      // else call v1 API
      if (flagEnabled(this.irisCtx.irisContext, 'platformBucket3v2ApiMigration')) {
        const params: UserServiceApi.UpdateLinuxCredentialsV2Params = {
          body: {
            username,
            newPassword,
            currentPassword: currentPassword ? currentPassword : '',
          }
        };

        return this.userService.UpdateLinuxCredentialsV2(params).pipe(
          map((r: SuccessResp) => ({
            verifyPasswordResult: true,
            message: r.message,
          } as UpdateLinuxPasswordResult)),
        );
      } else {
        const linuxCredentials: PrincipalsServiceApi.UpdateLinuxCredentialsParams = {
          body: {
            linuxUsername: username,
            linuxPassword: newPassword,
          }
        };
        if (currentPassword) {
          Object.assign(linuxCredentials.body, { linuxCurrentPassword: currentPassword });
        }
        return this.principalsService.UpdateLinuxCredentials(linuxCredentials);
      }
    }
  }

  /**
   * Generate bash access ssh key and return it.
   *
   * @returns Observable of LinuxSupportUserBashShellAccessResult.
   */
  generateBashAccessSshKey(): Observable<LinuxSupportUserBashShellAccessResult> {
    return this.principalsService.LinuxSupportUserBashShellAccess({});
  }

  /**
   * Get support user access info.
   *
   * @returns   Observable of GetSupportUserAccessInfoResp object.
   */
  getSupportUserAccessInfo(): Observable<GetSupportUserAccessInfoResp> {
    return this.http.get<any>(Api.private('nexus/security/support_user_access_info'));
  }
}

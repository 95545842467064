import { Injectable } from '@angular/core';
import { TenantConfig, User } from '@cohesity/api/v1';
import { BehaviorSubject, Observable } from 'rxjs';
// TODO: (Nidhi Kulkarni) Remove Lodash dependency
import { find, get } from 'lodash-es';

import { TenantService } from './tenant.service';

/**
 * Defines app tenant config
 */
interface AppTenant extends TenantConfig {
  /**
   * Flag to indicate if tenant is managed on helios
   */
  isManagedOnHelios?: boolean;
}

/**
 * Defines app user
 */
export interface AppUser extends User {
  /**
   * User privileges
   */
  privs?: Record<string, boolean>;

  /**
   * Whether user has Data security privilege
   */
  _isDso?: boolean;

  /**
   * Whether user in admin for local domain
   */
  _isLocalAdmin?: boolean;

  /**
   * Tenant config of current tenant id
   */
  _tenant?: AppTenant;

  /**
   *  TenantId of the logged in user or impersonated organization
   */
  tenantId?: string;

  /**
   * First tenant ID marked as the default tenant id
   */
  _defaultTenantId?: string;

  /**
   * Whether user has access to multiple accounts
   */
  _hasMultipleAccounts?: boolean;

  /**
   * Initials derived from username
   */
  _userNameInitials?: string;

  /**
   * OrgMembership contains the list of all available tenantIds for this
   * user to switch to.
   */
  orgMembership?: null | AppTenant[];
}

/**
 * Data returned by Login API.
 */
interface LoginData {
  /**
   * The group Sids that are assigned to the source.
   */
  groupSids?: null;

  /**
   * Indicates whether node is in cluster
   */
  isNodeInCluster?: boolean;

  /**
   * Privileges of the user's role
   */
  privileges?: string[];

  /**
   * User model
   */
  user?: AppUser;
}

/**
 * This service acts as a central store for login data.
 * It will be the single source of truth for Angular user service and AJS user service.
 * Purpose of this service is to assist seamless migration from AJS user service
 * and the service will be removed once AJS user service is removed.
 */
@Injectable({
  providedIn: 'root',
})
export class UserStoreService {
  /**
   * Login data for the user.
   */
  private readonly _loginData = new BehaviorSubject<LoginData>(undefined);

  /**
   * Observable to expose loginData to methods
   */
  readonly loginData$: Observable<LoginData> = this._loginData.asObservable();

  /**
   * Access login data with this getter
   */
  get loginData(): LoginData {
    return this._loginData.getValue();
  }

  /**
   * Setter to modify the private behaviour subject
   *
   * @param data updated login data
   */
  private set loginData(data: LoginData) {
    this._loginData.next(data);
  }

  /**
   * User value as observable.
   * Note: Ideally this should be derived from login data but we
   * are retaining this separate instance to avoid regressions in MFA workflow in legacy AngularJS user service.
   * This separate instance should be removed once AJS migration is complete.
   */
  private readonly _user = new BehaviorSubject<AppUser>(undefined);

  /**
   * Observable to expose user to user service
   */
  readonly user$: Observable<AppUser> = this._user.asObservable();

  constructor(private tenantService: TenantService) {}

  /**
   * Returns the current user privileges map.
   *
   * @example
   * { CLUSTER_VIEW: true, PROTECTION_VIEW: true }
   *
   * @return  User privileges map.
   */
  getUserPrivs(): AppUser['privs'] {
    return this.loginData?.user?.privs || {};
  }

  /**
   * Returns the tenantId of the logged in user or impersonated organization
   * tenantId or if the tenant user is a part of multiple organization, then
   * get the switched tenantId.
   *
   * @return The Tenant ID
   */
  getUserTenantId(): string | undefined {
    return this.loginData?.user?.tenantId;
  }

  /**
   * Determines if logged in user is restricted user or not.
   *
   * @return True if restricted user, False otherwise.
   */
  isRestrictedUser(): boolean {
    return !!this.loginData?.user?.restricted;
  }

  /**
   * Determines if the logged in user is Self Service based user. Such users
   * log in through MSFT OpenID Connect workflow and have Graph UUID present
   * as an attribute on the user itself.
   *
   * @returns True iff logged in user is Self Service based.
   */
  isSelfServiceUser(): boolean {
    return !!this.loginData?.user?.msftUserInfo?.graphUuid;
  }

  /**
   * Convert a domain name to lowercase because the backend hasn't
   * standardized this properly.
   *
   * TODO(David): Remove this code and its references after backend has fixed
   * this issue. No ETA. Original JIRA ticket:
   * https://cohesity.atlassian.net/browse/ENG-46283
   *
   * @param domain domain name
   * @return domain name
   */
  sanitizeDomain(domain: string): string {
    return domain && domain !== 'LOCAL' ? domain.toLowerCase() : domain;
  }

  /**
   * user deserializer to transform/convert user response
   * use this fn to alter the structure create new structure
   * or filter out un-necessary keys in user object.
   * Note: This method was copied from AJS user service
   *
   * @param response user object response object
   * @return loginData new transformed user object
   */
  userDeserializer(response: LoginData): LoginData {
    const loginData: LoginData = response || {};
    loginData.user = structuredClone(loginData.user) || {};

    loginData.user.domain = this.sanitizeDomain(loginData.user.domain);

    // convert permission array into map
    loginData.user.privs = (loginData.privileges || []).reduce(function reducePrivs(privs, priv) {
      privs[priv] = true;
      return privs;
    }, {});

    loginData.user._isDso = !!loginData.user.privs.DATA_SECURITY;
    loginData.user._isLocalAdmin = loginData.user.username === 'admin' && loginData.user.domain === 'LOCAL';

    if (loginData.user.orgMembership) {
      // orgMembership contains list all the tenants a user has access to
      // if it is greater than 1, user has access to multiple organizations
      loginData.user._hasMultipleAccounts = get(loginData.user.orgMembership, 'length', 0) > 1;

      loginData.user._tenant = find(loginData.user.orgMembership, ['tenantId', loginData.user.tenantId]);

      // Save the first tenantId as defaultTenantId and
      // keep it same on all iterations
      if (!this.tenantService.impersonatedTenantId) {
        loginData.user._defaultTenantId = loginData.user._defaultTenantId || loginData.user.tenantId;
      }
    } else {
      loginData.user._hasMultipleAccounts = false;
      delete loginData.user._tenant;
      delete loginData.user._defaultTenantId;
    }

    // compute user name initials
    // eg. user with name "helloWord" will have initials "he"
    if (loginData.user.username) {
      loginData.user._userNameInitials = loginData.user.username.slice(0, 2);
    }

    return loginData;
  }

  /**
   * Update Login data and user in the store
   *
   * @param loginData Updated login data
   */
  updateLoginData(loginData: LoginData): void {
    this.loginData = this.userDeserializer(loginData);
    this.updateUser(this.loginData?.user);
  }

  /**
   * Updates value of the user observable
   *
   * @param user Updated user
   */
  updateUser(user: AppUser): void {
    this._user.next(user);
  }

  /**
   * Clear login data and user on session destroy
   */
  clearLoginData(): void {
    this.loginData = undefined;
    this.updateUser(undefined);
  }

  /**
   * Determines if this is the logged in user
   *
   * @method     isThisLoggedInUser
   * @param      user    The user
   * @return     True if this logged in user, False otherwise.
   */
  isThisLoggedInUser(user: AppUser) {
    return user.sid === this.loginData?.user?.sid;
  }

  /**
   * Update login data with updated/latest user object if its for loggedin user.
   *
   * @param updatedUser      updated user information.
   */
  updateLogindataUser(updatedUser: AppUser): void {
    let newLoginData: LoginData;
    // If the updated user is the logged-in user, update loginData
    if (this.isThisLoggedInUser(updatedUser)) {
      newLoginData = { ...structuredClone(this.loginData), user: updatedUser };
      this.updateLoginData(newLoginData);
    }
  }
}

import { Component } from '@angular/core';
import { isNumber } from 'lodash-es';
import { filter, startWith } from 'rxjs/operators';
import { ProtectionItemName } from 'src/app/modules/protection/protection-builder/models';
import { JobType, JobStreamConcurrencyMap } from 'src/app/shared/constants';
import { Concurrency } from 'src/app/shared/settings-list-items/settings-list-concurrency/concurrency-form.model';

import { BaseProtectionBuilderComponent } from '../../base-protection-builder/base-protection-builder.component';

/**
 * Specify concurrency per stream in settings for a protection group.
 */
@Component({
  selector: 'coh-settings-list-stream-concurrency',
  template: `
    <coh-settings-list-concurrency [formControl]="formControl" [jobType]="jobType">
    </coh-settings-list-concurrency>
  `
})
export class SettingsListStreamConcurrencyComponent extends BaseProtectionBuilderComponent<Concurrency, any> {

  /**
   * Specifies the protection group Job type.
   */
  jobType: JobType = 'Backup';

  /**
   * Form control init.
   */
  initFormControl() {
    if (this.protectionGroup && isNumber(this.protectionGroup.concurrency)) {
      this.formControl.setValue({ value: this.protectionGroup.concurrency });
    } else {
      const sourceFormControl = this.formGroup.parent.get(ProtectionItemName.Source);

      if (sourceFormControl) {
        sourceFormControl.valueChanges.pipe(
          this.untilDestroy(),
          startWith(sourceFormControl.value),
          filter(value => !!value),
        ).subscribe(value => {
          const environment = value.protectionSource.environment;
          this.formControl.setValue({ value: JobStreamConcurrencyMap[environment] });
        });
      }
    }
  }
}

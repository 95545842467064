<form [formGroup]="unregisterForm">
  <h2 mat-dialog-title translate>unregisterSource</h2>
  <div mat-dialog-content>
    <!-- Show spinner if showSpinner is true -->
    <cog-spinner  *ngIf="showSpinner"></cog-spinner>

    <ng-container *ngIf="!showSpinner && isAuthPending()">
      <!-- Use innerHTML for non-EXPRESS or disabled flag -->
        <!-- Check for express and Azure -->
        <ng-container  *ngIf="isExpressAndAzure(); else normalContent">
          <p>{{ 'dms.unregisterAzureSource' | translate }}</p>
        </ng-container>

        <ng-template #normalContent>
          <p [innerHTML]="'dms.unregisterSourceName' | translate:{ name: name, type: type }" class="margin-bottom"></p>
        </ng-template>

      <!-- To be added when rigel unregister is implemented. -->
      <!-- <mat-checkbox cogDataId="remove-rigel-checkbox" formControlName="removeRigel">
        <span [innerHTML]="'removeRigelConnectionWithSource' | translate:{rigelName:rigelName}">
        </span>
      </mat-checkbox> -->
      <mat-form-field [hideRequiredMarker]="true">
        <input cogDataId="confirm-unregister-source"
          required
          matInput
          [placeholder]="'typeYesToConfirm' | translate"
          formControlName="confirmUnregister">
        <mat-error id="confirm-required-invalid" *ngIf="unregisterForm.controls.confirmUnregister.errors?.required">
          {{'errors.required' | translate}}
        </mat-error>
        <mat-error id="confirm-pattern-pattern" *ngIf="unregisterForm.controls.confirmUnregister.errors?.pattern">
          {{'mustInputYes' | translate}}
        </mat-error>
      </mat-form-field>
    </ng-container>
    <div class="progress-container">
      <ng-container *ngIf="isExpressAndAzure()">
        <ng-container *ngTemplateOutlet="statusCard; context: expRegService.getStepStatusDetailsWithMessage(ExpressRegistrationStep.AUTHORIZATION, unRegistrationStatus[ExpressRegistrationStep.AUTHORIZATION], unRegistrationStatus)">
        </ng-container>
        <ng-container *ngIf="!isAuthPending()">
          <ng-container *ngTemplateOutlet="statusCard; context: expRegService.getStepStatusDetailsWithMessage(ExpressRegistrationStep.DELETE_SOURCE, unRegistrationStatus[ExpressRegistrationStep.DELETE_SOURCE], unRegistrationStatus)">
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <mat-dialog-actions align="end">
    <button mat-stroked-button cogCancelButton [mat-dialog-close]="false"
      [disabled]="loading"
      cogDataId="cancel-unregister-btn">
      {{'cancel' | translate}}
    </button>
    <button mat-flat-button cogSubmitButton
      [loading]="loading"
      [disabled]="loading || !unregisterForm.valid"
      cogDataId="unregister-btn"
      color="warn" (click)="unregister()">
      {{'unregister' | translate}}
    </button>
  </mat-dialog-actions>
</form>


<!-- Template for displaying a status card with a title, message, and status icon. -->
<ng-template #statusCard let-data>
  <mat-card class="margin-top padding-top-sm padding-bottom-sm">
    <div class="row">
      <div class="col-xs-10">
        <h4 class="no-margin">{{ data.title | translate }}</h4>
        {{ data.message | translate }}
      </div>
      <div align="center" class="col-xs-2 margin-top-sm">
        <cog-spinner *ngIf="data.iconState === 'in_progress' || data.iconState === 'pending'" size="sm"></cog-spinner>
        <cog-icon *ngIf="data.iconState === 'success'" shape="helix:status-success-alt!success"></cog-icon>
        <cog-icon *ngIf="data.iconState === 'failure'" shape="helix:status-error-alt!critical"></cog-icon>
      </div>
    </div>
  </mat-card>

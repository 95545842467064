import { Injectable } from '@angular/core';
import { DataTreeFilter, DataTreeFilterUtils, NavItem } from '@cohesity/helix';
import { flagEnabled, IrisContextService, isDmsScope } from '@cohesity/iris-core';
import { FilterOption } from '@cohesity/iris-source-tree';
import { TranslateService } from '@ngx-translate/core';
import { get } from 'lodash-es';
import { UserService } from 'src/app/core/services';
import { Environment, HostType } from 'src/app/shared/constants';

import { VmSourceDataNode } from './vm-source-data-node';
import { VmSourceTreeService } from './vm-source-tree.service';

@Injectable({
  providedIn: 'root',
})
export class VmwareSourceTreeService extends VmSourceTreeService {
  /**
   * Additional filter options related to vmware
   */
  vmAttributeOptions: FilterOption[];

  /**
   * Filter options for host type.
   */
  hostFilterOptions: FilterOption[];

  /**
   * Set additional filters specific to vmware source
   */
  constructor(translate: TranslateService, private userService: UserService, irisContext: IrisContextService) {
    super(Environment.kVMware, translate, irisContext);
    this.vmAttributeOptions = [
      {
        id: 'vmware-tools-found',
        label: 'cSourceTree.filterText.vmwareToolsFound',
        filter: this.vmwareToolsFoundFilter,
        icon: 'helix:object-vmtools!accent',
      },
      {
        id: 'mssql-servers',
        label: 'cSourceTree.filterText.sql',
        filter: this.msSqlServersFilter,

        // Disable the sql filter for DMS users, since SQL is not yet supported there
        canAccess: context => !isDmsScope(context),
      },
      {
        id: 'vcenter-migrated',
        label: 'cSourceTree.filterText.vcenterMigrated',
        filter: this.migratedVmFilter,
        canAccess: context => !isDmsScope(context),
      },
      {
        id: 'cohesity-agent-installed',
        label: 'cohesityAgentInstalled',
        filter: this.getAgentFilter(true),
        canAccess: context => !isDmsScope(context),
      },
      {
        id: 'cohesity-agent-not-installed',
        label: 'cohesityAgentNotInstalled',
        filter: this.getAgentFilter(false),
        canAccess: context => !isDmsScope(context),
      },
      {
        id: 'saas-connector-vm',
        label: 'saasConnectorVm',
        filter: this.saasConnectorFilter(true),
        canAccess: context => flagEnabled(context, 'vmwareVMC'),
      },
    ].filter(Boolean);
    this.filters.addFilterGroup({
      id: 'vmware-filters',
      label: 'vmAttributes',
      options: this.vmAttributeOptions,
    });

    // List of host filters for Vmware.
    this.hostFilterOptions = [
      {
        id: HostType.kLinux,
        label: 'linux',
        filter: this.filterByHost({ isLinux: true }),
      },
      {
        id: HostType.kWindows,
        label: 'windows',
        filter: this.filterByHost({ isWindows: true }),
      },
    ];

    this.filters.addFilterGroup({
      id: 'hostFilter',
      label: 'hostType',
      options: this.hostFilterOptions,
    });
  }

  /**
   * Optional method to provide context menu items for a node. This is used in the source tree view
   * component.
   *
   * @param   The node to get nav items for.
   * @returns 0 or more nav item menu items.
   */
  getContextMenuItems(node: VmSourceDataNode): NavItem[] {
    const items: NavItem[] = [];

    // TODO: Need to figure out the correct way to determine node owner information here.
    // editing node setting is not allowed for the user who is not from the
    // organization owning the source.

    if (
      !this.userService.privs.PROTECTION_SOURCE_MODIFY ||
      node.type === 'kStandaloneHost' ||
      node.type !== 'kVirtualMachine' ||
      node.hasConnectionStateProblem
    ) {
      return items;
    }

    switch (true) {
      case node.isApplicationHost:
        // TODO: Add application modify action
        // TODO: Add application unregister action
        // Applications for vmware currently include sql and exchange
        break;

      default:
        items.push({
          displayName: this.translate.instant('registerApplications'),
          icon: 'add',
          state: 'sql-register',
          stateParams: {
            entityId: node.protectionSource.id,
          },
        } as any);
    }
    return items;
  }

  /**
   * Filter callback function to filters the list of VMs based on the selected host type.
   *
   * @param filterConfig Selected value for host type.
   * @returns A DataTreeFilter that filters the node tree.
   */
  filterByHost: (filterConfig: any) => DataTreeFilter<any> = filterConfig => (nodes: VmSourceDataNode[]) =>
    DataTreeFilterUtils.searchFilter(
      nodes,
      this.treeControl,
      node =>
        node.envSource.type === 'kVirtualMachine' &&
        ((filterConfig.isLinux && node.envSource.hostType === 'kLinux') ||
          (filterConfig.isWindows && node.envSource.hostType === 'kWindows')),
      false,
      true
    );

  /**
   * Filters a list of nodes which have vmware tools found
   *
   * @param    nodes         The list of nodes to filter.
   * @return   The filtered list.
   */
  vmwareToolsFoundFilter: DataTreeFilter<VmSourceDataNode> = (nodes: any[]) =>
    DataTreeFilterUtils.searchFilter(
      nodes,
      this.treeControl,
      (node: VmSourceDataNode) => get(node.envSource, 'toolsRunningStatus') === 'kGuestToolsRunning',
      false,
      true
    );

  /**
   * Filters a list of nodes which are of ms sql server type.
   *
   * @param    nodes         The list of nodes to filter.
   * @return   The filtered list.
   */
  msSqlServersFilter: DataTreeFilter<VmSourceDataNode> = (nodes: any[]) =>
    DataTreeFilterUtils.searchFilter(
      nodes,
      this.treeControl,
      (node: VmSourceDataNode) =>
        !!(
          (node.data.registrationInfo?.environments || []).find(env => env === 'kSQL')
        ),
      false,
      true
    );

  /**
   * Creates a filter to check for nodes with or without cohesity agents.
   *
   * @param isAgentInstalled whether to match on nodes with agents or nodes without
   * @returns A data tree filter.
   */
  getAgentFilter(isAgentInstalled: boolean): DataTreeFilter<VmSourceDataNode> {
    return (nodes: any[]) => DataTreeFilterUtils.searchFilter(
      nodes,
      this.treeControl,
      (node: VmSourceDataNode) => node.isAgentInstalled === isAgentInstalled,
      false,
      true
    );
  }

  /**
   * Creates a filter to select nodes which are SaaS Connector .
   *
   * @param isSaasConnector whether to match on nodes which are Saas Connector
   * @return A data tree filter.
   */
  saasConnectorFilter(isSaasConnector: boolean): DataTreeFilter<VmSourceDataNode> {
    return (nodes: any[]) => DataTreeFilterUtils.searchFilter(
      nodes,
      this.treeControl,
      (node: VmSourceDataNode) => node.isSaasConnector === isSaasConnector,
      false,
      true
    );
  }

  /**
   * Only expand certain node types by default.
   *
   * @param   treeNode   The treenode to check.
   * @return  True if the node should be expanded by default.
   */
  shouldExpandNodeOnLoad(treeNode: VmSourceDataNode): boolean {
    return (
      super.shouldExpandNodeOnLoad(treeNode) ||
      ['kvCloudDirector', 'kVCenter'].includes(treeNode.type) ||
      (treeNode.type === 'kFolder' && treeNode.envSource['folderType'] !== 'kVMFolder')
    );
  }

  /**
   * Filter callback function to show the migrated vm of a vcenter.
   *
   * @param    nodes         The list of nodes to filter.
   * @return   The filtered list.
   */
  migratedVmFilter: DataTreeFilter<VmSourceDataNode> = (nodes: any[]) =>
    DataTreeFilterUtils.searchFilter(
      nodes,
      this.treeControl,
      (node: VmSourceDataNode) => (node.protectionSource.vmWareProtectionSource as any).vmLinkingInfo?.isMigrated
    );
}

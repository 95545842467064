import { CassandraIndexedObject, CdpObjectInfo, MongoIndexedObject, ObjectSnapshotsInfo, ProtectedObject } from '@cohesity/api/v2';
import { Environment } from 'src/app/shared';

import { NoSqlIndexedObject, ObjectTypeToEntityTypeMap } from './nosql-common';
import { RestoreSearchResult } from './restore-search-result';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';

/**
 * This class provides search results information for NoSql objects.
 *
 * @example
 * const searchResult = new NoSqlSearchResult(object);
 */
export class NoSqlObjectSearchResult implements RestoreSearchResult {
  /**
   * Static property for the result type. Which can be referenced by other classes.
   */
  static readonly searchResultType = 'object';

  get environment(): string {
    return this.noSqlIndexedObject.sourceInfo.environment;
  }

  /**
   * Gets a unique id for a object based on the name, path, object id and protection group id.
   * This is only used internally.
   */
  get id(): string {
    return `${this.name}:${this.path}:${this.objectId}:${this.protectionGroupId}`;
  }

  get name(): string {
    return this.noSqlIndexedObject.name;
  }

  get objectType(): string {
    return ObjectTypeToEntityTypeMap[this.environment][this.noSqlIndexedObject.type];
  }

  get objectId(): number {
    return this.noSqlIndexedObject.sourceInfo.id;
  }

  get objectName(): string {
    return this.noSqlIndexedObject.sourceInfo.name;
  }

  /**
   * Specifies the id of the indexed object.
   */
  get indexedObjectId(): string {
    return this.noSqlIndexedObject.id;
  }

  /**
   * Gets the fully qualified name of the object.
   */
  get path(): string {
    return this.noSqlIndexedObject.path;
  }

  /**
   * Gets the group id from the latest snapshot info.
   */
  get protectionGroupId(): string {
    return this.noSqlIndexedObject.protectionGroupId;
  }

  get protectionGroupName(): string {
    // Returning 'Deleted' as a safety measure for testing. Need a better
    // way of handling this scenario.
    return this.protectionGroupsMap.get(this.protectionGroupId) || 'Deleted';
  }

  /**
   * By default, objects can be selected along with other object types.
   */
  requiresExclusiveSelection = false;

  get restoreTimestampUsecs(): number {
    return null;
  }

  readonly resultType = NoSqlObjectSearchResult.searchResultType;

  get sourceEnvironment(): string {
    return this.environment;
  }

  get sourceId(): number {
    return this.noSqlIndexedObject.sourceInfo.sourceId || null;
  }

  get parentSourceName(): string {
    return this.noSqlIndexedObject.sourceInfo.name;
  }

  get storageDomainId(): number {
    return this.noSqlIndexedObject.storageDomainId;
  }

  /**
   * Specifies the CDP related information for a given object.
   */
  get cdpObjectInfo(): CdpObjectInfo {
    let cdpObjectInfo;
    if (this.environment === Environment.kMongoDB) {
      const indexedObject = this.noSqlIndexedObject as MongoIndexedObject;
      if (indexedObject?.cdpInfo?.cdpEnabled !== undefined) {
        cdpObjectInfo = indexedObject.cdpInfo;
      }
    }
    return cdpObjectInfo;
  }

  /**
   * Specifies the Keyspace type for Cassandra.
   */
  get keyspaceType(): string {
    return (this.noSqlIndexedObject as CassandraIndexedObject).keyspaceType || '';
  }

  constructor(
    private noSqlIndexedObject: NoSqlIndexedObject,
    private protectionGroupsMap: Map<string, string>,
  ) {}
}

/**
 * This class provides search results information for NoSql protection groups.
 *
 * @example
 * const searchResult = new NoSqlProtectionGroupSearchResult(object);
 */
export class NoSqlProtectionGroupSearchResult implements RestoreSearchResult {
  /**
   * Static property for the result type. Which can be referenced by other classes.
   */
  static readonly searchResultType = 'protectionGroup';

  get environment(): string {
    return this.object.sourceInfo.environment;
  }

  get id(): string {
    return `${this.name}:${this.protectionGroupId}:${this.sourceId}:${this.objectId}`;
  }

  get name(): string {
    return this.protectionGroupName;
  }

  get objectId(): number {
    return this.object.id;
  }

  get objectName(): string {
    return this.latestSnapshotInfo.protectionGroupName;
  }

  /**
   * Object type will always be kCluster for protection groups.
   */
  get objectType(): string {
    return 'kCluster';
  }

  /**
   * Gets the group id from the latest snapshot info.
   */
  get protectionGroupId(): string {
    return this.latestSnapshotInfo.protectionGroupId;
  }

  get protectionGroupName(): string {
    return this.latestSnapshotInfo.protectionGroupName;
  }

  /**
   * Gets the group run id from the latest snapshot info.
   */
  get protectionRunId(): string {
    return this.latestSnapshotInfo.protectionRunId;
  }

  /**
   * Specifies the id of the indexed object.
   */
  get indexedObjectId(): string {
    return `${this.name}:${this.protectionGroupId}:${this.sourceId}:${this.objectId}`;
  }

  /**
   * By default, objects can be selected along with other objec types.
   */
  requiresExclusiveSelection = false;

  get restoreTimestampUsecs(): number {
    return this.latestSnapshotInfo.protectionRunStartTimeUsecs;
  }

  readonly resultType = NoSqlProtectionGroupSearchResult.searchResultType;

  get storageDomainId(): number {
    return this.latestSnapshotInfo.storageDomainId;
  }

  get sourceEnvironment(): string {
    return this.object.sourceInfo.environment || this.environment;
  }

  get sourceId(): number {
    return this.object.sourceId;
  }

  get keyspaceType(): string {
    // Keyspace Type will be empty in case of Protection group search result.
    return null;
  }

  get parentSourceName(): string {
    return this.object.sourceInfo && this.object.sourceInfo.name;
  }

  /**
   * Convenience getter to return the first item in the last snapshots info array.
   */
  private get latestSnapshotInfo(): ObjectSnapshotsInfo {
    return this.object.latestSnapshotsInfo[0];
  }

  /**
   * Specifies the CDP related information for a given object.
   */
  get cdpObjectInfo(): CdpObjectInfo {
    if (this.environment === Environment.kMongoDB &&
      flagEnabled(this.irisContext?.irisContext, 'mongoDBSourceLevelPITR')) {
      const mongoDbEntityParams = this.object?.mongoDBParams;
      // Checking if current ProtectionGroup's Id is present in the
      // list of cdp enabled protectionGroups for source
      if (mongoDbEntityParams?.cdpInfo?.cdpEnabled !== undefined &&
          mongoDbEntityParams?.cdpInfo?.protectionGroupIds?.
          includes(this.protectionGroupId)) {
          return mongoDbEntityParams.cdpInfo as CdpObjectInfo;
      }
    }
    return undefined;
  }

  constructor(
    private object: Required<ProtectedObject>,
    private irisContext?: IrisContextService) {
  }
}

/**
 * Combined type for the object and protection group search results.
 */
export type NoSqlSearchResult = NoSqlObjectSearchResult | NoSqlProtectionGroupSearchResult;

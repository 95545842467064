import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { ProtectedObjectInfo, ProtectionGroupRuns } from '@cohesity/api/v2';
import { DataFilterValue, DateFilterRange, FiltersComponent } from '@cohesity/helix';
import { AjaxHandlerService, AutoDestroyable, FilterConfig, FilterValuesList } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { UIRouterGlobals } from '@uirouter/core';
import { isEqual, isNil } from 'lodash-es';
import moment, { Moment } from 'moment';
import { ObservableInput } from 'ngx-observable-input';
import { combineLatest, merge, Observable, of, race, throwError } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { Environment, TaskStatus } from 'src/app/shared';

import { IProtectionRun } from '../../models/common.models';
import { ProtectionGroup } from '../../models/protection-group.models';
import { ProtectionRun } from '../../models/protection-run.models';
import { GetProtectionGroupRunsParams, ProtectionRunService } from '../../services/protection-run.service';
import { ProtectionRunsService, ViewState } from '../../services/protection-runs.service';
import { DatePipeWrapper } from 'src/app/shared/pipes';

/**
 * Parameters to help creating the data structure for cog-value-property-filter [filterValues]
 */
const filterSettings: FilterConfig[] = [
  {
    name: 'backupType',
    prefix: 'enum.jobRunType.',
    values: ['kFull', 'kIncremental', 'kHydrateCDP', 'kLog',
      'kSystem', 'kStorageArraySnapshot'],
  },
  {
    name: 'tags',
    prefix: '',
    values: [],
  },
];

/**
 * @description
 * Protection Runs with calendar and list.
 * This component can use either Protection Group or Object instances as input. Base on `group$` or `object$`
 * presence this component will load either Protection Group or Object runs.
 */
@Component({
  selector: 'coh-protection-runs',
  templateUrl: './protection-runs.component.html',
  styleUrls: ['./protection-runs.component.scss'],
  providers: [ProtectionRunsService],
})
export class ProtectionRunsComponent extends AutoDestroyable implements AfterViewInit, OnInit {
  /**
   * Protection Group instance.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @ObservableInput() @Input('group') group$: Observable<ProtectionGroup>;

  /**
   * Protected Object instance.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @ObservableInput() @Input('object') object$: Observable<ProtectedObjectInfo>;

  /**
   * Table filters component reference.
   */
  @ViewChild('tableFilters', { static: true })
  private tableFilters: FiltersComponent;

  /**
   * Calendar filters component reference.
   */
  @ViewChild('calendarFilters', { static: true })
  private calendarFilters: FiltersComponent;

  /**
   * Base protection group runs API request params.
   */
  private readonly runsReqParams: GetProtectionGroupRunsParams = {
    id: '',
    includeObjectDetails: false,
  };

  /**
   * Run API limit in table view.
   */
  tableRunsLimit = 360;

  /**
   * Run API limit in calendar view.
   */
  calendarRunsLimit = 1000;

  /**
   * Total number of runs.
   */
  totalRunsCount = 0;

  /**
   * Runs for selected calendar view date.
   */
  selectedDayRuns$: Observable<IProtectionRun[]>;

  /**
   * Calendar dates will be marked with red circle in calendar.
   */
  markedDates$: Observable<Date[]>;

  /**
   * Calendar dates that have runs.
   */
  activeDates$: Observable<Date[]>;

  /**
   * Sets view state between table or calendar.
   */
  set viewState(viewState: ViewState) {
    this.runsService.viewState = viewState;
  }

  /**
   * Returns current view state - table or calendar.
   */
  get viewState(): ViewState {
    return this.runsService.viewState;
  }

  /**
   * Dispatches event when viewState changes.
   */
  viewState$: Observable<ViewState>;

  /**
   * Table source run items.
   */
  tableRuns$: Observable<IProtectionRun[]>;

  /**
   * Date range for table date picker filter.
   */
  tableDateRange$: Observable<DateFilterRange<Moment>>;

  /**
   * Observable of selected calendar date in calendar view.
   */
  calendarDate$: Observable<Moment>;

  /**
   * Selected run in calendar view.
   */
  selectedRun: IProtectionRun;

  /**
   * Protection Group or Protected Object environment.
   */
  environment$: Observable<Environment>;

  /**
   * Show data read label.
   */
  hasDataRead$: Observable<boolean>;

  /**
   * Show data write label.
   */
  hasDataWritten$: Observable<boolean>;

  /**
   * Show Banner with warning messages.
   */
  groupMessage: string;

  /**
   * Filters config for `FiltersComponent`.
   */
  filterValuesList: FilterValuesList = {};

  constructor(
    private ajaxService: AjaxHandlerService,
    private runService: ProtectionRunService,
    private runsService: ProtectionRunsService,
    private uiRouterGlobals: UIRouterGlobals,
    private translate: TranslateService,
    private cohDate: DatePipeWrapper
  ) {
    super();
  }

  /**
   * Init component and load initial data.
   */
  ngOnInit() {
    const { groupId } = this.uiRouterGlobals.params;

    const group$ = this.group$.pipe(filter(group => !isNil(group)));
    const object$ = this.object$.pipe(filter(object => !isNil(object)));

    // Initializing tags filter values for externally triggered backup.
    group$.pipe(this.untilDestroy()).subscribe((group) => {
      if (group.isExternallyTriggered) {
        filterSettings[1].values = group.getEnvParams().externallyTriggeredJobParams.tags;
      }
      if (group.environment === Environment.kCassandra && group.getEnvParams().isLogBackup) {
        this.groupMessage =  group.lastRun?.getBackupStatusMessage();
      }
    });

    // Compare last run start date with the default table start date.
    // This needs to run only once on component init to update the default date range.
    combineLatest([group$, this.runsService.tableDateRange$]).pipe(
      take(1),
      this.untilDestroy(),
    ).subscribe(([group, tableDateRange]) => {
      const lastRunStartDate = moment(group.lastRun?.startDate);
      const { start, end, timeframe } = tableDateRange;

      // If the last run is before the default start date,
      // set the start date to the last run start date.
      if (lastRunStartDate.isBefore(start)) {
        this.runsService.tableDateRange = { start: lastRunStartDate, end, timeframe};
      }
    });

    this.filterValuesList = new FilterValuesList(filterSettings, this.translate);
    const groupEnv$ = group$.pipe(
      map(({ environment }) => environment as Environment),
      shareReplay(1)
    );

    this.runsReqParams.id = groupId;

    const objectEnv$ = object$.pipe(
      map(({ environment }) => environment as Environment),
      shareReplay(1)
    );

    this.environment$ = race(groupEnv$, objectEnv$);

    this.viewState$ = this.runsService.viewState$;

    this.hasDataWritten$ = combineLatest([ this.environment$, this.group$ ])
      .pipe(
        map(([ env, group ]) =>
          env !== Environment.kOracle && !group?.isRemotelyManagedGroup && group?.isActive)
      );

    this.hasDataRead$ = this.group$.pipe(map(group => !group?.isRemotelyManagedGroup));

    // TODO(alex): refactor calendar and table view into separate components
    this.initCalendar();
    this.initTable();
  }

  /**
   * Initialized calendar observables.
   */
  private initCalendar() {
    this.calendarDate$ = this.runsService.calendarDate$;

    const runs$ = combineLatest([
      this.runsService.reloadCalendar$,
      this.runsService.calendarRange$,
      this.calendarFilters.filterValues$,
    ]).pipe(
      map(([, { start: startDate, end: endDate }, appliedFilters]) =>
        appliedFilters.reduce((params, appliedFilter) => {
          if (appliedFilter.key === 'runType') {
            params.runTypes = appliedFilter.value.map(v => v.value);
            this.runsService.saveStateParams({ runType: params.runTypes[0]});
          }

          return params;
        }, {
          ...this.runsReqParams,
          startTimeUsecs: startDate.valueOf() * 1000,
          endTimeUsecs: endDate.valueOf() * 1000,
        })),
      // use null to display spinner before API resolves
      switchMap(params => merge(of(null), this.getRuns({ ...params, numRuns: this.calendarRunsLimit }))),
      catchError(e => {
        this.ajaxService.errorMessage(e);
        return throwError(null);
      }),
      shareReplay(1)
    );

    this.activeDates$ = runs$.pipe(
      map(runs => (runs || []).map(run => run.startDate).sort((d1, d2) => d2.getTime() - d1.getTime())),
      tap((activeDates: Date[]) => {
        let nextDate = this.runsService.calendarDate;
        if (activeDates.length) {
          // find date in list of run dates that matches already selected date
          // or just use the last run's start date in descending array or runs
          const active =
            activeDates.find(d => moment(d).isSame(moment(nextDate), 'date')) || activeDates[0];

          if (active) {
            nextDate = moment(active);
          }
        }

        this.runsService.calendarDate = nextDate;
      }),
      shareReplay(1)
    );

    this.selectedDayRuns$ = combineLatest([runs$, this.runsService.calendarDate$]).pipe(
      map(([runs, date]) => {
        if (Array.isArray(runs) && date) {
          return (
            runs
              // filter runs that are within selected calendar day
              .filter((run: IProtectionRun) => moment(run.startDate).isSame(date, 'date'))
              // sort runs by start time in descending order
              .sort((r1, r2) => r2.startTimeUsec - r1.startTimeUsec)
          );
        }

        return null;
      }),
      // select run based on runId from state params
      tap(runs => {
        if (runs) {
          const { runId } = this.uiRouterGlobals.params;
          this.runListSelectionChanged(runs.find(run => run.runId === runId) || runs[0]);
        }
      }),
      shareReplay(1)
    );

    this.markedDates$ = runs$.pipe(
      map(runs =>
        (runs || []).reduce((badRunDates: Date[], run: IProtectionRun) => {
          const backupFailed = run.status === 'Failed';

          let copyTasks = [];

          if (run.replicationStats) {
            copyTasks = copyTasks.concat(run.replicationStats);
          }

          if (run.archivalStats) {
            copyTasks = copyTasks.concat(run.archivalStats);
          }

          if (run.cloudSpinStats) {
            copyTasks = copyTasks.concat(run.cloudSpinStats);
          }

          if (backupFailed || copyTasks.find(task => task.status === 'Failed')) {
            badRunDates.push(run.startDate);
          }

          return badRunDates;
        }, [])
      ),
      shareReplay(1)
    );
  }

  /**
   * Initialized table data observables.
   */
  private initTable() {
    this.tableDateRange$ = this.runsService.tableDateRange$.pipe(take(1), shareReplay(1));

    // wait for date range from state params to resolve before listening for table filter changes.
    this.tableDateRange$.subscribe(() => this.initTableFilters());
  }

  /**
   * Initialize observables for table filters.
   * This should be called when other observables are resolved because filter might override other
   * observables values.
   */
  private initTableFilters() {
    const filterValues$: Observable<DataFilterValue<any>[]> = this.tableFilters.filterValues$.pipe(
      debounceTime(0),
      distinctUntilChanged(isEqual)
    );

    this.tableRuns$ = combineLatest([this.runsService.reloadRunsTable$, filterValues$]).pipe(
      map(([, appliedFilters]) => {
        const dateRange: DateFilterRange<Moment> = { start: undefined, end: undefined };
        const reqParams = appliedFilters.reduce(
          (params: GetProtectionGroupRunsParams, dataFilter: DataFilterValue<any, any>) => {
            const { key, value } = dataFilter;

            if (value) {
              switch (key) {
                case 'startDate': {
                  const { start, end, timeframe } = value as DateFilterRange<Moment>;
                  if (start && end) {
                    params.startTimeUsecs = start.valueOf() * 1000;
                    params.endTimeUsecs = end.valueOf() * 1000;

                    dateRange.start = start;
                    dateRange.end = end;
                    dateRange.timeframe = timeframe;
                  }

                  break;
                }
                case 'runType':
                  params.runTypes = value.map(v => v.value);
                  this.runsService.saveStateParams({ runType: params.runTypes[0]});
                  break;

                case 'externallyTriggeredBackupTag':
                  params.runTags = value.map(v => v.value);
                  break;
              }
            }

            return params;
          },
          { ...this.runsReqParams }
        );

        // make sure to resolve state param table range before overriding with filter date range.
        this.runsService.tableDateRange = dateRange;

        return reqParams;
      }),
      // use null to display spinner before API resolves
      switchMap(params => this.getRuns({ ...params, numRuns: this.tableRunsLimit })),
      catchError(err => {
        this.ajaxService.errorMessage(err);
        return throwError(null);
      }),
      shareReplay(1)
    );
  }

  /**
   * Initialization after DOM is updated.
   * Note: Cannot put inside ngOnInit as this.tableFilters and this.calendarFilters is undefined
   */
  ngAfterViewInit() {
    this.updateFilterFromStateParams();
  }

  /**
   * Handles calendar date change event.
   */
  dateChangeHandler(date: Moment) {
    this.runsService.calendarDate = date;
  }

  /**
   * Handles `viewState` toggle buttons change event.
   */
  stateChangeHandler({ value: viewState }: MatButtonToggleChange) {
    this.runsService.viewState = viewState;
    this.updateFilterFromStateParams();
  }

  /**
   * Handles run selection change in runs list for day view.
   *
   * @param  run  Protection run selected in runs list.
   */
  runListSelectionChanged(run: IProtectionRun) {
    if (run) {
      this.selectedRun = run;
      this.runsService.saveStateParams({ runId: run.runId });
    }
  }

  /**
   * Return runs for Protection Group or Protected Object.
   *
   * @params   params  Optional API params for fetching runs.
   * @returns  Runs list.
   */
  private getRuns(params: any): Observable<IProtectionRun[]> {
    return combineLatest([this.group$, this.object$]).pipe(
      switchMap(([group, object]) => {
        if (object) {
          return this.runService.getObjectRuns(object.id, true, params);
        } else if (group) {
          return this.runService.getRuns(params).pipe(
            map((runs: ProtectionGroupRuns) => {
              this.totalRunsCount = runs?.totalRuns;

              if (Array.isArray(runs?.runs)) {
                return runs.runs.map(run => {
                  const protectionRun = new ProtectionRun(run, true);
                  const {
                    status,
                    backupStatus,
                    lastPausedByUsername,
                    userInitiatedPauseRequestedTimeUsecs,
                    pausedNote
                  } = protectionRun;

                  // If the status is 'Paused' and necessary fields are present,
                  // update the backupStatus name for tooltip
                  if (
                    status === TaskStatus.Paused &&
                    backupStatus &&
                    lastPausedByUsername &&
                    userInitiatedPauseRequestedTimeUsecs
                  ) {
                    const lastPauseModification = this.cohDate
                      .transform(userInitiatedPauseRequestedTimeUsecs);

                    backupStatus.name = pausedNote
                      ? this.translate
                        .instant('protectionGroups.pauseRunsJobDescription', {
                          lastPausedByUsername,
                          lastPauseModification,
                          pausedNote
                        })
                      : this.translate
                        .instant('protectionGroups.pauseRunsJobDescriptionWithoutPauseNote', {
                          lastPausedByUsername,
                          lastPauseModification,
                        });
                  }
                  return protectionRun;
                }
                );
              }
              return null;
            }),
          );
        }

        return of([]);
      })
    );
  }

  /**
   * Set the backup type filter based on value provided via state/url params.
   */
  updateFilterFromStateParams() {
    const { params: { runType } } = this.uiRouterGlobals;
    const backupTypeFilterSelection = runType ? this.filterValuesList.backupType.filter(val =>
      runType === val.value) : [];
    [this.tableFilters, this.calendarFilters].forEach(filterComponent => {
      filterComponent.setValue('runType', backupTypeFilterSelection);
    });
  }

  /**
   * Handles event when "View All Runs" is clicked in runs limit banner in calendar view.
   *
   * @param totalRuns Runs count to override current runs limit for calendar.
   */
  viewAllCalendarRuns(totalRuns = 0) {
    this.calendarRunsLimit = totalRuns;
    this.runsService.reloadCalendar();
  }

  /**
   * Handles event when "View All Runs" is clicked in runs limit banner in table view.
   *
   * @param totalRuns Runs count to override current runs limit for table.
   */
  viewAllTableRuns(totalRuns = 0) {
    this.tableRunsLimit = totalRuns;
    this.runsService.reloadTable();
  }
}

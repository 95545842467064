import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { GcpDiskInfo } from '@cohesity/api/v1';
import { CanSelectAnyRowsFn, CanSelectRowFn, DataTreeNodeContext, DataTreeNodeDetail, FileSize, IsAllSelectedFn, ToggleSelectAllFn } from '@cohesity/helix';
import { Memoize } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { differenceBy } from 'lodash-es';
import { GcpSourceDataNode } from '../gcp-source-data-node';

/**
 * The interface for the list of disks which can be excluded used in this
 * component.
 */
interface GcpDiskInfoModel extends GcpDiskInfo {
  // This is added field which converts sizeGb to bytes.
  byteSize: number;
}

@Component({
  selector: 'coh-gcp-disk-exclusion-options',
  templateUrl: './gcp-disk-exclusion-options.component.html',
  styleUrls: ['./gcp-disk-eclusion-options.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GCPDiskExclusionOptionsComponent implements DataTreeNodeDetail<GcpSourceDataNode> {
  /**
   * The node context, including info about the node and its selection status.
   */
  @Input() nodeContext: DataTreeNodeContext<GcpSourceDataNode>;

  /**
   * The selection model for the disks.
   */
  selection = new SelectionModel<GcpDiskInfoModel>(true);

  /**
   * Gets the node from the nodeContext.
   */
  get node(): GcpSourceDataNode {
    return this.nodeContext.node;
  }

  /**
   * Gets the current options for the node.
   */
  get currentOptions(): any {
     return this.nodeContext.selection.getOptionsForNode(this.node.id) ?? {};
  }

  /**
   * Gets the list of excluded gcp disks, if any, from the special parameters.
   */
  get excludedGcpDisks(): string[] {
    return this.currentOptions.gcpSpecialParameters?.diskExclusionNameParams ?? [];
  }

  /**
   * Get the available GCP Disks that can be excluded.
   */
  @Memoize()
  get gcpDisks(): GcpDiskInfoModel[] {
    return (this.nodeContext.node.protectionSource.gcpProtectionSource?.gcpDiskInfoList ?? [])
      .map(disk => ({
        ...disk,
        byteSize: FileSize['gigabyte'] * disk.sizeGb,
      }));
  }

  /**
   * Gets the button's label depending on the number of excluded disks.
   */
  get buttonLabel() {
    let translateKey = '';

    if (this.excludedGcpDisks.length) {
      if (this.excludedGcpDisks.length === 1) {
        translateKey = 'cSourceTreeNode.volumesExcludedSingular';
      } else {
        translateKey ='cSourceTreeNode.volumesExcludedPlural';
      }
    } else {
      translateKey = 'cSourceTreeNode.allVolumes';
    }

    return this.translate.instant(translateKey, { n: this.excludedGcpDisks.length });
  }

  /**
   * The column definition.
   */
  colDefinition = ['name', 'id', 'type'];

  /**
   * The form to contain the options.
   *
   * Note - this is not being used anywhere. This is required as the component
   *        rendering this uses form Validity to enable the submit button.
   */
  form = new FormGroup({
    temp: new FormControl()
  });

  constructor(
    private translate: TranslateService,
  ) { }

  /**
   * Initialize the selection with the given list of disks.
   */
  initializeSelection() {
    // Remove the excluded lists from the selection.
    const selectedDisks = differenceBy(this.gcpDisks, this.excludedGcpDisks.map(name => ({ name })), 'name');

    selectedDisks.forEach(disk => this.selection.select(disk));
  }

  /**
   * If a user can toggle the row.
   *
   * Disabled for when the disk is root disk, ie isRootDevice is true.
   */
  canSelectRowFn: CanSelectRowFn<GcpDiskInfoModel> = row => !row.isRootDevice;

  /**
   * Toggle all the disks except the root disk.
   *
   * @param event The checkbox change event.
   */
  toggleSelectAllFn: ToggleSelectAllFn = event => {
    this.gcpDisks.forEach(volume => {
      if (!event.checked) {
        if (this.canSelectRowFn(volume)) {
          this.selection.deselect(volume);
        }
      } else {
        this.selection.select(volume);
      }
    });
  };

  /**
   * Check if all of the disks are selected or not.
   *
   * @returns True, if all of them are selected.
   */
  isAllSelected: IsAllSelectedFn = () => this.selection.selected?.length === this.gcpDisks?.length;

  /**
   * Can select any row based on if there's only one element which would be
   * a root disk (which is not toggleable)
   *
   * @returns True, if we can select any row.
   */
  canSelectAnyRows: CanSelectAnyRowsFn = () => this.gcpDisks.length > 1;

  /**
   * Click event for when the row is clicked.
   *
   * @param disk The GCP Disk.
   */
  toggleObjectSelection(disk: GcpDiskInfoModel) {
    if (this.canSelectRowFn(disk)) {
      this.selection.toggle(disk);
    }
  }

  /**
   * Updates the selection options after the save button is clicked.
   */
  onSave() {
    const options = {
      sourceId: this.node.id,
      gcpSpecialParameters: {
        diskExclusionNameParams: this.gcpDisks
          .filter(disk => !this.selection.isSelected(disk))
          .map(({ name }) => name)
      },
    };

    // If all of the options are selected, that means no disk is excluded
    // where we would not set the exclusion params at all.
    if (!this.isAllSelected()) {
      this.nodeContext.selection.setOptionsForNode(this.node.id, options);
    } else {
      this.nodeContext.selection.removeOptionsForNode(this.node.id);
    }
  }
}

import { Inject, Injectable } from '@angular/core';
import {
  ClusterConfig,
  IrisContextService,
  flagEnabled,
  isAllClustersScope,
  isGlobalScope,
  isMcm,
  isMcmOnPrem,
  isClusterScope,
  isOneHeliosAppliance,
  isIbmBaaSEnabled,
  isTenantUser
} from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';
import {
  HookResult,
  Param,
  RawParams,
  StateDeclaration,
  StateObject,
  StateOrName,
  StateRegistry,
  StateService,
  UIRouterGlobals,
} from '@uirouter/core';
import { forEach, isObject, isString } from 'lodash-es';
import { NGXLogger } from 'ngx-logger';
import {
  AccessContext,
  AccessContextType,
  AllClusterMap,
  AppModuleConfig,
  CanAccess,
  EnvAccessContext,
  StateAccessContext,
  StateAccessMap,
  WorkflowAccessContext,
} from 'src/app/app-module.config';
import { AppServiceManagerService } from 'src/app/app-services';
import { APP_MODULES } from 'src/app/app.states';
import { RouterTab } from 'src/app/shared';
import { AjsClusterService } from 'src/app/shared/ng-upgrade/services';
import { environment } from 'src/environments/environment';

import { AjsStateDeclaration, AppStateDeclaration } from '../state/app-state-declaration';
import { StateContext } from '../state/state-context';
import { AdaptorAccessService } from './adaptor-access.service';
import { AjsUpgradeService } from './ajs-upgrade.service';
import { AppStateService } from './app-state.service';
import { HomeService } from './home.service';

/**
 * Key for state history in local storage
 */
const storageKey = 'page_state_history';

/**
 * Interface to use to describe ui-router params, which do not define an explict type.
 * Internal only to this class.
 */
interface Params {
  [key: string]: Param;
}

/**
 * Interface for a state history item.
 */
interface StateHistoryItem {
  name: string;
  params?: Params;
}

/**
 * An Hybrid tabs data model which includes properties from ajs tabs and angular tabs.
 */
export interface HybridRouterTab extends RouterTab {
  /**
   * Indicator whether the tab is default or not in ajs.
   */
  default?: boolean;

  /**
   * The target state name the tab in ajs.
   */
  route?: string;
}

/**
 * Angular replacement for legacy StateManagementService service.
 * Some methods are pass-through for now and will be replaced.
 */
@Injectable({
  providedIn: 'root',
})
export class StateManagementService {
  /**
   * This includes canAccess properties for all lazy loaded states in angular 7+.
   * canAccess checks for angularjs states are read directly from the state config.
   */
  private stateAccessMap: StateAccessMap = {};

  /**
   * Tracking previous state on $transitions.onSuccess so we can redirect
   * people when necessary... back buttons, etc.
   */
  readonly stateHistory: StateHistoryItem[] = [];

  /**
   * This includes allClustersSupport properties for all lazy loaded states in angular 7+.
   * The property is read directly from the state config for angular js states.
   */
  allClusterMap: AllClusterMap = {};

  constructor(
    @Inject(APP_MODULES) appModules: AppModuleConfig[],
    private adaptorAccessService: AdaptorAccessService,
    private ajsClusterService: AjsClusterService,
    private ajsUpgrade: AjsUpgradeService,
    private appServiceManager: AppServiceManagerService,
    private homeService: HomeService,
    private log: NGXLogger,
    private stateService: StateService,
    private appStateService: AppStateService,
    private irisCtx: IrisContextService,
    private uiRouterGlobals: UIRouterGlobals,
    private stateRegistry: StateRegistry,
    private translateService: TranslateService,
  ) {
    // Handle case when page is refreshed where history will be gone in this class.
    const history = localStorage.getItem(storageKey);

    if (history) {
      this.stateHistory = JSON.parse(history);
    }

    this.irisCtx.irisContext$.subscribe(ctx => {
      if (isIbmBaaSEnabled(ctx) && isTenantUser(ctx)) {
        this.homeService.setDefaultHomeState('dashboards.ibm-baas');
      }
    });

    appModules.forEach(appModule => {
      // TODO: Consider rewriting this so that an error is thrown or logged if a duplicate
      // name exists.
      Object.assign(this.stateAccessMap, appModule.getStateAccessMap() || {});
      Object.assign(this.allClusterMap, appModule.allClusterMap || {});
    });
  }

  /**
   * Return the state access context object used to evaluate states canAccess methods.
   *
   * @param   stateOrName  The state or state name
   * @param   stateParams  The state params
   * @param envAccessCtx   Custom env access context to use while creating workflow access context
   * @return  The state access context object.
   */
  getStateAccessContext(stateOrName: StateOrName, stateParams?: RawParams, envAccessCtx?: EnvAccessContext):
  StateAccessContext {
    const irisCtx = this.irisCtx.irisContext;


    const useCtx = !!Object.keys(irisCtx?.selectedScope).length;
    return {
      // On initial app load, there exists a race condition between state change and
      // context loading which is making it necessary to fallback to legacy clusterInfo
      // checking. selectedScope needs to be properly bootstrapped in the guards.
      clusterInfo: useCtx ? irisCtx.selectedScope : this.ajsClusterService.clusterInfo,
      stateName: typeof stateOrName === 'string' ? stateOrName : stateOrName.name,
      stateParams: stateParams || {},
      workflow: this.adaptorAccessService.getWorkflowAccessContext(envAccessCtx),
      canAccessSomeEnv: this.adaptorAccessService.canAccessSomeEnv.bind(this.adaptorAccessService),
      canAccessSomeEnvItems: this.adaptorAccessService.canAccessSomeEnvItems.bind(this.adaptorAccessService),
      isMcmOnPremAllCluster: this.isMcmOnPremAllCluster,
      ...this.adaptorAccessService.getEnvAccessContext(),
      ...irisCtx.privs,
      irisContext: irisCtx,
    };
  }

  /**
   * Return the state access context object used to evaluate states canAccess methods.
   *
   * @param   stateOrName  The state or state name
   * @param   stateParams  The state params
   * @return  The state access context object.
   */
  getClusterContextCapabilities(stateOrName: StateOrName, stateParams?: RawParams): StateAccessContext {
    const envAccessCtx = this.adaptorAccessService.getEnvAccessContext();
    (envAccessCtx as any).BIFROST_CAPABILITIES = envAccessCtx.CLUSTER_CAPABILITIES;
    return this.getStateAccessContext(stateOrName, stateParams, envAccessCtx);
  }


  /**
   * Provides workflow context objects based on the type required.
   *
   * @param   stateOrName          The state or state name.
   * @param   stateParams          The state params.
   * @param   contextType          The type of context to use (default or all cluster)
   * NOTE:  stateAccessContextFn and ajsStateAccessContextFn must be defined in state-management service.
   * @return                       True if user has access to navigate to given state else false.
   */
  getWorkflowContext(stateOrName: StateOrName, stateParams: RawParams, contextType: AccessContextType):
  WorkflowAccessContext {
    // define WorkflowAccessContext based on required contextType.
    switch (contextType) {
      case AccessContextType.ClusterCapability:
        return this.getAccessContext(stateOrName, stateParams, contextType).stateAccessContext.workflow;
      case AccessContextType.Default:
      default:
        return undefined;
    }
  }

  /**
   * Provides access context objects based on the type required.
   *
   * @param   stateOrName          The state or state name.
   * @param   stateParams          The state params.
   * @param   contextType          The type of context to use (default or all cluster)
   * NOTE:  stateAccessContextFn and ajsStateAccessContextFn must be defined in state-management service.
   * @return                       True if user has access to navigate to given state else false.
   */
  getAccessContext(stateOrName: StateOrName, stateParams: RawParams, contextType: AccessContextType):
  AccessContext {
    let stateAccessContext: StateAccessContext;

    // define stateAccessContext based on required contextType.
    switch (contextType) {
      case AccessContextType.ClusterCapability:
        stateAccessContext = this.getClusterContextCapabilities(stateOrName, stateParams);
        break;
      case AccessContextType.Default:
      default:
        stateAccessContext = this.getStateAccessContext(stateOrName, stateParams);
    }

    return {
      stateAccessContext: stateAccessContext,
      ajsStateAccessContext: {
        // This is a special method that needs to be available to the $eval context for canAccess
        canUserAccessAnyTab: this.canUserAccessAnyTab.bind(this),
        ...stateAccessContext,
      },
    };
  }

  /**
   * Indicates if the currently logged in user has appropriate permissions to
   * access the provided state.
   *
   * @param   stateOrName          The state or state name.
   * @param   stateParams          The state params.
   * @param   noLogging            If true then dont log if state is not accessible.
   * @param   contextType          The type of context to use (default or all cluster)
   * NOTE:  stateAccessContextFn and ajsStateAccessContextFn must be defined in state-management service.
   * @return                       True if user has access to navigate to given state else false.
   */
  canUserAccessState(stateOrName: StateOrName,
    stateParams?: RawParams,
    noLogging = false,
    contextType = AccessContextType.Default): boolean {
    // TODO: Figure out how to move away from using $rootScope here without breaking everything.
    const $rootScope = this.ajsUpgrade.get('$rootScope');

    const { stateAccessContext, ajsStateAccessContext } = this.getAccessContext(stateOrName, stateParams, contextType);
    const fullStateName = this.getStateName(stateOrName);

    if (!fullStateName) {
      if (!noLogging) {
        this.log.error('StateManagementService.canUserAccessState:', 'required `state` or `stateName` is missing.');
      }
      return false;
    }

    // Check if the state is actually registered. When a module is in the middle of being
    // lazy loaded, there is a brief period where th stateConfig will not register for a
    // state. To work around that, also check for the existing of the state name in the
    // stateAccessMap here, and allow the check to continue if it exists.
    const stateConfig = this.stateRegistry.get(fullStateName);
    if (!stateConfig && !this.stateAccessMap[fullStateName]) {
      if (!noLogging) {
        this.log.error(
          'StateManagementService.canUserAccessState:',
          `provided '${fullStateName}'`,
          'state is not registered. Please check spelling of the state-name.'
        );
      }
      return false;
    }

    const canAccess: string | CanAccess =
      this.stateAccessMap[fullStateName] || (stateConfig as AjsStateDeclaration).canAccess;

    // All states should have an explicit stateAccess entry or canAccess state property
    // declaration (ajs only). This is a change from previous behavior in which canAccess
    // was optional. In order to prevent regressions from being introduced, this will only
    // throw an error and prevent access in development mode.
    if (!canAccess && !environment.production) {
      if (!noLogging) {
        this.log.error(
          'StateManagementService.canUserAccessState:',
          `provided '${fullStateName}'`,
          'does not have a can access permission assigned to it. Add an entry to the module config.'
        );
      }
      return false;
    }

    // If user is in global scope/context, then ensure the state supports global context.
    // If a cid or region id param is present, the scope will be switched to
    // a cluster or DataProtect scope and this will be handled elsewhere.
    if (isGlobalScope(this.irisCtx.irisContext) && !stateParams?.cid && !stateParams?.regionId) {
      const stateAllClustersConfig = this.allClusterMap[fullStateName] || {};

      // In AJS states, look under allClustersSupport for globalContext support.
      const stateConfigGlobalAccess = (stateConfig as any)?.allClustersSupport?.globalContext ?? false;

      if (!stateConfigGlobalAccess
        && (typeof stateAllClustersConfig === 'boolean' || !stateAllClustersConfig.globalContext)) {
        return false;
      }
    }

    if (typeof canAccess === 'function') {
      // NOTE: After a flag change within UI in /feature-flags page, the iris
      // context service can still hold stale values if the page is not
      // refreshed because of which this can return false. In such cases, a
      // page reload resolves the issue.
      //
      // TODO(tauseef): Maybe rebuild the UI flags from localStorage by calling
      // FeatureFlagsService's updateFlags(). This can however be costly since
      // this will trigger checks for large number of flags.
      return (canAccess as CanAccess)(stateAccessContext);
    }

    // evaluate the given state access expression under rootscope or provided
    // custom scope with user.privs as locals directly available while
    // evaluating the expression as a shortcut for user.privs.CLUSTER_VIEW
    return !!$rootScope.$eval(canAccess || 'true', ajsStateAccessContext);
  }

  /**
   * Indicates if the currently logged in user is allowed to access any one of
   * the provided tab. Used to determine if a tabbed interface including
   * multiple states should be accessible by the user.
   *
   * @param    tabs    The tabs.
   * @return   True if user has access to goto any one of the given state else false.
   */
  canUserAccessAnyTab(tabs: HybridRouterTab[]): boolean {
    return (tabs || []).some(tab => this.canUserAccessState(tab.routeName || tab.route, tab.routeParams));
  }

  /**
   * Determine if the provided to-state is safe for context switch else return
   * the safe context switch state.
   *
   * @param  toState  The target state definition object.
   * @param  prevCluster  The previously selected cluster.
   * @param  selectedCluster  The selected cluster.
   * @returns  Return null if provided to-state is safe else return the safe
   * context switch state.
   */
  public getSafeContextSwitchState(
    toState: StateDeclaration | string,
    prevCluster: ClusterConfig,
    selectedCluster: ClusterConfig
  ): StateContext {
    const enteringGlobalScope = !prevCluster._globalContext && selectedCluster._globalContext;
    const exitingGlobalScope = prevCluster._globalContext && !selectedCluster._globalContext;
    const globalTransition = enteringGlobalScope || exitingGlobalScope;

    const goingToSingleFromNonCluster = !selectedCluster._nonCluster && prevCluster._nonCluster;
    const goingToNonClusterFromSingle = selectedCluster._nonCluster && (!prevCluster._nonCluster || globalTransition);

    const stateName = this.getStateName(toState);
    const stateConfig = this.stateRegistry.get(stateName);
    const allClustersSupport = this.allClusterMap[stateName] || (stateConfig as AjsStateDeclaration).allClustersSupport;

    const noContextChange =
      !goingToSingleFromNonCluster &&
      !goingToNonClusterFromSingle &&
      !globalTransition &&
      prevCluster.clusterId === selectedCluster.clusterId;

    /**
     * switching to single cluster scope from
     * 1. multi cluster scope.
     * 2. same single cluster scope.
     * 3. another single cluster scope.
     *    a. currently this case will occurs and if then it would be else block for below if condition.
     *    b. eg. if there is an option to use global search in Helios while user is accessing a cluster using
     *       pass through.
     */
    if (goingToSingleFromNonCluster || (noContextChange && !selectedCluster._nonCluster)) {
      if (typeof allClustersSupport === 'object') {
        if (allClustersSupport.heliosOnly && !isMcm(this.irisCtx.irisContext)) {
          // goto to home state for on-prem setup when target state is
          // available only for helios.
          return this.homeService;
        }

        if (allClustersSupport.singleClusterState !== stateName) {
          // going for specially configured single cluster state for safe state.
          // TODO: maybe call getSafeContextSwitchState recursively to reach to
          // safe state.
          return { name: allClustersSupport.singleClusterState };
        }
      }
    }

    // switching to all cluster from single cluster context or b/w 2 all cluster states.
    if (goingToNonClusterFromSingle || (noContextChange && selectedCluster._allClusters)) {
      if (typeof allClustersSupport === 'object') {
        if (allClustersSupport.heliosOnly && !isMcm(this.irisCtx.irisContext)) {
          // goto to home state for on-prem setup when target state is
          // available only for helios.
          return this.homeService;
        }

        if (allClustersSupport.allClustersState !== stateName) {
          // going for specially configured all cluster state for safe state.
          // TODO: maybe call getSafeContextSwitchState recursively to reach to
          // safe state.
          return { name: allClustersSupport.allClustersState };
        }
      } else if (!allClustersSupport) {
        // goto home state when all cluster state is not configured target state
        return this.homeService;
      }
    }
  }

  /**
   * Return whether the current selected context is Helios On-Prem all cluster.
   */
  get isMcmOnPremAllCluster(): boolean {
    return isMcmOnPrem(this.irisCtx.irisContext) && isAllClustersScope(this.irisCtx.irisContext);
  }

  /**
   * Navigates to the configured HOME_STATE if it is anything other than Dashboard.
   *
   * @returns Promise which is resolved with true if navigation to dashboard state valid else resolved with false.
   */
  dashboardStateAccessCheck(): HookResult {
    const dashboardState = 'dashboards';
    const ctx = this.irisCtx.irisContext;

    const clusterState = this.ajsClusterService.clusterState;

    const gotoDashboardIfSetupFailure = window.localStorage.getItem('gotoDashboardIfSetupFailure');

    if (gotoDashboardIfSetupFailure === null) {
      // get cluster setup done before going to dashboard.
      if (!clusterState.found || isOneHeliosAppliance(ctx)) {
        this.ajsClusterService.isClusterCreateInProgress().subscribe(inProgress => {
          if (isOneHeliosAppliance(ctx)) {
            if (inProgress || !clusterState.found) {
              this.stateService.go('appliance-manager-setup');
            }
          } else {
            if (inProgress) {
              this.stateService.go('cluster-setup.confirm');
            } else if (flagEnabled(ctx, 'ngClusterCreate')) {
              this.stateService.go('cluster-create');
            } else {
              this.stateService.go('cluster-setup');
            }
            return false;
          }
        });
      }
    }

    // goto especially configured home state if it is different from dashboard state.
    if (dashboardState !== this.homeService.name) {
      // replace the old history with current one so that user wont run into
      // redirection loop on browser back action.
      this.homeService.goHome({ location: 'replace' });
      return false;
    }

    // Need a cluster count and "remoteClusterList" includes all possible scopes.
    const clusterCount = (this.appStateService.remoteClusterList || []).reduce(
      (count, scope) => scope._nonCluster ? count : ++count,
      0
    );

    // When in Helios "All Clusters" with no clusters defined, send the user to
    // the help center instead so they can get some help with config, etc.
    if (isMcm(ctx) && isAllClustersScope(ctx) && clusterCount === 0) {
      if (flagEnabled(ctx, 'appClustersRequiredPage')) {
        this.stateService.go('app-blank', { hideNav: false, collapseMenu: true, app: 'clusterManager', force: true}, { location: 'replace' });
      } else {
        this.stateService.go('help.center', null, { location: 'replace' });
      }

      return false;
    }

    return true;
  }

  /**
   * Return the fallback state for current state.
   *
   * goto current state's configured parentState if
   * 1. having any one path params (isOptional=false) because assuming such
   *    params hold some identifer like jobId, sourceId etc which is not
   *    context switch friendly.
   * 2. having any one optional param (isOptional=true) for identifer type of param (isIdentifier=true)
   *    and having some value e.g. case 3 below.
   *
   * @returns  Return null if current state is context switch friendly else return the configured parentState.
   */
  getCurrentFallbackState() {
    let gotoFallbackState = false;

    // Holds the reference to original state config before ui-state consumers and
    // losses some properties like `isIdentifier`.
    const paramConfigs = this.uiRouterGlobals.current.params || {};

    // Holds the reference to parsed state params.
    const parsedParams = this.uiRouterGlobals.$current.params || {};

    // Holds the reference to current state params values.
    const paramValues = this.uiRouterGlobals.params;

    // loop over each param and look for any param which is not context switch safe.
    forEach(parsedParams, (param, paramName) => {
      // Holds the reference to original state config for provided param.
      const paramConfig = paramConfigs[paramName] || {};

      // Determines whether param is having a valid value or not and checking
      // against `undefined` because ui-router assigned `undefined` as default value.
      const hasParamValue = paramValues[paramName] !== undefined;

      /**
       * early exit if going to fallback state decision is made.
       *
       * case 1: isOptional will be false for all path param
       * 1.  /job/1234/run/876545435      /job/{jobId}/run/{startTime}
       * 2.  /organizations/vjOrg/        /organizations/{tenantId}
       *
       * case 2: isOptional will be true for search param
       * 1.  /bkp-reports/?objectId=321                   /bkp-reports/?{objectId}&{objectName}
       * 2.  /protection/jobs/?protectedObject=kSql       /protection/jobs/?{protectedObject}&{jobType}&{jobRunErrors}
       *
       * case 3: isOptional will be true for search param with & w/o default value
       * state config = {
       *   name: 'recoveries',
       *   url: '/protection/recoveries/?{recoveryType}&{jobId}',
       *   params: {
       *     // isOptional will be true for recoveryType search param having default value.
       *     recoveryType: { type: 'string', value: 'tape' },
       *
       *     // isOptional will be true for jobId search param.
       *     jobId: { type: 'string' },
       *
       *     // isOptional will be false for silentParam initialized by using Shorthand notation.
       *     // silentParam are those which doesn't persists in the URL.
       *     // NOTE: using Shorthand will allow navigation to state w/o explicity specifying silentParam value but
       *     // it doesn't allow navigation for full notation with undefined as a default value & w/o default value.
       *     silentParam: undefined,
       *
       *     // isOptional will be false for silentParam params w/o default value or with default value as undefined
       *     // navigation will be prevented if silentParam is not specified.
       *     silentParam1: { type: 'string' },
       *     silentParam2: { type: 'string', value: undefined },
       *
       *     // isOptional will be true for silentParam params with default value other that undefined.
       *     silentParam3: { type: 'string', value: null },
       *     silentParam4: { type: 'string', value: '' },
       *     silentParam5: { type: 'string', value: 0 },
       *     silentParam6: { type: 'string', value: [1, 2, 3] },
       *   },
       *  }
       */
      if (!gotoFallbackState && (param.isOptional ? paramConfig.isIdentifier && hasParamValue : true)) {
        gotoFallbackState = true;
      }
    });

    // goto configured parentState or home state if it is not configured.
    return gotoFallbackState
      ? (this.uiRouterGlobals.current as AppStateDeclaration).parentState || this.homeService.name
      : null;
  }

  /**
   * goto the fallback state for current state.
   */
  gotoCurrentFallbackState() {
    this.stateService.go(this.getCurrentFallbackState());
  }

  /**
   * Return the default tab or 1st accessible tab.
   *
   * @returns The default tab for provided tab list.
   */
  getDefaultTab(tabList: HybridRouterTab[]): HybridRouterTab {
    let defaultTab: HybridRouterTab;
    let firstAccessibleTab: HybridRouterTab;

    tabList.forEach(tab => {
      const routeName = tab.routeName || tab.route;
      const isDefault = tab.isDefault || tab.default;
      const canAccess = this.canUserAccessState(routeName, tab.routeParams);

      if (!defaultTab && canAccess && isDefault) {
        defaultTab = tab;
      }

      if (!firstAccessibleTab && canAccess && !defaultTab) {
        firstAccessibleTab = tab;
      }
    });

    return defaultTab || firstAccessibleTab;
  }

  /**
   * adds a state to our stateHistory array
   *
   * @param   from         the state transitioned from
   * @param   fromParams   state params of the state transitioned from
   */
  addPreviousState(from: StateObject, fromParams?: Params) {
    // ensure the previous state is valid, this will prevent a direct url
    // request to a detail page from saving an invalid (blank) previous state
    // to stateHistory
    if (from.name && from.name !== '') {
      const ctx = this.irisCtx.irisContext;
      // Add the current scope to the history params, so that we will switch to the correct
      // scope when we use goToPreviousState.
      // It is ok that this does not account for cid or regionId since there are
      // already state params for those.
      const activeServiceType = this.appServiceManager.getActiveService()?.serviceType;
      const params: Params = { ...fromParams, serviceType: activeServiceType as any };

      // When is cluster scope, add the cid param, otherwise returnToPreviousState
      // can get confused and switch the scope to "All Clusters" when operating
      // in the clusterManager app service.
      if (isClusterScope(ctx)) {
        params.cid = ctx.selectedScope.clusterId as any;
      }

      // Filter out transient params.
      const transientParams = (from as any).transientParams;

      if (Array.isArray(transientParams)) {
        Object.keys(params)
          .filter(k => transientParams.includes(k))
          .forEach(k => delete params[k]);
      }

      // add the previous state to the stack
      this.stateHistory.push({ name: from.name, params });

      // if stack is too big, trim the oldest off. assuming user will never
      // use internal 'go back' options for more than X consecutive pages and
      // want to minimize size of previousState array in memory.
      if (this.stateHistory.length > 10) {
        this.stateHistory.shift();
      }

      // Save state history in local storage so that it won't be lost when page
      // is refreshed.
      localStorage.setItem(storageKey, JSON.stringify(this.stateHistory));
    }
  }

  /**
   * directs the user to the previous $state. if no previous $state exists,
   * sends the user to the parent state or the optionally provided default
   * $state
   *
   * @param   defaultGoBackState   If a state doesn't have a parent, this should
   *                               be used to provide a default go back state.
   * @param   defaultStateParams   If set, params to pass to a default state.
   * @param   skipSibling          Whether to skip sibling (such as tabs) when
   *                               going one state back.
   */
  goToPreviousState(defaultGoBackState?: string, defaultStateParams?: any, skipSibling = false) {
    let toState;
    if (!this.stateHistory.length) {
      // if no previous state, go to provided default or if no default state
      // send to parent of current state
      if (defaultGoBackState && defaultGoBackState.length) {
        this.stateService.go(defaultGoBackState, defaultStateParams);
      } else {
        // send to parent state
        this.stateService.go('^');
      }
    } else {
      toState = this.getPreviousStateHistory(skipSibling);

      // Remove the page just returned.
      this.stateHistory.pop();
      this.stateService.go(toState.name, toState.params);
    }

    // When going back, remove the page we're leaving from our stateHistory
    // stack to allow consecutive 'go back' clicks without creating an endless loop.
    setTimeout(() => this.stateHistory.pop());
  }

  /**
   * Gets the state name from a StateOrName type.
   *
   * @param   stateOrName   This is either a ui-router StateDeclaration or a string
   * @returns The name of the state.
   */
  private getStateName(stateOrName: StateOrName): string {
    switch (true) {
      case isObject(stateOrName):
        return (stateOrName as StateObject).name;
      case isString(stateOrName):
        return stateOrName as string;
      default:
        return undefined;
    }
  }

  /**
   * Function to return the previous state history item, with the option support
   * siblings. This is useful for tabs where without skipping the sibling,
   * the back button will keep cycling in between the tabs if the user goes
   * from one tab to another and clicks back.
   *
   * TODO: This function doesn't support returning non sibling parent when the
   * parent state is set using 'parent' attribute in the state declaration.
   *
   * @param skipSibling Whether to skip sibling (such as tabs) when returning
   *                    state history item.
   * @returns The previous state history item.
   */
  getPreviousStateHistory(skipSibling = false): StateHistoryItem {
    const stateHistoryCopy = [...this.stateHistory];

    if (!stateHistoryCopy?.length) {
      return;
    }

    const current = this.uiRouterGlobals.current;
    let previous = stateHistoryCopy[stateHistoryCopy.length - 1];

    if (!skipSibling) {
      // It not skipping the sibling, just return the previous state.
      return previous;
    }

    // If skipping the sibling to get the previous state, compare the
    // current state with previous state.
    const currentParts = current.name.split('.');
    const currentParent = currentParts.slice(0, currentParts.length - 1).join('.');

    previous = stateHistoryCopy.pop();
    let previousParts = previous.name.split('.');
    let previousParent = previousParts.slice(0, previousParts.length - 1).join('.');

    while (currentParent === previousParent && stateHistoryCopy.length) {
      // Keep looping until the previous state is not a sibling of the current
      // state.
      previous = stateHistoryCopy.pop();
      previousParts = previous.name.split('.');
      previousParent = previousParts.slice(0, previousParts.length - 1).join('.');
    }

    return previous;
  }

  /**
   * Provides the state name of the previous state in the history stack.
   *
   * @param fallbackTitle the title to provide if no previous state.
   * @param skipSibling   whether to skip sibling (such as tabs) when returning
   *                      state title.
   * @returns the state title
   */
  previousStateTitle(fallbackTitle: string, skipSibling = false): string {
    const history = this.stateHistory;

    if (history?.length) {
      const stateConfig: AppStateDeclaration = this.stateRegistry.get(
        this.getPreviousStateHistory(skipSibling).name
      );

      // If stateConfig is present, return its title, it its not set, return
      // a generic back.
      return stateConfig
        ? (stateConfig.title || this.translateService.instant('back'))
        : fallbackTitle;
    }

    return fallbackTitle;
  }
}

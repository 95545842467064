import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FilterDefDirective, FiltersComponent, ValueFilterSelection } from '@cohesity/helix';
import { AdaptorAccessService } from '@cohesity/iris-core';
import { AutoDestroyable, EnvironmentTypePipe, Memoize } from '@cohesity/utils';

import { BaseAccessService } from '../base-access-service';

@Component({
  selector: 'cog-environment-type-filter',
  templateUrl: './environment-type-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnvironmentTypeFilterComponent
  extends AutoDestroyable
  implements OnInit, OnDestroy, AfterViewInit {
  /**
   * Name of the filter
   */
  readonly filterName = 'environments';

  /**
   * Filters with the same label are grouped in a single menu popup
   */
  @Input() filterGroupLabel: string;

  /**
   * Supported Environments
   */
  @Input() adapterAccessService: AdaptorAccessService;

  @Input() accessService: BaseAccessService;

  /**
   * Holds flag to select all the filters by default
   */
  @Input() selectAllByDefault = false;

  @Input() allowMultiple = true;

  /**
   * Environment group filter value
   */
  @Memoize()
  get filterValues(): ValueFilterSelection[] {
    return (this.adapterAccessService || this.accessService).getSupportedEnvironments().map(env => ({
      label: this.environmentTypePipe.transform(env),
      value: env,
    }));
  }

  /**
   * The filter def directive for the filter
   */
  @ViewChild(FilterDefDirective, { static: true }) filterDef: FilterDefDirective;

  constructor(private filters: FiltersComponent, private environmentTypePipe: EnvironmentTypePipe) {
    super();
  }

  ngAfterViewInit(): void {
    if ((this.adapterAccessService || this.accessService) && this.selectAllByDefault) {
      // Select all filters by default
      this.filters.setValue(this.filterName, this.allowMultiple ? this.filterValues : this.filterValues.slice(0, 1));
    }
  }

  ngOnInit() {
    if (this.filters && this.filterDef) {
      this.filters.addFilterDef(this.filterDef);
    }
  }

  ngOnDestroy() {
    if (this.filters && this.filterDef) {
      this.filters.removeFilterDef(this.filterDef);
    }
  }
}

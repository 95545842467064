import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

/**
 * Executes a primary function and a fallback function in case of an 404 error.
 *
 * This function takes two parameters: a primary function that returns an Observable,
 * and a fallback function that also returns an Observable. The primary function is executed first.
 * If the primary function throws an error, the error is caught, and the status code is checked.
 * If the status code is 404 (Not Found), the fallback function is executed instead.
 *
 * @template T - The type of the value returned by the primary and fallback functions.
 * @param primaryFn - The primary function to execute.
 * @param fallbackFn - The fallback function to execute in case of a 404 error.
 * @returns An Observable that emits the result of the primary function or the fallback function.
 *          If an error occurs and it's not a 404 error, the error is rethrown.
 */
export function executeWithFallback<T>(primaryFn: () => Observable<T>, fallbackFn: () => Observable<T>): Observable<T> {
  return primaryFn().pipe(
    catchError(err => {
      if (err.status === 404) {
        // If 404 error, call fn2
        return fallbackFn();
      }
      // If not, rethrow the error
      return throwError(err);
    })
  );
}

/**
 * Executes a primary promise and a fallback promise in case of a 404 error.
 *
 * This function takes two parameters: a primary promise that returns a Promise,
 * and a fallback promise that also returns a Promise. The primary promise is executed first.
 * If the primary promise rejects with an error, the error is caught, and the status code is checked.
 * If the status code is 404 (Not Found), the fallback promise is executed instead.
 *
 * @template T - The type of the value returned by the primary and fallback promises.
 * @param primaryPromise - The primary promise to execute.
 * @param fallbackPromise - The fallback promise to execute in case of a 404 error.
 * @returns A Promise that resolves with the result of the primary promise or the fallback promise.
 *          If an error occurs and it's not a 404 error, the error is rejected.
 */
export function executeWithFallbackPromise<T>(
  primaryPromise: () => Promise<T>,
  fallbackPromise: () => Promise<T>
): Promise<T> {
  return new Promise((resolve, reject) => {
    primaryPromise()
      .then(resolve)
      .catch(err => {
        if (err.status === 404) {
          // If 404 error, call fallbackPromise
          fallbackPromise().then(resolve).catch(reject);
        } else {
          // If not, reject the error
          reject(err);
        }
      });
  });
}

import { Subscription } from 'rxjs';

/**
 * Defines the various ways we do requests for data from the api.
 *
 * 'stats' - a request that will render the stats on a page.
 * 'list' a request that will render the list / table on a page.
 * 'default' the default request type if you don't pass a specific type.
 */
export type RequestType = 'stats' | 'list' | 'default';

/**
 * This structure holds the cache of subscriptions
 * by request type
 */
export type LatestSubscriptions = Record<RequestType, Subscription | undefined>;

/**
 * This utility class will keep track of subscriptions for you
 * and cancel any older subscriptions before tracking the new subscription.
 * This is useful to cancel http requests so that they do not come back
 * out of order and present stale data to the user.
 *
 * By using the RequestType as a key, it's possible to track separate multiple
 * types of requests using this utility.  This can be useful on pages where
 * a stats list or chart must be kept in sync with a list of data.
 */

export class SubscriptionTracker {

  /**
   * A simple structure to keep track of subscriptions keyed by RequestType.
   */
  latestSubscriptions: LatestSubscriptions = {
    'default': undefined,
    'list': undefined,
    'stats': undefined
  };

  /**
   * This method will keep track of your most recent subscriptions and cancel any existing
   * subscriptions before tracking the newest subscription.
   *
   * @param subscription - an rxjs subscription to track.
   * @param requestType - most uses of this do not need to supply this argument.  But some pages are tracking
   * subscriptions for stat lists and table, and this can be useful to track those subscriptions for
   * 'stats', and 'list' separately.
   * @returns void
   */
  track(subscription: Subscription, requestType: RequestType = 'default') {
    if (this.latestSubscriptions[requestType]) {
      this.latestSubscriptions[requestType].unsubscribe();
      this.latestSubscriptions[requestType] = undefined;
    }
    this.latestSubscriptions[requestType] = subscription;
  }

  /**
   * This determines if there is already a pending subscription for the provided requestType.
   * This can be useful to check before creating a new subscription via http request, polling, etc.
   *
   * @param requestType
   * @returns boolean if a subscription exists for this request type and if not yet closed.
   */
  hasPendingSubscription(requestType: RequestType = 'default') {
    return this.latestSubscriptions[requestType] && !this.latestSubscriptions[requestType].closed;
  }
}

import { Injectable } from '@angular/core';
import { identity } from 'lodash-es';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';

import { Api } from '@cohesity/api/private';
import { TenantServiceApi, Tenant } from '@cohesity/api/v1';
import {
  ConnectorsServiceApi,
  ExternalConnectionServiceApi,
} from '@cohesity/api/v2';
import { AjaxHandlerService } from '@cohesity/utils';

import { OrganizationsService, PassthroughOptionsService, TenantService } from 'src/app/core/services';
import {
  DecoratedBifrostConnection,
  DecoratedBifrostConnector
} from 'src/app/shared/models';
import { OrgIdSplitPipe } from 'src/app/shared/pipes';

/**
 * This service aims to provide functional APIs for all things Bifrost Connection on MCM.
 *
 * This includes but not limited to CRUD on Bifrost Connection, fetching configuration
 * file, and OVA installers for setting up connectors (Hybrid Extender) within a Bifrost
 * Connection.
 */
@Injectable({
  providedIn: 'root',
})
export class BifrostConnectionSetupService {

  /**
   * Base url for downloading assets from cohesity
   */
  COHESITY_DOWNLOAD_BASE = 'https://downloads.cohesity.com';

  /**
   * Holds an observable to whether the connection setup is loading.
   */
  loading$ = new BehaviorSubject<boolean> (false);

  /**
   * Holds an observable to all the active connections.
   */
  bifrostConnections$ = new BehaviorSubject<DecoratedBifrostConnection[]>(null);

  /**
   * Holds an observable to what is the current selected connection.
   */
  selectedBifrostConnection$ = new BehaviorSubject<DecoratedBifrostConnection>(null);

  /**
   * Fetches the clusterId of the cluster in context.
   *
   * @returns cluster id for the passthrough call.
   */
  get accessClusterId(): number {
    return this.passthroughOptionsService.accessClusterId;
  }

  constructor(
    private ajaxService: AjaxHandlerService,
    private connectorServiceAPI: ConnectorsServiceApi,
    private externalConnectionServiceAPI: ExternalConnectionServiceApi,
    private organizationService: OrganizationsService,
    private orgIdSplitPipe: OrgIdSplitPipe,
    private passthroughOptionsService: PassthroughOptionsService,
    private tenantService: TenantServiceApi,
    private tenantImpersonationService: TenantService,
  ) {}

  /**
   * Utility to form OVA url for installing Hyx
   *
   * @param softwareVersion on which user is doing operation
   * @param type of system
   * @returns hyx installer ova url
   */
  public hyxOVAUrl(softwareVersion: string, type: string): string {
    const directoryPath = `${this.COHESITY_DOWNLOAD_BASE}/hidden/Hybrid-Extender/${softwareVersion}`;
    const filePath = `/cohesity-hyx-${softwareVersion}${type}.ova`;
    return directoryPath + filePath;
  }

  /**
   * Utility to locate url for tenant proxy configuration
   *
   * @param orgId for which proxy url has to be formed
   * @param accessClusterId for this tenant
   * @returns location where the configuration file is stored
   */
  public tenantProxyUrl(orgId: string | number, connectionId: number): string {
    const url = Api.public(
      `tenants/proxy/config?id=${orgId}&clusterId=${this.accessClusterId}&connectionId=${connectionId}`
    );
    return url;
  }

  /**
   * Utitlity to validate hyx configuration file location
   *
   * @param validateOnly switch to fetch config data or just validates
   * @param id organisation id in context
   * @returns url for downloading configuration file
   */
  public validateConfigFile(
    validateOnly: boolean,
    id: string,
    connectionId: number
  ): Observable<string> {
    const params: TenantServiceApi.GetTenantsProxyConfigParams = {
      validateOnly,
      id,
      accessClusterId: this.accessClusterId,
      connectionId
    };

    return new Observable<string>(
      (subscriber) => {
        this.tenantService.GetTenantsProxyConfig(params).pipe(
          finalize(() => subscriber.complete())
        ).subscribe(
          () => {
            subscriber.next(this.tenantProxyUrl(id, connectionId));
          },
          (error) => {
            this.ajaxService.handler(error);
            subscriber.error(error);
          }
        );
      }
    );
  }

  /**
   * Creates a new bifrost connection and marks it as
   * selected connection.
   *
   * @param name name for the new connection
   * @returns observable of a new bifrost connection
   */
  public createHyxConnection(name: string): Observable<DecoratedBifrostConnection> {
    return this.externalConnectionServiceAPI.CreateBifrostConnection({
      // TODO: Ashish - fix typing below when yaml is updated.
      body: {
        name,
      } as any,
      accessClusterId: this.accessClusterId
    }).pipe(
      tap((newConnection) => {
        // decorate the connection
        const augmentConnection = new DecoratedBifrostConnection(newConnection);
        // update the selected connection observable
        this.selectedBifrostConnection$.next(augmentConnection);
      }),
      catchError((err) => {
        this.ajaxService.errorMessage(err);
        return of(null);
      })
    );
  }

  /**
   * Updates an already existing bifrost connection and marks it as
   * selected connection.
   *
   * @param name of the connection
   * @returns observable of updated bifrost connection
   */
  public updateHyxConnection(
    updateConnectionDetails: DecoratedBifrostConnection
  ): Observable<DecoratedBifrostConnection> {

    const { isDefault, ...updateConnectionBody } = updateConnectionDetails;

    return this.externalConnectionServiceAPI.UpdateBifrostConnection({
      id: updateConnectionBody.id,
      body: updateConnectionBody,
      accessClusterId: this.accessClusterId
    }).pipe(
      tap((newConnection) => {
        // decorate the connection
        const augmentConnection = new DecoratedBifrostConnection(newConnection);
        // update the selected connection observable
        this.selectedBifrostConnection$.next(augmentConnection);
      }),
      catchError((err) => {
        this.ajaxService.errorMessage(err);
        return of(null);
      })
    );
  }

  /**
   * Utility to fetch list of connections for a particular tenant.
   *
   * @param tenantId tenant in context for which connections are to be fetched
   * updates the list of connections.
   */
  public listConnections(tenantId: string) {

    this.loading$.next(true);

    this.externalConnectionServiceAPI.GetBifrostConnection({
      tenantId,
      accessClusterId: this.accessClusterId
    }).pipe(finalize(() => this.loading$.next(false))).subscribe(
      (connectionInfo) => {
        const decoratedConnections: DecoratedBifrostConnection[] = (
          connectionInfo.BifrostConnections || []
        ).map((el) => new DecoratedBifrostConnection(el));
        this.bifrostConnections$.next(decoratedConnections);
      },
      this.ajaxService.catchAndHandleError(),
    );
  }

  /**
   * Fetches the connection by id and marks it as selected connection
   *
   * @param id connection id for which details are
   * @returns Bifrost connection details
   */
  public getConnectionById(id: number): Observable<DecoratedBifrostConnection> {
    return this.externalConnectionServiceAPI.GetBifrostConnectionById({
      id,
      accessClusterId: this.accessClusterId
    }).pipe(
      map((connection) => new DecoratedBifrostConnection(connection)),
      tap(
        (connection: DecoratedBifrostConnection) => {
          this.selectedBifrostConnection$.next(connection);
        },
      ),
      this.ajaxService.catchAndHandleError()
    );
  }

  /**
   * Utility to check if 2 connection are same or not.
   *
   * @param connectionA connection
   * @param connectionB connection
   * @returns equality of two connections
   */
  public compareConnection(connectionA: DecoratedBifrostConnection, connectionB: DecoratedBifrostConnection): boolean {
    return connectionA && connectionB && connectionA?.id === connectionB?.id;
  }

  /**
   * Utility to fetch connectors within a connection.
   *
   * @param connectionId for which connectors are to be fetched
   * @param tenantId in context to which connection belongs
   * @returns an observable of array of connectors in the give connectionId
   */
  public getConnectors(connectionId: number, tenantId: string): Observable<DecoratedBifrostConnector[]> {

    const params: ConnectorsServiceApi.GetBifrostConnectorParams = {
      tenantId,
      connectionId,
      accessClusterId: this.accessClusterId
    };

    return this.connectorServiceAPI.GetBifrostConnector(
      params
    ).pipe(
      // sneak peak into error and delegate to ajax service for error handling
      tap(identity, (err) => this.ajaxService.handler(err)),
      // fetch the appropriate key to return array
      map((connectorsInfo) => connectorsInfo.BifrostConnectors),
      map((connectors) => connectors.map((connector) => new DecoratedBifrostConnector(connector)))
    );
  }

  /**
   * Utility to delete a connector
   *
   * @param connectorDetails connector to delete
   * @returns an observable with empty response indicating succesful delete operation
   */
  public deleteConnector(connectorDetails: Partial<DecoratedBifrostConnector>): Observable<null> {
    return this.connectorServiceAPI.DeleteBifrostConnector({
      id: connectorDetails.id,
      accessClusterId: this.accessClusterId
    }).pipe(
      // sneak peak into error and delegate to ajax service for error handling
      tap(identity, (err) => this.ajaxService.handler(err)),
    );
  }

  /**
   * Utility to delete a connection.
   *
   * @param connectionId connection id to delete
   * @returns an observable with empty response indicating succesful delete operation
   */
  public deleteConnection(connectionId: number): Observable<null> {
    return this.externalConnectionServiceAPI.DeleteBifrostConnection({
      id: connectionId,
      accessClusterId: this.accessClusterId
    }).pipe(
      // sneak peak into error and delegate to ajax service for error handling
      tap(identity, (err) => this.ajaxService.handler(err)),
    );
  }

  /**
   * Resets the state variables in the service.
   */
  public resetSelection() {
    this.selectedBifrostConnection$.next(null);
  }

  /**
   * This method tries to auto impersonate the tenant in a given cluster
   * if the user has required access for impersonation.
   *
   * @param orgId tenant to impersonate
   * @param clusterId cluster on which tenant to impersonate
   * @param updateBifrostData whether to update service state data with impersonated tenant
   * @returns flag indicating if the impersonation was successful or not
   */
  public autoImpersonateTenant(orgId: string, clusterId: number, updateBifrostData: boolean = true): boolean {

    const tenantAccess = this.organizationService.findAccessInfo(orgId, clusterId);

    if (!tenantAccess) {
      return false;
    }

    const { tenantId, tenantName } = tenantAccess;

    // derive minimal tenant info required for impersonation
    const impersontionInfo = <Tenant>{
      tenantId,
      name: tenantName
    };
    this.tenantImpersonationService.impersonatedTenant$.next(impersontionInfo);
    const impersonationSuccess = this.tenantImpersonationService.impersonatedTenant?.tenantId === tenantId;

    if (impersonationSuccess && updateBifrostData) {
      this.listConnections(this.orgIdSplitPipe.transform(orgId));
    }

    return impersonationSuccess;
  }

  /**
   * This method resets the tenant impersonation information.
   */
  public clearImpersonation() {
    this.tenantImpersonationService.clearImpersonatedTenant();
  }

  /**
   * This method resets the state of this service. Call this method
   * when Bifrost connection related workflows are completed or before starting
   * a new workflow. Each of the steps can also be called separately.
   */
  public resetServiceState() {
    this.resetSelection();
    this.clearImpersonation();
  }

  /** This method fetches the default bifrost connection for a tenant.
   * Post the release of Hyx realms {@link https://jira.cohesity.com/browse/ENG-115408}
   * A default network realm (Bifrost connection) will be created for each of the tenant
   * in the cluster. This method fetches this default bifrost connection for a given
   * tenant (tenantId).
   *
   * @param tenantId for which default bifrost connection has to be fetched
   * @returns decorated default bifrost connection
   */
  public fetchDefaultConnection(tenantId: string): Observable<DecoratedBifrostConnection> {

    const accessClusterId = this.passthroughOptionsService.accessClusterId;

    return this.externalConnectionServiceAPI.GetBifrostConnection({
      accessClusterId: accessClusterId,
      tenantId,
      defaultConnectionOnly: true
    }).pipe(
      map((resp) => resp.BifrostConnections),
      map((list) => {
        if (list?.length) {
          return new DecoratedBifrostConnection(list?.[0]);
        }

        return null;
      }),
      catchError(() => of(null))
    );
  }
}

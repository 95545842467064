import { AfterViewInit, Directive, ElementRef, Input, OnDestroy, Renderer2 } from '@angular/core';

/**
 * Truncate the text with the character limit provided. Also adds a title which
 * shows when hovering over truncated text.
 *
 * @example
 *    <h1 cogTruncate="3">Hello</h1>
 *    Hel...
 *
 *    <h1 cogTruncate>Hello</h1>
 *    --> Would add default ellipsis overflow styles when the text overflows.
 *
 *    <h1 cogTruncate="4" trailText="***">Boneless</h1>
 *    Bone***
 */
@Directive({
  selector: '[cogTruncate]',
  standalone: true,
})
export class TruncateDirective implements OnDestroy, AfterViewInit {
  /**
   * Input limit to truncate the text to.
   */
  @Input('cogTruncate') limit: number;

  /**
   * Optionally pass the trailing text, HTML horizontal ellipsis(&hellip;)
   * by default.
   */
  @Input() trailText = '…';

  /**
   * DOM Mutation change listener.
   */
  private changes: MutationObserver;

  constructor(
    private element: ElementRef<HTMLElement>,
    private renderer: Renderer2,
  ) {}

  ngAfterViewInit() {
    this.truncateText(this.element.nativeElement.textContent);

    // Create a DOM change listener.
    this.changes = new MutationObserver((mutations: MutationRecord[]) => {
      this.truncateText(mutations[0].target.textContent);
    });

    // Attach the listener to the element.
    this.changes.observe(this.element.nativeElement, {
      characterData: true,
      subtree: true,
    });
  }

  /**
   * Function to truncate the text from the DOM element.
   *
   * @param text The text to truncate.
   */
  private truncateText(text: string) {
    if (!this.limit) {
      // Add styles/title when the text overflows.
      if (this.element.nativeElement.offsetWidth > this.element.nativeElement.parentElement?.offsetWidth) {
        this.renderer.setAttribute(this.element.nativeElement, 'title', text);
        this.renderer.setStyle(this.element.nativeElement, 'white-space', 'nowrap');
        this.renderer.setStyle(this.element.nativeElement, 'overflow', 'hidden');
        this.renderer.setStyle(this.element.nativeElement, 'text-overflow', 'ellipsis');
      }
    } else if (text?.length > this.limit) {
      this.element.nativeElement.textContent = text.substring(0, this.limit - this.trailText.length) + this.trailText;
      this.renderer.setAttribute(this.element.nativeElement, 'title', text);
    }
  }

  /**
   * Unsubscribe listener on destroy.
   */
  ngOnDestroy() {
    this.changes.disconnect();
  }
}

import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MomentDatePipe } from '../../../date-pipe/moment-date.pipe';
import { BaseRendererComponent } from '../base-renderer.component';

/**
 * Available params that can be specified for date values
 */
export interface DateDataRendererParams {
  /**
   * Whether the date is in usecs
   */
  usecs?: boolean;
}

/**
 * Renders a simple date value.
 */
@Component({
  selector: 'cog-date-data-renderer',
  template: '<ng-container *ngIf="value">{{value | cogDate}}</ng-container>',
  styleUrls: ['date-data-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, MomentDatePipe],
})
export class DateDataRendererComponent extends BaseRendererComponent<number, DateDataRendererParams> {}

import { CreateView, SwiftConfig, ViewIntent, ViewProtocol } from '@cohesity/api/v2';
import { ViewCategory } from '../views.model';

/**
 * CreateView model decorated with properties consumed locally.
 */
export interface CreateViewDecorated extends CreateView {
  mostSecureSettings?: boolean;
}

/**
 *  Create View Basic Form Model.
 */
export interface CreateViewBasicForm {
  name: string;
  category: ViewCategory;
  dataLockExpiryUsecs?: number;
  storageDomainId: number;
  protocolAccess: ViewProtocol[];
  objectServicesMappingConfig: CreateView['objectServicesMappingConfig'];
  swiftConfig: SwiftConfig;
  mostSecureSettings?: boolean;
  isExternallyTriggeredBackupTarget?: boolean;
  intent?: ViewIntent;
}

/**
 * Protocol list for with applicable categories.
 */
export interface ProtocolForCategory extends ViewProtocol {
  applicableCategories: ViewCategory[];
}

/**
 * All Read/Write Protocols with applicable categories.
 */
export const ReadWriteProtocols: ProtocolForCategory[] = [
  {
    type: 'NFS',
    mode: 'ReadWrite',
    applicableCategories: ['FileServices', 'BackupTarget', 'ArchiveServices'],
  },
  {
    type: 'NFS4',
    mode: 'ReadWrite',
    applicableCategories: ['FileServices', 'BackupTarget', 'ArchiveServices'],
  },
  {
    type: 'SMB',
    mode: 'ReadWrite',
    applicableCategories: ['FileServices', 'BackupTarget', 'ArchiveServices'],
  },
  {
    type: 'S3',
    mode: 'ReadWrite',
    applicableCategories: ['ObjectServices', 'ArchiveServices'],
  },
  {
    type: 'Swift',
    mode: 'ReadWrite',
    applicableCategories: ['ObjectServices'],
  },
];

/**
 * All Read Only Protocols with applicable categories.
 */
export const ReadOnlyProtocols: ProtocolForCategory[] = [
  {
    type: 'S3',
    mode: 'ReadOnly',
    applicableCategories: ['FileServices', 'BackupTarget'],
  },
];

/**
 * All the values for object keys configs by type
 */
export const ObjectServicesMappingConfigByType = {
  objectKeyTypes: ['Flat', 'Hierarchical', 'ObjectId'] as const,
  flatKeyTypes: ['Random', 'Structured'] as const,
  structuredKeyLengths: ['Short', 'Long'] as const,
};

/**
 * The type of Object key types.
 */
export type ObjectKeyTypes = typeof ObjectServicesMappingConfigByType['objectKeyTypes'][number];

/**
 * The type of Flat key types.
 */
export type FlatKeyTypes = typeof ObjectServicesMappingConfigByType['flatKeyTypes'][number];

/**
 * The type of Structured key lengths.
 */
export type StructuredKeyLengths = typeof ObjectServicesMappingConfigByType['structuredKeyLengths'][number];

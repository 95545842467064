import { ComponentType } from '@angular/cdk/portal';
import { Injectable, TemplateRef } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { downgradeInjectable } from '@angular/upgrade/static';
import { TransitionService } from '@uirouter/core';
import { extend } from 'lodash-es';
import { Observable } from 'rxjs';
import { ComponentLoaderComponent } from 'src/app/dynamic-component-loader';
import { SimpleDialogComponent, SimpleDialogData } from 'src/app/shared/dialogs';

type PackageType = 'upgrade' | 'patch' | 'upgradeAndPatch';

/**
 * Default dialog config options. This can be overridden when the dialog is shown.
 * https://material.angular.io/components/dialog/api#MatDialogConfig
 */
export const defaultDialogConfig: MatDialogConfig<any> = {
  // Accessibility settings
  ariaLabel: 'Dialog popup',
  role: 'dialog',
  data: undefined,
  autoFocus: true,
  closeOnNavigation: true,
  disableClose: true,
  restoreFocus: true,
};

/**
 * Dynamic dialog service. This can be used to display any dialog component as well
 * as lazy-loaded component. To lazy load a dialog add an entry to the app/dynamic-components
 * manifest, and import the DynamicComponentLoader.forChild() module in the dialog's module.
 */
@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private dialog: MatDialog, private transitionService: TransitionService) {
    this.transitionService.onStart({}, () => this.onTransitionStart());
  }

  /**
   * On state transition, close all open dialogs.
   */
  onTransitionStart() {
    this.dialog.openDialogs.forEach(dialogRef => {
      dialogRef.close();
    });
  }

  /**
   * Opens a dialog and returns a reference to it. This should only be used if the
   * the dialog ref and component instance are needed. Otherwise, use showDialog.
   *
   * @example
   *  const dialogRef1: MatDialogRef<T,R> dialog = dialogService.getDialogRef(DialogComponent, data);
   *  const dialogRef2: MatDialogRef<T,R> dialog = dialogService.getDialogRef('lazy-component-id', data);
   *
   * @param   componentOrId   Either a component type, or an id for a dynamically loaded
   *                          component.
   * @param   dialogData      The dialog data to pass to the component being opened.
   * @param   options         Additional options for the dialog. This will be merged
   *                          witha  default set of options.
   */
  getDialogRef<T, R>(
    componentOrId: string | ComponentType<T> | TemplateRef<T>,
    dialogData?: any,
    options: MatDialogConfig<R> = {}
  ): MatDialogRef<T | ComponentLoaderComponent, R> {
    const isDynamic = typeof componentOrId === 'string';
    const component: ComponentType<T | ComponentLoaderComponent> = isDynamic
      ? ComponentLoaderComponent
      : (componentOrId as ComponentType<T>);
    const config = extend({}, defaultDialogConfig, options, { data: dialogData });
    const dialogRef = this.dialog.open(component, config);

    if (isDynamic) {
      (dialogRef.componentInstance as ComponentLoaderComponent).componentId = componentOrId as string;
    }
    return dialogRef;
  }

  /**
   * Opens a dialog and returns an observable of its result.
   *
   * @example
   *  dialogService.showDialog(DialogComponent, data).subscribe(results => console.log(results));
   *  dialogService.showDialog('lazy-component-id', data).subscribe(results => console.log(results));;
   *
   * @param   componentOrId   Either a component type, or an id for a dynamically loaded
   *                          component.
   * @param   dialogData      The dialog data to pass to the component being opened.
   * @param   options         Additional options for the dialog. This will be merged
   *                          witha  default set of options.
   */
  showDialog<T, R>(
    componentOrId: string | ComponentType<T> | TemplateRef<T>,
    dialogData?: any,
    config: MatDialogConfig<R> = {}
  ): Observable<R> {
    return this.getDialogRef(componentOrId, dialogData, config).afterClosed();
  }

  /**
   * Shows the register active directory dialog
   *
   * @return   An observable of the new source created by the dialog
   */
  showRegisterActiveDirectoryDialog(): Observable<any> {
    return this.showDialog('register-active-directory-dialog');
  }

  /**
   * Show the register Cassandra dialog.
   *
   * @return An observable of the new source created by the dialog.
   */
  showRegisterCassandraDialog(): Observable<any> {
    return this.showDialog('register-cassandra-dialog');
  }

  /**
   * Show the FlashRecover onboarding dialog.
   *
   * @param dialogConfig configuration to pass to underlying dialog component
   * @return An observable of the new source created by the dialog.
   */
  showFlashRecoverOnboardingDialog(dialogConfig): Observable<any> {
    return this.showDialog('flash-recover-onboarding-dialog', null, dialogConfig);
  }

  /**
   * Show the register MongoDB dialog.
   *
   * @return An observable of the new source created by the dialog.
   */
  showRegisterMongoDBDialog(): Observable<any> {
    return this.showDialog('register-mongodb-dialog');
  }

  /**
   * Show the register SAN (i.e. Pure, Nimble) dialog
   */
  showRegisterSanDialog(stateParams): Observable<any> {
    return this.showDialog('register-san-dialog', stateParams);
  }

  /**
   * Show the register NAS (i.e. NetApp, Isilon, GenericNAS, etc...) dialog
   */
  showRegisterNasDialog(stateParams): Observable<any> {
    return this.showDialog('register-nas-dialog', stateParams);
  }

  /**
   * Shows register exchange dialog.
   *
   * @returns An observable of the new source created by the dialog.
   */
  showRegisterExchangeDialog(): Observable<any> {
    return this.showDialog('register-exchange-dialog');
  }

  /**
   * Shows register SQL dialog.
   *
   * @returns An observable of the new source created by the dialog.
   */
  showRegisterSqlDialog(dialogParams): Observable<any> {
    return this.showDialog('register-sql-dialog', dialogParams);
  }

  showAddSwiftRolesDialog(dialogParams): Observable<any> {
    return this.showDialog('add-swift-roles-dialog', dialogParams);
  }

  /**
   * Shows register kubernetes dialog.
   *
   * @returns An observable of the new source created by the dialog.
   */
  showRegisterKubernetesDialog(): Observable<any> {
    return this.showDialog('register-kubernetes-dialog');
  }

  /**
   * Shows register Office365 dialog.
   *
   * @param dialogParams data to pass to underlying dialog component
   * @returns An observable of the new source created by the dialog.
   */
  showRegisterOffice365Dialog(dialogParams): Observable<any> {
    return this.showDialog('register-office365-dialog', dialogParams);
  }

  /**
   * Shows register Physical dialog.
   *
   * @param dialogParams data to pass to underlying dialog component
   * @returns An observable of the new source created by the dialog.
   */
  showRegisterPhysicalDialog(dialogParams: unknown): Observable<any> {
    return this.showDialog('register-physical-dialog', dialogParams);
  }

  /**
   * Open dialog to assign racks to chassis.
   *
   * @returns Observable of the dialog result.
   */
  assignRacks(): Observable<any> {
    return this.showDialog('assign-racks-chassis-dialog');
  }

  /**
   * Open dialog to register new remote storage.
   *
   * @returns Observable of the dialog result.
   */
  showRegisterRemoteStorageDialog(): Observable<any> {
    const dialogConfig: MatDialogConfig = {
      width: '40vw',
    };
    return this.showDialog('register-remote-storage-dialog', {}, dialogConfig);
  }

  /**
   * Show the register Universal Data Adapter dialog.
   *
   * @return An observable of the new source created by the dialog.
   */
  showRegisterUdaDialog(): Observable<any> {
    return this.showDialog('register-uda-dialog');
  }

  /**
   * Shows get new package upgrade dialog.
   *
   * @returns An observable of the new source created by the dialog.
   */
  showGetNewPackageDialog(packageType: PackageType = 'upgrade'): Observable<any> {
    return this.showDialog('get-new-package-dialog', {
      packageType,
    });
  }

  /**
   * Shows a dialog showing the notice required for govcloud users.
   *
   * @return An observable of the dialog showing the notice.
   */
  showGovCloudNoticeDialog(): Observable<any> {
    // Make the dialog open in fullscreen mode.
    return this.showDialog('govcloud-notice-dialog', {}, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%'
    });
  }

  /**
   * Open dialog to create file systems on remote storage.
   *
   * @returns Observable of the dialog result.
   */
  showCreateFileSystemsDialog(dialogData: any): Observable<any> {
    const dialogConfig: MatDialogConfig = {
      minWidth: '40vw',
      maxWidth: '50vw',
    };
    return this.showDialog('add-file-systems-remote-storage-dialog', dialogData, dialogConfig);
  }

  /**
   * Opens a simple dialog with some configuration. This is intended for simple
   * use-cases like a challenge modal. More advanced modals should be
   * implemented separately.
   *
   * @example
   *  // Angular
   *  dialogService.simpleDialog(null, data)
   *    .subscribe(result => console.log(result));
   *
   *  // AngularJS
   *  ngDialogService.simpleDialog(null, data).toPromise().then(...);
   *
   * @param    componentId      Either a component type, or an id for a
   *                            dynamically loaded component.
   * @param    dialogData       The dialog data to pass to the component being
   *                            opened.
   * @param    options          Additional options for the dialog. This will be
   *                            merged with a default set of options.
   * @return   The Observable state of the dialog result after closing.
   */
  simpleDialog<T, R>(
    componentId?: string,
    dialogData?: SimpleDialogData<T>,
    config: MatDialogConfig<R> = {}
  ): Observable<R> {
    const simpleDialogDefaults = {
      ...defaultDialogConfig,
      hasBackdrop: true,
      disableClose: false,
      position: {
        top: '15vh',
      },
      id: `simple-dialog__${componentId || Date.now()}`,
      minHeight: 150,
      minWidth: 243,
    };

    const conf = Object.assign({}, simpleDialogDefaults, config);

    return this.showDialog(SimpleDialogComponent, dialogData, conf);
  }
}

/**
 * Downgrade Dialog Service for legacy AngularJS use.
 */
declare const angular;
angular.module('C').factory('ngDialogService', downgradeInjectable(DialogService) as any);

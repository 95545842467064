import { cloneDeep } from 'lodash-es';
/**
 * Highchart's default chart options.
 */
export const TABLE_CELL_CHART_OPTIONS: { [key: string]: Highcharts.Options } = {
  sparkline: {
    chart: {
      spacing: [0, 10, 0, 10],
      styledMode: true,
    },
    yAxis: {
      minPadding: 0.3,
      maxPadding: 0.3,
    },
    xAxis: {
      crosshair: false,
      type: 'datetime',
      visible: false,
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      spline: {
        softThreshold: false,
        shadow: false,
      },
      series: {
        states: {
          hover: {
            enabled: false,
          },
        },
      },
    },
  }
};

TABLE_CELL_CHART_OPTIONS.sparklineSuccess = cloneDeep(TABLE_CELL_CHART_OPTIONS.sparkline);
TABLE_CELL_CHART_OPTIONS.sparklineSuccess.plotOptions.series.className = 'line-chart-zone-good';

TABLE_CELL_CHART_OPTIONS.sparklineCritical = cloneDeep(TABLE_CELL_CHART_OPTIONS.sparkline);
TABLE_CELL_CHART_OPTIONS.sparklineCritical.plotOptions.series.className = 'line-chart-zone-bad';

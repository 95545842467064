import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { AppPanelService } from '../app-panel/app-panel.service';
import { BannerComponent } from '../banner/banner.component';
import { BannersComponent } from '../banner/components/banners/banners.component';
import { DataIdDirective } from '../data-id/data-id.directive';
import { ButtonIconDecoratorDirective } from '../icon/button-icon-decorator.directive';
import { ReflowService, ViewportSize } from '../reflow';
import { BannerConfig, BannerLocation } from './app-banner.model';
import { AppBannerService } from './app-banner.service';

/**
 * @description
 * Placeholder for rendering banners.
 * This component is managed by AppBannerService
 */
@Component({
  selector: 'cog-app-banner',
  templateUrl: './app-banner.component.html',
  styleUrls: ['./app-banner.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    BannerComponent,
    BannersComponent,
    ButtonIconDecoratorDirective,
    DataIdDirective,
    MatLegacyButtonModule,
    MatLegacyTooltipModule,
    NgFor,
    NgIf,
  ],
})
export class AppBannerComponent implements OnInit, OnDestroy {
  /**
   * Name of the current location, used as identifier for finding where to place the banner
   */
  @Input() location = BannerLocation.top;

  /**
   * Used for appending banner component and to clear existing banner
   */
  banners: BannerConfig[] = [];

  /**
   * Holds the current banner index
   */
  currentBannerIndex = 0;

  /**
   * Used to unsubscribe.
   */
  private subscriptions = [];

  constructor(
    private appBannerService: AppBannerService,
    private appPanelService: AppPanelService,
    private reflowService: ReflowService,
    private cdr: ChangeDetectorRef,
    private elementRef: ElementRef
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.appBannerService
        .getLocation$(this.location)
        .pipe(
          distinctUntilChanged(null, curr => curr?.length || 0),
          tap(banners => {
            // Clear existing banner
            this.banners = [];

            // Reset appPanel location
            if (this.location === BannerLocation.top) {
              this.appPanelService.dialogPositionTop = 0;
            }

            // If there is a new banner, render it
            // Since there might be multiple banner. The service sorts it by priority.
            // At this point, we will render the highest priority in order.
            if (banners?.length) {
              this.loadBanners(banners);
            }

            this.cdr.detectChanges();
          })
        )
        .subscribe()
    );
    this.subscriptions.push(
      this.reflowService.currentViewport$.subscribe((size: ViewportSize) => {
        this.updateAppPanelDialogPosition(size);
      })
    );
  }

  /**
   * Unsubscribe observer on destroy.
   */
  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /**
   * return true if current banner have close operation
   */
  get showBannerCloseBtn(): boolean {
    return this.banners[this.currentBannerIndex]?.allowClose;
  }

  /**
   * Updates currentBannerIndex when banner changed
   *
   * @param index current banner index
   */
  updateCurrentBannerIndex(index: number) {
    this.currentBannerIndex = index;
  }

  /**
   * loads all specified banners to cog-banners
   *
   * @param  configs  Banner configs gathered from various banner components
   */
  loadBanners(configs: BannerConfig[]) {
    this.banners.push(...configs);
    this.updateAppPanelDialogPosition();
  }

  /**
   * Set the top location of appPanel to component height,
   * so that the appPanel starts after the banner
   */
  updateAppPanelDialogPosition(size: ViewportSize = ViewportSize.lg) {
    if (this.location === BannerLocation.top) {
      // Using requestAnimationFrame here to avoid forced reflow
      requestAnimationFrame(() => {
        this.appPanelService.dialogPositionTop =
          size === ViewportSize.xs ? 0 : this.elementRef.nativeElement.offsetHeight;
      });
    }
  }

  /**
   * handle to close banners from cog-banners
   *
   * @param bannerIndex closing banner index
   */
  closeBanner(bannerIndex: number) {
    const banner = this.banners[bannerIndex];
    if (banner.closeCallback) {
      banner.closeCallback(banner.id);
    }
    this.appBannerService.remove(banner.id, banner.location);
  }
}

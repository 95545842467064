import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ProtectionSourcesServiceApi } from '@cohesity/api/v1';
import { ProtectionGroup } from '@cohesity/api/v2';
import { get } from 'lodash-es';
import { Controls, NgxSubFormComponent, subformComponentProviders } from 'ngx-sub-form';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Environment } from 'src/app/shared';

import { NoSqlSearchResultGroup } from '../../model/nosql-search-result-group';
import { RestorePointSelection } from '../../model/restore-point-selection';
import { RecoverNoSqlSearchService } from '../../services/recover-nosql-search.service';

export interface DataCentersOption {

  /**
   * List of selected data centers.
   */
  value: string[];
}

@Component({
  selector: 'coh-data-centers-selection',
  templateUrl: './data-centers-selection.component.html',
  providers: subformComponentProviders(DataCentersSelectionComponent),
})
export class DataCentersSelectionComponent extends NgxSubFormComponent<DataCentersOption>
  implements OnChanges, OnDestroy {

  /**
   * List of Data center computed from the fetched keyspaces.
   */
  dataCenters: string[] = [];

  /**
   * Protection group object from which datacenters will be extracted.
   */
  protectionGroup: ProtectionGroup;

  /**
   * Show if the protection nodes are being fetched
   */
  loading$ = new BehaviorSubject<boolean>(false);

  /**
   * Selected restore object.
   */
  @Input() selectedObjects: RestorePointSelection[];

  /**
   * Specifies if point in time option is selected.
   */
  @Input() isPointInTimeSelected: boolean;

  /**
   * If the component should be displayed as read-only.
   */
  @Input() readOnly = false;


  constructor(
    public noSqlRestoreService: RecoverNoSqlSearchService,
    private protectionSourcesServiceApi: ProtectionSourcesServiceApi,
  ) {
    super();
  }

  /**
   * Listen for changes to the inputs and outputs and update the component
   * bindings if necessary.
   *
   * @param   changes   sumary of changed properties on the component.
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedObjects && changes.selectedObjects.currentValue
      && changes.selectedObjects.currentValue.length > 0) {
      this.initiateForm(changes.selectedObjects.currentValue[0]);
    }
  }

  /**
   * Init form.
   */
  initiateForm(restorePoint: RestorePointSelection) {
    // Extract data centers from protection group objects.
    // In case of PIT, data centers will be extracted from log backup job.
    this.getDataCenters(restorePoint.objectInfo as NoSqlSearchResultGroup);
  }

  /**
   * Fetch protection groups and map to filter values.
   */
  getDataCenters(objectInfo: NoSqlSearchResultGroup) {
    if (this.isPointInTimeSelected) {
      this.loading$.next(true);
      this.noSqlRestoreService.getProtectionGroupByEnv(Environment.kCassandra, this.loading$)
        .subscribe(groups => {
          const logBack = groups.protectionGroups.filter(grp => grp.cassandraParams?.sourceId === objectInfo.sourceId &&
            grp.cassandraParams?.isLogBackup);
          this.getAvailableDataCenters(logBack[0].cassandraParams?.dataCenters,
            this.protectionGroup?.cassandraParams?.sourceId);
        });
    } else if (this.protectionGroup?.id !== objectInfo.protectionGroupId) {
      this.loading$.next(true);
      this.noSqlRestoreService.getProtectionGroupById(objectInfo.protectionGroupId, this.loading$)
        .subscribe(group => {
          this.protectionGroup = group;
          this.getAvailableDataCenters(group.cassandraParams?.dataCenters,
            this.protectionGroup?.cassandraParams?.sourceId);
        });
    }
  }

  /**
   * Fetch all the available data centers from EH.
   */
  getAvailableDataCenters(dcList: string[], sourceId: number) {
    this.loading$.next(true);
    this.protectionSourcesServiceApi.ListProtectionSources({ id: sourceId })
      .pipe(map(sources => sources[0]))
      .subscribe(source => {
        const availableDCs: string[] = get(source, 'registrationInfo.cassandraParams.dataCenters', []);
        this.dataCenters = dcList?.filter(dc => availableDCs.indexOf(dc) !== -1);
        this.formGroup?.controls.value.setValue(this.dataCenters);
        this.loading$.next(false);
        this.formGroup?.updateValueAndValidity();
      });
  }

  getDefaultValues(): Partial<DataCentersOption> {
    return {
      value: [],
    };
  }

  /**
   * Returns a collection of form controls for the subform template.
   */
  protected getFormControls(): Controls<DataCentersOption> {
    return {
      value: new UntypedFormControl(null)
    };
  }
}

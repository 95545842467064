import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { IrisContextService } from '@cohesity/iris-core';
import { uniqBy } from 'lodash-es';
import { isObjectAutoProtected } from 'src/app/modules/object-details/shared/object-details-util';
import { ProtectedObjectItems } from '../../protected-objects.service';

/**
 * Unprotect object dialog actions.
 */
export enum ObjectUnprotectAction {
  Cancel = 'cancel',
  DeleteSnapshots = 'deleteSnapshots',
  Unprotect = 'unprotect',
}

/**
 * @description
 * Confirmation dialog when unprotecting object.
 */
@Component({
  selector: 'coh-object-unprotect-dialog',
  templateUrl: './object-unprotect-dialog.component.html',
  styleUrls: ['./object-unprotect-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ObjectUnprotectDialogComponent implements OnInit {
  /**
   * ObjectUnprotectAction reference for use inside of template.
   */
  ObjectUnprotectAction = ObjectUnprotectAction;

  /**
   * Form action values.
   */
  groupUnprotectForm: UntypedFormGroup;

  /**
   * Return the length of objects being unprotected.
   */
  get objectsLength(): number {
    const {protectedObjects = [], simpleObjects = []} = this.data?.objects || {};

    // If the object is present in both arrays, only count it once.
    return uniqBy([...protectedObjects, ...simpleObjects], 'id')?.length || 0;
  }

  /**
   * Get the name of the first object being unprotected.
   */
  get firstObjectName(): string {
    const {protectedObjects = []} = this.data?.objects || {};

    return protectedObjects[0]?.name;
  }

  /**
   * Specifies whether the toggle to delete snapshot should be visible.
   */
  get showSnapshotDeletionToggle(): boolean {
    return !this.data.disallowSnapshotDeletion;
  }

  constructor(
    readonly context: IrisContextService,
    public dialogRef: MatDialogRef<ObjectUnprotectDialogComponent>,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) readonly data: { objects: ProtectedObjectItems; disallowSnapshotDeletion?: boolean }) {}

  ngOnInit() {
    this.groupUnprotectForm = this.fb.group({enableSnapshotDeletion: [false]});
  }

  /**
   * Gets the action wanted by the user
   *
   * @returns ObjectUnprotectAction.DeleteSnapshots if the slide-toggle is activated
   * otherwise ObjectUnprotectAction.Unprotect
   */
  submitDelete() {
    this.dialogRef.close(
      this.groupUnprotectForm.get('enableSnapshotDeletion').value ?
       ObjectUnprotectAction.DeleteSnapshots : ObjectUnprotectAction.Unprotect
    );
  }

  /**
   * Returns true if any specified objects are auto protected.
   *
   * @param  objects  Protected objects to check for auto protections.
   * @returns  True if any specified objects are auto protected.
   */
  isAnyAutoProtected(objects: ProtectedObjectItems): boolean {
    return objects?.protectedObjects?.some(object => isObjectAutoProtected(object));
  }
}

import { DecimalPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseRendererComponent } from '../base-renderer.component';

/**
 * Available params that can be specified for difference data
 */
export interface NumberDataRendererParams {
  /**
   * Whether there is a suffix
   */
  suffix?: string;

  /**
   * Number pipe format param value
   */
  format?: string;

  /**
   * Show value as < limit if value is too small. Assume value > 0.
   */
  limit?: number;
}

/**
 * Renders a simple number cell.
 */
@Component({
  selector: 'cog-number-data-renderer',
  template: `{{
      renderParams?.limit && value > 0 && value < renderParams?.limit
        ? '< ' + renderParams?.limit
        : (value | number: renderParams?.format)
    }}{{ renderParams?.suffix }}`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [DecimalPipe],
})
export class NumberDataRendererComponent extends BaseRendererComponent<number, NumberDataRendererParams> {}

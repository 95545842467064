import { envGroups, Environment, enumGroupMap } from '@cohesity/iris-shared-constants';

/**
 * Additional environments for reporting.
 */
export enum ReportOnlyEnvironment {
  // M365
  kSite = 'kSite',

  // IBM
  kOther = 'kOther',
  kUnknown = 'kUnknown',
}

/**
 * All Environments including reporting.
 */
export type ReportEnvironment = Environment | ReportOnlyEnvironment;

/**
 * Environment group including reporting environments.
 */
export const reportEnvGroups = {
  ...envGroups,
  uncategorized: [
    ReportOnlyEnvironment.kOther,
    ReportOnlyEnvironment.kUnknown,
  ],
};

/**
 * Reverse mapping from enum to envGroup.
 */
export const reportEnumGroupMap = {
  ...enumGroupMap,
  [ReportOnlyEnvironment.kOther]: 'uncategorized',
  [ReportOnlyEnvironment.kUnknown]: 'uncategorized',
};

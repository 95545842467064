import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { CloudProvider, DmaasRegion } from '@cohesity/api/dms';
import { SnackBarService } from '@cohesity/helix';
import { flagEnabled, getConfigByKey, IrisContextService } from '@cohesity/iris-core';
import { Environment } from '@cohesity/iris-shared-constants';
import { TranslateService } from '@ngx-translate/core';
import { Controls, NgxSubFormComponent, subformComponentProviders, takeUntilDestroyed } from 'ngx-sub-form';
import { Observable } from 'rxjs';
import { delay, filter, finalize, map, tap } from 'rxjs/operators';
import { deploymentPlatformList } from 'src/app/shared/constants/deployment-platform.constants';
import { isIbmBaaSEnabled } from '@cohesity/iris-core';
import { SaaSConnection } from '../../interfaces';
import { Connection } from '../../interfaces/connection';
import { ConnectionSetupService } from '../../services';
import { ClusterMetadataService } from 'src/app/core/services';

/**
 * Holds the verification status of the connection.
 *
 * NotDone - Verification has not started.
 * InProgress - Verification in progress.
 * Verified - Connection is verified.
 */
type VerificationStatus = 'NotDone' | 'InProgress' | 'Verified';

/**
 * SaaS Connection modal dialog optional configuration data.
 */
export interface ConnectionSetupConfig {
  isNewConnection: boolean;
}

@Component({
  selector: 'coh-connection-setup-dialog',
  templateUrl: './connection-setup-dialog.component.html',
  styleUrls: ['./connection-setup-dialog.component.scss'],
  providers: subformComponentProviders(ConnectionSetupDialogComponent),
})
export class ConnectionSetupDialogComponent extends NgxSubFormComponent<SaaSConnection> implements OnInit {
  /**
   * Represents the type of source being registered (Currently holds only hyperV).
   */
  @Input() sourceType: string;

  /**
   * Represents whether editing the registration.
   */
  @Input() editMode = false;

  /**
   * Connection available regions.
   */
  @Input() regionTypes: CloudProvider[];

  /**
   * Specifies whether we have to perform close dialog action or not.
   */
  @Input() removeCloseDialogAction = false;

  /**
   * Show connection description for IBM
   */
  showIBMNewConnectionDesc = false;

  /**
   * Available filtered regions.
   * Filter out Azure Region if enableAzureRegionsForSaasConnections flag is false.
   */
  readonly availableRegions$: Observable<DmaasRegion[]> = this.connectionService.availableRegions$.pipe(
    map(regions => regions?.filter(
      region => (this.config?.isNewConnection ?
        (flagEnabled(this.irisContext.irisContext, 'enableAzureRegionsForSaasConnections') ?
          true : region.type !== 'Azure') :
          !this.regionTypes?.length || this.regionTypes?.includes(region.type))
      )
    )
  );

  /**
   * Show or hide deployment platform dropdown based on registration work flow.
   */
  get showDeploymentPlatform(): boolean {
    return this.config?.isNewConnection || this.sourceType === Environment.kHyperV;
  }

  /**
   * Get the type of image url depending on the type of deployment platform selected
   */
  get imageUrl(): string {
    if (this.currentConnection) {
      switch (this.deploymentPlatformValue) {
        // If deployment platform chosen is HyperV then return VHD link
        case Environment.kHyperV:
          return this.currentConnection?.imageUrlVHD;

        // Otherwise return OVA url
        default:
          return this.currentConnection?.imageUrl;
      }
    }
  }

  /**
   * Check if image url is available or not
   */
  get isImageUrlUnavailable(): boolean {
    switch (this.deploymentPlatformValue) {
      // If deployment platform chosen is HyperV then check if imageUrlVhd is available
      case Environment.kHyperV:
        return !this.currentConnection?.imageUrlVHD;

      // Otherwise check if OVA url is available
      default:
        return !this.currentConnection?.imageUrl;
    }
  }

  /**
   * Get the title for deployment connector depending on deployment platform type chosen
   */
  get deployConnectorStepTitle(): string {
    switch (this.deploymentPlatformValue) {
      // If deployment platform chosen is HyperV
      case Environment.kHyperV:
        return this.translate.instant('dms.deployConnectorHyperV');

      // Otherwise return common deploy connector title
      default:
        return this.translate.instant('dms.deployConnector');
    }
  }

  /**
   * Get the text for copy url depending on deployment platform type chosen
   */
  get copyUrlText(): string {

    switch (this.deploymentPlatformValue) {
      // If deployment platform chosen is HyperV return Vhd Url text
      case Environment.kHyperV:
        return this.translate.instant('copyVhdUrl');
      // Otherwise return ova url text
      default:
        return this.translate.instant('copyOvaUrl');
    }
  }

  /**
   * Get the value of deployment platform form control
   */
  get deploymentPlatformValue(): string {
    return this.formGroup.get('deploymentPlatform').value;
  }

  /**
   * Returns snackbar message when connection dialog is closed.
   */
  get closeDialogMessage(): string {
    if (!this.currentConnection) {
      return '';
    }

    return this.translate.instant('createdConnectionName', {
      name: this.currentConnection.groupName
    });
  }

  /**
   * Holds the deployment platform list.
   */
  deploymentPlatform = deploymentPlatformList;

  /**
   * Whether to show ports in the connections page.
   */
  showPorts = false;

  /**
   * Flag to indicates whether a connection is created.
   */
  connectionCreated = false;

  /**
   * Flag to indicates whether delete connection is in progress.
   */
  connectionDeleting = false;

  /**
   * Current selected Connection
   */
  currentConnection: Connection;

  /**
   * Holds whether the connection is being verified.
   */
  verifying: VerificationStatus = 'NotDone';

  /**
   * Outbound port table columns.
   */
  readonly portColumns = ['port', 'target', 'direction'];

  /**
   * Checks if a cluster is an IBM Baas cluster or not
   */
  isIbmCluster = isIbmBaaSEnabled(this.irisContext.irisContext);

  /**
   * Boolean to check if download installer button needs to be hidden.
   */
  hideDownloadInstallerButton = getConfigByKey(this.irisContext.irisContext,
    'saasConnections.hideDownloadSaasConnectorInstaller', true);

  /**
   * Provide configured help docs url.
   */
  get helpLink() {
    return this.clusterMetadataService.getDocUrl('HelpDocs');
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() readonly config: ConnectionSetupConfig,
    private dialogRef: MatDialogRef<ConnectionSetupDialogComponent>,
    private clipboard: Clipboard,
    private irisContext: IrisContextService,
    private snackBarService: SnackBarService,
    private translate: TranslateService,
    private clusterMetadataService: ClusterMetadataService,
    readonly connectionService: ConnectionSetupService,
  ) {
    super();
  }

  protected getFormControls(): Controls<SaaSConnection> {
    return {
      connection: new UntypedFormControl(),
      region: new UntypedFormControl(),
      deploymentPlatform: new UntypedFormControl(),
    };
  }

  ngOnInit() {
    // For IBM, show information for creating new connections
    if (isIbmBaaSEnabled(this.irisContext.irisContext)) {
      this.showIBMNewConnectionDesc = true;
    }
    this.connectionService.reset();
    this.connectionService.GetAvailableRegions();

    this.connectionService.selectedConnection$
      .pipe(
        takeUntilDestroyed(this),
        filter(result => !!result && !!this.formGroup),
        tap(connection => {
          // TODO(ENG-198815): Replace with async validator for the connection control.
          this.currentConnection = connection;
          this.formGroupControls.connection.setValue(connection);

          this.formGroupControls.connection.setErrors({
            verified: false,
          });

          // Trigger verification only if verification is triggered.
          if (this.verifying === 'InProgress' && connection.status === 'Connected') {
            this.formGroupControls.connection.setErrors(null);
            this.verifying = 'Verified';
          }
        }),
        filter(() => this.verifying === 'InProgress'),
        delay(10000)
      )
      .subscribe(connection => {
        // During the delay the verification status might change when the user clicks on
        // edit connection.
        if (this.verifying !== 'InProgress') {
          return;
        }
        this.connectionService.GetConnection(connection.groupId, connection.regionId);
      });
  }

  /**
   * Copies OVA/VHD template URL to clipboard.
   */
  copyUrl() {
    if (!this.isImageUrlUnavailable) {
      this.clipboard.copy(this.imageUrl);
      const msg = this.translate.instant('copiedToClipboard', {
        item: 'Data',
      });
      this.snackBarService.open(msg, 'success');
    }
  }

  /**
   * Handles form cancel button click event.
   */
  cancelForm() {
    this.connectionService.closeCreateConnection();
  }

  /**
   * Downloads the image in new tab.
   */
  downloadImage() {
    window.open(this.imageUrl, '_blank');
  }

  /**
   * Handles the creation of region.
   *
   * @param   regionId   The region to be created.
   */
  handleRegion(regionId: string) {
    this.connectionService.CreateConnection(regionId);
    this.connectionCreated = true;
  }

  /**
   * View the port information for the selected connection.
   */
  viewPortInformation() {
    if (!this.connectionService.selectedConnection$.value) {
      this.showPorts = false;
      return false;
    }
    this.showPorts = !this.showPorts;
  }

  /**
   * Edit a selected connection.
   */
  editConnection() {
    this.verifying = 'NotDone';
    this.editMode = false;
  }

  /**
   * Cancel connection creation which will delete the created connection.
   * Or just close dialog if connection is not created yet.
   */
  cancel() {
    if (this.connectionCreated && this.currentConnection?.groupId) {
      this.connectionDeleting = true;
      this.connectionService.DeleteConnection(this.currentConnection.groupId).pipe(
        finalize(() => (this.connectionDeleting = false)),
        takeUntilDestroyed(this),
      ).subscribe(
        () => this.removeCloseDialogAction ? this.cancelForm() : this.dialogRef.close()
      );
    } else if (this.removeCloseDialogAction) {
      this.cancelForm();
    } else {
      this.dialogRef.close();
    }
  }

  /**
   * Skip verification during the connection creation and close dialog opened.
   */
  skipVerificationAndCloseDialog() {
    if (this.removeCloseDialogAction) {
      this.cancelForm();
    } else {
      this.dialogRef.close(this.closeDialogMessage);
    }
  }

  /**
   * Verify the connection to check whether the rigel is connected or not.
   */
  verify() {
    this.verifying = 'InProgress';
    const { groupId, regionId } = this.currentConnection;
    this.connectionService.GetConnection(groupId, regionId);
  }
}

// Service: Source Service (all things related to entities)

(function(angular, undefined) {
  'use strict';

  /**
   * Source Data [DEPRECATED]
   * Variable declarations to be used in conjunction with job source management
   * TODO: Get rid of this completely (still used in reports)
   */
  angular.module('C').factory('SourceData', function SourceDataFn() {
    return {
      object: {},
    };
  });

  // Source Service
  angular
    .module('C.sourceService', ['C.sourceServiceFormatter'])
    .service('SourceService', SourceServiceFn);

  function SourceServiceFn($rootScope, $http, $q, $cacheFactory, $stateParams,
    _, $filter, $uibModal, SlideModalService, Routes, cModal, cMessage, cUtils,
    evalAJAX, API, ENUM_ENTITY_TYPE, ENTITY_KEYS, ENUM_HOST_TYPE_CONVERSION,
    ENUM_CONNECTION_STATE, ENUM_GUEST_TOOLS_STATUS, ENUM_HOST_TYPE,
    PUB_TO_PRIVATE_ENV_STRUCTURES, ENUM_FILESYSTEM_TYPE, FEATURE_FLAGS,
    ENUM_FILESYSTEM_TYPE_GENERIC, ENV_GROUPS, SourceServiceFormatter,
    ENV_TYPE_CONVERSION, ENUM_FILESYSTEM_TYPE_FLASHBLADE, NETWORKING_INFO_MAP,
    NAS_ENTITY_KEY_DATA_PROTOCOL_MAP, ngDialogService, OFFICE365_GROUPS,
    EXCHANGE_GROUPS, ORACLE_DG_DB_ROLE_TYPE, NgPassthroughOptionsService,
    NgAgentServiceApi) {

    var service = {
      addNodeToTagSet: addNodeToTagSet,
      addToJobTagSets: addToJobTagSets,
      areProtocolsSupported: areProtocolsSupported,
      bindAagDetailsToNode: bindAagDetailsToNode,
      browseForLeafEntities: browseForLeafEntities,
      buildSourceEntitiesOfTypeParams: buildSourceEntitiesOfTypeParams,
      calculateOracleChannnels: calculateOracleChannnels,
      createSource: createSource,
      deleteSource: deleteSource,
      deleteSourceModal: deleteSourceModal,
      downloadAgentsModal: downloadAgentsModal,
      entitiesHash: {},
      fetchAndCacheAppEntities: fetchAndCacheAppEntities,
      findNodeInHierarchy: findNodeInHierarchy,
      flatSources: [],
      getAagInfo: getAagInfo,
      getAppEntities: getAppEntities,
      getDatastores: getDatastores,
      getEntitiesById: getEntitiesById,
      getEntitiesOfType: getEntitiesOfType,
      getEntityHostOSType: getEntityHostOSType,
      getEntityKey: getEntityKey,
      getEntityName: getEntityName,
      getEntityType: getEntityType,
      getEntityTypeName: getEntityTypeName,
      getFSProtocols: getFSProtocols,
      getIndexedObjects: getIndexedObjects,
      getNetworkEntities: getNetworkEntities,
      getOneDriveId: getOneDriveId,
      getOracleAgentIdToHostAddressMap: getOracleAgentIdToHostAddressMap,
      getOracleHostAddressToAgentIdMap: getOracleHostAddressToAgentIdMap,
      getProtectionSources: getProtectionSources,
      getRegisterableHostObject: getRegisterableHostObject,
      getResourcePool: getResourcePool,
      getServers: getServers,
      getSourceReport: getSourceReport,
      getSources: getSources,
      getSourceStats: SourceServiceFormatter.calculateSourceInfo,
      getTree: getTree,
      getTypedEntity: getTypedEntity,
      getVirtualMachines: getVirtualMachines,
      getVmFolders: getVmFolders,
      getStorageProfile: getStorageProfile,
      isExchangeRegistered: isExchangeRegistered,
      isOracleDgPrimaryDatabase: isOracleDgPrimaryDatabase,
      isQuiesceCompatible: isQuiesceCompatible,
      isSameEntity: isSameEntity,
      isSystemDatabase: isSystemDatabase,
      isTdeDatabase: isTdeDatabase,
      knownVMs: getVmsFromSearchResults,
      listContainsVM: containsVmFn,
      nodeMatchesTagSet: nodeMatchesTagSet,
      normalizeEntity: normalizeEntity,
      openSystemBackupFailWarningModal: openSystemBackupFailWarningModal,
      parentSourceIds: [],
      processHierarchyBranch: processHierarchyBranch,
      publicEntitiesToPrivateEntities: publicEntitiesToPrivateEntities,
      publicEntityToPrivateEntity: publicEntityToPrivateEntity,
      reconstructRequestObjectFromSource: reconstructRequestObjectFromSource,
      refreshSource: refreshSource,
      registerAppOwner: registerAppOwner,
      registerSourceSlider: registerSourceSlider,
      selectNode: selectNode,
      selectSourceTypeSlider: selectSourceTypeSlider,
      showAagNodeSelectDialog: showAagNodeSelectDialog,
      sourcesHash: {},
      dbHostsCache: {},
      unregisterAppOwner: unregisterAppOwner,
      unselectNode: unselectNode,
      updateAppOwner: updateAppOwner,
      updateSource: updateSource,
      upgradeAgents: upgradeAgents,
      upgradeSourceApi: upgradeSourceApi
    };

    /** @type {Object} cacheFactory object,
     *                 see angular docs or source for details:
     *                 https://docs.angularjs.org/api/ng/type/$cacheFactory.Cache
     */
    var sourcesCache = $cacheFactory('sourcesCache');

    /**
     * Hash based on PUB_TO_PRIVATE_ENV_STRUCTURES but extended to allow int >
     * kEnum and kEnum > int lookups.
     *
     * @type   {object}
     */
    var ENV_TYPES_MAPPER = SourceServiceFormatter.buildEnvTypesMapper();

    /**
     * Running list of known VMs from file search results
     *
     * @type {Array}
     */

    // TODO: Make this more generic for vm search results too
    var knownFileSearchVMs = [];

    /**
     * Builds source entitiesOfType parameters.
     *
     * @method   buildSourceEntitiesOfTypeParams
     * @param    {string|integer|array}   types   Single or array of envType
     *                                            ints, kEnvType strings, or
     *                                            combo of the two.
     * @return   {Object}                 The source entitiesOfType parameters.
     */
    function buildSourceEntitiesOfTypeParams(types, excludeTypes) {
      types = types ?
        [].concat(types) :
        Object.keys(PUB_TO_PRIVATE_ENV_STRUCTURES);

      excludeTypes = excludeTypes ? [].concat(excludeTypes) : undefined;

      return types.reduce(
        function eachType(out, type) {
          var thisEnv = ENV_TYPES_MAPPER[type] || {};

          // If types was undefined (~gimme all types), or this env has the
          // required properties...
          if (thisEnv.entityTypesQueryParam) {
            // Push this envTypeEnum to the params, and...
            out.environmentTypes.push(thisEnv.envTypeEnum);

            // Reduce the listed source entity typeEums to the type-specific
            // query param.
            out[thisEnv.entityTypesQueryParam] =
              Object.keys(
                PUB_TO_PRIVATE_ENV_STRUCTURES[thisEnv.envTypeEnum].sourceEntityTypes
              );

            if (excludeTypes) {
              _.remove(out[thisEnv.entityTypesQueryParam], function (entityType) {
                return excludeTypes.includes(entityType);
              });
            }
          }

          return out;
        },

        { environmentTypes: [] }
      );
    }

    /**
     * Gets AAG data for the given source Ids (VMs and Physical Servers).
     *
     * @method   getAagInfo
     * @param    {number|array}   sourceIds   One or more source ID numbers.
     * @return   {object}         Promise to resolve with an array of AAG info,
     *                            or the raw response if error.
     */
    function getAagInfo(sourceIds) {
      return $http({
        method: 'get',
        url: API.public('protectionSources/sqlAagHostsAndDatabases'),
        params: { sqlProtectionSourceIds: sourceIds},
      }).then(SourceServiceFormatter.transformAagInfo);
    }

    /**
     * Get entities by type and environment
     * @docs     https://{node}/docs/restApiDocs/bootprintinternal/#operation--entitiesOfType-get
     *
     * @method   entitiesOfType
     * @param    {object=}    params   Query params: {
     *                                     rootEntityId: int64
     *                                     environmentTypes: string[1, 2, 3, 4, 7]
     *                                     azureEntityTypes: string[]
     *                                     physicalEntityTypes: string[]
     *                                     pureEntityTypes: string[0, 1]
     *                                     viewEntityTypes: string[]
     *                                     vmwareEntityTypes: string[]
     *                                     isProtected: bool
     *                                 }
     * @return   {object}              Promise to resolve with entities list,
     *                                 or raw error.
     */
    function getEntitiesOfType(params) {
      var opts = {
        method: 'get',
        url: API.private('entitiesOfType'),
        params: params,
      };

      return $http(opts).then(function gotEntities(resp) {
        return (resp.data || []).map(function(entity) {
          return service.entitiesHash[entity.id] = preProcessEntity(entity);
        });
      });
    }

    /**
     * Pre-process Source Entity with some convenience data.
     *
     * @method   preProcessEntity
     * @param    {object}   entity   The Source.
     * @return   {object}   The enhanced Source.
     */
    function preProcessEntity(entity) {
      return angular.extend(entity, {
        _entityKey: getEntityKey(entity.type),
        _typeEntity: getTypedEntity(entity),
      });
    }

    /**
     * Get protection sources
     * @docs https://{node}/docs/restApiDocs/bootprintinternal
     * /#operation--public-protectionSources-rootNodes-get
     *
     * @method  protectionSources
     * @param {object} [params] Query params: {
     *                            id: int64
     *                            environment: string[]
     *                          }
     *
     * @return {object} [Promise to resolve with entities list]
     */
    function getProtectionSources(params) {
      var opts = {
        method: 'get',
        url: API.public('protectionSources/rootNodes'),
        params: params,
      };

      return $http(opts).then(function gotSources(resp) {
        return resp.data[0];
      });
    }

    /**
     * Convenience function to get all Server entities (VM+Physical)
     *
     * @method   getServers
     * @param    {bool=}   isProtected   True get only protected Servers.
     *                                   False gets only unprotected Servers.
     *                                   Undefined gets all known Servers.
     * @return   {object}                Promise to resolve with the list of
     *                                   servers, or a raw Error
     */
    function getServers(isProtected) {
      var params = {
        environmentTypes: ['kVMware', 'kPhysical'],
        vmwareEntityTypes: ['kVirtualMachine'],
        physicalEntityTypes: ['kHost'],
        isProtected: isProtected,
      };

      return getEntitiesOfType(params);
    }

    /**
     * Convenience function that will set params according to objects that
     * are indexable and will return getEntitiesOfType API call
     *
     * @param    {Boolean}    isProtected    if True get only protected objects
     *                                       false gets only unprotected objects
     *                                       undefined gets all objects
     *
     * @return   {object}                   promise to resolve with list of
     *                                      servers
     */
    function getIndexedObjects(isProtected) {
      // TODO (Tauseef): figure out why is this not same as
      // ENV_GROUPS.indexableEntitiesExposedAsServers +
      // indexableEntitiesExposedAsViews

      var params = {
        environmentTypes: [
          'kVMware',
          'kPhysical',
          'kView',
          'kGenericNas',
          'kIsilon',
          'kNetapp',
        ].concat(cUtils.onlyStrings(ENV_GROUPS.cloudSources)),
        vmwareEntityTypes: ['kVirtualMachine'],
        awsEntityTypes: ['kEC2Instance'],
        azureEntityTypes: ['kVirtualMachine'],
        gcpEntityTypes: ['kVirtualMachine'],
        genericNasEntityTypes: ['kHost'],
        isilonEntityTypes: ['kMountPoint'],
        netappEntityTypes: ['kVolume'],
        physicalEntityTypes: ['kHost'],
        viewEntityTypes: ['kView'],
        isProtected: isProtected,
      };

      return getEntitiesOfType(params);
    }

    /**
     * Detects the given Entity's hostType and returns that integer.
     *
     * @method    getEntityHostOSType
     * @param     {object}     typedDoc     The typedDocument to query
     * @return    {integer}                 The detected type.
     *                                      Default = 0
     */

    // TODO: If other hostTypes are added update this to be more dynamic.
    function getEntityHostOSType(typedDoc) {
      // set the default host type to 'Unknown OS'
      var defaultHostType = -1;
      var type;

      // Sanity check
      if (!typedDoc) {
        return defaultHostType;
      }

      // The OS type does not makes sense for nas, views, RA adapters
      if (ENV_GROUPS.nas.includes(typedDoc.objectId.entity.type) ||
        ENV_GROUPS.spanFS.includes(typedDoc.objectId.entity.type)) {
        return undefined;
      }

      // If type is undefined and osType *is* defined...
      if (typedDoc.osType &&

        // and osType is not "unknown" nor "windows"...
        !/(unknown)/i.test(typedDoc.osType)) {
        type = undefined;
      }

      // When hostType is present, we know the type
      // Get the typedEntity (physical or vm) for this object
      if (angular.isUndefined(type)) {
        type = getTypedEntity(typedDoc.objectId.entity).hostType;
      }

      // if type is still undefined, revert to good old string mapping
      if (angular.isUndefined(type) && angular.isDefined(typedDoc.osType)) {
        // this is to convert string to number. 'Linux' -> 0
        type =
          ENUM_HOST_TYPE_CONVERSION[_.invert(ENUM_HOST_TYPE)[typedDoc.osType]];
      }

      // If we identified a type, return it, otherwise return the default
      return (angular.isDefined(type)) ? type : defaultHostType;
    }

    /**
     * Get the typedEntity object from a given entity.
     *
     * @method   getTypedEntity
     * @param    {object}             entity   The given Entity object
     * @return   {object|undefined}            The vmwareEntity, physicalEntity,
     *                                         viewEntity, etc object as
     *                                         detected from the keys in
     *                                         ENTITY_KEYS
     *
     */
    function getTypedEntity(entity) {
      return !!entity && (entity.type > 0) && !!ENTITY_KEYS[entity.type] ?

        // When all are truthy, this is what is returned
        entity[ENTITY_KEYS[entity.type]] :

        undefined;
    }

    /**
     * Gets a list of application entities based on provided params
     * (i.e. appEnvType)
     *
     * @param      {object}   params  The parameters
     * @return     {object}  promise to resolve request. On success
     *                        resolves with array of app entities. on
     *                        failure rejects with raw server response
     */
    function getAppEntities(params) {
      var opts = {
        method: 'get',
        url: API.private('appEntities'),
        params: params,
      };

      return $http(opts).then(
        function getEntitiesSuccess(resp) {
          return (resp.data || [])
            // Add a few helper properties before returning data
            .map(function eachAppEntity(entity) {
              return angular.extend(entity, {
                _appEntityKey: getEntityKey(entity.appEntity.entity.type),
                _appTypeEntity: getTypedEntity(entity.appEntity.entity),
              });
            });
        }
      );

    }

    /**
     * Unregister an App Owner (SQL, etc)
     *
     * @method     unregisterAppOwner
     * @param      {Object}   data    Data payload
     * @return     {object}  The response data, or the raw response if
     *                        error
     */
    function unregisterAppOwner(data) {
      return modifyAppOwner(data, 'delete');
    }

    /**
     * Register an App Owner (SQL, etc)
     *
     * @method     registerAppOwner
     * @param      {Object}   data    RegisterAppOwnerArg
     * @return     {object}  The response data, or the raw response if
     *                        error
     */
    function registerAppOwner(data) {
      return modifyAppOwner(data, 'post');
    }

    /**
     * Update an App Owner (SQL, etc)
     *
     * @method     updateAppOwner
     * @param      {Object}   data    RegisterAppOwnerArg
     * @return     {object}  The response data, or the raw response if
     *                        error
     */
    function updateAppOwner(data) {
      return modifyAppOwner(data, 'put');
    }

    /**
     * Reusable method to register/modify an App owner. For the same
     * endpoint and configurable HTTP method.
     *
     * @method     modifyAppOwner
     * @param      {Object}   data    RegsiterAppOwnerArg
     * @param      {String=}  method  Can be one of 'post' or 'put'.
     *                                Defaults to 'post'
     * @return     {object}  The response data, or the raw response if
     *                        error
     */
    function modifyAppOwner(data, method) {
      var opts = {
        method: method || 'post',
        url: API.private('applicationSourceRegistration'),
        data: data,
        headers: NgPassthroughOptionsService.requestHeaders
      };

      return $http(opts)
        .then(function registereSuccessFn(resp) {
          var out = {};

          if (resp.data) {
            angular.extend(out, resp.data, {
              _normalizedEntity: normalizeEntity(resp.data),
            });
          }

          return out;
        });
    }

    /**
     * Fetches a flat list of known VM ENtityProtos (optionally by
     * vCenterId)
     *
     * @method     getVirtualMachines
     * @param      {Integer=}  vCenterId  Optional vCenterId to restrict
     *                                    search scope
     * @return     {Array}     The array, or the raw server response if
     *                         failed
     */
    function getVirtualMachines(params) {
      var opts = {
        method: 'get',
        url: API.private('virtualMachines'),
        params: params,
      };

      return $http(opts)
        .then(function vmsListReceived(resp) {
          return (resp && resp.data) ? resp.data : [];
        });
    }

    /**
     * Registers a new source to the cluster
     *
     * @method   createSource
     * @param    {object}   data   Source data reg payload
     * @return   {object}          Promise to resolve with new source data,
     *                             or raw server response if failed.
     */
    function createSource(data) {
      return $http({
        method: 'post',
        url: API.private('backupsources'),
        data: data,
        headers: NgPassthroughOptionsService.requestHeaders
      }).then(
        function createSourceSuccess(resp) {
          sourcesUpdateSuccess(resp);

          return preProcessEntity(resp.data);
        }
      );
    }

    /**
     * Updates a source registered on the cluster
     *
     * @method     updateSource
     * @param      {object}  source  Source data reg payload
     * @return     {object}  Promise to resolve with updated source data, or raw
     *                       server response if failed.
     */
    function updateSource(source) {
      return $http({
        method: 'put',
        url: API.private('backupsources', source.entity.id),
        data: source,
      }).then(sourcesUpdateSuccess);
    }

    /**
     * Unregisters a source from the cluster
     *
     * @method   deleteSource
     * @param    {integer}  id     The Source id to update
     * @return   {object}          Promise to resolve with {something?},
     *                             or raw server response if failed.
     */
    function deleteSource(id) {
      return $http({
        method: 'delete',
        url: API.private('backupsources', id),
      }).then(sourcesUpdateSuccess);
    }

    /**
     * This function is exactly the same as getSources, but the paramters passed
     * in will be different, just here for the sake of purpose based naming
     *
     * @method   refreshSource
     * @param    {object}    params               The parameters
     * @param    {boolean}   [showMessage=true]   true to show cMessage
     * @return   {object}    promise to resolve api request
     */
    function refreshSource(params, showMessage) {
      showMessage = angular.isDefined(showMessage) ? showMessage : true;

      // broadcast start of action
      $rootScope.$broadcast('sourceRefreshStart');

      params = params ? params : {};

      return $http({
        method: 'get',
        url: API.private('backupsources'),
        params: params,
      }).then(
        function refreshSuccess(response) {
          if (showMessage) {
            cMessage.success({ textKey: 'sources.refresh.success' });
          }

          return sourcesUpdateSuccess(response);
        }
      ).finally(
        function refreshFinally() {
          // broadcast end of action
          $rootScope.$broadcast('sourceRefreshComplete');
        }
      );
    }

    /**
     * get list of sources from the API
     *
     * @param    {Object}    data               optional API parameters, i.e {
     *                                          onlyReturnOneLevel: true }
     * @param    {Boolean}   [useCache=false]   Use cached values instead of
     *                                          fetching a new.
     * @return   {object}    promise to resolve api request.
     */
    function getSources(params, useCache) {
      var cacheKey;
      var cachedRequest;

      service.parentSourceIds.length = 0;

      // Default useCache to false;
      useCache = !!useCache;

      // Default params for use as a caching key
      params = params || {};

      cacheKey = JSON.stringify(params);
      cachedRequest = sourcesCache.get(cacheKey);

      if (useCache && cachedRequest) {
        return $q.resolve(cachedRequest);
      }

      cUtils.selfOrDefault(params, 'allUnderHierarchy', true);
      return $http({
        method: 'get',
        url: API.private('backupsources'),
        params: params,
        headers: NgPassthroughOptionsService.requestHeaders
      })
      .then(function requestSuccess(resp) {
        // Do some light preprocessing
        if (resp.data && resp.data.entityHierarchy) {
          resp.data.entityHierarchy.children =
            resp.data.entityHierarchy.children ||
            [];

          resp.data.entityHierarchy.children.forEach(
            function eachSource(source) {
              source._entityKey = getEntityKey(source.entity.type);
              source._typeEntity = getTypedEntity(source.entity);
              service.sourcesHash[source.entity.id] = source.entity;
              service.parentSourceIds.push(source.entity.id);
            }
          );
        }

        sourcesCache.put(cacheKey, resp.data);

        if (params.onlyReturnOneLevel && resp.data) {
          angular.extend(service.flatSources,
            flattenSourceTree(resp.data));
        }

        return resp.data;
      });

    }

    /**
     * converts a public API source entity into a private API entity
     *
     * @param      {object}  pubEntity  The public API entity
     * @return     {object}  the derived private API entity
     */
    function publicEntityToPrivateEntity(pubEntity) {
      // the entity is not a well formed public entity or is not a public
      // entity at all. Return it as it is.
      if (!_.get(pubEntity, 'environment')) {
        return pubEntity;
      }

      var envStruct = PUB_TO_PRIVATE_ENV_STRUCTURES[pubEntity.environment];

      if (!envStruct) {
        return pubEntity;
      }

      var typedSource = pubEntity[envStruct.publicEntityKey];
      var privateEntity = {
        id: pubEntity.id,
        parentId: pubEntity.parentId,
        type: envStruct.envType,
        displayName: pubEntity.name,
      };

      // gate based on typedSource, as this conversion can work even with the
      // limited info already established, and API is currently missing the
      // typedSource for (at least) hyperV entities.
      if (typedSource) {
        privateEntity[envStruct.privateEntityKey] = {
          type: envStruct.entityTypes[typedSource.type],
          hostType: typedSource.hostType,
          name: typedSource.name,
          networkingInfo: _getPrivateNetworkingInfo(typedSource.networkingInfo)
        };
      }

      return privateEntity;
    }

    /**
     * converts a public NetworkingInfo object to private api entity
     *
     * @method     _getPrivateNetworkingInfo
     * @param      {object}  info  The public networking info API entity
     * @return     {object}  private networking info API entity
     */
    function _getPrivateNetworkingInfo(info) {
      if (!info || !info.resourceVec) { return; }

      var resourceVec = info.resourceVec.map(
        function transformToPrivate(resource) {
          return {
            endpointVec: _.clone(resource.endpoints),
            type: NETWORKING_INFO_MAP[resource.type]
          };
        });

      return _.set(_.cloneDeep(info), 'resourceVec', resourceVec);
    }

    /**
     * Convert a set of public entities into private entities
     *
     * @method   publicEntitiesToPrivateEntities
     * @param    {Object}  entities   The entities to be transformed
     * @return   {Object}  The transformed entities
     */
    function publicEntitiesToPrivateEntities(entities) {
      // dont modify the original entities
      entities = angular.copy(entities);

      _.each(entities, function forEachEntity(entity, key) {
        // Network Security Group is an array of objects.
        // It needs to be recursively converted.
        entities[key] =
          _.isArray(entity) ? publicEntitiesToPrivateEntities(entity) :
            publicEntityToPrivateEntity(entity);
      });

      return entities;
    }

    /**
     * Calls the API to get entity(s) by id
     *
     * @method     getEntitiesById
     * @param      {Array|Integer}  ids     One or more Entity Ids
     * @return     {object}        The list of entities requested
     */
    function getEntitiesById(ids) {
      return $http({
        method: 'get',
        url: API.private('backupEntities'),
        params: { entityIds: [].concat(ids) },
      }).then(function getEntitiesSuccess(resp) {
        if (resp.data.length) {
          buildEntitiesHash(resp.data);
        }

        return resp.data || [];
      });
    }

    /**
     * build a hash of our entities
     *
     * @param    {array}    entities    array of entity objects
     */
    function buildEntitiesHash(entities) {
      entities.forEach(function(entity) {
        service.entitiesHash[entity.id] = entity;
      });
    }

    /**
     * calls the api to get the sources report
     *
     * @param  {Object} registeredEntityReportArg filters for the report
     * @return {object}                          promise to resolve the API call
     */
    function getSourceReport(registeredEntityReportArg) {
      return $http({
        method: 'get',
        url: API.private('reports/backupsources'),
        params: registeredEntityReportArg,
      });
    }

    /**
     * Generates a normalized entity object with relevent environment and entity
     * properties, plus some helper properties. Reference ENUM_ENTITY_TYPE.
     *
     * @method    normalizeEntity
     * @param     {Object}   entity   The leaf entity object.
     * @returns   {Object}   The normalized leaf entity object.
     */
    function normalizeEntity(entity) {
      var typedEntity;
      var entityKey;
      var displayName;
      var dataProtocolNumbers;
      var dataProtocols;
      var nasHostEntityName;

      if (!entity || !ENTITY_KEYS[entity.type]) {
        return {};
      }

      entityKey = ENTITY_KEYS[entity.type];
      typedEntity = entity[entityKey];

      // TODO(spencer): Remove after SQL testing done
      if (entity.type === 3) {
        entity.displayName = entity.displayName || getEntityTypeName(entity);
      }

      // AWS has missing 'displayName' at times
      if (entity.type === ENV_TYPE_CONVERSION.kAWS) {
        entity.displayName = entity.displayName || entity[entityKey].name ||
          entity[entityKey].commonInfo.name;
      }

      displayName = entity.displayName || '';

      // For a given NAS entity i.e Netapp / islon/ generic nas/ flash blade
      // supported data protocols is stored with different keys.
      // for more info check magneto/base/entity.proto
      dataProtocolNumbers =
        _.get(typedEntity, 'volumeInfo.dataProtocolVec') ||
        _.get(typedEntity, 'vserverInfo.dataProtocolVec') ||
        _.get(typedEntity, 'mountPointInfo.supportedProtocolVec') ||
        _.get(typedEntity, 'protocol') ||
        _.get(typedEntity, 'fileSystemInfo.supportedProtocolVec') ||
        _.get(typedEntity, 'filesetInfo.supportedProtocolVec') ||
        _.get(typedEntity, 'containerInfo.supportedProtocolVec') || false;

      if (_.isNumber(dataProtocolNumbers)) {
        dataProtocolNumbers = [dataProtocolNumbers];
      }

      // we get data protocols as numbers instead of KValues
      // convert them for better usability.
      dataProtocols = (dataProtocolNumbers || []).map(
        function returnEnum(protocolInt) {
          return NAS_ENTITY_KEY_DATA_PROTOCOL_MAP[entityKey][protocolInt];
        }
      );

      // NAS Host Entity display name
      nasHostEntityName =
        _.get(typedEntity, 'volumeInfo.vserverName') ||
        _.get(typedEntity, 'mountPointInfo.zoneId') ||
        _.get(typedEntity, 'filesetInfo.fileSystemName') || '';

      return {
        // @type  {integer|undefined}  - Entity connection state
        connectionState: typedEntity.connectionState,

        // @type  {string}  - ie. 'vmwareEntity', 'pureEntity'
        entityKey: entityKey,

        // @type  {integer}  - ie. 1 for kVMware
        environmentType: entity.type,

        // @type  {integer}  - unique entity id
        id: entity.id,

        // @type  {string}  - Entity name
        name: displayName,

        // @type  {string}  - Entity name lowercased
        nameLowerCase: displayName.toLowerCase(),

        // @type  {integer}  - Parent source id. Defaults to undefined because
        // in some cases, ie. phsyical entities, there is no parentId.
        parentId: +entity.parentId || undefined,

        // @type  {integer}  - ie. 8 == VM
        type: typedEntity.type,

        // @type  {string|undefined}  - ?
        uid: typedEntity.uid,

        // @type  {string|undefined}  - ?
        uuid: typedEntity.uuid,

        // @type {array} - data protocols Array
        // for NAS
        dataProtocols: dataProtocols,

        // @type {integer} - host Type { kLinux = 0; kWindows = 1; kOther = 2;
        //                               kAix = 3; kSolaris = 4; }
        hostType: typedEntity.hostType,

        // @type {boolean} - Entity is VM Template or not
        isVmTemplate: typedEntity.isVmTemplate,

        // @type {string} - NAS host entity display name
        nasHostEntityName: nasHostEntityName,
      };

    }

    /**
     * getter method for a specific entityKey
     *
     * @param      {Integer}  entityType  The environment type
     * @return     {String}   an entityKey
     */
    function getEntityKey(entityType) {
      return ENTITY_KEYS[entityType] || '';
    }

    /**
     * Checks to see whether the node is registered as exchange application.
     *
     * @method      isExchangeRegistered
     * @param      {object}  server  The server object
     * @return     {boolean} True if exchange registered, False otherwise
     */
    function isExchangeRegistered(node) {
      return node.entity.type === 1 && node.registeredEntityInfo &&
        (node.registeredEntityInfo.appEnvVec || []).includes(17) &&
        node.registeredEntityInfo.verificationStatus === 2 &&
        !node.registeredEntityInfo.verificationError;
    }

    /**
     * Utility function to compare two sources/entities and determine if
     * they represent the same entity
     *
     * @param     {object}   entity1   entity object
     * @param     {object}   entity2   entity object
     * @return    {boolean}            match or no
     */
    function isSameEntity(entity1, entity2) {
      var prop;
      if (_.get(entity1, "uuid")) {
        prop = 'uuid';
      } else if (_.get(entity1, "ownerId")) {
        prop = 'ownerId';
      } else {
        prop = 'id';
      }
      return entity1 && entity2 && _.isEqual(entity1[prop], entity2[prop]);
    }

    /**
     * Returns provided entity's name
     *
     * DEPRECATED: entity.displayName is now a reliable standard.
     *
     * @param      {Object}  entity  entity object
     * @return     {String}  entity name
     */
    function getEntityName(entity) {
      var text = $rootScope.text.servicesSourceService;
      var entityKey = entity && getEntityKey(entity.type);

      return entityKey ?
        entity[entityKey].name || entity.displayName : text.unknown;
    }

    /**
     * Get Entity Type name for an entity
     *
     * @param  {Object} An entity object
     * @return {String} name of the provided entity
     **/
    function getEntityTypeName(entity) {
      var entityKey = getEntityKey(entity.type);

      return ENUM_ENTITY_TYPE[entity.type][entity[entityKey].type];
    }

    /**
     * Get Entity Type for an entity
     *
     * @param      {object}  entity  entity object
     * @return     {string}  entity type of the provided entity
     */
    function getEntityType(entity) {
      var entityKey = getEntityKey(entity.type);

      return entity[entityKey].type;
    }

    /**
     * builds an index map of selected nodes for use
     * with SourceService.processHierarchyBranch
     *
     * @return {Object} index map
     */
    function createSelectedMap(job) {
      var selectedMap = {};

      if (angular.isDefined(job.sources)) {
        job.sources.forEach(function(source) {
          selectedMap[source.entities[0].id] = true;
        });
      }

      // For dbs, check the backup source params for specific databases
      // that might have been protected. This allows specific databases in a
      // volume protected job to show in the run now modal
      if (_.isArray(job.backupSourceParams)) {
        job.backupSourceParams.forEach(function processSourceParams(params) {
          if (_.isArray(params.appEntityIdVec)) {
            params.appEntityIdVec.forEach(function addDbId(id) {
              selectedMap[id] = true;
            });
          }
        });
      }

      return selectedMap;
    }

    /**
     * builds an index map of excluded nodes for use
     * with SourceService.processHierarchyBranch
     *
     * @return {Object} index map
     */
    function createExcludedMap(job) {
      var excludedMap = {};

      if (angular.isDefined(job.excludeSources)) {
        job.excludeSources.forEach(function(source) {
          excludedMap[source.entities[0].id] = true;
        });
      }

      return excludedMap;
    }

    /**
     * Select a node.
     *
     * @method   selectNode
     * @param    {Object}   node     To be selected.
     * @param    {Object}   [opts]   Options
     */
    function selectNode(node, opts) {

      var wasUnselected = !node._isSelected;

      opts = {
        autoProtect: !!opts.autoProtect,
        ancestorAutoProtect: !!opts.ancestorAutoProtect,
        ancestorExcluded: !!opts.ancestorExcluded,
        expandedNodes: opts.expandedNodes,
        selectedObjectsCounts: opts.selectedObjectsCounts,
        tagAutoProtect: !!opts.tagAutoProtect,
        tree: opts.tree || [],
        treeFilterExpressionFn: opts.treeFilterExpressionFn ||
          function() { return true; },

        // unexclude option used to explicitly unexclude a node
        unexclude: !!opts.unexclude,

        // For the sake of upgrade selection, need to distinguish between the
        // detail page and object selection
        detailedView: !!opts.detailedView,

        /**
         * List of restricted entityTypes to permit selection of. Undefined and
         * unrestricted by default.
         *
         * @type   {array}
         */
        onlyEntitiesOfType: opts.onlyEntitiesOfType,
      };

      // When a list of restricted entityTypes is given, exit early if this node
      // isn't of one of those types.
      if (Array.isArray(opts.onlyEntitiesOfType) &&
        !opts.onlyEntitiesOfType.includes(node.entity.type)) {
        return;
      }

      // If the node is filtered out of view, then it is not selectable.
      if (!opts.treeFilterExpressionFn(node)) {
        return;
      }

      // In the source detail view, if a physical agent is not upgradable or is
      // in the process of upgrading, then it's not selectable and it's safe to
      // exit early
      if (opts.detailedView &&
        node.entity.type === 6 &&
        (node._agent.upgradability !== 0 || node._isUpgrading)) {
        return;
      }

      // don't traverse through a VMware tag category branch, as they are hidden
      // from the user and only retained in the tree for informational purposes
      if (node.entity.type === 1 && node.entity.vmwareEntity.type === 14) {
        return;
      }

      // prevent recursing through an auto protected/excluded branch from a
      // standard checkbox click
      if (!opts.tagAutoProtect && !opts.autoProtect &&
        (node._isTagAutoProtected ||
        node._isTagExcluded ||
        node._isAutoProtected)) {
        return;
      }

      switch (true) {

        // node based auto protection
        case (opts.autoProtect):

          // if a node was previously excluded and this is recursion via action
          // on an ancestor, we want to maintain the exclusion
          if (!opts.unexclude && node._isExcluded) {
            opts.ancestorExcluded = true;
          } else {
            node._isExcluded = false;
            node._isAutoProtected =
              !node._isTagAutoProtected && !opts.ancestorAutoProtect;
            node._isAncestorAutoProtected = opts.ancestorAutoProtect;
            node._isAncestorExcluded = opts.ancestorExcluded;
            node._isSelected = !opts.ancestorExcluded && !node._isTagExcluded;
          }

          opts.ancestorAutoProtect = true;
          break;

        // tag auto protection
        case (opts.tagAutoProtect):
          node._isTagAutoProtected = true;

          if (opts.unexclude) {
            node._isSelected = true;
            node._isExcluded = false;
          } else {
            node._isSelected = !(node._isExcluded ||
              node._isAncestorExcluded ||
              node._isTagExcluded);
          }

          break;

        // standard selection
        default:
          node._isSelected = true;
          break;

      }

      // adjust object count map if node was previously unselected and is now
      // selected
      if (opts.selectedObjectsCounts && wasUnselected && node._isSelected) {
        opts.selectedObjectsCounts[node._normalizedEntity.type]++;
      }

      if (Array.isArray(node.children)) {

        // If node has children, mark them as selected too
        node.children.forEach(function loopChildren(child) {
          // only add the child if it is not filtered.
          // For autoprotect or exclusion, ignore filtering
          // TODO: should we do the same for unselecting/removing nodes?
          if (opts.ancestorAutoProtect ||
            opts.ancestorExcluded ||
            opts.treeFilterExpressionFn(child)) {
            selectNode(child, opts);
          }
        });

        // expand the node (if its not already expanded) so the user can clearly
        // see that child nodes were automatically selected
        if (opts.expandedNodes && !opts.expandedNodes.includes(node)) {
          opts.expandedNodes.push(node);
        }

      }

      // if we're dealing with a duplicate, any other
      // instances need to be found and updated to match
      if (node._isLeaf && node._isDuplicate) {
        opts.tree.forEach(function loopBranches(branch) {
          updateNodeIfDuplicate(branch, node);
        });
      }

    }

    /**
     * unselect a node
     *
     * @param      {object}  node    object
     * @param      {object}  opts    options
     */
    function unselectNode(node, opts) {

      var wasSelected = node._isSelected;

      opts = {
        autoProtect: opts.autoProtect === true,
        ancestorExcluded: opts.ancestorExcluded === true,
        excluding: opts.excluding === true,
        expandedNodes: opts.expandedNodes || [],
        selectedObjectsCounts: opts.selectedObjectsCounts,
        tagAutoProtect: opts.tagAutoProtect === true,
        tree: opts.tree || [],
      };

      // don't traverse through a VMware tag category branch, as they are hidden
      // from the user and only retained in the tree for informational purposes
      if (node.entity.type === 1 && node.entity.vmwareEntity.type === 14) {
        return;
      }

      // prevent recursing through an auto protected/excluded branch from a
      // standard checkbox click
      if (!opts.tagAutoProtect && !opts.autoProtect &&
        (node._isTagAutoProtected || node._isTagExcluded || node._isAutoProtected)) {
        return;
      }

      // in most of the below cases, the node will no longer be 'selected' for
      // protection. With the exception of cascading autoprotection off, in
      // which case the node may still be selected based on tag protection.
      node._isSelected = false;

      // handle special cases
      switch (true) {
        // excluding this node explicitly
        case (opts.autoProtect && opts.excluding && !opts.ancestorExcluded):
          node._isExcluded = true;
          node._isAncestorExcluded = false;
          opts.ancestorExcluded = true;
          break;

        // excluded by inheritance
        case (opts.autoProtect && opts.excluding && opts.ancestorExcluded):
          node._isAncestorExcluded = true;
          break;

        // cascading autoProtect off
        case (opts.autoProtect && !opts.excluding):
          node._isAutoProtected = false;
          node._isAncestorAutoProtected = false;
          node._isAncestorExcluded = false;
          node._isExcluded = false;
          node._isSelected = node._isTagAutoProtected;
          break;

        // excluding via tag exclusion
        case (opts.tagAutoProtect):
          node._isTagExcluded = true;
          break;
      }

      // adjust object count map if node was previously selected and is no
      // longer selected
      if (opts.selectedObjectsCounts && wasSelected && !node._isSelected) {
        opts.selectedObjectsCounts[node._normalizedEntity.type]--;
      }

      if (Array.isArray(node.children)) {
        node.children.forEach(function loopChildren(child) {
          // TODO: check filtering, etc here as we do in selectNode()?
          unselectNode(child, opts);
        });
      }

      // if we're dealing with a duplicate, any other
      // instances need to be found and updated to match
      if (node._isLeaf && node._isDuplicate) {
        opts.tree.forEach(function loopBranches(branch) {
          updateNodeIfDuplicate(branch, node);
        });
      }

    }

    /**
     * recursively checks nodes in provided node/branch,
     * finding any leaf level entities that match provided
     * duplicate, updating their selection values when found
     *
     * @param      {object}  node           the current search node
     * @param      {object}  duplicateNode  The duplicate node being searched for
     */
    function updateNodeIfDuplicate(node, duplicateNode) {

      if (isSameEntity(node.entity, duplicateNode.entity)) {
        syncDuplicateNodeProperties(duplicateNode, node, true);
      }

      if (Array.isArray(node.children)) {
        node.children.forEach(function checkChildren(child) {
          updateNodeIfDuplicate(child, duplicateNode);
        });
      }

    }

    /**
     * adds the provided source{} tagset to the provided jobTagSets[] array with
     * useful transformations, etc.
     *
     * @param      {Array}    jobTagSets   The job tag sets
     * @param      {object}   source       The source object (structured matches
     *                                     job.sources[])
     * @param      {boolean}  isSelected  Indicates if the tagSet is "selected."
     *                                    If true (defualt) the tagset is auto
     *                                    protected, if false the tagset is
     *                                    excluded
     * @return     {object}   The newly created/added tagSet object
     */
    function addToJobTagSets(jobTagSets, source, isSelected) {
      var entityKeyObj = _getTagMetaData(source.entities[0].type);
      var entity = {
         displayName: source.entities.map(function mapNames(tag) {
              return tag.displayName;
            }).join(', '),
      };
      var vmEntity = {
        name: source.entities.map(function mapNames(tag) {
          return tag[entityKeyObj.entityKey].name;
        }).join(', '),
      };

      isSelected = isSelected !== false;

      switch (source.entities[0].type) {
        case ENV_TYPE_CONVERSION.kVMware:
          entity.type = 1;
          vmEntity.type = 15;
          entity.vmwareEntity = vmEntity;
          break;
        case ENV_TYPE_CONVERSION.kHyperV:
          entity.type = 2;
          vmEntity.type = 9;
          entity.hypervEntity = vmEntity;
          break;
      }

      jobTagSets.push({

        _isSelected: isSelected,

        // to be populated while building standard hashedBranches, for nested
        // display via cEntityTree
        children: [],

        // easy lookup of child entity Ids to prevent dupes
        childrenIds: [],

        entities: source.entities,

        // these properties to be incremented for each included VM via
        // addNodeToTagSet()
        _numEntities: 0,
        _logicalSizeInBytes: 0,

        // list of tagIds so children can be more easily determined
        tagIds: source.entities.map(function mapIds(tag) {
          return tag.id;
        }),

        // fake VMware entity for display by cEntityTree
        entity: entity,
      });

      // return the newly added tagSet
      return jobTagSets[jobTagSets.length - 1];

    }

    /**
     * updates the tagSet to include the provided Node
     *
     * @param      {object}  node    The node to add to tagSet
     * @param      {object}  tagSet  The tag set to add the node to
     * @return     {object}  the updated tagSet
     */
    function addNodeToTagSet(node, tagSet) {
      tagSet.children.push(node);
      tagSet.childrenIds.push(node.entity.id);
      tagSet._numEntities++;
      tagSet._logicalSizeInBytes =
        tagSet._logicalSizeInBytes + (node.logicalSizeInBytes || 0);

      return tagSet;
    }

    /**
     * processes provided entityHierarchy and returns a tree structure
     *
     * @param      {array}   entityHierarchy  The entity hierarchy
     * @param      {object}  opts             The options
     * @return     {array}   the source tree
     */
    function getTree(entityHierarchy, opts) {
      var sourceTree;
      var tree = [];
      var numBranches = entityHierarchy.length || 0;
      var n = 0;
      var processHierarchyConfig;
      var entityKeyObj;

      var preSelectedNodes = createSelectedMap(opts.job || {});
      var preExcludedNodes = createExcludedMap(opts.job || {});
      var isTagProtectionSupported =
        ENV_GROUPS.taggable.includes(_.get(opts, 'job.type'));

      // Sanity check. This is currently necessary (3.9) for viewing "Physical
      // Servers" (the source) when no physical entities are registered with the
      // cluster. The "source" persists as empty.
      if (!Array.isArray(entityHierarchy) || !entityHierarchy.length) {
        return tree;
      }

      processHierarchyConfig = {
        backupSourceParams: opts.job && opts.job.backupSourceParams || [],
        envType: entityHierarchy[0].entity.type,
        excludedNodes: preExcludedNodes,
        excludeNodesOfType: [],
        excludeSources: opts.job && opts.job.excludeSources || [],
        expandedNodes: opts.expandedNodes || [],
        expandNodesOfType: opts.expandNodesOfType || [],
        jobTagSets: opts.jobTagSets || [],
        selectedCounts: opts.jobObjectsCountByType || [],
        selectedNodes: preSelectedNodes,
        sourceParamsMap: opts.sourceParamsMap || {},
        sources: opts.job && opts.job.sources || [],
        totalCounts: opts.sourceObjectsCountByType || {},
      };

      if (isTagProtectionSupported) {

        processHierarchyConfig.sources.forEach(
          function processSourceFn(source) {
            entityKeyObj = _getTagMetaData(source.entities[0].type);

            if (source.entities[0][entityKeyObj.entityKey].type ===
                entityKeyObj.tagEnum) {
              addToJobTagSets(processHierarchyConfig.jobTagSets, source);
            }
          }
        );

        processHierarchyConfig.excludeSources.forEach(
          function processExcludeSourceFn(source) {
            entityKeyObj = _getTagMetaData(source.entities[0].type);

            if (source.entities[0][entityKeyObj.entityKey].type ===
              entityKeyObj.tagEnum) {
              addToJobTagSets(processHierarchyConfig.jobTagSets, source, false);
            }
          }
        );

      }

      processHierarchyConfig.backupSourceParams.forEach(
        function mapParamsFn(sourceParams) {
          processHierarchyConfig.sourceParamsMap[sourceParams.sourceId] =
            sourceParams;
        }
      );

      /* NOTE: Previously, the code in this function removed the root node of
       * certain evironment types. These parent/root nodes weren't displayed in
       * the tree and weren't available for selection/protection, etc. With
       * public APIs and new job flow (introduced in 6.0) we began exposing root
       * nodes (think vcenter node) and allowing users to auto-protect such
       * nodes. This resulted in the areas of code still using this private API
       * logic to silently fail (reporting zero objects being protected) as the
       * root node couldn't be found and therefore child nodes couldn't be
       * counted. In a more recent change, the code to prune this root node was
       * removed from this function in order to make 'Job Detail > Settings tab'
       * report correctly and the Run Now modal usable, as its configured to
       * show a "zero objects protected" message if no child objects are found.
       */
      for (; n < numBranches; n++) {
        tree.push(processHierarchyBranch(
          entityHierarchy[n],
          processHierarchyConfig
        ));
      }

      sourceTree = entityHierarchy[0];

      // for Azure, AWS and KVM types, filter out the unwanted nodes

      if (ENV_GROUPS.cloudSources.includes(sourceTree.entity.type) ||
        sourceTree.entity.type === ENV_TYPE_CONVERSION.kKVM) {

        sourceTree.children =
          SourceServiceFormatter.pruneCloudTree(sourceTree.children);

        sourceTree._numEntities =
          SourceServiceFormatter.findNumEntities(sourceTree);
      }

      return tree;
    }

    /**
     * Get the entityKey (like vmwareEntity/awsEntity etc) and kTag Enum
     * for a given taggable entityType
     *
     * @method   _getTagMetaData
     * @param    {String}   entityType   The entityType (like 1 for VMware)
     * @return   {Object}   Object containing entityKey and tagEnum
     */
    function _getTagMetaData(entityType) {
      var entityKey;
      var tagEnum;

      switch (entityType) {
        case ENV_TYPE_CONVERSION.kVMware:
          entityKey = 'vmwareEntity';
          tagEnum = 15;
          break;

        case ENV_TYPE_CONVERSION.kAWS:
        case ENV_TYPE_CONVERSION.kAWSNative:
        case ENV_TYPE_CONVERSION.kAWSSnapshotManager:
          entityKey = 'awsEntity';
          tagEnum = 9;
          break;

        case ENV_TYPE_CONVERSION.kAzure:
        case ENV_TYPE_CONVERSION.kAzureNative:
        case ENV_TYPE_CONVERSION.kAzureSnapshotManager:
          entityKey = 'azureEntity';
          tagEnum = 12;
          break;

        case ENV_TYPE_CONVERSION.kGCP:
        case ENV_TYPE_CONVERSION.kGCPNative:
          entityKey = 'gcpEntity';
          tagEnum = 11;
          break;

        case ENV_TYPE_CONVERSION.kHyperV:
          entityKey = 'hypervEntity';
          tagEnum = 9;
          break;

        case ENV_TYPE_CONVERSION.kSQL:
          entityKey = 'sqlEntity';
          tagEnum = 3;
          break;
      }

      return {
        entityKey: entityKey,
        tagEnum: tagEnum,
      };
    }

    /**
     * Determines if node is a leaf.
     *
     * @method   isLeafNode
     * @param    {object}    node        The node
     * @param    {number}    [envType]   Optional envType to check in addition
     *                                   to the entity type.
     * @return   {boolean}   True if leaf node, False otherwise.
     */
    function isLeafNode(node, envType) {
      // WARNING: CLogic changes to this Fn need to also be made in
      // PubJobServiceFormatter.isLeafNode (public protos) until this one is no
      // longer in use.
      /*
       * In Database hierarchies, hosts are not leaves. So if the entity is not
       * a DB entity and the root environment is a database environment, then
       * this modifier will superceed the rest of the expressions below.
       */
      if (ENV_GROUPS.databaseSources.includes(node._rootEnvironment) &&
        ENV_GROUPS.databaseSources.includes(node.entity.type)) {
        return _.get(node.entity, 'sqlEntity.type') === 1 ||
          _.get(node.entity, 'oracleEntity.type') === 3;
      }

      // TODO(Tauseef): Convert entity numbers to constants env_group variables
      return (node.entity.type === ENV_TYPE_CONVERSION.kVMware &&
          node.entity.vmwareEntity.type === 8) ||
        (node.entity.type === ENV_TYPE_CONVERSION.kHyperV &&
          node.entity.hypervEntity.type === 6) ||

        // For AD env, only hosts are considered leaves
        (envType === ENV_TYPE_CONVERSION.kAD &&
          node.entity.type === ENV_TYPE_CONVERSION.kPhysical) ||

        // For Exchange onPrem, databases are leaves
        (envType === ENV_TYPE_CONVERSION.kExchange &&
          node.entity.type === ENV_TYPE_CONVERSION.kExchange &&
          EXCHANGE_GROUPS.exchangeLeafEntities
            .includes(node.entity.exchangeEntity.type)) ||
        (node.entity.type === ENV_TYPE_CONVERSION.kPhysical &&
          node.entity.physicalEntity.type !== 0) ||
        (node.entity.type === ENV_TYPE_CONVERSION.kPure &&
          node.entity.pureEntity.type === 1) ||
        (node.entity.type === ENV_TYPE_CONVERSION.kNetapp &&
          node.entity.netappEntity.type === 2) ||
        (node.entity.type === ENV_TYPE_CONVERSION.kFlashBlade &&
          node.entity.flashbladeEntity.type === 1) ||
        (node.entity.type === ENV_TYPE_CONVERSION.kGenericNas &&
          [1, 3].includes(node.entity.genericNasEntity.type)) ||
        (node.entity.type === ENV_TYPE_CONVERSION.kAcropolis &&
          node.entity.acropolisEntity.type === 4) ||
        (node.entity.type === ENV_TYPE_CONVERSION.kIsilon &&
          node.entity.isilonEntity.type === 2) ||

        // for kvm types, leaf is a Virtual Machine (type 5)
        (node.entity.type === ENV_TYPE_CONVERSION.kKVM &&
          node.entity.kvmEntity.type === 5) ||

        // for Azure types, leaf is a Virtual Machine (type 2)
        (node.entity.type === ENV_TYPE_CONVERSION.kAzure &&
          node.entity.azureEntity.type === 2) ||

        // for AWS types, leaf is a EC2 Instance (type 3)
        (node.entity.type === ENV_TYPE_CONVERSION.kAWS &&
          node.entity.awsEntity.type === 3) ||

        // for AWS types, leaf can also be a RDS Instance (type 12)
        (node.entity.type === ENV_TYPE_CONVERSION.kAWS &&
          node.entity.awsEntity.type === 12) ||

        // for AWS types, leaf can also be a Aurora Instance (type 16)
        (node.entity.type === ENV_TYPE_CONVERSION.kAWS &&
          node.entity.awsEntity.type === 16) ||

        // for GCP types, leaf is a VirtualMachine (type 4)
        (node.entity.type === ENV_TYPE_CONVERSION.kGCP &&
          node.entity.gcpEntity.type === 4) ||

        // For Office 365 types, leaf is one of office365LeafInts
        (node.entity.type === ENV_TYPE_CONVERSION.kO365 &&
          isOffice365LeafEntity(node)) ||

        // For GPFS types, leaf is a fileset (type 2)
        (node.entity.type === ENV_TYPE_CONVERSION.kGPFS &&
          node.entity.gpfsEntity.type === 2) ||

        // For Kubernetes types, leaf is a namespace (type 1)
        (node.entity.type === ENV_TYPE_CONVERSION.kKubernetes &&
          node.entity.kubernetesEntity.type === 1) ||

        (node.entity.type === ENV_TYPE_CONVERSION.kNimble &&
          node.entity.pureEntity.type === 1) ||

        // For Elastifile types, leaf is a namespace (type 1)
        (node.entity.type === ENV_TYPE_CONVERSION.kElastifile &&
          node.entity.elastifileEntity.type === 1) ||

        // For Cassandra types, leaf is a table (type 2)
        (node.entity.type === ENV_TYPE_CONVERSION.kCassandra &&
          node.entity.cassandraEntity.type === 2) ||

        // For MongoDB types, leaf is a collection (type 2)
        (node.entity.type === ENV_TYPE_CONVERSION.kMongoDB &&
          node.entity.mongodbEntity.type === 2) ||

        // For HBase types, leaf is a table (type 2)
        (node.entity.type === ENV_TYPE_CONVERSION.kHBase &&
          node.entity.hbaseEntity.type === 2) ||

        // For Hive types, leaf is a table (type 2)
        (node.entity.type === ENV_TYPE_CONVERSION.kHive &&
          node.entity.hiveEntity.type === 2) ||

        // For UDA types, check the isLeaf flag in objectInfo
        (node.entity.type === ENV_TYPE_CONVERSION.kUDA &&
          node.entity.udaEntity.objectInfo.isLeaf);
    }

    /**
     * Filter list of volumes for either LVM or non-LVM as specified.
     *
     * @method     filterVolusmes
     * @param      {array}   node    the node
     * @param      {string}  type    filter by 'lvm' or 'nonlvm' volume types
     * @return     {array}   array of filtered volumes
     */
    function filterVolumes(node, type) {
      var entityKey = getEntityKey(node.entity.type);
      var volumes = node.entity[entityKey].volumeInfoVec || [];

      return volumes.filter(function filterVolumesFn(volumeInfo) {
        return type === 'lvm' ?
          !!volumeInfo.volumeGuid : !volumeInfo.volumeGuid;
      });
    }

    /*
     * @ngdoc function
     * @name        processHierarchyBranch
     *
     * @description function used to scrub our tree model and provide
     *              additional, more easily readable details about a given node
     *
     * @param       {object}  node     The EntityHierarchy node to be scrubbed
     * @param       {object}  options  The options
     * @example
       {
          {Array}   expandNodesOfType     Integers representing the types for auto expansion
          {Array}   expandedNodes         Node objects to be expanded
          {Array}   excludeNodesOfType    Node types not to show (children will also be
                                          ommitted)
          {Object}  selectedNodes         Index map of entity id's to be $selected
          {Object}  excludedNodes         Index map of entity id's to be un$selected (used
                                          when autoIncludeChildren is true)
          {Array}   sources               Sources array from job object, used to
                                          to store backupSourceParams on the associated
                                          tree entity
          {Boolean} autoIncludeChildren
          {Array}   ancestors             Array of ancestors for this node
          {Object}  totalCounts           Map of entity type counts by entity type id
          {Object}  selectedCounts        Map of selected entity type counts by entity type id
       }
     * @param       {object}  objHash  Optional - object hash map so duplicated
     *                                 vms (from folders) can be marked as such,
     *                                 and any object isn't counted twice. Shouldn't
     *                                 be provided on initial call.
     * @return     {object}  a scrubbed version of the provided node
     */
    function processHierarchyBranch(node, options, objHash) {

      var protectedOrUnprotectedKey;
      var agentStatusVec;
      var entityType = getEntityKey(node.entity.type);

      /**
       * For DB nodes, this will be the host object.
       *
       * @type   {object}   The found host ancestor.
       */
      var thisNodesParentEntity;

      objHash = objHash || {};

      options = options ? options : {};

      // default options are empty,
      // overwrite them with any options passed in
      options = angular.extend({
        ancestors: [],
        autoIncludeChildren: false,
        jobTagSets: [],
        backupSourceParams: [],
        excludeChildren: false,
        excludedNodes: {},
        excludeNodesOfType: [],
        excludeSources: [],
        expandedNodes: [],
        expandNodesOfType: [],
        selectedCounts: {},
        selectedNodes: {},
        sourceParamsMap: {},
        sources: [],
        totalCounts: {},
      }, options);

      node._normalizedEntity = normalizeEntity(node.entity);
      node._iconClass = $filter('entityIconClass')(node.entity);
      node._entityTypeName = ENUM_ENTITY_TYPE[node._normalizedEntity.environmentType][node._normalizedEntity.type];
      node._hasConnectionStateProblem = entityHasConnectionStateProblem(node);
      node._connectionStateText = ENUM_CONNECTION_STATE[node._normalizedEntity.connectionState];
      node._hasError = node._hasConnectionStateProblem ||
        // Ref constant SQL_DATABASE_STATE_DISPLAY_KEY for details
        [5, 6, 8].includes(_.get(node.entity, 'sqlEntity.sqlServerDbState'));

      // If there's no connection state set on the entity, but there is a
      // connection error. set the connection text to 'invalid' so that the
      // tooltip in the UI displays something.
      if (!node._connectionStateText && node._hasConnectionStateProblem) {
        node._connectionStateText = ENUM_CONNECTION_STATE[2];
      }

      node._inJob = false;
      node._displayName = node.entity.displayName;

      node.entity[entityType].agentStatusVec =
        node.entity[entityType].agentStatusVec || [];
      node._agentStatusVec = node.entity[entityType].agentStatusVec;

      node._isAutoProtected = false;
      node._isAncestorAutoProtected = false;
      node._isTagAutoProtected = false;
      node._isExcluded = false;
      node._isAncestorExcluded = false;
      node._isTagExcluded = false;
      node._isSelected = false;
      node._isExpanded = false;
      node._isDuplicate = false;

      node._isQuiesceCompatible = false;
      node._isPhysicalHost =
        node.entity.physicalEntity && node.entity.physicalEntity.type === 1;
      node._isVirtualHost =
        node.entity.vmwareEntity && node.entity.vmwareEntity.type === 8;

      // a physical agent is installed on a vm if the node entity proto has
      // a physicalEntityId
      node._isPhysicalAgentInstalled =
        !!(node.entity[entityType].commonInfo &&
          node.entity[entityType].commonInfo.physicalEntityId);

      node._isDatabaseHost = false;
      node._isSqlHost = false;
      node._isExchangeHost = false;
      node._isApplicationHost = false;
      node._isSqlRegistering = false;
      node._isSqlRegError = false;
      node._isRegistering = false;
      node._isRegError = false;
      node._isConnectionError = false;
      node._isAnyError = false;

      /**
       * Oracle Server - TODO(Tauseef): Add Oracle errors here or merge MS SQL
       * and Oracle errors into single DBError.
       */
      node._isOracleHost = false;

      node._isLeaf = isLeafNode(node, options.envType);

      node._isHypervisor = ENV_GROUPS.hypervisor.includes(node.entity.type);

      node._isPhysicalEntity = ENV_GROUPS.physical.includes(node.entity.type);

      node._volumesLvm = filterVolumes(node, 'lvm');
      node._volumesNonLvm = filterVolumes(node, 'nonlvm');

       // Identify O365 Entity
       node._isO365Entity = node.entity.type === 24;

       // Identify O365 source node.
       if (node._isO365Entity) {
         // O365 SharePoint Site
         node._isSharePointSite = node._normalizedEntity.type === 9;
       }

      // For SQL Hosts tree, SQL hosts will not have a children sub-tree, only
      // the auxChildren sub-tree which contains the DBs.
      if (FEATURE_FLAGS.protectSqlFileBased &&
        !node.hasOwnProperty('children')) {
        node.children = node.auxChildren;
      }

      if (FEATURE_FLAGS.oracleSourcesEnabled &&
        !node.hasOwnProperty('children')) {
        node.children = node.auxChildren;
      }
      // NetApp & NAS only
      if (ENV_GROUPS.nas.includes(node.entity.type)) {
        node._dataProtocols = getNasDataProtocols(node);
      }

      // Oracle Servers
      if (FEATURE_FLAGS.oracleSourcesEnabled &&
        !node.hasOwnProperty('children')) {
        node.children = node.auxChildren;
      }

      // if there are Job backupSourceParams for this entity, add them to the
      // node for easier processing in the Job flow/tree.
      if (options.sourceParamsMap[node.entity.id]) {
        node._backupSourceParams = options.sourceParamsMap[node.entity.id];
      }

      // Determine if this node is a duplicate. Note: This approach to duplicate
      // tracking falls apart if node can be represented in the tree more than
      // twice. If such a case arises, objHash[id] should be made an array
      if (objHash[node.entity.id]) {
        objHash[node.entity.id]._isDuplicate = node._isDuplicate = true;
      } else {
        objHash[node.entity.id] = node;
      }

      switch (true) {

        // node is a duplicate and its dupe has some type of exclusion. Exit
        // the switch early, as an exclusion always takes precedent.
        case node._isDuplicate &&
          !objHash[node.entity.id]._isSelected &&
          (objHash[node.entity.id]._isTagExcluded ||
          objHash[node.entity.id]._isAncestorExcluded):
          break;

        // node is explicitly selected (included in sources)
        case options.selectedNodes[node.entity.id]:
          node._isSelected = true;
          node._inJob = true;

          if (!node._isLeaf) {
            node._isAutoProtected = true;
            options.autoIncludeChildren = true;
            options.excludeChildren = false;
          }

          // find this entity in provided sources (if provided)
          if (options.sources && options.sources.length) {
            options.sources.some(
              function findSource(source) {
                if (isSameEntity(node.entity, source.entities[0])) {
                  // Mark source instance as found in tree so we can determine
                  // those that are missing in the job flow. Exit this loop.
                  return source._foundInTree = true;
                }
              }
            );
          }

          break;

        // node is expicitly excluded
        case options.excludedNodes[node.entity.id]:

          // Either a tag or ancestor was autoprotected, check for ancestor
          node._isAncestorAutoProtected = options.ancestors.some(
            function checkAncestorSelection(ancestor) {
              return ancestor._isSelected;
            }
          );
          node._isExcluded = true;
          node._isSelected = false;
          options.autoIncludeChildren = false;
          options.excludeChildren = true;
          break;

        // node has a parent that is excluded
        case options.excludeChildren:
          node._isAncestorAutoProtected = true;
          node._isAncestorExcluded = true;
          break;

        // node has a parent that is auto protected
        case options.autoIncludeChildren:
          node._isSelected = true;
          node._inJob = true;
          node._isAncestorAutoProtected = true;
          break;
      }

      if (options.jobTagSets.length &&
        node._isLeaf &&
        node.entity[node._normalizedEntity.entityKey].tagAttributesVec) {

        // tags are present on this leaf, and tags are protected or excluded for
        // the Job. check for a match.
        options.jobTagSets.forEach(
          function loopTagSetBranches(tagSet) {

            // if this node is already represented in the tagSet's children,
            // skip it
            if (tagSet.childrenIds.includes(node.entity.id)) {
              return;
            }

            if (nodeMatchesTagSet(node, tagSet)) {

              addNodeToTagSet(node, tagSet);

              switch (true) {
                case tagSet._isSelected:
                  node._isTagAutoProtected = true;
                  node._isSelected =
                    !node._isExcluded &&
                    !node._isAncestorExcluded &&
                    !node._isTagExcluded;
                  node._inJob = node._isSelected;
                  break;

                case !tagSet._isSelected:
                  node._isSelected = false;
                  node._isTagExcluded = true;
                  node._inJob = false;
                  break;
              }
            }

          }
        );
      }

      if (node._isSelected &&

        // node is not a duplicate, or its duplicate wasn't selected.
        (!node._isDuplicate || !objHash[node.entity.id]._isSelected) &&
        options.selectedCounts[node._normalizedEntity.type] !== undefined) {
        options.selectedCounts[node._normalizedEntity.type]++;
      }

      if (!node._isDuplicate &&
        options.totalCounts[node._normalizedEntity.type] !== undefined) {
        options.totalCounts[node._normalizedEntity.type]++;
      }

      // now that node selection and counting is complete, if the node is a
      // duplicate it's necessary to ensure that the selection/auto protection
      // between duplicate nodes matches
      if (node._isDuplicate) {
        syncDuplicateNodeProperties(node, objHash[node.entity.id]);
      }

      if (node.aggregatedProtectedInfoVec && node.aggregatedUnprotectedInfoVec) {
        // if an entity has aggregate information, save some aggregate details.
        node._numEntities =
          (node.aggregatedProtectedInfoVec[0].numEntities || 0) +
          (node.aggregatedUnprotectedInfoVec[0].numEntities || 0);
        node._logicalSizeInBytes = node.logicalSizeInBytes =
          (node.aggregatedProtectedInfoVec[0].totalLogicalSizeInBytes || 0) +
          (node.aggregatedUnprotectedInfoVec[0].totalLogicalSizeInBytes || 0);
      } else if (node.logicalSizeInBytes >= 0) {
        // likely for VM/leaf level, we still want to know the objects size
        node._logicalSizeInBytes = node.logicalSizeInBytes;
      }

      if (FEATURE_FLAGS.oracleSourcesEnabled &&
        node.entity.type === 19) {
        // TODO: This is temp fix until we get size for oracle Entity similar to
        // other entities
        node._logicalSizeInBytes = node.logicalSizeInBytes ||
          _.get(node.entity.oracleEntity, 'dbEntityInfo.sizeBytes', 0);
      }

      // Decorate the node with protection helper properties.
      SourceServiceFormatter.nodeProtectionStatusDecorator(node);

      // do leaf specific things
      if (node._isLeaf) {

        // Stub the agent object
        node._agent = {};

        node._isExchangeRegistered = isExchangeRegistered(node);

        if (node.registeredEntityInfo) {
          node._isConnectionError = !!node.registeredEntityInfo.refreshError;

          // Backend defaults are not being propagated through Iris-Backend.
          // Usually doesn't matter, but in this case it does. The proper fix is
          // in Iris-backend but that is a heavy lift, so a front-end fix is the
          // immediate solution.
          node.registeredEntityInfo.verificationStatus =
            node.registeredEntityInfo.verificationStatus || 2;
        }

        // Identify and tag MS SQL Cluster.
        node._isSqlCluster =
          node.entity.physicalEntity && node.entity.physicalEntity.type === 2;

        // Identify as a SQL entity. Not all SQL entities are hosts, so we're
        // doing this check here instead with other SQL checks lower.
        node._isSqlEntity = node.entity.type === 3;

        // Identify Oracle Entity
        node._isOracleSource = node.entity.type === 19;

        // Identify Exchange Entity
        node._isExchangeHost = node.entity.type === 17;

        // Track the parentHost if this is a database type
        if (ENV_GROUPS.databaseSources.includes(node.entity.type)) {
          thisNodesParentEntity = _.find(
            _.get(options, 'ancestors', []),
            ['entity.id', node.entity.parentId]
          ) || {};

          if (!_.isEmpty(thisNodesParentEntity)) {
            // If available, fill parent host data.
            // This will be useful in case of oracle where we only get
            // the  nodes data at the parent host level.
            node._parentHostEntity =
              thisNodesParentEntity._typeEntity || {};
          }
        }

        // Identify as an Application Host of any kind.
        node._isApplicationHost =
          !!(node.registeredEntityInfo && node.registeredEntityInfo.appEnvVec);

        if (node._isSqlEntity && node.entity.sqlEntity.type === 1) {
          /**
           * For SQL DB entities, `entity.displayName` shows the
           * "instance/dbname" and in the context of the Source Tree, UX
           * determined to show only the "dbname". Though it still has value in
           * other contexts, like restore.
           */
          node._displayName = node.entity.sqlEntity.databaseName;
        }

        // This node is registered as an App Host, set some status flags.
        if (node._isApplicationHost) {
          // SQL
          node._isSqlHost = node.registeredEntityInfo.appEnvVec.includes(3);
          node._isOracleHost = node.registeredEntityInfo.appEnvVec.includes(19);
          node._isExchangeHost =
            node.registeredEntityInfo.appEnvVec.includes(17);
          node._isActiveDirectoryHost =
            node.registeredEntityInfo.appEnvVec.includes(29);

          /*
           * True if the intersection of these two lists has length. False
           * otherwise. This allows easy updating via the
           * ENV_GROUPS.databaseSources constant as new DB adapters are added.
           */
          node._isDatabaseHost =
            !!_.intersection(
              ENV_GROUPS.databaseSources,
              node.registeredEntityInfo.appEnvVec
            ).length;

          // Oracle
          node._isOracleHost = node.registeredEntityInfo.appEnvVec.includes(19);

          // Determine if there was an error in SQL registration verification.
          node._isApplicationRegError =
            !!node.registeredEntityInfo.verificationError;

          // Determine if a SQL/App registration has been initialized.
          node._isApplicationRegistering =
            angular.isDefined(node.registeredEntityInfo.verificationStatus) &&
            node.registeredEntityInfo.verificationStatus !== 2;

          // Add SQL Host credentials if available and not configured with a
          // persistentAgent.
          node._credentials = !node.registeredEntityInfo.usesPersistentAgent ?
            node.registeredEntityInfo.connectorParams &&
            node.registeredEntityInfo.connectorParams.credentials :
            undefined;

          if (FEATURE_FLAGS.protectSqlAag) {
            node._aags = Object.values(
              SourceServiceFormatter.extractAagInfo(node)
            );
          }
        }

        // Set the _isQuiesceCompatible property.
        node._isQuiesceCompatible = isQuiesceCompatible(node);

        // If Physical, decorate with _agent and additional properties.
        if (node.entity.physicalEntity) {

          // Determine if registration has been initialized.
          node._isRegistering =
            node.registeredEntityInfo.verificationStatus === 0;

          // Determine if there was an error in registration verification.
          node._isRegError = !!node.registeredEntityInfo.verificationError;
        }

      }

      if (node._agentStatusVec[0]) {

        agentStatusVec = node._agentStatusVec;

        // Stub `properties` object if missing.
        agentStatusVec.forEach(function forEachAgentStatusVec(agent) {
          agent.properties = agent.properties || {};
          agent.properties.upgradability =
            $stateParams.forceUpgrade === 'true' ? 0 :
            agent.properties.upgradability;
          agent._isAnyError =
            !!agent.refreshError || !!agent.verificationError;
        });

        // Assign first child agent to _agent property. This is primarily for
        // Physical Servers, which have only a single agent.
        node._agent = agentStatusVec[0] && agentStatusVec[0].properties || {};

        // Iterate through the agents and look for an agent in special
        // condition. If found, assign this agent as the _agent in order to
        // surface relevant messaging. This is primarily for clusters.
        agentStatusVec.some(
          function checkAgentsForSpecialConditions(agent) {
            if ([1, 2].includes(node._agent.upgradeStatus)) {
              // Agent is upgrading
              // Overwrite _agent with the cluster node that is upgrading
              node._agent = agent.properties;
              node._isUpgrading = true;
              return true;
            } else if (agent.properties && agent.properties.upgradeError) {
              // Agent is in error
              // Overwrite _agent with the cluster node that is in error
              node._agent = agent.properties;
              return true;
            }
          }
        );
      }

      // ancestors will be recursively passed to this very function as an array
      // of ancestors, and ids/strings will be pushed to these arrays.
      node._protectedDescendants = [];
      node._unprotectedDescendants = [];
      node._sqlHostDescendants = [];
      node._exchangeHostDescendants = [];
      node._inJobDescendants = [];
      node._quiesceDescendants = [];
      node._descendantNames = [];
      node._descendantTypes = [];

      // an array of arrays.
      // this can be walked to determine descendant protection/exclusion status
      node._descendantTagSets = [];

      // update ancestors metadata if there are any
      if (options.ancestors.length) {
        protectedOrUnprotectedKey =
          (node._isProtected || node._isSqlProtected) ?
            '_protectedDescendants' : '_unprotectedDescendants';

        options.ancestors.forEach(function updateAncestorsFn(ancestor) {

          ancestor[protectedOrUnprotectedKey].push(node._normalizedEntity.id);
          ancestor._descendantNames.push(node._normalizedEntity.nameLowerCase);
          ancestor._descendantTypes.push(node._normalizedEntity.type);

          if (node.entity.vmwareEntity &&
            Array.isArray(node.entity.vmwareEntity.tagAttributesVec)) {
            ancestor._descendantTagSets.push(
              node.entity.vmwareEntity.tagAttributesVec.map(
                function loopTags(tag) {
                  return tag.entityId;
                }
              )
            );
          }

          if (node.entity.hypervEntity &&
            Array.isArray(node.entity.hypervEntity.tagAttributesVec)) {
            ancestor._descendantTagSets.push(
              node.entity.hypervEntity.tagAttributesVec.map(
                function loopTags(tag) {
                  return tag.entityId;
                }
              )
            );
          }

          if (node._inJob) {
            ancestor._inJobDescendants.push(node._normalizedEntity.id);
          }

          if (node._isSqlHost || node._isSqlEntity) {
            // update the ancestors to reflect the presence of a child SQL Server
            ancestor._sqlHostDescendants.push(node._normalizedEntity.id);
          }

          if (node._isExchangeHost) {
            // update the ancestors to reflect the presence of a child Exchange
            // Host
            ancestor._exchangeHostDescendants.push(node._normalizedEntity.id);
          }

          if (node._isQuiesceCompatible) {
            ancestor._quiesceDescendants.push(node._normalizedEntity.id);
          }

        });
      }

      node._isAnyError =
        node._isRegError || node._isApplicationRegError || node._isConnectionError;

      if (Array.isArray(node.children)) {
        // Alpha sort the children by displayName
        node.children =
          $filter('orderBy')(node.children, function childrenSort(child) {
            return child.entity.displayName;
          }
        );

        // Make our ancestors object a non-reference copy of itself: we don't
        // want to continue pushing ancestors to the same array by reference, as
        // we would then be pushing all nodes into the same array, and then we
        // would have an inaccurate list of ancestors as the tree gets
        // traversed.
        options.ancestors = options.ancestors.slice(0);

        // add the current node to our unreferenced ancestors list
        options.ancestors.push(node);

        node.children.forEach(function loopChildrenFn(child) {
          child._rootEnvironment = node._rootEnvironment;
          if (child.entity &&
            !options.excludeNodesOfType.includes(getEntityType(child.entity))) {
            child = processHierarchyBranch(child, options, objHash);
          }
        });

        // add all nodes with children to our expandedNodes array
        if (options.expandNodesOfType.includes(node._normalizedEntity.type)) {
          options.expandedNodes.push(node);
        }
      }

      // return our object
      return node;
    }

    /**
     * Return the array of data protocols supported by a NAS node.
     *
     * @method     getNasDataProtocols
     * @param      {object}  node    The node to parse
     * @return     {array}   Array of supported file protocols
     */
    function getNasDataProtocols(node) {
      var netappEntity = node.entity.netappEntity;
      var isilonEntity = node.entity.isilonEntity;
      var flashbladeEntity = node.entity.flashbladeEntity;
      var dataProtocolVec;

      switch (node.entity.type) {
        // NetApp
        case 9:
          // We need to test all three levels of the hierarchy because the
          // filesystem protocol is defined at all levels. Since most nodes are
          // leaves, we start there are work upwards
          switch (true) {
            case !!netappEntity.volumeInfo:
              dataProtocolVec = netappEntity.volumeInfo.dataProtocolVec;
              break;

            case !!netappEntity.vserverInfo:
              dataProtocolVec = netappEntity.vserverInfo.dataProtocolVec;
              break;

            case !!netappEntity.clusterInfo:
              dataProtocolVec = netappEntity.clusterInfo.dataProtocolVec;
              break;
          }
          break;

        // Generic NAS
        case 11:
          dataProtocolVec = [].concat(node.entity.genericNasEntity.protocol);
          break;

        // Isilon
        case 14:
          // Protocol info only at mount point/share level
          if (isilonEntity.mountPointInfo) {
            dataProtocolVec = isilonEntity.mountPointInfo.supportedProtocolVec;
          }
          break;

        // Pure FlashBlade
        case 21:
          // Protocol info only at mount point/share level
          if (flashbladeEntity.fileSystemInfo) {
            dataProtocolVec =
              flashbladeEntity.fileSystemInfo.supportedProtocolVec;
          }
          break;

      }
      return dataProtocolVec || [];
    }

    /**
     * syncs protection related properties for a pair of duplicate nodes.
     *
     * @param      {object}   node1            The node
     * @param      {object}   node2            The duplicate of the node
     * @param      {boolean}  [isNode1Master]  if true, values should be copied
     *                                         from node1 to node2. if false,
     *                                         values will be true if either
     *                                         node has true
     */
    function syncDuplicateNodeProperties(node1, node2, isNode1Master) {
      var propsToSync = [
        '_isSelected',
        '_isAutoProtected',
        '_isAncestorAutoProtected',
        '_isAncestorExcluded',
        '_isTagAutoProtected',
        '_isTagExcluded',
      ];

      propsToSync.forEach(function syncProp(prop) {
        node1[prop] = node2[prop] = !!isNode1Master ?
          node1[prop] : node1[prop] || node2[prop];
      });

    }

    /**
     * checks a node against a provided tag set and indicates if the node has
     * all of tags in the set
     *
     * @param      {object}   node    The node
     * @param      {object}   tagSet  The tag set, as created in
     *                                addToJobTagSets()
     * @return     {boolean}  true if the node contain all tags from the tag
     *                        set, false otherwise
     */
    function nodeMatchesTagSet(node, tagSet) {
      return tagSet.tagIds.every(function tagMatches(tagId) {
        var tagAttributesVec =
          node.entity[node._normalizedEntity.entityKey].tagAttributesVec;

        return Array.isArray(tagAttributesVec) && tagAttributesVec.some(
            function checkTagAttrib(tagAttrib) {
              return tagAttrib.entityId === tagId;
            }
          );
      });
    }

    /**
     * Detects if an entity has connection state problems.
     *
     * @method    entityHasConnectionStateProblem
     * @param     {object}    node   The node to detect connection problems on.
     * @returns   {boolean}   True if entity has connection state problem.
     */
    function entityHasConnectionStateProblem(node) {
      switch (node.entity.type) {
        // VM Server
        case 1:

          // For VM's an explicit 'connectionState' value is provided. If
          // connectionState === 0 then all is good. Any other value indicates
          // some type of connectionState problem.
          return [7, 8].includes(node.entity.vmwareEntity.type) &&
            node.entity.vmwareEntity.connectionState !== 0;

        // Physical Server
        case 6:

          // For physical servers there is not an explicit 'connectionState'
          // value provided. connectionState should be inferred from the
          // presence of the refreshError object.
          return node.entity.physicalEntity.type !== 0 &&
            !!node.registeredEntityInfo.refreshError;
      }

      return false;
    }

    /**
     * Determines if a given entity supports app aware backups.
     *
     * @method   isQuiesceCompatible
     * @param    {Object}    node   Node object from server
     * @return   {Boolean}   If this node is Quiesce compatible
     */
    function isQuiesceCompatible(node) {
      var typedEntity = getTypedEntity(node.entity);

      // if we have a VMware tools property
      return !!typedEntity.vmwareToolsStatus &&

        // Check if VMware tools is running
        typedEntity.vmwareToolsStatus === ENUM_GUEST_TOOLS_STATUS.RUNNING;
    }

    /**
     * Fetches the resourcePool for a given vCenterEntity
     *
     * @method     getResourcePool
     * @param      {Object}  params  EntityId of a given vCenter
     * @param      {Object}  headers accessClusterId for MCM mode
     * @return     {Object}  Promise with response or failure
     */
    function getResourcePool(params, headers) {
      var opts = {
        method: 'get',
        params: params || {},
        headers: headers,
        url: API.private('resourcePools'),
      };

      if (!params.vCenterId) {
        $q.reject({});
      }

      return $http(opts)
        .then(function success(resp) {
          return resp.data || [];
        });
    }

    /**
     * Fetches the list of datastores for a given resourcePoolEntity
     *
     * @method     getDatastores
     * @param      {Object}  params  EntityId of a given resource pool
     * @param      {Object}  headers accessClusterId for MCM mode
     * @return     {Object}  Promise with response or failure
     */
    function getDatastores(params, headers) {
      var opts = {
        method: 'get',
        params: params,
        headers: headers,
        url: API.private('datastores'),
      };

      return $http(opts)
        .then(function success(resp) {
          return resp.data || [];
        });
    }

    /**
     * Fetches the list of VM folders for a given resourcePoolEntity
     *
     * @method     getVmFolders
     * @param      {Object}  params  EntityId of a given resource pool
     * @return     {Object}  Promise with response or failure
     */
    function getVmFolders(params) {
      var opts = {
        method: 'get',
        params: params || {},
        url: API.private('vmwareFolders'),
      };

      return $http(opts)
        .then(function success(resp) {
          return resp.data || {};
        });
    }

    /**
     * Fetches the list of storage profile for a given virtual data center id.
     *
     * @method     getVmFolders
     * @param      {Object}  vdcId  Id of a given virtual data center
     * @return     {Object}   Array of response
     */
    function getStorageProfile(vdcId) {
      var opts = {
        method: 'get',
        url: API.public('virtualDatacenters/' + vdcId + '/storageProfiles')
      };

      return $http(opts)
        .then(function success(resp) {
          return resp.data || {};
        });
    }

    /**
     * Fetches the list of NetworkEntities for a given ResourcePoolEntity
     *
     * @method     getNetworkEntities
     * @param      {Object}  params  EntityId of a given resource pool
     * @param      {Object}  headers accessClusterId for MCM mode
     * @return     {Object}  Promise with response or failure
     */
    function getNetworkEntities(params, headers) {
      var opts = {
        method: 'get',
        params: params || {},
        headers: headers,
        url: API.private('networkEntities'),
      };

      return $http(opts)
        .then(function success(resp) {
          return resp.data || [];
        });
    }

    /**
     * Extract all the discrete VMs in the provided list of fileEntities
     *
     * @method     getVmsFromSearchResults
     * @param      {Array}  list    Search results, or other list of
     *                              FileEntities
     * @return     {Array}          The extracted list of VMs
     */
    function getVmsFromSearchResults(list) {
      if (Array.isArray(list)) {
        knownFileSearchVMs = cUtils.dedupe(

          // The list
          knownFileSearchVMs.concat(
            list.reduce(reducerFn, [])
          ),
          containsVmFn
        );
      }

      /**
       * Quick reduction to a list of VMs
       *
       * @method     reducerFn
       * @param      {Array}   VMs     Cumulative list of found VMs (may contain dups)
       * @param      {Object}  item    The File Entity we're extracting from
       * @return     {Array}   The transformed list of just VMs
       */
      function reducerFn(VMs, item) {
        if (item.fileDocument &&
          item.fileDocument.objectId.entity.type === 1) {
          item.fileDocument.objectId.entity._entityKey = getEntityKey(item.fileDocument.objectId.entity.type);
          VMs.push(item.fileDocument.objectId.entity);
        }

        return VMs;
      }

      return knownFileSearchVMs;
    }

    /**
     * Quick checks if a collection of VMs already contains the VM
     *
     * @method     containsVmFn
     * @param      {Array}   list    Collection of VMs
     * @param      {Object}  item    Entity Proto we're looking for
     * @return     {Bool}    If the VM was found in the list
     */
    function containsVmFn(list, item) {
      return list.some(function finderFn(vm, ii) {
        return isSameEntity(item, vm);
      });
    }

    /**
     * launches the register source type selection screen in a cSlideModal
     *
     * @return     {object}  promise to resolve the cSlideModal
     */
    function selectSourceTypeSlider() {
      return SlideModalService.newModal({
        controller: 'selectSourceTypeController',
        templateUrl: 'app/protection/sources/modify/select-source-type.html',
        size: 'xl',
      });
    }

    /**
     * Launches the register source form for the provided type in a cSlideModal.
     *
     * @param    {integer}   envType        The source envType to register.
     * @param    {object}    envTypes       Array of all the allowed environment
     *                                      types.
     * @param    {integer}   [entityType]   The specific entityType to register.
     * @param    {string}    [customClass]  The custom class name.
     * @param    {integer}   connectionId   bifrost connection id to associate new source with
     * @return   {object}    Promise to resolve the cSlideModal with the newly
     *                       registered SOurce, or undefined if canceled.
     */
    function registerSourceSlider(envType, envTypes, entityType, customClass, connectionId) {
      var sourceTypeOpts;
      var modalConfig;

      // For active directory, use the Angular dialog service.
      if (envType === 29) {
        // Angular dialogs return with no value when they are canceled rather
        // than throwing an exception.
        return ngDialogService.showRegisterActiveDirectoryDialog().toPromise()
          .then(function afterClosed(source) {
            if (!source) {
              return Promise.reject();
            }
            return source;
          })
          .catch(Promise.reject);
      }

      switch (true) {
        case ENV_GROUPS.physical.includes(envType):
          if (FEATURE_FLAGS.ngRegisterPhysicalDialogEnabled) {
            return ngDialogService.showRegisterPhysicalDialog({ connectionId }).toPromise()
              .then(function afterClosed(source) {
                return source || Promise.reject();
              })
              .catch(Promise.reject);
          } else {
            sourceTypeOpts = {
              controller: 'physicalModifyController',
              templateUrl: 'app/protection/sources/modify/physical/physical.html',
            };
          }
          break;

        case envType === 3:
          return ngDialogService.showRegisterSqlDialog({skipAppDiscovery: true})
          .toPromise().then(function afterClosed(source) {
            if (!source) {
              return Promise.reject();
            }
            return source;
          })
          .catch(Promise.reject);

        case envType === 7:
        case envType === 35:
        case envType === 52:
          return ngDialogService.showRegisterSanDialog().toPromise()
          .then(function afterClosed(source) {
            if (!source) {
              return Promise.reject();
            }
            return source;
          })
          .catch(Promise.reject);

        case envType == 17:
          return ngDialogService.showRegisterExchangeDialog().toPromise()
          .then(function afterClosed(source) {
            if (!source) {
              return Promise.reject();
            }
            return source;
          })
          .catch(Promise.reject);

        case ENV_GROUPS.nas.includes(envType):
          if (FEATURE_FLAGS.nasNgRegistration) {
            return ngDialogService.showRegisterNasDialog({
              envTypes,
              connectionId
            }).toPromise()
            .then(function afterClosed(source) {
              if (!source) {
                return Promise.reject();
              }
              return source;
            })
            .catch(Promise.reject);
          } else {
            sourceTypeOpts = {
              controller: 'nasModifyController',
              templateUrl: 'app/protection/sources/modify/nas/nas.html',
            };
          }
          break;

        case envType === 19:
          sourceTypeOpts = {
            controller: 'oracleModificationController as $ctrl',
            templateUrl: 'app/protection/sources/db-manager/oracle-modify.html',
            resolve: {
              // Page config is required as a dependency.
              pageConfig: {},
            },
          };
          break;

        case ENV_GROUPS.office365.includes(envType):
          if (FEATURE_FLAGS.office365NgRegistration) {
            return ngDialogService.showRegisterOffice365Dialog().toPromise()
              .then(function afterClosed(source) {
                return source || Promise.reject();
              })
              .catch(Promise.reject);
          } else {
            sourceTypeOpts = {
              component: 'cOffice365Manager',
            };
          }
          break;

        case envType === 38:
          return ngDialogService.showRegisterCassandraDialog().toPromise()
          .then(function afterClosed(source) {
            if (!source) {
              return Promise.reject();
            }
            return source;
          })
          .catch(Promise.reject);

        case envType === 39:
          return ngDialogService.showRegisterMongoDBDialog().toPromise()
          .then(function afterClosed(source) {
            if (!source) {
              return Promise.reject();
            }
            return source;
          })
          .catch(Promise.reject);

        case envType === 34:
          return ngDialogService.showRegisterKubernetesDialog().toPromise()
          .then(function afterClosed(source) {
            if (!source) {
              return Promise.reject();
            }
            return source;
          })
          .catch(Promise.reject);

        case envType === 46:
          return ngDialogService.showRegisterUdaDialog().toPromise()
          .then(function afterClosed(source) {
            if (!source) {
              return Promise.reject();
            }
            return source;
          })
          .catch(Promise.reject);

        // default for ENV_GROUPS.hypervisor envType or for other case
        default:
          // Default to vCenter for hypervisor source registration
          envType = envType || 0;
          sourceTypeOpts = {
            controller: 'hypervisorModifyController',
            templateUrl: 'app/protection/sources/modify/hypervisor/hypervisor.html',
          };
      }

      // Opened class name
      let openedClassName = 'c-slide-modal-open';
      if (customClass) {
        openedClassName += ' ' + customClass;
      }

      modalConfig = angular.merge({
        size: 'xl',
        openedClass: openedClassName,
        resolve: {
          envType: function() { return envType; },

          envTypes: function() { return envTypes; },

          entityType: function() { return entityType; },

          connectionId: function() { return connectionId; }
        },
      }, sourceTypeOpts);

      return SlideModalService.newModal(modalConfig);
    }

    /**
     * pop a modal to confirm deletion of a source. If confirmed call the
     * API to delete the source, closing the modal on success
     *
     * @param      {Object}    source           The source to be deleted
     * @return     {object}    promise to resolve deletion request
     */
    function deleteSourceModal(source) {
      var entityKey;
      var id;

      entityKey = getEntityKey(source.entity.type);
      id = source.entity.id;

      var modalConfig = {
        modalFade: true,
        backdrop: 'static',
        templateUrl: 'app/protection/sources/modals/delete-source.html',
        controller: deleteSourceModalControllerFn,
      };

      /* @ngInject */
      function deleteSourceModalControllerFn(
        $rootScope, $scope, $uibModalInstance, cMessage) {

        var text = $rootScope.text.servicesSourceService;

        $scope.text = {
          deleteSource: text.unregisterHeader[entityKey],
          areYouSure: text.areYouSure,
          closeButtonText: text.cancel,
          actionButtonText: text.delete,
        };

        $scope.source = source;

        $scope.ok = function ok() {

          $scope.submitting = true;

          deleteSource(id).then(
            function unregisterSourceSuccess(response) {
              cMessage.success({
                textKey: 'unregisterSourceSuccessful',
              });
              $uibModalInstance.close(response);
            },

            function deleteSourceFailure(response) {
              evalAJAX.errorMessage(response);
              $scope.submitting = false;
            }
          );

        };

        $scope.cancel = function cancel() {
          $uibModalInstance.dismiss('cancel');
        };
      }

      return $uibModal.open(modalConfig).result;
    }

    /**
     * Opens a slideModal to browse for and select an Entity based on a type.
     *
     * @method   browseForLeafEntities
     * @param    {integer|integer[]}   envTypes     One or more integers
     *                                              representing envTypes to
     *                                              browse.
     * @param    {integer}             [hostType]   Optional host type: [0, 1].
     *                                              Default = undefined.
     * @param    {object}              [filters]    Hash of filters to apply or
     *                                              not apply. See
     *                                              c-browse-for-target.js for
     *                                              complete list of available
     *                                              optional filters.
     * @return   {object}              Promise to resolve with the selected
     *                                 target Entity or nothing.
     */
    function browseForLeafEntities(envTypes, hostType, filters, setting) {
      /**
       * Unfortunately, these values will be "integers".
       *
       * @type   {array}   List of hostType (OS) enums
       */
      var permittedHostTypes = Object.keys(ENUM_HOST_TYPE);

      var modalOptions;

      // Ensure `filters` is an object.
      filters = angular.isObject(filters) ? filters : {};

      // coerce envTypes to an array if it isn't already and then filter out any
      // values that aren't supported
      envTypes = [].concat(envTypes).filter(
        function onlySupportedEnvTypesFn(type) {
          return ENV_GROUPS.entityBrowsable.includes(type);
        }
      );

      setting = setting || {};

      // browsing for Views must be done independently of other envTypes
      if (envTypes.includes(4)) {
        envTypes = [4];
      }

      if (!envTypes.length) {
        return $q.reject();
      }

      // Modal config options
      modalOptions = {
        templateUrl: 'app/global/c-browse-for-leaf-entities/c-browse-for-leaf-entities.html',
        controller: 'BrowseForLeafEntitesController',
        resolve: {
          opts: {
            setting: setting,
            envTypes: envTypes,
            filters: filters,
            singleSelect: !!filters.singleSelect,

            // since source tree is being used for recovery and not protection
            // physical agent check for cloudVMs is unnecessary
            ignorePhysicalAgentCheck: true,
          },
        },
        size: 'xl',
      };

      // Ensure a provided hostType is permitted. Also, you can't pass undefined
      // as a resolved value. hostType coerced to {string} for checking only.
      if (permittedHostTypes.includes('' + hostType)) {
        modalOptions.resolve.opts.hostType = hostType;
      }

      return SlideModalService.newModal(modalOptions);
    }

    /**
     * Displays a modal to download Agent binaries.
     *
     * @param     {string}   [hostEnv]   Used to restrict display of download
     *                                   links to specific OSs. Default: ''
     */
    function downloadAgentsModal(hostEnv, disableSapOptionSql = false) {

      var modalConfig = {
        modalFade: true,
        backdrop: 'static',
        templateUrl: 'app/protection/sources/modals/download-agents.html',
        controller: DownloadAgentsModalController,
      };

      // Ensure `hostEnv` is a string value. Default is '' which will allow all files
      // ot display (unfiltered). It is the developer's responsibility to ensure
      // they are using a valid Hostenv. These are currently "Windows",
      // "Linux", "AIX" and "Solaris".
      hostEnv = angular.isString(hostEnv) ? hostEnv.toLowerCase() : '';

      /* @ngInject */
      function DownloadAgentsModalController(_, $rootScope, $scope,
        $httpParamSerializer, RemoteAccessClusterService) {

        /**
         * Returns agent download url for given host type
         *
         * @method    getDownloadUrl
         * @param     {enum}    hostType        Type of the Host
         * @param     {enum}    [pkgType]       Type of package installer
         * @param     {enum}    [agentType]     Type of agent i.e. kGo, kJava
         * @returns   {string}  Download url (with cluster id if SPOG)
         */
        function getDownloadUrl(hostType, pkgType, agentType) {
          var path = 'physicalAgents/download?' +
            $httpParamSerializer({
              hostType: hostType,
              pkgType: pkgType,
              agentType: agentType
            });
          return RemoteAccessClusterService
            .decorateApiPathForRemote(API.public(path));
        }

        /**
         * Initilization for component
         */
        function activate() {
          // Declare the list of supported Cohesity agents
          var osAgents = [{
            hostEnv: 'windows',
            hostType: 'kWindows'
          }, {
            hostEnv: 'linux',
            hostType: 'kLinux',
            pkgTypeList: ['kScript', 'kRPM', 'kDEB', 'kSuseRPM', 'kPowerPCRPM'],

            //default package type
            pkgType: 'kRPM'
          },
          FEATURE_FLAGS.downloadGoAIXAgent && {
            hostEnv: 'aix',
            hostType: 'kAix',
            agentType: 'kGo',
            hostEnvText: 'aix7_2'
          },
          FEATURE_FLAGS.downloadJavaAIXAgent && {
            hostEnv: 'aix',
            hostType: 'kAix',
            agentType: 'kJava'
          },
          FEATURE_FLAGS.solarisEnabled && {
            hostEnv: 'solaris',
            hostType: 'kSolaris',
            hostEnvText: 'solaris11'
          },
          FEATURE_FLAGS.hpuxEnabled && {
            hostEnv: 'hpux',
            hostType: 'kHPUX',
            hostEnvText: 'HPUX'
          },
          (FEATURE_FLAGS.downloadSapHanaAgent && !disableSapOptionSql) && {
            hostEnv: 'sapHana',
            hostType: 'kSapHana',
            pkgTypeList: ['kScript', 'kRPM'],
            //default package type
            pkgType: 'kRPM',
            agentType: 'kLegacy'
          },
          (FEATURE_FLAGS.downloadJavaSapOracleAgent && !disableSapOptionSql) && {
            hostEnv: 'sapOracle',
            hostType: 'kSapOracle',
            agentType: 'kJava',
            pkgTypeList: ['kScript', 'kRPM'],
            // default package type
            pkgType: 'kRPM',
          },
          (FEATURE_FLAGS.downloadJavaSapHanaAgent && !disableSapOptionSql) && {
            hostEnv: 'sapHanaPpc',
            hostType: 'kSapHana',
            agentType: 'kJava'
          },].filter(_.identity);

          $scope.agentFiles = osAgents.filter(
            function osFilter(os) {
              return os.hostEnv.includes(hostEnv);
            });

          // Create url for each agent
          $scope.agentFiles.forEach($scope.transformHostAgent);
        }

        /**
         * Get url for agent download for host
         *
         * @method    transfromHostAgent
         * @param     {host}     host object from osAgents array
         */
        $scope.transformHostAgent =
          function transformHostAgent(host) {
            host.url = getDownloadUrl(host.hostType, host.pkgType,
              host.agentType);
          }

        activate();
      }

      return $uibModal.open(modalConfig).result;
    }

    /**
     * Warning modal to display objects with failed system backup.
     *
     * @param     {object}   [sourceObject]   array to source objects with system
     *                        backup enabled
     */
    /** @ngInject */
    function openSystemBackupFailWarningModal(sourceObjects) {

      var modalConfig = {
        modalFade: true,
        backdrop: 'static',
        controller: SystemBackupWarningController,
      };

      var windowOptions = {
        titleKey: 'bmrJobFail',
        actionButtonKey: $stateParams.mode === 'edit' ? 'saveJob' : 'createJob',
        contentKey: 'bmrFailWarningMessage',
        contentKeyContext: {
          sourceObjects: sourceObjects,
        },
      };

      function SystemBackupWarningController($scope, $uibModalInstance) {
        $scope.ok = function ok() {
          $uibModalInstance.close({
            createJobWithoutSystemBackup: true,
          });
        };
      }

      return cModal.standardModal(modalConfig, windowOptions);
    }

    /**
     * Fetch the JSON data for where agent binaries can be downloaded from.
     * Does some simplifying transformations before returning.
     *
     * @method   getAgentsUrls
     * @return   {object}   Promise to resolve with the list of agent file
     *                      urls, or raw server response if error.
     */
    function getAgentsUrls() {
      var opts = {
        method: 'get',
        type: 'json',
        url: Routes.agentsDataUri,
      };

      return $http(opts)
        .then(function dataReceived(resp) {
          return (resp.data || [])
            .map(function reducer(file) {
              var downloadRoot = Routes.agentsDataUri.split('/');

              downloadRoot.pop();
              file.file.filepath = downloadRoot
                .concat(file.file.Filename).join('/');
              file.file.hostenv = file.file.Hostenv.toLowerCase();

              return file.file;
            });
        });
    }

    /**
     * Transforming function to return a flattened list of vCenter sources
     *
     * @method     flattenSourceTree
     * @param      {object}  data  response from server
     * @return     {Array}         List of vCenter Sources
     */

    // TODO: Should this do more? Like, flatten all sources?
    function flattenSourceTree(data) {
      return data.entityHierarchy ? data.entityHierarchy.children : [];
    }

    /**
     * Success handler for updateSources call.
     *
     * @method   sourcesUpdateSuccess
     * @param    {object}   [response]   The server's response.
     * @return   {object}   Response passthrough.
     */
    function sourcesUpdateSuccess(response) {
      // clear the cache since sources data
      // is known to have changed
      sourcesCache.removeAll();

      // call getSources to refresh the cache
      getSources();

      return response;
    }

    /**
     * Initiate physical agent upgrade
     *
     * @method     upgradeAgents
     * @param      {Object}   data    The data
     * @return     {object}  Q promise carrying the server's response
     */
    function upgradeAgents(data) {
      return $http({
        method: 'post',
        url: API.public('physicalAgents/upgrade'),
        data: data,
      }).then(function transformUpgradeAgentResponse(response) {
        return response.data || {};
      });
    }

    /**
     * Generates a register app compatible Host object
     *
     * @method    getRegisterableHostObject
     * @param     {object}   node         The Node object to extract the host
     *                                    data from.
     * @param     {string}   appEnvType   The env type for the app for
     *                                    registering oracle/SQL
     * @returns   {object}   The generated Host object. Can be empty.
     */
    function getRegisterableHostObject(node, appEnvType) {
      var username = (node._credentials && node._credentials.username) ||
        undefined;

      return (!node || !node.entity) ? {} :
        {
          credentials: username ? { username: username } : undefined,
          ownerEntity: node.entity,
          appEnvVec: node.registeredEntityInfo.appEnvVec || [appEnvType],
          usesPersistentAgent: !username,
        };

    }

    /**
     * Generate a comma delimited list of supported filesystem protocols
     *
     * @method     getFSProtocols
     * @param      {array}   protocols  Supported FS protocol enum ints
     * @return     {string}  CSV string of supported FS protocol names
     *
     */
    function getFSProtocols(protocols, envType) {
      var enums;

      if (!Array.isArray(protocols)) {
        return;
      }

      switch(envType) {
        // Generic NAS, GPFS & Elastifile
        case 11:
        case 31:
        case 37:
          enums = ENUM_FILESYSTEM_TYPE_GENERIC;
          break;

        // Pure Storage FlashBlade
        case 21:
          enums = ENUM_FILESYSTEM_TYPE_FLASHBLADE;
          break;

        default:
          enums = ENUM_FILESYSTEM_TYPE;
      }


      return protocols.reduce(
        function filterFSProtocols(protocols, protocolKey) {
          if (enums[protocolKey]) {
            protocols.push(enums[protocolKey]);
          }

          return protocols;
        }, []).join(', ');
    }

    /**
     * Determines if supplied protocols are supported by the Volume or VServer
     *
     * @method     areProtocolsSupported
     * @param      {object}   entity     The entity to evaluate
     * @param      {Array}    protocols  The protocols to look for
     * @return     {boolean}  True if all supplied protocols are supported.
     */
    function areProtocolsSupported(source, protocols) {
      var entity = source.entity || source.entities[0];
      var typedEntity = getTypedEntity(entity);
      var subType;
      var protocolVec;

      protocols = [].concat(protocols);

      // Magneto uses a different set of protocol enums for generic NAS which
      // are offset by 1. Also the name and location of the protocol value is
      // different for Generic NAS proto.
      if (entity.type === 11) {
        protocols = protocols.map(function remapEnumForGenericNas(value) {
          return value + 1;
        });

        return protocols.every(function isProtocolIncluded(protocol) {
          return typedEntity.protocol === protocol;
        });
      }

      switch (true) {
        case !!typedEntity.volumeInfo:
          subType = 'volumeInfo';
          break;

        case !!typedEntity.vserverInfo:
          subType = 'vserverInfo';
          break;

        case !!typedEntity.clusterInfo:
          subType = 'clusterInfo';
          break;

        case !!typedEntity.mountPointInfo:
          subType = 'mountPointInfo';
          break;
      }

      switch (entity.type) {
        case ENV_TYPE_CONVERSION.kNetapp:
          protocolVec = 'dataProtocolVec';
          break;

        case ENV_TYPE_CONVERSION.kIsilon:
        case ENV_TYPE_CONVERSION.kFlashBlade:
          protocolVec = 'supportedProtocolVec';
          break;
      }

      return subType && protocols.every(function isProtocolIncluded(protocol) {
        return typedEntity[subType][protocolVec] ?
          typedEntity[subType][protocolVec].includes(protocol) : false;
      });
    }

    /**
     * Generates a source registration request object based on a source object
     * from the entity hierarchy.
     *
     * @method     reconstructRequestObjectFromSource
     * @param      {Object}  source  The source object from EH
     * @return     {Object}  a basic request object
     */
    function reconstructRequestObjectFromSource(source) {
      return source && {
        entity: source.entity,
        entityInfo: {
          endpoint: source.registeredEntityInfo.connectorParams.endpoint,
          hostType: source.entity.physicalEntity.hostType,
          type: source.entity.type,
        },
      };
    }

    /**
     * Convenience wrapper for cUtils.searchTree for finding an Entity in the
     * EntityHierarchy by its id.
     *
     * @method   findNodeInHierarchy
     * @param    {number}         entityId   The entity identifier.
     * @param    {object|array}   tree       One or more EntityHierarchy root
     *                                       node(s).
     * @return   {object}         The found node, or undefined.
     */
    function findNodeInHierarchy(entityId, tree) {
      var foundEntity;

      // Ensure the tree is an array of at least one sub-tree.
      [].concat(tree)
        .some(function eachBranch(subTree) {
          foundEntity = cUtils.searchTree(subTree, isSameNode, 'children');

          return !!foundEntity;
        });

      /**
       * Checks if the given node matches the given entityId.
       *
       * @method   isSameNode
       * @param    {object}    node   The node
       * @return   {boolean}   True if same node, False otherwise.
       */
      function isSameNode(node) {
        return node.entity && node.entity.id === entityId;
      }

      return foundEntity;
    }

    /**
     * Shows the AAG node select dialog.
     * TODO: Remove this after old job flow is not used anymore.
     *
     * @method   showAagNodeSelectDialog
     * @param    {object}   node   The node
     * @return   {object}   Promise to resolve/reject with the modal's outcome.
     */
    function showAagNodeSelectDialog(node) {
      var config = {
        size: 'lg',
        templateUrl: 'app/global/c-source-tree/dialogs.c-source-tree.html',

        /* @ngInject */
        controller: function AagDetectedModalController($scope,
          $uibModalInstance, evalAJAX, node) {
          var $ctrl = this;

          /**
           * Init this controller.
           *
           * @method   $onInit
           */
          $ctrl.$onInit = function $onInit() {
            $scope.node = $ctrl.node = node;
            $ctrl.getSetSelection(true);

            if (node._aags && !node._aagDetails) {
              bindAagDetailsToNode(node)
                .catch(evalAJAX.errorMessage);
            }
          };

          /**
           * Getter-Setter for the selection model.
           *
           * @method   getSetSelection
           * @param    {string|boolean}   [choice]   The user's selection.
           * @return   {*}                The current model value.
           */
          $ctrl.getSetSelection = function getSetSelection(choice) {
            if (arguments[0]) {
              $ctrl.aagSelectOption = choice;
              $ctrl.okDisabled = !choice;
            }

            return $ctrl.aagSelectOption;
          };

          /**
           * Modal ok/close method.
           *
           * @method   ok
           */
          $ctrl.ok = function ok() {
            $uibModalInstance.close($ctrl.getSetSelection() || true);
          };
        },
        resolve: { node: function() { return node; } },
      };
      var options = {
        actionButtonKey: 'select',
        closeButtonKey: false,
        titleKey: 'aagOptions',
      };

      return cModal.standardModal(config, options);
    }

    /**
     * Gets the aag information and binds it to the given node object.
     *
     * @method   bindAagDetailsToNode
     * @param    {object}   node   The node or yoda result
     * @return   {object}   Promise to resolve with the enhanced node.
     */
    function bindAagDetailsToNode(node) {
      var hostId = node.entity.sqlEntity ?
        node.entity.sqlEntity.ownerId :
        node.entity.id;

      return getAagInfo(hostId)
        .then(function received(aagInfo) {
          node._aagDetails = aagInfo;

          return node;
        });
    }

    /**
     * Fetches and caches the Database App Entities. Please don't use this cache
     * outside the DB file-based job transformer.
     *
     * @method   fetchAndCacheAppEntities
     * @param    {boolean}   [refresh=false]   Tell the request to fetch fresh
     *                                         data.
     * @return   {object}    Promise to resolve with the entities hash.
     */
    function fetchAndCacheAppEntities(refresh) {
      var cachedSources = service.dbHostsCache;

      if (!refresh && !_.isEmpty(cachedSources)) {
        return $q.resolve(cachedSources);
      }

      // TODO(spencer): Revise these params once getEntitiesOfType returns
      // appEntities.
      return getEntitiesOfType({
        environmentTypes: ['kSQL', 'kVMware', 'kPhysical', 'kOracle'],

        // TODO(spencer): Turn this back on once we can get appEntities from
        // this API
        // isProtected: true,
        oracleEntityTypes: ['kDatabase'],
        sqlEntityTypes: ['kInstance', 'kDatabase', 'kAAG'],
        vmwareEntityTypes: ['kVirtualMachine', 'kVirtualApp'],
        physicalEntityTypes: ['kHost', 'kWindowsCluster'],
      }).then(
        function setDBEntitiesCache(entities) {
          // Reduce the list to a lookup hash by entity id. Skip entities we
          // already know about.
          entities.forEach(function eachSource(appEntity) {
            if (!cachedSources[appEntity.id]) {
              cachedSources[appEntity.id] = appEntity;
            }
          });

          return cachedSources;
        }
      );
    }

    /**
     * Determines if the given entity is a System Database or not.
     *
     * @method   isSystemDatabase
     * @param    {object}     entity   The entity to check
     * @return   {boolean}    True if is system DB. False otherwise.
     */
    function isSystemDatabase(entity) {
      return (/\/(master|msdb|model)$/i).test(entity.displayName);
    }

    /**
     * Determines if the given entity is a TDE Database or not.
     *
     * @method   isTdeDatabase
     * @param    {object}     entity   The entity to check
     * @return   {boolean}    True if is TDE DB. False otherwise.
     */
    function isTdeDatabase(entity) {
      return _.get(entity,
        'oracleEntity.dbEntityInfo.tdeEncryptedTsCount', 0) > 0;
    }

    /**
     * Determines the number of Oracle RMAN channels.
     * An RMAN channel represents one stream of data to a device, and
     * corresponds to one database server session.
     *
     * @method   calculateOracleChannnels
     * @param    {Number}   [cpuCount]   Specifies the number of CPU in the host
     * @return   {Number}   Number of channels available for backup
     */
    function calculateOracleChannnels(cpuCount) {
      var nodeCount = $rootScope.clusterInfo.nodeCount;

      if (!cpuCount) {
        return nodeCount;
      }
      return Math.min(nodeCount, 2 * cpuCount);
    }

    /**
     * Returns the Drive Id for the given Office365 entity.
     *
     * @param    {object}   o365Source   Specifies the Office 365 source.
     * @return   {string}   OneDrive Id associated with the Office365 user.
     */
    function getOneDriveId(o365Source) {
      return _.get(o365Source, 'o365Entity.driveId');
    }

    /**
     * Determines if the given entity is an office 365 leaf entity.
     *
     * @method   isOffice365LeafEntity
     * @param    {string}    node   Specifies the selected node
     * @return   {boolean}   True if the type is a leaf entity, false,
     *                       otherwise.
     */
     function isOffice365LeafEntity(node) {
      // Office365 leaf entities include 'kSite' which can act as both leaf and
      // an internal node, hence an explicit check on the count of children is
      // needed.
      return OFFICE365_GROUPS.office365LeafInts
        .includes(node.entity.o365Entity.type) &&
        (!node.children || !node.children.length);
    }

    /**
     * Determines whether the given entity is an Oracle Dataguard primary
     * database.
     *
     * @method    isOracleDgPrimaryDatabase
     * @param     {object}    privateEntity   Specifies the private entity
     * @returns   {boolean}   True if the database is a DG Primary.
     */
    function isOracleDgPrimaryDatabase(privateEntity) {
      if (!privateEntity) {
        return false;
      }

      return _.get(
        privateEntity, 'oracleEntity.dbEntityInfo.dgEntityInfo.role') ===
          ORACLE_DG_DB_ROLE_TYPE.kPrimaryInt;
    }

    /**
     * Returns agent id vs endpoint object for a physical source. This
     * function returns a one to many map i.e. one agent id can point to
     * multiple ips.
     *
     * @method    getOracleAgentIdToHostAddressMap
     * @param     {object}      parentEntity  parent physical source of oracle
     *                                        node
     * @returns   {object}      host address to agent id map
     */
    function getOracleAgentIdToHostAddressMap(parentEntity) {
      var serverTypes = PUB_TO_PRIVATE_ENV_STRUCTURES.kPhysical.entityTypes;
      var agentIdToHostAddressMap = {};
      var agentHostIdToNameMap = _.chain(parentEntity.agentStatusVec)
        .filter(function filterAgentIter(agent) {
          return !!agent.displayName && !!agent.id;
        })
        .keyBy('id')
        .mapValues('displayName')
        .value();

      var resourceVec = _.chain(parentEntity)
        .get('networkingInfo.resourceVec', [])
        .filter(function filterNodes(item) {
          return item.type === NETWORKING_INFO_MAP.kServer;
        })
        .value();

      if (parentEntity.type === serverTypes.kHost) {
        // Get the first agent id in map
        for (var id in agentHostIdToNameMap) {
          agentIdToHostAddressMap[id] = [parentEntity.name];
          break;
        }
        return agentIdToHostAddressMap;
      }

      // TODO(Anurag): simplify these nested for loops.
      for (var id in agentHostIdToNameMap) {
        var agentDisplayName = agentHostIdToNameMap[id];
        agentIdToHostAddressMap[id] = [];

        // prepare a map for all the ips in resourvec to that point to same
        // agent.
        for (var i = 0; i < resourceVec.length; i++) {
          var tempHostAddressArray = [];
          var found = false;
          var endpoints = resourceVec[i].endpointVec;
          if (endpoints && endpoints.length) {
            for (var j = 0; j < endpoints.length; j++) {
              var endpoint = endpoints[j];
              var hostName = endpoints[0].ipv4Addr || endpoints[0].ipv6Addr;
              tempHostAddressArray.push(hostName);
              if ([
                endpoint.ipv4Addr,
                endpoint.ipv6Addr,
                endpoint.fqdn].includes(agentDisplayName)) {
                found = true;
              }
            }
            if (found) {
              tempHostAddressArray.forEach(function iterHostAddress(hostAddress) {
                agentIdToHostAddressMap[id].push(hostAddress);
              });
            }
          }
        }
      }

      return agentIdToHostAddressMap;
    }

    /**
     * Returns endpoint vs agent id object for a physical source. This
     * function returns a one to many map i.e. multiple ips can point
     * to one agent id.
     *
     * @method    getOracleHostAddressToAgentIdMap
     * @param     {object}      parentEntity  parent physical source of oracle
     *                                        node
     * @returns   {object}      Agent id to host address map
     */
    function getOracleHostAddressToAgentIdMap(parentEntity) {
      var hostAddressToAgentIdMap = {};
      var agentIdToHostAddressMap =
        getOracleAgentIdToHostAddressMap(parentEntity);

      for (var id of Object.keys(agentIdToHostAddressMap)) {
        agentIdToHostAddressMap[id].forEach(
          function iterHostAddress(hostAddress) {
            hostAddressToAgentIdMap[hostAddress] = id;
          }
        );
      }
      return hostAddressToAgentIdMap;
    }

    /**
     * @method   upgradeSourceApi
     * @param    {Array}   agentIds   List of Agent ID
     * @return   {object}   returns promise for V1/V2 Upgrade API
     */
    function upgradeSourceApi(agentIds) {
      if(FEATURE_FLAGS.enableV2ApiForDbAdaptor) {
        const upgradeRequestParams = {
          body: {
            agentIDs: agentIds
          },
        };
        return NgAgentServiceApi.CreateUpgradeTask(upgradeRequestParams)?.toPromise();
      }
      return upgradeAgents({ agentIds: agentIds });
    }

    return service;
  }

})(angular);

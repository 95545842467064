import { Injectable } from '@angular/core';
import { ProtectionJobsServiceApi, TenantServiceApi } from '@cohesity/api/v1';
import {
  ObjectServiceApi,
  ProtectedObjectInfo,
  ProtectionGroup as ProtectionGroupApi,
  ProtectionGroups,
  ProtectionGroupServiceApi,
  ProtectionPolicyResponse,
  UpdateProtectionGroupsState,
} from '@cohesity/api/v2';
import { DataFilterValue, SnackBarService } from '@cohesity/helix';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { ExportToCsvService } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { StateService, UIRouterGlobals } from '@uirouter/core';
import { get, isNil } from 'lodash-es';
import { BehaviorSubject, from, Observable, of, throwError } from 'rxjs';
import { defaultIfEmpty, filter, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import {
  AdaptorAccessService,
  AjsUpgradeService,
  DialogService,
  PassthroughOptionsService,
  UserService,
} from 'src/app/core/services';
import {
  enumGroupMap,
  ENV_GROUPS,
  envGroups,
  Environment,
  envTypes,
  FilterValue,
  JobEnvParamsV2,
  JobRunType,
  protectionGroupEnvValues,
  TaskStatus,
} from 'src/app/shared';
import { PolicyResponse, ProtectionPolicyService } from 'src/app/shared/policy';

import { PolicyUtils } from '../../policy-shared/protection-policy-utils';
import {
  CassandraSetPrimaryModalComponent,
} from '../../protection/nosql/cassandra-set-primary-modal/cassandra-set-primary-modal.component';
import { GroupActionModalComponent } from '../components/group-action-modal/group-action-modal.component';
import {
  ProtectionGroupDeleteDialogComponent,
} from '../components/protection-group-delete-dialog/protection-group-delete-dialog.component';
import {
  PauseRunsDialogData,
  PauseRunsDialogResponse,
  ProtectionGroupPauseRunsDialogComponent
} from '../components/protection-group-pause-runs-dialog/protection-group-pause-runs-dialog.component';
import { GroupAction } from '../models';
import { ProtectionStates } from '../models/protection-group.constants';
import { ProtectionGroup } from '../models/protection-group.models';
import { ProtectionRunService } from './protection-run.service';

/**
 * Available job modifier actions using modal.
 */
export enum ModalAction {
  Deactivate = 'deactivate',
  Delete = 'delete',
  Failover = 'failover',
  Start = 'start',
  Cancel = 'cancel',
}

/**
 * Mapping from action name (before translation) to enum value for API call.
 */
const actionMap: { [index: string]: any } = {
  [ GroupAction.Pause ]: 'kPause',
  [ GroupAction.Resume ]: 'kResume',
  [ GroupAction.Failover ]: 'kActivate',
  [ GroupAction.Deactivate ]: 'kDeactivate',
  [ GroupAction.Cancel ]: 'kCancel',
};

/**
 * Provides a mapping of filter keys to actual API params.
 */
const filterTaskToParamNameMap = {
  Backup: 'lastRunLocalBackupStatus',
  Replication: 'lastRunReplicationStatus',
  CloudSpin: 'lastRunCloudSpinStatus',
  Archival: 'lastRunArchivalStatus',
};

/**
 * Policy map of policy id to protection policy response from API.
 */
export const policiesCache: { [index: string]: ProtectionPolicyResponse } = {};

/**
 * Get group API parameters.
 */
export type GroupParams = ProtectionGroupServiceApi.GetProtectionGroupsParams;

/**
 * Protection Run service for getting Protection Run Info and delete snapshots.
 */
@Injectable({
  providedIn: 'root',
})
export class ProtectionGroupService {
  /**
   * Legacy AJS JobActionService service.
   */
  private ajsJobActionService: any;

  /**
   * Legacy AJS JobRunsService service.
   */
  private ajsJobService: any;

  /**
   * BehaviorSubject that will be set by the protection group component and consumed
   * by the sub-components like protection runs, protection run details,
   * replication/ archival details pages.
   */
  private groupPolicy$ = new BehaviorSubject<ProtectionPolicyResponse>(null);

  /**
   * the observable that is read from other components.
   */
  readonly selectedGroupPolicy$ = this.groupPolicy$.asObservable();

  /**
   * Constructor.
   */
  constructor(
    private adaptorAccessService: AdaptorAccessService,
    private dialogService: DialogService,
    private irisCtx: IrisContextService,
    private groupsService: ProtectionGroupServiceApi,
    private jobsService: ProtectionJobsServiceApi,
    private objectService: ObjectServiceApi,
    private policyService: ProtectionPolicyService,
    private snackbarService: SnackBarService,
    private state: StateService,
    private translate: TranslateService,
    private upgradeService: AjsUpgradeService,
    private userService: UserService,
    private passthroughOptionsService: PassthroughOptionsService,
    private runService: ProtectionRunService,
    private exportToCsvService: ExportToCsvService,
    private uiRouterGlobals: UIRouterGlobals,
    private tenantService: TenantServiceApi,
  ) {
    this.ajsJobActionService = this.upgradeService.get('JobActionService');
    this.ajsJobService = this.upgradeService.get('JobService');
  }

  /**
   * Get the callback function used in AJS based on menu action.
   *
   * @param  action  Job action.
   * @returns Callback function used in AJS.
   */
  getModalActionCallback(action: ModalAction) {
    switch (action) {
      case ModalAction.Start:
      case ModalAction.Cancel:
        return () => setTimeout(() => {
          this.passthroughOptionsService.reset();
          this.state.reload();
        }, 3500);
      case ModalAction.Deactivate:
        return () => {
          this.passthroughOptionsService.reset();
          this.state.reload();
        };
      case ModalAction.Delete: {
        const { name } = this.uiRouterGlobals.current;

        return (snapshotDeleted: boolean) => {
          this.passthroughOptionsService.reset();

          if (snapshotDeleted && name !== ProtectionStates.groupList) {
            this.state.go(ProtectionStates.groupList);
          } else {
            this.state.reload();
          }
        };
      }
      case ModalAction.Failover:
        return (jobUid, sourceEntity, isCloudMigrationJob) => {
          this.passthroughOptionsService.reset();

          // when failing over a cloud source, go to clone flow instead of recover flow.
          if (isCloudMigrationJob && ENV_GROUPS.cloudDeploySources.includes(envTypes[sourceEntity.type])) {
            this.state.go('clone-vms.clone-options', {
              jobId: jobUid.objectId,
              jobUid,
              sourceEntity,
              entityId: sourceEntity.id,
              entityType: sourceEntity.type,
            });
          } else {
            // activates failover flow after user accepts confirmation modal.
            this.state.go('recover-vm.recover-options', {
              jobId: jobUid.objectId,
              jobUid,
              sourceEntity,
            });
          }
        };
    }
  }

  /**
   * Opens job action modal dialog for specified job.
   *
   * @param  action          Job action.
   * @param  jobId           Job ID to run.
   * @param  startTimeUsecs  Job's last run start time in usec.
   */
  openJobActionModal(action: ModalAction, jobId: number, startTimeUsecs?: number) {
    if ((action === ModalAction.Delete || action === ModalAction.Failover) && !startTimeUsecs) {
      // This is edge case handler when delete/failover a job without run.
      // Since for job without job run, we can't transform job object using this.ajsJobRunsService.getJobRuns,
      // so we make a fake job object with necessary property to open JobAction dialogs and JobService.someJobAction.
      const job = {
        jobDescription: {
          jobId: jobId
        },
        _policy: {},
      };
      this.runJobAction(action, job);
    } else {
      from(this.ajsJobService.getJob(jobId, {
        excludeTasks: true,
        includeJobsWithoutRun: true,
        startTimeUsecs,
      })).subscribe((job: any) => {
        // Cancel action needs job instance id. Fetch using getProtectionJobs endpoint and add it to job object.
        if (action === ModalAction.Cancel) {
          this.jobsService.GetProtectionJobs({
            ids : [jobId],
            includeLastRunAndStats: true,
            onlyReturnBasicSummary: true,
            ...this.passthroughOptionsService.requestParams,
          }).subscribe(jobDetails => {
            job.lastRunId = get(jobDetails[0], 'lastRun.backupRun.jobRunId');
            this.runJobAction(action, { jobDescription: job });
          });
        } else {
          if (action === ModalAction.Delete) {
            // Decorate the job with the info of whether it is protect once
            // since we are still using v1 job api for protection group deletion.
            job.isProtectOnce = PolicyUtils.isProtectOncePolicy(policiesCache[job['policyId']]);
          }
          this.runJobAction(action, { jobDescription: job });
        }
      });
    }
  }

  /**
   * Open group actions (run now) modal.
   *
   * @param protectionGroup Protection Group instance.
   */
  openGroupActionModal(protectionGroup: ProtectionGroup) {
    return this.dialogService.showDialog(GroupActionModalComponent, { protectionGroup });
  }

  /**
   * Open Set primary (run now) modal for.
   *
   * @param protectionGroup Protection Group instance.
   */
  openCassandraSetPrimaryModal(protectionGroup: ProtectionGroup) {
    return this.dialogService.showDialog(CassandraSetPrimaryModalComponent, { protectionGroup });
  }

  /**
   * Handles group action callback.
   *
   * @param action Group action to handle.
   */
  handleGroupAction(action: GroupAction) {
    if (action === GroupAction.Run) {
      this.snackbarService.open(this.translate.instant('newRunHasBeenCreated'));
      setTimeout(() => {
        this.passthroughOptionsService.reset();
        this.state.reload();
      }, 1000);
    }
  }

  /**
   * Run action callback on JobAction object fetched from Ajs JobAction Service
   *
   * @param action Object of type ModalAction.
   * @param job Object fetched from AjsJobService getJob().
   *
   */
  runJobAction(action: ModalAction, job: any): any {
    this.ajsJobActionService.getJobAction(
      action,
      job,
      this.getModalActionCallback(action)
    ).action();
  }

  /**
   * Map filters to ajax call parameters.
   *
   * @param    filters  Filters applied to the table
   * @returns  API call parameters
   */
  mapGroupParams(filters: DataFilterValue<any>[]): GroupParams {
    const params: GroupParams = {
      isDeleted: false,
      includeLastRunInfo: true,
    };

    filters.forEach(appliedFilter => {
      const { key, value } = appliedFilter;

      if (value && value.length) {
        const [{ value: firstValue }] = value;

        if (key === 'lastRunStatus') {
          // Map singular status filter to all of the possible status params
          // so when the user selects 'Failed' (for instance) they will see
          // groups having last run failing any type of schedule.

          if (firstValue.includes('.')) {
            // A child level filter was selected. Need to determine which
            // param should actually be sent and then set it.
            const filterKeys = firstValue.split('.');
            const copyTaskType = filterKeys[0];
            const status = filterKeys[1];
            const paramName = filterTaskToParamNameMap[copyTaskType];
            params[paramName] = [status];
          } else {
            // Parent level was selected. Use the "any" status param.
            params.lastRunAnyStatus = [firstValue];
          }
        } else if (key === 'policyId') {
          // Map singular filter names to plural API params.
          params[key + 's'] = [firstValue];
        } else if (key === 'tenantIds') {
          params[key] = value.map(v => v.value);
        } else if (key === 'environments') {
          params.environments = [];
          value.forEach(valFilter => params.environments = !envGroups[valFilter.value]
            // If the value is not in the "envGroups" map it is most likely a
            // specific environment and can be passed along as-is.
            ? [valFilter.value]

            // Otherwise, build the list of environments from envGroups.
            : params.environments.concat(envGroups[valFilter.value].filter(environment => {
              if ([Environment.kO365, Environment.kO365Outlook].includes(environment) &&
                !(flagEnabled(this.irisCtx.irisContext, 'office365GroupSupportEnabled') ||
                  flagEnabled(this.irisCtx.irisContext, 'office365MailboxEnabled') ||
                  flagEnabled(this.irisCtx.irisContext, 'office365OneDriveSupportEnabled') ||
                  flagEnabled(this.irisCtx.irisContext, 'office365PublicFolderSupportEnabled') ||
                  flagEnabled(this.irisCtx.irisContext, 'office365SharePointSupportEnabled') ||
                  flagEnabled(this.irisCtx.irisContext, 'office365TeamsSupportEnabled'))) {
                return false;
              }
              if (Environment.kAD === environment && !flagEnabled(this.irisCtx.irisContext, 'activeDirectorySourceEnabled')) {
                return false;
              }
              return protectionGroupEnvValues[environment];
            }))
          );
        } else if (key === 'task') {
          switch (firstValue) {
            case 'kNative':
              params.isActive = true;
              break;
            case 'kForeign':
              params.isActive = false;
              break;
            case 'kPaused':
              params.isPaused = true;
              break;
            case 'kDeleted':
              params.isDeleted = true;
              break;
          }
        } else if (key === 'slaStatus' ) {
          if (firstValue === 'kMet') {
            params.isLastRunSlaViolated = false;
          } else if (firstValue === 'kMissed') {
            params.isLastRunSlaViolated = true;
          }
        }
      }
    });

    return params;
  }

  /**
   * Get protection Groups
   *
   * @param    params  Protection group parameters.
   * @returns  Observable of Protection Groups.
   */
  getGroups(params: GroupParams): Observable<ProtectionGroups> {
    params = {
      ...params,
      includeTenants: true,
      ...this.passthroughOptionsService.requestParams,
    };

    return this.groupsService.GetProtectionGroups(params);
  }

  /**
   * Get protection Group by ID.
   *
   * @param    groupId  Protection group ID.
   * @returns  Observable of Protection Group.
   */
  getGroup(groupId: string, options: Partial<GroupParams> = {}): Observable<ProtectionGroupApi> {
    if (!groupId) {
      return of(null);
    }
    return this.groupsService.GetProtectionGroupById({
      id: groupId,
      ...options,
      ...this.passthroughOptionsService.requestParams,
    });
  }

  /**
   * Returns Protection Group instance by ID.
   *
   * @param    groupId  Protection group ID.
   * @returns  Observable of Protection Group instance.
   */
  getProtectionGroup(groupId: string, options: Partial<GroupParams> = {}): Observable<ProtectionGroup> {
    return this.getGroup(groupId, options).pipe(
      filter(group => !isNil(group)),
      map(group => new ProtectionGroup(group))
    );
  }

  /**
   * Check if specific action is allowed for the protection group.
   * Based on logic in isValidJobAction() of AJS job-action-service.js.
   *
   * @param    action  Specific protection group action.
   * @param    group   Protection Group
   * @returns  true if action is allowed.
   */
  isValidGroupAction(action: GroupAction, group: ProtectionGroup): boolean {
    const { privs } = this.userService;
    const sqlParallelLogRuns = flagEnabled(this.irisCtx.irisContext, 'sqlParallelLogRuns');

    // Determine whether to allow VMWare parallel run. Run now should be allowed
    // even when there is a run in progress.
    const allowVmWareParallelRuns =
      flagEnabled(this.irisCtx.irisContext, 'vmwareParallelRuns') &&
      group.environment === Environment.kVMware &&
      group.lastRun.status === TaskStatus.Running;

    const isContinuousSnapshotEnabled =
      flagEnabled(this.irisCtx.irisContext, 'continuousSnapshotsEnabled') &&
      group.reference[JobEnvParamsV2[group.environment]]?.continuousSnapshots?.isEnabled;

    // Determine whether to allow Oracle parallel log runs. Run now should be allowed
    // even when there is a incremental/full backup run in progress.
    const oracleParallelLogRuns = flagEnabled(this.irisCtx.irisContext, 'oracleParallelLogRuns') &&
      group.environment === Environment.kOracle && group.lastRun.status === TaskStatus.Running
      && group.lastRun.runType !== JobRunType.kLog;

    // Determine whether to allow Physical Files parallel run. Run now should be allowed
    // even when there is a run in progress.
    const allowPhysicalFilesParallelRuns = group.allowParallelRuns &&
      group.environment === Environment.kPhysicalFiles &&
      group.lastRun.status === TaskStatus.Running;

    // Determine whether to allow Cassandra to set this cohesity cluster as primary in the job run.
    const allowCassanadraSetPrimaryRun =
      flagEnabled(this.irisCtx.irisContext, 'cassandraPITSetPrimary') &&
      group.environment === Environment.kCassandra &&
      group.isCassandraLogbackup &&
      group.lastRun.status !== TaskStatus.Running;

    switch (action) {
      case GroupAction.Run:
        // Group is local/active, group is at rest, AND group is not deleted or protect once or is externally triggered
        // protection job, show Run Now button.
        // Note that this is still shown when the job is paused because paused job is for a
        // scheduled future job but run now is a manual action. They don't affect each other.
        return privs.PROTECTION_JOB_OPERATE &&
          ((group.isActive &&
          !group.isDeleted &&
          !group.isProtectOnce &&
          !group.isLastRunPaused() &&
          group.isAtRest(sqlParallelLogRuns) &&
          !group.isExternallyTriggered) ||
          allowVmWareParallelRuns ||
          isContinuousSnapshotEnabled ||
          oracleParallelLogRuns ||
          allowPhysicalFilesParallelRuns
        );

      case GroupAction.Failover:
        if (!privs.PROTECTION_MODIFY || !privs.RESTORE_MODIFY || group.isActive || group.regionId) {
          return false;
        }

        switch (group.environment) {
          case Environment.kVMware:
            return true;
          case Environment.kGCP:
            return true;

          case Environment.kHyperV:
          case Environment.kHyperVVSS:
            return flagEnabled(this.irisCtx.irisContext, 'hyperVFailover');

          case Environment.kAWS:
            return flagEnabled(this.irisCtx.irisContext, 'awsFailover') && group.isNativeGroup;

          case Environment.kAzure:
            return flagEnabled(this.irisCtx.irisContext, 'azureFailover') && group.isNativeGroup;

          default:
            return false;
        }

      case GroupAction.Pause:
        // Group is not paused and active, not protect once, not deleted, and is externally triggered protection job,
        // show pause option
        return privs.PROTECTION_JOB_OPERATE &&
          group.isActive &&
          !group.isPaused &&
          !group.isProtectOnce &&
          !group.isDeleted &&
          !group.isExternallyTriggered;

      case GroupAction.Resume:
        // Group is paused, show un-pause option
        return privs.PROTECTION_JOB_OPERATE &&
          group.isPaused &&
          !group.isDeleted;

      case GroupAction.Cancel:
        // Group is running or paused, not deleted, and is externally triggered protection job, show the cancel button
        return privs.PROTECTION_JOB_OPERATE &&
          group.lastRun.status === TaskStatus.Running &&
          !group.isDeleted &&
          !group.isExternallyTriggered;

      case GroupAction.Edit:
        // if group is not deleted and is local (not replication target), it is
        // editable
        return privs.PROTECTION_MODIFY &&
          !group.isDeleted &&
          group.isActive;

      case GroupAction.Delete:
        // If group is at rest, has no active archival run and no replication run,
        // not wormlocked or deleted, show delete action
        return privs.PROTECTION_MODIFY &&
          group.isAtRest(sqlParallelLogRuns) &&
          !group.hasActiveArchivalRun() &&
          !group.hasActiveReplicationRun() &&
          !group.isDeleted;

      case GroupAction.DeleteSnapshots:
        // If group is at rest and deleted, show delete group with snapshot action
        return privs.PROTECTION_MODIFY &&
          group.isAtRest(sqlParallelLogRuns) &&
          group.isDeleted;

      case GroupAction.SelectSnapshotsForDelete:
      // If group is at rest, show delete group with snapshot action.
      // This will allow user to go to the UI to select snapshots for delete.
      // TODO(ang): When enable it, check existing deletable runs
      // return privs.PROTECTION_MODIFY;
        return false;

      case GroupAction.Deactivate:
        // Deactivate Group if Policy has a Remote Replication rule
        return privs.PROTECTION_MODIFY &&
          group.isActive && (group.environment === Environment.kVMware ||

          // HyperV Failover id feature flag protected
          (flagEnabled(this.irisCtx.irisContext, 'hyperVFailover') &&
            [Environment.kHyperV, Environment.kHyperVVSS].includes(group.environment as Environment)) ||

          // AWS failover is featureflag protected and depends on native job type
          (flagEnabled(this.irisCtx.irisContext, 'awsFailover') &&
            group.environment === Environment.kAWS && group.isNativeGroup) ||

          // Azure failover is featureflag protected and depends on native job type
          (flagEnabled(this.irisCtx.irisContext, 'azureFailover') &&
            group.environment === Environment.kAzure && group.isNativeGroup));

      case GroupAction.ExportToCsv:
        // Export the runs as csv file which gets downloaded.
        return flagEnabled(this.irisCtx.irisContext, 'enableUiExportToCsv');

      case GroupAction.SetPrimary:
        // if group is for Cassandra log backup and is local (not replication target)
        return allowCassanadraSetPrimaryRun &&
          group.isActive;

      default:
        // unknown action
        return false;
    }
  }

  /**
   * Get the allowed actions for protection Group based on group data
   *
   * @param    group  Protection group.
   * @returns  Allowed group actions.
   */
  getGroupActions(group: ProtectionGroup): GroupAction[] {
    return Object.values(GroupAction)
      .filter(action => this.isValidGroupAction(action, group));
  }

  /**
   * Get protection policies, used for filter and name display
   *
   * @param    params  Protection group parameters.
   * @returns  Observable of filter value array.
   */
  getPolicies(): Observable<FilterValue[]> {
    return this.policyService.getPolicies().pipe(
      filter(response => response && !!response.policies),

      // Cache the list of policies
      tap(response => response.policies.forEach(policy => policiesCache[policy.id] = policy)),
      map(response => (response.policies as PolicyResponse[]).map(policy => ({
        label: policy.name,
        value: policy.id
      })))
    );
  }


  /**
   * Get tenants, used for filter and name display
   *
   * @param    params  Get tenants parameters.
   * @returns  Observable of filter value array.
   */
  getTenants(params: TenantServiceApi.GetTenantsParams): Observable<FilterValue[]> {
    return this.tenantService.GetTenants(params).pipe(
      filter(response => !!response),
      map(response => response.map(tenant => ({
        label: tenant.name,
        value: tenant.tenantId
      }))));
  }

  /**
   * Get protection group policy by ID.
   *
   * @param    policyId  Policy ID.
   * @returns  Observable of protection group policy.
   */
  getPolicy(policyId: string): Observable<PolicyResponse> {
    return this.policyService.getPolicyById(policyId);
  }

  /**
   * Update protection Group States
   *
   * @param    actionName    Action.
   * @param    groupIds      Protection Group IDs.
   * @returns  Observable of API call result.
   */
  updateGroupsState(actionName: GroupAction, groupIds: string[], pausedNote?: string):
  Observable<UpdateProtectionGroupsState> {
    const action = actionMap[actionName];

    if (!action) {
      throwError({ error: { message: 'actionNotDefined' }});
    }
    if (!groupIds.length) {
      throwError({ error: { message: 'noSelection' }});
    }

    return this.groupsService.UpdateProtectionGroupsState({
      body: { action, ids: groupIds, pausedNote },
      ...this.passthroughOptionsService.requestParams,
    }).pipe(tap((res: UpdateProtectionGroupsState) => {
      if (res.failedProtectionGroups && res.failedProtectionGroups.length) {
        this.snackbarService.open(
          this.translate.instant(`updateProtectionGroup.${action}.message.failure`, {
            messages: res.failedProtectionGroups
              .map(({ protectionGroupId, errorMessage }) => `${protectionGroupId} - ${errorMessage}`)
              .join(', '),
          }),
          'error'
        );
      } else if (res.successfulProtectionGroupIds && res.successfulProtectionGroupIds.length) {
        this.snackbarService.open(this.translate.instant(`updateProtectionGroup.${action}.message.success`));
      }
    }));
  }

  /**
   * Protection Group Pause Future Runs
   *
   * @param protectionGroup Protection Group to pause future runs.
   */
  pauseFutureRuns(protectionGroup: ProtectionGroup): Observable<any> {
    const data: PauseRunsDialogData = {
      title: 'protectionGroups.pauseFutureRunsJob.dialogTitle',
    };

    const pauseRunDialog$ = this.dialogService
      .showDialog<ProtectionGroupPauseRunsDialogComponent, PauseRunsDialogResponse>(
        ProtectionGroupPauseRunsDialogComponent, data
      );

    return pauseRunDialog$.pipe(
      filter(Boolean),
      mergeMap((pauseRunsDialogData: PauseRunsDialogResponse) => this.updateGroupsState(
        GroupAction.Pause,
        [protectionGroup.id],
        pauseRunsDialogData?.note
      )),
      defaultIfEmpty(null)
    );
  }

  /**
   * Protection Group Delete with confirmation dialog.
   *
   * @param protectionGroup Protection Group to delete.
   */
  deleteGroup(protectionGroup: ProtectionGroup): Observable<GroupAction> {
    const groupAction$: Observable<GroupAction> =
      this.dialogService.showDialog(ProtectionGroupDeleteDialogComponent, { protectionGroup });

    return groupAction$.pipe(
      filter(Boolean),
      mergeMap(groupAction => this.groupsService.DeleteProtectionGroup({
        id: protectionGroup.id,
        deleteSnapshots: groupAction === GroupAction.DeleteSnapshots,
        ...this.passthroughOptionsService.requestParams
      })),
      withLatestFrom(groupAction$),
      map(([, groupAction]) => groupAction)
    );
  }

  /**
   * Gets environment tooltip for the icon
   *
   * @param    environment  Protection group environment.
   * @param    groupOnly    Shows environment group name only.
   * @returns  Tooltip string.
   */
  getEnvironmentTooltip(environment: Environment, groupOnly = false): string {
    const label = this.translate.instant(`enum.envGroup.longName.${enumGroupMap[environment]}`);

    if (groupOnly) {
      return label;
    }
    return this.translate.instant('labelColonValue', {
      label,
      value: this.translate.instant(`enum.environment.${environment}`)
    });
  }

  /**
   * Returns protected object instances by ID.
   *
   * @param    objectId  Protected Object ID.
   * @returns  Observable for protected object instance.
   */
  getProtectedObject(objectId: number): Observable<ProtectedObjectInfo> {
    return this.objectService.GetProtectedObjectOfAnyTypeById({
      id: objectId,
      ...this.passthroughOptionsService.requestParams,
    });
  }

  /**
   * Function to fetch runs of a protection group and export them as a csv file.
   *
   * @param id The id of the group.
   */
  exportGroupRunsToCsv(id: string, startTimeUsecs?: number, endTimeUsecs?: number) {
    this.runService.getRuns({
      id,
      startTimeUsecs,
      endTimeUsecs,
      includeObjectDetails: false,
    }).subscribe(
      value => {
        this.passthroughOptionsService.reset();
        this.exportToCsvService.downloadCsv(value?.runs || [], 'protection_runs');
      }
    );
  }

  /**
   * Setter the is called when the group details are retrieved in the protection group component
   *
   * @param policy
   */
  setPolicy(policy: ProtectionPolicyResponse) {
    const currentPolicy = this.groupPolicy$.getValue();
    if (currentPolicy?.id !== policy?.id) {
      this.groupPolicy$.next(policy);
    }
  }
}

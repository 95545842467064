<div class="settings-list-item"
  [class.settings-list-item-col]="columnLayout"
  cogDataId="settings-list-item">
  <div class="settings-list-item-label" *ngIf="(label && !labelTemplate) ||
    (resetToDefault && editMode && valueChanged)">
    <label *ngIf="label && !labelTemplate" [inheritDataId]="true"
      [ngClass]="{
        'settings-list-item-label-width-sm': labelWidth === 'sm',
        'settings-list-item-label-width-lg': labelWidth === 'lg'
      }"
      [cogDataId]="'label-' + (label | lowercase)">
      {{label}}
      <small *ngIf="description && editMode && !valueChanged"
        class="settings-list-item-description text-muted margin-top-xs margin-right-xl">
        {{description}}
      </small>
    </label>
    <small *ngIf="resetToDefault && editMode && valueChanged"
      class="settings-list-item-reset text-muted margin-top-xs margin-right-xl">
      <button
        (click)="resetAction.emit()"
        [inheritDataId]="true"
        class="settings-list-item-action-button"
        type="button"
        color="primary"
        [cogDataId]="'action-button-' + (label | lowercase)"
        mat-icon-button>
        {{resetToDefault}}
      </button>
    </small>
  </div>
  <div
    class="settings-list-item-label"
    [ngClass]="{
        'settings-list-item-label-width-sm': labelWidth === 'sm',
        'settings-list-item-label-width-lg': labelWidth === 'lg'
      }"
    *ngIf="labelTemplate">
    <ng-container *ngTemplateOutlet="labelTemplate; context: {
      editMode: editMode
    }">
    </ng-container>
  </div>
  <div class="settings-list-item-content">
    <div [@openClose]="editMode ? 'close' : 'open'"
      [inheritDataId]="true"
      class="settings-list-item-value"
      [cogDataId]="'value-' + (label | lowercase)">
      <ng-content select="cog-settings-list-item-value-display"></ng-content>
    </div>
    <div [@openClose]="editMode ? 'open' : 'close'"
      [inheritDataId]="true"
      class="settings-list-item-editor"
      [cogDataId]="'editor-' + (label | lowercase)">
      <ng-content select="cog-settings-list-item-value-editor"></ng-content>
    </div>
  </div>

  <!-- Action button provided as input to this component, visible only in view mode. -->
  <div *ngIf="!editMode && actionIcon" class="settings-list-item-action-button-wrapper">
    <button (click)="onActionClick($event)" [inheritDataId]="true" class="settings-list-item-action-button"
      [cogDataId]="'action-button-' + (label | lowercase)" mat-icon-button type="button">
      <cog-icon [shape]="actionIcon"></cog-icon>
    </button>
  </div>

  <!-- Using div wrapper for the tooltip as the tooltip won't display when attached to a disabled button. -->
  <div *ngIf="!readOnly && !alwaysEdit"
    [matTooltipDisabled]="!invalid"
    [matTooltip]="intlService.settingsListDisabledTooltip"
    class="settings-list-item-action-button-wrapper">
    <button (click)="toggleEditMode($event)"
      [disabled]="invalid"
      [inheritDataId]="true"
      class="settings-list-item-action-button"
      [cogDataId]="'edit-button-' + (label | lowercase)"
      mat-icon-button
      type="button">
      <cog-icon [shape]="editMode ? 'close!solid' : 'edit!outline'"></cog-icon>
    </button>
  </div>
</div>

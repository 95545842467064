import { REGEX_FORMATS } from '../constants';

/**
 * Checking whether the IP is within the Apps Subnet IP range.
 *
 * @param appIpStr    App IP Address
 * @param subNetMask  App subnet mask
 * @param matchIpStr  IP to match against the App CIDR
 * @returns true if the IP is matched else false
 */
export const checkForIpMatch = (appIpStr: string, subNetMask: number, matchIpStr: string): boolean => {
  const ipMatchCount = 4 - Math.floor(subNetMask / 8);
  const appIpArr = appIpStr.split('.');
  const matchIpArr = matchIpStr.split('.');
  let isMatched = true;

  for (let i = 0; i < ipMatchCount; i++) {
    if (appIpArr[i] !== matchIpArr[i]) {
      isMatched = false;
    }
  }
  return isMatched;
};

/**
 * Create Netmask Ipv4 Addrs
 *
 * @param     Number    netmaskBits
 * @returns   String    return netmask Ipv4 addrs string
 */
export function createNetmaskAddr(netmaskBits: number): string {
  const mask = [];
  let n: number;
  for (let i = 0; i < 4; i++) {
    n = Math.min(netmaskBits, 8);
    mask.push(256 - Math.pow(2, 8 - n));
    netmaskBits -= n;
  }
  return mask.join('.');
}

/**
 * Create Netmask Ipv6 Addrs
 *
 * @param     Number    netmaskBits
 * @returns   String    return netmask Ipv6 addrs string
 */
export function createIPv6NetmaskAddr(netmaskBits: number): string {
  let mask = '';
  for (let i = 0; i < 8; i++) {
    if (netmaskBits >= 16) {
      mask += 'ffff';
      netmaskBits -= 16;
    } else {
      mask += (Math.pow(2, netmaskBits) - 1).toString(16).padStart(4, '0');
      netmaskBits = 0;
    }
    if (i < 7) {
      mask += ':';
    }
  }
  return mask;
}

/**
 * Validates if a given IPv4 mask is valid.
 *
 * An IPv4 mask is considered valid if it is a 32-bit number represented as four decimal numbers separated by periods,
 * where each decimal number is between 0 and 255. The mask must contain only contiguous 1s followed by 0s.
 *
 * @param mask - The IPv4 mask to validate.
 * @returns true if the mask is valid, false otherwise.
 */
export function isValidIPv4Mask(mask) {
  // Convert the mask to a number
  const maskParts = mask.split('.').map(Number);

  // Ensure there are exactly four parts
  if (maskParts.length !== 4) {
    return false;
  }

  // Validate each part
  for (const part of maskParts) {
    if (part < 0 || part > 255 || isNaN(part)) {
      return false;
    }
  }

  // Convert the mask to a binary string and check if it contains only contiguous 1s followed by 0s
  const binaryMask = maskParts.map(part => part.toString(2).padStart(8, '0')).join('');
  const firstZeroIndex = binaryMask.indexOf('0');

  // If there are no zeros or the zeros are not contiguous, it's invalid
  if (firstZeroIndex === -1 || binaryMask.slice(firstZeroIndex).includes('1')) {
    return false;
  }

  return true;
}

/**
 * Checks if a given IPv4 address belongs to a private network.
 *
 * This function validates the input IP address format and checks if it falls within the following private IP ranges:
 * - 10.0.0.0 – 10.255.255.255 (10/8 prefix)
 * - 172.16.0.0 – 172.31.255.255 (172.16/12 prefix)
 * - 192.168.0.0 – 192.168.255.255 (192.168/16 prefix)
 *
 * @param ipAddress - The IPv4 address to check.
 * @returns true if the IP address belongs to a private network, false otherwise.
 */
export function isPrivateIPv4(ipAddress: string): boolean {
  if (!REGEX_FORMATS.IPv4.test(ipAddress) && !REGEX_FORMATS.CIDRIPv4.test(ipAddress)) {
    return false;
  }

  const parts = ipAddress.split('.').map(Number);

  // Private IP ranges for IPv4:
  // 10.0.0.0 – 10.255.255.255 (10/8 prefix)
  if (parts[0] === 10) {
    return true;
  }

  // 172.16.0.0 – 172.31.255.255 (172.16/12 prefix)
  if (parts[0] === 172 && parts[1] >= 16 && parts[1] <= 31) {
    return true;
  }

  // 192.168.0.0 – 192.168.255.255 (192.168/16 prefix)
  if (parts[0] === 192 && parts[1] === 168) {
    return true;
  }

  return false;
}

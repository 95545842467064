import { ApplicationEnvironment, ApplicationToSourceEnvironment } from '../env/application-environment.constants';
import { EnvironmentEntitiesAsSource } from '../env/environment-entities-as-source.constants';
import { Environment } from './../env/environment.constants';
import { Office365BackupType } from './office365.constants';

/**
 * The generic data structure to hold the CanAccessEnv callback or result in the same format.
 *
 * @example
 *   // The data structure holding the CanAccessEnv callback references used to determine whether an adaptor is
 *   // accessible or not.
 *   type CanAccessEnvFn = CanAccessEnvBase<CanAccessEnv>;
 *
 *   // The data structure holding the result from above CanAccessEnv callback methods.
 *   type EnvAccessResult = CanAccessEnvBase<boolean>;
 *
 */
export type CanAccessEnvBase<T> = {
  [K in Environment]:
    K extends EnvironmentEntitiesAsSource ? {
      /**
       * The environment's descendent entity.
       */
      entity: Record<EnvItem<K>['entity'], T>;
      host?: never;
    } :
    K extends ApplicationEnvironment ? {
      /**
       * The environment's host backup source type if above environment is of application type.
       */
      host: Record<typeof ApplicationToSourceEnvironment[K][0], T>;
      entity?: never;
    } :
    T;
};

/**
 * Enum for physical environment entity type.
 */
export enum PhysicalEntityType {
  /**
   * EH container
   */
  kGroup = 'kGroup',

  /**
   * Single physical server
   */
  kHost = 'kHost',

  /**
   * Microsoft Windows cluster
   */
  kWindowsCluster = 'kWindowsCluster',

  /**
   * Oracle Real Application Cluster(RAC)
   */
  kOracleRACCluster = 'kOracleRACCluster',

  /**
   * Oracle Active-Passive Cluster
   */
  kOracleAPCluster = 'kOracleAPCluster',

  /**
   * Microsoft Windows cluster
   */
  kRole= 'kRole',

  /**
   * Unix cluster
   */
  kUnixCluster = 'kUnixCluster',
}

/**
 * The interfaces defined below includes the following for each environment:
 *
 * 1. environment's descendent entities for environment like kVMware, kHyperV etc
 * 2. host environment for application environment types like kSQL, KOracle, kAD etc
 * 3. the backup source type for job environment type like kHyperVVSS, kPhysicalFile etc
 */

export enum AcropolisEntities {
  kStandaloneCluster = 'kStandaloneCluster',
  kOtherHypervisorCluster = 'kOtherHypervisorCluster',
}

export interface AcropolisEnvItem {
  environment: Environment.kAcropolis;
  entity?: AcropolisEntities;
  sourceEnvironment?: null;
  hostEnvironment?: null;
}

export interface ADEnvItem {
  environment: Environment.kAD;
  entity?: null;
  sourceEnvironment?: null;
  hostEnvironment?: Environment.kPhysical;
}

export interface AgentEnvItem {
  environment: Environment.kAgent;
  entity?: null;
  sourceEnvironment?: null;
  hostEnvironment?: null;
}

export enum AWSEntities {
  kIAMUser = 'kIAMUser',
}

export interface AWSEnvItem {
  environment: Environment.kAWS;
  entity?: AWSEntities;
  sourceEnvironment?: null;
  hostEnvironment?: null;
}

export interface AWSNativeEnvItem {
  environment: Environment.kAWSNative;
  entity?: null;
  sourceEnvironment?: Environment.kAWS;
  hostEnvironment?: null;
}

export interface AWSSnapshotManagerEnvItem {
  environment: Environment.kAWSSnapshotManager;
  entity?: null;
  sourceEnvironment?: Environment.kAWS;
  hostEnvironment?: null;
}

export interface AWSS3EnvItem {
  environment: Environment.kAwsS3;
  entity?: null;
  sourceEnvironment?: Environment.kAWS;
  hostEnvironment?: null;
}

export enum AzureEntities {
  kSubscription = 'kSubscription',
}

export interface AzureEnvItem {
  environment: Environment.kAzure;
  entity?: AzureEntities;
  sourceEnvironment?: null;
  hostEnvironment?: null;
}

export interface AzureNativeEnvItem {
  environment: Environment.kAzureNative;
  entity?: null;
  sourceEnvironment?: Environment.kAzure;
  hostEnvironment?: null;
}

export interface AzureSnapshotManagerEnvItem {
  environment: Environment.kAzureSnapshotManager;
  entity?: null;
  sourceEnvironment?: Environment.kAzure;
  hostEnvironment?: null;
}

export enum CassandraEntities {
  kCluster = 'kCluster',
  kKeyspace = 'kKeyspace',
  kTable = 'kTable'
}

export interface CassandraEnvItem {
  environment: Environment.kCassandra;
  entity?: CassandraEntities;
  sourceEnvironment?: null;
  hostEnvironment?: null;
}

export enum CouchbaseEntities {
  kCluster = 'kCluster',
  kBucket = 'kBucket'
}

export interface CouchbaseEnvItem {
  environment: Environment.kCouchbase;
  entity?: CouchbaseEntities;
  sourceEnvironment?: null;
  hostEnvironment?: null;
}

export enum MongoDBEntities {
  kCluster = 'kCluster',
  kDatabase = 'kDatabase',
  kCollection = 'kCollection'
}

export enum MongoDBPhysicalEntities {
  kOpsManager = 'kOpsManager',
  kOrganisation = 'kOrganisation',
  kProject = 'kProject'
}

export interface MongoDBEnvItem {
  environment: Environment.kMongoDB;
  entity?: MongoDBEntities;
  sourceEnvironment?: null;
  hostEnvironment?: null;
}

export interface MongoDBPhysicalEnvItem {
  environment: Environment.kMongoDBPhysical;
  // TODO Atif: Change MongoDBEntities for physical
  entity?: MongoDBEntities;
  sourceEnvironment?: null;
  hostEnvironment?: null;
}

export interface ElastifileEnvItem {
  environment: Environment.kElastifile;
  entity?: null;
  sourceEnvironment?: null;
  hostEnvironment?: null;
}

export interface ExchangeEnvItem {
  environment: Environment.kExchange;
  entity?: null;
  sourceEnvironment?: null;
  hostEnvironment?: Environment.kPhysical | Environment.kVMware;
}

export interface FlashBladeEnvItem {
  environment: Environment.kFlashBlade;
  entity?: null;
  sourceEnvironment?: null;
  hostEnvironment?: null;
}

export enum GCPEntities {
  kIAMUser = 'kIAMUser',
}

export interface GCPEnvItem {
  environment: Environment.kGCP;
  entity?: GCPEntities;
  sourceEnvironment?: null;
  hostEnvironment?: null;
}

export interface GCPNativeEnvItem {
  environment: Environment.kGCPNative;
  entity?: null;
  sourceEnvironment?: Environment.kGCP;
  hostEnvironment?: null;
}

export interface GenericNasEnvItem {
  environment: Environment.kGenericNas;
  entity?: null;
  sourceEnvironment?: null;
  hostEnvironment?: null;
}

export interface GPFSEnvItem {
  environment: Environment.kGPFS;
  entity?: null;
  sourceEnvironment?: null;
  hostEnvironment?: null;
}

export enum HBaseEntities {
  kCluster = 'kCluster',
  kNamespace = 'kNamespace',
  kTable = 'kTable'
}

export interface HBaseEnvItem {
  environment: Environment.kHBase;
  entity?: HBaseEntities;
  sourceEnvironment?: null;
  hostEnvironment?: null;
}

export enum HdfsEntities {
  kCluster = 'kCluster'
}

export interface HdfsEnvItem {
  environment: Environment.kHdfs;
  entity?: HdfsEntities;
  sourceEnvironment?: null;
  hostEnvironment?: null;
}

export enum HiveEntities {
  kCluster = 'kCluster',
  kDatabase = 'kDatabase',
  kTable = 'kTable'
}

export interface HiveEnvItem {
  environment: Environment.kHive;
  entity?: HiveEntities;
  sourceEnvironment?: null;
  hostEnvironment?: null;
}

export interface HyperFlexEnvItem {
  environment: Environment.kHyperFlex;
  entity?: null;
  sourceEnvironment?: null;
  hostEnvironment?: null;
}

export enum HyperVEntities {
  kSCVMMServer = 'kSCVMMServer',
  kStandaloneHost = 'kStandaloneHost',
  kStandaloneCluster = 'kStandaloneCluster',
}

export interface HyperVEnvItem {
  environment: Environment.kHyperV;
  entity?: HyperVEntities;
  sourceEnvironment?: null;
  hostEnvironment?: null;
}

export interface HyperVVSSEnvItem {
  environment: Environment.kHyperVVSS;
  entity?: null;
  sourceEnvironment?: Environment.kHyperV;
  hostEnvironment?: null;
}

export interface IsilonEnvItem {
  environment: Environment.kIsilon;
  entity?: null;
  sourceEnvironment?: null;
  hostEnvironment?: null;
}

export interface KubernetesEnvItem {
  environment: Environment.kKubernetes;
  entity?: null;
  sourceEnvironment?: null;
  hostEnvironment?: null;
}

export enum KVMEntities {
  kOVirtManager = 'kOVirtManager',
}

export interface KVMEnvItem {
  environment: Environment.kKVM;
  entity?: KVMEntities;
  sourceEnvironment?: null;
  hostEnvironment?: null;
}

export enum NetappEntities {
  kCluster = 'kCluster',
  kVserver = 'kVserver',
}

export interface NetappEnvItem {
  environment: Environment.kNetapp;
  entity?: NetappEntities;
  sourceEnvironment?: null;
  hostEnvironment?: null;
}

export enum NimbleEntities {
  kStorageArray = 'kStorageArray',
}

export interface NimbleEnvItem {
  environment: Environment.kNimble;
  entity?: NimbleEntities;
  sourceEnvironment?: null;
  hostEnvironment?: null;
}

export enum Office365Entities {
  kApplication = 'kApplication',
  kDomain = 'kDomain',
  kGraphUser = 'kGraphUser',
  kGroup = 'kGroup',
  kGroups = 'kGroups',
  kMailbox = 'kMailbox',
  kO365Exchange = 'kO365Exchange',
  kO365OneDrive = 'kO365OneDrive',
  kO365Sharepoint = 'kO365Sharepoint',
  kOutlook = 'kOutlook',
  kPublicFolder = 'kPublicFolder',
  kPublicFolders = 'kPublicFolders',
  kRootPublicFolder = 'kRootPublicFolder',
  kSite = 'kSite',
  kSites = 'kSites',
  kTeam = 'kTeam',
  kTeams = 'kTeams',
  kUser = 'kUser',
  kUsers = 'kUsers',
}

export interface O365EnvItem {
  environment: Environment.kO365;
  entity?: Office365BackupType & Office365Entities;
  sourceEnvironment?: null;
  hostEnvironment?: null;
}

export interface O365ExchangeEnvItem {
  environment: Environment.kO365Exchange;
  entity?: null;
  sourceEnvironment?: Environment.kO365;
  hostEnvironment?: null;
}

export interface O365GroupEnvItem {
  environment: Environment.kO365Group;
  entity?: null;
  sourceEnvironment?: Environment.kO365;
  hostEnvironment?: null;
}

export interface O365OneDriveEnvItem {
  environment: Environment.kO365OneDrive;
  entity?: null;
  sourceEnvironment?: Environment.kO365;
  hostEnvironment?: null;
}

export interface O365OutlookEnvItem {
  environment: Environment.kO365Outlook;
  entity?: null;
  sourceEnvironment?: Environment.kO365;
  hostEnvironment?: null;
}

export interface O365PublicFoldersEnvItem {
  environment: Environment.kO365PublicFolders;
  entity?: null;
  sourceEnvironment?: Environment.kO365;
  hostEnvironment?: null;
}

export interface O365SharepointEnvItem {
  environment: Environment.kO365Sharepoint;
  entity?: null;
  sourceEnvironment?: Environment.kO365;
  hostEnvironment?: null;
}

export interface O365TeamsEnvItem {
  environment: Environment.kO365Teams;
  entity?: null;
  sourceEnvironment?: Environment.kO365;
  hostEnvironment?: null;
}

export interface OracleEnvItem {
  environment: Environment.kOracle;
  entity?: null;
  sourceEnvironment?: null;
  hostEnvironment?: Environment.kPhysical;
}

export interface PhysicalEnvItem {
  environment: Environment.kPhysical;
  entity?: null;
  sourceEnvironment?: null;
  hostEnvironment?: null;
}

export interface PhysicalFilesEnvItem {
  environment: Environment.kPhysicalFiles;
  entity?: null;
  sourceEnvironment?: Environment.kPhysical;
  hostEnvironment?: null;
}

export interface RemoteAdapterEnvItem {
  environment: Environment.kRemoteAdapter;
  entity?: null;
  sourceEnvironment?: null;
  hostEnvironment?: null;
}

export enum PureEntities {
  kStorageArray = 'kStorageArray',
}

export interface PureEnvItem {
  environment: Environment.kPure;
  entity?: PureEntities;
  sourceEnvironment?: null;
  hostEnvironment?: null;
}

export interface RDSSnapshotManagerEnvItem {
  environment: Environment.kRDSSnapshotManager;
  entity?: null;
  sourceEnvironment?: Environment.kAWS;
  hostEnvironment?: null;
}

export interface SQLEnvItem {
  environment: Environment.kSQL;
  entity?: null;
  sourceEnvironment?: null;
  hostEnvironment?: Environment.kPhysical | Environment.kVMware | Environment.kSQL;
}

export interface StorageSnapshotEnvItem {
  environment: Environment.kStorageSnapshotProvider;
  entity?: null;
  sourceEnvironment?: null;
  hostEnvironment?: null;
}

export interface ViewEnvItem {
  environment: Environment.kView;
  entity?: null;
  sourceEnvironment?: null;
  hostEnvironment?: null;
}

export interface VCDEnvItem {
  environment: Environment.kVCD;
  entity?: null;
  sourceEnvironment?: Environment.kVMware;
  hostEnvironment?: null;
}

export interface PuppeteerEnvItem {
  environment: Environment.kPuppeteer;
  entity?: null;
  sourceEnvironment?: Environment.kRemoteAdapter;
  hostEnvironment?: null;
}

export interface UDAEnvItem {
  environment: Environment.kUDA;
  entity?: null;
  sourceEnvironment?: null;
  hostEnvironment?: null;
}

export enum VMwareEntities {
  kVCenter = 'kVCenter',
  kStandaloneHost = 'kStandaloneHost',
  kvCloudDirector = 'kvCloudDirector',
}

export interface VMwareEnvItem {
  environment: Environment.kVMware;
  entity?: VMwareEntities;
  sourceEnvironment?: null;
  hostEnvironment?: null;
}

export interface SfdcEnvItem {
  environment: Environment.kSfdc;
  entity?: null;
  sourceEnvironment?: null;
  hostEnvironment?: null;
}

/**
 * The data structure for an environment used to define the environment's navigation options places like
 * create job dropdown, create recovery dropdown, filter by object type, filter by job type etc places.
 *
 * case 1: Environments having their descendent entities as backup source(used by register hypervisor source dropdown).
 *   // The below object used to represents the kVMware kVCenter's source and using AdaptorAccessService it is used to
 *   // determine whether the logged-in user can create a kVMware kVCenter's backup job or not.
 *   {
 *     environment: 'kVMware';
 *
 *     // The kVMware's descendent entity type.
 *     entity: 'kVCenter';
 *     sourceEnvironment: null;
 *     hostEnvironment: null;
 *   }
 *
 * case 2: Environments that supports application backup(used by register oracle, SQL sources).
 *   {
 *     environment: 'kOracle';
 *     entity: null;
 *     sourceEnvironment: null;
 *
 *     // The kOracle's host environment type.
 *     hostEnvironment: 'kPhysical';
 *   }
 *
 * case 3 Environments which can be used only as a backup job type(used by create kHyperVVSS backup job type).
 *   {
 *     environment: 'kHyperVVSS';
 *     entity: null;
 *
 *     // The protection source environment type for the kHyperVVSS job type.
 *     sourceEnvironment: 'kHyperV';
 *     hostEnvironment: null;
 *   }
 */
export type EnvItem<E extends Environment> =
  E extends Environment.kAcropolis ? AcropolisEnvItem :
  E extends Environment.kAD ? ADEnvItem :
  E extends Environment.kAgent ? AgentEnvItem :
  E extends Environment.kAWS ? AWSEnvItem :
  E extends Environment.kAWSNative ? AWSNativeEnvItem :
  E extends Environment.kAWSSnapshotManager ? AWSSnapshotManagerEnvItem :
  E extends Environment.kAwsS3 ? AWSS3EnvItem :
  E extends Environment.kAzure ? AzureEnvItem :
  E extends Environment.kAzureNative ? AzureNativeEnvItem :
  E extends Environment.kAzureSnapshotManager ? AzureSnapshotManagerEnvItem :
  E extends Environment.kCassandra ? CassandraEnvItem :
  E extends Environment.kCouchbase ? CouchbaseEnvItem :
  E extends Environment.kElastifile ? ElastifileEnvItem :
  E extends Environment.kExchange ? ExchangeEnvItem :
  E extends Environment.kFlashBlade ? FlashBladeEnvItem :
  E extends Environment.kGCP ? GCPEnvItem :
  E extends Environment.kGCPNative ? GCPNativeEnvItem :
  E extends Environment.kGenericNas ? GenericNasEnvItem :
  E extends Environment.kGPFS ? GPFSEnvItem :
  E extends Environment.kHBase ? HBaseEnvItem :
  E extends Environment.kHdfs ? HdfsEnvItem :
  E extends Environment.kHive ? HiveEnvItem :
  E extends Environment.kHyperFlex ? HyperFlexEnvItem :
  E extends Environment.kHyperV ? HyperVEnvItem :
  E extends Environment.kHyperVVSS ? HyperVVSSEnvItem :
  E extends Environment.kIsilon ? IsilonEnvItem :
  E extends Environment.kKubernetes ? KubernetesEnvItem :
  E extends Environment.kKVM ? KVMEnvItem :
  E extends Environment.kMongoDB ? MongoDBEnvItem :
  E extends Environment.kNetapp ? NetappEnvItem :
  E extends Environment.kNimble ? NimbleEnvItem :
  E extends Environment.kO365 ? O365EnvItem :
  E extends Environment.kO365Exchange ? O365ExchangeEnvItem :
  E extends Environment.kO365Group ? O365GroupEnvItem :
  E extends Environment.kO365OneDrive ? O365OneDriveEnvItem :
  E extends Environment.kO365Outlook ? O365OutlookEnvItem :
  E extends Environment.kO365PublicFolders ? O365PublicFoldersEnvItem :
  E extends Environment.kO365Sharepoint ? O365SharepointEnvItem :
  E extends Environment.kO365Teams ? O365TeamsEnvItem :
  E extends Environment.kOracle ? OracleEnvItem :
  E extends Environment.kPhysical ? PhysicalEnvItem :
  E extends Environment.kPhysicalFiles ? PhysicalFilesEnvItem :
  E extends Environment.kRemoteAdapter ? RemoteAdapterEnvItem :
  E extends Environment.kPure ? PureEnvItem :
  E extends Environment.kRDSSnapshotManager ? RDSSnapshotManagerEnvItem :
  E extends Environment.kSQL ? SQLEnvItem :
  E extends Environment.kStorageSnapshotProvider ? StorageSnapshotEnvItem :
  E extends Environment.kView ? ViewEnvItem :
  E extends Environment.kVCD ? VCDEnvItem :
  E extends Environment.kPuppeteer ? PuppeteerEnvItem :
  E extends Environment.kUDA ? UDAEnvItem :
  E extends Environment.kSfdc ? SfdcEnvItem :
  E extends Environment.kVMware ? VMwareEnvItem :
  never;

/**
 * The data structure represents the union of EnvItem for all environments.
 *
 * TODO(veetesh): figure out a way to dynamically define EnvItems by using above EnvItem<E>.
 */
export type EnvItems =
  AcropolisEnvItem |
  ADEnvItem |
  AgentEnvItem |
  AWSEnvItem |
  AWSNativeEnvItem |
  AWSSnapshotManagerEnvItem |
  AWSS3EnvItem |
  AzureEnvItem |
  AzureNativeEnvItem |
  AzureSnapshotManagerEnvItem |
  CassandraEnvItem |
  CouchbaseEnvItem |
  ElastifileEnvItem |
  ExchangeEnvItem |
  FlashBladeEnvItem |
  GCPEnvItem |
  GCPNativeEnvItem |
  GenericNasEnvItem |
  GPFSEnvItem |
  HBaseEnvItem |
  HdfsEnvItem |
  HiveEnvItem |
  HyperFlexEnvItem |
  HyperVEnvItem |
  HyperVVSSEnvItem |
  IsilonEnvItem |
  KubernetesEnvItem |
  KVMEnvItem |
  MongoDBEnvItem |
  MongoDBPhysicalEnvItem |
  NetappEnvItem |
  NimbleEnvItem |
  O365EnvItem |
  O365ExchangeEnvItem |
  O365GroupEnvItem |
  O365OneDriveEnvItem |
  O365OutlookEnvItem |
  O365PublicFoldersEnvItem |
  O365SharepointEnvItem |
  O365TeamsEnvItem |
  OracleEnvItem |
  PhysicalEnvItem |
  PhysicalFilesEnvItem |
  RemoteAdapterEnvItem |
  PureEnvItem |
  RDSSnapshotManagerEnvItem |
  SQLEnvItem |
  StorageSnapshotEnvItem |
  ViewEnvItem |
  VCDEnvItem |
  PuppeteerEnvItem |
  UDAEnvItem |
  SfdcEnvItem |
  VMwareEnvItem;

export type EnvItemKey =
  `${Environment}` |
  `${Environment}_entity_${EnvItem<Environment>['entity']}` |
  `${Environment}_source_${EnvItem<Environment>['sourceEnvironment']}` |
  `${Environment}_host_${EnvItem<Environment>['hostEnvironment']}`;

export const getEnvItemKey = (
  { environment, entity, sourceEnvironment, hostEnvironment }: EnvItem<Environment>
) => [
  environment,
  entity ? `entity_${entity}` : null,
  sourceEnvironment ? `source_${sourceEnvironment}` : null,
  hostEnvironment ? `host_${hostEnvironment}` : null,
].filter(Boolean).join('_') as EnvItemKey;

export const parseEnvItemKey = (key: EnvItemKey): EnvItem<Environment> => {
  const [environment, type, entityOrSourceOrHost] = key.split('_');

  return {
    environment,
    entity: type === 'entity' ? entityOrSourceOrHost : null,
    sourceEnvironment: type === 'source' ? entityOrSourceOrHost : null,
    hostEnvironment: type === 'host' ? entityOrSourceOrHost : null,
  } as EnvItem<Environment>;
};

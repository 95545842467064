import { Injectable } from '@angular/core';
import { NavItem } from '@cohesity/helix';
import { AppType, IrisContext, PillarType, flagEnabled } from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';
import { ServiceNavProvider } from '../service-nav-provider';

/**
 * This Service configures the appliance-manager navigation menu with iris.
 */
@Injectable({ providedIn: 'root' })
export class ApplianceManagerNavService implements ServiceNavProvider {
  /**
   * The pillar type for the nav service.
   */
  pillar: PillarType = 'appliance-manager';

  /**
   * The application type for the nav service.
   */
  app: AppType = 'applianceManager';

  /**
   * The icon to be displayed for the applianceManager in the navigation bar.
   */
  serviceNavTitleIcon = 'helix:appliance-manager';

  /**
   * A boolean indicating whether the applianceManager branding should be shown in the navigation bar.
   */
  showNavBranding = true;

  constructor(private translate: TranslateService){}

  /**
   * Get the title of the navigation bar.
   */
  get serviceNavTitle(): string {
    return this.translate.instant('applianceManager');
  }

  /**
   * Get the navigation items for the specified Iris context.
   *
   * @param irisContext The Iris context.
   */
  getNavItems(irisContext: IrisContext): NavItem[] {
    return [
      {
        displayName: 'dashboard',
        state: 'appliance-manager',
        icon: 'dashboard!outline',
      },
      {
        displayName: 'system',
        state: 'cluster',
        icon: 'helix:system',
        subNavList: [
          {
            displayName: 'health',
            state: 'health',
          },
          {
            displayName: 'monitoringPerformance',
            state: flagEnabled(irisContext, 'applianceManagerUIChangesForGA') ? 'appliance-manager-performance' : 'performance',
          },
        ],
      },
      {
        displayName: 'settings',
        state: 'cluster',
        icon: 'settings!outline',
        subNavList: [
          {
            displayName: 'clusterManagement',
            state: 'cluster.summary',
          },
          {
            displayName: 'accessManagement',
            state: 'on-prem-access-management'
          },
          {
            displayName: 'networking',
            state: flagEnabled(irisContext, 'ngNetworking') ? 'ng-networking' : 'networking',
          },
          {
            displayName: 'softwareUpdate',
            state: 'ngCluster-upgrade',
          },
          {
            displayName: 'snmp',
            state: 'snmp-view',
          },
          {
            displayName: 'applianceManager.thirdPartyServices',
            state: 'appliance-manager-third-party-services',
          },
        ],
      },
    ];
  }
}

// Factory: Source Details Utility Factory
import { recoveryGroup } from 'src/app/shared';

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.sourcesUtil', [])
    .factory('SourcesUtil', SourcesUtilFn);

  function SourcesUtilFn(_, $state, PubSourceService, ViewService, evalAJAX,
    ENV_GROUPS, ENV_TYPE_CONVERSION, ENUM_ENV_TYPE, HADOOP_SOURCE_EDIT_MODE,
    SOURCE_TYPE_GROUPS, FEATURE_FLAGS, SourceService, $rootScope, cModal, cMessage,
    ngDialogService, AdaptorAccessService) {

    return {
      editSource: editSource,
      getStateParams: getStateParams,
      goToProtectionJobFlow: goToProtectionJobFlow,
      groupSources: groupSources,
      groupSourcesTree: groupSourcesTree,
      isServerProtected: isServerProtected,
      refreshSource: refreshSource,
      unregisterSource: unregisterSource,
      generateContextMenu: generateContextMenu,
      upgradeAgent: upgradeAgent,
    };

    /**
     * groups rootnodes by environment type so they can be properly displayed
     *
     * @param     {Array}    rootNodes    top level source objects
     * @param     {String}    prop         the property to group our rootNodes
     *                                     by
     * @return    {Object}                 objects grouped by environment type
     */
    function groupSources(rootNodes, prop) {
      var environment;
      var _environment;

      return rootNodes.reduce(function groupTypes(sourceGroups, source) {
        var environments = _.get(source, 'registrationInfo.environments');

        /* if the current source has an environments array, create a separate
          group object for each environment in the array. this is necessary
          because physical servers and vcenters can be registered as SQL
          Oracle sources. If the source has an environments array and is also
          a physical server include the server under both groups, because
          the SQL server is not tied to the Physical server you can unregister
          the SQL source on the Physical server and still maintain the Physical
          server so it needs to be represented in both places. */
        if (environments && environments.length) {
          environments.forEach(function eachEnvironment(environment) {

            /* kExchange is currently a 3rd class citizen and not supported
              by magneto so it will be hidden from our sources landing page
              until it is fully supported in a later release */
            if (environment !== 'kExchange') {
              sourceGroups[environment] = sourceGroups[environment] || [];

              // keep a shallow copy of source registered as an application in
              // their respective group so that main node modification will not
              // reflected for registered node, refer ENG-34817 to better
              // understand the issue.
              sourceGroups[environment].push(
                _.assign({}, source, { _rootEnvironment: environment, })
              );
            }
          });

          environment = _.get(source, prop);

          if (environment === 'kPhysical') {
            sourceGroups[environment] = sourceGroups[environment] || [];

            // keep a shallow copy of source registered as an application in
            // their respective group so that main node modification will not
            // reflected for registered node, refer ENG-34817 to better
            // understand the issue.
            sourceGroups[environment].push(
              _.assign({}, source, { _rootEnvironment: environment, })
            );
          }
        } else {
          // If the source neither has an environments array and is not a
          // physical server it should behave normally and be grouped on its own
          environment = _.get(source, prop);

          // Azure supports special Gov Clouds.
          // These should appear as distinct sources.
          if (environment === 'kAzure' && source.protectionSource
            .azureProtectionSource.subscriptionType === 'kAzureGovCloud') {

            // cache the original environemt
            _environment = environment;

            // override environment with value for display
            environment = '_kAzureGov';
          }

          // Aws supports special Gov Clouds.
          // These should appear as distinct sources.
          if (environment === 'kAWS' && source.protectionSource
            .awsProtectionSource.subscriptionType === 'kAWSGovCloud') {

            // cache the original environemt
            _environment = environment;

            // override environment with value for display
            environment = '_kAwsGov';
          }


          sourceGroups[environment] = sourceGroups[environment] || [];

          // keep the same source node reference so that any modification in
          // provided rootNodes will be reflected in source group and vise-versa
          // used to keep track of selected node in c-source-group
          sourceGroups[environment].push(

            // use the original value for environmet if available. This is
            // needed in cases where environment is overridden for display
            // purposes
            _.assign(source, { _rootEnvironment: _environment || environment, })
          );
        }

        return sourceGroups;
      }, {});
    }

    /**
     * Returns node tree grouped by their environment with pseudo root node
     * as the group category.
     *
     * @method   groupSourcesTree
     * @param    {Array}   nodes   The nodes list to group
     * @return   {Array}   List of nodes grouped by their environment.
     */
    function groupSourcesTree(nodes) {
      var sourceGroupsMap;
      var sourceGroupTree;
      var prop = 'protectionSource.environment';

      if (nodes[0] && nodes[0].rootNode) {
        prop = 'rootNode.environment';
      }

      sourceGroupsMap = groupSources(nodes, prop);

      // group root sources by environment type
      sourceGroupTree = _.map(sourceGroupsMap,
        function eachGroup(groupNodes, environment) {
          // Should supress the root node for physical/sql servers.
          var suppressRootNode = _.get(groupNodes[0], 'protectionSource.physicalProtectionSource.type') === 'kGroup' ||
            _.get(groupNodes[0], 'protectionSource.sqlProtectionSource.type') === 'kRootContainer' ||
            _.get(groupNodes[0], 'protectionSource.oracleProtectionSource.type') === 'kRootContainer';

          return {
            protectionSource: {
              // mock group container ID so that c-source-tree can render it
              // w/o running into duplicate IDs issues
              id: -1 * groupNodes[0].protectionSource.id,
              name: ENUM_ENV_TYPE[environment],
              environment: environment,
            },
            suppressRootNode,
            nodes: suppressRootNode ? groupNodes[0].nodes : groupNodes,
            selectedObjectsCounts: {},
          };
        }
      );

      return sourceGroupTree;
    }

    /**
     * Goes to appropriate state according to environment type
     * to edit a source
     *
     * @param    {object}    source    object that contains selected source
     *                                 details
     */
    function editSource(source) {
      var dialogName;
      var stateName;
      var stateParams = { id: source.rootNode.id };

      switch(source.rootNode.environment) {
        case 'kPhysical':
          if (FEATURE_FLAGS.ngRegisterPhysicalDialogEnabled) {
            dialogName = 'register-physical-dialog';
            stateParams = {
              editMode: true,
              source: source,
            };
          } else {
            stateName = 'physical-edit';
            stateParams = { env: source._rootEnvironment,
              id: source.rootNode.id };
          }
          break;
        case 'kPure':
        case 'kNimble':
          dialogName = 'register-san-dialog';
          break;
        case 'kNetapp':
        case 'kGenericNas':
        case 'kIsilon':
        case 'kFlashBlade':
        case 'kGPFS':
        case 'kElastifile':
          if (FEATURE_FLAGS.nasNgRegistration) {
            dialogName = 'register-nas-dialog';
            stateParams = {
              editMode: true,
              source: source.registeredSource,
            };
          } else {
            stateName = 'nas-edit';
          }
          break;
        case 'kView':
          stateName = 'view';
          stateParams = { viewName: source.rootNode.name };
          break;
        case 'kO365':
          if (FEATURE_FLAGS.office365NgRegistration) {
            dialogName = 'register-office365-dialog';
            stateParams = {
              isEditMode: true,
              sourceRegistration: source,
            };
          } else {
            stateName = 'office365-edit';
            stateParams = {
              id: source.rootNode.id,
              source: source,
            };
          }
          break;
        case 'kHyperFlex':
          stateName = 'storage-snapshot-new';
          break;
        case 'kKubernetes':
          dialogName = 'register-kubernetes-dialog';
          stateParams = {
            editMode: true,
            id: source.rootNode.id,
          };
          break;
        case 'kExchange':
          dialogName = 'register-dag-exchange-dialog';
          stateParams = {
            editMode: true,
            id: source.rootNode.id,
          };
          break;
        case 'kCassandra':
          dialogName = 'register-cassandra-dialog';
          stateParams = {
            editMode: true,
            source: source
          };
          break;
        case 'kCouchbase':
          dialogName = 'register-couchbase-dialog';
          stateParams = {
            editMode: true,
            source: source
          };
          break;
        case 'kHBase':
        case 'kHdfs':
        case 'kHive':
          dialogName = 'register-hadoop-dialog';
          stateParams = {
            editMode: true,
            source: source,
            editModeType: HADOOP_SOURCE_EDIT_MODE.RECONFIGURE,
          };
          break;
        case 'kMongoDB':
          dialogName = 'register-mongodb-dialog';
          stateParams = {
            editMode: true,
            source: source,
          };
          break;
        case 'kUDA':
          dialogName = 'register-uda-dialog';
          stateParams = {
            editMode: true,
            source: source,
          };
          break;
        case 'kSfdc':
          dialogName = 'register-sfdc-dialog';
          stateParams = {
            editMode: true,
            source: source,
          };
          break;
        case 'kMongoDBPhysical':
          dialogName = 'register-mongodb-physical-dialog';
          stateParams = {
            editMode: true,
            source: source,
          };
          break;
        default:
          stateName = 'hypervisor-edit';
      }

      if (stateName) {
        $state.go(stateName, stateParams);
      } else if (dialogName) {
        ngDialogService.showDialog(dialogName, stateParams).toPromise()
        .then(function afterClosed(source) {
          if (!source) {
            return Promise.reject();
          }
          return source;
        })
        .catch(Promise.reject);
      }
    }

    /**
     * calls appropriate API method to unregister a source
     *
     * @method    unregisterSource
     * @param     {object}    source    object that contains selected source
     *                                 details
     * @return    {Object}    Promise
     */
    function unregisterSource(source) {
      if (source.rootNode.environment === 'kView') {
        return ViewService.deleteViewModal(source.view.view);
      }

      return PubSourceService.deleteSourceModal(source);
    }

    /**
     * calls appropriate API method to refresh a source
     *
     * @method    refreshSource
     * @param    {number}    id    ID of the source to be refreshed
     * @return   {Object}    Promise
     */
    function refreshSource(id) {
      return PubSourceService.refreshSource(id).catch(evalAJAX.errorMessage);
    }

    /**
     * go to the correct Protection Job State when user clicks the Protect
     * button
     *
     * @param    {Object}    source    Source object containing source details
     */
    function goToProtectionJobFlow(source, environments, jobType) {
      var stateParams = getStateParams(source, environments);
      $state.go('job-modify', stateParams);
    }

    /**
     * Returns the correct stateParams to be used when deeplinking to the job
     * flow.
     *
     * @method   getStateParams
     * @param    {Object}   source           source object containing rootNode
     *                                       details
     * @param    {Array}    [environments]   The allowed environments
     * @return   {Object}   state params obj for deeplinking to job flow
     */
    function getStateParams(source, environments) {
      var stateParams;

      if (!environments || !environments.length) {
        environments = [].concat(source.rootNode.environment);
      }

      stateParams = {
        environments: environments,
        parentSourceId: source.rootNode.id,
      };

      if (ENV_GROUPS.cohesityGroups.includes(source._rootEnvironment)) {
        // Adjust params so that parentSourceEnvironment is sent to the job flow
        // rather than parentSourceId. Since there is a single "parent source"
        // for cohesityGroups[] environment then it can safely be selected by
        // environment.
        stateParams.parentSourceId = undefined;
        stateParams.parentSourceEnvironment = source._rootEnvironment;

        // Pass the _aags property to the protection flow so it can determine
        // whether is needs to protect additional sources.
        source.rootNode._aags = source._aags;

        // Add the specific source as a pre selected node for protection.
        stateParams.protectSources = [source.rootNode];
      }

      return stateParams;
    }

    /**
     * Builds and returns the correct items to be displayed in the context menu
     * for each Source.
     *
     * @method   generateContextMenu
     * @param    {object}   source           The source
     * @param    {string}   environment      The environment
     * @param    {function} reloadCallback   Callback to invoke after an action
     *                                       to refresh data.
     * @return   {array}    array of item objects for c-context-menu
     */
    function generateContextMenu(source, environment, reloadCallback) {
      // TODO (Saleh): Refactor this entire method. There should be a seperate
      // function for each type which will return its specific menu actions.
      // The method in its current state is getting too messy.
      var menuItems = [];
      var environments = [];
      var stateName = 'job-modify';
      var stateParams;
      var isSourceOwner = !!source._owner.isSourceOwner;
      var isVCloudDirectorSource = source._type === 'kvCloudDirector';

      // If the user doesn't own the source, then the user can't perform any
      // action. Hence, return an empty array. (exception vCloudDirector)
      if (!isSourceOwner && !isVCloudDirectorSource) {
        return [];
      }

      switch (environment) {
        case 'kPhysical':
          environments.push('kPhysicalFiles');
          break;
      }

      stateParams = getStateParams(source, environments);

      switch (true) {
        case source.rootNode.environment === 'kView':
          menuItems.push({
            icon: 'icn-protect',
            translateKey: 'protect',
            action: function protectViewAction() {
              ViewService.protectView(source.view.view);
            }
          });
          break;

        case source.rootNode.environment === 'kPhysical':
          menuItems =
            _generateContextMenuForPhysicalSource(
              source, stateName, stateParams, environment, reloadCallback
            );
          break;

        // SQL VMs
        case (source._type === 'kVirtualMachine' &&
          source._rootEnvironment === 'kSQL'):
          // Don't show protect databases option if we know the host is already
          // full protected.
          if (!source._isProtected) {
            menuItems.push({
              icon: 'icn-protect',
              translateKey: 'protectDatabases',

              // TODO(spencer): Consider disabling these until we get the
              // accurate grouping container ID from iris Backend. These are
              // always vcenter id or nothing (for physical) which is incorrect.
              // disabled: true,
              // state: stateName,
              // stateParams: _getDbNewJobStateParams(source)
              action: function protectDBFn() {
                goToProtectionJobFlow(source, [environment]);
              },
            });
          }
          menuItems.push({
            // Unregister server as SQL server
            icon: 'icn-delete',
            disabled: !source._isRegistered,
            action: function unRegisterSQL() {
              updateAppRegistration(source, 'unregister',
                ENV_TYPE_CONVERSION.kSQL, reloadCallback);
            },
            translateKey: 'unregisterSQLServer',
          });
          break;

        case source._rootEnvironment === 'kO365':
          menuItems.push({
            icon: 'icn-protect',
            translateKey: 'protectData',
            action: function protectO365Outlook() {
              goToProtectionJobFlow(source,
                ENV_GROUPS.office365);
            },
          });
          break;

        case source.rootNode.environment === 'kHyperFlex':
          break;
        case ['kHyperFlex', 'kNimble'].includes(source.rootNode.environment):
          // These environments don't support protection.
          break;

        default:
          menuItems.push({
            icon: 'icn-protect',
            translateKey: 'protect',
            state: stateName,
            stateParams: stateParams,
          });
      }

      if (source._type !== 'kVirtualMachine') {
        menuItems.push(
          {
            translateKey: 'edit',
            icon: 'icn-edit',
            disabled: !$rootScope.user.privs.PROTECTION_SOURCE_MODIFY,
            action: function editAction() {
              editSource(source);
            },
          },
          (environment === 'kOracle') ? undefined : {
            translateKey: environment === 'kView' ? 'delete' : 'unregister',
            icon: 'icn-delete',
            disabled: !$rootScope.user.privs.PROTECTION_SOURCE_MODIFY,
            action: function unregisterAction() {
              unregisterSource(source).then(reloadCallback);
            },
          },
          {
            translateKey: 'refresh',
            icon: 'icn-refresh',
            disabled: !source._isRegistered,
            action: function refreshAction() {
              refreshSource(source.rootNode.id).then(
                function refreshSuccess() {
                  cMessage.success({
                    textKey: 'sources.refresh.success',
                  });

                  reloadCallback();
              }, evalAJAX.errorMessage).catch(angular.noop);
            },
          }
        );
      }

      // Remove undefined objects.
      menuItems = menuItems.filter(_.identity);

      // if it is a view source remove the refresh action item which is
      // the last element in the array. you cannot refresh a view source
      if (source.rootNode.environment === 'kView') {
        menuItems.splice(-1, 1);
      }

      // Adding Upgrade Context Action only if environment is Physical
      if (source.rootNode.environment === 'kPhysical' &&
        (!source._isAix || FEATURE_FLAGS.upgradeJavaAIXAgent)) {
        // Upgrade Agent Context Menu
        menuItems.push({
          icon: 'icn-upgrade',
          translateKey: 'upgradeAgent',
          disabled: !$rootScope.user.privs.PROTECTION_SOURCE_MODIFY ||
            source._isRegistering ||
            source._agent.upgradability !== 'kUpgradable',
          action: function upgradeAgentWrapper() {
            var upgradeAgentConfig = {
              agentIds: PubSourceService.getAgentsToUpgrade(source),
              names: [source.rootNode.name],
              oldVersion: _.get(source, '_agent.version', ''),
              isMulipleAgentsUpgrade: source._isSqlCluster,
              hasVcsSource: source._isVcsCluster
            };

            upgradeAgent(upgradeAgentConfig);
          },
        });
      }

      // If not a VCD source owner then keep refresh entity hierarchy option.
      if (!isSourceOwner && isVCloudDirectorSource) {
        menuItems = menuItems.filter(
          ({ translateKey }) => translateKey === 'refresh');
      }

      return menuItems;
    }

    /**
     * Generates the Context Menu for Physical Sources
     *
     * @method   _generateContextMenuForPhysicalSource
     * @param    {object}   source           the source entity
     * @param    {string}   stateName        state name of the menu job-modify /
     *                                       job
     * @param    {object}   stateParams      The state parameters
     * @param    {string}   environment      The environment
     * @param    {function} reloadCallback   Callback to invoke after an action
     *                                       to refresh data.
     * @return   {Array}    The list of physical-specific context menu actions.
     */
    function _generateContextMenuForPhysicalSource(source, stateName,
      stateParams, environment, reloadCallback) {
      var menuItems = [];
      var environments = _.get(source, 'registrationInfo.environments', []);

      if (_.intersection(environments, ENV_GROUPS.databaseSources).length &&
          environment !== 'kPhysical') {
        // Don't show protect databases option if we know the host is already
        // full protected.
        if (!source._isProtected) {
          menuItems.push({
            icon: 'icn-protect',
            disabled: source._isRegistering,
            translateKey: 'protectDatabases',

            // TODO(spencer): Consider disabling these until we get the accurate
            // grouping container ID from iris Backend. These are always vcenter
            // id or nothing (for physical) which is incorrect.
            // disabled: true,
            // state: stateName,
            // stateParams: _getDbNewJobStateParams(source)
            action: function protectDBFn() {
              goToProtectionJobFlow(source, [environment]);
            },
          });
        }
      } else if (environments.includes('kAD') && environment !== 'kPhysical') {
        if (!source._isProtected) {
          menuItems.push({
            icon: 'icn-protect',
            translateKey: 'protectActiveDirectory',
            action: function protectAdFn() {
              goToProtectionJobFlow(source, [environment]);
            }
          })
        }
      } else {
        // Both VCS and Oracle AP Cluster can be protected by a block or file
        // based job.
        // Refer JOB_GROUPS.leafEntitiesForPhysicalJob for details.
        if (SOURCE_TYPE_GROUPS.physicalBlockBased.includes(source._hostType) &&
          !source._type !== 'kOracleRACCluster') {
          menuItems.push({
            icon: 'icn-protect',
            translateKey: 'protectBlockBased',
            state: stateName,
            disabled: source._isBlockProtected,
            stateParams: getStateParams(source, ['kPhysical']),
          });
        }
        if (SOURCE_TYPE_GROUPS.physicalFileBased.includes(source._hostType) &&
          !source._type !== 'kOracleRACCluster') {
          menuItems.push({
            icon: 'icn-protect',
            translateKey: 'protectFileBased',
            disabled: source._isProtected,
            state: stateName,
            stateParams: getStateParams(source, ['kPhysicalFiles']),
          });
        }
      }

      // Enable Collect Diagnostics only for Linux and Oracle sources.
      // Fature flag to be enabled for UI trigger.
      if ((FEATURE_FLAGS.physSourceDiagnosticsCollection) &&
        (source._isAix || source._isLinux || source._isOracleHost)) {
        menuItems.push({
          icon: 'icn-download',
          disabled: !source._isRegistered,
          translateKey: 'collectDiagnostics',
          action: function collectSourceDiagnostics() {
            if (source.rootNode.id) {
              PubSourceService.runDiagnostics(source.rootNode.id)
              .then(function collectSourceDiagnosticsSuccess() {
                cMessage.info({
                  textKey: 'sources.collectdiagnostics.success',
                });
                reloadCallback();
              }).catch(evalAJAX.errorMessage);
            }
          },
        });
      }

      if (source._isSqlHost) {
        menuItems.push({
          // Unregister server as SQL server
          icon: 'icn-delete',
          disabled: !source._isRegistered,
          action: function unRegisterSQL() {
            updateAppRegistration(source, 'unregister',
              ENV_TYPE_CONVERSION.kSQL, reloadCallback);
          },
          translateKey: 'unregisterSQLServer',
        });
      } else if (source._isWindows && !source._isSqlHost &&
          environment === 'kPhysical') {
        menuItems.push({
          // Register as SQL server
          icon: 'icn-add',
          disabled: !source._isRegistered,
          action: function registerSQL() {
            updateAppRegistration(source, 'update',
              ENV_TYPE_CONVERSION.kSQL, reloadCallback);
          },
          translateKey: 'registerAsSQLServer',
        });
      }

      if (source._isOracleHost) {
        menuItems.push({
          // Unregister server as Oracle Server.
          icon: 'icn-delete',
          disabled: !source._isRegistered,
          action: function unRegisterOracle() {
            updateAppRegistration(source, 'unregister',
              ENV_TYPE_CONVERSION.kOracle, reloadCallback);
          },
          translateKey: 'unregisterOracleServer',
        }, {
          // Edit Oracle server credentials.
          icon: 'icn-edit',
          disabled: !source._isRegistered,
          action: function editOracleCredentials() {
            $state.go('oracle-edit', {
              id: source.rootNode.id,
              hostName: source.protectionSource.physicalProtectionSource.name,
              isDbAuthenticated: _.get(source,
                'registrationInfo.isDbAuthenticated', false),
            });
          },
          translateKey: 'editOracleCredentials',
        });
      } else if ((source._isLinux || source._isAix) && !source._isOracleHost) {
        if (AdaptorAccessService.canAccessSomeEnv(recoveryGroup.oracle)) {
          menuItems.push({
            // Register as Oracle server.
            icon: 'icn-add',
            disabled: !source._isRegistered,
            action: function registerOracleServer() {
              // Disable registration as oracle server for oracle cluster types.
              if (!FEATURE_FLAGS.oracleRACRegistrationEnabled &&
                ENV_GROUPS.oracleClusterTypes.includes(source._type)) {
                cMessage.error({
                  textKey: "oracleSources.cmessages.racRegistrationNoSupport",
                });
                return;
              }
              // Navigate to oracle registration with the selected host name
              $state.go('oracle-register', {
                hostName: source.protectionSource.physicalProtectionSource.name,
              });
            },
            translateKey: 'registerAsOracleServer',
          });
        }
      }

      if (source._isActiveDirectoryHost) {
        menuItems.push({
          // Unregister server as Active Directory server
          icon: 'icn-delete',
          disabled: !source._isRegistered,
          action: function unRegisterAD() {
            updateAppRegistration(source, 'unregister',
              ENV_TYPE_CONVERSION.kAD, reloadCallback);
          },
          translateKey: 'unregisterActiveDirectory',
        });
      } else if (source._isWindows && !source._isActiveDirectoryHost &&
        environment === 'kPhysical') {
        if (AdaptorAccessService.canAccessSomeEnv(recoveryGroup.activeDirectory)) {
          menuItems.push({
            // Register as Active Directory Host
            icon: 'icn-add',
            disabled: !source._isRegistered,
            action: function registerAD() {
              updateAppRegistration(source, 'update',
                ENV_TYPE_CONVERSION.kAD, reloadCallback);
            },
            translateKey: 'registerAsActiveDirectory',
          });
        }
      }

      return menuItems;
    }

    /**
     * Generates stateParams for creating a DB job with the given source.
     *
     * NOTE: This is known to be incomplete. The Iris backend proto doesn't
     * provide accurate data to do this. See notes within. Will likely be fixed
     * in 6.0.1 or later.
     *
     * @private
     * @method   _getDbNewJobStateParams
     * @param    {object}   source   The source to use in the new DB job.
     * @return   {object}   The generates stateParams object.
     */
    function _getDbNewJobStateParams(source) {
      return {
        environments: [source._rootEnvironment],

        // TODO: This info is not currently in the registrationInfo response. It
        // was supposed to be by way of a change in grouping on the backend, but
        // they never did it that way.
        parentSourceId: source.rootNode.parentId || source.rootNode.id,
        sourceId: source.rootNode.id,
        protectSources: [source.rootNode],
      };
    }


    /**
     * for block-based physical servers, check if all volumes are protected.
     *
     * @param     {Object}    source    object containing rootNode details
     * @return    {Boolean}             returns true if all volumes are
     *                                  protected
     */
    function isServerProtected(source) {
      var serverVolumes =
        _.get(source, 'rootNode.physicalProtectionSource.volumes');

      if (serverVolumes && serverVolumes.length) {
        return source.rootNode.physicalProtectionSource.volumes.every(
          function checkVolumes(volume) {
            return volume.isProtected;
        });
      }
    }

    /**
     * Triggers the appropriate update App registration action based on the
     * given Node. Can either modify or unregister depending on the `type`
     * param.
     *
     * @method    updateAppRegistration
     * @param     {object}    source            The Node object.
     * @param     {string}    [type='update']   One of 'update' or 'unregister'.
     *                                          Default = 'update'
     * @param     {function}  reloadCallback    Callback to invoke after an
     *                                          action to refresh data.
     * @param     {number}    appEnvType        App environment type enum value.
     */
    function updateAppRegistration(source, type, appEnvType, reloadCallback) {

      // This is the requestObj that will be sent to the register or unregister
      // SQL server.
      var host = {
        ownerEntity: {
          id: source.rootNode.id
        },
        appEnvVec: [appEnvType]
      };

      var serviceMethodFn;
      type = type || 'update';

      // Reset the SQL Registration Error
      source._isSqlRegError = false;

      serviceMethodFn = type === 'unregister' ?
        SourceService.unregisterAppOwner : SourceService.registerAppOwner;

      serviceMethodFn(host).then(
        _handleAppRegChangeSuccess(source, type, appEnvType, reloadCallback),
        evalAJAX.errorMessage
      );
    }

    /**
     * Factory Fn that returns a promise response handler which displays a
     * success cMessage when a node's App reg is updated and reloads the
     * view/tree.
     *
     * @method    _handleAppRegChangeSuccess
     * @param     {object}     node             The node to update App reg on
     * @param     {string}     [type]           One of 'update' or 'unregister'.
     *                                          Default = 'update'
     * @param     {function}   reloadCallback   Callback to invoke after an
     *                                          action to refresh data.
     * @returns   {function}   The promise handler Fn.
     */
    function _handleAppRegChangeSuccess(
      source, type, appEnvType, reloadCallback) {
      var textKey = (type === 'unregister') ?
        'sourceTreePub.unregisterDBSuccess':'sourceTreePub.updateDBRegSuccess';

      return function handlerFn(resp) {
        cMessage.success({
          textKey: textKey,
          textKeyContext: {
            name: source.rootNode.name,

            // Adding database type to differentiate between MS SQL and Oracle.
            database: ENUM_ENV_TYPE[appEnvType],
          },
        });

        // Refresh the data
        reloadCallback();
      };
    }

    /**
     * Upgrades the Agent with the given config params
     *
     * @method   upgradeAgent
     * @param   {object}   upgradeAgentConfig   The upgrade agent configurations
     */
    function upgradeAgent(upgradeAgentConfig) {
      var modalConfig = {
        templateUrl: 'app/protection/sources/modals/upgrade-agent.html',
        controller: CancelTaskModalCtrl,
      };
      var agentIds = upgradeAgentConfig.agentIds;

      function CancelTaskModalCtrl() {
        var names = upgradeAgentConfig.names || [];

        var translateContext = {
          names: names.join(', '),
          oldVersion: upgradeAgentConfig.oldVersion,
          newVersion: $rootScope.clusterInfo.clusterSoftwareVersion,
        };
        this.translateContext = translateContext;
        this.hasVcsSource = upgradeAgentConfig.hasVcsSource;
        this.isMulipleAgentsUpgrade = upgradeAgentConfig.isMulipleAgentsUpgrade;
      };

      var options = {
        titleKey: 'agentUpgrade.title',
        actionButtonKey: 'upgrade',
        closeButtonKey: 'cancel'
      };

      cModal.standardModal(modalConfig, options).then(function processUpgradeAgents(r) {
        SourceService.upgradeSourceApi(agentIds).then(
          function upgradeAgentsSuccess(r) {
            cMessage.success({
              titleKey: 'sourceTreePub.agentUpgradeScheduled.title',
              textKey: 'sourceTreePub.agentUpgradeScheduled.text',
            });
            $state.reload();
          },
          evalAJAX.errorMessage
        );

      });

    }

  }
})(angular);
